import Step1 from "./step1.mdx";
import Step2 from "./step2.mdx";
import WebSettingsStep from "./WebSettingsStep";

export default ({ nonHttps } = {}) => {
    if (nonHttps) return [{ component: WebSettingsStep }, { component: Step2 }];

    return [
        { component: WebSettingsStep },
        { component: Step2 },
        { component: Step1 },
    ];
};
