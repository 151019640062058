import React, { useState } from "react";
import { SimpleTextField, withErrorCatcher } from "@/components/utilities";

import BaseModal from "@/components/utilities/modal/baseModal";
import css from "application/create/styles/createApp.module";
import { MODAL_THEME } from "@/actions/_types";
import PropTypes from "prop-types";

function ModalHmsCredential(props) {
    const [formData, setFormData] = useState({
        hms_app_id: null,
        hms_app_secret: null,
    });
    const [err, setErr] = useState("");

    const { hms_app_id, hms_app_secret } = formData;
    function onAcceptHandler({ HMS_data }) {
        const { onClick } = props;

        if (typeof onClick === "function") onClick(HMS_data);
    }
    function handleSubmitForm() {
        const { hms_app_id, hms_app_secret } = formData;
        if (hms_app_id && hms_app_secret) {
            onAcceptHandler({ HMS_data: formData });
        } else {
            setErr("فیلد خالی را پر کنید");
        }
    }
    return (
        <BaseModal
            theme={MODAL_THEME.BLUE}
            useDefaultFooter={true}
            modalSize="md"
            title={"ایجاد کریدنشیال هواوی"}
            body={
                <div className="frameworksModal">
                    <div className={"modalContentHMS frameworksModal__content"}>
                        <div className={css.hmsBox}>
                            <SimpleTextField
                                name="hms_app_id"
                                type="text"
                                placeholder="hms_app_id"
                                fullWidth={true}
                                style={{ width: "80%", margin: "10px auto" }}
                                onChange={event =>
                                    setFormData({
                                        ...formData,
                                        hms_app_id: event.target.value,
                                    })
                                }
                                value={hms_app_id ?? ""}
                                error={!formData.hms_app_id && !!err}
                                helperText={!formData.hms_app_id && err}
                            />
                            <SimpleTextField
                                name="hms_app_secret"
                                placeholder="hms_app_secret"
                                type="text"
                                fullWidth={true}
                                style={{ width: "80%", margin: "10px auto" }}
                                onChange={event =>
                                    setFormData({
                                        ...formData,
                                        hms_app_secret: event.target.value,
                                    })
                                }
                                value={hms_app_secret ?? ""}
                                error={!formData.hms_app_secret && !!err}
                                helperText={!formData.hms_app_secret && err}
                            />
                        </div>
                    </div>
                </div>
            }
            onAccept={handleSubmitForm}
            onClose={props.onClose}
            disableSubmitButton={Boolean(hms_app_secret && hms_app_id)}
        ></BaseModal>
    );
}
ModalHmsCredential.propTypes = {
    onClick: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};
export default withErrorCatcher(ModalHmsCredential);
