import React from "react";
import { Route } from "react-router-dom";
import { ToastContainer, ToastPosition } from "react-toastify";

function PublicLayout({ component: Component }) {
    return (
        <>
            <Route render={props => <Component {...props} />} />

            <ToastContainer
                rtl={true}
                className="toast"
                toastClassName="toast-wrap"
                bodyClassName="toast-body-default"
                progressClassName="toast-progress-default"
                pauseOnHover={true}
                position={ToastPosition.BOTTOM_LEFT}
                autoClose={6000}
            />
        </>
    );
}

export default PublicLayout;
