import actionTypes from "@/actions/_types";
import { getApi } from "@/api";

export function faveInstallationInProgress(pushe_id) {
    return {
        type: actionTypes.INSTALLATION.FAVE_INSTALLATION_IN_PROGRESS,
        payload: pushe_id,
    };
}

export function saveApplicationIdInProgress(app) {
    return {
        type: actionTypes.APPLICATION.SAVE_APPLICATION_Id_IN_PROGRESS,
        payload: app,
    };
}

export function getGatewayInformation(data = {}, onSuccess, onFailure) {
    return function () {
        return getApi()
            .post("billing/gateway/", data)
            .then(resp => {
                if (onSuccess) onSuccess(resp.data);
            })
            .catch(() => {
                if (onFailure) onFailure();
            });
    };
}

export function emptyInprog() {
    return function (dispatch) {
        dispatch({
            type: actionTypes.APP.INPROG_DONE,
            payload: null,
        });
    };
}
