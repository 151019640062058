import React from "react";
import AndroidEmailList from "@/components/email/email/list/android/AndroidEmailList";
import { pagePermissionWrapper, PERMISSIONS } from "@/components/utilities";
import WebEmailList from "@/components/email/email/list/web/webEmailList";

const EmailListPage = ({ match: { params }, ...props }) => {
    const EmailPage = NOTIFICATIONS_PAGE[params.platform];
    return <EmailPage {...props} />;
};

const NOTIFICATIONS_PAGE = {
    android: AndroidEmailList,
    web: WebEmailList,
};

export default pagePermissionWrapper(PERMISSIONS.EMAIL_LIST, EmailListPage);
