import { getApi } from "@/api";
import { getPlatformId, isValidPlatform } from "@/actions/_utils.jsx";
import { errorToast, successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import actionTypes, { NOTIFICATION_MODE } from "@/actions/_types";
import {
    NOTIFICATION_ANALYTICS_TYPE,
    notificationAnalyticsHandler,
    processError,
} from "@/components/utilities";
import to from "await-to-js";
import { store } from "@/store";

const { getState, dispatch } = store;

export const fetchNotifications =
    (params = {}, onSuccess, onFailure) =>
    async (dispatch, getState) => {
        // Based on the `params(is_draft)` being set or not, it fetchs `all drafts`, `all notifications`
        // or draft and notification together.
        const { platform, appId } = getState().app;

        if (isValidPlatform(platform, dispatch)) {
            params.appId = appId;
            params.platform = getPlatformId(platform);

            try {
                const response = await getApi(platform).fetchNotifications(
                    params,
                );

                dispatch({
                    payload: {
                        results: response.data.results,
                        initial: !params.offset,
                    },
                    type: actionTypes.NOTIFICATION[
                        `FETCH_${platform.toUpperCase()}_NOTIFICATIONS`
                    ],
                });
                if (onSuccess) onSuccess();
            } catch (e) {
                if (onFailure) onFailure(e);
            }
        }
    };

export const retrieveNotification =
    ({ notificationId, showErrorToast }, onSuccess, onFail) =>
    async dispatch => {
        try {
            const response = await getApi().getNotification(notificationId);

            dispatch({
                type: actionTypes.NOTIFICATION.SAVE_NOTIFICATION,
                payload: response.data,
            });

            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (e) {
            if (showErrorToast) {
                errorToast(
                    TOAST_CONTENT.NOTIFICATION_FETCH_ERROR.TITLE,
                    TOAST_CONTENT.NOTIFICATION_FETCH_ERROR.DESC,
                );
            }
            if (onFail) {
                onFail(e);
            }
        }
    };

export function fetchStatistics(wrapperId, onSuccess) {
    return function (dispatch) {
        setTimeout(() => {
            return getApi()
                .get(`messaging/notifications/${wrapperId}/statistics/`)
                .then(resp => {
                    dispatch({
                        payload: resp.data,
                        type: actionTypes.NOTIFICATION.FETCH_STATISTICS,
                    });
                    if (onSuccess) onSuccess(resp.data);
                })
                .catch(() => {});
        }, 3000);
    };
}

export function sendRapidNotification(data, onSuccess, onFailure) {
    return function (dispatch, getState) {
        // const notification_mode = getState().app.openModal.params["mode"];
        const { platform } = getState().app;
        if (isValidPlatform(platform, dispatch)) {
            data.platform = getPlatformId(platform);
            return getApi()
                .post("messaging/rapid/", data)
                .then(() => {
                    successToast("موفقیت", "ارسال شما با موفقیت انجام شد");
                    if (onSuccess) onSuccess();
                })
                .catch(() => {
                    if (onFailure) onFailure();
                });
        }
    };
}

export const sendTestNotification =
    (data, platform, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getApi(platform).createNotificationTest(data),
        );

        if (error) {
            errorToast(
                TOAST_CONTENT.NOTIFICATION_CREATE_ERROR.TITLE,
                error.toString(),
            );
            if (typeof onFailure === "function") {
                onFailure();
            }
            return;
        }

        dispatch({
            type: actionTypes.NOTIFICATION[
                `CREATE_${platform.toUpperCase()}_NOTIFICATION`
            ],
            payload: response.data,
        });

        successToast(
            TOAST_CONTENT.NOTIFICATION_CREATE_SUCCESS.TITLE,
            TOAST_CONTENT.NOTIFICATION_CREATE_SUCCESS.DESC,
        );

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const createNotification =
    (data, platform, notificationMode, onSuccess, onFailure) =>
    async dispatch => {
        if (isValidPlatform(platform, dispatch)) {
            try {
                data.platform = getPlatformId(platform);
                let response;
                if (Number(notificationMode) === NOTIFICATION_MODE.TEST) {
                    response = await getApi(platform).createNotificationTest(
                        data,
                    );
                } else {
                    response = await getApi(platform).createNotification(data);
                }
                let args = {};

                if (
                    Number(notificationMode) === NOTIFICATION_MODE.TEST ||
                    notificationMode === NOTIFICATION_MODE.NORMAL
                ) {
                    args = {
                        type: actionTypes.NOTIFICATION[
                            `CREATE_${platform.toUpperCase()}_NOTIFICATION`
                        ],
                        payload: response.data,
                    };
                } else if (notificationMode === NOTIFICATION_MODE.WELCOME) {
                    args = {
                        type: actionTypes.APPLICATION[
                            `UPDATE_${platform.toUpperCase()}_APPLICATION`
                        ],
                        payload: {
                            app_id: response.data.app_ids[0],
                            welcome_template_v2: response.data.wrapper_id,
                            welcome_active: false,
                        },
                    };
                } else if (notificationMode === NOTIFICATION_MODE.UPDATE) {
                    args = {
                        type: actionTypes.APPLICATION[
                            `UPDATE_${platform.toUpperCase()}_APPLICATION`
                        ],
                        payload: {
                            app_id: response.data.app_ids[0],
                            update_template_v2: response.data.wrapper_id,
                        },
                    };
                }

                dispatch(args);

                notificationAnalyticsHandler(
                    NOTIFICATION_ANALYTICS_TYPE.success,
                    response,
                );

                successToast(
                    TOAST_CONTENT.NOTIFICATION_CREATE_SUCCESS.TITLE,
                    TOAST_CONTENT.NOTIFICATION_CREATE_SUCCESS.DESC,
                );

                if (onSuccess) onSuccess();
            } catch (e) {
                notificationAnalyticsHandler(
                    NOTIFICATION_ANALYTICS_TYPE.error,
                    e,
                );

                if (onFailure) onFailure(e);
            }
        }
    };

export function deleteNotification(notificationIds, onSuccess, onFailure) {
    return function () {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            if (Array.isArray(notificationIds)) {
                return getApi()
                    .post(
                        `messaging/notifications/multiple_delete`,
                        notificationIds,
                    )
                    .then(() => {
                        dispatch({
                            payload: notificationIds,
                            type: actionTypes.NOTIFICATION[
                                `DELETEALL_${platform.toUpperCase()}_NOTIFICATION`
                            ],
                        });
                        successToast(
                            TOAST_CONTENT.NOTIFICATION_DELETE_SUCCESS.TITLE,
                            TOAST_CONTENT.NOTIFICATION_DELETE_SUCCESS.DESC,
                        );
                        dispatch({
                            type: actionTypes.APP.CLOSE_MODAL,
                            payload: null,
                        });
                        if (onSuccess) onSuccess();
                    })
                    .catch(error => {
                        if (onFailure)
                            onFailure(processError(error?.response?.data));
                    });
            }
            return getApi()
                .delete(`messaging/notifications/${notificationIds}`)
                .then(() => {
                    dispatch({
                        payload: notificationIds,
                        type: actionTypes.NOTIFICATION[
                            `DELETE_${platform.toUpperCase()}_NOTIFICATION`
                        ],
                    });
                    successToast(
                        TOAST_CONTENT.NOTIFICATION_DELETE_SUCCESS.TITLE,
                        TOAST_CONTENT.NOTIFICATION_DELETE_SUCCESS.DESC,
                    );
                    if (onSuccess) onSuccess();
                })
                .catch(error => {
                    if (onFailure)
                        onFailure(processError(error?.response?.data));
                });
        }
    };
}

export function resendNotification(wrapper_id, onSuccess, onFailure) {
    return function () {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            return getApi()
                .post("messaging/notifications/resend/", { wrapper_id })
                .then(resp => {
                    dispatch({
                        payload: resp.data,
                        type: actionTypes.NOTIFICATION[
                            `CREATE_${platform.toUpperCase()}_NOTIFICATION`
                        ],
                    });

                    successToast(
                        TOAST_CONTENT.NOTIFICATION_RESEND_SUCCESS.TITLE,
                        TOAST_CONTENT.NOTIFICATION_RESEND_SUCCESS.DESC,
                    );

                    notificationAnalyticsHandler(
                        NOTIFICATION_ANALYTICS_TYPE.success,
                        resp,
                    );

                    if (onSuccess) onSuccess();
                })
                .catch(resp => {
                    notificationAnalyticsHandler(
                        NOTIFICATION_ANALYTICS_TYPE.error,
                        resp,
                    );

                    if (onFailure)
                        onFailure(processError(resp?.response?.data));
                });
        }
    };
}

export const sendDraft = (data, onSuccess, onFailure) => async dispatch => {
    const platform = getState().app.platform;
    data = { ...data, data_type: 1 };
    if (isValidPlatform(platform, dispatch)) {
        data.platform = getPlatformId(platform);
        const [error, response] = await to(
            getApi(platform).sendNotificationDraft(data),
        );

        if (error) {
            errorToast(
                TOAST_CONTENT.DRAFT_CREATE_FAILURE.TITLE,
                error.toString(),
            );
            if (typeof onFailure === "function") {
                onFailure();
            }
            return;
        }

        dispatch({
            type: actionTypes.NOTIFICATION[
                `SEND_${platform.toUpperCase()}_DRAFT`
            ],
            payload: response.data,
        });

        successToast(
            TOAST_CONTENT.DRAFT_SEND_SUCCESS.TITLE,
            TOAST_CONTENT.DRAFT_SEND_SUCCESS.DESC,
        );

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    }
};

export const editDraft = (data, onSuccess, onFailure) => async dispatch => {
    const platform = getState().app.platform;
    if (isValidPlatform(platform, dispatch)) {
        data.platform = getPlatformId(platform);
        data = { ...data, data_type: 1 };
        const [error, response] = await to(
            getApi(platform).updateNotificationDraft(data),
        );

        if (error) {
            errorToast(
                TOAST_CONTENT.DRAFT_UPDATE_FAILURE.TITLE,
                error.toString(),
            );
            if (typeof onFailure === "function") {
                onFailure();
            }
            return;
        }

        dispatch({
            type: actionTypes.NOTIFICATION[
                `UPDATE_${platform.toUpperCase()}_DRAFT`
            ],
            payload: response.data,
        });

        successToast(
            TOAST_CONTENT.DRAFT_UPDATE_SUCCESS.TITLE,
            TOAST_CONTENT.DRAFT_UPDATE_SUCCESS.DESC,
        );

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    }
};

export function estimateRecipients(values, onSuccess, onFailure) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            values.platform = getPlatformId(platform);
            return getApi()
                .post("messaging/notifications/estimate/", values)
                .then(resp => {
                    // dispatch({
                    //     type: actionTypes.NOTIFICATION.ESTIMATE_NOTIFICATION,
                    //     payload: resp.data.count
                    // });
                    if (onSuccess) {
                        onSuccess(resp.data.count);
                    }
                })
                .catch(() => {
                    if (onFailure) {
                        onFailure();
                    }
                });
        }
    };
}

export function clearRecipientsEstimate() {
    return function (dispatch) {
        dispatch({
            type: actionTypes.NOTIFICATION.ESTIMATE_NOTIFICATION,
            payload: null,
        });
    };
}
