import React from "react";
import FlatButton from "material-ui/FlatButton";
import { withErrorCatcher } from "@/components/utilities";
import { isArray, isEmpty } from "lodash";

const DesktopModeTab = props => {
    const { notification } = props;
    const {
        icon = null,
        title,
        content,
        image,
        buttons,
    } = notification?.data ?? {};

    return (
        <div className="preview-body preview-body--notif">
            <div
                className="preview-body__avatar"
                style={icon ? { backgroundImage: `url(${icon})` } : {}}
            />
            <div className="preview-body__title">
                <div className="preview-body__title__web-title">
                    <div className="icons">
                        <i className="material-icons">clear</i>
                        <i className="material-icons">settings</i>
                    </div>
                    <div className="title-content">
                        <div className="title">{title}</div>
                        <div className="content">{content}</div>
                    </div>
                </div>
            </div>

            {image && (
                <div
                    className="preview-body__avatar--big"
                    style={image ? { backgroundImage: `url(${image})` } : {}}
                />
            )}

            {buttons &&
                isArray(buttons) &&
                !isEmpty(buttons) &&
                buttons.map((btn, indx) => (
                    <FlatButton
                        key={indx}
                        label={btn["btn_content"]}
                        labelPosition={"before"}
                        icon={
                            <i className="material-icons">{btn["btn_icon"]}</i>
                        }
                        className="preview-body__buttons"
                        onClick={() => {
                            btn?.btn_action?.url &&
                                window.open(btn.btn_action.url, "_blank");
                        }}
                    />
                ))}
        </div>
    );
};
export default withErrorCatcher(DesktopModeTab);
