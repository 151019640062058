import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import ApplicationDetails from "application/create/ios/partials/ApplicationDetails";
import { reduxForm } from "redux-form";
import { createValidator, isRequired } from "@/components/utils/validation";
import ApplicationCredentials from "application/create/ios/partials/ApplicationCredentials";

const IosAppCreateStep = () => {
    return (
        <>
            <ApplicationDetails />
            <ApplicationCredentials />
        </>
    );
};

const validators = createValidator({
    name: [isRequired],
    package_name: [isRequired],
    framework: [isRequired],
    credentials: value => {
        if (!!value && value.server_key && value.client_key) {
            return undefined;
        }
        return {
            server_key:
                value && value.server_key ? undefined : "این فیلد الزامی است",
            client_key:
                value && value.client_key ? undefined : "این فیلد الزامی است",
        };
    },
});

const IosAppCreateStepForm = reduxForm({
    form: "application_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(IosAppCreateStep);

export default withErrorCatcher(IosAppCreateStepForm);
