
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Manifest = makeShortcode("Manifest");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`راه‌اندازی کتابخانه پوشه`}</h1>
    <p>{`در فایل `}<inlineCode parentName="p">{`Info.plist`}</inlineCode>{` متناظر با `}<inlineCode parentName="p">{`Target`}</inlineCode>{` برنامه، کلید‌ها و مقادیر زیر را وارد کنید.`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/ios/extra/app-groups"
      }}>{`نکته مهم: برای راه اندازی App Groups به راهنما مراجعه کنید.`}</a></p>
    <Manifest passToSteps={props.passToSteps} mdxType="Manifest">
      <pre><code parentName="pre" {...{}}>{`<plist version="1.0">
    <dict>
        <key>FirebaseAppDelegateProxyEnabled</key>
        <false/>

        <key>Pushe</key>
        <dict>
            <key>AppGroupsId</key>
            <string>AppGroupsId مقدار</string>
            <key>ManifestKey</key>
            <string>{{manifest}}</string>
        </dict>
    </dict>
</plist>
`}</code></pre>
    </Manifest>
    <p>{`در فایل `}<inlineCode parentName="p">{`Info.plist`}</inlineCode>{` متناظر با `}<inlineCode parentName="p">{`Notification Service Extension`}</inlineCode>{` برنامه، کلید‌ها و مقادیر زیر را وارد کنید.`}</p>
    <pre><code parentName="pre" {...{}}>{`<plist version="1.0">
    <dict>
        <key>Pushe</key>
        <dict>
            <key>AppGroupsId</key>
            <string>AppGroupsId مقدار</string>
        </dict>
    </dict>
</plist>
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;