import React, { useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { Link } from "react-router-dom";

const CardActions = props => {
    const { actions, className } = props;
    const [displayActionModal, toggleDisplayActionModal] = useState(-1);

    return (
        <div className={className || "card-list__actions"}>
            {actions.map((action, idx) => {
                const hasModalAction = action.modalAction;

                return (
                    <React.Fragment key={idx}>
                        {action.type === "button" && (
                            <button
                                data-tip={action.tip}
                                onClick={() => {
                                    if (hasModalAction) {
                                        toggleDisplayActionModal(action.id);
                                        return;
                                    }

                                    action.onclick();
                                }}
                            >
                                {action.iconComponent && !action.iconName && (
                                    <action.iconComponent />
                                )}
                                {action.iconName && !action.iconComponent && (
                                    <svg className="svg">
                                        <use href={`#${action.iconName}`} />
                                    </svg>
                                )}
                            </button>
                        )}

                        {action.type === "link" && (
                            <Link data-tip={action.tip} to={action.url}>
                                <svg className="svg">
                                    <use href={`#${action.iconName}`} />
                                </svg>
                            </Link>
                        )}

                        {displayActionModal === action.id && hasModalAction && (
                            <action.modalAction
                                onClose={() => toggleDisplayActionModal(-1)}
                                {...(action?.modalActionProps ?? {})}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default withErrorCatcher(CardActions);
