import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import WayPoint from "react-waypoint";
import Loading from "@/components/utils/loading";
import pickBy from "lodash/pickBy";
import AndroidTableFilter from "@/components/users/installations/android/components/AndroidTableFilter";
import AndroidTableHeader from "@/components/users/installations/android/components/AndroidTableHeader";
import AndroidTableRows from "@/components/users/installations/android/components/AndroidTableRows";
import PropTypes from "prop-types";
import { defaultTo as dt } from "lodash";

class AndroidInstallationTable extends React.Component {
    static propTypes = {
        fetchInstallations: PropTypes.func,
        isMenuThin: PropTypes.bool,
        installationLoading: PropTypes.bool,
        installationList: PropTypes.array,
        favList: PropTypes.array,
        favPusheIds: PropTypes.array,
        responseErrorInProg: PropTypes.string,
        currentInstallation: PropTypes.object,
        sendRapidNotification: PropTypes.func,
        faveInstallation: PropTypes.func,
        unFaveDevice: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            offset: dt(props.installationList, []).length,
            filters: {},
        };
    }

    fetchInstallationsData({ shouldResetOffset, ...params }) {
        const { fetchInstallations } = this.props;
        const { offset } = this.state;

        const callback = (dataLength = 0) => {
            this.setState(() => ({
                offset: offset + dataLength,
            }));
        };

        let finalParams = pickBy(params, value => !!value) || {};
        finalParams = {
            ...finalParams,
            offset: shouldResetOffset ? 0 : offset,
        };

        this.setState(
            {
                filters: finalParams,
                shouldResetOffset: shouldResetOffset ? 0 : offset,
            },
            () => {
                fetchInstallations(
                    { ...this.state.filters },
                    callback,
                    callback,
                );
            },
        );
    }

    render() {
        const {
            isMenuThin,
            installationLoading,
            installationList,
            favList,
            favPusheIds,
            responseErrorInProg,
            currentInstallation,
            sendRapidNotification,
            faveInstallation,
            unFaveDevice,
        } = this.props;

        return (
            <div className="main-block">
                <header className="main-block__header">
                    <p className="main-block__title">نصب‌ها</p>
                </header>
                <div className="main-block__body">
                    <div className="table table--featured table--installed table--lg">
                        <AndroidTableFilter
                            fetchInstallationsData={this.fetchInstallationsData.bind(
                                this,
                            )}
                            isMenuThin={isMenuThin}
                        />
                        <div className="table-head">
                            <AndroidTableHeader />
                        </div>
                        <div className="table-body">
                            <AndroidTableRows
                                installationList={installationList}
                                favList={favList}
                                favPusheIds={favPusheIds}
                                responseErrorInProg={responseErrorInProg}
                                currentInstallation={currentInstallation}
                                sendRapidNotification={sendRapidNotification}
                                faveInstallation={faveInstallation}
                                unFaveDevice={unFaveDevice}
                            />
                        </div>
                        <WayPoint
                            onEnter={() =>
                                this.fetchInstallationsData({
                                    ...this.state.filters,
                                })
                            }
                        >
                            {installationLoading ? (
                                <div className="table-loading">
                                    <Loading className="loading-circle--blue-gray loading-circle-xlarge" />
                                </div>
                            ) : (
                                <div className="table-end">
                                    <p>اطلاعات جدیدی وجود ندارد</p>
                                </div>
                            )}
                        </WayPoint>
                    </div>
                </div>
            </div>
        );
    }
}

export default withErrorCatcher(AndroidInstallationTable);
