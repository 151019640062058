import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "@/actions/dashboard";
import { store } from "@/store";
import {
    webFetchFavorites,
    WebUnFaveDevice,
    WebUpdateFaveDevice,
} from "@/actions/installations";
function UseWebInstallationList() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const [platform, appId, dashboard, installationLoading, favorites] =
        useSelector(state => {
            let { appId, platform } = state?.app;
            if (!appId) {
                appId = "all";
            }
            const { favorites } = state[platform];
            const dashboard = state[platform].dashboard[appId] || {};
            const { installationLoading } = state?.loading;
            return [platform, appId, dashboard, installationLoading, favorites];
        });

    const fetchDataDashBoard = useCallback(() => {
        setIsLoading(true);
        const callBack = () => {
            setIsLoading(false);
        };

        fetchDashboardData(callBack, callBack)(dispatch, store.getState);
        webFetchFavorites({}, callBack, callBack)(dispatch, store.getState);
    }, [dispatch]);

    const deleteFavInstall = (param, callBack) => {
        WebUnFaveDevice(param, callBack, callBack)(dispatch, store.getState);
    };
    const updateWebFavInstall = (param, callBack) => {
        WebUpdateFaveDevice(
            param,
            callBack,
            callBack,
        )(dispatch, store.getState);
    };

    useEffect(() => {
        fetchDataDashBoard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId]);

    return {
        platform,
        appId,
        dashboard,
        installationLoading,
        isLoading,
        favorites,
        deleteFavInstall,
        updateWebFavInstall,
    };
}
export default UseWebInstallationList;
