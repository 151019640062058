import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import areIntlLocalesSupported from "intl-locales-supported";
import utils from "material-ui-persian-date-picker-utils";
import moment from "moment-jalaali";

import DatePicker from "material-ui/DatePicker";
import TimePicker from "material-ui/TimePicker";

import "styles/modules/_mod-date-picker.scss";

let DateTimeFormat;

/**
 * Use the native Intl.DateTimeFormat if available, or a polyfill if not.
 //  */
if (areIntlLocalesSupported(["fa-IR"])) {
    DateTimeFormat = global.Intl.DateTimeFormat;
} else {
    const IntlPolyfill = require("intl");
    DateTimeFormat = IntlPolyfill.DateTimeFormat;
    require("intl/locale-data/jsonp/fa-IR");
}

// eslint-disable-next-line no-unused-vars
function disableWeekends(date) {
    return date < moment().startOf("day");
}

const DateComp = props => {
    // eslint-disable-next-line no-unused-vars
    const { type, date, onDateChange, minDate, defaultDate } = props;
    const svgIcon = type === "time" ? "clock" : "calender";
    const direction = "rtl";

    return (
        <div
            className={classNames("date-picker", {
                "date-picker--time": type === "time",
            })}
        >
            <svg>
                <use xlinkHref={`#svg_${svgIcon}`} />
            </svg>

            {type === "date" &&
                (direction === "rtl" ? (
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        hintText="تاریخ"
                        okLabel="تایید"
                        cancelLabel="لغو"
                        value={date}
                        onChange={(event, date) => onDateChange(date)}
                        locale="fa-IR"
                        firstDayOfWeek={6}
                        utils={utils}
                        minDate={minDate || null}
                    />
                ) : (
                    <DatePicker
                        hintText="Date"
                        okLabel="Ok"
                        cancelLabel="Cancel"
                        value={date}
                        onChange={(event, date) => onDateChange(date)}
                    />
                ))}
            {type === "time" &&
                (direction === "rtl" ? (
                    <TimePicker
                        format="24hr"
                        hintText="زمان"
                        okLabel="تایید"
                        cancelLabel="لغو"
                        dialogBodyStyle={{
                            fontFamily: "iranyekanwebbold",
                        }}
                        value={date}
                        onChange={(event, date) => onDateChange(date)}
                    />
                ) : (
                    <TimePicker
                        format="24hr"
                        hintText="Time"
                        okLabel="Ok"
                        cancelLabel="Cancel"
                        value={date}
                        onChange={(event, date) => onDateChange(date)}
                    />
                ))}
        </div>
    );
};

export default DateComp;

DateComp.propTypes = {
    type: PropTypes.oneOf(["date", "time"]),
    date: PropTypes.object.isRequired,
    onDateChange: PropTypes.func,
    minDate: PropTypes.object,
};
