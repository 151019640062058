import React from "react";
import { Field, FormSection, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { ReduxSimpleTextField, withErrorCatcher } from "@/components/utilities";
import {
    createValidator,
    isRequired,
    withMessage,
} from "@/components/utils/validation";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { AUTOMATION_FORM_NAME } from "@/components/email/constants";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
import EmailMultiFormatContent from "@/components/email/email/create/android/partials/EmailMultiFormatContent";

const WebAutomationStep2 = props => {
    const { change, initialValues } = props;

    const formValues =
        useReduxFormValues(AUTOMATION_FORM_NAME, ["notification.data"]) ?? {};

    return (
        <form className="notif-step2">
            <FormSection name="notification.data">
                <div className="step-block">
                    <header>
                        <p>محتوی ایمیل</p>
                    </header>

                    <div className="step-block__column">
                        <Field
                            fullWidth
                            name="subject"
                            label="عنوان ایمیل"
                            required
                            component={ReduxSimpleTextField}
                        />

                        <EmailMultiFormatContent
                            change={change}
                            initialValues={initialValues}
                            formValues={formValues}
                        />
                    </div>
                </div>
            </FormSection>
        </form>
    );
};

const validator = createValidator({
    notification: {
        data: {
            subject: withMessage(
                isRequired,
                FORM_ERRORS.EMAIL_FORM.REQUIRED_TITLE,
            ),
            content: (value, key, allValues) => {
                const emptyValue = value === "<p></p><br/>";
                return !allValues?.data?.["isHTML"] && emptyValue
                    ? FORM_ERRORS.EMAIL_FORM.REQUIRED_CONTENT
                    : undefined;
            },
            html: (value, key, allValues) =>
                allValues?.data?.["isHTML"] && !value
                    ? FORM_ERRORS.EMAIL_FORM.REQUIRED_HTML
                    : undefined,
        },
    },
});
const AndroidAutomationStep2RF = reduxForm({
    form: AUTOMATION_FORM_NAME.web,
    validate: validator,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebAutomationStep2);

export default withRouter(withErrorCatcher(AndroidAutomationStep2RF));
