import React, { useState } from "react";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import IconButton from "material-ui/IconButton";
import { Refresh } from "@/components/utils/lib/svgicons";
import { refreshApiToken } from "@/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

const WebServiceTab = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state?.auth?.profile?.token ?? "");
    const [isLoading, toggleLoading] = useState(false);

    const refreshToken = () => {
        toggleLoading(true);
        const cb = () => toggleLoading(false);
        refreshApiToken({ showToast: true }, cb, cb)(dispatch);
    };

    return (
        <div className="profile-block">
            <div className="profile-block__token">
                <p>
                    <span>توکن</span>
                    <i
                        className="more-info"
                        data-tip="اطلاعات تکمیلی در مورد توکن"
                    >
                        ؟
                    </i>
                </p>
                <IconButton
                    className="icon-btn"
                    data-tip="دریافت مجدد توکن"
                    onClick={refreshToken}
                    disabled={isLoading}
                    style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        padding: "0",
                        width: "30px",
                        height: "30px",
                    }}
                >
                    {isLoading ? (
                        <CircleLoader />
                    ) : (
                        <Refresh viewBox="0 0 18 17" />
                    )}
                </IconButton>
                <div className="profile-block__token-hash">{token}</div>

                <ReactTooltip className="tooltip" effect="solid" />
            </div>
        </div>
    );
};

export default withErrorCatcher(WebServiceTab);
