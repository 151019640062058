import React, { Component } from "react";
import ReactHighmaps from "react-highcharts/ReactHighmaps.src";
import Colors from "../lib/colors";
import { iranMap, iranMapNames } from "@/components/utilities/map/mapsData";
import eventHandler from "@/components/utils/events/eventHandler";

let config = function (data, chartHeight) {
    return {
        chart: {
            height: chartHeight,
            style: {
                fontFamily: "IRANSans",
                direction: "ltr",
            },
        },
        title: {
            text: null,
        },
        legend: {
            enabled: false,
        },
        credits: { enabled: false },
        reflow: false,
        colors: ["#1B9A78"],
        tooltip: {
            useHTML: true,
            style: {
                padding: 10,
                textAlign: "right",
                direction: "rtl",
                color: Colors.gray_8,
            },
            headerFormat:
                '<span style="color:{series.color}">{series.name}</span><br/>',
            formatter: function () {
                var name = iranMapNames.find(
                    item => item.id === this.point.code,
                ).name;
                return (
                    "میزان " +
                    '<b style="font-family:iranyekanwebbold;font-weight: bold">' +
                    this.point.value +
                    " درصد</b>" +
                    "<br/>در استان: " +
                    '<span style="font-family:iranyekanwebbold">' +
                    name +
                    "</span>"
                );
            },
        },
        plotOptions: {
            map: {
                allAreas: true,
                joinBy: ["iso-a2", "code"],
                mapData: iranMap,
            },
        },
        colorAxis: {
            min: 1,
            type: "linear",
            minColor: "#D6F4EC",
            maxColor: "#1B9A78",
        },
        series: [
            {
                dataLabels: {
                    enabled: true,
                    color: "#FFFFFF",
                    format: "{point.value}",
                },
                name: "موقعیت مکانی کاربران",
                data: data,
            },
        ],
    };
};

// Create the charts
class Iran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 1,
        };
    }

    updateDimensions() {
        const width = this.chart.clientWidth;
        this.setState({ height: width });
    }

    componentDidMount() {
        this.updateDimensions();
        eventHandler.on("resize", () => this.updateDimensions(), {
            key: "dimensionIran",
        });
    }

    componentWillUnmount() {
        eventHandler.remove("resize", "dimensionIran");
    }

    render() {
        const { data } = this.props;
        const { height } = this.state;

        return (
            <div
                ref={refs => (this.chart = refs)}
                className="card-g__chart card-g__chart--map"
            >
                <ReactHighmaps config={config(data, height)} />
            </div>
        );
    }
}

export default Iran;
