import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const CardFooter = props => {
    const { children } = props;

    return <div className="card-list__footer">{children}</div>;
};

export default withErrorCatcher(CardFooter);
