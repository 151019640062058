import { isEmpty } from "lodash";
import {
    accept,
    reject,
    actionProcess,
    addOrderingToButtons,
    collapseKeyProcess,
    concatUrl,
    doNotNeedProcess,
    isBigImageMode,
    isCsvMode,
    isFilterMode,
    isJsonNotification,
    isNonZero,
    isNormalMode,
    isNotNull,
    isNotTestMode,
    isObjectEmpty,
    isSegmentMode,
    isShowNotification,
    isTestMode,
    isTopicMode,
    isUpdateMode,
    jsonifyString,
    normalizeCsv,
    normalizeFilters,
    processDateTime,
    processList,
    processObject,
    processTrueOrFalse,
    showCsvId,
    showCustomContent,
    showIcons,
    showLEDColor,
    showLimitedItems,
    soundUrlProcess,
    timeToPersian,
    useDefaultBigContent,
    useDefaultBigTitle,
    webActionProcess,
} from "@/components/utilities/data/normalizer";

/**
 * Used to normalize android notification form data when sending a notification create request
 */
export const androidNotificationNormalizer = {
    data: {
        title: isShowNotification,
        content: isShowNotification,
        icon: isShowNotification,
        action: isShowNotification,
        buttons: [isShowNotification, addOrderingToButtons],
        big_content: [useDefaultBigContent, isShowNotification],
        big_title: [useDefaultBigTitle, isShowNotification],
        summary: isShowNotification,
        image: [isBigImageMode, isShowNotification],
        ticker: isShowNotification,
        notif_icon: isShowNotification,
        notif_channel_id: isShowNotification,
        delay_until: isShowNotification,
        wake_screen: isShowNotification,
        sound_url: isShowNotification,
        led_color: isShowNotification,
        scheduled_time: [isNormalMode, isObjectEmpty],
    },
    update_av_code: [isUpdateMode, isNotNull],
    custom_content: [isJsonNotification, jsonifyString],
    eta: [isNotTestMode, isNotNull],
    app_ids: [accept, val => (!Array.isArray(val) ? [val] : val)],
    partners: isNormalMode,
    rate_limit: [isNotTestMode, isNonZero],
    time_to_live: accept,
    priority: accept,
    unique: isNotTestMode,
    data_type: accept,
    topics: [isTopicMode, isObjectEmpty],
    filters: [normalizeFilters, isFilterMode],
    max_recipients: [isNotTestMode, isNotNull],
    collapse_key: accept,
    segment_ids: isSegmentMode,
    type: [isTestMode, () => 1], // TODO double check
    csv_id: [isCsvMode, normalizeCsv],
};

export const iosNotificationNormalizer = {
    data: {
        title: isShowNotification,
        subtitle: isShowNotification,
        content: isShowNotification,
        action: isShowNotification,
        buttons: [isShowNotification, addOrderingToButtons],
        big_content: [useDefaultBigContent, isShowNotification],
        big_title: [useDefaultBigTitle, isShowNotification],
        summary: isShowNotification,
        image: isShowNotification,
        ticker: isShowNotification,
        notif_icon: isShowNotification,
        notif_channel_id: isShowNotification,
        delay_until: isShowNotification,
        wake_screen: isShowNotification,
        sound_url: isShowNotification,
        led_color: isShowNotification,
    },
    custom_content: [isJsonNotification, jsonifyString],
    eta: isNotTestMode,
    app_ids: [accept, val => (!Array.isArray(val) ? [val] : val)],
    partners: isNormalMode,
    rate_limit: [isNotTestMode, isNonZero],
    time_to_live: accept,
    priority: accept,
    unique: isNotTestMode,
    data_type: accept,
    topics: [isTopicMode, isObjectEmpty],
    filters: isNormalMode,
    max_recipients: [isNotTestMode, isNotNull],
    collapse_key: accept,
    type: [isTestMode, () => 1], // TODO double check
};

/**
 * Used to normalize web notification form data when sending a notification create request
 */

export const webNotificationNormalizer = {
    data: {
        title: accept,
        content: accept,
        icon: accept,
        action: concatUrl,
        buttons: addOrderingToButtons,
        image: accept,
        close_on_click: accept,
    },
    update_av_code: [isUpdateMode, isNotNull],
    custom_content: [isJsonNotification, jsonifyString],
    eta: [isNotTestMode, isNotNull],
    app_ids: [accept, val => (!Array.isArray(val) ? [val] : val)],
    rate_limit: [isNotTestMode, isNonZero],
    time_to_live: accept,
    data_type: accept,
    type: [isTestMode, () => 1], // TODO double check
    csv_id: [isCsvMode, normalizeCsv],
    filters: [normalizeFilters, isFilterMode],
    segment_ids: isSegmentMode,
    topics: [isTopicMode, isObjectEmpty],
};

export const editNotificationNormalizer = {
    ...{
        ...webNotificationNormalizer,
        custom_content: reject,
    },
    wrapper_id: accept,
    data_type: accept,
    app_ids: accept,
};

/**
 * Used to normalize the initial data of the android notification form when forwarding notification
 */
export const androidForwardNotificationNormalizer = {
    data: {
        title: accept,
        content: accept,
        icon: accept,
        action: accept,
        buttons: accept,
        big_content: accept,
        big_title: accept,
        summary: accept,
        image: accept,
        ticker: accept,
        notif_icon: accept,
        notif_channel_id: undefined,
        delay_until: accept,
        wake_screen: accept,
        sound_url: accept,
        led_color: accept,
        scheduled_time: undefined,
        subtitle: accept, // ios only
    },
    custom_content: accept,
    eta: undefined,
    app_ids: undefined,
    partners: undefined,
    rate_limit: accept,
    time_to_live: accept,
    priority: accept,
    unique: accept,
    data_type: accept,
    topics: undefined,
    filters: undefined,
    segment_ids: undefined,
    max_recipients: undefined,
    collapse_key: accept,
    type: accept,
};

export const androidNormalizeNotificationCardData = {
    data: {
        __all__: v => (isEmpty(v) ? undefined : v),
        title: undefined,
        content: undefined,
        icon: undefined,
        action: actionProcess,
        buttons: undefined,
        big_content: undefined,
        big_title: undefined,
        summary: undefined,
        image: undefined,
        ticker: doNotNeedProcess,
        notif_icon: showIcons,
        notif_channel_id: doNotNeedProcess,
        delay_until: doNotNeedProcess,
        wake_screen: processTrueOrFalse,
        sound_url: soundUrlProcess,
        led_color: showLEDColor,
        scheduled_time: processDateTime,
    },
    wrapper_id: doNotNeedProcess,
    custom_content: showCustomContent,
    rate_limit: doNotNeedProcess,
    time_to_live: timeToPersian,
    priority: undefined,
    unique: processTrueOrFalse,
    eta: processDateTime,
    filters: {
        _all__: v => (isEmpty(v) ? undefined : v),
        operator: processList,
        brand: processList,
        mobile_net: processList,
        state: processList,
        topics: processList,
        app_version: processList,
        device_id: showLimitedItems,
        pushe_id: showLimitedItems,
        custom_id: showLimitedItems,
        email: showLimitedItems,
        phone_number: showLimitedItems,
        android_id: showLimitedItems,
        ad_id: showLimitedItems,
        tags: processObject,
        imei: doNotNeedProcess,
        p_id: doNotNeedProcess, // Todo: Do we really have this?
    },
    topics: doNotNeedProcess,
    max_recipients: doNotNeedProcess,
    collapse_key: collapseKeyProcess,
    csv_id: showCsvId,
    segment_ids: undefined,
    segment_names: showLimitedItems,
};

export const webNormalizeNotificationCardData = {
    data: {
        __all__: v => (isEmpty(v) ? undefined : v),
        title: undefined,
        content: undefined,
        icon: undefined,
        action: webActionProcess,
        buttons: undefined,
        close_on_click: processTrueOrFalse,
    },
    wrapper_id: doNotNeedProcess,
    rate_limit: doNotNeedProcess,
    time_to_live: timeToPersian,
};
