import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "styles/modules/_mod-footer.scss";
import "./../../application/components/styles/style.scss";
import "styles/modules/_mod-social.scss";
import { bindActionCreators } from "redux";
import { toggleDemo } from "../../../actions/app";
import eventHandler from "@/components/utils/events/eventHandler";
import myStorage from "@/myStorage";

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    addPaddingBottom() {
        const mainWrap = document.getElementById("mainWrap");
        const footer = document.getElementById("footer");

        if (!footer || !mainWrap) return;
        mainWrap.style.paddingBottom = footer.clientHeight + "px";
    }

    componentDidMount() {
        this.addPaddingBottom();
        eventHandler.on("resize", () => this.addPaddingBottom(), {
            key: "addPaddingBottom",
        });
    }

    componentWillUnmount() {
        eventHandler.remove("resize", "addPaddingBottom");
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const isDemo = this.props.demo || myStorage.getItem("token") === "demo";

        return (
            <div>
                <div className="footer" id="footer">
                    <div className="footer-wrap">
                        <ul className="footer-links">
                            <li>
                                <a
                                    href="http://blog.pushe.co/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    وبلاگ پوشه
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://docs.pushe.co/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    مستندات{" "}
                                </a>
                            </li>
                            {/*<li><a href="javascript:void(0)"*/}
                            {/*onClick={() => this.props.openModal(MODAL_TYPES.MISC.REPORT)*/}
                            {/*}>*/}
                            {/*گزارش تخلف*/}
                            {/*</a></li>*/}
                            <li>
                                <a
                                    href="https://pushe.co/faq/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    سوالات متداول
                                </a>
                            </li>
                            {/*<li><a href="">پشتیبانی</a></li>*/}
                            {/*<li><a href="https://pushe.co/#section_footer" target="_blank">تماس با ما</a></li>*/}
                            {/*<li><a onClick={()=>{!this.props.demo ? this.props.openModal(MODAL_TYPES.MISC.TOGGLE_DEMO) : this.props.toggleDemo();}}>{isDemo ? "خروج از دمو" : "ورود به دمو" }</a></li>*/}
                            <li>
                                <a
                                    href="https://play.google.com/store/apps/details?id=co.ronash.pushesample"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    دانلود اپلیکیشن دمو
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://demo.wp.pushe.co"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    وب‌سایت دمو وب‌پوش
                                </a>
                            </li>
                        </ul>
                        <div className="social social--footer">
                            <a
                                href="https://www.linkedin.com/company/pushe-co"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg>
                                    <use href="#svg_linkedin" />
                                </svg>
                            </a>
                            <a
                                href="https://github.com/pusheco"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg>
                                    <use href="#svg_github" />
                                </svg>
                            </a>
                            <a
                                href="https://twitter.com/pushe_co"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg>
                                    <use href="#svg_twitter" />
                                </svg>
                            </a>
                            <a
                                href="https://www.instagram.com/pushe.co/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg>
                                    <use href="#svg_instagram" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { demo } = state.app;
    return { demo };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleDemo: bindActionCreators(toggleDemo, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
