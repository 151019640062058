import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import {
    hasPermission,
    PERMISSIONS,
    withErrorCatcher,
} from "@/components/utilities";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getFormValues, reduxForm } from "redux-form";
import {
    arrayValidator,
    createValidator,
    isRequired,
} from "@/components/utils/validation";
import "styles/modules/_mod-card-b.scss";
import { fetchSegmentation } from "@/actions/segmentations";
import StepFilterTabs from "@/components/utilities/commons/wizard/StepFilterTabs";
import WebFilterTabs from "@/components/notifications/notification/create/web/parials/WebFilterTabsList";

const WebNotifStep2 = props => {
    const { change, form } = props;
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const { filterType, max_recipients, data } = useSelector(
        state => getFormValues(form)(state) ?? {},
    );

    useEffect(() => {
        if (hasPermission(PERMISSIONS.SEGMENTATION_LIST))
            fetchSegmentation()(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form className="notif-step3">
            <StepFilterTabs filterTabs={WebFilterTabs} change={change} />

            <ReactTooltip className="tooltip" effect="solid" />
        </form>
    );
};

const validators = createValidator({
    filters: {
        events: arrayValidator({
            name: isRequired,
            type: isRequired,
            op: isRequired,
            value: isRequired,
        }),
        visits: arrayValidator({
            type: isRequired,
            op: isRequired,
            value: isRequired,
        }),
    },
});

const WebNotificationStep2Form = reduxForm({
    form: "notification_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebNotifStep2);

export default withRouter(withErrorCatcher(WebNotificationStep2Form));
