import React from "react";
import AndroidAutomationList from "@/components/email/automation/list/android/AndroidAutomationList";
import { pagePermissionWrapper, PERMISSIONS } from "@/components/utilities";
import WebAutomationList from "@/components/email/automation/list/web/WebEmailList";

const EmailAutomationListPage = props => {
    const {
        match: { params },
        ...otherProps
    } = props;

    const EmailPage = NOTIFICATIONS_PAGE[params.platform];

    return <EmailPage {...otherProps} />;
};

const NOTIFICATIONS_PAGE = {
    android: AndroidAutomationList,
    web: WebAutomationList,
};

export default pagePermissionWrapper(
    PERMISSIONS.EMAIL_AUTOMATION_LIST,
    EmailAutomationListPage,
);
