import { Field } from "redux-form";
import React, { useState } from "react";
import { TextField } from "redux-form-material-ui";
import Checkbox from "material-ui/Checkbox";
import PropTypes from "prop-types";
import { withErrorCatcher } from "@/components/utilities";

const NotificationSettingsComponent = ({
    title,
    maxRecipientsDefault,
    channelDefault,
}) => {
    const [maxRecipients, setMaxRecipients] = useState(maxRecipientsDefault);
    const [channel, setChannel] = useState(channelDefault);

    const styles = {
        rowStyle: {
            position: "relative",
            minHeight: "40px",
        },
        checkStyle: {
            display: "inline-block",
            verticalAlign: "middle",
            width: "100px",
            marginLeft: "10px",
            position: "absolute",
            bottom: "10px",
            marginRight: "20px",
            fontSize: ".9rem",
        },
    };

    return (
        <div className="notif-block notif-block--setting">
            <header>
                <p>{title}</p>
            </header>

            <div className="notif-block__body">
                <div className="notif-block__row" style={styles.rowStyle}>
                    <Checkbox
                        className="mui-checkbox"
                        label="ارسال به کاربران کانال خاص"
                        defaultChecked={channel}
                        onCheck={(event, isInputChecked) =>
                            setChannel(isInputChecked)
                        }
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                        }}
                    />
                    {channel && (
                        <Field
                            component={TextField}
                            name="data.notif_channel_id"
                            className="mui-textfield"
                            floatingLabelText="شناسه کانال"
                            style={styles.checkStyle}
                        />
                    )}
                </div>

                <div className="notif-block__row" style={styles.rowStyle}>
                    <Checkbox
                        className="mui-checkbox"
                        defaultChecked={maxRecipients}
                        label={"ارسال به کاربران محدود"}
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "265px",
                        }}
                        onCheck={(event, checked) => setMaxRecipients(checked)}
                    />
                    {maxRecipients && (
                        <Field
                            component={TextField}
                            name="max_recipients"
                            className="mui-textfield"
                            floatingLabelText="تعداد کاربران"
                            style={styles.checkStyle}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

NotificationSettingsComponent.propTypes = {
    title: PropTypes.string,
    showChannels: PropTypes.bool.isRequired,
    showUnique: PropTypes.bool.isRequired,
    maxRecipientsDefault: PropTypes.bool.isRequired,
    channelDefault: PropTypes.bool.isRequired,
};
NotificationSettingsComponent.defaultProps = {
    title: "تنظیمات بیشتر",
};

export default withErrorCatcher(NotificationSettingsComponent);
