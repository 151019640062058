import React from "react";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";

const EmailPageHeader = props => {
    const { pageTitle, platform, history } = props;

    return (
        <div className="segments__page-header">
            <header>
                <p>{pageTitle}</p>
            </header>

            <span data-tip={""}>
                <FlatButton
                    id="newApplication"
                    className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue "
                    rippleColor={blue900}
                    label={"ارسال ایمیل"}
                    onClick={() => {
                        history.push(`/email/${platform}/create`);
                    }}
                />
            </span>
        </div>
    );
};

EmailPageHeader.propTypes = {
    pageTitle: PropTypes.string,
    platform: PropTypes.string,
    history: PropTypes.object.isRequired,
};

export default withErrorCatcher(EmailPageHeader);
