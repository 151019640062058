import actionTypes from "./../actions/_types";
import { combineReducers } from "redux";
// import storage from 'redux-persist/lib/storage';

import { reducer as formReducer } from "redux-form";
import AuthReducer from "./auth";
import AutomationReducer from "./automation";
import GeofenceReducer from "./geofence";
import AppReducer from "./app";
import inprogReducer from "./inprog";
import webReducer from "./web";
import iOSReducer from "./ios";
import androidReducer from "./android";
import financialReducer from "./financial";
import credentialReducer from "./credentials";
import alerts from "./alerts";
import loading from "./loading";
import configEmailSMS from "./configEmailSMS";
import segmentations from "./segmentations";
import segmentationsWeb from "./web/segmentationWeb";
import { connectRouter } from "connected-react-router";
import { history } from "@/store";

const rootReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.APP.TOGGLE_DEMO:
            state = { app: state.app };
            break;
        case actionTypes.AUTH.SIGN_OUT:
            state = undefined;
            // storage.removeItem('persist:root');
            break;
    }

    return combineReducers({
        app: AppReducer,
        auth: AuthReducer,
        financial: financialReducer,
        credentials: credentialReducer,
        automation: AutomationReducer,
        geofence: GeofenceReducer,
        config: configEmailSMS,
        inprog: inprogReducer,

        ios: iOSReducer,
        android: androidReducer,
        web: webReducer,

        alerts: alerts,

        router: connectRouter(history),
        form: formReducer,
        loading,
        segmentations,
        segmentationsWeb,
    })(state, action);
};

export default rootReducer;
