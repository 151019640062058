import React from "react";
import PropTypes from "prop-types";
import BaseModal from "@/components/utilities/modal/baseModal";
import { withErrorCatcher } from "@/components/utilities";
import { MODAL_THEME } from "@/actions/_types";

const SegmentRemoveModal = ({ onClose, onAccept }) => {
    return (
        <BaseModal
            theme={MODAL_THEME.RED}
            title="حذف دسته‌بندی"
            useDefaultFooter={true}
            body={
                <div>
                    آیا از حذف این دسته‌بندی مطمئن هستید؟
                    <br />
                    در صورت حذف این دسته‌بندی ، نوتیفیکیشن‌های اتوماتیک ساخته
                    شده توسط آن به حالت معلق در آمده و اعلان‌های آن ارسال
                    نخواهند شد و فقط می ‌توانید آمار آن را مشاهده کنید.
                </div>
            }
            error={""}
            onClose={onClose}
            onAccept={onAccept}
        />
    );
};

SegmentRemoveModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default withErrorCatcher(SegmentRemoveModal);
