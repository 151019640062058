import React, { Component } from "react";
import ReactDOM from "react-dom";
import "styles/modules/_mod-support-widget.scss";
import SupportWidget from "@/components/app/containers/SupportWidget";
import OutsideClickWrapper from "@/components/utils/OutsideClickWrapper";

export default class Support extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    renderIcon() {
        const { isOpen } = this.state;
        let Icon;
        if (isOpen) {
            Icon = (
                <svg className="support-btn-icon active">
                    <use xlinkHref="#svg_support_close" />
                </svg>
            );
        } else {
            Icon = (
                <svg className="support-btn-icon">
                    <use xlinkHref="#svg_support_widget" />
                </svg>
            );
        }

        return Icon;
    }

    renderWidget() {
        const { isOpen } = this.state;
        const child = (
            <OutsideClickWrapper
                onClose={() => this.setState({ isOpen: false })}
                excludes={[this.buttonRef]}
            >
                <SupportWidget
                    onClose={() => this.setState({ isOpen: false })}
                />
            </OutsideClickWrapper>
        );
        const parent = document.getElementById("root");

        if (isOpen) return ReactDOM.createPortal(child, parent);
    }

    render() {
        return (
            <>
                <button
                    className="support-button"
                    id="supportButton"
                    ref={ref => (this.buttonRef = ref)}
                    onClick={() =>
                        this.setState({ isOpen: !this.state.isOpen })
                    }
                >
                    {this.renderIcon()}
                </button>
                {this.renderWidget()}
            </>
        );
    }
}
