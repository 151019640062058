import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const SmallTab = props => {
    const { notification } = props;
    const { icon = null, title, content } = notification?.data ?? {};

    return (
        <div className="preview-body preview-body--notif preview-body--notif-sm">
            <div
                className="preview-body__avatar"
                style={icon ? { backgroundImage: `url(${icon})` } : {}}
            />
            <div>
                <div className="preview-body__title">{title}</div>
                <div className="preview-body__desc">{content}</div>
            </div>
        </div>
    );
};

export default withErrorCatcher(SmallTab);
