import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import App from "@/App";
import { store, history } from "@/store";
import ReactGA from "react-ga";
import initializeSentry from "configs/sentry";
import initializeEnvs from "configs/initializeEnvs";

export const getConfigs = initializeEnvs();

initializeSentry().then(() => "");

export const PUSHE_APP_ID = "ld8328ko071y65qe";
Pushe.init(PUSHE_APP_ID);

if (CONSOLE_ENV === "production") {
    ReactGA.initialize("UA-172456205-1");
}

render(
    <AppContainer>
        <App store={store} history={history} />
    </AppContainer>,
    document.getElementById("root"),
);

if (module.hot) {
    module.hot.accept("./App", () => {
        const NextApp = require("./App").default;
        render(NextApp);
    });
}
