import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";
import {
    CircleLoader,
    SelectBox,
    withErrorCatcher,
} from "@/components/utilities";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { useDispatch } from "react-redux";
import {
    CreateSetUpConfigSMS,
    UpdateSetUpConfigSMS,
} from "@/actions/configEmailSMS";
import { CONFIG_SMS_TYPE } from "@/components/app/profile/constants";
import {
    createValidator,
    isRequired,
    isSmsSenderNumber,
} from "@/components/utils/validation";
const SMSConfigTab = props => {
    const [loading, toggleLoading] = useState(false);
    const {
        handleSubmit,
        invalid,
        sms,
        initialValues: { username = "" },
    } = props;
    const [type, setType] = useState(1);
    const dispatch = useDispatch();
    const [passFake, setPassFake] = useState("********");

    useEffect(() => {
        if (sms?.length) {
            setType(sms[0]?.provider);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const submitUserInfo = values => {
        toggleLoading(true);
        const cb = () => toggleLoading(false);
        // const normalizedValues = normalize(values, sendNormalizer);
        const { id } = !!sms?.length && sms[0];
        if (sms?.length) {
            UpdateSetUpConfigSMS(id, values, cb, cb)(dispatch);
        } else {
            CreateSetUpConfigSMS(values, cb, cb)(dispatch);
        }
    };
    return (
        <>
            <div className={"profile__block"}>
                <div className="profile__section">
                    <h4 className="profile__section--header">
                        تنظیمات پیامک
                        <span
                            className={"profile__section--header__description"}
                        >
                            (
                            {type === CONFIG_SMS_TYPE.NIK_SMS
                                ? ` نیک اس ام اس  `
                                : ` کاوه نگار `}
                            )
                        </span>
                    </h4>
                    <form>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <Field
                                    name={"provider"}
                                    component={props => {
                                        const {
                                            input: { onChange, value },
                                        } = props;

                                        return (
                                            <div className="step-block__row">
                                                <SelectBox
                                                    //disabled={!!sms?.length}
                                                    value={value || type}
                                                    onChange={value => {
                                                        setType(value);
                                                        onChange(value);
                                                    }}
                                                    label={
                                                        "انتخاب نوع سامانه پیامکی"
                                                    }
                                                    options={[
                                                        {
                                                            label: "کاوه نگار",
                                                            value: 1,
                                                        },
                                                        {
                                                            label: "نیک اس ام اس",
                                                            value: 2,
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <Field
                                    name="name"
                                    component={TextField}
                                    floatingLabelText="نام*"
                                    autoComplete="off"
                                    fullWidth={true}
                                />
                            </div>
                        </div>
                        {type === CONFIG_SMS_TYPE.KAVE_NEGAR && (
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <Field
                                        name="api_key"
                                        component={TextField}
                                        floatingLabelText="*api_key"
                                        autoComplete="off"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-sm-12 col-xs-12">
                                    <Field
                                        name="sender_number"
                                        component={TextField}
                                        floatingLabelText="شماره ارسال کننده*"
                                        autoComplete="off"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                        )}
                        {type === CONFIG_SMS_TYPE.NIK_SMS && (
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <Field
                                        name="username"
                                        component={TextField}
                                        floatingLabelText="نام کاربری*"
                                        autoComplete="off"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-sm-12 col-xs-12">
                                    {username && passFake ? (
                                        <TextField
                                            name="password"
                                            type={"password"}
                                            value={passFake}
                                            autoFocus={false}
                                            autoComplete="new-password"
                                            fullWidth={true}
                                            floatingLabelText="پسورد"
                                            onChange={() => {
                                                setPassFake("");
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Field
                                                autoFocus
                                                name="password"
                                                component={TextField}
                                                floatingLabelText="پسورد"
                                                autoComplete="new-password"
                                                fullWidth={true}
                                                type={"password"}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="col-sm-12 col-xs-12">
                                    <Field
                                        name="sender_number"
                                        component={TextField}
                                        floatingLabelText="شماره ارسال کننده*"
                                        autoComplete="off"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                        )}
                    </form>
                </div>
                <div className="profile__section--footer">
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            paddingTop: "60px",
                        }}
                    >
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            label={
                                loading ? (
                                    <CircleLoader
                                        size={25}
                                        color="#32a9e0"
                                        style={{ top: "8px" }}
                                    />
                                ) : (
                                    "ذخیره"
                                )
                            }
                            onClick={handleSubmit(values => {
                                if (invalid) return;
                                submitUserInfo(values);
                            })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
const validators = createValidator({
    sender_number: [isRequired, isSmsSenderNumber],
    name: isRequired,
    api_key: isRequired,
    username: isRequired,
});
const SMSConfigTabForm = reduxForm({
    form: "configSMS_SMS",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: validators,
})(SMSConfigTab);

export default withErrorCatcher(SMSConfigTabForm);
