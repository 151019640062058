import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "styles/modules/_mod-legend";

const COLOR_MIN = {
    r: 214,
    g: 244,
    b: 236,
};
const COLOR_MAX = {
    r: 27,
    g: 154,
    b: 120,
};

const Legend = props => {
    const { data, mapNames, className } = props;
    const classList = {};
    classList[className] = className;

    const colorChunk = {
        r: Math.floor((COLOR_MIN.r - COLOR_MAX.r) / (data.length - 1)),
        g: Math.floor((COLOR_MIN.g - COLOR_MAX.g) / (data.length - 1)),
        b: Math.floor((COLOR_MIN.b - COLOR_MAX.b) / (data.length - 1)),
    };
    /**
     * sort data based on value
     * add color to each object in data
     */
    const dataWithColor = data.sort(compareItems).map((item, index) => {
        const color = {
            r: COLOR_MAX.r + colorChunk.r * index,
            g: COLOR_MAX.g + colorChunk.g * index,
            b: COLOR_MAX.b + colorChunk.b * index,
        };

        return { ...item, color: `rgb(${color.r},${color.g},${color.b})` };
    });

    function compareItems(a, b) {
        return b.value - a.value;
    }

    function getItemName(item) {
        let found = mapNames.find(itemMap => itemMap.id === item.code);
        if (found) {
            return found.name;
        }
        return "سایر";
    }

    return (
        <div className={classNames("legend", classList)}>
            <ul>
                {dataWithColor.map((item, index) => (
                    <li key={index} style={{ color: item.color }}>
                        <span>{getItemName(item)}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Legend;

Legend.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
    mapNames: PropTypes.array /* array of fa names */,
};
