import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withErrorCatcher } from "@/components/utilities";
import DefaultProfileImage from "images/default_user.png";
import FloatingActionButton from "material-ui/FloatingActionButton";
import { Camera, CloseX } from "@/components/utils/lib/svgicons";
import { deleteFile } from "@/actions/app";
import ProfileImageUploadModal from "@/components/app/profile/partials/ProfileImageUploadModal";

const ProfileImage = props => {
    const { change } = props;

    const dispatch = useDispatch();
    const [displayModal, toggleDisplayModal] = useState(false);
    const profilePicture = useSelector(state => state?.auth?.profile?.picture);

    return (
        <>
            <div className="profile-img">
                <div
                    className="profile-img__avatar"
                    style={{
                        backgroundImage: `url(${
                            profilePicture || DefaultProfileImage
                        })`,
                    }}
                >
                    <FloatingActionButton
                        mini={true}
                        className="profile-img__btn profile-img__btn--camera"
                        onClick={() => toggleDisplayModal(true)}
                    >
                        <Camera
                            viewBox="0 0 23 19"
                            style={{ cursor: "pointer" }}
                        />
                    </FloatingActionButton>

                    <FloatingActionButton
                        mini={true}
                        className="profile-img__btn profile-img__btn--close"
                        onClick={() => {
                            const onSuccess = () => change("picture", "");
                            deleteFile("picture", onSuccess)(dispatch);
                        }}
                    >
                        <CloseX viewBox="0 0 15.414 15.414" />
                    </FloatingActionButton>
                </div>
            </div>

            {displayModal && (
                <ProfileImageUploadModal
                    change={change}
                    closeModal={() => toggleDisplayModal(false)}
                />
            )}
        </>
    );
};

export default withErrorCatcher(ProfileImage);
