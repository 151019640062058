import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const WebTableHeader = () => (
    <div className="table-head">
        <div className="table-row">
            <div className="table-col table-col--30 table-col--right">
                <p style={{ paddingRight: "35px" }}>نام و آدرس وبسایت</p>
            </div>
            <div className="table-col table-col--20">تاریخ ایجاد</div>
            <div className="table-col table-col--10">تعداد کاربر</div>
            {/* <div className="table-col table-col--10">تغییر نام</div>
            <div className="table-col table-col--10">حذف وبسایت</div>*/}
            <div className="table-col table-col--20">آموزش </div>
            <div className="table-col table-col--20">امکانات </div>
            {/*<div className="table-col table-col--20" />*/}
        </div>
    </div>
);

export default withErrorCatcher(WebTableHeader);
