import { useEffect, useState } from "react";

export const useTimer = () => {
    const initSeconds = 60;

    const [seconds, setSeconds] = useState(initSeconds);
    const [isActive, setIsActive] = useState(false);

    function reset() {
        setSeconds(initSeconds);
        setIsActive(false);
    }

    const start = () => {
        setIsActive(true);
    };

    useEffect(() => {
        let interval = null;

        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (!isActive || seconds === 0) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isActive, seconds]);

    return {
        start,
        reset,
        seconds,
        isFinished: () => seconds === 0,
    };
};
