import React from "react";
import {
    PERMISSIONS,
    useHasPermission,
    withErrorCatcher,
} from "@/components/utilities";
import PagePlaceholder from "@/components/utilities/extra/PagePlaceholder";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { Envelop } from "@/components/utils/lib/svgicons";

const EmailPagePlaceholder = ({ history }) => {
    const platform = useSelector(s => s?.app?.platform ?? "android");

    const hasCreatePermission = useHasPermission(
        PERMISSIONS.EMAIL_AUTOMATION_CREATE,
    );

    return (
        <>
            <PagePlaceholder
                icon={<Envelop />}
                body={"هیچ ایمیل اتوماتیکی یافت نشد"}
                footer={
                    <div
                        data-tip={
                            !hasCreatePermission
                                ? "برای ساخت ایمیل اتوماتیک باید پلن خود را ارتقا دهید"
                                : ""
                        }
                    >
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            disabled={!hasCreatePermission}
                            label={"ساخت ایمیل اتوماتیک"}
                            onClick={() =>
                                history.push(
                                    `/email/automation/${platform}/create/`,
                                )
                            }
                        />
                    </div>
                }
            />
            <ReactTooltip className="tooltip" effect="solid" place="bottom" />
        </>
    );
};

export default withErrorCatcher(EmailPagePlaceholder);
