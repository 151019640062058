/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { getCommonApi } from "@/api";
import { formValueSelector } from "redux-form";
import { withErrorCatcher } from "@/components/utilities";
import { connect } from "react-redux";
function SegmentAutoComplete({
    value,
    label,
    onChange,
    items,
    errorText,
    appId,
    platform,
}) {
    const [listNames, setListNames] = useState([]);

    async function FetchSuggestEventNames() {
        const resp = await getCommonApi().SuggestEventNames(
            platform,
            appId.app_id,
        );
        ////  convert to   {
        //                      value: "test_4",
        //                       translation: "تست 4",
        //                  },
        //setListNames(convertArrayToObj("value","translation",resp.data.results))
        setListNames(resp.data.results);
    }
    useEffect(() => {
        if (appId.app_id) {
            FetchSuggestEventNames();
        }
    }, [appId.app_id]);

    const defaultProps = {
        options: listNames,
    };
    return (
        <>
            <Autocomplete
                className=" select--filters auto--complete"
                noOptionsText={"موردی یافت نشد"}
                clearOnEscape
                value={value}
                hintText={label}
                onChange={(_, value) => onChange(value)}
                fullWidth
                errorText={errorText}
                disabled={(items || []).length === 0}
                {...defaultProps}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder={label}
                        variant="standard"
                    />
                )}
            />
        </>
    );
}
const mapStateToProps = state => {
    const appId = formValueSelector("segments")(
        state,
        "segment",
        "name",
        "app_id",
        "segment_id",
    );
    const { platform } = state.app;
    return {
        appId,
        platform,
    };
};
export default withErrorCatcher(connect(mapStateToProps)(SegmentAutoComplete));
