import React from "react";
import PropTypes from "prop-types";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
    deleteApplication,
    exportCSV,
    fetchApplications,
    updateApplication,
} from "@/actions/applications";
import { openModal } from "@/actions/app";
import { createNotification } from "@/actions/notifications";
import { fetchDashboardData } from "@/actions/dashboard";
import connect from "react-redux/es/connect/connect";
import isEmpty from "lodash/isEmpty";
import ReactTooltip from "react-tooltip";
import breakpoint from "@/components/utils/responsive/breakpoint";
import eventHandler from "@/components/utils/events/eventHandler";
import Loading from "@/components/utils/loading";
import WayPoint from "react-waypoint";
import ApplicationFilterWrapper from "application/list/base/ApplicationFilterWrapper";
import uuid from "uuid/v4";
import ApplicationPageHeader from "application/list/common/ApplicationPageHeader";
import ApplicationPagePlaceholder from "application/list/ApplicationPagePlaceholder";

class ApplicationList extends React.Component {
    static propTypes = {
        platform: PropTypes.string.isRequired,
        ApplicationsCharts: PropTypes.object,
        ApplicationFilter: PropTypes.func.isRequired,
        ApplicationsRows: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            appRowHeight: 85,
            isSmallSize: false,
            isFilterOpen: false,
            filteredApplications: props.applications || [],
            isLoadingNewRows: false,
        };
    }

    componentDidMount() {
        this.props.fetchApplications({ platform: this.props.platform });

        this.handleResize();
        eventHandler.on("resize", () => this.handleResize(), {
            key: "resizeMobileTable",
        });
    }

    componentDidUpdate(prevProps) {
        const { app_id, fetchDashboardData } = this.props;

        if (prevProps.app_id !== app_id) {
            fetchDashboardData();
        }
    }

    componentWillUnmount() {
        eventHandler.remove("resize", "resizeMobileTable");
    }

    handleResize() {
        let rowHeight;
        let isSmallSize;

        if (breakpoint(window, "lg")) {
            // minWidth = 1202px
            rowHeight = 85;
            isSmallSize = false;
        } else {
            rowHeight = 260;
            isSmallSize = true;
        }

        this.setState({
            isSmallSize,
            appRowHeight: rowHeight,
        });
    }

    handleDeleteApplication({ app_id }) {
        const { deleteApplication, platform } = this.props;

        deleteApplication({
            platform,
            app_id,
        });
    }

    handleUpdateApplication({ app_id, ...values }) {
        const { platform } = this.props;

        const data = {
            platform,
            app_id,
            ...values,
        };

        this.props.updateApplication(data);
    }

    handleOffset() {
        this.setState(
            () => ({
                isLoadingNewRows: true,
            }),
            () => {
                setTimeout(() => {
                    this.setState(prevState => ({
                        offset: prevState.offset + 10,
                        isLoadingNewRows: false,
                    }));
                }, 1000);
            },
        );
    }

    renderPlaceholder() {
        const { platform, history } = this.props;

        return (
            <ApplicationPagePlaceholder history={history} platform={platform} />
        );
    }

    renderApplications() {
        const { filteredApplications, isSmallSize, offset } = this.state;
        const { appLoading, history, ApplicationsRows, ...otherProps } =
            this.props;

        if (!appLoading && filteredApplications.length === 0) {
            return (
                <div className="table-empty">
                    <p>اطلاعاتی برای نمایش وجود ندارد!</p>
                </div>
            );
        }

        return filteredApplications
            .slice(0, offset + 10)
            .map(app => (
                <ApplicationsRows
                    key={uuid()}
                    app={app}
                    isSmallSize={isSmallSize}
                    history={history}
                    handleDeleteApplication={this.handleDeleteApplication.bind(
                        this,
                    )}
                    handleUpdateApplication={this.handleUpdateApplication.bind(
                        this,
                    )}
                    {...otherProps}
                />
            ));
    }

    render() {
        const {
            ApplicationsCharts,
            applications,
            ApplicationFilter,
            ApplicationsTableHeader,
            appLoading,
            pageTitle,
            platform,
            history,
        } = this.props;
        const { isFilterOpen, offset, isLoadingNewRows } = this.state;

        if (appLoading) {
            return <CircleLoader />;
        } else if (isEmpty(applications)) {
            return this.renderPlaceholder();
        }

        return (
            <>
                {ApplicationsCharts && <ApplicationsCharts />}

                <ApplicationPageHeader
                    pageTitle={pageTitle}
                    platform={platform}
                    history={history}
                />

                <div className="main-block">
                    <div className="main-block__body">
                        <div className="table table--featured table--app table--lg">
                            <ApplicationFilterWrapper
                                openFilter={() =>
                                    this.setState({ isFilterOpen: true })
                                }
                                closeFilter={() =>
                                    this.setState({ isFilterOpen: false })
                                }
                                isFilterOpen={isFilterOpen}
                                render={filterProps => (
                                    <ApplicationFilter
                                        offset={offset}
                                        applications={applications}
                                        onFilterChange={({
                                            filteredApplications,
                                        }) => {
                                            filteredApplications &&
                                                this.setState({
                                                    filteredApplications,
                                                });
                                        }}
                                        {...filterProps}
                                    />
                                )}
                            />

                            <ApplicationsTableHeader />

                            {this.renderApplications()}

                            <WayPoint onEnter={this.handleOffset.bind(this)}>
                                {isLoadingNewRows && (
                                    <div className="table-loading">
                                        <Loading className="loading-circle--blue-gray loading-circle-xlarge" />
                                    </div>
                                )}
                            </WayPoint>
                        </div>
                    </div>
                </div>

                <ReactTooltip className="tooltip" effect="solid" />
            </>
        );
    }
}

function mapStateToProps(state) {
    let app_id = state.app.appId;
    if (!app_id) {
        app_id = "all";
    }

    const { demo } = state.app;
    const { appLoading } = state.loading;

    return {
        demo,
        app_id,
        appLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchApplications,
            openModal,
            deleteApplication,
            exportCSV,
            updateApplication,
            createNotification,
            fetchDashboardData,
        },
        dispatch,
    );
}

export default withErrorCatcher(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationList)),
);
