import React from "react";
import {
    Card,
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    DateTimeComponent,
    DisplayAppComponent,
    NotificationDeleteModalAction,
    NotificationResendModalAction,
    StatisticsComponent,
    StatusComponent,
} from "@/components/utilities/modules/CardList";
import { createStatusObj } from "@/components/notifications/notification/list/common/createCardStatus";
import { tabs } from "@/components/notifications/notification/list/web/tabs/tabs";
import { Notification } from "@/components/utils/lib/svgicons";
import SendDraftModalAction from "@/components/notifications/notification/list/web/draft/SendDraftModalAction";

function sendModalDescription(isDraft, wrapper_id) {
    if (isDraft) {
        return "آیا از ارسال این پیش‌نویس مطمئن هستید؟";
    } else {
        return `آیا از ارسال دوباره نوتیفیکیشن
        ${wrapper_id}
        مطمئن هستید؟`;
    }
}

function deleteModalDescription(isDraft, wrapper_id) {
    if (isDraft) {
        return `آیا از پاک شدن این پیش‌نویس
        مطمئن هستید؟`;
    } else {
        return `آیا از پاک شدن نوتیفیکیشن
        ${wrapper_id}
        مطمئن هستید؟`;
    }
}

function WebNotificationListItems({ notificationList, applications }) {
    const platform = "web";

    return notificationList.map(n => {
        const statusList = createStatusObj(n);

        return (
            <Card key={n.wrapper_id}>
                <CardHeader
                    StatusComponent={StatusComponent}
                    statusProps={{
                        statusList,
                    }}
                    DateTimeComponent={DateTimeComponent}
                    dateTimeProps={{
                        dateTime: n?.created_at,
                    }}
                />
                <CardBody
                    tabs={tabs}
                    tabProps={{
                        notification: n,
                        applications: applications,
                    }}
                    fixedComponents={[DisplayAppComponent]}
                    fixedProps={{
                        appIds: n?.app_ids,
                        platform: platform,
                    }}
                />
                <CardFooter>
                    {
                        <StatisticsComponent
                            statistics={n?.statistics}
                            isDraft={n.is_draft}
                            meta={[
                                {
                                    key: "accurate_recipients",
                                    icon: "svg_user_2",
                                    name: "تعداد مخاطبین",
                                },
                                {
                                    key: "delivered",
                                    icon: "svg_eye",
                                    name: "تعداد دریافت‌ها",
                                },
                                {
                                    key: "clicked",
                                    icon: "svg_click",
                                    name: "تعداد کلیک‌ها",
                                },
                            ]}
                        />
                    }
                    <div className="notif-action-buttom-wrapper__card">
                        {/*   <DisplayStatistics
                            statisticsComponent={NotificationStatistics}
                            statisticsComponentProps={{
                                wrapperId: n?.wrapper_id,
                            }}
                            disabled={n.is_draft}
                        />*/}
                        <CardActions
                            className={"card-list__actionsNewFormatting"}
                            actions={[
                                {
                                    id: "1",
                                    type: "button",
                                    tip: n.is_draft
                                        ? "ارسال پیش‌نویس"
                                        : "ارسال دوباره",
                                    // eslint-disable-next-line react/display-name
                                    iconComponent: () => (
                                        <Notification viewBox="0 0 21 21" />
                                    ),
                                    modalAction: n.is_draft
                                        ? SendDraftModalAction
                                        : NotificationResendModalAction,
                                    modalActionProps: {
                                        title: n.is_draft
                                            ? "ارسال پیش‌نویس"
                                            : "ارسال دوباره نوتیفیکیشن",
                                        body: (
                                            <div>
                                                {sendModalDescription(
                                                    n.is_draft,
                                                    n.wrapper_id,
                                                )}
                                            </div>
                                        ),
                                        app_ids: n.is_draft
                                            ? n.app_ids
                                            : undefined,
                                        wrapperId: n?.wrapper_id,
                                    },
                                },
                                {
                                    id: "2",
                                    type: "link",
                                    tip: n?.is_draft ? "ویرایش" : "فوروارد",
                                    iconName: n?.is_draft
                                        ? "svg_edit"
                                        : "svg_forward",
                                    url: n?.is_draft
                                        ? `/notification/${platform}/edit/${n.wrapper_id}`
                                        : `/notification/${platform}/forward/${n?.["wrapper_id"]}`,
                                },
                                {
                                    id: "3",
                                    type: "button",
                                    tip: "حذف",
                                    iconName: "svg_trash",
                                    modalAction: NotificationDeleteModalAction,
                                    modalActionProps: {
                                        title: n.is_draft
                                            ? "حذف پیش‌نویس"
                                            : "حذف نوتیفیکیشن",
                                        body: (
                                            <div>
                                                {deleteModalDescription(
                                                    n.is_draft,
                                                    n.wrapper_id,
                                                )}
                                                ‍‍‍
                                            </div>
                                        ),
                                        wrapperId: n?.["wrapper_id"],
                                    },
                                },
                            ]}
                        />
                    </div>
                </CardFooter>
            </Card>
        );
    });
}

export default WebNotificationListItems;
