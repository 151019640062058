import React from "react";
import { darken, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: props =>
            theme.palette.dashboard[props.backgroundColor],
        color: props => theme.palette.dashboard[props.color],
        borderRadius: 0,

        "&:hover": {
            backgroundColor: props =>
                darken(theme.palette.dashboard[props.backgroundColor], 0.2),
        },
    },
    label: {
        fontSize: ".9rem",
        fontWeight: "bold",
        padding: "0 8px",
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export const FLAT_BUTTON_SIZE = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
};

export const FLAT_BUTTON_TYPE = {
    DEFAULT: "default",
    GRAY: "gray",
};

const buttonProps = {
    [FLAT_BUTTON_TYPE.DEFAULT]: {
        color: "white",
        backgroundColor: "blue",
    },
    [FLAT_BUTTON_TYPE.GRAY]: {
        color: "black",
        backgroundColor: "gray",
    },
};

export const FlatButton = props => {
    const btnType = props?.["buttonType"] ?? FLAT_BUTTON_TYPE.DEFAULT;
    const styleProps = {
        color: buttonProps[btnType].color,
        backgroundColor: buttonProps[btnType].backgroundColor,
    };
    const classes = useStyles(styleProps);

    const {
        children,
        label,
        size = FLAT_BUTTON_SIZE.MEDIUM,
        ...otherProps
    } = props;

    return (
        <Button
            variant="contained"
            size={size}
            color="primary"
            disableElevation={true}
            classes={{ root: classes.root, label: classes.label }}
            className={classes.margin}
            {...otherProps}
        >
            {children || label}
        </Button>
    );
};
