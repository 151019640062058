/* eslint-disable no-unused-vars */
import { PROMPT_TYPE } from "@/constants";

/**
 * Categorize options based on the `options?.dialogType` in order to
 * minimalize options sent upstream(API)
 * @param usage to differentiate between code block given to user
 *      and options sent upstream. 'CodeBlock' and 'API' available
 *      as options.
 */
function refineSDKPromptOptions(options, usage = "CodeBlock") {
    if (options?.dialogType === PROMPT_TYPE.PUSHE) {
        const {
            overlaySubscription,
            delayingPrompt,
            unblockPrompt,
            dialogType,
            ...otherOptions
        } = options;
        return {
            ...returnCommonSubscriptionPromptSettings(options, usage),
            ...otherOptions,
        };
    } else if (options?.dialogType === PROMPT_TYPE.OVERLAY) {
        return {
            ...returnCommonSubscriptionPromptSettings(options, usage),
            overlaySubscription: {
                ...options?.overlaySubscription,
            },
        };
    } else if (options?.dialogType === PROMPT_TYPE.DEFAULT) {
        return {
            ...returnCommonSubscriptionPromptSettings(options, usage),
        };
    }
    return options;
}

const returnCommonSubscriptionPromptSettings = (options, usage) => ({
    showDialog: options?.showDialog ?? false,
    ...(usage === "API" && { dialogType: options?.dialogType }),
    ...(options?.unblockPrompt?.enableUnblockPrompt
        ? { unblockPrompt: options?.unblockPrompt }
        : {}),
    ...(typeof options?.delayingPrompt === "object"
        ? { delayingPrompt: options.delayingPrompt }
        : {}),
});

function refineSDKGeolocationPromptOptions(options) {
    if (options?.isEnabled) {
        if (options.showDialog) {
            return options;
        } else {
            return {
                isEnabled: options?.isEnabled,
                showDialog: options?.showDialog,
            };
        }
    }

    return null;
}

export { refineSDKPromptOptions, refineSDKGeolocationPromptOptions };
