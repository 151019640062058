import { store } from "../store";
import { signOut } from "./auth";
import { TOAST_CONTENT } from "../components/utils/assertion-texts";
import { errorToast } from "@/components/utils/Toasts";
import { processError } from "@/components/utilities";

/*
 ** Handle errors of actions
 * with opening Toast
 */
export const handleErrors = error => {
    const excludePages = ["/", "signin", "deskpro"];
    let currentPage = location.pathname.replace(
        /^\/([a-zA-Z0-9-_]+)(\/?.*)/,
        "$1",
    );

    if (excludePages.includes(currentPage) || !error) {
        return;
    }

    // Timout Error
    if (!!error.code && error.code === "ECONNABORTED") {
        errorToast(
            TOAST_CONTENT.SERVER_TIMEOUT_ERR.TITLE,
            TOAST_CONTENT.SERVER_TIMEOUT_ERR.DESC,
        );
        return;
    }

    // Generic Network Errors
    if (!!error.message && error.message === "Network Error") {
        errorToast(
            TOAST_CONTENT.NETWORK_ERROR.TITLE,
            TOAST_CONTENT.NETWORK_ERROR.DESC,
        );
        return;
    }

    // No Response Error (probably because of Generic Network Errors this does not catches anymore)
    if (error.request && !error.response) {
        errorToast(
            TOAST_CONTENT.SERVER_ERROR.TITLE,
            TOAST_CONTENT.SERVER_ERROR.DESC,
        );
        return;
    }

    if (error.response) {
        let responseCode = error.response.status;
        let responseText = "";

        if (error.response.data) {
            try {
                if (error.response.data["non_field_errors"]) {
                    responseText = error.response.data["non_field_errors"][0];
                }
                if (error.response.data["details"]) {
                    responseText = error.response.data["details"];
                } else if (
                    typeof error.response.data === "object" &&
                    Object.values(error.response.data).length > 0
                ) {
                    responseText = Object.values(error.response.data)[0];
                } else {
                    responseText = JSON.stringify(error.response.data);
                }
            } catch (e) {
                // ignore it
            }
        }

        if (responseCode.toString().startsWith("4")) {
            if (responseCode === 401) {
                return store.dispatch(signOut());
            } else {
                if (!responseText) return;
                errorToast(
                    TOAST_CONTENT.DEFAULT_ERROR_TOAST.TITLE,
                    processError(responseText),
                );
                return;
            }
        }

        if (responseCode.toString().startsWith("5")) {
            errorToast(
                TOAST_CONTENT.DEFAULT_ERROR_TOAST.TITLE,
                TOAST_CONTENT.DEFAULT_ERROR_TOAST.DESC,
            );
            return;
        }
    }
};
