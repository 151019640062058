
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`اضافه کردن کتابخانه ری‌اکت‌نیتیو`}</h1>
    <p>{`با استفاده از دستور زیر کتابخانه پوشه را به پروژه خود اضافه کنید.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i -P pushe-react-native
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`نکته:`}</em><br parentName="p"></br>{`
`}{`در صورتی که `}<em parentName="p">{`ورژن ری‌اکت‌نیتو`}</em>{` در پروژه شما قبل از `}<em parentName="p">{`نسخه ۶۰`}</em>{` می‌باشد بعد از دستور بالا باید دستور زیر را هم وارد کنید`}</p>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`react-native link pushe-react-native
`}</code></pre>
    </blockquote>
    <p>{`برای اطلاعات بیشتر از نحوه اضافه کردن کتابخانه به پروژه‌تان می‌توانید `}<a parentName="p" {...{
        "href": "http://docs.pushe.co/docs/react-native/intro/#%D8%A7%D8%B6%D8%A7%D9%81%D9%87-%DA%A9%D8%B1%D8%AF%D9%86-%DA%A9%D8%AA%D8%A7%D8%A8%D8%AE%D8%A7%D9%86%D9%87"
      }}>{`راهنما پوشه`}</a>{` را مطالعه کنید.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;