import actionTypes from "@/actions/_types";

const initial_state = {
    automation: {},
    automations: {
        offset: 0,
        hasMore: true,
        items: [],
    },
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case actionTypes.AUTOMATION.CREATE_AUTOMATION:
            return {
                ...state,
                automations: {
                    ...state.automations,
                    items: [action.payload, ...state.automations.items],
                },
            };
        case actionTypes.AUTOMATION.UPDATE_AUTOMATION:
            return {
                ...state,
                automations: {
                    ...state.automations,
                    items: state.automations.items.map(a => {
                        return a.automation_id === action.payload.automation_id
                            ? action.payload
                            : a;
                    }),
                },
            };
        case actionTypes.AUTOMATION.DELETE_AUTOMATION:
            return {
                ...state,
                automations: {
                    ...state.automations,
                    items: state.automations.items.filter(
                        a => a.automation_id !== action.payload.automation_id,
                    ),
                },
            };
        case actionTypes.AUTOMATION.RETRIEVE_AUTOMATION_LIST:
            return {
                ...state,
                automations: {
                    ...state.automations,
                    offset: action.payload.offset,
                    hasMore: action.payload.hasMore,
                    items: action.payload.shouldReset
                        ? [...action.payload.items]
                        : [...state.automations.items, ...action.payload.items],
                },
            };
        case actionTypes.AUTOMATION.RETRIEVE_AUTOMATION:
            return {
                ...state,
                automation: {
                    ...state.automation,
                    [action.payload.automation_id]: { ...action.payload },
                },
            };
        case actionTypes.AUTOMATION.UPDATE_AUTOMATION_LOCAL_STATE:
            return {
                ...state,
                automation: {
                    ...state.automation,
                    [action.payload.id]: { ...action.payload.data },
                },
            };
        case actionTypes.AUTOMATION.CLEAR_FETCHED_AUTOMATION_ITEMS:
            return {
                ...state,
                automations: {
                    ...state.automations,
                    offset: 0,
                    hasMore: true,
                    items: [],
                },
            };
        default:
            return state;
    }
};
