import React from "react";
import { useDispatch } from "react-redux";
import MenuList from "@/components/utils/menu-list";
import { Link } from "react-router-dom";
import { signOut } from "@/actions/auth";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PersonIcon from "@material-ui/icons/Person";
import HttpIcon from "@material-ui/icons/Http";
import KeyIcon from "@material-ui/icons/VpnKey";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PaymentIcon from "@material-ui/icons/Payment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    iconRoot: {
        fill: theme.palette.dashboard.dark,
    },
}));

const HeaderMenuDropDown = props => {
    const { open, closeMenu } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const currentLocation = location;

    return (
        <MenuList
            isOpen={open}
            id="profile-menu-dropdown"
            className="header-setting"
            onSettingClose={closeMenu}
        >
            <li>
                <Link
                    data-testid="account"
                    to={{
                        pathname: "/account",
                        state: { previousLocation: currentLocation.pathname },
                    }}
                    className="menulist-item"
                >
                    <span>اطلاعات کاربری</span>
                    <PersonIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                    />
                </Link>
            </li>
            <li>
                <Link
                    to={{
                        pathname: "/account/api",
                        state: { previousLocation: currentLocation.pathname },
                    }}
                    className="menulist-item"
                >
                    <span>وب‌سرویس - api</span>
                    <HttpIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                    />
                </Link>
            </li>
            <li>
                <Link
                    to={{
                        pathname: "/account/security",
                        state: { previousLocation: currentLocation.pathname },
                    }}
                    className="menulist-item"
                >
                    <span>امنیت حساب</span>
                    <VerifiedUserIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                    />
                </Link>
            </li>
            <li>
                <Link
                    to={{
                        pathname: "/account/credentials",
                        state: { previousLocation: currentLocation.pathname },
                    }}
                    className="menulist-item"
                >
                    <span>مدیریت شناسه‌ها</span>
                    <KeyIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                    />
                </Link>
            </li>
            <li>
                <Link
                    to={{
                        pathname: "/account/services",
                        state: { previousLocation: currentLocation.pathname },
                    }}
                    className="menulist-item"
                >
                    <span> پیامک و ایمیل</span>
                    <SettingsIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                    />
                </Link>
            </li>
            <li>
                <Link to="/financial" className="menulist-item">
                    <span>امور مالی</span>
                    <PaymentIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                    />
                </Link>
            </li>

            <li className="divide">
                <a
                    className="menulist-item menulist-item--red"
                    onClick={() => signOut()(dispatch)}
                >
                    <span>خروج</span>
                    <ExitToAppIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                    />
                </a>
            </li>
        </MenuList>
    );
};

export default HeaderMenuDropDown;
