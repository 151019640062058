export const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 65,
    legend: {
        display: true,
        position: "bottom",
        labels: {
            textAlign: "bottom",
            usePointStyle: true,
            boxWidth: 10,
            padding: 20,
        },
    },
};
