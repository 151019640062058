import React from "react";
import AndroidEmailWizard from "@/components/email/email/create/android/AndroidEmailWizard";
import { withEmailCredential } from "@/components/utilities/conf/withCredentialWrapper";
import WebEmailWizard from "@/components/email/email/create/web/WebEmailWizard";

const SendEmailPage = props => {
    const {
        match: { params },
        history,
    } = props;
    const PlatformWizard = PLATFORM_FORMS[params.platform];

    return <PlatformWizard history={history} />;
};

const PLATFORM_FORMS = {
    android: AndroidEmailWizard,
    web: WebEmailWizard,
};

export default withEmailCredential(SendEmailPage);
