/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Util from "@/utils";
import WayPoint from "react-waypoint";
import {
    Checkbox,
    CircleLoader,
    MoreVertMenu,
    thousandSeparatorFarsi,
} from "@/components/utilities";
import {
    PTable,
    PTableBody,
    PTableHeader,
    PTableItem,
    PTableRow,
} from "@/components/utilities/modules/PTable";
import { useFetchInvoiceItems } from "@/components/financial/hooks/useFetchInvoiceItems";
import LibraryBooks from "@material-ui/icons/LibraryAdd";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Send from "@material-ui/icons/Send";
import { InvoiceDetailsDialog } from "@/components/financial/partials/InvoiceDetailsDialog";
import { INVOICE_STATUS } from "@/components/financial/constants";
import { generateServiceName } from "@/components/financial/helpers";
import InvoiceUploadDialog from "@/components/financial/partials/InvoiceUploadDialog";
import InvoiceDownloadDialog from "@/components/financial/partials/InvoiceDownloadDialog";

const FinancialTable = props => {
    const { onSelectedItemsChanged } = props;
    const [selectedItems, updateSelectedItems] = useState([]);
    const [showUploadInvoice, setShowUploadInvoice] = useState(false);
    const [downloadInvoice, setDownloadInvoice] = useState({
        show: false,
        invoiceId: null,
    });

    const [loading, { items, hasMore }, handleFetchPaymentReceipts] =
        useFetchInvoiceItems();

    useEffect(() => {
        onSelectedItemsChanged(selectedItems);
    }, [selectedItems]);

    return (
        <>
            <PTable columns={7} mobileSizeMax={"1199px"}>
                <PTableHeader>
                    <PTableItem>ردیف</PTableItem>
                    <PTableItem expand={1.2}>تاریخ</PTableItem>
                    <PTableItem>نام سرویس</PTableItem>
                    <PTableItem>مبلغ (ریال)</PTableItem>
                    <PTableItem>وضعیت پرداخت</PTableItem>
                    <PTableItem>جزئیات هزینه</PTableItem>
                    <PTableItem>امکانات</PTableItem>
                </PTableHeader>

                <PTableBody>
                    {items.map((invoice, idx) => {
                        const invoiceId = invoice["invoice_id"];
                        const isNotPaidYet = invoice["status"] === 0;
                        const rowNumber = idx + 1;
                        return (
                            <PTableRow key={invoiceId}>
                                <PTableItem inMobileHeader>
                                    <div className="factorIdItem">
                                        {isNotPaidYet ? (
                                            <Checkbox
                                                onToggleCheckbox={isChecked => {
                                                    if (isChecked)
                                                        updateSelectedItems([
                                                            ...selectedItems,
                                                            invoice,
                                                        ]);
                                                    else {
                                                        updateSelectedItems(
                                                            selectedItems.filter(
                                                                i =>
                                                                    i[
                                                                        "invoice_id"
                                                                    ] !==
                                                                    invoiceId,
                                                            ),
                                                        );
                                                    }
                                                }}
                                                label={
                                                    <div className="factor-id fa-num">
                                                        {rowNumber}
                                                    </div>
                                                }
                                            />
                                        ) : (
                                            <div className="factor-id fa-num without-checkbox">
                                                {rowNumber}
                                            </div>
                                        )}
                                    </div>
                                </PTableItem>
                                <PTableItem expand={1.2} inMobileHeader>
                                    {Util._convertToLocalTimezone(
                                        invoice["created_at"],
                                    )}
                                </PTableItem>
                                <PTableItem
                                    mobileTitle="نام سرویس: "
                                    style={{ fontSize: 12 }}
                                >
                                    {generateServiceName(invoice["services"])}
                                </PTableItem>
                                <PTableItem mobileTitle="مبلغ: ">
                                    {thousandSeparatorFarsi(
                                        invoice["payable_amount"],
                                    )}
                                </PTableItem>
                                <PTableItem mobileTitle="وضعیت پرداخت: ">
                                    {INVOICE_STATUS[invoice.status]}
                                </PTableItem>
                                <PTableItem
                                    inMobileFooter
                                    // eslint-disable-next-line react/no-children-prop
                                    children={
                                        <InvoiceDetailsDialog
                                            buttonLabel="مشاهده"
                                            invoiceId={invoiceId}
                                        />
                                    }
                                    mobileChildren={
                                        <InvoiceDetailsDialog
                                            buttonLabel={
                                                <span>
                                                    جزئیات هزینه{" "}
                                                    <span>&#10229;</span>
                                                </span>
                                            }
                                            invoiceId={invoiceId}
                                        />
                                    }
                                />
                                <PTableItem inMobileFooter>
                                    <MoreVertMenu
                                        disabled
                                        options={[
                                            {
                                                label: "دانلود فاکتور",
                                                icon: SaveAlt,
                                                disabled: false,
                                                onClick: () =>
                                                    setDownloadInvoice(
                                                        _prevState => ({
                                                            show: true,
                                                            invoiceId:
                                                                invoiceId,
                                                        }),
                                                    ),
                                            },
                                            {
                                                label: "ثبت پرداخت آفلاین",
                                                icon: LibraryBooks,
                                                disabled: true,
                                                onClick: () => {},
                                            },
                                            {
                                                label: "ارسال اصل فاکتور",
                                                icon: Send,
                                                disabled: true,
                                                onClick: () =>
                                                    setShowUploadInvoice(true),
                                            },
                                        ]}
                                    />
                                </PTableItem>
                            </PTableRow>
                        );
                    })}

                    <div className="financial__loader">
                        {loading && <CircleLoader size={30} />}
                        {!loading &&
                            !hasMore &&
                            (!Array.isArray(items) || items.length === 0) && (
                                <p>اطلاعات جدیدی وجود ندارد</p>
                            )}
                    </div>

                    <WayPoint
                        onEnter={handleFetchPaymentReceipts}
                        scrollableAncestor={window}
                    />
                </PTableBody>
            </PTable>
            {showUploadInvoice && (
                <InvoiceUploadDialog
                    //app={app}
                    onClose={() => setShowUploadInvoice(false)}
                    onAccept={_values => {}}
                />
            )}
            {downloadInvoice.show && (
                <InvoiceDownloadDialog
                    invoiceId={downloadInvoice.invoiceId}
                    onClose={() =>
                        setDownloadInvoice(_prevState => ({
                            show: false,
                            invoiceId: null,
                        }))
                    }
                    onAccept={_values => {}}
                />
            )}
        </>
    );
};

export default FinancialTable;
