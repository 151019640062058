import React from "react";
import css from "authentication/styles/forgotpass.module.scss";
import { Link } from "react-router-dom";

function ForgotPassSuccess() {
    return (
        <>
            <div className={css.resetSuccess}>
                لینکی برای تغییر رمز عبور به ایمیل شما فرستاده شد.
            </div>

            <Link className={css.goToSignInPage} to={"/signin"}>
                بازگشت به صفحه ورود
            </Link>
        </>
    );
}

export default ForgotPassSuccess;
