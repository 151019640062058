import React from "react";
import {
    ImageUploader,
    UPLOAD_IMAGE_TYPE,
    withErrorCatcher,
} from "@/components/utilities";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import Modal from "@/components/utilities/commons/modal";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { uploadFile } from "@/actions/app";
import { useDispatch, useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { fetchProfile } from "@/actions/auth";

const ProfileImageUploadModal = props => {
    const dispatch = useDispatch();

    const { closeModal, change } = props;
    const { form } = useReduxForm();
    const picture = useSelector(state => getFormValues(form)(state)?.picture);

    return (
        <Modal onModalClose={closeModal} className={"modal--image-profile"}>
            <div className="modal-header">تغییر پروفایل</div>

            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12" style={{ height: "240px" }}>
                        <div className="upload-pro-field">
                            <ImageUploader
                                uploadFile={(...args) =>
                                    uploadFile(...args)(dispatch)
                                }
                                title="انتخاب عکس"
                                subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                type={UPLOAD_IMAGE_TYPE.PICTURE}
                                imageUrl={picture}
                                onRemoveFile={() =>
                                    change(form, "picture", null)
                                }
                                onFileUploaded={uploadedUrl =>
                                    change("picture", uploadedUrl)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <FlatButton
                    className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                    rippleColor={blue900}
                    label="تایید"
                    onClick={() => {
                        fetchProfile()(dispatch);
                        closeModal();
                    }}
                />
            </div>
        </Modal>
    );
};

export default withErrorCatcher(ProfileImageUploadModal);
