import { toFarsiNumbers } from "@/components/utilities";

/**
 * Accepts a number or a string of numbers and return separated with thousands
 *
 * @param num: number | string
 * @param separator string
 */
export function thousandSeparator(num, separator = ",") {
    if (typeof num === "undefined" || num === null) {
        return "";
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

/**
 * Accepts a number or a string of numbers and return separated with thousands and also translate numbers to Farsi
 *
 * @param num
 * @param separator
 */
export function thousandSeparatorFarsi(num, separator = ",") {
    const separated = thousandSeparator(num, separator);

    return toFarsiNumbers(separated);
}
