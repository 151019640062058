import React from "react";
import { useSelector } from "react-redux";
import IconButton from "material-ui/IconButton";
import { withRouter } from "react-router-dom";
import { Bell, QuestionGuide } from "@/components/utils/lib/svgicons";
import classNames from "classnames";

const Free = require("images/pricing/free.png");
const wallet = require("images/pricing/wallet.png");
import DefaultProfileImage from "images/default_user.png";

const HeaderButtons = props => {
    const { openMenu, openSidebarMobileView, openNotificationCenter, history } =
        props;

    const hasUnreadAlert = useSelector(state => state?.alerts?.unread ?? null);
    const userProfile = useSelector(state => state?.auth?.profile ?? {});

    const showPlanButton = false;
    const showCreditButton = false;

    return (
        <>
            {/* Mobile Button */}
            <button
                className="nav-btn"
                id="mobile-nav-button"
                onClick={openSidebarMobileView}
            >
                <span />
                <span />
                <span />
            </button>
            {/* -- End of Mobile Button */}

            <div className="header-tools" id="headerMenus">
                {/* Plans button */}
                {showPlanButton && (
                    <IconButton
                        className="header-btn"
                        data-tip={``}
                        onClick={() => {}}
                    >
                        <img
                            src={Free}
                            className="header-financial-image"
                            alt="plan button"
                        />
                    </IconButton>
                )}
                {/* -- End of Plans button */}

                {/* Financial credit & page button */}
                {showCreditButton && (
                    <div
                        className="header-pricing-container"
                        data-tip={``}
                        onClick={() => history.push("/financial")}
                    >
                        <IconButton className="header-btn">
                            <img
                                src={wallet}
                                className="header-pricing-wallet"
                                alt="financial button"
                            />
                        </IconButton>
                    </div>
                )}
                {/* -- End of Financial credit & page button */}

                {/* Docs Button */}
                <IconButton
                    className="header-btn"
                    data-tip="راهنما"
                    onClick={() => window.open("https://docs.pushe.co")}
                >
                    <QuestionGuide viewBox="0 0 24 24" />
                </IconButton>
                {/* -- End of Docs Button */}

                {/* Notification Center Button */}
                <IconButton
                    className={classNames("header-btn bell-animate", {
                        "header-btn--active": hasUnreadAlert,
                    })}
                    data-tip="پیام‌ها"
                    onClick={openNotificationCenter}
                >
                    <Bell viewBox="0 0 25 25" />
                </IconButton>
                {/* --End of Notification Center Button */}

                {/* User Profile Button */}
                <button
                    id="profile-button"
                    className="header-avatar"
                    style={{
                        backgroundImage: `url(${
                            userProfile?.picture ?? DefaultProfileImage
                        })`,
                    }}
                    data-tip={userProfile?.email ?? "حساب کاربری"}
                    onClick={openMenu}
                ></button>
                {/* -- End of User Profile Button */}
            </div>
        </>
    );
};

export default withRouter(HeaderButtons);
