import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    all: {
        historical: [],
        distributions: [],
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.DASHBOARD.FETCH_WEB_DASHBOARD_DATA:
            return {
                ...state,
                [action.payload.app_id]: action.payload,
            };
    }
    return state;
}
