/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { errorToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";

const BOX_STATES = {
    PLACEHOLDER: 1,
    FILE: 2,
};

/**
 * File Uploader
 *
 * @param props
 * @constructor
 */
const FileUploader = ({ ...props }) => {
    const {
        accept,
        validateFile,
        onFileSelected,
        onFileDeleted,
        multiple = false,
        maxSize = 1000000,
        title = "انتخاب فایل",
        subtitle = "فایل را به اینجا بکشید و یا روی اینجا کلیک کنید",
        hasInitFile = false,
        inputAcceptFiles = "",
    } = props;

    // states
    const [file, saveFile] = useState(null);
    const [boxState, setBoxState] = useState(BOX_STATES.PLACEHOLDER);

    useEffect(() => {
        if (onFileSelected && file) {
            onFileSelected(file);
        }
    }, [file]);

    useEffect(() => {
        if (hasInitFile && boxState !== BOX_STATES.FILE)
            setBoxState(BOX_STATES.FILE);
    }, []);

    // Dropzone"s hook
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        maxSize,
        multiple,
        disabled: boxState !== BOX_STATES.PLACEHOLDER,
        onDropAccepted: files => {
            if (!validateFile(files[0])) {
                return;
            }
            saveFile(files[0]);
            setBoxState(BOX_STATES.FILE);
        },
        onDropRejected: files => {
            // MaxSize exceed error
            if (files[0].size > maxSize) {
                errorToast(
                    TOAST_CONTENT.FILE_SIZE_EXCEEDED.TITLE,
                    TOAST_CONTENT.FILE_SIZE_EXCEEDED.DESC,
                );
                return;
            }
            // accept type error
            if (!!accept) {
                if (
                    (Array.isArray(accept) &&
                        accept.indexOf(files[0].type) < 0) ||
                    files[0].type !== accept
                ) {
                    errorToast(
                        TOAST_CONTENT.FILE_SELECT_ERROR.TITLE,
                        TOAST_CONTENT.FILE_SELECT_ERROR.DESC,
                    );
                }
            }
        },
    });

    /**
     * Called in Image mode
     *
     * 1. Delete file from inner state
     * 2. Change Box state to BOX_STATE_PLACEHOLDER
     */
    const onDelete = () => {
        saveFile(null);
        setBoxState(BOX_STATES.PLACEHOLDER);
        if (typeof onFileDeleted === "function") onFileDeleted();
    };

    return (
        <div
            {...getRootProps({
                className: classNames(
                    "file-upload",
                    {
                        "file-upload--is-placeholder":
                            boxState === BOX_STATES.PLACEHOLDER,
                    },
                    { "file-upload--is-image": boxState === BOX_STATES.FILE },
                ),
            })}
        >
            <input {...getInputProps()} accept={inputAcceptFiles} />

            {/* PLACEHOLDER */}
            {boxState === BOX_STATES.PLACEHOLDER && (
                <div className="file-upload__placeholder">
                    <i className="file-upload__dashed-box" />
                    <p className="file-upload__title">{title}</p>
                    {!!subtitle && (
                        <p className="file-upload__subtitle">{subtitle}</p>
                    )}
                </div>
            )}

            {/* Uploaded File */}
            {boxState === BOX_STATES.FILE && (
                <div onClick={onDelete}>
                    <p>فایل انتخاب شده</p>
                    <p>{!!file ? file.name : undefined}</p>
                    <p style={{ fontSize: ".7rem", marginTop: "20px" }}>
                        برای تغییر فایل اینجا کلیک کنید
                    </p>
                </div>
            )}
        </div>
    );
};

export { FileUploader };
