import React from "react";
import Util from "@/utils";
import Colors from "@/components/utils/lib/colors";
import isEqual from "lodash/isEqual";
import { humanizeToEnglish } from "@/components/utilities";
import { CircleLoader } from "@/components/utilities";

export default class BaseChart extends React.Component {
    constructor(props) {
        super(props);
    }

    static sortDataBaseDate(data = []) {
        // eslint-disable-next-line no-prototype-builtins
        if (!data.constructor.prototype.hasOwnProperty("sort")) return data;

        const compare = (a, b) => {
            if (a.date > b.date) return 1;
            if (a.date < b.date) return -1;
            return 0;
        };

        return data.sort(compare);
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(
            BaseChart.sortDataBaseDate(this.props.data),
            BaseChart.sortDataBaseDate(nextProps.data),
        );
    }

    getDates() {
        return BaseChart.sortDataBaseDate(this.props.data).map(value => {
            return value["date"]
                ? Util._convertToLocalTimezone(value["date"]).split(" ")[1]
                : "";
        });
    }

    seriesOfDaily() {
        let data = BaseChart.sortDataBaseDate(this.props.data);

        let uninstalls = {
            name: "حذف روزانه",
            color: Colors.orange,
            data: Array.isArray(data)
                ? data.map(i =>
                      "last_uninstalls" in i ? i["last_uninstalls"] : null,
                  )
                : [],
        };

        let installs = {
            name: "نصب روزانه",
            color: Colors.green_0,
            data: Array.isArray(data)
                ? data.map(i =>
                      "last_installs" in i ? i["last_installs"] : null,
                  )
                : [],
        };

        return [uninstalls, installs];
    }

    seriesOfAll() {
        let data = BaseChart.sortDataBaseDate(this.props.data);

        let inAccessUsers = {
            name: "کاربران دردسترس",
            data: Array.isArray(data)
                ? data.map(i =>
                      "transactional" in i ? i["transactional"] : null,
                  )
                : [],
            color: Colors.orange,
        };

        let activeInstallations = {
            name: "نصب های دردسترس",
            data: Array.isArray(data)
                ? data.map(i =>
                      "transactional_installs" in i
                          ? i["transactional_installs"]
                          : null,
                  )
                : [],
            color: Colors.blue_0,
        };

        let allInstallations = {
            name: "تمامی نصب ها",
            data: Array.isArray(data)
                ? data.map(i => ("total" in i ? i["total"] : null))
                : [],
            color: Colors.green_0,
        };

        return [[inAccessUsers, activeInstallations], allInstallations];
    }

    getSeries(type) {
        switch (type) {
            case "dailyInstallations":
                return this.seriesOfDaily();
            case "allInstallations":
                return this.seriesOfAll();
        }
    }

    getPlotOptions() {
        return {};
    }

    getToolTipOptions() {
        const config = {
            useHTML: true,
            style: {
                padding: 10,
                textAlign: "right",
                direction: "rtl",
                color: Colors.gray_8,
            },
        };

        if (this.tooltipFormatter) {
            config.formatter = this.tooltipFormatter;
        }

        return config;
    }

    getChartColors() {
        if (this.chartColors) {
            return this.chartColors;
        }

        return [
            Colors.orange,
            Colors.blue_8,
            Colors.pink_f,
            Colors.green_b,
            Colors.purple_9,
            Colors.purple_f,
            Colors.purple_b,
            Colors.green_4,
        ];
    }

    chartsCommonConfigs(series) {
        const labelStyle = {
            color: Colors.gray_b,
            fontFamily: "iranyekanwebbold",
        };
        const tooltipStyle = {
            padding: 10,
            textAlign: "right",
            color: Colors.gray_8,
            ontFamily: "iranyekanwebbold",
        };
        const legendStyle = {
            margin: 10,
            color: Colors.gray_b,
            transition: "all 0.2s",
            ontFamily: "iranyekanwebbold",
        };

        return {
            credits: {
                enabled: false,
            },
            colors: [Colors.green, Colors.blue, Colors.orange, Colors.red],
            title: {
                text: "",
            },
            yAxis: {
                title: {
                    text: "",
                },
                labels: {
                    style: { ...labelStyle },
                    formatter() {
                        return humanizeToEnglish(this.value);
                    },
                },
            },
            xAxis: {
                crosshair: true,
                labels: {
                    style: { ...labelStyle },
                },
                categories: this.getDates(),
            },
            tooltip: {
                useHTML: true,
                animation: true,
                followPointer: true,
                style: {
                    ...tooltipStyle,
                },
                formatter: function () {
                    const color = this.series.color;
                    const style = (() =>
                        `style="color:${color};font-family:iranyekanwebbold"`)();
                    const innerStyle = (() =>
                        `style=font-family:iranyekanwebbold`)();

                    return `
                        <span ${style}">${this.series.name}</span>
                        <br/>
                        <span ${innerStyle}>تعداد: ${this.y}</span>
                        <br/>
                        <span ${innerStyle}>تاریخ: ${this.x}</span>
                    `;
                },
            },
            legend: {
                useHTML: true,
                enabled: true,
                borderWidth: 0,
                rtl: true,
                itemStyle: {
                    ...legendStyle,
                },
                itemHoverStyle: {
                    color: Colors.gray_8,
                },
            },
            series,
        };
    }

    isLoading() {
        const { data = [] } = this.props;
        if (data.length >= 0) {
            return false;
        } else {
            return <CircleLoader size={30} />;
        }
    }
}
