
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Manifest = makeShortcode("Manifest");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`افزودن توکن`}</h1>
    <p>{`توکن پوشه بایستی به فایلی به نام
`}<inlineCode parentName="p">{`AndroidManifest.xml`}</inlineCode>{`
اضافه شود. این فایل را می‌توانید با استفاده از فعال‌کردن تیک
`}<strong parentName="p">{`CustomManifest`}</strong>{`
در بخش
Player Settings
به
Assets
اضافه‌کنید. اما در صورتی که نسخه‌ی یونیتی در حال استفاده این گزینه را نداشت می‌توانید فایل را از
`}<inlineCode parentName="p">{`Assets/Pushe/Templates`}</inlineCode>{`
به مسیر
`}<inlineCode parentName="p">{`Assets/Plugins/Android`}</inlineCode>{`
انتقال دهید.`}</p>
    <p>{`توکن مورد نظر خود را از کد زیر برداشته و به فایل
`}<inlineCode parentName="p">{`AndroidManifest`}</inlineCode>{`
ذکر شده اضافه کنید`}</p>
    <Manifest {...props} mdxType="Manifest">
      <pre><code parentName="pre" {...{
          "className": "language-xml"
        }}>{`<manifest>
    <application>
        <!-- ... rest of manifest ... -->
        <meta-data
            android:name="pushe_token"
            android:value="{{manifest}}" />

    </application>
</manifest>
`}</code></pre>
    </Manifest>
    <h1>{`دسترسی‌ها`}</h1>
    <p>{`در صورتی که قصد داشته باشید از امکانات ویژه‌ی پوشه همچون ارسال فیلتر استفاده کنید بایستی دسترسی‌های زیر را به مانیفست اضافه کنید. در غیر اینصورت این دسترسی‌ها اجباری نیستند.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<!-- ارسال اعلان بر اساس اپراتور -->
<uses-permission android:name="android.permission.ACCESS_NETWORK_STATE"/>

<!-- آمار موقعیت کاربران و ارسال اعلان بر اساس موقعیت -->
<uses-permission android:name="android.permission.ACCESS_COARSE_LOCATION"/>
<!-- ارسال اعلان جغرافیایی یا جئوفنس -->
<uses-permission android:name="android.permission.ACCESS_FINE_LOCATION"/>

<!-- Android 10 or higher -->
<uses-permission android:name="android.permission.ACCESS_BACKGROUND_LOCATION"/>
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;