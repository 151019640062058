import EmailTab from "@/components/email/email/list/android/tabs/EmailTab";
import DetailsTab from "@/components/email/email/list/android/tabs/DetailsTab";

export const tabs = [
    {
        label: "ایمیل",
        value: "email_mode",
        display: () => true,
        component: EmailTab,
        disabled: () => false,
        wrapperClassName: "card-list__custom-body--wrapper",
    },
    {
        label: "جزئیات",
        value: "details",
        display: () => true,
        component: DetailsTab,
        disabled: () => false,
        wrapperClassName:
            "card-list__custom-body--wrapper card-list__tab-details",
    },
];
