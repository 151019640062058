
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`افزودن پلاگین`}</h1>
    <blockquote>
      <h3 parentName="blockquote">{`برای مشاهده‌ی آموزش کامل به  `}<a parentName="h3" {...{
          "href": "https://docs.pushe.co/docs/unity/intro/"
        }}>{`مستندات پوشه برای `}<strong parentName="a">{`Unity`}</strong></a></h3>
    </blockquote>
    <ul>
      <li parentName="ul">{`کتابخانه پوشه برای `}<inlineCode parentName="li">{`Unity`}</inlineCode>{` را `}<a parentName="li" {...{
          "href": "https://static.pushe.co/d/unity/pushe-plus-unity-2.5.0.unitypackage"
        }}>{`از این‌جا`}</a>{` دانلود کنید.`}</li>
      <li parentName="ul">{`در پروژه یونیتی خود بر روی فولدر `}<inlineCode parentName="li">{`Assets`}</inlineCode>{` کلیک راست کنید و از
`}<inlineCode parentName="li">{`Import Package`}</inlineCode>{`
بر روی
`}<inlineCode parentName="li">{`Custom Package`}</inlineCode>{`
کلیک کنید و فایل پکیج دانلود شده بالا را انتخاب کنید.`}</li>
      <li parentName="ul">{`از `}<strong parentName="li">{`Gradle`}</strong>{` به عنوان build tool استفاده کنید.`}</li>
      <li parentName="ul">{`به دلیل تحریم بودن سرورهای گریدل، ابزار گذرازتحریم داشته باشید.`}</li>
      <li parentName="ul">{`بایستی
minSDKVersion
برابر ۱۷
(Android 4.2)
یا بالاتر باشد`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;