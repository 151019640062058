import React from "react";
import PropTypes from "prop-types";
import { UPLOAD_IMAGE_TYPE, ImageUploader } from "@/components/utilities";

export default class NotificationImageUpload extends React.Component {
    static propTypes = {
        uploadFile: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        type: PropTypes.oneOf(["image", "icon", "prompt-icon"]),
        aspectRatio: PropTypes.number,
        toggleUploaderProgress: PropTypes.func,
    };

    static defaultProps = {
        subtitle: "",
        // NaN means withOut aspectRatio
        aspectRatio: NaN,
    };

    constructor(props) {
        super(props);

        this.state = {
            uploadedUrl: props.input.value,
        };
        this.emitChange();
    }

    componentDidUpdate(_prevProps, _props) {
        const value = this.props?.input?.value;
        if (this.state.uploadedUrl !== value) {
            this.setState({
                uploadedUrl: value,
            });
        }
    }

    emitChange() {
        this.props.input.onChange(this.state.uploadedUrl);
    }

    setUploadedUrl(url) {
        this.setState({ uploadedUrl: url }, this.emitChange.bind(this));
    }

    render() {
        return (
            <ImageUploader
                type={UPLOAD_IMAGE_TYPE.ICON}
                uploadFile={this.props.uploadFile}
                onFileUploaded={url => this.setUploadedUrl(url)}
                onRemoveFile={() => {
                    this.setState({
                        uploadedUrl: "",
                    });
                    this.setUploadedUrl("");
                }}
                aspectRatio={this.props.aspectRatio}
                title={this.props.title}
                subtitle={this.props.subtitle}
                imageUrl={this.state.uploadedUrl}
                toggleUploaderProgress={this.props.toggleUploaderProgress}
            />
        );
    }
}
