/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Field, getFormValues } from "redux-form";
import FilterSelectionField from "@/components/utils/FilterSelectionField";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationTopics } from "@/actions/applications";
import { store } from "@/store";

const NotificationTopicsFilterTab = () => {
    const dispatch = useDispatch();
    const { form } = useReduxForm();
    const { app_ids } = useSelector(state => getFormValues(form)(state) ?? {});
    const appTopics = useSelector(state => state?.inprog?.appTopics ?? []);

    const addLabelIntoArray = array =>
        array.map(item => ({ name: item, key: item }));

    useEffect(() => {
        // Fetch topics on componentDidMount
        const app_id = Array.isArray(app_ids) ? app_ids[0] : app_ids;
        if (!app_id) return;

        getApplicationTopics({ app_id })(dispatch, store.getState);
    }, []);

    return (
        <Field
            title="تاپیک"
            name="topics"
            placeholder="نام تاپیک را وارد کنید ..."
            isCreatable={true}
            component={FilterSelectionField}
            list={addLabelIntoArray(appTopics)}
        />
    );
};

export default NotificationTopicsFilterTab;
