import React, { Component } from "react";
import ReactDOM from "react-dom";
import FlatButton from "material-ui/FlatButton";
import Modal from "@/components/utilities/commons/modal";
import { red900, blue900, black } from "material-ui/styles/colors";
import { MODAL_THEME } from "@/actions/_types";

const uuid = require("uuid/v4");
import PropTypes from "prop-types";

class BaseModal extends Component {
    constructor(props) {
        super(props);
        this.themeTypes = {
            [MODAL_THEME.RED]: red900,
            [MODAL_THEME.BLUE]: blue900,
            [MODAL_THEME.BLACK]: black,
        };
    }

    renderModalButtons() {
        const {
            theme = MODAL_THEME.BLUE,
            btnType = "double",
            acceptLabel = "قبول",
            rejectLabel = "انصراف",
        } = this.props;

        const modalTheme = this.themeTypes[theme];

        return btnType === "double" ? (
            <div>
                <FlatButton
                    className={`mui-btn mui-btn--md mui-btn--solid mui-btn--${theme}`}
                    rippleColor={modalTheme}
                    label={acceptLabel}
                    disabled={this.props.isLoading}
                    onClick={() => this.props.onAccept()}
                />
                <FlatButton
                    className={`mui-btn mui-btn--md mui-btn--border mui-btn--${theme}-white`}
                    rippleColor={modalTheme}
                    label={rejectLabel}
                    onClick={() =>
                        !this.props.onReject
                            ? this.props.onClose()
                            : this.props.onReject()
                    }
                />
            </div>
        ) : (
            <div>
                <FlatButton
                    className={`mui-btn mui-btn--md mui-btn--border mui-btn--${theme}-white`}
                    rippleColor={modalTheme}
                    label={acceptLabel}
                    onClick={() =>
                        !this.props.onReject
                            ? this.props.onClose()
                            : this.props.onReject()
                    }
                />
            </div>
        );
    }

    renderErrors() {
        let { error } = this.props;

        if (error !== null && error !== undefined) {
            if (typeof error === "string") error = { error };
            const errors = Object.entries(error).map(item => {
                const title = item[1];
                return `${title}`;
            });
            return errors.map(key => <div key={uuid()}>{key}</div>);
        }
    }

    render() {
        const {
            customClass,
            footer,
            desc,
            body,
            children,
            modalSize = "sm",
            useDefaultFooter = false,
        } = this.props;

        const defualtModalClass = "modal--default";
        const className = `modal--${modalSize} ${
            customClass || defualtModalClass
        }`;

        const parentElem = document.getElementById("mainWrap");

        // Use portal to have modals always in top level
        // and prevent css overrides on modals
        return (
            parentElem &&
            ReactDOM.createPortal(
                <Modal
                    onModalClose={() => this.props.onClose()}
                    className={className}
                    isModalInside={true}
                >
                    <div className="modal-header">
                        {<p>{this.props.title}</p>}
                    </div>
                    <div className="modal-desc">{desc}</div>
                    <div className="modal-body">{body}</div>
                    <div className="modal-footer">
                        {useDefaultFooter ? this.renderModalButtons() : footer}
                    </div>
                    <div className="send-limit__footer">
                        <div className="color-error">{this.renderErrors()}</div>
                    </div>
                    {children}
                </Modal>,
                parentElem,
            )
        );
    }
}

BaseModal.propTypes = {
    title: PropTypes.any,
    desc: PropTypes.any,
    body: PropTypes.any,
    useDefaultFooter: PropTypes.bool,
    acceptLabel: PropTypes.string,
    rejectLabel: PropTypes.string,
    customClass: PropTypes.any,
    theme: PropTypes.string,
    error: PropTypes.any,
    footer: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
    modalSize: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
    btnType: PropTypes.oneOf(["single", "double"]),
    children: PropTypes.node,
    isLoading: PropTypes.bool,
};

export default BaseModal;
