import React from "react";
import css from "authentication/styles/noafarinSignUp.module";
import logo from "images/svg/pushe-logo-horizontal.svg";
import { Field } from "redux-form";
import {
    CircleLoader,
    FlatButton,
    ReduxSimpleTextField,
} from "@/components/utilities";
import NotRegisteredInNoafarin from "authentication/noafarin/partials/NotRegisteredInNoafarin";

function NoAfarinStartStep(props) {
    const { handleSubmit, inProgress, error } = props;

    return (
        <>
            <div className={css.pusheLogo}>
                <img src={logo} alt={"pushe logo"} />
            </div>

            <div className={css.bodyWrapper}>
                <p>
                    برای ورود از این طریق باید از قبل در سامانه نوآفرین عضو شده
                    باشید.
                </p>

                <p>
                    به شماره موبایلی که در سامانه ایران نوآفرین ثبت کرده اید،
                    رمز یکبار مصرف ارسال می‌شود.
                </p>

                <div className={css.formWrapper}>
                    <Field
                        autoFocus
                        name="innovative_code"
                        label={"کد نوآفرین"}
                        required
                        component={ReduxSimpleTextField}
                        onKeyPress={e => e.charCode === 13 && handleSubmit()}
                    />
                </div>

                <div className={css.buttonWrapper}>
                    <FlatButton
                        fullWidth
                        label={"ارسال پیامک"}
                        onClick={handleSubmit}
                        endIcon={inProgress ? <CircleLoader /> : null}
                        disabled={inProgress}
                    />
                </div>

                {error && <p className={css.error}>{error}</p>}

                <NotRegisteredInNoafarin />
            </div>
        </>
    );
}

export default NoAfarinStartStep;
