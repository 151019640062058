import React, { useEffect, useState } from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
    FLAT_BUTTON_TYPE,
    FlatButton,
    SimpleTextField,
    withErrorCatcher,
} from "@/components/utilities";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(_theme => ({
    saveContentTextRoot: {
        textAlign: "right",
        fontSize: ".9rem",
        color: "#000",
        padding: "5px 20px",
        marginBottom: 0,
    },
}));

const RenameFaveItemComponent = props => {
    const { onToggle, updateItemMutation, isLoading, data } = props;
    const { name = null } = data?.favorite_data;
    const { id } = data;
    const classes = useStyles();

    const [names, setNames] = useState("");
    const [nameError, setNameError] = useState("");
    useEffect(() => {
        setNames(name);
    }, [name]);

    const handleSaveAction = async () => {
        if (names) {
            await updateItemMutation(
                { names, id },
                {
                    onSuccess: () => {
                        setNames("");
                        onToggle(false);
                    },
                },
            );
        } else {
            if (!names) setNameError("این فیلد اجباری است");
        }
    };

    return (
        <DialogContent>
            <DialogContentText classes={{ root: classes.saveContentTextRoot }}>
                نام ای که می‌خواهید به عنوان دستگاه مورد علاقه ذخیره کنید را
                وارد کنید و دکمه ذخیره را بفشارید.
            </DialogContentText>
            <SimpleTextField
                margin="dense"
                id="name"
                label="نام"
                error={nameError}
                helperText={nameError}
                type="text"
                fullWidth
                value={names}
                onChange={e => {
                    if (nameError) setNameError("");
                    setNames(e.target.value);
                }}
                onKeyPress={e => e.charCode === 13 && handleSaveAction()}
            />
            <FlatButton
                label="بی‌خیال"
                fullwidth
                buttonType={FLAT_BUTTON_TYPE.GRAY}
                onClick={() => {
                    setNames("");
                    onToggle(false);
                }}
            />
            <FlatButton
                label="ذخیره"
                fullwidth
                disabled={isLoading}
                onClick={handleSaveAction}
            />
        </DialogContent>
    );
};

export default withErrorCatcher(RenameFaveItemComponent);
