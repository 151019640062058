import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import {
    FRAMEWORK_CAST_NAME_INTO_VALUE,
    FRAMEWORK_CAST_VALUE_INTO_NAME,
} from "@/actions/_types";
import xcodeSteps from "application/guides/ios/xcode/steps";
import reactNativeSteps from "application/guides/ios/reactNative/steps";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import flutterSteps from "application/guides/ios/flutter/steps";

const IosGuideWizard = props => {
    const {
        application: { framework },
        application = {},
        history,
        applications,
        installs,
        retrieveInstall,
    } = props;
    let steps = stepsMap[framework];

    let title = `مشاهده راهنمای (${FRAMEWORK_CAST_VALUE_INTO_NAME[framework]})`;
    let subtitle = "راهنمای گام به گام ساخت اپلیکیشن";

    return (
        <BaseWizard
            formName="guide_wizard"
            title={title}
            subtitle={subtitle}
            history={history}
            steps={steps}
            footer={WizardFooter}
            buttonOptions={{
                submitButtonName: "بستن",
                submitButtonAction: () => history.goBack(),
            }}
            passToSteps={{
                installs,
                application,
                applications,
                retrieveInstall,
            }}
        />
    );
};

const stepsMap = {
    [FRAMEWORK_CAST_NAME_INTO_VALUE["Xcode"]]: xcodeSteps,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["ReactNative"]]: reactNativeSteps,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["Flutter"]]: flutterSteps,
};

export default withErrorCatcher(IosGuideWizard);
