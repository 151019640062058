import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "@/actions/dashboard";
import { store } from "@/store";

export const useWebDashboard = () => {
    const [onLoading, setOnLoading] = useState(false);
    const { platform, appId } = useSelector(state => state?.app);
    const dispatch = useDispatch();
    const fetchAllData = useCallback(() => {
        const callback = () => setOnLoading(false);
        setOnLoading(true);
        fetchDashboardData(callback, callback)(dispatch, store.getState);
    }, [dispatch]);
    useEffect(() => {
        fetchAllData();
    }, [appId, fetchAllData]);
    const dashboardData = useSelector(state =>
            platform ? state[platform].dashboard : null,
        ),
        NewData = (appId, dashboardData) => {
            if (!platform) {
                return [];
            } else if (appId) {
                return dashboardData[appId];
            } else {
                return dashboardData["all"];
            }
        };

    return {
        platform,
        data: NewData(appId, dashboardData) || {},
        allData: NewData(null, dashboardData),
        onLoading,
    };
};
