import React from "react";
import Web from "application/list/web/WebApplicationList";
import Ios from "application/list/ios/IosApplicationList";
import { withErrorCatcher } from "@/components/utilities";

const ApplicationListPage = ({
    match: {
        params: { platform },
    },
    history,
}) => {
    const Component = APPLICATIONS_PAGE_PLATFORM[platform];

    return <Component platform={platform} history={history} />;
};

const APPLICATIONS_PAGE_PLATFORM = {
    web: Web,
    ios: Ios,
};

export default withErrorCatcher(ApplicationListPage);
