import { Field } from "redux-form";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { JsonEditorField } from "@/components/utils/components/JsonEditorField";

const NotificationJsonComponent = () => {
    return (
        <div className="notif-block">
            <header>
                <p>درج کد JSON</p>
                <span>اطلاعات JSON به کاربران نمایش داده نمی‌شود</span>
            </header>
            <div className="notif-block__body">
                <Field
                    component={JsonEditorField}
                    required
                    fontSize={14}
                    name="custom_content"
                />
            </div>
        </div>
    );
};

export default withErrorCatcher(NotificationJsonComponent);
