import axios from "axios";
import { store } from "./store";
import { handleErrors } from "@/actions/_handleErrors";
import myStorage from "@/myStorage";
import { displayPermissionErrorModal, reloadingPage } from "@/actions/app";
import { debounce } from "lodash";
import { generateGetParams } from "@/actions/_utils";
import { getConfigs } from "@/index";

async function getApiUrl(apiName) {
    const config = await getConfigs();
    return config.api[apiName];
}

const MERGABLE_METHODS = ["get", "options"];
const inflightRequests = {};

const updateDebouncer = debounce(
    () => {
        store.dispatch(reloadingPage(true));
    },
    5 * 60000,
    { trailing: true, maxWait: 5 * 60000 },
);

class Api {
    /**
     * @param baseUrl Either a full url (starting with http) or a key to use to get the url from config.api
     * @param isPublic If true then authentication headers will not be sent with the requests
     */
    constructor({ baseUrl, isPublic = false } = {}) {
        this.options = {
            baseUrl,
            isPublic,
        };
        this.demo = store.getState().app.demo;
    }

    getHeaders() {
        let headers = {};

        if (this.options.isPublic) {
            return {};
        } else if (this.demo) {
            headers["Authorization"] = "Token demo";
        } else {
            const token =
                myStorage.getItem("token") ||
                (store.getState().auth || {}).token;
            if (token && token === "demo") {
                headers["Authorization"] = `Token ${token}`;
            } else if (token && token !== "undefined") {
                headers["Authorization"] = `JWT ${token}`;
            }
        }

        return headers;
    }

    getMergeKey(type, url, data, baseUrl) {
        if (data && Object.keys(data) > 0) {
            return null;
        }

        if (MERGABLE_METHODS.indexOf(type.toLowerCase()) < 0) {
            return null;
        }

        return `${type.toLowerCase()}#${baseUrl}${url}`;
    }

    /*
     *  In response of every request get server version from header
     *  and compare it to UI build ID version, if server version greater than UI_VERSION,
     *  render ReloadingPage modal in layout.jsx
     */
    compareUIVersion(header = {}) {
        try {
            if (
                header["console_version"] &&
                parseInt(header["console_version"]) > parseInt(UI_VERSION)
            ) {
                updateDebouncer();
            }
        } catch (e) {
            console.warn("Comparison version warning ...", e);
        }
    }

    async method(
        type,
        url,
        data,
        { baseUrl, params, headers: customHeaders } = {},
    ) {
        let option = { method: type, url, params, customHeaders };

        if (data) {
            option = { method: type, url, data, customHeaders };
        }

        baseUrl = baseUrl || this.options.baseUrl;
        if (baseUrl && !baseUrl.startsWith("http")) {
            baseUrl = await getApiUrl(baseUrl);
        }

        let headers = this.getHeaders();

        const mergeKey = this.getMergeKey(type, url, data, baseUrl);

        if (mergeKey && inflightRequests[mergeKey]) {
            return inflightRequests[mergeKey];
        }

        const promise = new Promise((resolve, reject) => {
            let axiosInstance = axios.create({
                baseURL: baseUrl,
                timeout: 600000,
            });

            axiosInstance.interceptors.response.use(
                this.onSuccessResponse,
                this.onFailedResponse,
            );

            let result = axiosInstance.request({
                ...option,
                headers: {
                    ...headers,
                    ...option.customHeaders,
                },
                xsrfHeaderName: "X-CSRFToken",
                xsrfCookieName: "csrftoken",
            });

            return result
                .then(resp => {
                    this.compareUIVersion(resp.headers);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                    handleErrors(err);
                });
        });

        if (mergeKey) {
            inflightRequests[mergeKey] = promise;

            promise.finally(() => {
                delete inflightRequests[mergeKey];
            });
        }

        return promise;
    }

    post(url, data, options) {
        return this.method("post", url, data, options);
    }

    get(url, options) {
        return this.method("get", url, null, options);
    }

    put(url, data, options) {
        return this.method("put", url, data, options);
    }

    patch(url, data, options) {
        return this.method("patch", url, data, options);
    }

    delete(url, options) {
        return this.method("delete", url, null, options);
    }

    onSuccessResponse(response) {
        if (
            (store.getState()?.app?.permissionError?.errno ?? -1) > -1 &&
            response?.config?.url !== "auth/profile/"
        ) {
            displayPermissionErrorModal({ errno: -1, errmsg: "" })(
                store.dispatch,
            );
        }

        return response;
    }

    onFailedResponse(error) {
        const is_403 = error?.response?.status === 403;
        const errorData = error?.response?.data?.detail ?? {};
        const errno = parseInt(errorData?.errno ?? -1);
        const errmsg = errorData?.errmsg;

        if (is_403 && parseInt(errno) > -1) {
            if ((store.getState()?.app?.permissionError?.errno ?? -1) === -1) {
                displayPermissionErrorModal({ errno, errmsg })(store.dispatch);
            }
        }

        return Promise.reject(error);
    }
}

class CommonApi extends Api {
    platform = null;

    constructor(options) {
        super({ baseUrl: "root", ...(options || {}) });
    }

    // Authentications
    signIn = data => this.post(`/auth/`, data);
    signInDeskPro = data => this.post(`/auth/signindeskpro/`, data);
    forgotPassword = data => this.post(`/resetpassword/`, data);
    resetPassword = (token, data) =>
        this.post(`/resetpassword/${token}/done/`, data);
    googleSignIn = async () =>
        (window.location = `${await getApiUrl(
            "root",
        )}oauth2login/authorization/`);
    noafarinStart = data => this.post(`/auth/noafarin/start/`, data);
    noafarinSignUp = data => this.post(`/auth/noafarin/signup/`, data);
    noafarinSignIn = data => this.post(`/auth/noafarin/signin/`, data);
    updateConsoleSettings = data => this.patch(`/auth/profile/0`, data);

    // Todo: this is not general and only works for metrix
    otpAuth = token => this.post(`/metrix/auth/otp-auth/${token}/verify`);

    // Credentials
    retrieveCredentials = () => this.get(`credentials/`);
    createCredential = data => this.post(`credentials/`, data);
    deleteCredential = id => this.delete(`credentials/${id}`);

    // config set up email
    fetchConfigSetUpEmailAPI = () => this.get("email/credentials/");
    updateConfigSetUpEmailAPI = (id, data) =>
        this.patch(`email/credentials/${id}`, data);
    crateConfigSetUpEmailAPI = data => this.post(`email/credentials/`, data);

    // config set up SMS
    fetchConfigSetUpSMSAPI = () => this.get("sms/credentials/");
    updateConfigSetUpSMSAPI = (id, data) =>
        this.patch(`sms/credentials/${id}`, data);
    crateConfigSetUpSMSAPI = data => this.post(`sms/credentials/`, data);

    // notification API
    createNotification = data => this.post("messaging/notifications", data);
    createNotificationTest = data =>
        this.post(
            `messaging/notifications/${this.platform}/send_test_message/`,
            data,
        );
    getNotification = notificationId =>
        this.get(`messaging/notifications/${notificationId}`);
    fetchNotifications = params =>
        this.get(`messaging/notifications/${generateGetParams(params)}`);

    // draft API
    /* Create-draft endpoint is the same as the one for creating notification with one
       extra parameter being `is_draft:boolean` */
    sendNotificationDraft = data =>
        this.post("messaging/notifications/send-draft", data);
    updateNotificationDraft = data =>
        this.post("messaging/notifications/update-draft", data);

    // application API
    createApplication = data =>
        this.post(`applications/${this.platform}`, data);
    getApplications = () => this.get(`applications/${this.platform}`);
    getApplication = ({ appId }) =>
        this.get(`applications/${this.platform}/${appId}`);
    destroyApplication = app_id =>
        this.delete(`applications/${this.platform}/${app_id}`);
    updateApplication = ({ app_id, data }) =>
        this.patch(`applications/${this.platform}/${app_id}`, data);

    //HMS Credential create
    createHMSCredential = (app_id, data) =>
        this.patch(`applications/android/${app_id}`, data);

    // installation API
    fetchFavorites = data =>
        this.get(`favorites/${this.platform}/${generateGetParams(data)}`);

    refreshApiToken = () => this.post(`auth/profile/token`);
    FetchUserRecipients = segment_id =>
        this.get(`/plus/segmentation/${segment_id}/recipients`);

    retrieveAutomationList = params =>
        this.get(`plus/automation/notification/`, { params });
    retrieveAutomation = automationId =>
        this.get(`plus/automation/notification/${automationId}`);
    createAutomation = data => this.post(`plus/automation/notification/`, data);
    updateAutomation = (automationId, data) =>
        this.patch(`plus/automation/notification/${automationId}`, data);
    deleteAutomation = automationId =>
        this.delete(`plus/automation/notification/${automationId}`);
    exportAutomation = automationId =>
        this.post(`plus/automation/notification/${automationId}/export/`, {});

    retrieveGeofenceList = params => this.get(`plus/geofence/`, { params });
    retrieveGeofence = geofenceId => this.get(`plus/geofence/${geofenceId}`);
    createGeofence = data => this.post(`plus/geofence/`, data);
    updateGeofence = (geofenceId, data) =>
        this.patch(`plus/geofence/${geofenceId}`, data);
    toggleEnableGeofence = (geofenceId, data) =>
        this.post(`plus/geofence/${geofenceId}/activation`, data);
    deleteGeofence = geofenceId => this.delete(`plus/geofence/${geofenceId}`);
    exportGeofence = geofenceId =>
        this.post(`plus/geofence/${geofenceId}/export/`, {});
    // Segmentation
    SegmentationTags = (platform, appId) =>
        this.get(`/applications/${platform}/${appId}/tags`);
    SuggestEventNames = (platform, appId) =>
        this.get(`/applications/${platform}/${appId}/events`);
    createSegmentation = data => this.post(`plus/segmentation/`, data);
    updateSegmentation = (segmentId, data) =>
        this.patch(`plus/segmentation/${segmentId}`, data);
    deleteSegmentation = segmentId =>
        this.delete(`plus/segmentation/${segmentId}`);
    fetchSegmentation = params => this.get(`plus/segmentation/`, { params });

    uploadFilterFile = data =>
        this.post(`files/csv/`, data, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    uploadPromptIcon = data =>
        this.post(`files/prompt-icon/`, data, {
            headers: { "Content-Type": "multipart/form-data" },
        });

    fetchInvoiceItems = params => this.get(`billing/invoice/`, { params });
    fetchInvoiceDetails = invoiceId =>
        this.get(`billing/invoice/${invoiceId}/ingredients/`);
    payInvoices = invoiceIds =>
        this.post(`billing/parsian-gateway/`, { invoice_ids: invoiceIds });

    retrieveEmailList = params => this.get(`messaging/email/`, { params });
    retrieveEmail = id => this.get(`messaging/email/${id}/`);
}

class AndroidApi extends CommonApi {
    platform = "android";

    estimateEmailRecipients = values =>
        this.post(`messaging/email/estimate`, values);
}

class IosApi extends CommonApi {
    platform = "ios";
}

class WebApi extends CommonApi {
    platform = "web";

    webpushTest = async data =>
        this.post("messaging/notifications/send_test_web_notification/", data);
    getWebPush = async path => this.get(path, { baseUrl: "webpush" });
    postWebPush = async (path, data) =>
        this.post(path, data, { baseUrl: "webpush" });
    deleteWebPush = async path => this.delete(path, { baseUrl: "webpush" });
    patchWebPush = async (path, data) =>
        this.patch(path, data, { baseUrl: "webpush" });

    // installation API
    fetchFavorites = data =>
        this.getWebPush(
            `favorites/${this.platform}/${generateGetParams(data)}`,
        );

    saveWebSettings = (appId, data) =>
        this.patch(`applications/${this.platform}/${appId}/`, data);
}

export const getApi = (platform = "android", options) => {
    switch (platform) {
        case "android":
            return new AndroidApi(options);
        case "ios":
            return new IosApi(options);
        case "web":
            return new WebApi(options);
        default:
            return new CommonApi(options);
    }
};

export const getCommonApi = options => new CommonApi(options);
export const getAndroidApi = options => new AndroidApi(options);
export const getWebApi = options => new WebApi(options);
export const getIosApi = options => new IosApi(options);
export const getMediaApi = options =>
    new Api({ baseUrl: "media", isPublic: true, ...(options || {}) });
