import React from "react";
import PropTypes from "prop-types";

const Loading = props => {
    const className = props.className ? ` ${props.className}` : "";

    return (
        <div className="loading">
            <div className={`loading-circle${className}`}></div>
        </div>
    );
};

export default Loading;

Loading.propTypes = {
    className: PropTypes.string,
};
