export const INVOICE_STATUS = {
    0: "پرداخت نشده",
    1: "پرداخت شده",
};

export const BANK_NAME = "بانک تجارت";

export const BANK_SHEBA_NUMBER = "IR 700180000000000306825933";

export const TAX_CERTIFICATE_LINK =
    "https://static.pushe.co/d/console/گواهی مالیات بر ارزش افزوده.pdf";

export const SERVICE_TYPES_TRANSLATION = {
    1: {
        starter: "شروع موبایل",
        growth: "رشد موبایل",
        pro: "حرفه‌ای موبایل",
        custom: "اختصاصی موبایل",
    },
    2: {
        starter: "شروع وب",
        pro: "حرفه‌ای وب",
        custom: "اختصاصی وب",
    },
    3: {
        new_growth: "سرویس رشد",
        new_pro: "سرویس حرفه‌ای",
        new_custom: "سرویس اختصاصی",
    },
};
