import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    notificationList: [],
    hasMore: true,
    limit: 20,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.NOTIFICATION.FETCH_ANDROID_NOTIFICATIONS:
            if (action.payload.initial) {
                return {
                    notificationList: action.payload.results,
                    hasMore: action.payload.results.length >= state.limit,
                    limit: state.limit,
                };
            }
            return {
                notificationList: [
                    ...state.notificationList,
                    ...action.payload.results.filter(val => {
                        if (state.notificationList.length)
                            for (
                                let idx = 0;
                                idx < state.notificationList.length;
                                idx++
                            )
                                if (
                                    state.notificationList[idx].wrapper_id ===
                                    val.wrapper_id
                                )
                                    return false;
                        return true;
                    }),
                ],
                hasMore: action.payload.results.length >= state.limit,
                limit: state.limit,
            };

        case actionTypes.NOTIFICATION.CREATE_ANDROID_NOTIFICATION:
            return {
                notificationList: [action.payload, ...state.notificationList],
                hasMore: state.hasMore,
                limit: state.limit,
            };
        case actionTypes.NOTIFICATION.DELETE_ANDROID_NOTIFICATION:
            return {
                notificationList: [
                    ...state.notificationList.filter(
                        notif => notif.wrapper_id !== action.payload,
                    ),
                ],
                hasMore: state.hasMore,
                limit: state.limit,
            };
        case actionTypes.NOTIFICATION.UPDATE_ANDROID_NOTIFICATION:
            return {
                notificationList: state.notificationList.map(val => {
                    if (val.wrapper_id === action.payload.wrapper_id)
                        return {
                            ...val,
                            ...action.payload,
                        };
                    return val;
                }),
                hasMore: state.hasMore,
                limit: state.limit,
            };
        case actionTypes.NOTIFICATION.DELETEALL_ANDROID_NOTIFICATION:
            return {
                notificationList: [
                    ...state.notificationList.filter(notif => {
                        for (let idx = 0; idx < action.payload.length; idx++) {
                            if (action.payload[idx] === notif.wrapper_id) {
                                return false;
                            }
                        }
                        return true;
                    }),
                ],
                hasMore: state.hasMore,
                limit: state.limit,
            };
    }
    return state;
}
