/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FieldArray } from "redux-form";
import { withErrorCatcher } from "@/components/utilities";
import SegmentsFields from "@/components/users/segmentation/components/SegmentsFields";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";

const SegmentsFieldsWrapper = ({ fields, isReadonly }) => {
    useEffect(() => {
        if (fields.length === 0) {
            fields.push({});
        }
    }, []);

    return (
        <>
            {fields.map((item, idx, arr) => {
                const showOr = arr.length - 1 > idx;
                return (
                    <React.Fragment key={idx}>
                        <FieldArray
                            name={`${item}.segments`}
                            component={SegmentsFields}
                            isReadonly={isReadonly}
                            deleteWrapperFields={() => {
                                // prevent delete first wrapper
                                if (idx > 0) {
                                    fields.remove(idx);
                                }
                            }}
                        />
                        {showOr && (
                            <div className="segments__or-separator">
                                <span className="text">یا</span>
                                <span className="border" />
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
            {!isReadonly && (
                <FlatButton
                    className="mui-btn mui-btn--sm mui-btn--blue segments__or-button"
                    rippleColor={blue900}
                    label="اضافه کردن یا"
                    onClick={() => {
                        // Prevent to add extra "or" when already an empty one added
                        fields.push({});
                    }}
                />
            )}
        </>
    );
};

export default withErrorCatcher(SegmentsFieldsWrapper);
