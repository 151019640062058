import React from "react";
import { Field } from "redux-form";
import { RadioButtonGroup } from "redux-form-material-ui";
import { RadioButton } from "material-ui/RadioButton";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";

const GenericRadioButtons = ({ options, onChanges, title }) => {
    return (
        <>
            {!!title && (
                <header>
                    <p>{title}</p>
                </header>
            )}

            <Field
                name="trigger"
                className="radiobtn-wrap"
                component={({ input: { value, onChange }, ...props }) => {
                    return (
                        <RadioButtonGroup
                            onChange={(ev, val) => {
                                onChange(val);
                                if (typeof onChanges === "function")
                                    onChanges(ev, val);
                            }}
                            valueSelected={value}
                            defaultSelected={1}
                            {...props}
                            name=""
                        >
                            {options.map((item, idx) => (
                                <RadioButton
                                    key={idx}
                                    className="mui-radiobtn"
                                    value={item.value}
                                    label={item.label}
                                />
                            ))}
                        </RadioButtonGroup>
                    );
                }}
            />
        </>
    );
};

GenericRadioButtons.defaultProps = {};
GenericRadioButtons.propTypes = {
    title: PropTypes.string,
    options: PropTypes.arrayOf({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
    }),
    onChanges: PropTypes.func,
};

export default withErrorCatcher(GenericRadioButtons);
