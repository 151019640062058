import React from "react";
import { useSelector } from "react-redux";
import { ReduxSimpleTextField, withErrorCatcher } from "@/components/utilities";
import { withRouter } from "react-router-dom";
import { Field, FormSection, reduxForm } from "redux-form";
import { createValidator, isRequired } from "@/components/utils/validation";
import SelectApplicationComponent from "@/components/utilities/commons/wizard/components/SelectApplicationComponent";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
import { useCreateSMSRemaining } from "@/components/sms/sms/create/general/hooks/useCreateSMSRemaining";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";

const WebEmailStep1 = props => {
    const { change } = props;
    const platform = useSelector(s => s?.app?.platform);
    const applications = useSelector(s => s?.[platform]?.applications ?? []);
    const content =
        useReduxFormValues("web_sms_wizard", ["data.content"])?.data?.content ??
        "";

    const remainingElem = useCreateSMSRemaining({ content });

    return (
        <form>
            <SelectApplicationComponent
                platform={platform}
                applications={applications}
                change={change}
            />

            <FormSection name="data">
                <div className="step-block">
                    <header>
                        <p>محتوی پیامک</p>
                    </header>

                    <div className="step-block__column">
                        <Field
                            fullWidth
                            multiline
                            rows={4}
                            helperText={remainingElem}
                            name="content"
                            label="متن پیامک"
                            required
                            component={ReduxSimpleTextField}
                        />
                    </div>
                </div>
            </FormSection>
        </form>
    );
};

const validators = createValidator({
    app_ids: (value, key, allValues) =>
        allValues.isTestMode || value?.length > 0
            ? undefined
            : FORM_ERRORS.NO_APP_SELECTED,
    data: {
        content: isRequired,
    },
});

export default withRouter(
    withErrorCatcher(
        reduxForm({
            form: "web_sms_wizard",
            validate: validators,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            onSubmitSuccess: (submitResult, dispatch, props) =>
                props.onSuccess(submitResult),
            onSubmitFail: (error, dispatch, submitError, props) =>
                props.onFailure(error),
        })(WebEmailStep1),
    ),
);
