import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    favList: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.INSTALLATION.FETCH_IOS_FAVORITES:
            return {
                favList: [
                    ...state.favList,
                    ...action.payload.filter(val => {
                        if (state.favList.length)
                            for (let idx = 0; idx < state.favList.length; idx++)
                                if (
                                    state.favList[idx].pushe_id === val.pushe_id
                                )
                                    return false;
                        return true;
                    }),
                ],
            };

        case actionTypes.INSTALLATION.FAVE_IOS_INSTALLATIONS:
            return {
                favList: [...state.favList, action.payload],
            };

        case actionTypes.INSTALLATION.UNFAVE_IOS_DEVICE: {
            return {
                favList: state.favList.filter(
                    fave => fave.pushe_id !== action.payload,
                ),
            };
        }
    }
    return state;
}
