import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import AndroidAutomationWizard from "@/components/sms/automation/create/android/AndroidAutomationWizard";
import { AUTOMATION_WIZARD_MODE } from "@/components/sms/constants";
import { withSMSCredential } from "@/components/utilities/conf/withCredentialWrapper";
import WebAutomationWizard from "@/components/sms/automation/create/web/WebAutomationWizard";

const AutomationCreatePage = props => {
    const platform = props?.match?.params?.platform;
    const WizardComponent = PLATFORMS[platform];

    return (
        <WizardComponent
            {...props}
            wizardMode={AUTOMATION_WIZARD_MODE.CREATE}
        />
    );
};

const PLATFORMS = {
    android: AndroidAutomationWizard,
    web: WebAutomationWizard,
};

export default withErrorCatcher(withSMSCredential(AutomationCreatePage));
