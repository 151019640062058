export const TIME_UNIT = {
    MILLISECONDS: "milliseconds",
    SECONDS: "second",
    MINUTES: "minutes",
    HOUR: "hour",
};

/**
 * Convert time units to each other
 *
 * @param originUnit (one of TIME_UNIT)
 * @param destUnit (one of TIME_UNIT)
 * @param time (String of number | number)
 * @returns {number|*}
 */
export const timeConverter = (originUnit, destUnit, time) => {
    if (
        Object.values(TIME_UNIT).indexOf(originUnit) < 0 ||
        Object.values(TIME_UNIT).indexOf(destUnit) < 0
    )
        return time;
    if (!time || (typeof time !== "number" && isNaN(Number(time)))) return;

    if (typeof time !== "number") time = Number(time);
    let seconds;

    switch (originUnit) {
        case TIME_UNIT.MILLISECONDS:
            seconds = time / 1000;
            break;
        case TIME_UNIT.SECONDS:
            seconds = time;
            break;
        case TIME_UNIT.MINUTES:
            seconds = time * 60;
            break;
        case TIME_UNIT.HOUR:
            seconds = time * 60 * 60;
    }

    switch (destUnit) {
        case TIME_UNIT.MILLISECONDS:
            return seconds * 1000;
        case TIME_UNIT.SECONDS:
            return seconds;
        case TIME_UNIT.MINUTES:
            return seconds / 60;
        case TIME_UNIT.HOUR:
            return seconds / (60 * 60);
    }
};
