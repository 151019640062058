import React from "react";
import css from "application/create/styles/createApp.module.scss";
import {
    reduxSelectBox,
    ReduxSimpleTextField,
    withErrorCatcher,
} from "@/components/utilities";
import { Field } from "redux-form";
import { ANDROID_FRAMEWORKS } from "application/constants";

const ApplicationDetails = () => {
    return (
        <div className={css.createAppDetails}>
            <div className={css.createAppHeading}>ایجاد اپلیکیشن</div>

            <div className={css.createAppBody}>
                <div className={css.flexRow}>
                    <Field
                        name={"name"}
                        label={"نام اپلیکیشن"}
                        required
                        component={ReduxSimpleTextField}
                    />

                    <Field
                        name={"package_name"}
                        label={"نام بسته"}
                        required
                        component={ReduxSimpleTextField}
                    />
                </div>

                <Field
                    name={"framework"}
                    label={"ابزار مورد استفاده*"}
                    options={[
                        {
                            label: ANDROID_FRAMEWORKS.ANDROID_STUDIO.LABEL,
                            value: ANDROID_FRAMEWORKS.ANDROID_STUDIO.VALUE,
                        },
                        {
                            label: ANDROID_FRAMEWORKS.REACT_NATIVE.LABEL,
                            value: ANDROID_FRAMEWORKS.REACT_NATIVE.VALUE,
                        },
                        {
                            label: ANDROID_FRAMEWORKS.FLUTTER.LABEL,
                            value: ANDROID_FRAMEWORKS.FLUTTER.VALUE,
                        },
                        {
                            label: ANDROID_FRAMEWORKS.UNITY.LABEL,
                            value: ANDROID_FRAMEWORKS.UNITY.VALUE,
                        },
                    ]}
                    component={reduxSelectBox}
                />
            </div>
        </div>
    );
};

export default withErrorCatcher(ApplicationDetails);
