import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Support from "@/components/app/containers/Support";
import SidebarLogo from "@/components/app/sidebar/SidebarLogo";
import SidebarButtons from "@/components/app/sidebar/SidebarButtons";
import SidebarMenus from "@/components/app/sidebar/SidebarMenus";
import "styles/modules/_mod-menu.scss";

const Sidebar = props => {
    const { open, handleClose, history } = props;

    const platform = useSelector(state => state?.app?.platform);

    const onMenuItemClick = ({ hasNested = false } = {}) => {
        if (open) {
            !hasNested && handleClose();
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        }
    };

    return (
        <div>
            <div
                className={classNames("menu-underlay", {
                    "menu-underlay--show": open,
                })}
                onClick={handleClose}
            >
                <button className="menu-underlay__close" />
            </div>

            <div className={classNames("menu", { "menu--open": open })}>
                <div
                    className="menu-header"
                    style={{ backgroundColor: "#FFFFFF" }}
                >
                    <SidebarLogo />

                    <SidebarButtons
                        onMenuItemClick={onMenuItemClick}
                        platform={platform}
                        history={history}
                    />
                </div>

                <Scrollbars className="menu-wrap" autoHide>
                    <div className="menu-body">
                        <SidebarMenus
                            platform={platform}
                            onMenuItemClick={onMenuItemClick}
                            history={history}
                        />

                        <div className="menu-footer">
                            <Support />
                        </div>
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

export default withRouter(Sidebar);
