import React from "react";
import AndroidSMSList from "@/components/sms/sms/list/android/AndroidSMSList";
import { pagePermissionWrapper, PERMISSIONS } from "@/components/utilities";
import WebSMSList from "@/components/sms/sms/list/web/WebSMSList";

const SMSListPage = ({ match: { params }, ...props }) => {
    const SMSPage = NOTIFICATIONS_PAGE[params.platform];
    return <SMSPage {...props} />;
};

const NOTIFICATIONS_PAGE = {
    android: AndroidSMSList,
    web: WebSMSList,
};

export default pagePermissionWrapper(PERMISSIONS.SMS_LIST, SMSListPage);
