import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import { createInitialFormData } from "@/components/sms/sms/create/android/controls/createInitialFormData";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import { PLATFORMS } from "@/constants";
import { normalize } from "@/components/utils/normalize";
import AndroidSMSStep1 from "@/components/sms/sms/create/android/steps/AndroidSMSStep1";
import AndroidSMSStep2 from "@/components/sms/sms/create/android/steps/AndroidSMSStep2";
import { smsSendNormalizer } from "@/components/sms/normalize";
import { estimateRecipients, sendSMS, SMS_KEYS } from "@/queries/sms";
import { clearForm } from "@/actions/app";
import { useMutation } from "react-query";
import { onSuccessAppendToList } from "@/queries/constants";
import { useDispatch } from "react-redux";
import WizardStepFinale from "@/components/utilities/commons/wizard/controls/WizardStepFinale";
import AndroidSendTestModal from "@/components/sms/sms/create/android/partials/AndroidSendTestModal";
import { successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import AndroidSMSStep3 from "@/components/sms/sms/create/android/steps/AndroidSMSStep3";

const AndroidSMSWizard = props => {
    const { history, sms } = props;
    const dispatch = useDispatch();

    const [sendSMSMutation] = useMutation(sendSMS, {
        onSuccess: data => {
            onSuccessAppendToList(SMS_KEYS.LIST(), data);
            successToast(
                TOAST_CONTENT.SEND_SMS_SUCCESS.TITLE,
                TOAST_CONTENT.SEND_SMS_SUCCESS.DESC,
            );
        },
    });

    return (
        <BaseWizard
            formName="android_sms_wizard"
            title="ارسال پیامک"
            subtitle="با این فرم می‌توانید به کاربران اپلیکیشن‌های اندرویدتان پیامک ارسال کنید"
            history={history}
            steps={steps}
            stepFinale={WizardStepFinale}
            preview={undefined}
            testModal={AndroidSendTestModal}
            footer={WizardFooter}
            formInitialData={createInitialFormData(sms)}
            onMountCallback={() => {
                // Do nothing yet
            }}
            onUnmountCallback={() => {
                clearForm("android_sms_wizard")(dispatch);
                history.push(`/sms/android`);
            }}
            submitAction={async (data, onSuccess, onError) => {
                const normalizedValues = normalize(data, smsSendNormalizer);
                await sendSMSMutation(normalizedValues, { onSuccess, onError });
            }}
            buttonOptions={{}}
            hasReduxForm={true}
            passToSteps={{
                platform: PLATFORMS.ANDROID,

                // Only for final step
                estimateMutationFunc: estimateRecipients,
                estimateNormalizer: smsSendNormalizer,
                messageTypeName: "پیامک",
            }}
        />
    );
};

const steps = [
    {
        component: AndroidSMSStep1,
        showTestBtn: true,
        isEnabled: _values => true,
    },
    {
        component: AndroidSMSStep2,
        showTestBtn: true,
        isEnabled: _values => true,
    },
    {
        component: AndroidSMSStep3,
        showTestBtn: true,
    },
];

export default withErrorCatcher(AndroidSMSWizard);
