import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import "styles/modules/_mod-application.scss";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import { StarStroke } from "@/components/utils/lib/svgicons";
import IconButton from "material-ui/IconButton";
import Util from "@/utils";
import { CircleLoader } from "@/components/utilities";
import isEmpty from "lodash/isEmpty";

class FirstInstall extends React.Component {
    static defaultProps = {
        application: {},
        installs: {},
    };

    static propTypes = {
        retrieveInstall: PropTypes.func.isRequired,
        application: PropTypes.object,
        installs: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            install: {},
        };

        this.intervalId = null;
    }

    onClick() {
        const {
            retrieveInstall,
            application: { app_id },
        } = this.props;
        this.setState(
            () => ({
                isLoading: true,
            }),
            () => {
                const onFailure = () => {
                    this.setState(
                        () => ({ isLoading: false }),
                        () => clearInterval(this.intervalId),
                    );
                };

                const onSuccess = () => {
                    const {
                        installs: { [app_id]: installList },
                    } = this.props;
                    if (
                        Array.isArray(installList) &&
                        installList.length > 0 &&
                        !isEmpty(installList[0])
                    ) {
                        this.setState(
                            () => ({
                                install: installList[0],
                            }),
                            () => clearInterval(this.intervalId),
                        );
                    }
                };

                const action = () => {
                    const request = () =>
                        retrieveInstall({ app_id }, onSuccess, onFailure);
                    request();
                    this.intervalId = setInterval(() => request(), 10000);
                };

                action();
                setTimeout(() => {
                    this.setState(
                        () => ({ isLoading: false }),
                        () => clearInterval(this.intervalId),
                    );
                }, 180000);
            },
        );
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        const { isLoading, install } = this.state;
        const {
            model,
            pushe_id,
            created_at,
            app_info: { name } = {},
        } = install;
        return (
            <div className="firstInstall">
                {Object.keys(install).length === 0 && (
                    <div className="firstInstall__btnWrapper">
                        <FlatButton
                            className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue "
                            rippleColor={blue900}
                            label={
                                isLoading ? (
                                    <CircleLoader
                                        size={20}
                                        style={{ top: "1px" }}
                                    />
                                ) : (
                                    "بررسی و دریافت نصب"
                                )
                            }
                            onClick={() => !isLoading && this.onClick()}
                            disabled={isLoading}
                        />
                    </div>
                )}

                <div className="firstInstall__body">
                    {Object.keys(install).length > 0 && (
                        <div className="firstInstall__body--row">
                            <div className="col">
                                <span className="title">مدل دستگاه</span>
                                <span className="value">{model}</span>
                            </div>
                            <div className="col">
                                <span className="title">شناسه دستگاه</span>
                                <span className="value">{pushe_id || "-"}</span>
                            </div>
                            <div className="col">
                                <span className="title">تاریخ نصب</span>
                                <span className="value">
                                    {Util._convertToLocalTimezone(created_at)}
                                </span>
                            </div>
                            <div className="col">
                                <span className="title">نام برنامه</span>
                                <span className="value">{name}</span>
                            </div>
                            <div className="col table-row--featured">
                                <span className="title">مورد علاقه</span>
                                <span className="value">
                                    <IconButton
                                        className="table-btn table-btn--star"
                                        data-tip="ویژه"
                                    >
                                        <StarStroke viewBox="0 0 21 21" />
                                    </IconButton>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withErrorCatcher(FirstInstall);
