/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { default as Checkboxes } from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        marginRight: 0,
        marginLeft: 0,
    },
    checkboxRoot: {
        padding: "6px",
        color: theme.palette.dashboard.gray,
    },
    checkboxChecked: {
        color: theme.palette.dashboard.green,
    },
}));

export const Checkbox = props => {
    const {
        label,
        checkboxProps,
        formControlProps,
        onToggleCheckbox,
        // if checked & onCheck is passed -> become controlled component
        checked,
        onCheck,
        disabled,
    } = props;

    const classes = useStyles();
    const [isChecked, setChecked] = useState(false);

    const handleChange = event => {
        const checked = event.target.checked;

        if (typeof onCheck === "function") onCheck(checked);
        else setChecked(checked);
    };

    useEffect(() => {
        if (typeof onToggleCheckbox === "function" && !checked)
            onToggleCheckbox(isChecked);
    }, [isChecked]);

    return (
        <FormControlLabel
            control={
                <Checkboxes
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    disabled={disabled}
                    color="default"
                    classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checkboxChecked,
                    }}
                    {...checkboxProps}
                />
            }
            label={label}
            classes={{ root: classes.formControlRoot }}
            {...formControlProps}
        />
    );
};

export const ReduxCheckbox = props => {
    const {
        onCheck,
        input: { onChange, value, ...inputProps },
        ...rest
    } = props;

    return (
        <Checkbox
            {...inputProps}
            {...rest}
            checked={!!value}
            onCheck={isInputChecked => {
                onChange(isInputChecked);
                if (onCheck) onCheck(isInputChecked);
            }}
        />
    );
};
