import React from "react";
import ApplicationList from "application/list/base/ApplicationList";
import ApplicationFilter from "application/list/ios/components/IosApplicationFilter";
import { withErrorCatcher } from "@/components/utilities";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import {
    deleteApplication,
    exportCSV,
    fetchApplications,
    updateApplication,
} from "@/actions/applications";
import IosApplicationRow from "application/list/ios/components/IosApplicationRow";
import IosTableHeader from "application/list/ios/components/IosTableHeader";
import { openModal } from "@/actions/app";
import PropTypes from "prop-types";

const IosApplicationList = ({
    applications,
    history,
    updateApplication,
    openModal,
    deleteApplication,
    exportCSV,
}) => {
    return (
        <ApplicationList
            platform="ios"
            pageTitle="اپلیکیشن‌ها"
            applications={applications}
            ApplicationsTableHeader={IosTableHeader}
            ApplicationFilter={ApplicationFilter}
            ApplicationsRows={IosApplicationRow}
            updateApplication={updateApplication}
            history={history}
            openModal={openModal}
            exportCSV={exportCSV}
            deleteApplication={deleteApplication}
        />
    );
};

IosApplicationList.propTypes = {
    applications: PropTypes.array,
    history: PropTypes.object,
    updateApplication: PropTypes.func,
    openModal: PropTypes.func,
    deleteApplication: PropTypes.func,
};

IosApplicationList.defaultProps = {};

function mapStateToProps(state) {
    const { applications } = state["ios"];
    return {
        applications,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchApplications,
            updateApplication,
            openModal,
            deleteApplication,
            exportCSV,
        },
        dispatch,
    );
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(IosApplicationList),
);
