import React from "react";
import AndroidSMSWizard from "@/components/sms/sms/create/android/AndroidSMSWizard";
import { withSMSCredential } from "@/components/utilities/conf/withCredentialWrapper";
import WebSMSWizard from "@/components/sms/sms/create/web/WebSMSWizard";

const SendSMSPage = props => {
    const {
        match: { params },
        history,
    } = props;
    const PlatformWizard = PLATFORM_FORMS[params.platform];

    return <PlatformWizard history={history} />;
};

const PLATFORM_FORMS = {
    android: AndroidSMSWizard,
    web: WebSMSWizard,
};

export default withSMSCredential(SendSMSPage);
