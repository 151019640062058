import React from "react";
import { NOTIFICATION_MODE } from "@/actions/_types";
import NotificationTiming from "@/components/notifications/notification/create/controls/NotificationTiming";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { withErrorCatcher } from "@/components/utilities";
import { useSelector } from "react-redux";
import { createValidator } from "@/components/utils/validation";
import moment from "moment";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
import NotificationDeliverySettingsComponent from "@/components/utilities/commons/wizard/components/NotificationDeliverySettingsComponent";
import NotificationDisplaySettingsComponent from "@/components/utilities/commons/wizard/components/NotificationDisplaySettingsComponent";
import ReactTooltip from "react-tooltip";
import { hasOneSignal as checkHasOneSignal } from "@/utils";
import NotificationDisplay from "@/components/notifications/notification/create/controls/NotificationDisplay";

const AndroidNotifStep4 = props => {
    const {
        passToSteps: { notificationMode },
        change,
    } = props;

    const fields = ["app_ids", "data", "eta"];
    const formValues = useReduxFormValues("notification_wizard", fields);

    const applications = useSelector(
        state => state?.["android"]?.applications ?? [],
    );
    const hasOneSignal = checkHasOneSignal(formValues?.app_ids, applications);
    const isModalNormal = notificationMode === NOTIFICATION_MODE.NORMAL;
    return (
        <form className="notif-step4">
            <>
                {isModalNormal && (
                    <>
                        <div className="notif-block">
                            <div className="notif-block__body">
                                <header>
                                    <p>تعیین زمان ارسال و نمایش اعلان</p>
                                </header>

                                <br />

                                <NotificationTiming
                                    change={change}
                                    eta={formValues?.eta}
                                />

                                <NotificationDisplay
                                    change={change}
                                    scheduled_time={
                                        formValues?.data?.scheduled_time
                                    }
                                    delay_until={formValues?.data?.delay_until}
                                />
                            </div>
                        </div>
                    </>
                )}

                <NotificationDeliverySettingsComponent
                    showCollapseKeyBlock={true}
                    showDelayUntilBlock={true}
                    showRateLimitBlock={isModalNormal && false}
                    showTimeToLiveBlock={isModalNormal}
                />

                <NotificationDisplaySettingsComponent
                    data={formValues?.data ?? {}}
                    hasOneSignal={hasOneSignal}
                    change={change}
                />

                <ReactTooltip className="tooltip" effect="solid" />
            </>
        </form>
    );
};

const etaValidator = (value, key, values) => {
    let eta = values.eta;
    if (eta) {
        let momentObject = moment.utc(eta);
        let now = moment.utc();
        const minutesInTheFuture = moment
            .duration(momentObject.diff(now))
            .asMinutes();
        if (minutesInTheFuture < 15) {
            return FORM_ERRORS.NOTIFICATION_TIME_ERROR;
        }
    }
};

const scheduledTimeValidator = (value, key, values) => {
    let val = values?.data?.scheduled_time;
    if (val) {
        let momentObject = moment.utc(val);
        let now = moment.utc();
        const minutesInTheFuture = moment
            .duration(momentObject.diff(now))
            .asMinutes();
        if (minutesInTheFuture < 60 * 24) {
            return FORM_ERRORS.PUBLISH_TIME_ERROR;
        }
    }
};

const validators = createValidator({
    eta: etaValidator,
    data: {
        scheduled_time: scheduledTimeValidator,
    },
});

const AndroidNotifStep4RF = reduxForm({
    form: "notification_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidNotifStep4);

export default withRouter(withErrorCatcher(AndroidNotifStep4RF));
