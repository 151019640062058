import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { isArray, isEmpty } from "lodash";
import FlatButton from "material-ui/FlatButton";

const BigTab = props => {
    const { notification } = props;
    const { title, subtitle, content, image, buttons } =
        notification?.data ?? {};

    return (
        <div className="preview-body preview-body--notif">
            {image && (
                <div
                    className="preview-body__avatar--big"
                    style={image ? { backgroundImage: `url(${image})` } : {}}
                />
            )}

            <div>
                <div className="preview-body__title">{title}</div>
                <div className="preview-body__subtitle">{subtitle}</div>
                <div className="preview-body__desc">{content}</div>
            </div>

            {buttons &&
                isArray(buttons) &&
                !isEmpty(buttons) &&
                buttons.map((btn, indx) => {
                    const width =
                        buttons.length === 3
                            ? "33.3%"
                            : buttons.length === 2
                            ? "50%"
                            : "100%";
                    const url = btn?.["btn_action"]?.url;
                    return (
                        <FlatButton
                            key={indx}
                            label={btn["btn_content"]}
                            labelPosition={"before"}
                            icon={
                                <i
                                    className="material-icons"
                                    style={{
                                        fontSize: "1.2rem",
                                        margin: "0",
                                        width: "20%",
                                        textAlign: "left",
                                    }}
                                >
                                    {btn["btn_icon"]}
                                </i>
                            }
                            labelStyle={{
                                fontSize: ".8rem",
                                padding: "0 0 0 5px",
                                width: "80%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textAlign: "left",
                            }}
                            style={{ width }}
                            onClick={() => {
                                url && window.open(url, "_blank");
                            }}
                        />
                    );
                })}
        </div>
    );
};

export default withErrorCatcher(BigTab);
