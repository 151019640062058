import React from "react";
import ApplicationList from "application/list/base/ApplicationList";
import WebApplicationFilter from "application/list/web/components/WebApplicationFilter";
import WebApplicationRow from "application/list/web/components/WebApplicationRow";
import WebTableHeader from "application/list/web/components/WebTableHeader";
import { withErrorCatcher } from "@/components/utilities";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { fetchApplications } from "@/actions/applications";

const WebApplicationList = ({ applications }) => {
    return (
        <ApplicationList
            platform="web"
            pageTitle="وب‌سایت‌ها"
            applications={applications}
            ApplicationsTableHeader={WebTableHeader}
            ApplicationFilter={WebApplicationFilter}
            ApplicationsRows={WebApplicationRow}
        />
    );
};

function mapStateToProps(state) {
    const { applications } = state["web"];
    return { applications };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchApplications }, dispatch);
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(WebApplicationList),
);
