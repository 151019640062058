import React, { cloneElement } from "react";

export const PTableBody = props => {
    const { children, columns, mobileSizeMax } = props;
    const childrenProps = { columns, mobileSizeMax };

    return (
        <div className="PTableBody">
            {React.Children.map(
                children,
                child =>
                    React.isValidElement(child) &&
                    cloneElement(child, childrenProps),
                null,
            )}
        </div>
    );
};
