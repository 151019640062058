import { FORM_ERRORS } from "@/components/utils/assertion-texts";

export const ticketFormValidations = values => {
    const errors = {};

    const email_re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.email || values.email.length === 0) {
        errors.email = FORM_ERRORS.REQUIRED_FIELD;
    } else if (values.email && !email_re.test(values.email.toLowerCase())) {
        errors.email = FORM_ERRORS.TICKET_FORM.INVALID_EMAIL;
    }

    if (!values.name || values.name.length === 0) {
        errors.name = FORM_ERRORS.REQUIRED_FIELD;
    } else if (values.name && values.name.length < 3) {
        errors.name = FORM_ERRORS.TICKET_FORM.INVALID_NAME;
    }

    if (!values.subject || values.subject.length === 0) {
        errors.subject = FORM_ERRORS.REQUIRED_FIELD;
    } else if (values.subject && values.subject.length < 3) {
        errors.subject = FORM_ERRORS.TICKET_FORM.INVALID_SUBJECT;
    }

    if (!values.message || values.message.length === 0) {
        errors.message = FORM_ERRORS.REQUIRED_FIELD;
    } else if (values.message && values.message.length < 3) {
        errors.message = FORM_ERRORS.TICKET_FORM.INVALID_MESSAGE;
    }

    return errors;
};
