import React from "react";
import AndroidNotifList from "@/components/notifications/notification/list/android/AndroidNotifList";
import WebNotifList from "@/components/notifications/notification/list/web/WebNotifList";
import IosNotifList from "@/components/notifications/notification/list/ios/IosNotifList";

const NotificationListPage = ({ match: { params }, ...props }) => {
    const NotificationPage = NOTIFICATIONS_PAGE[params.platform];
    return <NotificationPage {...props} />;
};

const NOTIFICATIONS_PAGE = {
    android: AndroidNotifList,
    web: WebNotifList,
    ios: IosNotifList,
};

export default NotificationListPage;
