/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    withErrorCatcher,
    DateTimePicker,
    timeConverter,
    TIME_UNIT,
} from "@/components/utilities";
import { GEOFENCE_TRIGGER_TYPES } from "@/components/notifications/geofence/constants";
import Checkbox from "material-ui/Checkbox";
import GenericTextFieldComponent from "@/components/utilities/commons/wizard/components/GenericTextFieldComponent";
import PropTypes from "prop-types";
import { Field } from "redux-form";

const GeofenceDeliverySettings = props => {
    const {
        change,
        trigger,
        limit,
        rate_limit,
        expiration_date,
        show_trigger,
        show_limit,
        show_rate_limit,
        show_expiration_date,
    } = props;

    const [triggerInput, toggleTriggerInput] = useState(true);
    const [limitInput, toggleLimitInput] = useState(false);
    const [rateLimitInput, toggleRateLimitInput] = useState(false);
    const [expirationDate, toggleExpirationDate] = useState(false);

    useEffect(() => {
        // These are for the delay to get value of redux
        if (!limitInput && !!limit) toggleLimitInput(true);
        if (!rateLimitInput && !!rate_limit) toggleRateLimitInput(true);
        if (!expirationDate && !!expiration_date) toggleExpirationDate(true);
    }, [limit, rate_limit, expiration_date]);

    return (
        <>
            <header>
                <p>تنظیمات دریافت</p>
            </header>

            {show_trigger && trigger === GEOFENCE_TRIGGER_TYPES.ENTER && (
                <div className="notif-block__row wizard-block__input-row">
                    <Checkbox
                        className="mui-checkbox"
                        defaultChecked={triggerInput}
                        label="حداقل زمان حضور برای نمایش"
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "265px",
                        }}
                        onCheck={(event, checked) => {
                            toggleTriggerInput(!triggerInput);
                            if (!checked) {
                                change("dwell_time", "");
                            }
                        }}
                    />

                    {triggerInput && (
                        <GenericTextFieldComponent
                            fieldName="dwell_time"
                            unit="دقیقه"
                            noRow={true}
                            colClasses=""
                            format={val => {
                                if (
                                    typeof Number(val) === "number" &&
                                    !isNaN(Number(val))
                                ) {
                                    return timeConverter(
                                        TIME_UNIT.MILLISECONDS,
                                        TIME_UNIT.MINUTES,
                                        val,
                                    );
                                }
                                return "";
                            }}
                            normalize={val => {
                                if (
                                    val !== "" &&
                                    typeof Number(val) === "number" &&
                                    !isNaN(Number(val))
                                ) {
                                    return timeConverter(
                                        TIME_UNIT.MINUTES,
                                        TIME_UNIT.MILLISECONDS,
                                        val,
                                    );
                                }
                                return "";
                            }}
                        />
                    )}
                </div>
            )}

            {show_limit && (
                <div className="notif-block__row wizard-block__input-row">
                    <Checkbox
                        className="mui-checkbox"
                        defaultChecked={limitInput}
                        label="بیشینه تعداد دفعات نمایش نوتیفیکیشن"
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "265px",
                        }}
                        onCheck={(_event, _checked) => {
                            toggleLimitInput(!limitInput);
                            // if (!checked) {
                            //     change("limit", '');
                            // }
                        }}
                    />

                    {limitInput && (
                        <GenericTextFieldComponent
                            fieldName="limit"
                            unit="تعداد"
                            noRow={true}
                            colClasses=""
                            fieldType="number"
                            normalize={value =>
                                !isNaN(Number(value)) && value > 0
                                    ? Number(value)
                                    : ""
                            }
                            format={value =>
                                !isNaN(Number(value)) ? Number(value) : ""
                            }
                        />
                    )}
                </div>
            )}

            {show_rate_limit && (
                <div className="notif-block__row wizard-block__input-row">
                    <Checkbox
                        className="mui-checkbox"
                        defaultChecked={rateLimitInput}
                        label="حداقل زمان برای نمایش مجدد"
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "265px",
                        }}
                        onCheck={(_event, _checked) => {
                            toggleRateLimitInput(!rateLimitInput);
                            // if (!checked) {
                            //     change("rate_limit", '');
                            // }
                        }}
                    />

                    {rateLimitInput && (
                        <GenericTextFieldComponent
                            fieldName="rate_limit"
                            unit="دقیقه"
                            noRow={true}
                            colClasses=""
                            format={val => {
                                if (
                                    typeof Number(val) === "number" &&
                                    !isNaN(Number(val))
                                ) {
                                    return timeConverter(
                                        TIME_UNIT.MILLISECONDS,
                                        TIME_UNIT.MINUTES,
                                        val,
                                    );
                                }
                                return "";
                            }}
                            normalize={val => {
                                if (
                                    val !== "" &&
                                    typeof Number(val) === "number" &&
                                    !isNaN(Number(val)) &&
                                    val > 0
                                ) {
                                    return timeConverter(
                                        TIME_UNIT.MINUTES,
                                        TIME_UNIT.MILLISECONDS,
                                        val,
                                    );
                                }
                                return "";
                            }}
                        />
                    )}
                </div>
            )}

            {show_expiration_date && (
                <div className="notif-block__row wizard-block__input-row">
                    <Checkbox
                        className="mui-checkbox"
                        defaultChecked={expirationDate}
                        label="تاریخ انقضا پیام"
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "265px",
                        }}
                        onCheck={(_event, _checked) => {
                            toggleExpirationDate(!expirationDate);
                            // if (!checked) {
                            //     change("expiration_date", '');
                            // }
                        }}
                    />

                    {expirationDate && (
                        <Field
                            name="expiration_date"
                            component={({
                                input: { onChange, value },
                                meta: { error },
                            }) => {
                                return (
                                    <DateTimePicker
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                        initDatetime={false}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

GeofenceDeliverySettings.defaultProps = {
    show_trigger: false,
    show_limit: false,
    show_rate_limit: false,
    show_expiration_date: false,
};
GeofenceDeliverySettings.propTypes = {
    show_trigger: PropTypes.bool,
    show_limit: PropTypes.bool,
    show_rate_limit: PropTypes.bool,
    show_expiration_date: PropTypes.bool,
    trigger: PropTypes.number,
    limit: PropTypes.number,
    rate_limit: PropTypes.number,
    expiration_date: PropTypes.string,
    change: PropTypes.func.isRequired,
};

export default withErrorCatcher(GeofenceDeliverySettings);
