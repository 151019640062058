import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const StatusComponent = props => {
    const { statusList } = props;

    const first = statusList?.[0] ?? {};
    const textStyle = { color: first["color"] };

    const notificationTypeDataLength = statusList?.length;

    return (
        <div className="card-list__status-comp">
            <span className="card-list__status-text" style={textStyle}>
                {first["title"] || ""}
            </span>

            {statusList.map((item, index) => {
                const style = {
                    simpleStyle: {
                        backgroundColor: `${item.color}`,
                    },
                    overlayStyle: {
                        marginLeft: "-16px",
                        backgroundColor: `${item.color}`,
                        zIndex: notificationTypeDataLength - index,
                    },
                };

                let itemStyle =
                    notificationTypeDataLength > 1 &&
                    index !== notificationTypeDataLength - 1
                        ? style.overlayStyle
                        : style.simpleStyle;

                return (
                    <i key={item.key} style={itemStyle}>
                        <svg>
                            <use href={`#${item.icon}`} />
                        </svg>
                    </i>
                );
            })}
        </div>
    );
};

export default withErrorCatcher(StatusComponent);
