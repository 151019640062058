/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import classNames from "classnames";
import { PLATFORMS } from "@/constants";
import { NavLink } from "react-router-dom";
import { ListItem } from "material-ui/List";
import { App, Dash, Home, SortLeft } from "@/components/utils/lib/svgicons";
import PropTypes from "prop-types";
import parse from "url-parse";

const MENU_KEYS = {
    NOTIF: 1,
    USERS: 2,
    EMAIL: 3,
    SMS: 4,
};

const default_active_menus = {
    [MENU_KEYS.NOTIF]: false,
    [MENU_KEYS.USERS]: false,
    [MENU_KEYS.EMAIL]: false,
    [MENU_KEYS.SMS]: false,
};

const SidebarMenuAndroid = props => {
    const { platform, checkPermissionOnClick, onMenuItemClick, history } =
        props;

    const [activeMenu, setActiveMenu] = useState(default_active_menus);

    const toggleActiveMenu = (activeMenuKey, activeMenuValue) => {
        setActiveMenu({
            ...default_active_menus,
            [activeMenuKey]: activeMenuValue,
        });
    };

    // When page is reloaded or initially opened with one of these pages this function will open them
    const handleOnInitOpenMenus = () => {
        const { pathname } = parse(window.location);
        if (!pathname) return;
        let menu = null;
        const result = pathname.match(
            /(user|installation|segmentation|notification|email|sms).*(android).*/,
        );
        if (result) {
            // eslint-disable-next-line no-unused-vars
            const [_, first] = result;
            switch (first) {
                case "user":
                case "installation":
                case "segmentation":
                    menu = MENU_KEYS.USERS;
                    break;
                case "notification":
                    menu = MENU_KEYS.NOTIF;
                    break;
                case "email":
                    menu = MENU_KEYS.EMAIL;
                    break;
                case "sms":
                    menu = MENU_KEYS.SMS;
                    break;
            }
        }

        if (menu) {
            onMenuItemClick({ hasNested: true });
            toggleActiveMenu(menu, true);
        }
    };

    useEffect(() => {
        handleOnInitOpenMenus();
    }, [history?.location?.pathname]);

    return (
        <div
            className={classNames("menu-catg", {
                "menu-catg--open": platform === PLATFORMS.ANDROID,
            })}
            onClick={() => checkPermissionOnClick(PLATFORMS.ANDROID, null)}
        >
            <div className="menu-catg__header" id="android-platform-button">
                <svg className="icon-android">
                    <use xlinkHref="#svg_android_fill" />
                </svg>
                <span>Android</span>
            </div>

            <div className="menu-catg__body">
                <NavLink to="/dashboard/android">
                    <ListItem
                        key={1}
                        className="menu-item"
                        primaryText="داشبورد"
                        leftIcon={<Home viewBox="0 0 21 21" />}
                        hoverColor="#ffffff"
                        onClick={onMenuItemClick}
                    />
                </NavLink>

                <NavLink to="/application/android">
                    <ListItem
                        key={2}
                        id="application-page-button"
                        className="menu-item"
                        primaryText="اپلیکیشن‌ها"
                        leftIcon={<App viewBox="0 0 21 21" />}
                        hoverColor="#ffffff"
                        onClick={onMenuItemClick}
                    />
                </NavLink>

                <ListItem
                    key={3}
                    //menuKey={MENU_KEYS.NOTIF}
                    id="platformNotifsBtn"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.NOTIF],
                    })}
                    primaryText="نوتیفیکیشن‌ها"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.NOTIF]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.NOTIF, open);
                    }}
                    nestedItems={[
                        <NavLink key={101} to="/notification/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="نوتیفیکیشن‌های ارسالی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink
                            key={102}
                            to="/notification/automation/android"
                        >
                            <ListItem
                                className="menu-item submenu"
                                primaryText="نوتیفیکیشن اتوماتیک"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink key={103} to="/notification/geofence/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="نوتیفیکیشن جغرافیایی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                    ]}
                />

                <ListItem
                    key={4}
                    //menuKey={MENU_KEYS.USERS}
                    id="platformUsersBtn"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.USERS],
                    })}
                    primaryText="کاربران"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.USERS]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.USERS, open);
                    }}
                    nestedItems={[
                        <NavLink key={121} to="/installation/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="نصب‌ها"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,

                        <NavLink key={122} to="/segmentation/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="دسته‌بندی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,

                        <NavLink key={123} to="/user/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="آمار کاربران"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                    ]}
                />

                <ListItem
                    key={5}
                    //menuKey={MENU_KEYS.EMAIL}
                    id="email-page-button"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.EMAIL],
                    })}
                    primaryText="ایمیل‌ها"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.EMAIL]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.EMAIL, open);
                    }}
                    nestedItems={[
                        <NavLink key={141} to="/email/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="ایمیل‌های ارسالی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink key={142} to="/email/automation/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="ایمیل‌های اتوماتیک"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                    ]}
                />

                <ListItem
                    key={6}
                    //menuKey={MENU_KEYS.SMS}
                    id="sms-page-button"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.SMS],
                    })}
                    primaryText="پیامک‌ها"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.SMS]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.SMS, open);
                    }}
                    nestedItems={[
                        <NavLink key={161} to="/sms/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="پیامک‌های ارسالی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink key={162} to="/sms/automation/android">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="پیامک‌های اتوماتیک"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                    ]}
                />
            </div>
        </div>
    );
};

SidebarMenuAndroid.defaultProps = {};
SidebarMenuAndroid.propTypes = {
    platform: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
    checkPermissionOnClick: PropTypes.func.isRequired,
};

export default withErrorCatcher(SidebarMenuAndroid);
