import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector, reduxForm } from "redux-form";
import {
    createValidator,
    isNumber,
    isRequired,
} from "@/components/utils/validation";
import { withErrorCatcher } from "@/components/utilities";
import GenericTextFieldComponent from "@/components/utilities/commons/wizard/components/GenericTextFieldComponent";
import GenericSelectFieldComponent from "@/components/utilities/commons/wizard/components/GenericSelectFieldComponent";
import { selectOptionBuilderFromArray } from "@/components/utils/finals/selectOptionBuilder";
import {
    GEOFENCE_TRIGGER_TYPES,
    GEOFENCE_WIZARD_MODE,
} from "@/components/notifications/geofence/constants";
import GeofenceDeliverySettings from "@/components/utilities/commons/wizard/components/GeofenceDeliverySettings";
import GeofenceMapComponent from "@/components/utilities/commons/wizard/components/GeofenceMapComponent";
import GenericRadioButtons from "@/components/utilities/commons/wizard/components/GenericRadioButtons";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import * as moment from "moment";

const AndroidGeofenceStep1 = props => {
    const {
        applications,
        change,
        long,
        lat,
        radius,
        limit,
        rate_limit,
        trigger,
        dwell_time,
        expiration_date,
        passToSteps: { wizardMode },
    } = props;

    return (
        <form className="notif-step1">
            <div className="wizard-block">
                <GenericTextFieldComponent
                    fieldName="name"
                    labelText="نام پیام خودکار جغرافیایی*"
                />
            </div>

            <div className="wizard-block">
                <GenericSelectFieldComponent
                    fieldName="app_id"
                    title="انتخاب اپلیکیشن"
                    placeholder="انتخاب اپلیکیشن*"
                    options={selectOptionBuilderFromArray(
                        applications,
                        "name",
                        "app_id",
                    )}
                    readOnly={wizardMode === GEOFENCE_WIZARD_MODE.EDIT}
                />
            </div>

            <div className="wizard-block">
                <GenericRadioButtons
                    title="انتخاب نوع پیام"
                    options={[
                        {
                            label: "پیام ورود به منطقه",
                            value: GEOFENCE_TRIGGER_TYPES.ENTER,
                        },
                        {
                            label: "پیام خروج از منطقه",
                            value: GEOFENCE_TRIGGER_TYPES.LEAVE,
                        },
                    ]}
                    onChanges={(_ev, val) => {
                        if (
                            Number(val) === GEOFENCE_TRIGGER_TYPES.LEAVE &&
                            !!dwell_time
                        ) {
                            change("dwell_time", "");
                        }
                    }}
                />
            </div>

            <GeofenceMapComponent
                long={long}
                lat={lat}
                radius={radius}
                change={change}
                initMapWithDefaultValues={
                    wizardMode === GEOFENCE_WIZARD_MODE.CREATE
                }
            />

            <div className="wizard-block">
                <GeofenceDeliverySettings
                    change={change}
                    trigger={trigger}
                    limit={limit}
                    rate_limit={rate_limit}
                    dwell_time={dwell_time}
                    expiration_date={expiration_date}
                    show_trigger={true}
                    show_limit={true}
                    show_rate_limit={true}
                    show_expiration_date={true}
                />
            </div>
        </form>
    );
};

const validators = createValidator({
    name: isRequired,
    app_id: isRequired,
    lat: isRequired,
    long: isRequired,
    radius: [isRequired, isNumber],
    trigger: isRequired,
    expiration_date: value => {
        if (value) {
            let momentObject = moment.utc(value);
            let now = moment.utc();
            const minutesInTheFuture = moment
                .duration(momentObject.diff(now))
                .asMinutes();
            if (minutesInTheFuture < 15) {
                return FORM_ERRORS.NOTIFICATION_TIME_ERROR;
            }
        }
    },
});

const mapStateToProps = (state, ownProps) => {
    const {
        passToSteps: { platform },
    } = ownProps;
    const { applications = [] } = state[platform] || {};

    const {
        lat,
        long,
        radius,
        limit,
        rate_limit,
        trigger,
        dwell_time,
        expiration_date,
    } = formValueSelector("geofence_form")(
        state,
        "lat",
        "long",
        "radius",
        "limit",
        "rate_limit",
        "trigger",
        "dwell_time",
        "expiration_date",
    );

    return {
        applications,
        long,
        lat,
        radius,
        limit,
        rate_limit,
        trigger,
        dwell_time,
        expiration_date,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

const AndroidGeofenceStep1RF = reduxForm({
    form: "geofence_form",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, _, props) => props.onSuccess(submitResult),
    onSubmitFail: (error, _, __, props) => props.onFailure(error),
})(AndroidGeofenceStep1);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AndroidGeofenceStep1RF),
    ),
);
