import { useEffect } from "react";
import myStorage from "@/myStorage";
import { PREFERRED_PLATFORM } from "@/constants";

export const useHandleSignedInUser = history => {
    useEffect(() => {
        const token = myStorage.getItem("token");

        if (token) history.push(`/dashboard/${PREFERRED_PLATFORM}`);
    }, [history]);
};
