import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "@/components/utilities/commons/modal";

class ModalDefault extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            onClose,
            title,
            desc,
            body,
            footer,
            error,
            customClass,
            modalSize = "sm",
        } = this.props;

        const className = `modal--${modalSize} modal--default ${customClass}`;

        return (
            <Modal className={className} onModalClose={onClose}>
                <div className="modal-header">{title}</div>
                <div className="modal-desc">{desc}</div>
                <div className="modal-body">{body}</div>
                <div className="modal-footer">{footer}</div>
                <div className="send-limit__footer">
                    <p className="color-error">{error}</p>
                </div>
            </Modal>
        );
    }
}

ModalDefault.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    desc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    body: PropTypes.object,
    modalSize: PropTypes.string,
    error: PropTypes.object,
    customClass: PropTypes.object,
    footer: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default ModalDefault;
