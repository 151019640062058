/*
 ** Convert an enum to a react-select's options props
 *
 * @param myEnum: an enum
 *
 * @return an array of {value: String, label: String}
 */
export const selectOptionBuilderFromEnum = myEnum => {
    return Object.keys(myEnum).map(key => ({
        label: myEnum[key.toString()],
        value: key,
    }));
};

/*
 ** Convert an array of objects to a react-select's options props
 *
 * @param myArray: An array of objects
 * @param labelKey: The key name that provides option label
 * @param valueKey: The key name that provides option value
 *
 * @return an array of {value: string, label: string}
 */
export const selectOptionBuilderFromArray = (myArray, labelKey, valueKey) => {
    return myArray.map(item => ({
        label: item[labelKey],
        value: item[valueKey],
    }));
};

/*
 ** Convert an array of objects to a react-select's options props
 *
 * @param myArray: An array of objects
 * * @param labelKey: The key name that provides option label
 * @param valueKey: The key name that provides option value
 * @param arrayOfKeys: An array of {[key: string]: value}
 *   key: indicates the react-select option key
 *   value: indicates the existing key in myArray objects
 *
 * @return an array of {value: string, [key: string]: string}
 */
export const selectOptionBuilderFromArrayCustomKeys = (
    myArray,
    labelKey,
    valueKey,
    arrayOfKeys,
) => {
    return myArray.map(item => {
        const obj = {
            label: item[labelKey],
            value: item[valueKey],
        };

        const keys = arrayOfKeys.map(i => Object.keys(i)[0]);
        const values = arrayOfKeys.map(i => Object.values(i)[0]);

        for (const i in keys) {
            // eslint-disable-next-line no-prototype-builtins
            if (keys.hasOwnProperty(i) && keys[i]) {
                obj[keys[i]] = item[values[i]];
            }
        }
        return obj;
    });
};
