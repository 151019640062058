import React from "react";

import RichTextEditor from "./RichTextEditor";
import "./styles.scss";

const EditorFieldComponent = props => {
    const {
        placeholder,
        input: { onChange, value },
        disabled,
        meta: { submitFailed, error },
        id,
    } = props;

    return (
        <div className="formAlign">
            <RichTextEditor
                id={id}
                disabled={disabled}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            />
            {submitFailed && error && (
                <span className="form-error-text">{error}</span>
            )}
        </div>
    );
};

export default EditorFieldComponent;
