/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import css from "./styles.module.scss";
import {
    ReduxCheckbox,
    reduxSelectBox,
    ColorPicker,
} from "@/components/utilities";
import { Field, FormSection } from "redux-form";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";

const bellPositions = [
    { label: "پایین راست", value: "right" },
    { label: "پایین چپ", value: "left" },
];

export default props => {
    const { change } = props;
    const formValues = useReduxFormValues("webpush-settings", ["bell"]);

    useEffect(() => {
        if (!formValues?.bell?.properties?.position) {
            change("bell.properties.position", bellPositions[0]?.value);
        }
    }, []);

    const { showBell = false } = formValues?.bell ?? {};

    return (
        <div className={css.settingsWrapper}>
            <FormSection name={"bell"}>
                <Field
                    component={ReduxCheckbox}
                    name="showBell"
                    label="نمایش زنگوله"
                />

                {showBell && (
                    <>
                        <div className={css.settingsLineSeparator} />

                        <h3 className={css.settingsBlockHeading}>
                            وضعیت نمایش زنگوله در دستگاه های مختلف
                        </h3>
                        <div className={css.contentWrapper}>
                            <div className={css.divideColumnInHalf}>
                                <Field
                                    component={ReduxCheckbox}
                                    name="properties.displayInMobile"
                                    label="نمایش در موبایل"
                                />
                            </div>
                            <div className={css.divideColumnInHalf}>
                                <Field
                                    component={ReduxCheckbox}
                                    name="properties.displayInDesktop"
                                    label="نمایش در دسکتاپ"
                                />
                            </div>
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <h3 className={css.settingsBlockHeading}>تنظیم رنگ</h3>
                        <div className={css.mb20}>
                            <Field
                                name="properties.bellColor"
                                component={ColorPicker}
                                label="زنگوله"
                                defaultValue={"#ffffff"}
                            />
                        </div>
                        <div className={css.contentWrapper}>
                            <Field
                                name="properties.backgroundColor"
                                component={ColorPicker}
                                label="پس‌زمینه زنگوله"
                                defaultValue={"#0065ff"}
                            />
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <div className={css.settingsBellPositionWrapper}>
                            <Field
                                name="properties.position"
                                component={reduxSelectBox}
                                label={"مکان نمایش"}
                                options={bellPositions}
                            />
                        </div>
                    </>
                )}
            </FormSection>
        </div>
    );
};
