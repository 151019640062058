import React from "react";
import ReactDOM from "react-dom";
import { history } from "@/store";

const links = driver => {
    const parent = document.getElementById("introduction_1_btn_wrapper");
    return (
        parent &&
        ReactDOM.render(
            <>
                <button
                    onClick={() => {
                        driver.reset();
                        history.push("/application/web/create");
                    }}
                >
                    Web
                </button>
                <button
                    onClick={() => {
                        driver.reset();
                        history.push("/application/ios/create");
                    }}
                >
                    iOS
                </button>
                <button
                    onClick={() => {
                        driver.reset();
                        history.push("/application/android/create");
                    }}
                >
                    Android
                </button>
            </>,
            parent,
        )
    );
};

const monkeyPatchHighlighted = driver => {
    if (driver) {
        const highlighted = driver.options.onHighlighted.bind(driver);
        driver.options.onHighlighted = elem => {
            highlighted(elem);
            if (elem.node.id === "application-page-button") {
                setTimeout(() => links(driver), 500);
            }
        };
    }
};

export const steps = driver => {
    monkeyPatchHighlighted(driver);

    return [
        {
            element: "#menu-list",
            popover: {
                title: "انتخاب پلتفرم",
                description:
                    "در این قسمت می‌توانید پلتفرم مورد نظرتان را با کلیک بر روی Android ، iOS و Web انتخاب کنید",
                position: "left",
            },
        },
        {
            element: "#profile-button",
            stageBackground: "#0863fb",
            popover: {
                title: "پروفایل و تنظیمات",
                description:
                    "با کلیک روی این آیکن می‌توانید اطلاعات حساب کاربری ، امور مالی ، توکن وب‌سرویس و ... را مشاهده کنید",
                position: "right",
            },
        },
        {
            element: "#sendNotifBtn",
            popover: {
                title: "ارسال اعلان",
                description:
                    "پس از ساخت اپلیکیشن با کلیک بر روی این دکمه می‌توانید به کاربران اپلیکیشن‌تان اعلان ارسال کنید",
                position: "left",
            },
        },
        {
            element: "#application-page-button",
            popover: {
                title: "اپلیکیشن‌ها",
                description: `
                    <div>
                        <p>برای ساخت و مشاهده اپلیکیشن‌هایتان می‌توانید به این صفحه بروید</p>
                        <p class="introduction_1_create_text">برای ساخت اپلیکیشن در هر پلتفرم روی دکمه مربوط به آن در زیر کلیک کنید</p>
                        <div class="introduction_1_btn_wrapper" id="introduction_1_btn_wrapper" />
                    </div>
                `,
            },
        },
    ];
};

export const mobileSteps = driver => {
    monkeyPatchHighlighted(driver);

    return [
        {
            element: "#mobile-nav-button",
            stageBackground: "transparent",
            popover: {
                title: "منو های کنسول",
                description:
                    "برای مشاهده منوهای کنسول و انتخاب پلتفرم و صفحه مورد نظر این دکمه را کلیک کنید",
                position: "left",
            },
            onNext: currentElem => {
                driver.preventMove();
                currentElem.node.click();
                setTimeout(() => driver.moveNext(), 400);
            },
        },
        {
            element: "#menu-list",
            stageBackground: "transparent",
            popover: {
                title: "انتخاب پلتفرم",
                description:
                    "در این قسمت می‌توانید پلتفرم مورد نظرتان را با کلیک بر روی Android ، iOS و Web انتخاب کنید",
                position: "top-center",
            },
        },
        {
            element: "#profile-button",
            stageBackground: "#0863fb",
            popover: {
                title: "پروفایل و تنظیمات",
                description:
                    "با کلیک روی این آیکن می‌توانید اطلاعات حساب کاربری ، امور مالی ، توکن وب‌سرویس و ... را مشاهده کنید",
                position: "right",
            },
        },
        {
            element: "#sendNotifBtn",
            stageBackground: "transparent",
            popover: {
                title: "ارسال اعلان",
                description:
                    "پس از ساخت اپلیکیشن با کلیک بر روی این دکمه می‌توانید به کاربران اپلیکیشن‌تان اعلان ارسال کنید",
                position: "bottom-center",
            },
        },
        {
            element: "#application-page-button",
            stageBackground: "transparent",
            popover: {
                title: "ساخت اپلیکیشن",
                description: `
                    <div>
                        <p>برای ساخت و مشاهده اپلیکیشن‌هایتان می‌توانید به این صفحه بروید</p>
                        <p class="introduction_1_create_text">برای ساخت اپلیکیشن در هر پلتفرم روی دکمه مربوط به آن در زیر کلیک کنید</p>
                        <div class="introduction_1_btn_wrapper" id="introduction_1_btn_wrapper" />
                    </div>
                `,
                position: "bottom-center",
            },
        },
    ];
};
