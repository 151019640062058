import { getApi, getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";
import { dataUrlToBlob } from "@/actions/_utils.jsx";
import { change, submit, destroy } from "redux-form";
import { push } from "react-router-redux";
import to from "await-to-js";

export const changePlatform =
    ({ platform, nextUrl, callback }) =>
    dispatch => {
        const url =
            nextUrl ||
            (platform === "android"
                ? `/dashboard/${platform}`
                : `/application/${platform}`);
        dispatch(push(url));

        dispatch({
            type: actionTypes.APP.CHANGE_PLATFORM,
            payload: platform,
        });

        dispatch(destroy("notification_wizard"));

        if (typeof callback === "function") callback();
    };

export function selectApplication(app_id) {
    return {
        type: actionTypes.APP.SELECT_APPLICATION,
        payload: app_id,
    };
}

export function reloadingPage(hasReload = false) {
    return function (dispatch) {
        dispatch({
            type: actionTypes.APP.HAS_UPDATE,
            payload: hasReload,
        });
    };
}

export function openModal(modalType, params = {}) {
    return {
        type: actionTypes.APP.OPEN_MODAL,
        payload: {
            modalType,
            params,
        },
    };
}

export function closeModal(formName) {
    return function (dispatch, getState) {
        dispatch({
            type: actionTypes.APP.CLOSE_MODAL,
            payload: null,
        });
        dispatch({
            type: actionTypes.APP.INPROG_DONE,
            payload: null,
        });

        const form = getState()["form"];

        if (formName) {
            dispatch(destroy(form[formName]));
        } else if (Object.keys(form).length > 0) {
            dispatch(destroy(Object.keys(form)[0]));
        }
    };
}

export function clearInprog(actionType) {
    return function (dispatch) {
        dispatch({
            type: actionType,
            payload: null,
        });
    };
}

export function uploadFile(type, dataUrl, onSuccess, onFailure) {
    return function () {
        const data = new FormData();
        data.append("image", dataUrlToBlob(dataUrl));
        // Todo: post url should be refactored
        getApi()
            .post(`files/${type}s/`, data, {
                onUploadProgress: progressEvent => {
                    // eslint-disable-next-line no-unused-vars
                    const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                    );
                },
            })
            .then(resp => {
                if (onSuccess) onSuccess(resp.data);
            })
            .catch(err => {
                if (onFailure) onFailure(err);
            });
    };
}

export const uploadPromptIcon =
    (type, dataUrl, onSuccess, onFailure) => async () => {
        const data = new FormData();
        data.append("image", dataUrlToBlob(dataUrl));

        const [error, response] = await to(
            getCommonApi().uploadPromptIcon(data),
        );

        if (error) {
            if (typeof onFailure === "function") onFailure();
            return;
        }

        if (typeof onSuccess === "function") onSuccess(response?.data);
    };

export const uploadNotificationCSVFile = (data, extra) => async () => {
    const [error, response] = await to(getCommonApi().uploadFilterFile(data));

    if (error) {
        // eslint-disable-next-line no-constant-condition
        if (typeof extra?.onFailure) extra.onFailure();
        return;
    }

    // eslint-disable-next-line no-constant-condition
    if (typeof extra?.onSuccess) extra.onSuccess(response?.data?.["csv_id"]);
};

export function deleteFile(type, onSuccess, onFailure) {
    return function (dispatch) {
        getApi()
            .delete(`files/${type}s/delete/`)
            .then(resp => {
                dispatch({
                    payload: resp.data,
                    type: actionTypes.AUTH.FETCH_PROFILE,
                });
                if (onSuccess) onSuccess();
            })
            .catch(() => {
                if (onFailure) onFailure();
            });
    };
}

export function toggleDemo() {
    return function () {
        return false; // ** Do not enter demo

        // const token = myStorage.getItem("token");

        // if (token === "demo") {
        //     // Logout of demo
        //     myStorage.removeItem("token");
        //     dispatch({ type: actionTypes.APP.TOGGLE_DEMO, payload: false });
        //     dispatch(push("/signin"));
        // } else {
        //     // Login to demo
        //     myStorage.setItem("token", "demo");
        //     dispatch(push("/signin")); // use another dispatch to prevent suddenly show demo page
        //     dispatch({ type: actionTypes.APP.TOGGLE_DEMO, payload: true });
        // }
    };
}

export function clearForm(formName) {
    return function (dispatch) {
        dispatch(destroy(formName));
    };
}

export function submitForm(formName) {
    return function (dispatch) {
        dispatch(submit(formName));
    };
}

export function changeFormField(formName, fieldName, fieldValue) {
    return function (dispatch) {
        dispatch(change(formName, fieldName, fieldValue));
    };
}

export const displayPermissionErrorModal =
    ({ errno, errmsg }) =>
    async dispatch => {
        dispatch({
            type: actionTypes.APP.DISPLAY_PERMISSION_ERROR,
            payload: {
                errno,
                errmsg,
            },
        });
    };
