import { getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";
import to from "await-to-js";
import { errorToast, successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";

export const fetchSetUpConfigEmail = options => async dispatch => {
    const { onSuccess, onFailure } = options;

    const [error, response] = await to(
        getCommonApi().fetchConfigSetUpEmailAPI(),
    );

    if (error) {
        if (onFailure) onFailure();
        // errorToast("خطا در دریافت جزئیات", processError(error))
    } else {
        dispatch({
            payload: response.data.results,
            type: actionTypes.CONFIG.EMAIL.FETCH_CONFIG_EMAIL,
        });
        if (onSuccess) onSuccess();
    }

    if (typeof options?.onFinish === "function") options.onFinish();
};
export const UpdateSetUpConfigEmail =
    (id, data, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().updateConfigSetUpEmailAPI(id, data),
        );
        if (error) {
            if (onFailure) onFailure();
            errorToast(
                TOAST_CONTENT.UPDATE_EMAIL_SETTINGS_FAILURE.TITLE,
                TOAST_CONTENT.UPDATE_EMAIL_SETTINGS_FAILURE.DESC,
            );
        }
        if (response?.data) {
            if (onSuccess) onSuccess();
            dispatch({
                payload: response.data,
                type: actionTypes.CONFIG.EMAIL.UPDATE_CONFIG_EMAIL,
            });
            successToast(
                TOAST_CONTENT.UPDATE_EMAIL_SETTINGS_SUCCESS.TITLE,
                TOAST_CONTENT.UPDATE_EMAIL_SETTINGS_SUCCESS.DESC,
            );
        }
    };
export const CreateSetUpConfigEmail =
    (data = {}, onSuccess, onFailure) =>
    async dispatch => {
        const { username } = data;
        data.sender_email = username;
        const [error, response] = await to(
            getCommonApi().crateConfigSetUpEmailAPI(data),
        );
        if (error) {
            if (onFailure) onFailure();
            errorToast(
                TOAST_CONTENT.CREATE_EMAIL_SETTINGS_FAILURE.TITLE,
                TOAST_CONTENT.CREATE_EMAIL_SETTINGS_FAILURE.DESC,
            );
        }
        if (response?.data) {
            if (onSuccess) onSuccess();
            dispatch({
                payload: response.data,
                type: actionTypes.CONFIG.EMAIL.CREATE_CONFIG_EMAIL,
            });
            successToast(
                TOAST_CONTENT.CREATE_EMAIL_SETTINGS_SUCCESS.TITLE,
                TOAST_CONTENT.CREATE_EMAIL_SETTINGS_SUCCESS.DESC,
            );
        }
    };

/// config action sms//////////////////////////////////////////////////////////////////////////////
export const fetchSetUpConfigSMS = options => async dispatch => {
    const { onSuccess, onFailure } = options;

    const [error, response] = await to(getCommonApi().fetchConfigSetUpSMSAPI());

    if (error) {
        if (onFailure) onFailure();
        // errorToast("خطا در دریافت جزئیات", processError(error))
    } else {
        dispatch({
            payload: response.data.results,
            type: actionTypes.CONFIG.SMS.FETCH_CONFIG_SMS,
        });
        if (onSuccess) onSuccess();
    }

    if (typeof options?.onFinish === "function") options.onFinish();
};
export const UpdateSetUpConfigSMS =
    (id, data, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().updateConfigSetUpSMSAPI(id, data),
        );
        if (error) {
            if (onFailure) onFailure();
            errorToast(
                TOAST_CONTENT.UPDATE_SMS_SETTINGS_FAILURE.TITLE,
                TOAST_CONTENT.UPDATE_SMS_SETTINGS_FAILURE.DESC,
            );
        }
        if (response?.data) {
            if (onSuccess) onSuccess();
            dispatch({
                payload: response.data,
                type: actionTypes.CONFIG.SMS.UPDATE_CONFIG_SMS,
            });
            successToast(
                TOAST_CONTENT.UPDATE_SMS_SETTINGS_SUCCESS.TITLE,
                TOAST_CONTENT.UPDATE_SMS_SETTINGS_SUCCESS.DESC,
            );
        }
    };
export const CreateSetUpConfigSMS =
    (data = {}, onSuccess, onFailure) =>
    async dispatch => {
        const [error, response] = await to(
            getCommonApi().crateConfigSetUpSMSAPI(data),
        );
        if (error) {
            if (onFailure) onFailure();
            errorToast(
                TOAST_CONTENT.CREATE_SMS_SETTINGS_FAILURE.TITLE,
                TOAST_CONTENT.CREATE_SMS_SETTINGS_FAILURE.DESC,
            );
        }
        if (response?.data) {
            if (onSuccess) onSuccess();
            dispatch({
                payload: response.data,
                type: actionTypes.CONFIG.SMS.CREATE_CONFIG_SMS,
            });
            successToast(
                TOAST_CONTENT.CREATE_SMS_SETTINGS_SUCCESS.TITLE,
                TOAST_CONTENT.CREATE_SMS_SETTINGS_SUCCESS.DESC,
            );
        }
    };
