import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import oneSignalIcon from "images/onesignal.png";
import { selectOptionBuilderFromArray } from "@/components/utils/finals/selectOptionBuilder";
import GenericSelectFieldComponent from "@/components/utilities/commons/wizard/components/GenericSelectFieldComponent";

const SelectApplicationComponent = props => {
    const { applications, hasOneSignal, platform } = props;
    return (
        <div className="notif-block">
            <header>
                <p>{`${
                    platform === "web" ? "وب سایت های" : "اپلیکیشن های"
                } من`}</p>
            </header>
            <div className="notif-block__body">
                <GenericSelectFieldComponent
                    fieldName="app_ids"
                    placeholder={`انتخاب ${
                        platform === "web" ? "وب سایت*" : "اپلیکیشن*"
                    } `}
                    noRow={true}
                    options={selectOptionBuilderFromArray(
                        applications,
                        "name",
                        "app_id",
                    )}
                />
            </div>

            {hasOneSignal && (
                <p className="notif-onesignal--desc">
                    استفاده از تمام امکانات پوشه برای اپ‌های منتقل شده از
                    وان‌سیگنال ممکن نیست. علامت{" "}
                    <img
                        src={oneSignalIcon}
                        className="notif-onesignal--image"
                    />{" "}
                    در کنار امکانات غیر قابل استفاده آمده است.
                </p>
            )}
        </div>
    );
};

SelectApplicationComponent.propTypes = {
    title: PropTypes.string,
    applications: PropTypes.array.isRequired,
    hasOneSignal: PropTypes.bool,
    change: PropTypes.func.isRequired,
    modelName: PropTypes.string,
    modelNamePlural: PropTypes.string,
};
SelectApplicationComponent.defaultProps = {
    title: "اپلیکیشن‌های من",
    modelName: "اپلیکیشن",
    modelNamePlural: "اپلیکیشن‌ها",
};

export default withErrorCatcher(SelectApplicationComponent);
