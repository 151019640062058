import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import css from "application/create/styles/createApp.module.scss";

const CredentialListItem = props => {
    const { client_key, server_key } = props;

    return (
        <div className={css.credential}>
            <div className={css.row}>
                <div className={css.col}>FCM Sender ID:</div>
                <div className={css.col}>{client_key}</div>
            </div>
            <div className={css.row}>
                <div className={css.col}>FCM Server Key:</div>
                <div className={css.col}>{server_key}</div>
            </div>
        </div>
    );
};
export default withErrorCatcher(CredentialListItem);
