
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`تنظیمات Gradle`}</h1>
    <blockquote>
      <h3 parentName="blockquote">{`برای مشاهده‌ی آموزش کامل به  `}<a parentName="h3" {...{
          "href": "https://docs.pushe.co/docs/android/intro/"
        }}>{`مستندات پوشه برای `}<strong parentName="a">{`Android studio`}</strong></a></h3>
      <p parentName="blockquote">{` مراجعه کنید.`}</p>
    </blockquote>
    <p>{`در فایل
`}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{`
ماژول اپلیکیشن خود در قسمت
‍`}<inlineCode parentName="p">{`dependencies`}</inlineCode>{`
عبارت زیر را اضافه کنید.`}</p>
    <pre><code parentName="pre" {...{}}>{`implementation 'co.pushe.plus:base:2.5.0'
`}</code></pre>
    <p>{`سپس
`}<inlineCode parentName="p">{`gradle`}</inlineCode>{`
را سینک کنید.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;