import actionTypes from "@/actions/_types";

const initial_state = {
    geofence: {},
    geofences: {
        offset: 0,
        hasMore: true,
        items: [],
    },
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case actionTypes.GEOFENCE.CREATE_GEOFENCE:
            return {
                ...state,
                geofences: {
                    ...state.geofences,
                    items: [action.payload, ...state.geofences.items],
                },
            };
        case actionTypes.GEOFENCE.UPDATE_GEOFENCE:
            return {
                ...state,
                geofences: {
                    ...state.geofences,
                    items: state.geofences.items.map(geofence => {
                        return geofence.geofence_id ===
                            action.payload.geofence_id
                            ? action.payload
                            : geofence;
                    }),
                },
            };
        case actionTypes.GEOFENCE.DELETE_GEOFENCE:
            return {
                ...state,
                geofences: {
                    ...state.geofences,
                    items: state.geofences.items.filter(
                        geofence =>
                            geofence.geofence_id !== action.payload.geofence_id,
                    ),
                },
            };
        case actionTypes.GEOFENCE.RETRIEVE_GEOFENCE_LIST:
            return {
                ...state,
                geofences: {
                    ...state.geofences,
                    offset: action.payload.offset,
                    hasMore: action.payload.hasMore,
                    items: action.payload.shouldReset
                        ? [...action.payload.items]
                        : [...state.geofences.items, ...action.payload.items],
                },
            };
        case actionTypes.GEOFENCE.RETRIEVE_GEOFENCE:
            return {
                ...state,
                geofence: {
                    ...state.geofence,
                    [action.payload.geofence_id]: action.payload,
                },
            };
        case actionTypes.GEOFENCE.UPDATE_GEOFENCE_LOCAL_STATE:
            return {
                ...state,
                geofence: {
                    ...state.geofence,
                    [action.payload.id]: action.payload.data,
                },
            };
        case actionTypes.GEOFENCE.CLEAR_FETCHED_GEOFENCE_ITEMS:
            return {
                ...state,
                geofences: {
                    ...state.geofences,
                    offset: 0,
                    hasMore: true,
                    items: [],
                },
            };
        default:
            return state;
    }
};
