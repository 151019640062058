import React from "react";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { blue900 } from "material-ui/styles/colors";
import {
    ArrowLeft,
    ArrowRight,
    TestNotification,
} from "@/components/utils/lib/svgicons";
import FlatButton from "material-ui/FlatButton";
import ReactTooltip from "react-tooltip";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 0.3125rem !important",

        "& > span": {
            transition: "all .3s",
            color: `${theme.palette.dashboard.blue} !important`,
        },
        "&:hover": {
            backgroundColor: `${theme.palette.dashboard.blue} !important`,
            "&:hover > span": {
                transition: "all .3s",
                color: `${theme.palette.dashboard.white} !important`,
            },
        },
    },
}));

const WizardFooter = ({ ...props }) => {
    const {
        isFinalStep,
        isFirstStep,
        onPrevClick,
        onNextClick,
        isSendInProgress,
        onConfirmNotificationSend,
        hideNextBtn,
        onSubmitTest,
        onSubmit,
        onSubmitDraft,
        hideSubmitBtn,
        showTestBtn,
        hasDraftBtn,
        buttonOptions: {
            prevButtonName,
            nextButtonName,
            testButtonName,
            submitButtonName,
            finalButtonName,
        },
    } = props;
    const classes = useStyles();

    return (
        <div className="notif-footer">
            <div className="notif-footer__wrap">
                <div className="notif-footer__nav">
                    {(!isFirstStep || isFinalStep) && (
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--gray pull-right"
                            rippleColor={blue900}
                            label={prevButtonName || "قبلی"}
                            onClick={onPrevClick}
                            icon={<ArrowRight viewBox="0 0 16 16" />}
                        />
                    )}

                    {!hideNextBtn && (
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--blue pull-left"
                            rippleColor={blue900}
                            label={nextButtonName || "بعدی"}
                            labelPosition="before"
                            onClick={onNextClick}
                            icon={<ArrowLeft viewBox="0 0 16 16" />}
                        />
                    )}
                </div>

                <div className="notif-footer__main">
                    {/* Final Step Confirm Button */}
                    {isFinalStep && (
                        <FlatButton
                            className="mui-btn mui-btn--confirm mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            disabled={isSendInProgress}
                            label={
                                isSendInProgress ? (
                                    <CircleLoader
                                        size={25}
                                        color="#fff"
                                        style={{ top: "2px" }}
                                    />
                                ) : (
                                    `${finalButtonName || "بلی اطمینان دارم"}`
                                )
                            }
                            onClick={onConfirmNotificationSend}
                        />
                    )}

                    {/* Test Notification Button */}
                    {!isFinalStep &&
                        onSubmitTest &&
                        showTestBtn &&
                        !hasDraftBtn && (
                            <FlatButton
                                className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                                rippleColor={blue900}
                                label={testButtonName || "ارسال آزمایشی"}
                                onClick={onSubmitTest}
                            />
                        )}
                    {!isFinalStep && hasDraftBtn && (
                        <>
                            <IconButton
                                data-tip={testButtonName || "ارسال آزمایشی"}
                                classes={{ root: classes.root }}
                                onClick={onSubmitTest}
                            >
                                <TestNotification
                                    style={{ width: "21px", height: "21px" }}
                                />
                            </IconButton>
                            <FlatButton
                                className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                                rippleColor={blue900}
                                label={"ذخیره پیشنویس"}
                                onClick={onSubmitDraft}
                            />
                        </>
                    )}

                    {/* Save or Show Finale Button */}
                    {!isFinalStep && onSubmit && !hideSubmitBtn && (
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            disabled={isSendInProgress}
                            label={
                                isSendInProgress ? (
                                    <CircleLoader
                                        size={25}
                                        color="#fff"
                                        style={{ top: "2px" }}
                                    />
                                ) : (
                                    submitButtonName || "ارسال نهایی"
                                )
                            }
                            onClick={onSubmit}
                        />
                    )}
                </div>
            </div>
            <ReactTooltip className="tooltip" effect="solid" place="right" />
        </div>
    );
};

WizardFooter.propTypes = {
    onSubmit: PropTypes.func,
    isFinalStep: PropTypes.bool,
    onSubmitTest: PropTypes.func,
    isSendInProgress: PropTypes.bool,
    isFirstStep: PropTypes.bool.isRequired,
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    hideNextBtn: PropTypes.bool.isRequired,
    onConfirmNotificationSend: PropTypes.func,
    notificationMode: PropTypes.number,
    hideSubmitBtn: PropTypes.bool,
    showTestBtn: PropTypes.bool,
};

WizardFooter.defaultProps = {
    showTestBtn: false,
};

export default withErrorCatcher(WizardFooter);
