import React, { useState, useEffect } from "react";
import { getFormValues } from "redux-form";
import classNames from "classnames";
import { withErrorCatcher } from "@/components/utilities";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { useDispatch, useSelector } from "react-redux";
import WebFiltersList from "@/components/notifications/notification/create/web/parials/WebFiltersList";

const NotificationWebFiltersTab = props => {
    const { change } = props;
    const dispatch = useDispatch();
    const [fields, setFields] = useState([]);

    const { form } = useReduxForm();
    const { filters, app_ids } = useSelector(
        state => getFormValues(form)(state) ?? {},
    );

    const deviceTypes = useSelector(state => state?.inprog?.deviceTypes ?? []);
    const browserList = useSelector(state => state?.inprog?.browserList ?? []);

    useEffect(() => {
        const keys = Object.keys(filters ?? {}).map(i => `filters.${i}`);
        setFields(
            WebFiltersList.filter(i => keys.indexOf(i.reduxKey) > -1) ?? [],
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleFields = field => {
        const clonedFields = [...fields];
        const foundIndex = clonedFields.findIndex(i => i.key === field.key);

        if (foundIndex > -1) {
            // Remove
            clonedFields.splice(foundIndex, 1);
            setFields(clonedFields);
        } else {
            // Append
            setFields([...fields, field]);
        }
    };

    const DeviceTypes = (dev => dev.map(i => ({ key: i, name: i })))(
        deviceTypes,
    );
    const BrowserList = (dev => dev.map(i => ({ key: i, name: i })))(
        browserList,
    );

    return (
        <div id="filterTabsWrap">
            <div className="row row--20">
                {WebFiltersList.map(item => {
                    const isActive = item.isActive(filters);
                    const isDisabled = item.isDisabled;

                    return (
                        <div className="col-sm-3 col-xs-6" key={item.key}>
                            <div
                                className={classNames("card-b", {
                                    "card-b--active": isActive,
                                    "field-disable": isDisabled,
                                })}
                                data-tip={item?.tooltip}
                                tabIndex="0"
                                onClick={() => {
                                    if (isDisabled) return;
                                    const val = isActive
                                        ? ""
                                        : item.reduxKey === "csv"
                                        ? {}
                                        : [];

                                    item.action({
                                        dispatch,
                                        app_id: Array.isArray(app_ids)
                                            ? app_ids[0]
                                            : app_ids,
                                    });

                                    change(item.reduxKey, val);

                                    toggleFields(item);
                                }}
                            >
                                <svg>
                                    <use href={`#${item.icon}`} />
                                </svg>
                                <p>{item.title}</p>
                            </div>
                        </div>
                    );
                })}

                <div>
                    {fields.map(field => (
                        <field._parentComponent
                            key={field.key}
                            name={field.reduxKey}
                            title={field.title}
                            component={field._childComponent}
                            change={change}
                            {...field.childExtraProps({
                                DeviceTypes,
                                BrowserList,
                            })}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default withErrorCatcher(NotificationWebFiltersTab);
