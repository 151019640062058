import React from "react";
import {
    CircleLoader,
    pagePermissionWrapper,
    PERMISSIONS,
    useHasPermission,
    withErrorCatcher,
} from "@/components/utilities";
import "styles/modules/_mod-segments.scss";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import { bindActionCreators } from "redux";
import {
    deleteGeofence,
    exportCSVGeofence,
    retrieveGeofenceList,
    toggleEnableGeofence,
} from "@/actions/geofence";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import GeofenceTableBody from "@/components/notifications/geofence/list/GeofenceTableBody";
import FixedPageHeader from "@/components/utilities/commons/FixedPageHeader";
import RefreshButton from "@/components/utilities/commons/RefreshButton";
import ReactTooltip from "react-tooltip";
import GeofencePagePlaceholder from "@/components/notifications/geofence/list/GeofencePagePlaceholder";
import { useFetchGeofenceItems } from "@/components/notifications/geofence/hooks/useFetchGeofenceItems";
import WayPoint from "react-waypoint";

const GeofencePage = props => {
    const { deleteGeofence, toggleEnableGeofence, exportCSVGeofence, history } =
        props;
    const hasCreatePermission = useHasPermission(PERMISSIONS.GEOFENCE_CREATE);

    const {
        geofenceItems,
        hasMore,
        loading,
        handleFetchGeofenceItems,
        handleRefreshGeofenceItems,
    } = useFetchGeofenceItems();

    if (isEmpty(geofenceItems) && !loading)
        return <GeofencePagePlaceholder history={history} />;

    return (
        <div className="segments table--featured">
            {!isEmpty(geofenceItems) && !loading && (
                <FixedPageHeader>
                    <div className="segments__page-header">
                        <header>
                            <p>نوتیفیکیشن جغرافیایی</p>
                        </header>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <RefreshButton
                                onClick={handleRefreshGeofenceItems}
                                animationIsRunning={loading}
                                tooltipPosition={"right"}
                            />

                            <span
                                data-tip={
                                    hasCreatePermission
                                        ? ""
                                        : "برای ساخت نوتیفیکیشن جغرافیایی باید پنل خود را ارتقا دهید"
                                }
                            >
                                <FlatButton
                                    disabled={!hasCreatePermission}
                                    className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                                    rippleColor={blue900}
                                    label="نوتیفیکیشن جغرافیایی جدید"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() =>
                                        history.push(
                                            `/notification/geofence/android/create/`,
                                        )
                                    }
                                />
                            </span>
                        </div>
                    </div>
                </FixedPageHeader>
            )}

            {!isEmpty(geofenceItems) && (
                <GeofenceTableBody
                    history={history}
                    geofenceList={geofenceItems}
                    deleteGeofence={deleteGeofence}
                    toggleEnableGeofence={toggleEnableGeofence}
                    exportCSVGeofence={exportCSVGeofence}
                />
            )}

            <div className="financial__loader">
                {loading && <CircleLoader size={30} />}
                {!loading &&
                    !hasMore &&
                    (!Array.isArray(geofenceItems) ||
                        geofenceItems.length === 0) && (
                        <p style={{ textAlign: "center", padding: "0 0 20px" }}>
                            اطلاعات جدیدی وجود ندارد
                        </p>
                    )}
            </div>

            <WayPoint onEnter={handleFetchGeofenceItems} />

            <ReactTooltip className="tooltip" effect="solid" place="right" />
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            deleteGeofence,
            toggleEnableGeofence,
            retrieveGeofenceList,
            exportCSVGeofence,
        },
        dispatch,
    );
};

export default pagePermissionWrapper(
    PERMISSIONS.GEOFENCE_LIST,
    withErrorCatcher(connect(null, mapDispatchToProps)(GeofencePage)),
);
