import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import IconButton from "material-ui/IconButton";
import { Refresh } from "@/components/utils/lib/svgicons";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";

const CreateAppBar = props => {
    const { fetchApplication, history } = props;

    return (
        <div className="segments__page-header">
            <p className="main-block__title">اپلیکیشن‌ها</p>

            <div className="main-block__util">
                <IconButton
                    className="icon-btn"
                    data-tip="به‌روز‌رسانی"
                    tooltipPosition="top-center"
                    onClick={() => fetchApplication({ platform: "android" })}
                >
                    <Refresh viewBox="0 0 18 17" />
                </IconButton>

                <span data-tip={""}>
                    <FlatButton
                        id="newApplication"
                        className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue "
                        rippleColor={blue900}
                        label={"اپلیکیشن‌ جدید"}
                        onClick={() => {
                            history.push(`/application/android/create`);
                        }}
                    />
                </span>
            </div>
        </div>
    );
};

export default withErrorCatcher(CreateAppBar);
