import { Field } from "redux-form";
import FilterSelectionField from "@/components/utils/FilterSelectionField";
import { brands, cities, networks, operators } from "@/constants";
import { store } from "@/store";
import { getApplicationVersion } from "@/actions/applications";

export default [
    {
        title: "موقعیت مکانی",
        key: "state",
        reduxKey: "filters.state",
        icon: "svg_filter_location",
        isActive: filters => !!filters?.state,
        isDisabled: false,
        tooltip: undefined,
        action: () => {},
        _parentComponent: Field,
        _childComponent: FilterSelectionField,
        childExtraProps: () => ({
            list: cities,
        }),
    },
    {
        title: "اپراتور",
        key: "operator",
        reduxKey: "filters.operator",
        icon: "svg_filter_operator",
        isActive: filters => !!filters?.operator,
        isDisabled: false,
        tooltip: undefined,
        action: () => {},
        _parentComponent: Field,
        _childComponent: FilterSelectionField,
        childExtraProps: () => ({
            list: operators,
        }),
    },
    {
        title: "اتصال به اینترنت",
        key: "mobile_net",
        reduxKey: "filters.mobile_net",
        icon: "svg_filter_net_type",
        isActive: filters => !!filters?.mobile_net,
        isDisabled: false,
        tooltip: undefined,
        action: () => {},
        _parentComponent: Field,
        _childComponent: FilterSelectionField,
        childExtraProps: () => ({
            list: networks,
        }),
    },
    {
        title: "برند گوشی",
        key: "brand",
        reduxKey: "filters.brand",
        icon: "svg_filter_phone_brand",
        isActive: filters => !!filters?.brand,
        isDisabled: false,
        tooltip: undefined,
        action: () => {},
        _parentComponent: Field,
        _childComponent: FilterSelectionField,
        childExtraProps: () => ({
            list: brands,
        }),
    },
    {
        title: "نسخه اپلیکیشن",
        key: "app_version",
        reduxKey: "filters.app_version",
        icon: "svg_filter_version",
        isActive: filters => !!filters?.app_version,
        isDisabled: false,
        tooltip: undefined,
        action: ({ dispatch, app_id }) => {
            if (app_id)
                getApplicationVersion({ app_id })(dispatch, store.getState);
        },
        _parentComponent: Field,
        _childComponent: FilterSelectionField,
        childExtraProps: ({ applicationVersion }) => ({
            list: applicationVersion,
        }),
    },
];
