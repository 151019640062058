import { mapValues, isEmpty, omitBy } from "lodash";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";

const URL_RE = new RegExp(/\w+(?::\/\/).*$/);
const HTTP_URL_RE = new RegExp(/^(?:http(s)?:\/\/).*$/);
const DOMAIN_RE = /^[\w_-]+(?:[.][\w_-]{2,})+$/;
const SUB_DOMAIN_RE = /^[\w_-]+$/;
// const STARTS_WITH_HTTP = new RegExp(
//     /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
// );
// const URI_RE = new RegExp(/\w+:(\/?\/?)[^\s]+/);
// const TELEGRAM_RE = new RegExp(/^\S+$/);
export const TELEGRAM_RE = new RegExp(
    /^(https?:\/\/)?(t|telegram)?(.me\/)?(\S+)$/,
);
// const CELLPHONE_RE = new RegExp(/^(0|\+98|0098)9[\d]{9}$/);
const CELLPHONE_RE = new RegExp(/^[0-9]+$/);
const PHONE_RE = new RegExp(/^[0-9#*]+$/);
const EMAIL_RE = new RegExp(/\S+@\S+\.\S+/);
const NOT_EMPTY_RE = new RegExp(/^(?!\s*$).+/);
const PACKAGE_RE = new RegExp(/^(?=[a-zA-Z])[a-zA-Z0-9-_.]*$/);

function performValidation(validators, values, allValues, props) {
    function doValidation(validator, value, key, allValues) {
        const funcList = Array.isArray(validator) ? validator : [validator];
        for (const validator of funcList) {
            const result = validator(value, key, allValues, props);
            if (result !== null && result !== undefined) {
                return result;
            }
        }
    }

    let result;
    if (typeof validators === "function") {
        result = doValidation(validators, values, null, allValues);
    } else {
        result = mapValues(validators, (validator, key) => {
            if (typeof validator === "object" && !Array.isArray(validator)) {
                return performValidation(
                    validator,
                    values[key],
                    allValues,
                    props,
                );
            }
            return doValidation(validator, (values || {})[key], key, allValues);
        });
    }

    if (typeof result === "object") {
        result = omitBy(result, value => value === undefined);
        if (isEmpty(result)) {
            result = undefined;
        }
    }
    return result;
}

/**
 * TODO docs
 * @param validators
 */
export function createValidator(validators) {
    return (allValues, props) => {
        return performValidation(validators, allValues, allValues, props);
    };
}

export function arrayValidator(validators) {
    return (valueList, baseKey, allValues, props) => {
        const result = (valueList || []).map(value =>
            performValidation(validators, value, allValues, props),
        );
        return result.some(x => x && !isEmpty(x)) ? result : null;
    };
}

/**
 * Use this to change the error message of a validator
 *
 * ```
 * const validator = withMessage(isRequired, 'Please enter the title')
 * ```
 *
 * @param validator The original validator
 * @param message The new error message to use
 */
export const withMessage = (validator, message) => (value, key, values) =>
    validator(value, key, values) == null ? undefined : message;
export const isRequired = value =>
    value && (typeof value !== "string" || NOT_EMPTY_RE.test(value))
        ? undefined
        : FORM_ERRORS.REQUIRED_FIELD;
export const isRequiredList = value =>
    Array.isArray(value) && !isEmpty(value)
        ? undefined
        : FORM_ERRORS.REQUIRED_FIELD;
export const isPassword = value =>
    value.length < 6 ? "رمز عبور باید حداقل ۶ کاراکتر باشد" : undefined;
export const repeatPassword = (value, key, values) =>
    values["password"] === value
        ? undefined
        : "عدم تطابق تکرار رمز عبور با رمز عبور";
export const customRepeatPass = passwordName => (value, key, values) =>
    value !== values[passwordName] && values[passwordName]
        ? "عدم مطابقت تکرار رمز عبور با رمز عبور"
        : undefined;
export const isNumber = value =>
    typeof value === "number"
        ? undefined
        : "مقدار وارد شده باید از نوع عدد باشد";
export const isSmsSenderNumber = value =>
    /^\d*$/.test(value) || !value ? undefined : "شماره وارد شده صحیح نمی‌باشد";
export const isEmail = value =>
    EMAIL_RE.test(value) || !value ? undefined : "ایمیل صحیح نیست";
export const isFinancialNumber = value =>
    /^\d{12}$/.test(value) || !value ? undefined : "شماره اقتصادی صحیح نیست";
export const isNationalLegalNumber = value =>
    /^\d{11}$/.test(value) || !value ? undefined : "شناسه ملی صحیح نیست";
export const isPostalCode = value =>
    /^\d{10}$/.test(value) || !value ? undefined : "کد پستی صحیح نیست";
export const isUrl = value =>
    URL_RE.test(value) ? undefined : "آدرس وارد شده صحیح نیست";
export const isHtppUrl = value =>
    HTTP_URL_RE.test(value)
        ? undefined
        : "آدرس لینک باید با فرمت http(s)://example.com باشد";
export const isCellPhoneNumber = value =>
    CELLPHONE_RE.test(value) ? undefined : "شماره وارد شده صحیح نیست";
export const isPhoneNumber = value =>
    PHONE_RE.test(value) ? undefined : "شماره وارد شده صحیح نیست";
export const isAndroidPackageName = value =>
    PACKAGE_RE.test(value) ? undefined : "مقدار وارد شده صحیح نیست";
export const isObject = value => typeof value === "object";
export const isDomainName = value =>
    DOMAIN_RE.test(value) ? undefined : "دامنه وارد شده صحیح نیست";
export const isSubDomainName = (value, key, values) =>
    !values.is_https
        ? value == null || isEmpty(value)
            ? "این فیلد الزامی است"
            : SUB_DOMAIN_RE.test(value)
            ? undefined
            : "زیردامنه وارد شده صحیح نیست"
        : undefined;
export const isFarsiCharacter = value =>
    !new RegExp("[a-zA-Z0-9]", "ig").test(value) || !value
        ? undefined
        : "فقط کاراکتر فارسی مجاز می باشد";
export const minValue = min => value =>
    value && value < min
        ? `مقدار وارد شده می‌بایست بزرگتر مساوی ${min} باشد`
        : undefined;
export const maxValue = max => value =>
    value && value > max
        ? `مقدار وارد شده می‌بایست کوچکتر مساوی ${max} باشد`
        : undefined;
export const minTimeLengthValidator = length => value =>
    value && value < length
        ? `زمان وارد شده می‌بایست بزرگتر مساوی ${length} ثانیه باشد`
        : undefined;
export const maxTimeLengthValidator = length => value =>
    value && value > length
        ? `زمان وارد شده می‌بایست کوچکتر مساوی ${length} ثانیه باشد`
        : undefined;
export const listOfValidatorsWithMessage = errorList => {
    if (!Array.isArray(errorList) || errorList.length < 1) return undefined;
    for (let i = 0; i < errorList.length; i++) {
        const item = errorList[i];
        const result = withMessage(item.validator, item.message);
        if (result) return result;
    }
    return undefined;
};

export const isJsonString = value => {
    if (
        value == null ||
        value.trim().length === 0 ||
        !value.trim().startsWith("{")
    ) {
        return "جی‌سان وارد شده معتبر نیست";
    }

    try {
        JSON.parse(value);
    } catch (e) {
        return "جی‌سان وارد شده معتبر نیست";
    }
};
