import React from "react";
import IosInstallationsList from "@/components/users/installations/ios/IosInstallationsList";
import AndroidInstallationsList from "@/components/users/installations/android/AndroidInstallationsList";
import PropTypes from "prop-types";
import WebInstallationsList from "@/components/users/installations/web/WebInstallationsList";

const InstallationsPage = ({ match: { params }, ...props }) => {
    const platform = params.platform;
    const InstallationPage = INSTALLATION_PAGE[platform];

    return <InstallationPage platform={platform} {...props} />;
};

InstallationsPage.propTypes = {
    match: PropTypes.object.isRequired,
};

InstallationsPage.defaultProps = {};

const INSTALLATION_PAGE = {
    android: AndroidInstallationsList,
    ios: IosInstallationsList,
    web: WebInstallationsList,
};

export default InstallationsPage;
