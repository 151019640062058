import React, { useState } from "react";
import css from "application/create/styles/createApp.module.scss";
import {
    RadioButtons,
    ReduxCheckbox,
    ReduxSimpleTextField,
    withErrorCatcher,
} from "@/components/utilities";
import { Field } from "redux-form";
import {
    WEB_FRAMEWORKS,
    WEB_PUSH_DOMAIN_FOR_HTTP,
} from "application/constants";
import NotificationImageUpload from "@/components/utilities/commons/wizard/controls/NotificationImageUpload";
import { useDispatch } from "react-redux";
import { uploadFile } from "@/actions/app";
const WEBSITE_TYPE = {
    HTTPS: "1",
    HTTP: "2",
};

const WebsiteDetails = props => {
    const { change } = props;
    const [websiteType, setWebsiteType] = useState(WEBSITE_TYPE.HTTPS);
    const dispatch = useDispatch();
    const toggleUploaderProgress = status => {
        change("uploaderInProgress", status);
    };
    return (
        <div className={css.createAppDetails}>
            <div className={css.createAppHeading}>ایجاد وب سایت</div>

            <div className={css.createAppBody}>
                <div className={css.flexRow}>
                    <Field
                        name={"name"}
                        label={"نام وب‌سایت"}
                        component={ReduxSimpleTextField}
                        required
                        helperText={" "}
                    />

                    <Field
                        name={"domain"}
                        label={"دامنه وب‌سایت"}
                        component={ReduxSimpleTextField}
                        required
                        helperText={
                            "دامنه سایت خود را بدون http و www وارد کنید"
                        }
                    />
                </div>

                <div className={css.verticalPadding}>
                    <header>
                        <p>
                            بارگذاری آیکن سایت
                            <span style={{ fontSize: 10 }}>
                                (حداکثر حجم مجاز برای بارگذاری 1 مگابایت می
                                باشد)
                            </span>
                        </p>
                    </header>
                    <div className="notif-block__body">
                        <div
                            className="col-sm-12 col-xs-12"
                            style={{ height: "240px" }}
                        >
                            <div style={{ width: "100%", height: "100%" }}>
                                <Field
                                    component={NotificationImageUpload}
                                    name="icon_url"
                                    type="image"
                                    title="بارگذاری آیکن سایت"
                                    subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                    uploadFile={(...arag) =>
                                        uploadFile(...arag)(dispatch)
                                    }
                                    aspectRatio={1}
                                    format={null}
                                    toggleUploaderProgress={
                                        toggleUploaderProgress
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css.verticalPadding}>
                    <Field
                        name={"framework"}
                        label={"سایت در محیط وردپرس ساخته شده است"}
                        component={ReduxCheckbox}
                        normalize={val =>
                            val
                                ? WEB_FRAMEWORKS.WORDPRESS
                                : WEB_FRAMEWORKS.NORMAL
                        }
                        format={val => val === WEB_FRAMEWORKS.WORDPRESS}
                    />
                </div>
                <div className={css.verticalPadding}>
                    <RadioButtons
                        options={[
                            {
                                label: "وب‌سایت از https پشتیبانی می کند",
                                value: WEBSITE_TYPE.HTTPS,
                            },
                            {
                                label: "وب‌سایت تنها با http کار می کند",
                                value: WEBSITE_TYPE.HTTP,
                            },
                        ]}
                        onChange={value => {
                            setWebsiteType(value);
                            change("is_https", value === WEBSITE_TYPE.HTTPS);
                        }}
                        defaultValue={WEBSITE_TYPE.HTTPS}
                    />

                    {websiteType === WEBSITE_TYPE.HTTP && (
                        <div className={css.httpSubdomainWrapper}>
                            <p>
                                با توجه به اینکه ‌سایت شما از HTTPS پشتیبانی
                                نمی‌کند نیاز است که برای شما یک زیر دامنه از
                                سایت پوشه ایجاد کنیم.
                            </p>

                            <div className={css.httpSubdomainTextWrapper}>
                                <span className={css.httpSubdomain}>
                                    .{WEB_PUSH_DOMAIN_FOR_HTTP}
                                </span>
                                <Field
                                    name={"pushe_subdomain"}
                                    label={"نام ‌زیردامنه"}
                                    component={ReduxSimpleTextField}
                                />
                            </div>

                            <p className={css.httpNote}>
                                * دقت کنید که نام انتخاب شده هنگام نمایش
                                نوتیفیکیشن توسط مرورگر به کاربر نمایش داده
                                می‌شود.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withErrorCatcher(WebsiteDetails);
