/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import Toggle from "material-ui/Toggle";
import IconButton from "material-ui/IconButton";
import FlatButton from "material-ui/FlatButton";
import FloatingActionButton from "material-ui/FloatingActionButton";
import { blue900 } from "material-ui/styles/colors";
import { PopoverAnimationVertical } from "material-ui/Popover";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import breakpoint from "@/components/utils/responsive/breakpoint";
import eventHandler from "@/components/utils/events/eventHandler";
import {
    Pencil,
    Notification,
    GraduationHat,
    Search,
    Trash,
    Flag,
    FilterThick,
    Close,
    Download,
    Eye,
    ThreeDot,
    Clipboard,
} from "@/components/utils/lib/svgicons";
import Colors from "@/components/utils/lib/colors";
import Util from "@/utils";
import Input from "@/components/utils/input";
import Select from "@/components/utilities/commons/select";
import "../styles/style.scss";
import app_blank from "images/app-icon/app-blank.png";
import {
    FRAMEWORK_CAST_VALUE_INTO_NAME,
    FRAMEWORK_CAST_VALUE_INTO_TUTORIAL_LINK,
} from "@/actions/_types";
import { withErrorCatcher } from "@/components/utilities";
import FrameworkModal from "application/components/modals/FrameworkModal";
import WayPoint from "react-waypoint";
import Loading from "@/components/utils/loading";
import uuid from "uuid/v4";
import ModalHmsCredential from "application/components/modals/ModalHmsCredential";
import { submitHMSCredential } from "@/actions/applications";

class MobileAppTable extends React.Component {
    constructor(props) {
        super(props);
        this.constants = {
            timeFilterOptions: [
                { id: 0, title: "زمان ایجاد", value: "" },
                { id: 1, title: "یک روز گذشته", value: 1 },
                { id: 2, title: "یک هفته‌ گذشته", value: 7 },
                { id: 3, title: "دو هفته‌ گذشته", value: 14 },
                { id: 4, title: "یک ماه گذشته", value: 30 },
            ],
        };

        this.styles = {
            iconButtonUser: {
                display: "inline-block",
                verticalAlign: "middle",
                width: "1.875rem",
                height: "1.875rem",
                padding: "0",
            },
            menu: {
                // shown in wW < 1200px
                item: {
                    main: {
                        lineHeight: "2.375rem",
                        minHeight: "2.375rem",
                    },
                    innerDiv: {
                        padding: "0 3.125rem 0 0.3125rem",
                        color: Colors.gray_8,
                        fontSize: "0.875rem",
                    },
                    icon: {
                        margin: "0.375rem 0.75",
                        fill: Colors.gray_8,
                    },
                },
            },
            manifest: {
                builder: null,
                packageName: null,
                clientKey: null,
            },
        };

        this.state = {
            isFilterOpen: false,
            data: [],
            offset: 0,
            limit: 20,
            items: [],
            isLoading: false,
            searchAppId: null,
            searchAppName: null,
            searchTime: null,
            isDoneLoading: false,
            appRowHeight: 85,
            isSmallSize: false,
            showHmsModal: false,
            onClickHMSModal: null,
            showFrameworkModal: false,
            app_ID_HMS: null,
            onClickFrameworkModal: null,
            isLoadingNewRows: false,
        };
    }

    componentDidMount() {
        this.handleResize();
        eventHandler.on("resize", () => this.handleResize(), {
            key: "resizeMobileTable",
        });
    }

    componentWillUnmount() {
        eventHandler.remove("resize", "resizeMobileTable");
    }

    handleResize() {
        let rowHeight;
        let isSmallSize;

        if (breakpoint(window, "lg")) {
            // minWidth = 1202px
            rowHeight = 85;
            isSmallSize = false;
        } else {
            rowHeight = 260;
            isSmallSize = true;
        }

        this.setState({
            isSmallSize,
            appRowHeight: rowHeight,
        });
    }

    //////////////////////////////
    //    Columns of Each Row   //
    //////////////////////////////

    renderAppNameColumn({
        name,
        icon_url,
        aggregator,
        package_name,
        isSmallSize,
        app_id,
    }) {
        let icon = icon_url || app_blank;
        let classes = !isSmallSize
            ? "table-col table-col--16 table-col--right"
            : "small-appName";

        return (
            <div className={classes}>
                <div className="card-table" style={{ padding: "0 12px" }}>
                    {aggregator && (
                        <IconButton
                            className="table-btn card-table__flag active"
                            data-tip="نشان شده"
                            tooltipPosition="top-center"
                        >
                            <Flag viewBox="0 0 19 19" />
                        </IconButton>
                    )}
                    <img src={icon} />
                    <div className="card-table__text">
                        <span>{name}</span>
                        <p>{package_name}</p>
                        <p>
                            <span style={{ color: "#ccc", fontSize: ".9rem" }}>
                                app_id:
                            </span>{" "}
                            {app_id}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    renderDateTimeColumn({ app = {}, isSmallSize }) {
        const { created_at } = app;
        let classes = !isSmallSize
            ? "table-col table-col--15 color-gray-95"
            : "small-dateTime";

        return (
            <div className={classes}>
                {Util._convertToLocalTimezone(created_at)}
            </div>
        );
    }
    renderHmsHuaweiColumn({
        app = {},
        isSmallSize,
        framework_name,
        hms_credential,
    }) {
        let classes = !isSmallSize
            ? "table-col table-col--1 color-gray-95"
            : "small-appBuilder";

        return (
            <div className={classes}>
                {hms_credential?.app_id ? (
                    <img
                        src={Util._generateImageUrl("huawei")}
                        alt={"huawei"}
                        data-tip={" کریدنشیال هواوی"}
                        style={{ maxWidth: "40px", maxHeight: "30px" }}
                    />
                ) : (
                    <a
                        href={void 0}
                        onClick={() => this.handleModalHMS("set", { ...app })}
                        style={{ cursor: "pointer" }}
                    >
                        <img
                            src={Util._generateImageUrl("huaweiGray")}
                            alt={"huaweiGray"}
                            data-tip={" ایجاد کریدنشیال هواوی "}
                            style={{ maxWidth: "40px", maxHeight: "30px" }}
                        />
                    </a>
                )}
            </div>
        );
    }

    renderAppBuilderColumn({ app = {}, framework_name, isSmallSize }) {
        let classes = !isSmallSize
            ? "table-col table-col--1 color-gray-95"
            : "small-appBuilder";

        return (
            <div className={classes}>
                <a
                    href={void 0}
                    onClick={() =>
                        this.handleFrameworkOrGuide("set", {
                            ...app,
                            page: null,
                        })
                    }
                    style={{ cursor: "pointer" }}
                >
                    <img
                        src={Util._generateImageUrl(framework_name)}
                        alt={framework_name}
                        data-tip={
                            framework_name === "AppBuilder"
                                ? "اپلیکیشن ساز"
                                : framework_name
                        }
                        style={{ maxWidth: "40px", maxHeight: "30px" }}
                    />
                </a>
            </div>
        );
    }

    renderWelcomeColumn({ app = {}, isSmallSize }) {
        const {
            welcomeNotification,
            activateWelcomeNotification,
            deactivateWelcomeNotification,
        } = this.props;
        let classes = !isSmallSize
            ? "table-col table-col--14 table-col--center"
            : "small-welcome";

        return (
            <div className={classes}>
                <Toggle
                    className="toggle"
                    style={{
                        cursor: "pointer",
                        zIndex: 0,
                        margin: "0 auto",
                        width: "auto",
                    }}
                    labelPosition="right"
                    label="پیام خوش‌آمد"
                    data-tip={
                        app.has_one_signal_user
                            ? "استفاده از این عملکرد برای اپ وانسیگنال امکان پذیر نمی‌باشد"
                            : "پیام خوش‌آمد"
                    }
                    toggled={app.welcome_active}
                    onToggle={(event, isInputChecked) => {
                        if (app.has_one_signal_user) return;

                        if (!app.welcome_template_v2) {
                            welcomeNotification(app);
                        } else if (isInputChecked) {
                            activateWelcomeNotification(app);
                        } else {
                            deactivateWelcomeNotification(app);
                        }
                    }}
                    thumbSwitchedStyle={{ backgroundColor: "#0065ff" }}
                    trackSwitchedStyle={{ backgroundColor: "#0065ff30" }}
                />
                {app.welcome_template_v2 && (
                    <IconButton
                        className={classNames("table-btn", {
                            "field-disable": !app.welcome_template_v2,
                        })}
                        data-tip="ویرایش پیام خوش‌آمد"
                        disabled={app.has_one_signal_user}
                        onClick={() => welcomeNotification(app)}
                    >
                        <Pencil viewBox="0 0 24 24" />
                    </IconButton>
                )}

                <ReactTooltip className="tooltip" effect="solid" />
            </div>
        );
    }

    renderUpdateMessageColumn({ app = {}, isSmallSize }) {
        const { updateNotification } = this.props;
        let classes = !isSmallSize
            ? "table-col table-col--1"
            : "small-updateMessage";

        return (
            <div className={classes}>
                <div
                    data-tip={
                        app.has_one_signal_user
                            ? "استفاده از این عملکرد برای اپ وانسیگنال امکان پذیر نمی‌باشد"
                            : app.transactional_users === 0
                            ? "اپ شما هنوز نصبی ندارد"
                            : ""
                    }
                >
                    <IconButton
                        className="table-btn notification-icon"
                        data-tip="پیام بروزرسانی"
                        disabled={
                            app.has_one_signal_user ||
                            app.transactional_users === 0
                        }
                        onClick={() => updateNotification(app)}
                    >
                        <Notification viewBox="0 0 21 21" />
                    </IconButton>
                </div>
                {isSmallSize && <span className="title">پیام بروزرسانی</span>}
                <ReactTooltip className="tooltip" effect="solid" />
            </div>
        );
    }

    renderNumberOfUsersColumn({ app = {}, framework_tutorial, isSmallSize }) {
        const { app_id, framework, transactional_users } = app;
        let classes = !isSmallSize
            ? "table-col table-col--14"
            : "small-numberOfUsers";

        return (
            <div className={classes}>
                {transactional_users ? (
                    <div style={{ display: "inline-block" }}>
                        <span className="text-fa">{transactional_users}</span>
                    </div>
                ) : (
                    <div style={{ display: "inline-block" }}>
                        {!isSmallSize && (
                            <FlatButton
                                className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                                rippleColor={blue900}
                                label="آموزش"
                                data-tip={
                                    app.has_one_signal_user
                                        ? "استفاده از این عملکرد برای اپ وانسیگنال امکان پذیر نمی‌باشد"
                                        : ""
                                }
                                disabled={app.has_one_signal_user}
                                onClick={() =>
                                    this.handleFrameworkOrGuide("set", {
                                        app_id,
                                        framework,
                                        page: "guide",
                                    })
                                }
                            />
                        )}
                    </div>
                )}

                <ReactTooltip className="tooltip" effect="solid" />
            </div>
        );
    }

    renderCardMenus({ app = {}, framework_tutorial, isSmallSize }) {
        const {
            onOpenAppTokenModal,
            onUpdateApplication,
            onDeleteApplication,
            onGetInstallationHistory,
        } = this.props;
        const { framework, app_id } = app;
        let classes = !isSmallSize
            ? "table-col table-col--12"
            : "small-CardMenus";

        return (
            <div className={classes}>
                <IconMenu
                    className=""
                    iconButtonElement={
                        <IconButton>
                            <ThreeDot viewBox="0 0 20 20" />
                        </IconButton>
                    }
                    animation={PopoverAnimationVertical}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    targetOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MenuItem
                        innerDivStyle={this.styles.menu.item.innerDiv}
                        style={this.styles.menu.item.main}
                        primaryText="توکن یکپارچه سازی با دیگر سرویسها"
                        leftIcon={
                            <Clipboard
                                viewBox="0 0 561 561"
                                style={this.styles.menu.item.icon}
                            />
                        }
                        onClick={() => onOpenAppTokenModal(app)}
                    />
                    <MenuItem
                        innerDivStyle={this.styles.menu.item.innerDiv}
                        style={this.styles.menu.item.main}
                        primaryText="تغییر نام اپلیکیشن"
                        leftIcon={
                            <Pencil
                                viewBox="0 0 24 24"
                                style={this.styles.menu.item.icon}
                            />
                        }
                        onClick={() => onUpdateApplication(app)}
                    />
                    <MenuItem
                        innerDivStyle={this.styles.menu.item.innerDiv}
                        style={this.styles.menu.item.main}
                        primaryText="حذف اپلیکیشن"
                        leftIcon={
                            <Trash
                                viewBox="0 0 19 23"
                                style={this.styles.menu.item.icon}
                            />
                        }
                        onClick={() => onDeleteApplication(app)}
                    />
                    {!app.has_one_signal_user && (
                        <>
                            <MenuItem
                                innerDivStyle={this.styles.menu.item.innerDiv}
                                style={this.styles.menu.item.main}
                                primaryText={"دریافت تاریخچه نصب"}
                                leftIcon={
                                    <Download
                                        viewBox="0 0 19 23"
                                        style={this.styles.menu.item.icon}
                                    />
                                }
                                onClick={() => onGetInstallationHistory(app)}
                            />
                            <MenuItem
                                innerDivStyle={this.styles.menu.item.innerDiv}
                                style={this.styles.menu.item.main}
                                primaryText={
                                    framework === "AppBuilder"
                                        ? "نمایش توکن"
                                        : "نمایش منیفست"
                                }
                                leftIcon={
                                    <Eye
                                        viewBox="0 0 19 23"
                                        style={this.styles.menu.item.icon}
                                    />
                                }
                                onClick={() =>
                                    this.handleFrameworkOrGuide("set", {
                                        app_id,
                                        framework,
                                        page: "manifest",
                                    })
                                }
                            />
                            {!isSmallSize && (
                                <MenuItem
                                    innerDivStyle={
                                        this.styles.menu.item.innerDiv
                                    }
                                    style={this.styles.menu.item.main}
                                    primaryText="آموزش نصب"
                                    leftIcon={
                                        <GraduationHat
                                            viewBox="0 0 29 21"
                                            style={this.styles.menu.item.icon}
                                        />
                                    }
                                    onClick={() =>
                                        this.handleFrameworkOrGuide("set", {
                                            app_id,
                                            framework,
                                            page: "guide",
                                        })
                                    }
                                />
                            )}
                        </>
                    )}
                </IconMenu>

                {isSmallSize && !app.has_one_signal_user && (
                    <div className="">
                        <FlatButton
                            className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                            rippleColor={blue900}
                            label="آموزش"
                            onClick={() =>
                                this.handleFrameworkOrGuide("set", {
                                    app_id,
                                    framework,
                                    page: "guide",
                                })
                            }
                        />
                    </div>
                )}

                <ReactTooltip className="tooltip" effect="solid" />
            </div>
        );
    }

    renderTableFilter() {
        const { isFilterOpen, searchAppName, searchAppId } = this.state;

        return (
            <div
                className={classNames("table-filter", {
                    "table-filter--show": isFilterOpen,
                })}
            >
                <div
                    className="table-filter__bg"
                    onClick={() => this.setState({ isFilterOpen: false })}
                />
                <div className="table-filter__in">
                    <div className="table-filter__form">
                        <div className="table-row">
                            <div className="table-col table-col--25">
                                <Input
                                    className="input--border-round"
                                    placeholder="نام اپلیکیشن"
                                    value={searchAppName}
                                    onChange={e => {
                                        let searchAppName = e.target.value;
                                        this.setState(() => ({
                                            searchAppName,
                                            offset: 0,
                                        }));
                                    }}
                                />
                                {searchAppName ? (
                                    <Close
                                        className="input--close-icon"
                                        viewBox="0 0 26 26"
                                        style={{ fill: "#b3b3b3" }}
                                        onClick={() => {
                                            this.setState(() => ({
                                                searchAppName: "",
                                                offset: 0,
                                            }));
                                        }}
                                    />
                                ) : (
                                    <Search
                                        className="input--search-icon"
                                        viewBox="0 0 26 26"
                                        style={{ fill: "#b3b3b3" }}
                                    />
                                )}
                            </div>
                            <div className="table-col table-col--25">
                                <Input
                                    className="input--border-round"
                                    placeholder="نام بسته اپلیکیشن"
                                    value={searchAppId}
                                    onChange={e => {
                                        let searchAppId = e.target.value;
                                        this.setState(() => ({
                                            searchAppId,
                                            offset: 0,
                                        }));
                                    }}
                                />
                                {searchAppId ? (
                                    <Close
                                        className="input--close-icon"
                                        viewBox="0 0 26 26"
                                        style={{ fill: "#b3b3b3" }}
                                        onClick={() => {
                                            this.setState(() => ({
                                                searchAppId: "",
                                                offset: 0,
                                            }));
                                        }}
                                    />
                                ) : (
                                    <Search
                                        className="input--search-icon"
                                        viewBox="0 0 26 26"
                                        style={{ fill: "#b3b3b3" }}
                                    />
                                )}
                            </div>

                            <div className="table-col table-col--25">
                                <Select
                                    defaultSelect={
                                        this.constants.timeFilterOptions[0]
                                    }
                                    className="select--border-round"
                                    itemList={this.constants.timeFilterOptions}
                                    onItemChange={item => {
                                        let searchTime = item.value;
                                        this.setState(() => ({
                                            searchTime,
                                            offset: 0,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className="table-filter__submit"
                            onClick={() =>
                                this.setState({ isFilterOpen: false })
                            }
                        >
                            <FlatButton
                                className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                                rippleColor={blue900}
                                label="اعمال فیلتر"
                            />
                        </div>
                    </div>
                    <button
                        className="table-filter__close btn-close btn-close--md btn-close--darkblue"
                        onClick={() => this.setState({ isFilterOpen: false })}
                    ></button>
                </div>
                <div className="table-filter__btn-wrap">
                    <FloatingActionButton
                        className="btn-filter"
                        onClick={() => this.setState({ isFilterOpen: true })}
                    >
                        <FilterThick viewBox="0 0 22 22.321" />
                    </FloatingActionButton>
                </div>

                <ReactTooltip className="tooltip" effect="solid" />
            </div>
        );
    }

    /*
     ** Decide whether to show framework modal
     * or change to guide/manifest page
     */
    handleFrameworkOrGuide(
        type = "unset",
        { app_id, framework = null, page = "guide" } = {},
    ) {
        const handleSet = () => {
            const { history, updateApplication } = this.props;
            const changePageAction = () =>
                history.push(`/application/android/${page}/${app_id}`);
            const onClickFrameworkModal = framework =>
                updateApplication(
                    {
                        framework,
                        app_id,
                        platform: "android",
                    },
                    () => {
                        if (page !== null) {
                            // change page
                            changePageAction();
                        } else {
                            // close modal
                            this.setState({
                                showFrameworkModal: false,
                                onClickFrameworkModal: null,
                            });
                        }
                    },
                );

            // framework already set then redirect to page
            if (framework && page !== null) {
                changePageAction();
            }

            this.setState(() => ({
                showFrameworkModal: true,
                onClickFrameworkModal,
            }));
        };

        const handleUnset = () => {
            this.setState(() => ({
                showFrameworkModal: false,
                onClickFrameworkModal: null,
            }));
        };

        switch (type) {
            case "set":
                handleSet();
                break;
            case "unset":
                handleUnset();
                break;
        }
    }
    handleModalHMS(
        type = "unset",
        { app_id, HMS_data = null, page = "guide" } = {},
    ) {
        const { fetchApplications, platform } = this.props;
        fetchApplications({ platform: "android" });
        const handleSet = () => {
            const { history, updateApplication } = this.props;
            const onClickHMSModal = HMS_data =>
                updateApplication(
                    {
                        HMS_data,
                        app_id,
                        platform: "android",
                    },
                    () => {
                        if (HMS_data !== null) {
                            submitHMSCredential(
                                app_id,
                                { ...HMS_data },
                                fetchApplications,
                            );
                            this.setState({
                                showHmsModal: false,
                                onClickHMSModal: null,
                            });
                        }
                    },
                );

            this.setState(() => ({
                showHmsModal: true,
                onClickHMSModal,
            }));
        };

        const handleUnset = () => {
            this.setState(() => ({
                showHmsModal: false,
            }));
        };
        switch (type) {
            case "set":
                handleSet();
                break;
            case "unset":
                handleUnset();
                break;
        }
    }
    handleOffset() {
        this.setState(
            () => ({
                isLoadingNewRows: true,
            }),
            () => {
                setTimeout(() => {
                    this.setState(prevState => ({
                        offset: prevState.offset + 10,
                        isLoadingNewRows: false,
                    }));
                }, 1000);
            },
        );
    }

    renderRows() {
        const {
            offset,
            searchAppId = "",
            searchAppName = "",
            searchTime = "",
            isSmallSize,
        } = this.state;
        const { applications = [] } = this.props;

        let results = [...applications].filter(app => {
            if (searchAppId)
                return app["app_id"]
                    .toLowerCase()
                    .includes(searchAppId.toLowerCase());
            if (searchAppName)
                return app["name"]
                    .toLowerCase()
                    .includes(searchAppName.toLowerCase());
            if (searchTime)
                return Util._isOlderThan(searchTime, app.created_at);
            return true;
        });

        results = results.slice(0, offset + 10);

        if (results.length > 0) {
            return results.map(app => {
                const {
                    name,
                    icon_url,
                    framework,
                    aggregator,
                    package_name,
                    transactional_users,
                    has_one_signal_user,
                    app_id,
                    hms_credential,
                } = app;
                const framework_name = has_one_signal_user
                    ? "OneSignal"
                    : FRAMEWORK_CAST_VALUE_INTO_NAME[framework];
                const framework_tutorial =
                    FRAMEWORK_CAST_VALUE_INTO_TUTORIAL_LINK[framework];

                return (
                    <div
                        key={uuid()}
                        className={classNames("table-row", {
                            "table-row--featured": transactional_users > 0,
                            small: isSmallSize,
                        })}
                    >
                        <div>
                            {this.renderAppNameColumn({
                                aggregator,
                                name,
                                icon_url,
                                package_name,
                                isSmallSize,
                                app_id,
                            })}
                            {this.renderHmsHuaweiColumn({
                                app,
                                framework_name,
                                isSmallSize,
                                hms_credential,
                            })}
                            {this.renderDateTimeColumn({ app, isSmallSize })}
                            {this.renderAppBuilderColumn({
                                app,
                                framework_name,
                                isSmallSize,
                            })}
                            {this.renderWelcomeColumn({ app, isSmallSize })}
                            {/*{this.renderUpdateMessageColumn({app, isSmallSize})}*/}
                            {this.renderNumberOfUsersColumn({
                                app,
                                framework_tutorial,
                                isSmallSize,
                            })}
                            {this.renderCardMenus({
                                app,
                                framework_tutorial,
                                isSmallSize,
                            })}
                        </div>
                    </div>
                );
            });
        } else {
            return (
                <div className="table-empty">
                    <p>اطلاعاتی برای نمایش وجود ندارد!</p>
                </div>
            );
        }
    }

    render() {
        const {
            showFrameworkModal,
            onClickFrameworkModal,
            isLoadingNewRows,
            showHmsModal,
            onClickHMSModal,
        } = this.state;
        return (
            <>
                <div className="main-block__body">
                    <div className="table table--featured table--app table--lg">
                        {this.renderTableFilter()}
                        <div className="table-head">
                            <div className="table-row">
                                <div className="table-col table-col--15 table-col--right">
                                    <p style={{ paddingRight: "30px" }}>
                                        نام اپلیکیشن
                                    </p>
                                </div>
                                <div className="table-col table-col--1">
                                    کردینشیال هواوی
                                </div>
                                <div className="table-col table-col--15">
                                    تاریخ ایجاد
                                </div>
                                <div className="table-col table-col--1">
                                    ابزار
                                </div>
                                <div className="table-col table-col--14">
                                    پیام خوش‌‌ آمد
                                </div>
                                {/*<div className="table-col table-col--1">پیام به‌روز‌رسانی</div>*/}
                                <div className="table-col table-col--14">
                                    تعداد کاربر
                                </div>
                                <div className="table-col table-col--12">
                                    امکانات
                                </div>
                            </div>
                        </div>
                        <div className="table-body mobile-table">
                            {this.renderRows()}
                        </div>
                        <WayPoint onEnter={() => this.handleOffset()}>
                            {isLoadingNewRows && (
                                <div className="table-loading">
                                    <Loading className="loading-circle--blue-gray loading-circle-xlarge" />
                                </div>
                            )}
                        </WayPoint>
                        <br />
                    </div>
                </div>

                {showFrameworkModal && (
                    <FrameworkModal
                        onClick={onClickFrameworkModal}
                        onClose={() => this.handleFrameworkOrGuide("unset")}
                    />
                )}
                {showHmsModal && (
                    <ModalHmsCredential
                        onClick={onClickHMSModal}
                        onClose={() => this.handleModalHMS("unset")}
                    />
                )}

                <ReactTooltip className="tooltip" effect="solid" />
            </>
        );
    }
}

MobileAppTable.propTypes = {
    data: PropTypes.array.isRequired,
    onDeleteApplication: PropTypes.func.isRequired,
    onUpdateApplication: PropTypes.func.isRequired,
    onCreateWelcomeNotification: PropTypes.func.isRequired,
    onSelectingFramework: PropTypes.func.isRequired,
    activateWelcomeNotification: PropTypes.func.isRequired,
    deactivateWelcomeNotification: PropTypes.func.isRequired,
};

export default withErrorCatcher(MobileAppTable);
