import React from "react";
import { useSelector } from "react-redux";
import { hasPermission } from "@/components/utilities/permission/featurePermissions";
import { PermissionPlaceholder } from "@/components/utilities/extra/PermissionPlaceholders";

/**
 * if hasPermission true return renderComponent else return defaultComponent
 *
 * @param hasPermission boolean
 * @param renderComponent any
 * @param defaultComponent any
 * @returns *
 */
const renderPermission = (
    hasPermission,
    renderComponent,
    defaultComponent = null,
) => (hasPermission ? renderComponent : defaultComponent);

/**
 * pass a ``permission name`` and this function check if user has the permission or not and then render the component
 *
 * @param permission "@/components/utilities/permission/permissions"
 * @param renderComponent
 * @param defaultComponent
 * @returns component to render
 */
const renderWithPermission = (
    permission,
    renderComponent,
    defaultComponent = null,
) => (hasPermission(permission) ? renderComponent : defaultComponent);

/**
 * A Higher Order Component that check if user has the permission the render the passed component
 * otherwise render the fallback component
 *
 * @param permission
 * @param RenderComponent
 * @param DefaultComponent
 * @returns {function(*): *}
 */
const pagePermissionWrapper = (
    permission,
    RenderComponent,
    DefaultComponent = PermissionPlaceholder,
) => {
    const innerPagePermissionWrapper = props => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const permissions = useSelector(
            state => state?.auth?.profile?.permissions ?? [],
        );

        return permissions.indexOf(permission) > -1 ? (
            <RenderComponent {...props} />
        ) : (
            <DefaultComponent isPage />
        );
    };
    return innerPagePermissionWrapper;
};

const useHasPermission = permission => {
    const permissions = useSelector(
        state => state?.auth?.profile?.permissions ?? [],
    );

    return permissions.indexOf(permission) > -1;
};

export {
    renderPermission,
    renderWithPermission,
    pagePermissionWrapper,
    useHasPermission,
};
