export const FORM_ERRORS = {
    REQUIRED_FIELD: "این فیلد الزامی است",
    INVALID_INPUT: "ورودی نامعتبر است",
    NO_APP_SELECTED: "هیچ اپلیکیشنی برای ارسال انتخاب نشده است",
    NOTIFICATION_TIME_ERROR:
        "زمان انتخابی باید حداقل از پانزده دقیقه دیگر باشد",
    PUBLISH_TIME_ERROR: "زمان انتخابی باید حداقل از ۲۴ ساعت دیگر باشد",
    NOTIFICATION_TIME_INCOMPLETE_ERROR:
        "مشخصی نمودن روز و ساعت نوتیفیکیشن الزامی است",
    INVALID_URL_HTTP: "آدرس لینک باید با فرمت http(s)://example.com باشد",

    NOTIFICATION_FORM: {
        REQUIRED_TYPE: "نوع نوتیفیکیشن انتخاب نشده است",
        ICON_IS_REQUIRED: "لطفا آیکن را انتخاب کنید",
        REQUIRED_ICON_AND_FIELD: "متن و آیکن الزامی هستند",
        REQUIRED_CONTENT_FIELD: "متن الزامی هستند",
        ALL_FIELDS_REQUIRED: "پر کردن تمامی فیلدها الزامی است",
        REQUIRED_TITLE: "تیتر نوتیفیکیشن را وارد کنید",
        REQUIRED_CONTENT: "متن نوتیفیکیشن را وارد کنید",
        INVALID_ACTION: "عملیات وارد شده معتبر نیست",
        REQUIRED_BTN_AND_ACTION: "متن و عملکرد الزامی می باشد",
    },

    APPLICATION_FORM: {
        APP_ID_NAME_FORMAT:
            "اپ‌ آی‌دی باید با عدد یا کاراکتر شروع شود و با نقطه یا آندرلاین جدا شود",
    },

    TICKET_FORM: {
        INVALID_NAME: "نام وارد شده معتبر نیست",
        INVALID_EMAIL: "آدرس ایمیل نادرست است",
        INVALID_SUBJECT: "عنوان وارد شده معتبر نیست",
        INVALID_MESSAGE: "سوال وارد شده معتبر نیست",
    },

    EMAIL_FORM: {
        REQUIRED_TITLE: "عنوان ایمیل اجباری است",
        REQUIRED_CONTENT: "متن ایمیل اجباری است",
        REQUIRED_HTML: "وارد کردن html اجباری است",
    },
};

export const TOAST_CONTENT = {
    INVOICE_PAYMENT_SUCCESS: {
        TITLE: "پرداخت موفق",
        DESC: "پرداخت شما با موفقیت انجام شد",
    },

    INVOICE_PAYMENT_FAILURE: {
        TITLE: "خطا در پرداخت",
        DESC:
            "پرداخت شما ناموفق بود ،" +
            " در صورت کم شدن مبلغ از حساب‌تان تا ۲۴ ساعت آینده این مبلغ به حساب شما باز خواهد گشت",
    },

    DEFAULT_ERROR_TOAST: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطایی رخ داده‌است، لطفا مجددا تلاش کنید",
    },

    CHOOSE_AT_LEAST_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "حتما یکی باید انتخاب شده باشد",
    },

    UPLOAD_ICON_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "ابتدا آیکون انتخاب شده را آپلود نمایید",
    },

    SERVER_ERROR: {
        TITLE: "خطا در اتصال با سرور",
        DESC: "لطفا مجددا تلاش نمایید",
    },

    NETWORK_ERROR: {
        TITLE: "خطا در برقراری ارتباط با شبکه",
        DESC: "لطفا اتصال خود به اینترنت را چک کنید",
    },

    SERVER_TIMEOUT_ERR: {
        TITLE: "خطا در دریافت پاسخ از سرور",
        DESC: "قبل از ارسال درخواست جدید به سرور ، لطفا از نتیجه درخواست قبلی خود اطلاع حاصل کنید",
    },

    SEND_TICKET_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "پیام شما با موفقیت ارسال شد",
    },
    SEND_TICKET_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "در ارسال تیکت خطایی رخ داد. لطفا دوباره تلاش کنید",
    },

    UPLOAD_IN_PROGRESS: {
        TITLE: "خطا ، آپلود فایل ناتمام",
        DESC: "لطفا ابتدا آپلود فایل خود را به اتمام برسانید",
    },

    UPLOAD_PICTURE_SUCCESS: {
        TITLE: "عکس با موفقیت آپلود شد",
        DESC: "عکس شما با موفقیت ذخیره شد",
    },
    UPLOAD_FILE_SUCCESS: {
        TITLE: "فایل با موفقیت آپلود شد",
        DESC: "فایل شما با موفقیت ذخیره شد",
    },
    UPLOAD_PICTURE_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "در اپلود عکس خطایی رخ داد. لطفا دوباره تلاش کنید",
    },
    UPLOAD_FILE_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "در اپلود فایل خطایی رخ داد. لطفا دوباره تلاش کنید",
    },
    FILE_SELECT_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "فایل انتخاب شده صحیح نمی‌باشد",
    },
    PICTURE_SIZE_EXCEEDED: {
        TITLE: "خطایی رخ داد!",
        DESC: "حجم فایل انتخاب‌شده از 1مگابایت بیشتر نمی‌تواند باشد",
    },
    FILE_SIZE_EXCEEDED: {
        TITLE: "خطایی رخ داد!",
        DESC: "حجم فایل انتخاب‌ شده از حجم مجاز آن بیشتر است",
    },

    PROFILE_CHANGE_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "پروفایل شما با موفقیت تغییر کرد",
    },

    SET_EMAIL_CHANGE_IN_PROFILE: {
        TITLE: "درخواست تغییر پروفایل با موفقیت انجام گرفت",
        DESC: "برای تایید تغییر ایمیل بر روی لینک فعال سازی که به ایمیل‌تان ارسال شده کلیک کنید.",
    },

    TEST_NOTIFICATION_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "نوتیفیکیشن تستی با موفقیت ارسال شد",
    },

    TEST_NOTIFICATION_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "ارسال نوتیفیکیشن با خطا مواجه شده است. لطفا مجددا تلاش کنید",
    },

    CREATE_APPLICATION_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "اپلیکیشن جدید با موفقیت ساخته شد",
    },

    DELETE_APPLICATION_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "اپلیکیشن شما با موفقیت پاک شد",
    },

    NOTIFICATION_CREATE_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "نوتیفیکیشن با موفقیت ساخته شد",
    },

    NOTIFICATION_DELETE_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "نوتیفیکیشن با موفقیت پاک شد",
    },

    NOTIFICATION_RESEND_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "ارسال مجدد نوتیفیکیشن با موفقیت ساخته شد",
    },

    NOTIFICATION_SHARE_URL_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "آدرس اشتراک‌گذاری نوتیفیکیشن با موفقیت ساخته شد.",
    },

    NOTIFICATION_FETCH_ERROR: {
        TITLE: "دریافت اطلاعات نوتیفیکیشن با موفقیت انجام نشد.",
        DESC: "لطفا دوباره تلاش کنید.",
    },

    NOTIFICATION_CREATE_ERROR: {
        TITLE: "خطا در ارسال نوتیفیکیشن",
        DESC: "لطفا دوباره تلاش کنید.",
    },

    APPLICATION_FETCH_ERROR: {
        TITLE: "دریافت اطلاعات اپلیکیشن با موفقیت انجام نشد.",
        DESC: "لطفا دوباره تلاش کنید.",
    },

    EXPORT_CV_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "",
    },

    COPY_LINK_SHARE_TO_CLIPBOARD_SUCCESS: {
        TITLE: "کپی با موفقیت انجام شد",
        DESC: "متن با موفقیت به کلیپ‌بورد اضافه شد",
    },

    CHANGE_PLAN_ON_SUCCESS: {
        TITLE: "تغییر سرویس",
        DESC: "تغییر سرویس شما با موفقیت انجام شد",
    },

    CHANGE_PLAN_ON_FAILURE: {
        TITLE: " تغییر سرویس",
        DESC: "خطا در تغییر سرویس",
    },

    FETCH_TRANSFER_APP_SUCCESS: {
        TITLE: "انتقال اپ",
        DESC: "انتقال اپ با موفقیت انجام شد",
    },

    RENDER_ERROR: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطایی در نمایش صفحه رخ داد، لطفا با پشتیبانی پوشه تماس بگیرید.",
    },

    REFRESH_API_TOKEN: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطا در دریافت مجدد توکن",
    },

    MANIFEST_COPY_SUCCESS: {
        TITLE: "کپی با موفقیت انجام شد",
        DESC: "متن با موفقیت به کلیپ‌بورد اضافه شد",
    },

    SERVICEWORKER_SUBSCRIBE_FAILURE: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطا در subscribe کردن وب‌پوش",
    },

    SEND_EMAIL_SUCCESS: {
        TITLE: "ارسال موفق",
        DESC: "ایمیل شما با موفقیت ارسال شد",
    },

    SEND_SMS_SUCCESS: {
        TITLE: "ارسال موفق",
        DESC: "پیامک شما با موفقیت ارسال شد",
    },

    DRAFT_CREATE_FAILURE: {
        TITLE: "خطایی رخ داد!",
        DESC: "لطفا مجددا تلاش کنید",
    },

    DRAFT_CREATE_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "پیش‌نویس با موفقیت ساخته شد",
    },

    DRAFT_SEND_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "پیش‌نویس با موفقیت ارسال شد",
    },

    DRAFT_UPDATE_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "پیش‌نویس با موفقیت بروزرسانی شد",
    },

    DRAFT_UPDATE_FAILURE: {
        TITLE: "خطایی رخ داد!",
        DESC: "عدم موفقیت در ویرایش پیش‌نویس",
    },
    UPDATE_EMAIL_SETTINGS_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "تنظیمات ایمیل با موفقیت تغییر کرد",
    },
    CREATE_EMAIL_SETTINGS_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "تنظیمات ایمیل با موفقیت ثبت شد",
    },
    UPDATE_EMAIL_SETTINGS_FAILURE: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطا در تغییر تنظیمات ایمیل",
    },
    CREATE_EMAIL_SETTINGS_FAILURE: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطا در ثبت تنظیمات ایمیل",
    },
    UPDATE_SMS_SETTINGS_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "تنظیمات SMS با موفقیت تغییر کرد",
    },
    CREATE_SMS_SETTINGS_SUCCESS: {
        TITLE: "درخواست شما با موفقیت انجام گرفت",
        DESC: "تنظیمات SMS با موفقیت ثبت شد",
    },
    UPDATE_SMS_SETTINGS_FAILURE: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطا در تغییر تنظیمات SMS",
    },
    CREATE_SMS_SETTINGS_FAILURE: {
        TITLE: "خطایی رخ داد!",
        DESC: "خطا در ثبت تنظیمات SMS",
    },
};
