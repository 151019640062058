/**
 * Accepts a string that may contains Arabic or Farsi characters and return with all numbers converted to English
 *
 * @param input: string
 */
export function toEnglishNumbers(input) {
    if (typeof input === "undefined" || input === null) {
        return "";
    }

    const persianNumbers = [
        /۰/g,
        /۱/g,
        /۲/g,
        /۳/g,
        /۴/g,
        /۵/g,
        /۶/g,
        /۷/g,
        /۸/g,
        /۹/g,
    ];
    const arabicNumbers = [
        /٠/g,
        /١/g,
        /٢/g,
        /٣/g,
        /٤/g,
        /٥/g,
        /٦/g,
        /٧/g,
        /٨/g,
        /٩/g,
    ];

    let result = input;

    for (let i = 0; i < 10; i++) {
        result = result.replace(persianNumbers[i], i.toString());
        result = result.replace(arabicNumbers[i], i.toString());
    }

    return result;
}

/**
 * Accepts an English number of a string contains of English numbers and translate them to Farsi numbers
 *
 * @param input: string | number
 */
export function toFarsiNumbers(input) {
    if (typeof input === "undefined" || input === null) {
        return "";
    }

    const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

    let result = input.toString();

    for (let i = 0; i < 10; i++) {
        const searchValue = new RegExp(i.toString(), "g");
        result = result.replace(searchValue, persianNumbers[i]);
    }

    return result;
}
