import React from "react";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { FlatButton } from "@/components/utilities/elements/FlatButton";

const NoCredentialDialog = props => {
    const { onClose, title, content } = props;

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle className="p-dialog__no-credential--title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="p-dialog__no-credential--text">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <FlatButton onClick={onClose}>متوجه شدم</FlatButton>
            </DialogActions>
        </Dialog>
    );
};

export const withEmailCredential = WrappedComponent => {
    return function EmailCredentialWrapper(props) {
        const { history } = props;
        const has_email_credential = useSelector(
            s => s?.auth?.profile?.["has_email_credential"],
        );

        if (has_email_credential) return <WrappedComponent {...props} />;

        return (
            <NoCredentialDialog
                onClose={() => history.goBack()}
                title="ثبت نشدن کریدنشیال ایمیل"
                content={
                    <>
                        هنوز تنظیمات ایمیل برای پنل شما ثبت نشده و به همین علت
                        امکان ارسال ایمیل وجود ندارد. برای ثبت تنظیمات با
                        پشتیبانی پوشه به ایمیل <span>hi@pushe.co</span> تماس
                        بگیرید.
                    </>
                }
            />
        );
    };
};

export const withSMSCredential = WrappedComponent => {
    return function SMSCredentialWrapper(props) {
        const { history } = props;
        const has_sms_credential = useSelector(
            s => s?.auth?.profile?.["has_sms_credential"],
        );

        if (has_sms_credential) return <WrappedComponent {...props} />;

        return (
            <NoCredentialDialog
                onClose={() => history.goBack()}
                title="ثبت نشدن کریدنشیال پیامک"
                content={
                    <>
                        هنوز تنظیمات پیامک برای پنل شما ثبت نشده و به همین علت
                        امکان ارسال پیامک وجود ندارد. برای ثبت تنظیمات با
                        پشتیبانی پوشه به ایمیل <span>hi@pushe.co</span> تماس
                        بگیرید.
                    </>
                }
            />
        );
    };
};
