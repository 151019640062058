import UnPaidPermissionModal from "@/components/utilities/modal/permission/UnPaidPermissionModal";
import { useSelector } from "react-redux";

export const useDisplayPermissionModal = () => {
    const { errno } = useSelector(state => state?.app?.permissionError ?? {});

    const showPermissionModal = typeof errno === "number" && errno > -1;

    // TODO: fragment doesnot receive props, it causes error
    const PermissionModal = showPermissionModal
        ? UnPaidPermissionModal
        : undefined;

    return {
        PermissionModal,
    };
};
