import { getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";

export const createSegmentation =
    ({ ...data }, onSuccess = null, onFailure = null) =>
    async dispatch => {
        try {
            const resp = await getCommonApi().createSegmentation(data);

            dispatch({
                type: actionTypes.Web.SEGMENTATION.CREATE_SEGMENTATION,
                payload: resp.data,
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };

export const updateSegmentation =
    (segmentId, { ...data }, onSuccess = null, onFailure = null) =>
    async dispatch => {
        try {
            const resp = await getCommonApi().updateSegmentation(
                segmentId,
                data,
            );

            dispatch({
                type: actionTypes.Web.SEGMENTATION.UPDATE_SEGMENTATION,
                payload: resp.data,
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };

export const deleteSegmentation =
    (segmentId, onSuccess = null, onFailure = null) =>
    async dispatch => {
        try {
            await getCommonApi().deleteSegmentation(segmentId);

            dispatch({
                type: actionTypes.Web.SEGMENTATION.DELETE_SEGMENTATION,
                payload: {
                    segmentId,
                },
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };

export const fetchSegmentation = (
    params,
    onSuccess = null,
    onFailure = null,
) => {
    return async dispatch => {
        try {
            const resp = await getCommonApi().fetchSegmentation({
                ...params,
                limit: 1000,
            });

            dispatch({
                type: actionTypes.Web.SEGMENTATION.FETCH_SEGMENTATION,
                payload: resp.data.results,
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };
};
