import React from "react";
import { FormSection, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { withRouter } from "react-router-dom";
import { uploadFile } from "@/actions/app";
import { withErrorCatcher } from "@/components/utilities";
import {
    AndroidActions,
    WEB_ACTIONS,
} from "@/components/utilities/commons/wizard/actions";
import SelectNotificationType from "@/components/utilities/commons/wizard/components/SelectNotificationTypeComponent";
import NotificationContentComponent from "@/components/utilities/commons/wizard/components/NotificationContentComponent";
import NotificationButtonsComponent from "@/components/utilities/commons/wizard/components/NotificationButtonsComponent";
import { NOTIFICATION_TYPE } from "@/components/notifications/notification/constants";
import NotificationJsonComponent from "@/components/utilities/commons/wizard/components/NotificationJsonComponent";
import PropTypes from "prop-types";
import {
    arrayValidator,
    createValidator,
    isJsonString,
    isRequired,
    withMessage,
} from "@/components/utils/validation";
import { some, values } from "lodash";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { isNotificationActionValid } from "@/components/notifications/notification/validation";

const WebAutomationStep2 = ({ uploadFile, change, notificationTypes }) => {
    return (
        <form className="notif-step2">
            <SelectNotificationType />

            <FormSection name="notification">
                {notificationTypes[NOTIFICATION_TYPE.NOTIF] && (
                    <NotificationContentComponent
                        change={change}
                        uploadFile={uploadFile}
                        actionsList={WEB_ACTIONS}
                    />
                )}

                <NotificationButtonsComponent
                    actionsList={WEB_ACTIONS}
                    defaultSelectedAction={WEB_ACTIONS.find(
                        a => a.id === "close_notif",
                    )}
                />

                {notificationTypes[NOTIFICATION_TYPE.CUSTOM_CONTENT] && (
                    <NotificationJsonComponent />
                )}
            </FormSection>
        </form>
    );
};

function mapStateToProps(state) {
    const { notificationTypes = {} } =
        formValueSelector("web_automation_form")(
            state,
            "notificationTypes",
            "notification",
        ) || {};

    return {
        notificationTypes: notificationTypes,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ uploadFile }, dispatch);
}

const validator = createValidator({
    notificationTypes: value =>
        some(values(value), x => x === true)
            ? undefined
            : FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TYPE,
    notification: {
        data: {
            title: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TITLE,
            ),
            content: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_CONTENT,
            ),
            action: isNotificationActionValid(AndroidActions),
            buttons: arrayValidator({
                btn_content: withMessage(
                    isRequired,
                    FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_ICON_AND_FIELD,
                ),
                btn_icon: withMessage(
                    isRequired,
                    FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_ICON_AND_FIELD,
                ), // TODO icon errors not showing
                btn_action: isNotificationActionValid(AndroidActions),
            }),
        },
        custom_content: isJsonString,
    },
});

const WebAutomationStep2RF = reduxForm({
    form: "web_automation_form",
    validate: validator,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebAutomationStep2);

WebAutomationStep2.propTypes = {
    uploadFile: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    notificationTypes: PropTypes.object.isRequired,
};

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(WebAutomationStep2RF),
    ),
);
