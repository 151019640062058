import * as React from "react";
import ReactMapboxGl, { Feature, GeoJSONLayer, Layer } from "react-mapbox-gl";
import { setRTLTextPlugin } from "mapbox-gl";
import PropTypes from "prop-types";
import { geoJsonCircle } from "@/components/utilities";

const MAPIR_ACCESS_TOKEN =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjgzNGExNGVjNzJkNTg2NWU2ZDM4NmE0MjcyYWI2M2VjNWU1MTUxNzEzMDZlMGJjMzcyMTU1NTgxMTVjYmFiMzFiOTA1ZTVkY2ZlZTY1ZDUyIn0.eyJhdWQiOiJteWF3ZXNvbWVhcHAiLCJqdGkiOiI4MzRhMTRlYzcyZDU4NjVlNmQzODZhNDI3MmFiNjNlYzVlNTE1MTcxMzA2ZTBiYzM3MjE1NTU4MTE1Y2JhYjMxYjkwNWU1ZGNmZWU2NWQ1MiIsImlhdCI6MTU2MjM5MTMwOCwibmJmIjoxNTYyMzkxMzA4LCJleHAiOjE1NjIzOTQ5MDgsInN1YiI6IiIsInNjb3BlcyI6WyJiYXNpYyIsImVtYWlsIl19.xdlHRRFo3Iy25nfmKPbfrQ9toG9B6KmD7Kyytkfeow1xEUP3Yg4oV8JiKjjrSlf-i_DuceM5Ehvh-YVg3-H2HhgKwnpbtUU837JCrNQCMLIdZylLjFbwRUpVHu-5nnm6ZwHMWR9q3hJLk1QkjPL6uo-YG4TtUKiVeDxFKV8LX_JKdI_6yu2tUZO7QbRXzxfLijsAJnsx-grgDeTRFUs-FHs86vvrshYz_irs1cFOPRDkBdS7Gb1i5TW_sn8AeIKlsqk5WjYS0QpaL5Pc1Z84iUvs997I6zVm1zEkjIw4Pn8QBQOx5mZeqBwJxRS2wu-5PDLy7jjCv90aBf-xpczJvg";
const MAPIR_STYLE_URL = `https://map.ir/vector/styles/main/mapir-xyz-style.json?x-api-key=${MAPIR_ACCESS_TOKEN}`;

setRTLTextPlugin(
    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js",
);

const Map = ReactMapboxGl({
    accessToken: "pk.Mapir",
    transformRequest: url => {
        return {
            url: url,
            headers: { "x-api-key": MAPIR_ACCESS_TOKEN },
        };
    },
});

class GeofenceMapComp extends React.Component {
    static propTypes = {
        containerStyle: PropTypes.object,
        geofenceRadius: PropTypes.number.isRequired,
        geofenceCenter: PropTypes.any,
        center: PropTypes.any,
        onGeofenceChanged: PropTypes.func,
        geofenceColor: PropTypes.string,
        zoom: PropTypes.array, // array of number [10]
        interaction: PropTypes.bool,
        initMapWithDefaultValues: PropTypes.bool,
    };

    static defaultProps = {
        containerStyle: { width: "800px", height: "600px" },
        center: [51.388658, 35.721453],
        geofenceRadius: 1,
        geofenceCenter: [51.388658, 35.721453],
        onGeofenceChanged: () => {},
        geofenceColor: "rgba(0, 0, 0, 0.3)",
        zoom: [10],
        interaction: true,
        initMapWithDefaultValues: true, // Make this (false) in wizard edit mode to let fill the map with form initialValues
    };

    constructor(props) {
        super(props);
        this.state = {
            geojsonData: this.computeCircle(props.center),
            mapIsLoading: true,
        };
    }

    componentDidMount() {
        if (this.props.initMapWithDefaultValues) {
            this.computeCircle(this.props.center);
            this.props.onGeofenceChanged(
                [...this.props.center],
                this.props.geofenceRadius,
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { geofenceCenter: center, geofenceRadius: radius } = this.props;
        const { geofenceCenter: prevCenter, geofenceRadius: prevRadius } =
            prevProps;
        const [lng, lat] = center && center.length === 2 ? center : [0, 0];
        const [pLng, pLat] =
            center && prevCenter.length === 2 ? prevCenter : [0, 0];

        if (lng !== pLng || lat !== pLat || radius !== prevRadius) {
            this.computeCircle([lng, lat]);
        }
    }

    computeCircle(center) {
        const { geofenceRadius } = this.props;
        this.setState({
            geojsonData: geoJsonCircle(center, geofenceRadius),
        });
    }

    onClick(_, pos) {
        const { interaction } = this.props;
        if (!interaction) return;
        const newPos = [pos.lngLat.lng, pos.lngLat.lat];
        this.computeCircle(newPos);
        this.props.onGeofenceChanged(newPos, this.props.geofenceRadius);
    }

    onDragEnd({ lngLat }) {
        const newPos = [lngLat.lng, lngLat.lat];
        this.computeCircle(newPos);
        this.props.onGeofenceChanged(newPos, this.props.geofenceRadius);
    }

    render() {
        const { containerStyle, center, geofenceColor, zoom, interaction } =
            this.props;
        const { geojsonData, mapIsLoading } = this.state;
        return (
            <>
                <Map
                    style={MAPIR_STYLE_URL}
                    center={center}
                    containerStyle={containerStyle}
                    onClick={(a, b) => this.onClick(a, b)}
                    onStyleLoad={() => this.setState({ mapIsLoading: false })}
                    zoom={zoom}
                >
                    <GeoJSONLayer
                        data={geojsonData}
                        fillPaint={{ "fill-color": geofenceColor }}
                    />
                    <Layer
                        type="symbol"
                        id="position-marker"
                        layout={{ "icon-image": "marker-15", "icon-size": 1.2 }}
                    >
                        <Feature
                            coordinates={this.props.geofenceCenter}
                            draggable={interaction}
                            onDragEnd={evt => this.onDragEnd(evt)}
                            // onDrag={this.onDrag}
                            // onDragStart={this.onDragStart}
                        />
                    </Layer>
                </Map>
                {mapIsLoading && (
                    <div className="geofence-map-cover">
                        نقشه در حال بارگذاری می‌باشد لطفا کمی صبر کنید ...
                    </div>
                )}
            </>
        );
    }
}

export default GeofenceMapComp;
