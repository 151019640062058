import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import BaseModal from "@/components/utilities/modal/baseModal";
import HighlightWithCopy from "@/components/utils/HighlightWithCopy";

class AppTokenModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            error: null,
        };
    }

    render() {
        const { app, onClose, onAccept } = this.props;
        const _onSuccess = () => {
            onClose();
            this.setState({ error: null });
        };
        return (
            <BaseModal
                onClose={() => this.props.onClose()}
                title={` توکن وبسایت  (${app.name}  )   `}
                useDefaultFooter={false}
                body={
                    <ul>
                        <div>
                            با استفاده از این توکن می‌توانید پوشه را با سایر
                            سرویس ها یکپارچه سازی کنید
                            <br />
                            <br />
                            <HighlightWithCopy
                                textToCopy={app.app_token}
                                // eslint-disable-next-line react/no-children-prop
                                children={app.app_token}
                            />
                        </div>
                    </ul>
                }
                onAccept={() => onAccept()}
            />
        );
    }
}

export default withErrorCatcher(AppTokenModal);
