import React from "react";
import css from "./styles.module.scss";
import { Field, FormSection } from "redux-form";
import {
    ReduxCheckbox,
    reduxSelectBox,
    ReduxSimpleTextField,
} from "@/components/utilities";
import {
    SDK_DESKTOP_POSITIONS,
    SDK_MOBILE_POSITIONS,
} from "@/components/application/constants";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
import { geolocation_defaults } from "@/components/application/constants";

function GeolocationSettings(props) {
    const { isHttps } = props;

    const formValues = useReduxFormValues("webpush-settings", ["geolocation"]);
    const { showDialog, isEnabled } = formValues?.geolocation ?? {};

    return (
        <div className={css.settingsWrapper}>
            <FormSection name={"geolocation"}>
                <Field
                    component={ReduxCheckbox}
                    name="isEnabled"
                    label={"فعال‌سازی دریافت موقعیت مکانی"}
                    disabled={!isHttps}
                />
                {!isHttps && (
                    <p className={css.noDialogText}>
                        این گزینه در سایت‌های با پروتکل http قابل پشتیبانی
                        نمی‌باشد.
                        <br />
                    </p>
                )}
                {isEnabled && (
                    <>
                        <div className={css.settingsLineSeparator} />
                        <Field
                            component={ReduxCheckbox}
                            name="showDialog"
                            label={`نمایش دیالوگ عضویت وب‌پوش${
                                !isHttps
                                    ? "(نمایش در حالت http الزامی است)"
                                    : ""
                            }`}
                            disabled={!isHttps}
                        />

                        {!showDialog && (
                            <p className={css.noDialogText}>
                                در حالتی که این گزینه خاموش باشد، دیالوگ پوشه
                                نمایش داده نخواهد شد و مستقیما دیالوگ دریافت
                                موقعیت مکانی خود مرورگر نمایش داده می‌شود.
                                <br />
                            </p>
                        )}

                        {showDialog && (
                            <>
                                <div className={css.settingsLineSeparator} />

                                <h3 className={css.settingsBlockHeading}>
                                    محتوای دیالوگ
                                </h3>
                                <div className={css.contentWrapper}>
                                    <div className={css.locationFullWidthBlock}>
                                        <Field
                                            name="text.title"
                                            component={ReduxSimpleTextField}
                                            fullWidth
                                            placeholder={
                                                geolocation_defaults.text.title
                                            }
                                            label="تیتر"
                                        />
                                        <Field
                                            name="text.content"
                                            component={ReduxSimpleTextField}
                                            placeholder={
                                                geolocation_defaults.text
                                                    .content
                                            }
                                            label="متن"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className={css.contentWrapper}>
                                    <div className={css.locationTextBlock}>
                                        <Field
                                            name="text.acceptBtn"
                                            component={ReduxSimpleTextField}
                                            placeholder={
                                                geolocation_defaults.text
                                                    .acceptBtn
                                            }
                                            label="متن دکمه پذیرش"
                                        />
                                    </div>
                                    <div className={css.locationTextBlock}>
                                        <Field
                                            name="text.rejectBtn"
                                            component={ReduxSimpleTextField}
                                            placeholder={
                                                geolocation_defaults.text
                                                    .rejectBtn
                                            }
                                            label="متن دکمه عدم پذیرش"
                                        />
                                    </div>
                                </div>

                                <div className={css.settingsLineSeparator} />

                                <h3 className={css.settingsBlockHeading}>
                                    وضعیت نمایش دیالوگ در دستگاه های مختلف
                                </h3>
                                <div className={css.contentWrapper}>
                                    <div className={css.divideColumnInHalf}>
                                        <Field
                                            name={"displayIn.mobile"}
                                            component={ReduxCheckbox}
                                            label={"نمایش در موبایل"}
                                        />
                                    </div>
                                    <div className={css.divideColumnInHalf}>
                                        <Field
                                            name={"displayIn.desktop"}
                                            component={ReduxCheckbox}
                                            label={"نمایش در دسکتاپ"}
                                        />
                                    </div>
                                </div>

                                <div className={css.settingsLineSeparator} />

                                <div className={css.settingsPositionWrapper}>
                                    <Field
                                        name={"position.desktop"}
                                        component={reduxSelectBox}
                                        label={"مکان نمایش در دسکتاپ"}
                                        options={SDK_DESKTOP_POSITIONS}
                                    />

                                    <Field
                                        name={"position.mobile"}
                                        component={reduxSelectBox}
                                        label={"مکان نمایش در موبایل"}
                                        options={SDK_MOBILE_POSITIONS}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </FormSection>
        </div>
    );
}

export default GeolocationSettings;
