import React, { useState } from "react";
import { mdx } from "@mdx-js/react";
import HighlightWithCopy from "@/components/utils/HighlightWithCopy";

const replaceGeoOptions = application => {
    const geolocation = application?.webpush_settings?.["geolocation"] ?? {};

    return geolocation.isEnabled
        ? `Pushe.enableGeolocation({isEnabled: true})`
        : "";
};

const replaceBellOptions = application => {
    return application?.webpush_settings?.["bell"]?.showBell
        ? "Pushe.displayBell();"
        : "";
};

const Manifest = ({ children, passToSteps, onlyKey = false }) => {
    const replaceTextInMarkdown = (textToReplace, replaceFn) => {
        if (new RegExp(textToReplace).test(text)) {
            text = text.replace(
                textToReplace,
                replaceFn(passToSteps?.application ?? {}),
            );
        }
    };
    const manifestKey = passToSteps?.application?.["manifest_key"];
    const appId = passToSteps?.application?.["app_id"];

    let text = children?.props?.children?.props?.children;
    if (new RegExp("{{manifest}}").test(text) && manifestKey) {
        text = text.replace("{{manifest}}", manifestKey);
    } else if (new RegExp("{{appId}}").test(text) && appId) {
        text = text.replace("{{appId}}", appId);
    }

    replaceTextInMarkdown("{{handle_geolocation}}", replaceGeoOptions);
    replaceTextInMarkdown("{{handle_bell}}", replaceBellOptions);

    let elem;

    if (onlyKey) {
        elem = mdx("span", { children: appId });
    } else {
        elem = mdx("code", { children: text });
    }

    return <>{elem}</>;
};

const Tabs = ({ children, isRtl = false, isLtr = true, title }) => {
    const [active, setActive] = useState(0);

    // Default is `is-ltr`
    const dirClassname = isRtl ? "is-rtl" : isLtr ? "is-ltr" : "is-ltr";

    return (
        <div className={`mdx__tabs ${dirClassname}`}>
            <div className="mdx__tabs--wrapper">
                <div className="mdx__tabs--buttons">
                    {children.map((tab, indx) => (
                        <button
                            className={`mdx__tabs--button ${
                                tab.props?.id === active ? "active" : ""
                            }`}
                            onClick={() => setActive(tab?.props?.id)}
                            key={indx}
                        >
                            {tab.props?.label}
                        </button>
                    ))}
                </div>
                {title && <div className="mdx__tabs--title">{title}</div>}
            </div>

            {React.Children.toArray(children).find(tab => {
                return tab?.props?.id === active;
            })}
        </div>
    );
};

const Tab = ({ children }) => <div className="mdx__tabs--tab">{children}</div>;

export const mdxComponents = {
    code: HighlightWithCopy,
    a: ({ children, href }) => (
        <a href={href} target="_blank" rel="noreferrer">
            {children}
        </a>
    ),
    inlineCode: props => <code className="inline-code">{props.children}</code>,
    wrapper: props => <div className="mdx-wrapper">{props.children}</div>,
    Manifest,
    Tabs,
    Tab,
    div: props => <div {...props}>{props.children}</div>,
};
