import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
    retrieveGeofenceList,
    clearFetchedGeofenceItems,
} from "@/actions/geofence";

export const useFetchGeofenceItems = () => {
    const dispatch = useDispatch();

    const [loading, toggleLoading] = useState(true);
    const [firstReq, updateFirstReq] = useState(true);

    const { hasMore, items = [] } = useSelector(
        state => state?.geofence?.geofences ?? {},
    );

    useEffect(() => {
        // Scroll to top of page to make all row visible
        window.scrollTo(0, 0);

        return clearFetchedGeofenceItems()(dispatch);
    }, [dispatch]);

    const onFinish = () => {
        updateFirstReq(false);
        toggleLoading(false);
    };

    const handleFetchGeofenceItems = () => {
        if (!hasMore) return toggleLoading(false);
        if (loading && !firstReq) return;

        toggleLoading(true);

        retrieveGeofenceList({ onFinish })(dispatch);
    };

    const handleRefreshGeofenceItems = () => {
        toggleLoading(true);

        const finish = () => {
            onFinish();
            window.scrollTo(0, 0);
        };

        clearFetchedGeofenceItems({
            onFinish: () =>
                retrieveGeofenceList({
                    onFinish: finish,
                    offset: 0,
                    hasMore: true,
                })(dispatch),
        })(dispatch);
    };

    return {
        hasMore,
        loading,
        geofenceItems: items,
        handleFetchGeofenceItems,
        handleRefreshGeofenceItems,
    };
};
