import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { MODAL_THEME } from "@/actions/_types";
import BaseModal from "@/components/utilities/modal/baseModal";

const ResendModalAction = props => {
    const { title, desc, body, isLoading, onClose, onAccept, error } = props;

    return (
        <BaseModal
            theme={MODAL_THEME.BLUE}
            title={title}
            desc={desc}
            error={error}
            useDefaultFooter={true}
            body={body}
            onClose={onClose}
            isLoading={isLoading}
            onAccept={onAccept}
        />
    );
};

export default withErrorCatcher(ResendModalAction);
