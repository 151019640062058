import React from "react";

/**
 * **************************************
 * If you are going to use this component
 * You should also add more ```padding top```
 * for ```.main-wrap``` in _mod-main.scss file (search it with ```&-wrap``` keyword)
 */
const HeaderTemporaryTopBar = props => {
    const { handleClose } = props;

    return (
        <div className="header-bar">
            <div className="header-bar__in top-banner-beta">
                <p>{/* Put some text here ... */}</p>
                <span className="close" onClick={handleClose} />
            </div>
        </div>
    );
};

export default HeaderTemporaryTopBar;
