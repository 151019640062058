import { getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";
import to from "await-to-js";

export const createSegmentation =
    ({ ...data }, onSuccess = null, onFailure = null) =>
    async dispatch => {
        try {
            const resp = await getCommonApi().createSegmentation(data);

            dispatch({
                type: actionTypes.SEGMENTATION.CREATE_SEGMENTATION,
                payload: resp.data,
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };

export const FetchUserRecipient = async segment_id => {
    // eslint-disable-next-line no-unused-vars
    const [error, response] = await to(
        getCommonApi().FetchUserRecipients(segment_id),
    );

    if (response) {
        return response;
    }
};
export const updateSegmentation =
    (segmentId, { ...data }, onSuccess = null, onFailure = null) =>
    async dispatch => {
        try {
            const resp = await getCommonApi().updateSegmentation(
                segmentId,
                data,
            );

            dispatch({
                type: actionTypes.SEGMENTATION.UPDATE_SEGMENTATION,
                payload: resp.data,
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };

export const deleteSegmentation =
    (segmentId, onSuccess = null, onFailure = null) =>
    async dispatch => {
        try {
            await getCommonApi().deleteSegmentation(segmentId);

            dispatch({
                type: actionTypes.SEGMENTATION.DELETE_SEGMENTATION,
                payload: {
                    segmentId,
                },
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };

export const fetchSegmentation =
    ({ ...params } = {}, onSuccess = null, onFailure = null) =>
    async dispatch => {
        try {
            // const { platform } = params;
            const resp = await getCommonApi().fetchSegmentation({
                limit: 1000,
                ...params,
            });

            dispatch({
                type: actionTypes.SEGMENTATION.FETCH_SEGMENTATION,
                payload: resp.data.results,
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };
