import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const IosTableHeader = () => (
    <div className="table-head">
        <div className="table-row">
            <div className="table-col table-col--20 table-col--right">
                <p style={{ paddingRight: "35px" }}>نام اپلیکیشن</p>
            </div>
            <div className="table-col table-col--24">تاریخ ایجاد</div>
            <div className="table-col table-col--15">ابزار</div>
            <div className="table-col table-col--10">پیام خوش‌ آمد</div>
            {/*<div className="table-col table-col--10">پیام بروزرسانی</div>*/}
            <div className="table-col table-col--15">تعداد کاربر</div>
            <div className="table-col table-col--10">امکانات</div>
        </div>
    </div>
);

export default withErrorCatcher(IosTableHeader);
