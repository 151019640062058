import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import Toggle from "material-ui/Toggle";
import Util from "@/utils";
import mockupAndroid from "images/notification-preview/mockup-android.png";
import { getFormValues } from "redux-form";
import FlatButton from "material-ui/FlatButton";
import avatar from "images/app-icon/app2.svg"; // for test purpose
import { withErrorCatcher } from "@/components/utilities";
import { AndroidActions } from "@/components/notifications/notification/actions";
import { FULL_CONTENT_TYPE_MODE } from "@/components/notifications/notification/constants";
import { isEmpty } from "lodash";

export class AndroidNotifPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBig: props.isAndroid,
        };
    }

    /**
     * check direction of text based on first character and add class if needed
     * @param  {string} text
     * @param  {htmlDOM} dom
     */
    static checkTextDirection(text, dom) {
        if (typeof text === "undefined" || !text.length) return;

        if (/[a-z]|[A-Z]/.test(text[0])) {
            Util._addClass(dom, "text-left");
        } else {
            Util._removeClass(dom, "text-left");
        }
    }

    componentDidMount() {
        this.onUpdatePreviewClass();
    }

    componentDidUpdate() {
        this.onUpdatePreviewClass();
    }

    onUpdatePreviewClass() {
        const {
            values: { data },
        } = this.props;

        if (typeof data === "undefined") return;

        if (typeof data.title !== "undefined")
            AndroidNotifPreview.checkTextDirection(data.title, this.title);
        if (typeof data.content !== "undefined")
            AndroidNotifPreview.checkTextDirection(data.content, this.desc);
    }

    renderButtons() {
        const {
            values: {
                data: { buttons = [] },
            },
        } = this.props;

        if (buttons.length === 0) return null;

        return buttons.map((btn, idx) => {
            const _action = btn["btn_action"] || {};
            const iconName = btn["btn_icon"] || "";
            const width =
                buttons.length === 3
                    ? "33.3%"
                    : buttons.length === 2
                    ? "50%"
                    : "100%";
            return (
                <FlatButton
                    key={idx}
                    label={buttons[idx]["btn_content"]}
                    labelPosition={"before"}
                    icon={
                        <i
                            className="material-icons"
                            style={{
                                fontSize: "1.2rem",
                                margin: "0",
                                width: "20%",
                                textAlign: "left",
                            }}
                        >
                            {iconName}
                        </i>
                    }
                    labelStyle={{
                        fontSize: ".8rem",
                        padding: "0 0 0 5px",
                        width: "80%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textAlign: "left",
                    }}
                    style={{ width }}
                />
            );
        });
    }

    renderAndroidBody() {
        const { values } = this.props;
        let isImageMode =
            values.fullContentType === FULL_CONTENT_TYPE_MODE.IMAGE;
        const { data = {} } = values;
        let icon = data.icon || "";
        let image = data.image || "";
        let notif_icon = data.notif_icon || "";
        let { isBig } = this.state;
        let iconStyle = {
            backgroundImage: icon ? `url(${icon})` : "",
            backgroundColor: icon ? "transparent" : "#eee",
        };

        const renderTitle = () => {
            let title = null;

            if (isBig && data["big_title"]) {
                title = data["big_title"];
            } else if (data["title"]) {
                title = data["title"];
            }
            return <p>{title}</p>;
        };
        const renderContent = () => {
            let content;
            if (isBig) {
                if (isImageMode && image) {
                    if (data["summary"]) {
                        content = data["summary"];
                    } else if (data["content"]) {
                        content = data["content"];
                    }
                } else {
                    if (data["big_content"]) {
                        content = data["big_content"];
                    } else if (data["content"]) {
                        content = data["content"];
                    }
                }
            } else {
                if (data["content"]) {
                    content = data["content"];
                }
            }
            return <pre>{content}</pre>;
        };
        const renderSummary = () => {
            if (data["summary"] && (!image || !isImageMode)) {
                return <p>{data["summary"]}</p>;
            }
        };
        const renderArrow = () => {
            if (isBig) {
                return <div className="icon arrow-up" />;
            } else {
                return <div className="icon arrow-down" />;
            }
        };
        const imageComponent =
            isBig && image && isImageMode ? <img src={image} alt="" /> : null;

        return (
            <div>
                <div className="preview-body__main">
                    <div className="preview-body__top">
                        <div
                            className="preview-body__avatar"
                            style={iconStyle}
                        />
                        <div className="preview-body__text">
                            <div
                                className="preview-body__info"
                                onClick={() => this.setState({ isBig: !isBig })}
                            >
                                {notif_icon ? (
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: "10px" }}
                                    >
                                        {notif_icon}
                                    </i>
                                ) : (
                                    <img src={avatar} alt="" />
                                )}
                                <p>پوشه</p>
                                {renderSummary()}
                                {renderArrow()}
                            </div>
                            <div
                                className="preview-body__title"
                                ref={ref => (this.title = ref)}
                            >
                                {renderTitle()}
                            </div>
                            <div
                                className={
                                    data && isBig
                                        ? "preview-body__desc"
                                        : "preview-body__desc--small"
                                }
                                ref={ref => (this.desc = ref)}
                            >
                                {renderContent()}
                            </div>
                        </div>
                    </div>

                    <div className="preview-body__img">{imageComponent}</div>
                    <div className="preview-body__buttons">
                        {isBig && this.renderButtons()}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { values = {} } = this.props;
        const { data = {} } = values;
        const { isBig } = this.state;

        const d = new Date();
        const timeStr = (() => {
            let hour = d.getHours();
            let minute = d.getMinutes();

            if (hour < 10) {
                hour = `0${hour}`;
            }
            if (minute < 10) {
                minute = `0${minute}`;
            }

            return `${hour}:${minute}`;
        })();
        const dateStr = /(.*)\s/.exec(d.toDateString())[1].replace(/\s/i, ", ");

        const mockupPhone = mockupAndroid;

        const styles = {
            toggleButton: {
                display: "inline-block",
                verticalAlign: "middle",
                width: "60px",
            },
            thumb: {
                backgroundColor: "#0065ff",
            },
            track: {
                backgroundColor: "#0065ff30",
            },
        };

        const renderAction = () => {
            if (!data.action || !data.action.id) {
                return;
            }
            let actionName = AndroidActions.find(
                action => action.id === data.action.id,
            ).text;
            let url = data.action.url;

            let action = <span> {actionName} </span>;
            if (url) {
                const icon = (
                    <svg
                        style={{
                            height: "13px",
                            width: "13px",
                            fill: "white",
                            margin: "0 5px",
                        }}
                    >
                        <use xlinkHref="#svg_external_link" />
                    </svg>
                );
                action = (
                    <a href={url} target="_blank" rel="noreferrer">
                        {" "}
                        {icon}
                        {actionName}
                    </a>
                );
            }
            return (
                <div className="preview-action">
                    <p>عملکرد:</p>
                    {action}
                </div>
            );
        };
        return (
            <div
                className={classNames("preview preview--android", {
                    "preview--big":
                        isBig && data && (data.big_content || data.big_image),
                })}
            >
                <div
                    className="preview-phone"
                    style={{ backgroundImage: `url(${mockupPhone})` }}
                >
                    {/*<div className="preview-phone__header">*/}
                    {/*    <time>{timeStr}</time>*/}
                    {/*    <div className='icons'>*/}
                    {/*        <AndroidHeader/>*/}
                    {/*    </div>*/}
                    {/*    {data && data.led_color &&*/}
                    {/*    <span className="active" style={{'backgroundColor': data.led_color}}/>*/}
                    {/*    }*/}
                    {/*</div>*/}

                    <div className="preview-phone__date">
                        <time>{timeStr}</time>
                        <span>{dateStr}</span>
                    </div>
                    <div className="preview-phone__body">
                        <div className="preview-body">
                            {this.renderAndroidBody()}
                        </div>
                    </div>
                </div>

                <div className="preview-toggle">
                    <button
                        className={classNames("preview-toggle__btn", {
                            active: !isBig,
                        })}
                        onClick={() => this.setState({ isBig: false })}
                        data-tip="وقتی کاربر نوتیفیکیشن را دریافت می‌کند، حالت کوچک نوتیفیکیشن را خواهد دید"
                    >
                        حالت کوچک
                    </button>
                    <Toggle
                        label=""
                        className="preview-toggle__input"
                        onToggle={() => this.setState({ isBig: !isBig })}
                        style={styles.toggleButton}
                        trackStyle={styles.track}
                        thumbStyle={styles.thumb}
                        trackSwitchedStyle={styles.track}
                        thumbSwitchedStyle={styles.thumb}
                    />
                    <button
                        className={classNames("preview-toggle__btn", {
                            active: isBig,
                        })}
                        onClick={() => this.setState({ isBig: true })}
                        data-tip="وقتی کاربر لیست نوتیفیکیشن‌ها را پایین بکشد، حالت بزرگ نوتیفیکیشن را خواهد دید"
                    >
                        حالت بزرگ
                    </button>
                </div>
                {renderAction()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { formName } = ownProps;

    let values = getFormValues(formName)(state) || {};
    // To handle automation
    if ("notification" in values) {
        values = {
            ...values,
            ...values.notification,
        };
    }

    if (isEmpty(values)) {
        values = {
            data: {},
        };
    }

    return {
        values,
        myData: values,
    };
};

export default withErrorCatcher(
    connect(mapStateToProps, null)(AndroidNotifPreview),
);
