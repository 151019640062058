import React from "react";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import {
    Camera,
    Clipboard,
    Pencil,
    StarStroke,
    ThreeDot,
    Trash,
} from "@/components/utils/lib/svgicons";
import { PopoverAnimationVertical } from "material-ui/Popover";
import MenuItem from "material-ui/MenuItem";
import { withErrorCatcher } from "@/components/utilities";
import Colors from "@/components/utils/lib/colors";
import DeleteApplicationModal from "application/list/common/RemoveApplicationModal";
import RenameApplicationModal from "application/list/common/RenameApplicationModal";
import AppTokenModal from "application/list/common/AppTokenModal";
import UploadIconSiteModal from "application/list/web/components/UploadIconSiteModal";
import FavoriteDeviceModal from "application/list/web/columns/FavoriteDeviceModal";
const styles = {
    menu: {
        item: {
            main: {
                lineHeight: "2.375rem",
                minHeight: "2.375rem",
            },
            innerDiv: {
                padding: "0 3.125rem 0 0.3125rem",
                color: Colors.gray_8,
                fontSize: "0.875rem",
            },
            icon: {
                margin: "0.375rem 0.75",
                fill: Colors.gray_8,
            },
        },
    },
};

class MobileCardMenus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            showRenameModal: false,
            showAppToken: false,
            showIconUploader: false,
            showFavoriteDeviceModal: false,
        };
    }

    render() {
        const {
            showDeleteModal,
            showRenameModal,
            showAppToken,
            showIconUploader,
            showFavoriteDeviceModal,
        } = this.state;
        const {
            app = {},
            isSmallSize,
            handleUpdateApplication,
            handleDeleteApplication,
            className = "",
        } = this.props;
        let classes = !isSmallSize
            ? `table-col table-col--20 ${className}`
            : `small-CardMenus ${className}`;
        return (
            <>
                <div className={classes}>
                    <IconMenu
                        className=""
                        iconButtonElement={
                            <IconButton>
                                <ThreeDot viewBox="0 0 20 20" />
                            </IconButton>
                        }
                        animation={PopoverAnimationVertical}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                        targetOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <MenuItem
                            innerDivStyle={styles.menu.item.innerDiv}
                            style={styles.menu.item.main}
                            primaryText="تغییر نام سایت"
                            leftIcon={
                                <Pencil
                                    viewBox="0 0 24 24"
                                    style={styles.menu.item.icon}
                                />
                            }
                            onClick={() =>
                                this.setState({ showRenameModal: true })
                            }
                        />
                        <MenuItem
                            innerDivStyle={styles.menu.item.innerDiv}
                            style={styles.menu.item.main}
                            primaryText="افزودن به لیست علاقه مندی"
                            leftIcon={
                                <StarStroke
                                    viewBox="0 0 21 21"
                                    style={styles.menu.item.icon}
                                />
                            }
                            onClick={() =>
                                this.setState({ showFavoriteDeviceModal: true })
                            }
                        />
                        <MenuItem
                            innerDivStyle={styles.menu.item.innerDiv}
                            style={styles.menu.item.main}
                            primaryText="بارگذاری آیکن سایت"
                            leftIcon={
                                <Camera
                                    viewBox="0 0 24 24"
                                    style={styles.menu.item.icon}
                                />
                            }
                            onClick={() =>
                                this.setState({ showIconUploader: true })
                            }
                        />
                        <MenuItem
                            innerDivStyle={styles.menu.item.innerDiv}
                            style={styles.menu.item.main}
                            primaryText="حذف سایت"
                            leftIcon={
                                <Trash
                                    viewBox="0 0 19 23"
                                    style={styles.menu.item.icon}
                                />
                            }
                            onClick={() =>
                                this.setState({ showDeleteModal: true })
                            }
                        />
                        <MenuItem
                            innerDivStyle={styles.menu.item.innerDiv}
                            style={styles.menu.item.main}
                            primaryText="توکن یکپارچه سازی با دیگر سرویسها"
                            leftIcon={
                                <Clipboard
                                    viewBox="0 0 561 561"
                                    style={styles.menu.item.icon}
                                />
                            }
                            onClick={() =>
                                this.setState({ showAppToken: true })
                            }
                        />
                    </IconMenu>
                </div>

                {showDeleteModal && (
                    <DeleteApplicationModal
                        app={app}
                        onClose={() =>
                            this.setState({ showDeleteModal: false })
                        }
                        onAccept={handleDeleteApplication}
                    />
                )}

                {showRenameModal && (
                    <RenameApplicationModal
                        app={app}
                        onClose={() =>
                            this.setState({ showRenameModal: false })
                        }
                        onAccept={values =>
                            handleUpdateApplication({
                                app_id: app.app_id,
                                ...values,
                            })
                        }
                    />
                )}
                {showAppToken && (
                    <AppTokenModal
                        app={app}
                        onClose={() => this.setState({ showAppToken: false })}
                        onAccept={values =>
                            handleUpdateApplication({
                                app_id: app.app_id,
                                ...values,
                            })
                        }
                    />
                )}
                {showIconUploader && (
                    <UploadIconSiteModal
                        app={app}
                        onClose={() =>
                            this.setState({ showIconUploader: false })
                        }
                        onAccept={values =>
                            handleUpdateApplication({
                                app_id: app.app_id,
                                ...values,
                            })
                        }
                    />
                )}
                {showFavoriteDeviceModal && (
                    <FavoriteDeviceModal
                        app={app}
                        onClose={() =>
                            this.setState({ showFavoriteDeviceModal: false })
                        }
                        // onAccept={(values) => handleUpdateApplication({app_id: app.app_id, ...values})}
                    />
                )}
            </>
        );
    }
}

export default withErrorCatcher(MobileCardMenus);
