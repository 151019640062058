import React, { useState } from "react";
import { MoreVertMenu, withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import {
    PTable,
    PTableBody,
    PTableHeader,
    PTableItem,
    PTableRow,
} from "@/components/utilities/modules/PTable";
import Util from "@/utils";
import app_blank from "images/app-icon/app-blank.png";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RemoveFavInstallModal from "@/components/users/installations/web/component/RemoveFavInstallModal";
import RenameFavInstallModal from "@/components/users/installations/web/component/RenameFavInstallModal";

const InstallationsTableBody = ({
    installationList,
    deleteFavInstall,
    updateFavInstall,
}) => {
    const [modalRemove, setModalRemove] = useState({
        isRemove: false,
        removeData: null,
    });
    const [modalRename, setModalRename] = useState({
        isRename: false,
        renameData: null,
    });

    return (
        <>
            <header className="main-block__header">
                <p className="main-block__title">نصب‌ها مورد علاقه ها</p>
            </header>
            <div className={"segments-table-wrapper"}>
                <PTable columns={6} mobileSizeMax={"1199px"}>
                    <PTableHeader>
                        <PTableItem expand={2}>نام </PTableItem>
                        <PTableItem expand={2}>مرورگر </PTableItem>
                        <PTableItem expand={2}>نام وب سایت</PTableItem>
                        <PTableItem expand={2}>تاریخ ایجاد</PTableItem>
                        <PTableItem>امکانات</PTableItem>
                    </PTableHeader>
                    <PTableBody>
                        {installationList.map(fav => {
                            const { name, created_at } = fav?.favorite_data;
                            const browserName = fav.browser.name;
                            const nameWeb = fav?.app_details.name;
                            const icon_url = fav?.app_details.icon_url;
                            const { app_id } = fav;
                            return (
                                <PTableRow key={name}>
                                    <PTableItem
                                        expand={2}
                                        inMobileHeader
                                        mobileTitle={"نام : "}
                                        mobileChildren={name}
                                    >
                                        <div
                                            className={`table-col table-col--6 table-col--right`}
                                        >
                                            <div
                                                className="card-table"
                                                style={{ padding: "0 12px" }}
                                            >
                                                <img
                                                    src={icon_url || app_blank}
                                                />
                                                <div className="card-table__text">
                                                    <span>{name}</span>
                                                    <p>
                                                        <span
                                                            style={{
                                                                color: "#ccc",
                                                                fontSize:
                                                                    ".9rem",
                                                            }}
                                                        >
                                                            app_id:
                                                        </span>{" "}
                                                        {app_id}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </PTableItem>
                                    <PTableItem
                                        mobileTitle={"مرورگر: "}
                                        expand={2}
                                    >
                                        {browserName}
                                    </PTableItem>
                                    <PTableItem
                                        mobileTitle={"نام وب سایت: "}
                                        expand={2}
                                    >
                                        {nameWeb}
                                    </PTableItem>
                                    <PTableItem
                                        expand={2}
                                        mobileTitle={"تاریخ ایجاد: "}
                                    >
                                        {Util._convertToLocalTimezone(
                                            created_at,
                                        )}
                                    </PTableItem>
                                    <PTableItem inMobileHeader>
                                        <MoreVertMenu
                                            disabled
                                            options={[
                                                {
                                                    label: "ویرایش نام",
                                                    icon: EditIcon,
                                                    disabled: false,
                                                    onClick: () =>
                                                        setModalRename({
                                                            isRename: true,
                                                            renameData: fav,
                                                        }),
                                                },
                                                {
                                                    label: "حذف",
                                                    icon: DeleteIcon,
                                                    disabled: false,
                                                    onClick: () =>
                                                        setModalRemove({
                                                            isRemove: true,
                                                            removeData: fav,
                                                        }),
                                                },
                                            ]}
                                        />
                                    </PTableItem>
                                </PTableRow>
                            );
                        })}
                    </PTableBody>
                </PTable>
                {modalRemove.isRemove && (
                    <RemoveFavInstallModal
                        app={modalRemove.removeData}
                        onClose={() =>
                            setModalRemove({
                                isRemove: false,
                                removeData: null,
                            })
                        }
                        onAccept={deleteFavInstall}
                    />
                )}
                {modalRename.isRename && (
                    <RenameFavInstallModal
                        app={modalRename.renameData}
                        onClose={() =>
                            setModalRename({
                                isRename: false,
                                renameData: null,
                            })
                        }
                        onAccept={updateFavInstall}
                    />
                )}
            </div>
        </>
    );
};

InstallationsTableBody.proptypes = {
    installationList: PropTypes.array.isRequired,
};

export default withErrorCatcher(InstallationsTableBody);
