import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormSection, formValueSelector, reduxForm } from "redux-form";
import { uploadFile } from "@/actions/app";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { isNotificationActionValid } from "@/components/notifications/notification/validation";
import { AndroidActions } from "@/components/notifications/notification/actions";
import {
    arrayValidator,
    createValidator,
    isJsonString,
    isRequired,
    withMessage,
} from "@/components/utils/validation";
import { withErrorCatcher } from "@/components/utilities";
import { some, values } from "lodash";
import SelectNotificationType from "@/components/utilities/commons/wizard/components/SelectNotificationTypeComponent";
import { NOTIFICATION_TYPE } from "@/components/notifications/notification/constants";
import NotificationContentComponent from "@/components/utilities/commons/wizard/components/NotificationContentComponent";
import NotificationButtonsComponent from "@/components/utilities/commons/wizard/components/NotificationButtonsComponent";
import NotificationJsonComponent from "@/components/utilities/commons/wizard/components/NotificationJsonComponent";

const AndroidGeofenceStep2 = ({
    uploadFile,
    change,
    notificationTypes,
    hasOneSignal,
}) => {
    return (
        <form className="notif-step1">
            <SelectNotificationType />

            <FormSection name="notification">
                {notificationTypes[NOTIFICATION_TYPE.NOTIF] && (
                    <NotificationContentComponent
                        change={change}
                        uploadFile={uploadFile}
                        actionsList={AndroidActions}
                    />
                )}

                <NotificationButtonsComponent
                    hasOneSignal={hasOneSignal}
                    actionsList={AndroidActions}
                    defaultSelectedAction={AndroidActions.find(
                        a => a.id === "open_app",
                    )}
                />

                {notificationTypes[NOTIFICATION_TYPE.CUSTOM_CONTENT] && (
                    <NotificationJsonComponent />
                )}
            </FormSection>
        </form>
    );
};

const validators = createValidator({
    notificationTypes: value =>
        some(values(value), x => x === true)
            ? undefined
            : FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TYPE,
    app_id: isRequired,
    notification: {
        data: {
            title: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TITLE,
            ),
            content: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_CONTENT,
            ),
            action: isNotificationActionValid(AndroidActions),
            buttons: arrayValidator({
                btn_content: withMessage(
                    isRequired,
                    FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_ICON_AND_FIELD,
                ),
                btn_icon: withMessage(
                    isRequired,
                    FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_ICON_AND_FIELD,
                ), // TODO icon errors not showing
                btn_action: isNotificationActionValid(AndroidActions),
            }),
        },
        custom_content: isJsonString,
    },
});

function mapStateToProps(state) {
    const platform = "android";

    const notificationTypes =
        formValueSelector("geofence_form")(state, "notificationTypes") || {};
    const applications = state[platform].applications || [];

    return {
        applications,
        notificationTypes: notificationTypes || {},
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ uploadFile }, dispatch);
}

const AndroidGeofenceStep2RF = reduxForm({
    form: "geofence_form",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidGeofenceStep2);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AndroidGeofenceStep2RF),
    ),
);
