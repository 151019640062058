import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changePlatform } from "@/actions/app";

/**
 * Get a route platform `:platform` and check if with current redux platform
 * Then if they are different, then update the redux with platform route
 */
export const useSyncPlatformWithRedux = (routePlatform, nextUrl) => {
    const dispatch = useDispatch();

    const reduxPlatform = useSelector(state => state.app?.platform);

    useEffect(() => {
        // If routePlatform exists & is different with reduxPlatform
        // Then update the redux one with the routePlatform
        if (routePlatform && routePlatform !== reduxPlatform) {
            changePlatform({ platform: routePlatform, nextUrl })(dispatch);
        }
    }, [routePlatform, reduxPlatform, nextUrl, dispatch]);
};
