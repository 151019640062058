import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import classNames from "classnames";
import AppName from "application/list/web/columns/AppName";
import CreatedAt from "application/list/common/columns/CreatedAt";
import CountOfUsers from "application/list/web/columns/CountOfUsers";
import GuideButton from "application/list/web/columns/GuideButton";
import MobileCardMenus from "application/list/web/columns/MobileCardMenus";
import PropTypes from "prop-types";

const WebApplicationRow = ({
    app,
    history,
    isSmallSize,
    handleUpdateApplication,
    handleDeleteApplication,
}) => {
    const commonProps = { isSmallSize, history };
    const { transactional_users } = app;

    return (
        <div className="table-body mobile-table">
            <div
                className={classNames("table-row", {
                    "table-row--featured": transactional_users > 0,
                    small: isSmallSize,
                })}
            >
                <AppName app={app} {...commonProps} />
                <CreatedAt app={app} {...commonProps} />
                <CountOfUsers app={app} {...commonProps} />

                <GuideButton
                    //className="is-min-md"
                    platform="web"
                    app={app}
                    {...commonProps}
                />
                {/*    <RenameWebsite
                    className="is-min-md"
                    app={app}
                    handleUpdateApplication={handleUpdateApplication}
                    {...commonProps}/>
                <RemoveWebsite
                    className="is-min-md"
                    app={app}
                    handleDeleteApplication={handleDeleteApplication}
                    {...commonProps}/>*/}

                <MobileCardMenus
                    //className="is-max-md"
                    app={app}
                    handleUpdateApplication={handleUpdateApplication}
                    handleDeleteApplication={handleDeleteApplication}
                    {...commonProps}
                />
            </div>
        </div>
    );
};

WebApplicationRow.propTypes = {
    app: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool.isRequired,
    handleUpdateApplication: PropTypes.func.isRequired,
    handleDeleteApplication: PropTypes.func.isRequired,
};

export default withErrorCatcher(WebApplicationRow);
