import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    installList: [],
    hasMore: true,
    limit: 20,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.INSTALLATION.FETCH_WEB_INSTALLATIONS:
            if (action.payload.initial)
                return {
                    installList: action.payload.results,
                    hasMore: action.payload.results.length >= state.limit,
                    limit: state.limit,
                };
            return {
                installList: [
                    ...state.installList,
                    ...action.payload.results.filter(val => {
                        if (state.installList.length)
                            for (
                                let idx = 0;
                                idx < state.installList.length;
                                idx++
                            )
                                if (
                                    state.installList[idx].device_id ===
                                    val.device_id
                                )
                                    return false;
                        return true;
                    }),
                ],
                hasMore: action.payload.results.length >= state.limit,
                limit: state.limit,
            };

        case actionTypes.INSTALLATION.SEARCH_WEB_INSTALLATIONS:
            return {
                installList: action.payload.results,
                hasMore: action.payload.results.length >= state.limit,
            };
    }
    return state;
}
