import React from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import ModalDefault from "@/components/utils/modal-default";

export default class FaveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceFaveName: "",
        };
    }

    faveInstallation() {
        const { deviceFaveName } = this.state;
        this.props.onFaveInstallation(deviceFaveName);
    }

    renderError() {
        if (this.props.responseError !== null) {
            const errors = Object.entries(this.props.responseError).map(
                item => {
                    const title = item[1];
                    return `${title}`;
                },
            );
            return errors.map((key, index) => <div key={index}>{key}</div>);
        }
    }

    render() {
        return (
            <ModalDefault
                onClose={() => this.props.onModalClose()}
                title="افزودن دستگاه مورد علاقه"
                desc="برای دستگاه مورد علاقه خود یک نام انتخاب کنید."
                body={
                    <div>
                        <TextField
                            className="mui-textfield"
                            floatingLabelText="نام دستگاه من"
                            value={this.state.deviceFaveName}
                            type="text"
                            name="deviceFave"
                            onChange={event =>
                                this.setState({
                                    deviceFaveName: event.target.value,
                                })
                            }
                            fullWidth={true}
                            errorText={this.renderError()}
                        />
                    </div>
                }
                footer={
                    <div>
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            label="ذخیره"
                            onClick={_ => this.faveInstallation()}
                        />
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            label="انصراف"
                            onClick={() => this.props.onModalClose()}
                        />
                    </div>
                }
            />
        );
    }
}

FaveModal.propTypes = {
    onModalClose: PropTypes.func.isRequired,
};
