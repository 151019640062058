import { Field } from "redux-form";
import React from "react";
import { toFarsiNumbers, withErrorCatcher } from "@/components/utilities";
import { TextField } from "redux-form-material-ui";
import { isNil } from "lodash";
import PropTypes from "prop-types";

const ChooseVersionComponent = ({ fieldName }) => {
    return (
        <div className="notif-block__body">
            <div className="col-sm-6 col-xs-12">
                <Field
                    name={fieldName}
                    component={TextField}
                    className="mui-textfield"
                    type="text"
                    floatingLabelText="شماره نسخه جدید"
                    fullWidth={true}
                    normalize={(value, preValue) => {
                        let re = new RegExp(
                            // eslint-disable-next-line no-useless-escape
                            /^[0-9۰۱۲۳۴۵۶۷۸۹][\.\-]?[0-9۰۱۲۳۴۵۶۷۸۹]*$/,
                        );
                        if (value && re.test(value)) {
                            return value;
                        } else if (value.length > 1) {
                            return preValue;
                        } else {
                            return "";
                        }
                    }}
                    format={value =>
                        isNil(value) ? "" : toFarsiNumbers(value)
                    }
                />
            </div>
        </div>
    );
};

ChooseVersionComponent.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default withErrorCatcher(ChooseVersionComponent);
