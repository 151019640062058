import actionTypes from "@/actions/_types";

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.APPLICATION.FETCH_IOS_APPLICATIONS:
            return action.payload;

        case actionTypes.APPLICATION.DELETE_IOS_APPLICATION:
            return state.filter(app => app.app_id !== action.payload);

        case actionTypes.APPLICATION.CREATE_IOS_APPLICATION:
            return [...state, action.payload];

        case actionTypes.APPLICATION.UPDATE_IOS_APPLICATION:
            return state.map(val => {
                if (val.app_id === action.payload.app_id)
                    return {
                        ...val,
                        ...action.payload,
                    };
                return val;
            });
    }
    return state;
}
