import React from "react";
import {
    CircleLoader,
    PERMISSIONS,
    useHasPermission,
    withErrorCatcher,
} from "@/components/utilities";
import "styles/modules/_mod-segments.scss";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import AutomationTableBody from "@/components/notifications/automation/list/base/AutomationTableBody";
import { bindActionCreators } from "redux";
import {
    deleteAutomation,
    retrieveAutomationList,
    updateAutomation,
} from "@/actions/automation";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import FixedPageHeader from "@/components/utilities/commons/FixedPageHeader";
import RefreshButton from "@/components/utilities/commons/RefreshButton";
import ReactTooltip from "react-tooltip";
import AutomationPagePlaceholder from "@/components/notifications/automation/list/AutomationPagePlaceholder";
import { useFetchAutomationItems } from "@/components/notifications/automation/hooks/useFetchAutomationItems";
import WayPoint from "react-waypoint";
import { PLATFORMS } from "@/constants";

const WebNotificationAutomationList = props => {
    const platform = PLATFORMS.WEB;

    const { updateAutomation, deleteAutomation, history } = props;
    const hasCreatePermission = useHasPermission(
        PERMISSIONS.NOTIFICATION_AUTOMATION_CREATE,
    );

    const {
        automationItems,
        hasMore,
        loading,
        handleFetchAutomationItems,
        handleRefreshAutomationItems,
    } = useFetchAutomationItems(platform);

    if (isEmpty(automationItems) && !loading)
        return <AutomationPagePlaceholder history={history} />;

    return (
        <div className="segments table--featured">
            {!isEmpty(automationItems) && (
                <FixedPageHeader>
                    <div className="segments__page-header">
                        <header>
                            <p>نوتیفیکیشن اتوماتیک</p>
                        </header>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <RefreshButton
                                animationIsRunning={loading}
                                tooltipPosition={"right"}
                                onClick={handleRefreshAutomationItems}
                            />
                            <span
                                data-tip={
                                    hasCreatePermission
                                        ? ""
                                        : "برای ساخت نوتیفیکیشن اتوماتیک باید پنل خود را ارتقا دهید"
                                }
                            >
                                <FlatButton
                                    disabled={!hasCreatePermission}
                                    className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                                    rippleColor={blue900}
                                    label="نوتیفیکیشن اتوماتیک جدید"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() =>
                                        history.push(
                                            `/notification/automation/${platform}/create/`,
                                        )
                                    }
                                />
                            </span>
                        </div>
                    </div>
                </FixedPageHeader>
            )}

            {!isEmpty(automationItems) && (
                <AutomationTableBody
                    history={history}
                    automationItems={automationItems}
                    updateAutomation={updateAutomation}
                    deleteAutomation={deleteAutomation}
                    platform={platform}
                />
            )}

            <div className="financial__loader">
                {loading && <CircleLoader size={30} />}
                {!loading &&
                    !hasMore &&
                    (!Array.isArray(automationItems) ||
                        automationItems.length === 0) && (
                        <p style={{ textAlign: "center", padding: "0 0 20px" }}>
                            اطلاعات جدیدی وجود ندارد
                        </p>
                    )}
            </div>

            <WayPoint
                scrollableAncestor={window}
                onEnter={handleFetchAutomationItems}
            />

            <ReactTooltip className="tooltip" effect="solid" place="right" />
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            deleteAutomation,
            updateAutomation,
            retrieveAutomationList,
        },
        dispatch,
    );
};

export default withErrorCatcher(
    connect(null, mapDispatchToProps)(WebNotificationAutomationList),
);
