import React from "react";
import { errorToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import * as Sentry from "@sentry/browser";
import { withErrorBoundary } from "react-error-boundary";

const FallbackComponent = () => (
    <div style={{ color: "red", textAlign: "center", padding: "10px 0px" }}>
        <p>مشکلی در نمایش صفحه رخ داده است</p>
        <p>لطفا با پشتیبانی پوشه تماس بگیرید</p>
        <a href="mailto://support@pushe.co">support@pushe.co</a>
    </div>
);

const errorHandler = componentName => err => {
    setTimeout(() => {
        errorToast(
            TOAST_CONTENT.RENDER_ERROR.TITLE,
            TOAST_CONTENT.RENDER_ERROR.DESC,
        );
    }, 1000);
    Sentry.withScope(scope => {
        scope.addEventProcessor(async event => {
            event.extra = event.extra || {};
            event.extra.exceptionCaught = true;
            event.extra.exceptionCatcher = componentName;
            return event;
        });
        Sentry.captureException(err);
    });
};

export function withErrorCatcher(component) {
    return withErrorBoundary(
        component,
        FallbackComponent,
        errorHandler(component.name),
    );
}
