import React from "react";

const style = {
    wrapper: {
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 0",
    },
    svg: {
        fill: "#ccc",
        width: "120px",
        height: "auto",
    },
    desc: {
        padding: "10px",
    },
    link: {
        color: "#0065ff",
        fontWeight: "bold",
        fontSize: ".9rem",
    },
};

const PermissionPlaceholder = props => {
    const { isPage } = props;

    return (
        <div
            style={{
                ...style.wrapper,
                position: isPage ? "absolute" : "relative",
            }}
        >
            <svg style={style.svg}>
                <use href="#svg_update_plan" />
            </svg>
            <div style={style.desc}>
                برای مشاهده این قسمت باید پلن خود را ارتقا دهید.
            </div>
            <div>
                <a
                    style={style.link}
                    href="https://pushe.co/pricing"
                    target="_blank"
                    rel="noreferrer"
                >
                    مشاهده پلن‌ها
                </a>
            </div>
        </div>
    );
};

export { PermissionPlaceholder };
