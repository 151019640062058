import React from "react";
import PropTypes from "prop-types";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import BaseModal from "@/components/utilities/modal/baseModal";
import { withErrorCatcher } from "@/components/utilities";

class FavoriteDeviceModal extends React.Component {
    constructor(props) {
        super(props);
    }

    handleTypeHttp = () => {
        const { app: { is_https, domain } = {} } = this.props;
        if (is_https) {
            return `https://${domain}?pushe-fav`;
        } else {
            return `http://${domain}?pushe-fav`;
        }
    };
    render() {
        // eslint-disable-next-line no-unused-vars
        const { app: { app_id, domain, is_https } = {}, onClose } = this.props;

        const _onSuccess = () => {
            onClose();
        };

        return (
            <BaseModal
                onClose={() => this.props.onClose()}
                title="افزودن دستگاه به لیست علاقه مندی ها"
                desc="با کلیک بروی افزودن دستگاه خود را به علاقه مندی ها اضافه کنید"
                body={
                    <div>
                        <div>یا</div>
                        در صورتی که دستگاه دیگری را میخواهید به لیست علاقه مندی
                        هایتان اضافه کنید عبارت
                        <span dir={"ltr"} style={{ padding: "0 8px" }}>
                            {"?pushe-fav"}
                        </span>
                        انتهای ادرس سایت خودتان در دستگاه ذکر شده اضافه کنید
                    </div>
                }
                footer={
                    <div>
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            label="افزودن "
                            href={this.handleTypeHttp()}
                            target={"_blank"}
                            onClick={() => {
                                onClose();
                            }}
                        />
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            label="انصراف"
                            onClick={() => onClose()}
                        />
                    </div>
                }
            />
        );
    }
}

FavoriteDeviceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    error: PropTypes.object,
};

export default withErrorCatcher(FavoriteDeviceModal);
