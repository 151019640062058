import React from "react";
import WebNotifStep1 from "./steps/WebNotifStep1";
import WebNotifTestModal from "@/components/notifications/notification/create/web/parials/webNotifeTest/WebNotifTestModal";
import { webNotificationNormalizer } from "@/components/notifications/notification/normalize";
import WebNotifPreview from "@/components/notifications/notification/create/web/WebNotifPreview";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import { PLATFORMS } from "@/constants";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import createWebInitFormData from "@/components/notifications/notification/create/web/controls/createWebInitFormData";
import { clearForm } from "@/actions/app";
import {
    createNotification,
    editDraft,
    sendDraft,
} from "@/actions/notifications";
import { emptyInprog } from "@/actions/inprog";
import { withErrorCatcher } from "@/components/utilities";
import { useDispatch } from "react-redux";
import { normalize } from "@/components/utils/normalize";
import NotificationFormFinalStep from "@/components/notifications/notification/create/base/NotificationFormFinalStep";
import { NOTIFICATION_MODE } from "@/actions/_types";
import WebNotifStep2 from "./steps/WebNotifStep2";
import WebNotifStep3 from "./steps/WebNotifStep3";

const WebNotifWizard = props => {
    const { notification, notificationMode, history, appId } = props;
    const dispatch = useDispatch();
    const platform = PLATFORMS.WEB;

    const normalizeInitialData = () => {
        let newNotification = {};
        if (notification?.data?.action?.url) {
            const { action = undefined } = notification?.data;
            let url = new URL(action?.url);
            let params = new URLSearchParams(url.search);
            params.get("utm_medium");
            params.get("utm_source");
            params.get("utm_campaign");
            newNotification = {
                ...notification,
                data: {
                    ...notification.data,
                    action: {
                        ...notification.data.action,
                        url: notification.data.action.params.url,
                    },
                    utm_source: params.get("utm_source"),
                    utm_campaign: params.get("utm_campaign"),
                    utm_medium: params.get("utm_medium"),
                },
            };
            return newNotification;
        } else {
            return notification;
        }
    };
    return (
        <BaseWizard
            formName="notification_wizard"
            title="ارسال نوتیفیکیشن وب"
            subtitle="با این فرم می‌توانید نوتیفیکیشن ساده و پیشرفته به وب‌سایت‌ها بفرستید."
            platform={platform}
            steps={steps}
            stepFinale={NotificationFormFinalStep}
            history={history}
            footer={WizardFooter}
            preview={WebNotifPreview}
            formInitialData={createWebInitFormData(
                normalizeInitialData(),
                notificationMode,
                appId,
            )}
            testModal={WebNotifTestModal}
            onUnmountCallback={() => {
                clearForm("notification_wizard")(dispatch);
                emptyInprog()(dispatch);
            }}
            submitAction={(data, onSuccess, onFailure) => {
                let normalizedData = normalize(data, webNotificationNormalizer);
                //
                if (data.isDraftMode) {
                    normalizedData = { ...normalizedData, is_draft: true };
                }
                if (
                    notificationMode === NOTIFICATION_MODE.DRAFT &&
                    data.isDraftMode
                ) {
                    // Clicked on `ذخیره پیش‌نویس‌‍‍‍` when editing a draft
                    editDraft(
                        {
                            ...normalizedData,
                            wrapper_id: notification.wrapper_id,
                            app_ids: notification.app_ids,
                        },
                        onSuccess,
                        onFailure,
                    )(dispatch);
                } else if (
                    notificationMode === NOTIFICATION_MODE.DRAFT &&
                    !data.isDraftMode
                ) {
                    // Clicked on `ارسال نهایی` when editing a draft
                    sendDraft(
                        {
                            app_ids: notification.app_ids,
                            wrapper_id: notification.wrapper_id,
                        },
                        onSuccess,
                        onFailure,
                    )(dispatch);
                } else {
                    createNotification(
                        normalizedData,
                        platform,
                        notificationMode,
                        onSuccess,
                        onFailure,
                    )(dispatch);
                }
            }}
            hasReduxForm={true}
            passToSteps={{
                platform,
                notificationMode,
            }}
            hasDraftBtn={true}
        />
    );
};

const steps = [
    { component: WebNotifStep1, showTestBtn: true },
    {
        component: WebNotifStep2,
        showTestBtn: true,
        isEnabled: values =>
            values.notificationMode === NOTIFICATION_MODE.NORMAL,
    },
    { component: WebNotifStep3, showTestBtn: true },
];

export default withErrorCatcher(WebNotifWizard);
