import React from "react";
import { IOS_FRAMEWORKS } from "@/actions/_types";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import { withErrorCatcher } from "@/components/utilities";
import BaseModal from "@/components/utilities/modal/baseModal";
import "styles/modules/_mod-application.scss";
import { keys } from "lodash";

const images = {
    ReactNative: require("images/app-builder/react-native.png"),
    Xcode: require("images/app-builder/xcode.png"),
    Flutter: require("images/app-builder/flutter_gray.svg"),
};

class IosFrameworkModal extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        onClick: PropTypes.func,
        onClose: PropTypes.func.isRequired,
    };

    onClick({ framework }) {
        const { onClick } = this.props;

        if (onClick) onClick(framework);
    }

    render() {
        const { onClose } = this.props;

        return (
            <BaseModal
                customClass="modal--market"
                modalSize="md"
                title={"انتخاب فریمورک"}
                body={
                    <div className="frameworksModal">
                        <div className="frameworksModal__content">
                            {keys(IOS_FRAMEWORKS.NUMBERS).map(number => {
                                return (
                                    <div
                                        className={`frameworksModal__box`}
                                        key={uuid()}
                                        onClick={() =>
                                            this.onClick({ framework: number })
                                        }
                                    >
                                        <img
                                            src={
                                                images[
                                                    IOS_FRAMEWORKS._getName(
                                                        number,
                                                    )
                                                ]
                                            }
                                        />
                                        <p>{IOS_FRAMEWORKS._getName(number)}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
                onClose={onClose}
            ></BaseModal>
        );
    }
}

export default withErrorCatcher(IosFrameworkModal);
