import React from "react";
import classNames from "classnames";
import webPricing1 from "images/pricing/pricing-web-1.svg";
import webPricing2 from "images/pricing/pricing-web-2.svg";
import webPricing3 from "images/pricing/pricing-web-3.svg";
import css from "authentication/styles/signup.module.scss";

const SignUpBanners = () => {
    // const TABS = { MOBILE: 1, WEB: 2 };

    return (
        <div className={css.bannerWrapper}>
            <div className={classNames(css.tabBodyInner)}>
                <div className={css.pricingItems}>
                    <img
                        className={css.pricingImage}
                        src={webPricing1}
                        alt={"pricing image"}
                    />
                    <div className={css.pricingContent}>
                        <h4 className={css.pricingTitle}>رشد</h4>
                        <div className={css.border} />
                        <p>تا ۱۰۰ هزار کاربر فعال</p>
                        <p>۲۵ تومان برای هر کاربر</p>
                        <p>امکان استفاده آزمایشی و رایگان به مدت ۱۴ روز</p>
                    </div>
                </div>

                <div className={css.pricingItems}>
                    <img
                        className={css.pricingImage}
                        src={webPricing2}
                        alt={"pricing image"}
                    />
                    <div className={css.pricingContent}>
                        <h4 className={css.pricingTitle}>حرفه ای</h4>
                        <div className={css.border} />
                        <p>تا ۲ میلیون کاربر فعال</p>
                        <p>۵۰ تومان برای هر کاربر</p>
                        <p>امکان استفاده آزمایشی و رایگان به مدت ۱۴ روز</p>
                    </div>
                </div>

                <div className={css.pricingItems}>
                    <img
                        className={css.pricingImage}
                        src={webPricing3}
                        alt={"pricing image"}
                    />
                    <div className={css.pricingContent}>
                        <h4 className={css.pricingTitle}>اختصاصی</h4>
                        <div className={css.border} />
                        <p>تماس بگیرید</p>
                    </div>
                </div>
            </div>

            <div className={css.bannerFooter}>
                <p>
                    <a
                        target="_blank"
                        href={`https://pushe.co/pricing`}
                        rel="noreferrer"
                    >
                        قیمت‌گذاری
                        <span className={css.arrow}>&#8592;</span>
                    </a>
                </p>
            </div>
        </div>
    );
};

export default SignUpBanners;
