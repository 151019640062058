
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Step1Tabs from "./tabs/Step1Tabs.mdx";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`اضافه کردن `}<em parentName="h1">{`NotificationServiceExtension`}</em>{` به پروژه`}</h1>
    <p>{`برای نمایش عکس ، ویدیو ، و ...
(Rich Media)
باید
‍`}<inlineCode parentName="p">{`NotificationServiceExtension`}</inlineCode>{`
را به پروژه‌تان در Xcode اضافه و پیاده سازی کنید.
همچنین اطمینان حاصل کنید که ‍‍pod Pushe را برای Target مربوط به NotificationServiceExtension در Podfile اضافه کرده باشید.
`}<a parentName="p" {...{
        "href": "http://docs.pushe.co/docs/ios/intro/"
      }}>{`مشاهده راهنما کامل`}</a></p>
    <Step1Tabs mdxType="Step1Tabs" />
    <p>{`برای اطلاعات بیشتر به لینک‌های زیر مراجعه کنید:`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/ios/intro/"
      }}>{`مستندات پوشه برای ios`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/react-native/intro/"
      }}>{`مستندات پوشه برای ری‌اکت-نیتیو`}</a></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;