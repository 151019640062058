import { useInfiniteQuery, useMutation } from "react-query";
import {
    SMS_KEYS,
    fetchAutomations,
    removeAutomation,
    updateAutomation,
} from "@/queries/sms";
import {
    commonGetFetchMore,
    onSuccessFilterList,
    onSuccessUpdateItemInList,
} from "@/queries/constants";

export const useSMSAutomationApi = () => {
    /**
     * ***************** List Api Request **********************
     */
    const { status, fetchMore, canFetchMore, isFetchingMore, data, refetch } =
        useInfiniteQuery(SMS_KEYS.AUTOMATION_LIST(), fetchAutomations, {
            cacheTime: Infinity,
            staleTime: 0,
            getFetchMore: commonGetFetchMore,
        });

    const automationItems = data?.[0] ?? [];
    const isLoading = isFetchingMore || status === "loading";

    /**
     * ********************** Update Api Request ************************
     */
    const [updateMutation] = useMutation(updateAutomation, {
        onSuccess: automation =>
            onSuccessUpdateItemInList(
                SMS_KEYS.AUTOMATION_LIST(),
                "automation_id",
                automation,
            ),
    });

    /**
     * ********************** Delete Api Request ************************
     */
    const [deleteMutation] = useMutation(removeAutomation, {
        onSuccess: automationId =>
            onSuccessFilterList(
                SMS_KEYS.AUTOMATION_LIST(),
                "automation_id",
                automationId,
            ),
    });

    return {
        fetchMore,
        automationItems,
        isLoading,
        refetch,
        displayPagePlaceholder: automationItems.length === 0 && !isFetchingMore,
        noMoreData:
            !isLoading &&
            !canFetchMore &&
            (!Array.isArray(automationItems) || automationItems.length === 0),
        updateMutation,
        deleteMutation,
    };
};
