import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ROUTES from "@/routing/imports";
import PublicLayout from "@/routing/layouts/PublicLayout";
import PrivateLayout from "@/routing/layouts/PrivateLayout";
import NotFoundPage from "@/components/app/components/NotFoundPage";
import { PERMISSIONS } from "@/components/utilities/permission/permissions";
import { BREADCRUMBS } from "@/routing/constants";
import { PROFILE_TABS } from "@/components/app/profile/constants";

/**
 * Try to construct url routes like bellow
 * page/subpage(type)/:platform/action/:id/:etc
 *
 */
const Routes = () => {
    return (
        <Switch>
            {/* ******************* Public Routes ******************* */}
            <PublicLayout exact path="/" component={ROUTES.SignInPage} />
            <PublicLayout path="/signin" component={ROUTES.SignInPage} />
            <PublicLayout path="/signup" component={ROUTES.SignUpPage} />
            <PublicLayout
                path="/deskpro/"
                component={ROUTES.SignInDeskProPage}
            />
            <PublicLayout
                path="/forgot-password"
                component={ROUTES.ForgotPassPage}
            />
            <PublicLayout
                path="/reset-password"
                component={ROUTES.ResetPassPage}
            />
            <PublicLayout
                path="/activation"
                component={ROUTES.ActivationPage}
            />
            <PublicLayout
                path="/noafarin"
                component={ROUTES.NoafarinAuthPage}
            />
            <PublicLayout
                path="/email-verified"
                component={ROUTES.EmailChangeVerifiedPage}
            />
            <PublicLayout path="/otp-auth" component={ROUTES.OTPAuthPage} />

            {/* ******************* Private Routes ******************* */}

            <PrivateLayout
                path="/old-dashboard/:type?/:id?"
                breadcrumb={BREADCRUMBS.DASHBOARD.LABEL}
                component={ROUTES.Dashboard}
            />
            <PrivateLayout
                path="/old-dashboard/:platform/:type?/:id?"
                breadcrumb={BREADCRUMBS.DASHBOARD.LABEL}
                component={ROUTES.Dashboard}
            />
            {/*new dashboard for android and web */}
            <PrivateLayout
                path="/dashboard/:platform/:type?/:id?"
                breadcrumb={BREADCRUMBS.DASHBOARD.LABEL}
                component={ROUTES.DashboardPage}
            />
            <PrivateLayout
                path="/dashboard/:platform/:type?/:id?"
                breadcrumb={BREADCRUMBS.DASHBOARD.LABEL}
                component={ROUTES.DashboardPage}
            />
            {/* Profile or Account */}
            <PrivateLayout
                exact
                path="/account"
                profileTab={PROFILE_TABS.INFORMATION.KEY}
                component={ROUTES.Profile}
            />
            <PrivateLayout
                exact
                path="/account/api"
                profileTab={PROFILE_TABS.API.KEY}
                component={ROUTES.Profile}
            />
            <PrivateLayout
                exact
                path="/account/credentials"
                profileTab={PROFILE_TABS.CREDENTIALS.KEY}
                component={ROUTES.Profile}
            />
            <PrivateLayout
                exact
                path="/account/security"
                profileTab={PROFILE_TABS.SECURITY.KEY}
                component={ROUTES.Profile}
            />
            <PrivateLayout
                exact
                path="/account/services"
                profileTab={PROFILE_TABS.CONFIG.KEY}
                component={ROUTES.Profile}
            />
            <PrivateLayout
                exact
                path="/financial"
                breadcrumb={BREADCRUMBS.FINANCIAL.LABEL}
                component={ROUTES.FinancialPage}
            />

            {/* Applications */}
            <PrivateLayout
                exact
                path="/application/:platform/create"
                component={ROUTES.ApplicationPage}
            />
            <PrivateLayout
                exact
                path="/application/:platform/guide/:appId"
                component={ROUTES.GuidePage}
            />
            <PrivateLayout
                exact
                path="/application/:platform/manifest/:appId"
                component={ROUTES.ManifestPage}
            />
            <PrivateLayout
                exact
                path="/application/android"
                breadcrumb={BREADCRUMBS.APPLICATION.LABEL}
                component={ROUTES.Applications}
            />
            <PrivateLayout
                path="/application/:platform"
                breadcrumb={BREADCRUMBS.APPLICATION.LABEL}
                component={ROUTES.ApplicationsPage}
            />

            {/* Users */}
            <PrivateLayout
                exact
                path="/installation/:platform"
                breadcrumb={BREADCRUMBS.USERS.INSTALLATION.LABEL}
                component={ROUTES.InstallationsPage}
            />
            <PrivateLayout
                exact
                path="/user/:platform"
                breadcrumb={BREADCRUMBS.USERS.ANALYTIC.LABEL}
                component={ROUTES.UsersAnalyticsPage}
            />
            <PrivateLayout
                exact
                path="/segmentation/android"
                breadcrumb={BREADCRUMBS.USERS.SEGMENTATION.LABEL}
                component={ROUTES.SegmentationPage}
            />
            <PrivateLayout
                exact
                path="/segmentation/web"
                breadcrumb={BREADCRUMBS.USERS.SEGMENTATION.LABEL}
                component={ROUTES.SegmentationWeb}
            />

            {/* Notification */}
            <PrivateLayout
                exact
                path="/notification/:platform/create"
                component={ROUTES.SendNotificationPage}
            />
            <PrivateLayout
                exact
                path="/notification/:platform/welcome/:appId/:notificationId?"
                component={ROUTES.WelcomeNotificationPage}
            />
            <PrivateLayout
                exact
                path="/notification/:platform/forward/:notificationId"
                component={ROUTES.ForwardNotificationPage}
            />
            <PrivateLayout
                exact
                path="/notification/:platform/edit/:notificationId"
                component={ROUTES.EditDraftPage}
            />
            <PrivateLayout
                exact
                path="/notification/:platform"
                breadcrumb={BREADCRUMBS.NOTIFICATION.NOTIFICATION.LABEL}
                component={ROUTES.NotificationListPage}
            />
            <PrivateLayout
                exact
                path="/notification/automation/:platform/create/"
                component={ROUTES.NotificationAutomationCreatePage}
                permission={PERMISSIONS.NOTIFICATION_AUTOMATION_CREATE}
            />
            <PrivateLayout
                exact
                path="/notification/automation/:platform/edit/:automationId"
                component={ROUTES.NotificationAutomationEditPage}
            />
            <PrivateLayout
                exact
                path="/notification/automation/:platform"
                breadcrumb={BREADCRUMBS.NOTIFICATION.AUTOMATION.LABEL}
                component={ROUTES.NotificationAutomationPage}
            />
            <PrivateLayout
                exact
                path="/notification/geofence/:platform/create/"
                component={ROUTES.NotificationGeofenceCreatePage}
                permission={PERMISSIONS.GEOFENCE_CREATE}
            />
            <PrivateLayout
                exact
                path="/notification/geofence/:platform/edit/:geofenceId"
                component={ROUTES.NotificationGeofenceEditPage}
            />
            <PrivateLayout
                exact
                path="/notification/geofence/:platform"
                breadcrumb={BREADCRUMBS.NOTIFICATION.GEOFENCE.LABEL}
                component={ROUTES.NotificationGeofencePage}
            />

            {/* Email */}
            <PrivateLayout
                exact
                path="/email/:platform/create"
                component={ROUTES.SendEmailPage}
                permission={PERMISSIONS.EMAIL_CREATE}
            />
            <PrivateLayout
                exact
                path="/email/:platform/forward/:id"
                component={ROUTES.ForwardEmailPage}
                permission={PERMISSIONS.EMAIL_CREATE}
            />
            <PrivateLayout
                exact
                path="/email/:platform"
                breadcrumb={BREADCRUMBS.EMAIL.EMAIL.LABEL}
                component={ROUTES.EmailListPage}
            />
            <PrivateLayout
                exact
                path="/email/automation/:platform/create"
                component={ROUTES.EmailAutomationCreatePage}
            />
            <PrivateLayout
                exact
                path="/email/automation/:platform/edit/:automationId"
                component={ROUTES.EmailAutomationEditPage}
            />
            <PrivateLayout
                exact
                path="/email/automation/:platform"
                breadcrumb={BREADCRUMBS.EMAIL.AUTOMATION.LABEL}
                component={ROUTES.EmailAutomationListPage}
            />

            {/* SMS */}
            <PrivateLayout
                exact
                path="/sms/:platform/create/"
                component={ROUTES.SendSMSPage}
                permission={PERMISSIONS.SMS_CREATE}
            />
            <PrivateLayout
                exact
                path="/sms/:platform/forward/:id"
                component={ROUTES.ForwardSMSPage}
                permission={PERMISSIONS.SMS_CREATE}
            />
            <PrivateLayout
                exact
                path="/sms/:platform"
                breadcrumb={BREADCRUMBS.SMS.SMS.LABEL}
                component={ROUTES.SMSListPage}
            />
            <PrivateLayout
                exact
                path="/sms/automation/:platform/create"
                component={ROUTES.SMSAutomationCreatePage}
            />
            <PrivateLayout
                exact
                path="/sms/automation/:platform/edit/:automationId"
                component={ROUTES.SMSAutomationEditPage}
            />
            <PrivateLayout
                exact
                path="/sms/automation/:platform"
                breadcrumb={BREADCRUMBS.SMS.AUTOMATION.LABEL}
                component={ROUTES.SMSAutomationPage}
            />

            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default withRouter(Routes);
