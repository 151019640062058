import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import Colors from "@/components/utils/lib/colors";
import PieChart from "@/components/utils/charts/types/_pieChartBase";
import PropTypes from "prop-types";

const DISTRIBUTIONS_NAME = {
    android: "نسخه اندروید",
    ios: "نسخه iOS",
};

class SpecificationsChart extends React.Component {
    static propTypes = {
        dashboard: PropTypes.object.isRequired,
        platform: PropTypes.string.isRequired,
    };

    static defaultProps = {
        dashboard: {},
    };

    getDataCharts() {
        const { dashboard, platform } = this.props;
        const { distributions = {} } = dashboard;
        const { app_version = null } = distributions;

        if (!app_version) return [];

        const getAppVersionSeries = data => {
            if (!data) return [];

            const colors = [
                Colors.orange,
                Colors.pink_f,
                Colors.blue_0,
                Colors.purple_b,
                Colors.blue_0,
                Colors.green,
                Colors.purple_f,
            ];

            const total = data ? data["#all"] || 1 : {};

            return Object.entries(data)
                .filter(value => value[0] != "#all")
                .map((value, index) => {
                    return {
                        title: value[0],
                        percentage: parseInt((value[1] / total) * 100),
                        color: colors[index % colors.length],
                    };
                });
        };

        return {
            line: getAppVersionSeries(app_version),
            spline: {
                categories: Array.isArray(dashboard.historical)
                    ? dashboard.historical.map(value => value["date"])
                    : dashboard.historical?.date,
                series: [
                    {
                        name: "نصب روزانه",
                        type: "spline",
                        data: Array.isArray(dashboard.historical)
                            ? dashboard.historical.map(
                                  value => value["last_installs"],
                              )
                            : dashboard.historical?.last_installs,
                    },
                    {
                        name: "حذف روزانه",
                        data: Array.isArray(dashboard.historical)
                            ? dashboard.historical.map(
                                  value => value["last_uninstalls"],
                              )
                            : dashboard.historical.last_uninstalls,
                    },
                ],
            },
            pie: [
                {
                    title: "مدل گوشی",
                    data: dashboard.distributions.model,
                    svg: "#svg_smartphone",
                    id: "chart_pie_model",
                },
                {
                    data: dashboard.distributions.screen,
                    title: "صفحه نمایش",
                    svg: "#svg_device_screeen",
                    id: "chart_pie_screen",
                },
                {
                    data: dashboard.distributions.os_version,
                    title: DISTRIBUTIONS_NAME[platform],
                    svg: "#svg_android_logo",
                    id: "chart_pie_version",
                },
                {
                    data: dashboard.distributions.brand,
                    title: "برند دستگاه",
                    svg: "#svg_device_brand",
                    id: "chart_pie_device",
                },
            ],
        };
    }

    render() {
        let chartData = (this.getDataCharts() || [])["pie"] || [];

        return (
            <>
                {chartData.map((pieChart, index) => (
                    <div
                        key={index}
                        className="col-lg-3 col-md-6 col-sm-12 col-xs-12"
                    >
                        <PieChart
                            data={pieChart.data}
                            title={pieChart.title}
                            svg={pieChart.svg}
                            id={pieChart.id}
                        />
                    </div>
                ))}
            </>
        );
    }
}

export default withErrorCatcher(SpecificationsChart);
