/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import {
    hasPermission,
    PERMISSIONS,
    withErrorCatcher,
} from "@/components/utilities";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getFormValues, reduxForm } from "redux-form";
import { FILTER_MODE } from "@/components/notifications/notification/constants";
import {
    arrayValidator,
    createValidator,
    isRequired,
} from "@/components/utils/validation";
import "styles/modules/_mod-card-b.scss";
import NotificationSettingsComponent from "@/components/utilities/commons/wizard/components/NotificationSettingsComponent";
import { fetchSegmentation } from "@/actions/segmentations";
import StepFilterTabs from "@/components/utilities/commons/wizard/StepFilterTabs";
import filterTabs from "@/components/notifications/notification/create/android/partials/filterTabsList";

const AndroidNotificationStep3 = props => {
    const { change, form } = props;
    const dispatch = useDispatch();
    const { filterType, max_recipients, data } = useSelector(
        state => getFormValues(form)(state) ?? {},
    );

    useEffect(() => {
        if (hasPermission(PERMISSIONS.SEGMENTATION_LIST))
            fetchSegmentation()(dispatch);
    }, []);

    return (
        <form className="notif-step3">
            <StepFilterTabs filterTabs={filterTabs} change={change} />

            {filterType === FILTER_MODE.FILTERS && (
                <NotificationSettingsComponent
                    maxRecipientsDefault={max_recipients}
                    channelDefault={data?.["notif_channel_id"]}
                />
            )}

            <ReactTooltip className="tooltip" effect="solid" />
        </form>
    );
};

const validators = createValidator({
    filters: {
        events: arrayValidator({
            name: isRequired,
            type: isRequired,
            op: isRequired,
            value: isRequired,
        }),
        visits: arrayValidator({
            type: isRequired,
            op: isRequired,
            value: isRequired,
        }),
    },
});

const AndroidNotificationStep3Form = reduxForm({
    form: "notification_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidNotificationStep3);

export default withRouter(withErrorCatcher(AndroidNotificationStep3Form));
