
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Manifest = makeShortcode("Manifest");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`راه‌اندازی پلاگین وردپرس`}</h1>
    <ul>
      <li parentName="ul">{`در صفحه مدیریت وردپرس به صفحه `}<em parentName="li">{`افزونه‌ها`}</em>{` بروید ، دکمه `}<em parentName="li">{`افزودن`}</em>{`
را کلیک کنید سپس از نوار جستجوی افزونه‌ها نام
`}<inlineCode parentName="li">{`pushe-webpush`}</inlineCode>{`
را جستوجو کنید.`}</li>
      <li parentName="ul">{`بعد از یافتن پلاگین وردپرس پوشه ، دکمه `}<em parentName="li">{`هم اکنون نصب کنید`}</em>{` را بزنید تا پلاگین نصب شود
سپس در انتها دکمه `}<em parentName="li">{`فعال کردن`}</em>{` را بزنید تا پلاگین فعال شود.`}</li>
      <li parentName="ul">{`در صورتی که مراحل بالا را با موفقیت طی کرده باشید در لیست منوهای کناری یک منو با عنوان
`}<em parentName="li">{`پوشه وب‌پوش`}</em>{` اضافه شده`}</li>
      <li parentName="ul">{`برای راه اندازی دریافت نوتیفیکیشن مقدار زیر را که همان `}<em parentName="li">{`app_id`}</em>{` شما در پوشه می‌باشد را کپی کرده`}</li>
    </ul>
    <Manifest {...props} mdxType="Manifest">
      <pre><code parentName="pre" {...{}}>{`{{appId}}
`}</code></pre>
    </Manifest>
    <ul>
      <li parentName="ul">{`و در زیرمنوی تنظیمات پلاگین پوشه `}<em parentName="li">{`app_id`}</em>{` را در فیلد مربوط به آن وارد کنید
و سوئیچ فعال سازی وب‌پوش را هم روشن کنید.
و در آخر دکمه ذخیره تغییرات را بزنید.`}</li>
      <li parentName="ul">{`در این مرحله کار راه‌اندازی به اتمام رسیده و می‌توانید به کاربران‌تان اعلان ارسال کنید`}</li>
    </ul>
    <p>{`برای تغییر متن ، دکمه‌ها و تنظیمات دیالوگ عضویت ‌وب‌پوش می توانید به زیر منو دیالوگ عضویت پلاگین پوشه بروید.`}</p>
    <p><a parentName="p" {...{
        "href": "http://docs.pushe.co/docs/wordpress/intro/"
      }}>{`مشاهده راهنمای کامل پلاگین وردپرس`}</a></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;