/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from "react";
import classNames from "classnames";
import previewWebBackground from "images/notification-preview/web-bg.jpg";
import WindowsToolbar from "images/svg-inline/windows_toolbar.svg";
import MacToolbar from "images/svg-inline/mac_toolbar.svg";
import { withErrorCatcher } from "@/components/utilities";
import memoizeOne from "memoize-one";
import isEqual from "lodash/isEqual";

const browsers = {
    chrome: require("images/webusers/chrome.png"),
    firefox: require("images/webusers/firefox.png"),
};

const CHROME = "CHROME";
const FIREFOX = "FIREFOX";
const _styles = {
    toggleButton: {
        display: "inline-block",
        verticalAlign: "middle",
        width: "60px",
    },
    track: {
        backgroundColor: "#fff",
        opacity: "0.6",
    },
    trackSwitched: {
        backgroundColor: "#ffffff",
        opacity: "0.6",
    },
    thumbSwitched: {
        backgroundColor: "#ffffff",
    },
};

const DesktopWebPreview = props => {
    const [state] = useState({
        windowsBrowser: CHROME,
        macBrowser: CHROME,
    });

    function findApp(appIds, applications) {
        const app =
            applications.find(app => app.app_id === appIds && app) || {};
        return app.pushe_subdomain
            ? `${app.pushe_subdomain}.web.pushe.co`
            : app.domain;
    }

    const memoizedAppDomain = memoizeOne(findApp, isEqual);

    const renderPreviewWindows = () => {
        const { windowsBrowser } = state;
        const previewWebRef = useRef();
        const previewBodyRef = useRef();
        return (
            <div
                ref={previewWebRef}
                className={classNames("preview-web preview-web--windows", {
                    "preview-web--chrome": windowsBrowser === CHROME,
                    "preview-web--firefox": windowsBrowser === FIREFOX,
                })}
            >
                <div className="preview-web__title">Windows - Chrome</div>
                <div
                    className="preview-web__body"
                    style={{ backgroundImage: `url(${previewWebBackground})` }}
                >
                    <div
                        className="preview-body preview-body--avatar"
                        ref={previewBodyRef}
                    >
                        {windowsBrowser === CHROME && renderWindowsChromeBody()}
                    </div>
                    <footer>
                        <WindowsToolbar />
                        <time>
                            <p>6:30 AM</p>
                            <p>7/30/2020</p>
                        </time>
                    </footer>
                </div>
            </div>
        );
    };

    const renderPreviewMac = () => {
        return (
            <div
                style={{ marginTop: 0 }}
                className="preview-web preview-web--mac"
            >
                <div className="preview-web__title">Mac - Chrome</div>
                <div
                    className="preview-web__body"
                    style={{ backgroundImage: `url(${previewWebBackground})` }}
                >
                    <header>
                        <MacToolbar />
                        <time>Thu 01:57</time>
                    </header>
                    <div className="preview-body preview-body--avatar">
                        {renderMacBody()}
                    </div>
                </div>
            </div>
        );
    };

    const renderWindowsChromeBody = () => {
        const { appIds, notification, applications } = props;
        const {
            icon = null,
            title,
            content,
            image,
            buttons,
        } = notification?.data ?? {};
        let buttonsArray =
            Array.isArray(buttons) && buttons.length > 0
                ? buttons.slice(0, 2)
                : [];
        let domain = memoizedAppDomain(appIds, applications);

        return (
            <div>
                <div className="preview-body__main">
                    {image && (
                        <div
                            className="preview-body__img"
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    )}

                    <div className="preview-body__content">
                        <div
                            className={classNames(
                                "preview-body__icon",
                                !icon && "default",
                            )}
                            style={{
                                backgroundImage: `url(${
                                    icon || browsers.chrome
                                })`,
                            }}
                        />
                        <div className="preview-body__innerContents">
                            <div className="preview-body__title">
                                {title || "تیتر"}
                            </div>
                            <div className="preview-body__desc">
                                {content || "متن نوتیفیکیشن"}
                            </div>
                            <div className="preview-body__browserSub">
                                Google Chrome • {domain || "example.com"}
                            </div>
                        </div>
                    </div>

                    {buttonsArray.length > 0 && (
                        <div
                            className={classNames(
                                "preview-body__buttons",
                                buttonsArray.length > 1 && "multiple",
                            )}
                        >
                            {buttonsArray.map((button, indx) => {
                                return (
                                    <div className="previewBtn" key={indx}>
                                        {button.btn_content}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderMacBody = () => {
        const { macBrowser } = state;
        const { appIds, notification, applications } = props;
        const { icon = null, title, content } = notification?.data ?? {};
        const browserLogo =
            macBrowser === CHROME ? browsers.chrome : browsers.firefox;
        let domain = memoizedAppDomain(appIds, applications);

        return (
            <div className="preview-body__main">
                <div
                    className="preview-body__browser-logo"
                    style={{ backgroundImage: `url(${browserLogo})` }}
                />

                <div className="preview-body__content">
                    <div className="preview-body__title">{title || "تیتر"}</div>
                    <div className="preview-body__browserSub">
                        {domain || "example.com"}
                    </div>
                    <div className="preview-body__desc">
                        {content || "متن نوتیفیکیشن"}
                    </div>
                </div>

                {icon && (
                    <div
                        className="preview-body__icon"
                        style={{ backgroundImage: `url(${icon})` }}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="preview preview--web ">
            {renderPreviewWindows()}
            {renderPreviewMac()}
        </div>
    );
};

export default withErrorCatcher(DesktopWebPreview);
