import React from "react";
import { normalize } from "@/components/utils/normalize";
import { PUSHE_APP_ID } from "@";
import { webNotificationNormalizer } from "@/components/notifications/notification/normalize";
import { successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import { useMutation, useQuery } from "react-query";

import {
    fetchWebFavorites,
    removeWebFavorite,
    sendTestWeb,
    updateTestWeb,
    WEB_FAV_KEYS,
} from "@/queries/web";
import {
    onSuccessCustomUpdateItemInList,
    onSuccessFilterList,
} from "@/queries/constants";
import SendTestDialogWeb from "@/components/notifications/notification/create/web/parials/webNotifeTest/SendTestDialogWeb";
import RenameFaveItemComponent from "@/components/notifications/notification/create/web/parials/webNotifeTest/RenameFaveItemComponent";

const WebNotifTestModal = props => {
    const { values: formValues } = props;
    const { data, isLoading: fetchIsLoading } = useQuery(
        WEB_FAV_KEYS.FAV(),
        fetchWebFavorites,
        { cacheTime: 0, staleTime: Infinity },
    );
    const [removeFavoriteMutation] = useMutation(removeWebFavorite, {
        onSuccess: id =>
            onSuccessFilterList(WEB_FAV_KEYS.FAV(), "id", id, true),
    });
    const [updateFavoriteMutation] = useMutation(updateTestWeb, {
        onSuccess: (data, params) => {
            onSuccessCustomUpdateItemInList(
                WEB_FAV_KEYS.FAV(),
                params,
                "favorite_data",
                "name",
                data,
                true,
            );
        },
    });
    const [sentTestMutation, { isLoading: sendTestIsLoading }] = useMutation(
        sendTestWeb,
        {
            onSuccess: () =>
                successToast(
                    TOAST_CONTENT.TEST_NOTIFICATION_SUCCESS.TITLE,
                    TOAST_CONTENT.TEST_NOTIFICATION_SUCCESS.DESC,
                ),
        },
    );

    const sendTest = async deviceId => {
        let values = formValues;
        if (formValues?.notification) {
            values = {
                ...formValues,
                data: { ...formValues.data, close_on_click: true },
                app_ids: [PUSHE_APP_ID],
                filters: { instance_id: [deviceId] },
            };
        }
        const normalized = normalize(values, webNotificationNormalizer);
        // await  sendTestNotification(normalized, );
        await sentTestMutation({
            ...normalized,
            filters: {
                ...(normalized?.filters ?? {}),
                instance_id: [deviceId],
            },
        });
    };

    return (
        <SendTestDialogWeb
            itemDel={"id"}
            items={data}
            isDisableAddItem
            isLoading={fetchIsLoading || sendTestIsLoading}
            AddItemComponent={RenameFaveItemComponent}
            removeMutation={removeFavoriteMutation}
            updateItemMutation={updateFavoriteMutation}
            sendTestFunc={sendTest}
            itemKey={"device_id"}
            itemLabel={"device_type"}
            dialogTitle={"ارسال اعلان تستی"}
            {...props}
        />
    );
};

export default WebNotifTestModal;
