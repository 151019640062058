import { useEffect, useState } from "react";

export const useGetResetPassToken = () => {
    const [token, setToken] = useState("");
    const getToken = () =>
        (window.location?.pathname ?? "").split(/\/reset-password\/(.+)\//)[1];

    useEffect(() => {
        const token = getToken();

        if (!token) window.location = "/";

        setToken(token);
    }, []);

    return token;
};
