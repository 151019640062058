import React from "react";
import Modal from "@/components/utilities/commons/modal";
import { List, ListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import Avatar from "material-ui/Avatar";
import PropTypes from "prop-types";
import WayPoint from "react-waypoint";
import Loading from "@/components/utils/loading";
import { bindActionCreators } from "redux";
import {
    fetchFaveInstallations,
    fetchFavorites,
} from "@/actions/installations";
import connect from "react-redux/es/connect/connect";
import uuid from "uuid/v4";
import entries from "lodash/fp/entries";
import groupBy from "lodash/fp/groupBy";
import indexBy from "lodash/fp/indexBy";
import map from "lodash/fp/map";
import mapValues from "lodash/fp/mapValues";
import values from "lodash/fp/values";

class NotifTestModal extends React.Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        sendNotif: PropTypes.func.required,
        favInstallations: PropTypes.array,
        favDevices: PropTypes.array,
        hasMoreFavorite: PropTypes.bool,
        values: PropTypes.object.required,
    };

    static defaultProps = {
        onModalClose: () => {},
        onSubmit: () => {},
        favInstallations: [],
        favDevices: [],
        hasMoreFavorite: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            favourites: [],
            offset: 0,
        };
    }

    componentDidMount() {
        this.props.fetchFavorites();
        this.updateInstallations();
        this.fetchFavInstallations();
    }

    /**
     * Updates favourite installation list in state if it has changed in props
     */
    componentDidUpdate(prevProps) {
        if (
            prevProps.favDevices !== this.props.favDevices ||
            prevProps.favInstallations !== this.props.favInstallations
        ) {
            this.updateInstallations();
        }
    }

    async updateInstallations() {
        const favDict = await Promise.resolve(this.props.favDevices)
            .then(indexBy(x => x.pushe_id))
            .then(mapValues(x => x.name));

        const favourites = await Promise.resolve(this.props.favInstallations)
            .then(
                map(install => ({
                    instance_id: install.instance_id,
                    device_id: install.device_id,
                    pushe_id: install.pushe_id,
                    device_icon: install.device_icon,
                    app_icon: install.app_info.icon,
                    app_id: install.app_info.id,
                    app_name: install.app_info.name,
                })),
            )
            .then(groupBy(install => favDict[install.pushe_id]))
            .then(entries)
            .then(map(([key, value]) => ({ title: key, list: value })))
            .then(values);

        this.setState(() => ({ favourites }));
    }

    fetchFavInstallations() {
        const { offset } = this.state;

        this.props.fetchFaveInstallations({ offset }, dataLength => {
            this.setState({ offset: offset + dataLength });
        });
    }

    submit(appId, instanceId) {
        const { sendNotif, values } = this.props;

        const data = {
            ...values,
            app_ids: [appId],
            filters: { instance_id: [instanceId] },
        };

        sendNotif(data);
    }

    render() {
        const { onModalClose, hasMoreFavorite } = this.props;

        return (
            <Modal
                className="modal--sm modal--device"
                isModalInside={true}
                onModalClose={onModalClose.bind(this)}
            >
                <div className="modal-header">
                    <p>لیست دستگاه‌های مورد علاقه‌ی شما</p>
                </div>
                <div
                    className="modal-body text-left"
                    style={{ maxHeight: "500px", overflow: "auto" }}
                >
                    {this.state.favourites.map((item, index) => {
                        return (
                            <div className="block" key={index}>
                                <div className="block-content">
                                    <List>
                                        <Subheader>{item.title}</Subheader>
                                        {(item.list || []).map(listItem => (
                                            <ListItem
                                                key={uuid()}
                                                style={{ marginRight: "10px" }}
                                                primaryText={listItem.app_name}
                                                onClick={() =>
                                                    this.submit(
                                                        listItem.app_id,
                                                        listItem.instance_id,
                                                    )
                                                }
                                                rightAvatar={
                                                    <Avatar
                                                        src={listItem.app_icon}
                                                    />
                                                }
                                            />
                                        ))}
                                    </List>
                                </div>
                            </div>
                        );
                    })}
                    <WayPoint onEnter={() => this.fetchFavInstallations()}>
                        {hasMoreFavorite ? (
                            <div className="table-loading">
                                <Loading className="loading-circle--blue-gray" />
                            </div>
                        ) : (
                            <div className="table-end">
                                <p>اطلاعات جدیدی وجود ندارد</p>
                            </div>
                        )}
                    </WayPoint>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const { platform } = state.app;
    return {
        favInstallations: state[platform].installations.favInstallationList,
        favDevices: state[platform].favorites.favList,
        hasMoreFavorite: state[platform].installations.hasMoreFave,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchFaveInstallations,
            fetchFavorites,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifTestModal);
