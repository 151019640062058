import actionTypes from "@/actions/_types";

const initial_state = {
    segmentationListWeb: [],
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case actionTypes.Web.SEGMENTATION.CREATE_SEGMENTATION:
            return {
                ...state,
                segmentationListWeb: [
                    action.payload,
                    ...state.segmentationListWeb,
                ],
            };
        case actionTypes.Web.SEGMENTATION.UPDATE_SEGMENTATION:
            return {
                ...state,
                segmentationListWeb: state.segmentationListWeb.map(sg => {
                    if (sg.segment_id === action.payload.segment_id) {
                        return action.payload;
                    }
                    return sg;
                }),
            };
        case actionTypes.Web.SEGMENTATION.DELETE_SEGMENTATION:
            return {
                ...state,
                segmentationListWeb: state.segmentationListWeb.filter(
                    sg => sg.segment_id !== action.payload.segmentId,
                ),
            };
        case actionTypes.Web.SEGMENTATION.FETCH_SEGMENTATION:
            return {
                ...state,
                segmentationListWeb: [...action.payload],
            };
        default:
            return state;
    }
};
