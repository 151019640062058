import css from "authentication/styles/noafarinSignUp.module";
import React from "react";

function NotRegisteredNoAfarin() {
    return (
        <a
            className={css.subLink}
            target="_blank"
            href={"https://irannoafarin.ir/"}
            rel="noreferrer"
        >
            در سامانه ایران نو‌آفرین ثبت نام نکرده ام
            <span className={css.arrow}>&#8592;</span>
        </a>
    );
}

export default NotRegisteredNoAfarin;
