import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ReduxSimpleTextField } from "@/components/utilities";
import { Field, reduxForm } from "redux-form";
import { createValidator, isRequired } from "@/components/utils/validation";
import { useDispatch } from "react-redux";
import { createCredential } from "@/actions/credentials";

const CredentialAdd = props => {
    const dispatch = useDispatch();
    const { open, onClose, handleSubmit } = props;

    const handleSaveCredential = ({ server_key, client_key }) => {
        createCredential(
            { server_key, client_key },
            {
                onSuccess: () => onClose(),
            },
        )(dispatch);
    };

    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason === "backdropClick") return;
                onClose();
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                اضافه کردن شناسه جدید
            </DialogTitle>
            <DialogContent>
                <Field
                    component={ReduxSimpleTextField}
                    name="client_key"
                    label="*FCM Sender ID"
                />
                <Field
                    component={ReduxSimpleTextField}
                    name="server_key"
                    label="*FCM Server Key"
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    انصراف
                </Button>
                <Button
                    onClick={handleSubmit(handleSaveCredential)}
                    color="primary"
                >
                    ذخیره
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validators = createValidator({
    client_key: [isRequired],
    server_key: [isRequired],
});

const CredentialAddForm = reduxForm({
    form: "AddCredentials",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: validators,
})(CredentialAdd);

export default CredentialAddForm;
