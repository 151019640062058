import React from "react";
import BaseModal from "@/components/utilities/modal/baseModal";
import { withErrorCatcher } from "@/components/utilities";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import termsMd from "markdowns/terms.md";
import privacyMd from "markdowns/privacy.md";
import ReactMarkdown from "react-markdown";

class TermsAndPrivacyModal extends React.Component {
    static propTypes = {
        onAccept: PropTypes.func,
        onClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.privacyElem = React.createRef();
        this.termsElem = React.createRef();
    }

    handleLinkClick(type) {
        let elem;

        if (type === "privacy") {
            elem = this.privacyElem.current;
        } else if (type === "terms") {
            elem = this.termsElem.current;
        }

        if (elem) {
            elem.scrollIntoView(true);
        }
    }

    render() {
        const { onAccept, onClose } = this.props;

        return (
            <BaseModal
                onClose={onClose}
                title={
                    <>
                        <span
                            className="modal--terms__anchors"
                            onClick={() => this.handleLinkClick("terms")}
                        >
                            شرایط استفاده از پوشه
                        </span>
                        &nbsp;و&nbsp;
                        <span
                            className="modal--terms__anchors"
                            onClick={() => this.handleLinkClick("privacy")}
                        >
                            شرایط حفظ حریم خصوصی
                        </span>
                    </>
                }
                onAccept={onAccept}
                modalSize={"lg"}
                footer={
                    <>
                        <p
                            style={{
                                fontSize: ".8rem",
                                textAlign: "right",
                                marginTop: "15px",
                            }}
                        >
                            * بستن این پیام به منزله پذیرش شرایط استفاده از پوشه
                            است ، در صورت عدم پذیرش و تمایل به حذف حساب کاربری
                            به پشتیبانی پوشه ایمیل ارسال کنید.
                        </p>
                        <FlatButton
                            className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                            rippleColor={blue900}
                            label="متن را می‌پذیرم"
                            onClick={onAccept}
                        />
                    </>
                }
                body={
                    <div className="modal--terms">
                        <a
                            className="modal--terms__header"
                            ref={this.termsElem}
                        >
                            شرایط استفاده از پوشه
                        </a>

                        <ReactMarkdown
                            className={`modal--terms__terms-container`}
                            source={termsMd}
                        />

                        <br />
                        <hr />
                        <br />

                        <a
                            className="modal--terms__header"
                            ref={this.privacyElem}
                        >
                            شرایط حفظ حریم خصوصی
                        </a>

                        <ReactMarkdown
                            className={`modal--terms__privacy-container`}
                            source={privacyMd}
                        />
                    </div>
                }
            />
        );
    }
}

export default withErrorCatcher(TermsAndPrivacyModal);
