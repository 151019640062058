import React from "react";
import logo from "images/svg/logo.svg";
import { withErrorCatcher } from "@/components/utilities";
import { PUSHE_HOME_URL } from "@/constants";

const SidebarLogo = () => {
    return (
        <a
            href={PUSHE_HOME_URL}
            className="menu-logo"
            target="_blank"
            rel="noreferrer"
        >
            <img src={logo} alt="لوگو" />
        </a>
    );
};

SidebarLogo.defaultProps = {};
SidebarLogo.propTypes = {};

export default withErrorCatcher(SidebarLogo);
