/*
 ** Match your object with css media queries
 *
 * @param {Object} object
 * @param {string} size
 * @param {Object} options
 *
 * @return boolean
 */
function breakpoint(object, size, options = {}) {
    let isMatch, minWidth;
    isMatch = false;

    // breakpoint sizes match the ones in _breakpoint.scss file
    switch (size) {
        case "xs":
            minWidth = "576px";
            break;
        case "sm":
            minWidth = "768px";
            break;
        case "md":
            minWidth = "992px";
            break;
        case "lg":
            minWidth = "1200px";
            break;
        case "xlg":
            minWidth = "1920px";
            break;
        default:
            minWidth = "576px";
            break;
    }

    if (options.minWidth) minWidth = options.minWidth;

    try {
        isMatch = object.matchMedia("(min-width: " + minWidth + ")").matches;
    } catch (e) {
        // Ignore it!
    }

    return isMatch;
}

export default breakpoint;
