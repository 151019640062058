import BigTab from "@/components/notifications/notification/list/android/tabs/BigTab";
import SmallTab from "@/components/notifications/notification/list/android/tabs/SmallTab";
import DetailsTab from "@/components/notifications/notification/list/android/tabs/DetailsTab";
import isEmpty from "lodash/isEmpty";

export const tabs = [
    {
        label: "حالت کوچک",
        value: "1",
        display: () => true,
        component: SmallTab,
        wrapperClassName: "notif-card__body-wrapper",
        disabled: tabProps => {
            const notification = tabProps?.notification ?? {};
            const { data: { title } = {}, custom_content } = notification;

            return !isEmpty(custom_content) && !title;
        },
    },
    {
        label: "حالت بزرگ",
        value: "2",
        display: () => true,
        component: BigTab,
        wrapperClassName: "notif-card__body-wrapper",
        disabled: tabProps => {
            const notification = tabProps?.notification ?? {};
            const {
                data: {
                    image,
                    summary,
                    big_title,
                    big_content,
                    notif_icon,
                    ticker,
                } = {},
                custom_content,
            } = notification;

            return (
                !isEmpty(custom_content) &&
                !big_content &&
                !big_title &&
                !image &&
                !summary &&
                !notif_icon &&
                !ticker
            );
        },
    },
    {
        label: "جزئیات",
        value: "3",
        display: () => true,
        component: DetailsTab,
        wrapperClassName: "notif-card__body-wrapper",
        disabled: () => false,
    },
];
