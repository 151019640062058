import { isEmpty } from "lodash";
import {
    accept,
    collapseKeyProcess,
    doNotNeedProcess,
    isNotNull,
    isNotTestMode,
    processList,
    processObject,
    processTrueOrFalse,
    showCsvId,
    showCustomContent,
    showLimitedItems,
} from "@/components/utilities/data/normalizer";

export const emailSendNormalizer = {
    app_ids: accept,
    eta: [isNotTestMode, isNotNull],
    data: {
        isHTML: accept,
        subject: accept,
        html: (value, key, allValues) => {
            if (allValues.data["isHTML"]) allValues.data["content"] = value;
            return undefined;
        },
        content: accept, // api only accept `content` and not `html`
    },

    filters: accept,
};

export const makeEmailForwardDataNormalizer = {
    app_ids: undefined,
    data: {
        isHTML: accept,
        subject: accept,
        content: (value, key, allValues) => {
            if (allValues.data["isHTML"]) {
                allValues.data["html"] = value;
                return undefined;
            }
            return value;
        },
        html: accept, // This key only use in frontend & and api only accept `content`
    },
    filters: undefined,
};

export const androidNormalizeEmailCardData = {
    data: {
        __all__: v => (isEmpty(v) ? undefined : v),
        subject: undefined,
        content: undefined,
    },
    wrapper_id: doNotNeedProcess,
    custom_content: showCustomContent,
    rate_limit: undefined,
    time_to_live: undefined,
    priority: undefined,
    unique: processTrueOrFalse,
    filters: {
        _all__: v => (isEmpty(v) ? undefined : v),
        operator: processList,
        brand: processList,
        mobile_net: processList,
        state: processList,
        topics: processList,
        app_version: processList,
        device_id: showLimitedItems,
        pushe_id: showLimitedItems,
        custom_id: showLimitedItems,
        email: showLimitedItems,
        phone_number: showLimitedItems,
        android_id: showLimitedItems,
        ad_id: showLimitedItems,
        tags: processObject,
        imei: doNotNeedProcess,
        p_id: doNotNeedProcess, // Todo: Do we really have this?
    },
    topics: doNotNeedProcess,
    max_recipients: doNotNeedProcess,
    collapse_key: collapseKeyProcess,
    csv_id: showCsvId,
    segment_ids: undefined,
    segment_names: showLimitedItems,
};

export const automationToApiNormalizer = {
    name: accept,
    automation_id: accept,
    segment_id: accept,
    notification: {
        data: {
            ...emailSendNormalizer.data,
        },
    },
};

export const automationToFormNormalizer = {
    notificationTypes: accept,
    fullContentType: accept,
    name: accept,
    automation_id: accept,
    segment_id: accept,
    notification: {
        data: {
            ...makeEmailForwardDataNormalizer.data,
        },
    },
};
