/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import CardBack from "@/components/notifications/notification/list/common/notification-cards/CardBack";
import { fetchStatistics } from "@/actions/notifications";
import { useDispatch } from "react-redux";

const NotificationStatistics = props => {
    const { onClose, wrapperId } = props;

    const dispatch = useDispatch();
    const [isLoading, toggleIsLoading] = useState(false);
    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        toggleIsLoading(true);

        fetchStatistics(wrapperId, data => {
            setStatistics(data);
            toggleIsLoading(false);
        })(dispatch);
    }, []);
    return (
        <CardBack
            notifications={statistics}
            isLoading={isLoading}
            onClose={() => {
                setStatistics([]);
                onClose();
            }}
        />
    );
};

export default withErrorCatcher(NotificationStatistics);
