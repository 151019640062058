/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { SelectBox } from "@/components/utilities";
import { HTMLEditorField } from "@/components/utilities/editor/HTMLEditor";
import { Field } from "redux-form";
import { CONTENT_TYPE } from "@/components/email/constants";
import ReduxFormRichEditor from "@/components/utilities/RichTextEditor/ReduxFormRichEditor";

const EmailMultiFormatContent = props => {
    const { change, initialValues, formValues } = props;

    const [type, setType] = useState(
        initialValues?.data?.isHTML || formValues?.data?.isHTML
            ? CONTENT_TYPE.HTML
            : CONTENT_TYPE.SIMPLE,
    );

    useEffect(() => {
        if (type === CONTENT_TYPE.SIMPLE) {
            change("data.isHTML", false);
            change("data.html", "");
        } else if (type === CONTENT_TYPE.HTML) {
            change("data.isHTML", true);
            change("data.content", "");
        }
    }, [type]);

    return (
        <>
            <div className="step-block__column">
                <div className="step-block__row">
                    <SelectBox
                        value={type}
                        onChange={setType}
                        label={"انتخاب نوع متن*"}
                        options={[
                            { label: "وارد کردن به صورت متنی", value: "1" },
                            { label: "وارد کردن به صورت html", value: "2" },
                        ]}
                    />
                </div>

                <div className="step-block__row">
                    {type === CONTENT_TYPE.SIMPLE && (
                        <Field
                            fullWidth
                            multiline
                            rows={4}
                            name="content"
                            label="متن ایمیل"
                            format={val => {
                                return val?.replace(/<br\/>/g, "\n");
                            }}
                            normalize={val => {
                                return val?.replace(/\n/g, "<br/>");
                            }}
                            component={ReduxFormRichEditor}
                        />
                    )}

                    {type === CONTENT_TYPE.HTML && (
                        <Field
                            fullWidth
                            name="html"
                            component={HTMLEditorField}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default EmailMultiFormatContent;
