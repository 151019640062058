/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MODAL_TYPES, NOTIFICATION_MODE, PLATFORMS_ID } from "@/actions/_types";
import { closeModal, openModal, toggleDemo } from "@/actions/app";
import {
    createNotification,
    retrieveNotification,
} from "@/actions/notifications";
import {
    deleteApplication,
    exportCSV,
    fetchApplications,
    updateApplication,
} from "@/actions/applications";
import { fetchDashboardData } from "@/actions/dashboard";
import { emptyInprog, saveApplicationIdInProgress } from "@/actions/inprog";
import MobileAppTable from "@/components/application/components/tables/mobile-table.jsx";
import Loading from "@/components/utils/loading";
import { withErrorCatcher } from "@/components/utilities";
import ApplicationsChart from "@/components/application/components/applicationsChart";
import ApplicationModals from "application/components/modals/applicationModals";
import CreateAppBar from "application/components/CreateAppBar";
import ApplicationPagePlaceholder from "application/list/ApplicationPagePlaceholder";

class Applications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onLoading: true,
        };
    }

    componentDidMount() {
        this.handleFetchApplications();
        this.fetchDashboardData();
    }

    componentDidUpdate(prevProps) {
        const { platform, app_id } = this.props;

        if (prevProps.platform !== platform) {
            this.handleFetchApplications();
        }

        if (prevProps.app_id !== app_id) {
            this.fetchDashboardData();
        }
    }

    handleDeleteApplication() {
        const { deleteApplication, currentApplication, platform } = this.props;

        const { app_id } = currentApplication;

        deleteApplication({ app_id, platform });
    }

    /*
     ** Fetch distributions data
     *  for application and pushe versions chart
     */
    fetchDashboardData() {
        const { fetchDashboardData } = this.props;
        fetchDashboardData();
    }

    handleExportCSV() {
        const { app_id } = this.props.currentApplication;
        this.props.exportCSV({ app_id });
    }

    handleFetchApplications() {
        const callback = () => {
            this.setState({ onLoading: false });
        };

        this.setState(
            () => ({ onLoading: true }),
            () => {
                this.props.fetchApplications(
                    { platform: this.props.platform },
                    callback,
                    callback,
                );
            },
        );
    }

    handleUpdateApplication(values) {
        const { app_id } = this.props.currentApplication;
        const { platform } = this.props;

        this.props.updateApplication({ app_id, platform, ...values });
    }

    render() {
        const { isWeb, updateApplication, applications, history } = this.props;
        const { onLoading } = this.state;

        // let Component = isWeb ? WebAppTable : MobileAppTable;
        let Component = MobileAppTable;

        if (applications.length === 0 && onLoading) {
            return (
                <div className="table-loading">
                    <Loading className="loading-circle--blue-gray loading-circle-xlarge" />
                </div>
            );
        } else if (applications.length > 0) {
            return (
                <div>
                    {!isWeb && (
                        <div>
                            <ApplicationsChart />
                            <CreateAppBar
                                history={history}
                                fetchApplication={this.handleFetchApplications.bind(
                                    this,
                                )}
                            />
                        </div>
                    )}

                    <div className="main-block">
                        <Component
                            {...this.props}
                            data={
                                Array.isArray(this.props.data)
                                    ? this.props.data
                                    : []
                            }
                            onOpenAppTokenModal={app => {
                                this.props.saveApplicationIdInProgress(app);
                                this.props.openModal(
                                    MODAL_TYPES.APPLICATION.APP_TOKEN,
                                );
                            }}
                            onDeleteApplication={app => {
                                this.props.saveApplicationIdInProgress(app);
                                this.props.openModal(
                                    MODAL_TYPES.APPLICATION.DELETE,
                                );
                            }}
                            onUpdateApplication={app => {
                                this.props.saveApplicationIdInProgress(app);

                                this.props.openModal(
                                    MODAL_TYPES.APPLICATION.UPDATE_RENAME,
                                );
                            }}
                            onSelectingFramework={app => {
                                this.props.saveApplicationIdInProgress(app);
                                this.props.openModal(
                                    MODAL_TYPES.APPLICATION.SELECTING_FRAMEWORK,
                                );
                            }}
                            onGetInstallationHistory={app => {
                                this.props.saveApplicationIdInProgress(app);
                                this.props.openModal(
                                    MODAL_TYPES.APPLICATION
                                        .INSTALLATION_HISTORY,
                                );
                            }}
                            welcomeNotification={app => {
                                const {
                                    welcome_template_v2: notificationId,
                                    app_id: appId,
                                } = app;
                                let platform = PLATFORMS_ID[app.type];
                                let welcomeUrl;

                                if (notificationId) {
                                    welcomeUrl = `/notification/${platform}/welcome/${appId}/${notificationId}`;
                                } else {
                                    welcomeUrl = `/notification/${platform}/welcome/${appId}`;
                                }

                                history.push(welcomeUrl);
                            }}
                            activateWelcomeNotification={({ app_id }) => {
                                const { platform } = this.props;
                                updateApplication({
                                    app_id,
                                    platform,
                                    welcome_active: true,
                                });
                            }}
                            deactivateWelcomeNotification={({ app_id }) => {
                                const { platform } = this.props;
                                updateApplication({
                                    app_id,
                                    platform,
                                    welcome_active: false,
                                });
                            }}
                            updateNotification={({
                                type,
                                app_id,
                                update_template_v2,
                            }) => {
                                let updateUrl;
                                if (update_template_v2) {
                                    updateUrl = `/notification/update/${PLATFORMS_ID[type]}/${app_id}/${update_template_v2}`;
                                } else {
                                    updateUrl = `/notification/update/${PLATFORMS_ID[type]}/${app_id}`;
                                }
                                history.push(updateUrl);
                            }}
                            onCreateWelcomeNotification={parameters => {
                                const app_instance = parameters.instance;
                                const welcome_wrapper_id =
                                    app_instance.welcome_template_v2;
                                const update_wrapper_id =
                                    app_instance.update_template_v2;
                                this.props.saveApplicationIdInProgress(
                                    app_instance,
                                );

                                switch (parameters.mode) {
                                    case NOTIFICATION_MODE.WELCOME:
                                        if (welcome_wrapper_id) {
                                            this.props.retrieveNotification(
                                                welcome_wrapper_id,
                                                () => {
                                                    this.props.openModal(
                                                        MODAL_TYPES.NOTIFICATION
                                                            .NEW,
                                                        {
                                                            mode: parameters.mode,
                                                        },
                                                    );
                                                },
                                            );
                                        } else {
                                            this.props.openModal(
                                                MODAL_TYPES.NOTIFICATION.NEW,
                                                {
                                                    mode: parameters.mode,
                                                },
                                            );
                                            this.props.saveApplicationIdInProgress(
                                                app_instance,
                                            );
                                        }
                                        break;
                                    case NOTIFICATION_MODE.UPDATE:
                                        if (update_wrapper_id) {
                                            this.props.retrieveNotification(
                                                update_wrapper_id,
                                                () => {
                                                    this.props.openModal(
                                                        MODAL_TYPES.NOTIFICATION
                                                            .NEW,
                                                        {
                                                            mode: parameters.mode,
                                                        },
                                                    );
                                                },
                                            );
                                        } else {
                                            this.props.openModal(
                                                MODAL_TYPES.NOTIFICATION.NEW,
                                                {
                                                    mode: parameters.mode,
                                                },
                                            );
                                            this.props.saveApplicationIdInProgress(
                                                app_instance,
                                            );
                                        }
                                        break;
                                }
                            }}
                        />
                    </div>
                    <ApplicationModals
                        closeModal={this.props.closeModal}
                        activeModal={this.props.activeModal}
                        userProfile={this.props.userProfile}
                        applications={this.props.applications}
                        handleExportCSV={this.handleExportCSV.bind(this)}
                        currentApplication={this.props.currentApplication}
                        responseErrorInProg={this.props.responseErrorInProg}
                        handleDeleteApplication={this.handleDeleteApplication.bind(
                            this,
                        )}
                        handleUpdateApplication={this.handleUpdateApplication.bind(
                            this,
                        )}
                    />
                </div>
            );
        } else {
            return (
                <ApplicationPagePlaceholder
                    history={history}
                    platform={this.props.platform}
                />
            );
        }
    }
}

function mapStateToProps(state) {
    let app_id = state.app.appId;
    if (!app_id) app_id = "all";

    const { platform, openModal, demo } = state.app;
    const { profile } = state.auth;
    const { dashboard = {} } = state[platform];
    const { error } = state.inprog;
    const distributions = dashboard[app_id]
        ? dashboard[app_id].distributions
        : {};
    const { app_version: versions = {}, pushe_version: pusheVersions = {} } =
        distributions;
    const data = dashboard[app_id] ? dashboard[app_id].historical : [];
    const { plan = {} } = profile || {}; // ** Plan Could be null sometimes
    const relatedApps = (dashboard[app_id] || {})["related_apps"] || [];

    return {
        activeModal: openModal,
        userProfile: profile,
        demo,
        data,
        plan,
        app_id,
        versions,
        platform,
        pusheVersions,
        isWeb: platform === "web",
        responseErrorInProg: error,
        currentApplication: state.inprog.application,
        applications: state[platform].applications,
        relatedApps,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchApplications,
            openModal,
            deleteApplication,
            saveApplicationIdInProgress,
            retrieveNotification,
            exportCSV,
            closeModal,
            updateApplication,
            createNotification,
            toggleDemo,
            emptyInprog,
            fetchDashboardData,
        },
        dispatch,
    );
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(Applications),
);
