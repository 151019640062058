import { useState } from "react";
import { defaultTo as dt } from "lodash";

const useStepWizard = ({ steps = [], values }) => {
    const stepsLen = steps && Array.isArray(steps) ? steps.length : 0;

    const _shouldSkipStep = (nextPosition, values) => {
        const isEnabledFunc = dt(steps[nextPosition], {}).isEnabled;
        // eslint-disable-next-line no-extra-boolean-cast
        const isEnabled = !!isEnabledFunc ? isEnabledFunc(values) : true;
        return !isEnabled && nextPosition > 0;
    };

    const [currentStep, updateCurrentStep] = useState({
        position: 0,
        isFinale: false,
    });

    const onNextClick = () => {
        const payload = { ...currentStep };

        if (payload.position < stepsLen - 1) {
            do {
                payload.position = payload.position + 1;
            } while (_shouldSkipStep(payload.position, values));

            if (payload.position >= stepsLen) {
                payload.position = payload.position - 1;
                payload.isFinale = true;
            }
        } else {
            payload.isFinale = true;
        }

        updateCurrentStep(payload);
    };

    const onPrevClick = () => {
        const payload = { ...currentStep };

        if (payload.isFinale) {
            payload.isFinale = false;
            if (_shouldSkipStep(payload.position, values)) {
                payload.position = payload.position - 1;
            }
        } else if (payload.position > 0) {
            do {
                payload.position = payload.position - 1;
            } while (_shouldSkipStep(payload.position, values));
        }

        updateCurrentStep(payload);
    };

    const jumpToFinale = () => {
        const payload = { ...currentStep };

        payload.isFinale = true;

        updateCurrentStep(payload);
    };

    return [currentStep, { onNextClick, onPrevClick, jumpToFinale }];
};

export default useStepWizard;
