
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Manifest = makeShortcode("Manifest");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`مانیفست`}</h1>
    <p>{`متن زیر را کپی کرده و در تگ
`}<inlineCode parentName="p">{`application`}</inlineCode>{`
مانیفست خود که در آدرس زیر قابل دسترس می‌باشد وارد کنید.
`}<a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/react-native/intro/#%D8%A7%D8%B6%D8%A7%D9%81%D9%87%DA%A9%D8%B1%D8%AF%D9%86-%D9%85%D8%AD%D8%AA%D9%88%D8%A7%DB%8C-%D9%85%D8%A7%D9%86%DB%8C%D9%81%D8%B3%D8%AA"
      }}>{`اطلاعات بیشتر`}</a></p>
    <p><inlineCode parentName="p">{`android/src/main/AndroidManifest.xml`}</inlineCode></p>
    <Manifest {...props} mdxType="Manifest">
      <pre><code parentName="pre" {...{
          "className": "language-xml"
        }}>{`<meta-data
    android:name="pushe_token"
    android:value="{{manifest}}" />
`}</code></pre>
    </Manifest>
    <h1>{`دسترسی‌ها`}</h1>
    <p>{`در صورتی که از امکانات ویژه‌ی پوشه استفاده می‌کنید بایستی دسترسی‌های لازم را نیز به برنامه اضافه کنید. در غیر اینصورت این دسترسی‌ها اجباری نیستند.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<!-- ارسال اعلان بر اساس اپراتور -->
<uses-permission android:name="android.permission.ACCESS_NETWORK_STATE"/>

<!-- آمار موقعیت کاربران و ارسال اعلان بر اساس موقعیت -->
<uses-permission android:name="android.permission.ACCESS_COARSE_LOCATION"/>
<!-- ارسال اعلان جغرافیایی یا جئوفنس -->
<uses-permission android:name="android.permission.ACCESS_FINE_LOCATION"/>

<!-- Android 10 or higher -->
<uses-permission android:name="android.permission.ACCESS_BACKGROUND_LOCATION"/>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`نکته:`}</em>{`
در صورتی که مایل نیستید از امکانات موقعیت محور پوشه استفاده کنید می‌توانید
دسترسی بالا را در پروژه قرار ندهید.`}</p>
    </blockquote>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;