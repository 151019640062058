import {
    FILTER_MODE,
    FULL_CONTENT_TYPE_MODE,
    NOTIFICATION_TYPE,
} from "@/components/notifications/notification/constants";
import { isEmpty, values } from "lodash";
import * as Sentry from "@sentry/browser";
import { NOTIFICATION_MODE } from "@/actions/_types";
import {
    collapseKeyList,
    iconList,
    ledOptions,
    priorities,
    transferKeysIntoPersion,
} from "@/constants";
import {
    AndroidActions,
    findAndParseAction,
    WEB_ACTIONS,
} from "@/components/notifications/notification/actions";
import Highlight from "react-highlight/lib/optimized";
import React from "react";
import util from "@/utils";

export const accept = v => v;
export const reject = _ => null;
export const isShowNotification = (value, key, allValues) =>
    allValues.notificationTypes[NOTIFICATION_TYPE.NOTIF] ? value : undefined;
export const isJsonNotification = (value, key, allValues) =>
    allValues.notificationTypes[NOTIFICATION_TYPE.CUSTOM_CONTENT]
        ? value
        : undefined;
export const jsonifyString = (value, key, allValues) => {
    try {
        if (value) {
            if (typeof value === "string") {
                return JSON.parse(value);
            }
            if (typeof value === "object" && !isEmpty(value)) {
                return value;
            }
        }
        return undefined;
    } catch (e) {
        Sentry.captureEvent({
            message: `jsonify custom content has error-> ${e}`,
            level: Sentry.Severity.Info,
            extra: {
                value: value,
                allValue: allValues,
            },
        });
        return undefined;
    }
};
export const isNotNull = value => (value != null ? value : undefined);
export const isNonZero = value => (value !== 0 ? value : undefined);
export const isTestMode = (value, key, allValues) =>
    allValues.isTestMode ? value : undefined;
export const isNotTestMode = (value, key, allValues) =>
    allValues.isTestMode ? undefined : value;
export const isBigImageMode = (value, key, allValues) =>
    allValues.fullContentType === FULL_CONTENT_TYPE_MODE.IMAGE
        ? value
        : undefined;
export const isBigContentMode = (value, key, allValues) =>
    allValues.fullContentType === FULL_CONTENT_TYPE_MODE.CONTENT
        ? value
        : undefined;
export const isTopicMode = (value, key, allValues) =>
    allValues.filterType === FILTER_MODE.TOPICS ? value : undefined;
export const isCsvMode = (value, key, allValues) =>
    allValues.filterType === FILTER_MODE.CSV ? value : undefined;
export const isSegmentMode = (value, key, allValues) =>
    allValues.filterType === FILTER_MODE.SEGMENTS ? value : undefined;
export const isFilterMode = (value, key, allValues) =>
    allValues.filterType === FILTER_MODE.FILTERS ? value : undefined;
export const isObjectEmpty = value => (!isEmpty(value) ? value : undefined);
export const addOrderingToButtons = buttons =>
    (buttons || []).map((button, btn_order) => ({ ...button, btn_order }));
export const useDefaultBigTitle = (value, key, allValues) =>
    value ? value : allValues.data.title;
export const useDefaultBigContent = (value, key, allValues) =>
    value ? value : allValues.data.content;
export const isWelcomeMode = (value, key, allValues) =>
    allValues.notificationMode === NOTIFICATION_MODE.WELCOME
        ? value
        : undefined;
export const isUpdateMode = (value, key, allValues) =>
    allValues.notificationMode === NOTIFICATION_MODE.UPDATE ? value : undefined;
export const isNormalMode = (value, key, allValues) =>
    allValues.notificationMode === NOTIFICATION_MODE.NORMAL ? value : undefined;
export const normalizeCsv = value => value?.["id"];

export const normalizeFilters = (value, key, allValues) => {
    // if filter is instance_id accept it (in every mode)
    // otherwise filters are accepted  only in normal mode
    return Object.entries(value || {}).reduce((acc, curr) => {
        if (
            curr[0] === "instance_id" ||
            (allValues.notificationMode === NOTIFICATION_MODE.NORMAL &&
                !isEmpty(curr[1]))
        ) {
            acc[curr[0]] = curr[1];
        }
        return acc;
    }, {});
};
export const normalizeFiltersWithDeviceId = (value, key, allValues) => {
    // if filter is instance_id accept it (in every mode)
    // otherwise filters are accepted  only in normal mode
    return Object.entries(value || {}).reduce((acc, curr) => {
        if (
            curr[0] === "device_id" ||
            (allValues.notificationMode === NOTIFICATION_MODE.NORMAL &&
                !isEmpty(curr[1]))
        ) {
            acc[curr[0]] = curr[1];
        }
        return acc;
    }, {});
};

export const noneEmpty = value => (isEmpty(value) ? undefined : value);

/*
 *  normalize details mode in notification cards
 */

export const collapseKeyProcess = (value, key) =>
    value ? (
        <li>
            <span>{transferKeysIntoPersion[key]}</span>
            {collapseKeyList[value] || value}
        </li>
    ) : undefined;

export const processTrueOrFalse = (value, key) => {
    let v = "خیر";
    if (value) {
        v = "بله";
    }
    return (
        <li>
            <span>{transferKeysIntoPersion[key]}</span>
            {v}
        </li>
    );
};

export const processDateTime = (value, key) => {
    if (!value) return undefined;

    let datetime = value;
    try {
        datetime = new Date(value);
    } catch (error) {
        // ignore it!
    }

    return (
        <li>
            <span>{transferKeysIntoPersion[key]}:</span>&nbsp;
            {util._convertToLocalTimezone(datetime)}
        </li>
    );
};

export const doNotNeedProcess = (value, key) =>
    value ? (
        <li>
            <span>{transferKeysIntoPersion[key]}</span>
            {value}
        </li>
    ) : undefined;

export const showLimitedItems = (value, key) => {
    if (value?.length > 0) {
        let renderedValues = value;

        if (Array.isArray(value)) {
            if (value.length > 5)
                renderedValues = values(value).slice(0, 5).join(", ") + " ...";
            else {
                renderedValues = values(value).join(", ");
            }
        }

        return (
            <li>
                <span>{transferKeysIntoPersion[key]}</span>
                {renderedValues}
            </li>
        );
    }

    return undefined;
};

export const concatUrl = (value, key, allValues) => {
    const {
        utm_source = null,
        utm_campaign = null,
        utm_medium = null,
    } = allValues?.data;
    if (utm_campaign || utm_source || utm_medium) {
        let url = new URL(value.url);
        let params = new URLSearchParams(url.search);
        params.append("utm_source", utm_source);
        params.append("utm_campaign", utm_campaign);
        params.append("utm_medium", utm_medium);

        return { ...value, url: url.href + "?" + params.toString() };
    }
    return value;
};
export const showIcons = (value, key) => {
    if (value) {
        let icon = "add_box";
        let iconValue = iconList.find(item => item.title === value);
        if (iconValue) {
            icon = iconValue.title;
        }
        return (
            <li style={{ display: "flex", alignItems: "center" }}>
                <span>{transferKeysIntoPersion[key]}</span>
                <span className="material-icons">{icon}</span>
            </li>
        );
    }
    return undefined;
};

export const actionProcess = (value, key, allValues) => {
    if (value) {
        try {
            const actionData = findAndParseAction(AndroidActions, value);
            let reactElement = "اکشن مورد نظر یافت نشد.";

            if (actionData) {
                const actionName = actionData.action.text;
                reactElement = <span>{actionName}</span>;

                if (value.url && value.url !== "") {
                    reactElement = <a href={value.url}>{actionName}</a>;
                } else if (value.action_data) {
                    reactElement = (
                        <ul>
                            <li>
                                <span>{`${actionName}:`}</span>
                                <span>{value.action_data}</span>
                            </li>
                        </ul>
                    );
                }
            }
            return (
                <li>
                    <span>{transferKeysIntoPersion[key]}</span>
                    {reactElement}
                </li>
            );
        } catch (e) {
            Sentry.captureEvent({
                message: `use invalid action params -> ${e}`,
                level: Sentry.Severity.Info,
                value: value,
                allValue: allValues,
            });
            return (
                <li>
                    <span>{transferKeysIntoPersion[key]}</span>
                    {"نمایش اکشن با خطا مواجه شده است."}
                </li>
            );
        }
    }
    return undefined;
};

export const webActionProcess = (value, key, allValues) => {
    if (value) {
        try {
            const actionData = findAndParseAction(WEB_ACTIONS, value);
            let reactElement = "اکشن مورد نظر یافت نشد.";

            if (actionData) {
                const actionName = actionData.action.text;
                reactElement = <span>{actionName}</span>;

                if (value.url && value.url !== "") {
                    reactElement = <a href={value.url}>{actionName}</a>;
                } else if (value.action_data) {
                    reactElement = (
                        <ul>
                            <li>
                                <span>{`${actionName}:`}</span>
                                <span>{value.action_data}</span>
                            </li>
                        </ul>
                    );
                }
            }
            return (
                <li>
                    <span>{transferKeysIntoPersion[key]}</span>
                    {reactElement}
                </li>
            );
        } catch (e) {
            Sentry.captureEvent({
                message: `use invalid action params -> ${e}`,
                level: Sentry.Severity.Info,
                value: value,
                allValue: allValues,
            });
            return (
                <li>
                    <span>{transferKeysIntoPersion[key]}</span>
                    {"نمایش اکشن با خطا مواجه شده است."}
                </li>
            );
        }
    }
    return undefined;
};

export const showLEDColor = (value, key) => {
    if (value) {
        let colorValue = "#FF0000";

        const colorData = ledOptions.find(i => i.code === value);

        if (colorData) colorValue = colorData.value;
        return (
            <li style={{ display: "flex" }}>
                <span>{transferKeysIntoPersion[key]}</span>
                <li
                    className="radio-group radio-group--color"
                    style={{ marginRight: "10px" }}
                >
                    <span style={{ color: colorValue }} />
                </li>
            </li>
        );
    }

    return undefined;
};

export const showCustomContent = (value, key, allValues) => {
    const hasCustomContent = Object.keys(value || {}).length !== 0;

    if (hasCustomContent) {
        let stringifyValue = "";
        try {
            if (typeof value === "string") {
                stringifyValue = value;
            } else {
                stringifyValue = JSON.stringify(value);
            }
        } catch (e) {
            Sentry.captureEvent({
                message: `stringify custom_content has problem error -> ${e}`,
                level: Sentry.Severity.Info,
                extra: {
                    value: value,
                    allValue: allValues,
                },
            });
        }
        return (
            <li className="card-notif__json">
                <span>{transferKeysIntoPersion[key]}</span>
                <Highlight className="json" style={{ flexGrow: 1 }}>
                    {`${stringifyValue}`}
                </Highlight>
            </li>
        );
    }
    return undefined;
};

export const timeToPersian = (value, key) => {
    if (value) {
        let ttl = value;
        let format = "";

        const timeList = [
            { key: "سال", value: 3600 * 24 * 4 * 7 * 12 },
            { key: "ماه", value: 3600 * 24 * 4 * 7 },
            { key: "روز", value: 3600 * 24 },
            { key: "ساعت", value: 3600 },
            { key: "دقیقه", value: 60 },
            { key: "ثانیه", value: 1 },
        ];

        let divisor = 0;
        let divider = 0;

        for (const item of timeList) {
            divider = item.value;
            divisor = parseInt(ttl / divider);

            if (divisor > 0) {
                ttl -= divisor * divider;
                format = `${format} ${divisor}${item.key}`;
            }
        }
        return (
            <li>
                <span>{transferKeysIntoPersion[key]}</span>
                {format}
            </li>
        );
    }
    return undefined;
};

export const showPriorities = (value, key) => {
    const priority = priorities.find(item => item.key === value);

    return (
        <li>
            <span>{transferKeysIntoPersion[key]}</span>
            {priority ? priority.name : "اولویت انتخاب شده درست نمی‌باشد."}
        </li>
    );
};

export const soundUrlProcess = (value, key) => {
    if (value) {
        return (
            <li style={{ display: "flex" }}>
                <span>{transferKeysIntoPersion[key]}</span>
                <li
                    className="radio-group radio-group--sound"
                    style={{ marginRight: "10px" }}
                >
                    <audio id="audio">
                        <source src={value} type="audio/mp3" />
                    </audio>
                    <span
                        onClick={() => document.getElementById("audio").play()}
                    >
                        <input
                            className="active"
                            type="radio"
                            name="sound_url"
                            value={value}
                        />
                    </span>
                </li>
            </li>
        );
    }
    return undefined;
};

export const processObject = (value, key) => {
    if (!value) return undefined;
    return (
        <div>
            <span
                style={{
                    color: "#000000",
                    paddingLeft: "10px",
                    fontSize: "12px",
                }}
            >
                {transferKeysIntoPersion[key]}
            </span>
            <Highlight className="json">{JSON.stringify(value)}</Highlight>
        </div>
    );
};

export const processList = (value, key) => {
    if (value && Array.isArray(value) && value.length > 0) {
        return (
            <li>
                <span>{transferKeysIntoPersion[key]}</span>
                <p>{value.map(val => ` "${val}"`)}</p>
            </li>
        );
    }
    return undefined;
};

export const showCsvId = value => {
    if (value) {
        return (
            <li>
                <span> فیلتر فایل:</span>
                <span> {value} </span>
            </li>
        );
    }
    return undefined;
};

export const makeEmailForwardDataNormalizer = {
    app_ids: undefined,
    data: {
        isHTML: accept,
        subject: accept,
        content: (value, key, allValues) => {
            if (allValues.data["isHTML"]) {
                allValues.data["html"] = value;
                return undefined;
            }
            return value;
        },
        html: accept, // This key only use in frontend & and api only accept `content`
    },
    filters: undefined,
};
