/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ReduxSimpleTextField, withErrorCatcher } from "@/components/utilities";
import css from "application/create/styles/createApp.module";
import { Field } from "redux-form";
import { retrieveCredentials } from "@/actions/credentials";
import { useDispatch } from "react-redux";

const ApplicationCredentials = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        retrieveCredentials()(dispatch);
    }, []);

    return (
        <div className={css.createAppCredentials}>
            <div className={css.createAppHeading}>ایجاد اپلیکیشن</div>

            <div className={css.createAppBody}>
                <Field
                    name={"credentials.client_key"}
                    label={"*FCM Sender ID"}
                    component={ReduxSimpleTextField}
                />
                <Field
                    name={"credentials.server_key"}
                    label={"*FCM Server Key"}
                    component={ReduxSimpleTextField}
                />
            </div>
        </div>
    );
};

export default withErrorCatcher(ApplicationCredentials);
