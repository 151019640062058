import React from "react";
import Modal from "@/components/utilities/commons/modal";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { Ascending, Descending } from "@/components/utils/lib/svgicons";
import Colors from "@/components/utils/lib/colors";
import defaultIcon from "images/app-icon/app-blank.png";
import ReactTooltip from "react-tooltip";
import { humanizeToEnglish } from "@/components/utilities";
import { CircleLoader } from "@/components/utilities";
import { withErrorCatcher } from "@/components/utilities";

const uuid = require("uuid/v4");

class CardBack extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortBy: 1,
        };

        this.sortBy = {
            1: "ACCUREATE_RECIPIENT_DESC",
            2: "ACCUREATE_RECIPIENT_ASC",
            3: "CLICKED_DESC",
            4: "CLICKED_ASC",
        };

        this.styles = {
            selectField: {
                item: {
                    color: Colors.blue,
                },
            },
        };
    }

    renderFilter() {
        let Asc = (
            <Ascending
                style={{
                    width: "16px",
                    height: "16px",
                    marginTop: "5px",
                    right: "30px",
                }}
            />
        );
        let Desc = (
            <Descending
                style={{
                    width: "16px",
                    height: "16px",
                    marginTop: "5px",
                    right: "30px",
                }}
            />
        );
        const { sortBy } = this.state;

        return (
            <div
                className="row"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    height: "60px",
                }}
            >
                <div
                    className="col-xs-12 col-sm-3"
                    style={{ fontSize: "1rem", paddingTop: "12px" }}
                >
                    ترتیب بر اساس:
                </div>
                <div className="col-xs-12 col-sm-9">
                    <SelectField
                        className="mui-select"
                        value={sortBy}
                        onChange={(event, index, value) => {
                            this.setState({ sortBy: value });
                        }}
                        selectedMenuItemStyle={this.styles.selectField.item}
                        fullWidth={true}
                    >
                        <MenuItem
                            value={1}
                            leftIcon={Desc}
                            primaryText="بیشترین تعداد مخاطب"
                        />
                        <MenuItem
                            value={2}
                            leftIcon={Asc}
                            primaryText="کمترین تعداد مخاطب"
                        />
                        <MenuItem
                            value={3}
                            leftIcon={Desc}
                            primaryText="بیشترین تعداد کلیک"
                        />
                        <MenuItem
                            value={4}
                            leftIcon={Asc}
                            primaryText="کمترین تعداد کلیک"
                        />
                    </SelectField>
                </div>
            </div>
        );
    }

    renderStatistics() {
        let { isLoading } = this.props;
        if (isLoading) return <CircleLoader />;

        let notifications = this.handleStatistics();

        return notifications.map(item => {
            let { app_info = {}, statistics = {} } = item;
            let { icon_url = null, name = null } = app_info;
            let { accurate_recipients, clicked } = statistics;

            let hAccurateRecipients =
                accurate_recipients && humanizeToEnglish(accurate_recipients);
            let hClicked = clicked && humanizeToEnglish(clicked);

            return (
                <div key={uuid()} className="cardNotif-back-content">
                    <div className="cardNotif-back-row">
                        <div className="cardNotif-back-app">
                            <span
                                className="avatar"
                                style={{
                                    backgroundImage: `url(${
                                        icon_url ? icon_url : defaultIcon
                                    })`,
                                }}
                            />
                            <p>{name}</p>
                        </div>
                        <div className="cardNotif-back-statistics">
                            <div
                                className="single-statistics"
                                data-tip="تعداد دریافت"
                            >
                                <svg>
                                    <use xlinkHref="#svg_user_2" />
                                </svg>
                                <span className="text-fa">
                                    {hAccurateRecipients}
                                </span>
                            </div>
                            <div
                                className="single-statistics"
                                data-tip="تعداد کلیک"
                            >
                                <svg>
                                    <use xlinkHref="#svg_click" />
                                </svg>
                                <span className="text-fa">{hClicked}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    handleStatistics() {
        const { notifications = [] } = this.props;
        const { sortBy } = this.state;
        const sortFilter = this.sortBy[sortBy];
        let key;
        let filter;

        switch (sortFilter) {
            case "ACCUREATE_RECIPIENT_DESC":
                key = "accurate_recipients";
                filter = "desc";
                break;
            case "ACCUREATE_RECIPIENT_ASC":
                key = "accurate_recipients";
                filter = "asc";
                break;
            case "CLICKED_DESC":
                key = "clicked";
                filter = "desc";
                break;
            case "CLICKED_ASC":
                key = "clicked";
                filter = "asc";
                break;
        }

        return notifications.sort(function (a, b) {
            let aKey = a.statistics[key];
            let bKey = b.statistics[key];

            if (filter === "asc") return aKey - bKey;
            if (filter === "desc") return bKey - aKey;
        });
    }

    renderTotalApps() {
        const { notifications = [] } = this.props;
        const length = notifications.length;

        return (
            <div className="cardNotif-back-totalApps">
                <span className="color-green">
                    مجموع
                    <span className="number-of-apps">{length}</span>
                    اپلیکیشن
                </span>
            </div>
        );
    }

    render() {
        return (
            <Modal
                className="modal--ssm modal--status"
                onModalClose={this.props.onClose}
            >
                <div className="modal-header">آمار دریافت و کلیک</div>
                <div className="modal-body">
                    {this.renderFilter()}
                    <div className="cardNotif-back">
                        {this.renderStatistics()}
                    </div>
                    {this.renderTotalApps()}
                </div>
                <ReactTooltip className="tooltip" effect="solid" />
            </Modal>
        );
    }
}

export default withErrorCatcher(CardBack);
