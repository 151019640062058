import React, { Component } from "react";
import PropTypes from "prop-types";
import BaseModal from "@/components/utilities/modal/baseModal";
import { withErrorCatcher } from "@/components/utilities";
import { MODAL_THEME } from "@/actions/_types";

class RemoveApplicationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    render() {
        const { app, onClose } = this.props;

        const onSuccess = () => {
            onClose();
            this.setState({ error: null });
        };

        return (
            <BaseModal
                theme={MODAL_THEME.RED}
                title="حذف سایت"
                useDefaultFooter={true}
                desc="آیا مطمئن هستید؟"
                body={
                    <div>
                        تمامی اطلاعات سایت &nbsp;{app.name}&nbsp; حذف می شود و
                        دیگر نمی توانید آن ها را بازیابی کنید.
                    </div>
                }
                error={this.state.error}
                onClose={onClose}
                onAccept={() =>
                    this.props.onAccept(
                        { app_id: app.app_id },
                        onSuccess(),
                        error => this.setState({ error }),
                    )
                }
            />
        );
    }
}

RemoveApplicationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
};

export default withErrorCatcher(RemoveApplicationModal);
