import React from "react";
import { normalize } from "@/components/utils/normalize";
import { omit, values } from "lodash";
import { webNormalizeNotificationCardData } from "@/components/notifications/notification/normalize";
import { withErrorCatcher } from "@/components/utilities";
import "styles/modules/_mod-preview.scss";

const DetailsModeTab = props => {
    const { notification } = props;

    let modifiedData = normalize(
        notification,
        webNormalizeNotificationCardData,
    );
    modifiedData = { ...omit(modifiedData, ["data"]), ...modifiedData.data };

    return (
        <div className="preview-options">
            <ul>{modifiedData && values(modifiedData)}</ul>
        </div>
    );
};

export default withErrorCatcher(DetailsModeTab);
