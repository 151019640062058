import React from "react";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import Loading from "@/components/utils/loading";
import isEqual from "lodash/isEqual";
import { withErrorCatcher } from "@/components/utilities";

class InfoCard extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props.info, nextProps.info);
    }

    render() {
        return (
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="card-a card-a--detail">
                    <div className="card-a__img">
                        <svg>
                            <use href={`#svg_${this.props.icon}`} />
                        </svg>
                    </div>
                    <div className="card-a__text">
                        <div>
                            {this.props.info.map((infoItem, index) => (
                                <div className="card-a__info" key={index}>
                                    <var>
                                        {infoItem.value != null ? (
                                            infoItem.value
                                        ) : (
                                            <Loading />
                                        )}
                                    </var>
                                    <span>{infoItem.title}</span>
                                </div>
                            ))}
                        </div>
                        <FlatButton
                            className="mui-btn mui-btn--xs mui-btn--border mui-btn--gray-blue"
                            rippleColor={blue900}
                            label={this.props.buttonText}
                            onClick={this.props.onClick}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withErrorCatcher(InfoCard);
