import {
    axiosWithAuth,
    createUrl,
    getPlatformName,
    getPlatformNum,
} from "@/queries/constants";

export const SMS_KEYS = {
    LIST: () => `sms_list_${getPlatformName()}`,
    GET: () => `sms_${getPlatformName()}`,
    FAV: () => `sms_favorites_${getPlatformName()}`,
    AUTOMATION_LIST: () => `sms_automation_list_${getPlatformName()}`,
    AUTOMATION_GET: () => `sms_automation_${getPlatformName()}`,
};

export const fetchAllSMS = async (key, offset = 0) => {
    const url = await createUrl("messaging/sms/");
    const params = { params: { offset, platform: getPlatformNum() } };

    return (await axiosWithAuth().get(url, params))?.data?.results;
};

export const fetchSMS = async (key, wrapperId) => {
    const url = await createUrl(`messaging/sms/${wrapperId}`);

    return (await axiosWithAuth().get(url))?.data;
};

export const sendSMS = async data => {
    const url = await createUrl("messaging/sms/");

    return (await axiosWithAuth().post(url, data))?.data;
};

export const resendSMS = async wrapper_id => {
    const url = await createUrl("messaging/sms/resend/");
    const data = { wrapper_id };

    return (await axiosWithAuth().post(url, data))?.data;
};

export const removeSMS = async wrapperId => {
    const url = await createUrl(`messaging/sms/${wrapperId}`);

    await axiosWithAuth().delete(url);
    return wrapperId;
};

export const estimateRecipients = async data => {
    const url = await createUrl(`messaging/sms/estimate`);

    return (await axiosWithAuth().post(url, data))?.data?.count;
};

export const fetchSMSFavorites = async () => {
    const url = await createUrl(`sms/favorites/`);

    return (await axiosWithAuth().get(url))?.data?.results;
};

export const addNewSMSFavorite = async data => {
    const url = await createUrl(`sms/favorites/`);

    return (await axiosWithAuth().post(url, data))?.data;
};

export const removeSMSFavorite = async phone_number => {
    const url = await createUrl(`sms/favorites/${phone_number}`);

    await axiosWithAuth().delete(url);
    return phone_number;
};

export const sendTestSMS = async data => {
    const url = await createUrl(`messaging/sms/send_test_message/`);

    return (await axiosWithAuth().post(url, data))?.data;
};

/* ******************** Automation *************************** */

export const fetchAutomation = async (key, automationId) => {
    const url = await createUrl(`plus/automation/sms/${automationId}`);

    return (await axiosWithAuth().get(url))?.data;
};

export const fetchAutomations = async (key, offset = 0) => {
    const url = await createUrl("plus/automation/sms/");
    if (typeof offset === "boolean" && offset === false) offset = 0;
    const params = { params: { offset, platform: getPlatformNum() } };

    return (await axiosWithAuth().get(url, params))?.data?.results;
};

export const createAutomation = async data => {
    const url = await createUrl("plus/automation/sms/");

    return (await axiosWithAuth().post(url, data))?.data;
};

export const updateAutomation = async ({ automationId, data }) => {
    const url = await createUrl(`plus/automation/sms/${automationId}/`);

    return (await axiosWithAuth().patch(url, data))?.data;
};

export const removeAutomation = async automationId => {
    const url = await createUrl(`plus/automation/sms/${automationId}`);

    await axiosWithAuth().delete(url);
    return automationId;
};
