import React from "react";
import AceEditor from "react-ace";
import "brace/mode/html";
import "brace/theme/github";

export const HTMLEditorField = ({
    input: { value, onBlur, onChange, onFocus },
    meta: { submitFailed, error },
    height,
}) => (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <AceEditor
            value={value}
            onBlur={() => onBlur()}
            onFocus={() => onFocus()}
            onChange={onChange}
            mode="html"
            theme="chrome"
            style={{ height: height || "240px" }}
            editorProps={{ $blockScrolling: Infinity }}
        />
        {submitFailed && error && (
            <span style={{ fontSize: ".85rem", color: "rgb(244, 67, 54)" }}>
                {error}
            </span>
        )}
    </div>
);
