import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { keyBy, mapValues, some, values } from "lodash";

const uuid = require("uuid/v4");

export default class NotificationType extends React.Component {
    static propTypes = {
        notifTypes: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            data: mapValues(
                keyBy(props.notifTypes, n => n.name),
                n => !!(this.props.input.value || {})[n.name],
            ),
        };

        this.emitChange();
    }

    emitChange() {
        this.props.input.onChange(this.state.data);
    }

    toggleItem(item) {
        const newState = {
            ...this.state.data,
            [item.name]: !this.state.data[item.name],
        };

        if (some(values(newState), x => x)) {
            this.setState(
                {
                    data: newState,
                },
                this.emitChange.bind(this),
            );
        }
    }

    render() {
        const { notifTypes } = this.props;
        const { data } = this.state;

        return (
            <div>
                {notifTypes.map(item => (
                    <div className="col-sm-4 col-xs-12" key={uuid()}>
                        <div
                            className={classNames(
                                "card-b",
                                { "card-b--active": data[item.name] },
                                { "card-b--disable": item.enabled === false },
                            )}
                            tabIndex="0"
                            onClick={() => this.toggleItem(item)}
                        >
                            <svg>
                                <use href={`#${item.icon}`} />
                            </svg>
                            <p>{item.title}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
