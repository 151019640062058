import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "@/components/utils/PageLoader";
import { withErrorCatcher } from "@/components/utilities";
import { retrieveNotification } from "@/actions/notifications";
import { isEmpty } from "lodash";
import { AUTOMATION_WIZARD_MODE } from "@/components/email/constants";
import AndroidAutomationWizard from "@/components/email/automation/create/android/AndroidAutomationWizard";
import { useQuery } from "react-query";
import { EMAILS_KEYS, fetchAutomation } from "@/queries/email";
import { normalize } from "@/components/utils/normalize";
import { automationToFormNormalizer } from "@/components/email/normalize";
import { withEmailCredential } from "@/components/utilities/conf/withCredentialWrapper";
import WebAutomationWizard from "@/components/email/automation/create/web/WebAutomationWizard";

const AutomationEditPage = props => {
    const { match, history } = props;
    const dispatch = useDispatch();

    const { automationId } = match.params;

    const { data: automation, refetch: refetchAutomation } = useQuery(
        [EMAILS_KEYS.AUTOMATION_GET(), automationId],
        fetchAutomation,
        { cacheTime: 0, staleTime: Infinity },
    );

    const wrapper_id = automation?.wrapper_id;
    const notification = useSelector(
        s => s?.inprog?.notifications?.[wrapper_id] ?? {},
    );

    const platform = props?.match?.params?.platform;
    const WizardComponent = PLATFORMS[platform];

    return (
        <PageLoader
            perform={onFail => {
                if (automationId && isEmpty(automation))
                    refetchAutomation().then();
                if (wrapper_id && isEmpty(notification)) {
                    retrieveNotification(
                        { notificationId: wrapper_id },
                        null,
                        onFail,
                    )(dispatch);
                }
            }}
            history={history}
            isReady={!isEmpty(automation) && !isEmpty(notification)}
        >
            <WizardComponent
                history={history}
                wizardMode={AUTOMATION_WIZARD_MODE.EDIT}
                automation={
                    automation &&
                    normalize(automation, automationToFormNormalizer)
                }
                notification={
                    notification &&
                    normalize(
                        notification,
                        automationToFormNormalizer.notification,
                    )
                }
            />
        </PageLoader>
    );
};

const PLATFORMS = {
    android: AndroidAutomationWizard,
    web: WebAutomationWizard,
};

export default withErrorCatcher(withEmailCredential(AutomationEditPage));
