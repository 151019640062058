import React, { Component } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { withStyles } from "@material-ui/core";

const styles = () => ({
    root: {
        border: "1px solid #e7e7e7",
        direction: "ltr",

        overflowY: "auto",
        padding: 10,
    },
});
class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };
        this.props.onChange(
            draftToHtml(
                convertToRaw(this.state.editorState.getCurrentContent()),
            ),
        );
    }
    onEditorStateChange = editorState => {
        const { onChange, value } = this.props;

        const newValue = draftToHtml(
            convertToRaw(editorState.getCurrentContent()),
        );

        if (value !== newValue) {
            onChange(newValue);
        }

        this.setState({
            editorState,
        });
    };

    render() {
        const { classes } = this.props;
        const { editorState } = this.state;
        return (
            <div className={classes.root}>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorStyle={{ height: 250, overflowY: "auto" }}
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>
        );
    }
}
export default withStyles(styles)(RichTextEditor);
