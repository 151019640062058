
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Tabs mdxType="Tabs">
      <Tab id={0} label="Swift" mdxType="Tab">
        <p>{`بعد از ایجاد
‍`}<inlineCode parentName="p">{`NotificationServiceExtension`}</inlineCode>{`
در پروژه‌تان ، فایل
`}<inlineCode parentName="p">{`NotificationService.swift`}</inlineCode>{`
را مطابق کد زیر تغییر دهید.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`import UserNotifications
import Pushe

class NotificationService: UNNotificationServiceExtension {

    var contentHandler: ((UNNotificationContent) -> Void)?
    var bestAttemptContent: UNMutableNotificationContent?

    override func didReceive(_ request: UNNotificationRequest, withContentHandler contentHandler: @escaping (UNNotificationContent) -> Void) {
        self.contentHandler = contentHandler
        self.bestAttemptContent = (request.content.mutableCopy() as? UNMutableNotificationContent)

        if let bestAttemptContent = self.bestAttemptContent {
            Pushe.shared.didReceiveNotificationExtensionRequest(mutableContent: bestAttemptContent, contentHandler: contentHandler)
        }
    }

    override func serviceExtensionTimeWillExpire() {
        if let contentHandler = contentHandler, let bestAttemptContent =  bestAttemptContent {
            contentHandler(bestAttemptContent)
        }
    }
}
`}</code></pre>
      </Tab>
      <Tab id={1} label="Objective-C" mdxType="Tab">
        <p>{`بعد از ایجاد
`}<inlineCode parentName="p">{`NotificationServiceExtension`}</inlineCode>{`
در پروژه‌تان فایل
`}<inlineCode parentName="p">{`NotificationService.m`}</inlineCode>{`
را مطابق کد زیر تغییر دهید.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-objc"
          }}>{`#import "NotificationService.h"
#import <Pushe/Pushe.h>

@interface NotificationService ()

@property (nonatomic, strong) void (^contentHandler)(UNNotificationContent *contentToDeliver);
@property (nonatomic, strong) UNMutableNotificationContent *bestAttemptContent;

@end

@implementation NotificationService

- (void)didReceiveNotificationRequest:(UNNotificationRequest *)request withContentHandler:(void (^)(UNNotificationContent * _Nonnull))contentHandler {
    self.contentHandler = contentHandler;
    self.bestAttemptContent = [request.content mutableCopy];

    [PusheApp didReceiveNotificationExtensionRequest:self.receivedRequest
                      withMutableNotificationContent:self.bestAttemptContent];

    self.contentHandler(self.bestAttemptContent);
}

- (void)serviceExtensionTimeWillExpire {
    self.contentHandler(self.bestAttemptContent);
}

@end
`}</code></pre>
      </Tab>
    </Tabs>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;