import React from "react";
import { formValueSelector, reduxForm, FormSection } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { withRouter } from "react-router-dom";
import { uploadFile } from "@/actions/app";
import { withErrorCatcher } from "@/components/utilities";
import NotificationDisplaySettingsComponent from "@/components/utilities/commons/wizard/components/NotificationDisplaySettingsComponent";

const AndroidGeofenceStep4 = ({ data, change }) => {
    return (
        <form className="notif-step4">
            <FormSection name="notification">
                <NotificationDisplaySettingsComponent
                    data={data}
                    change={change}
                />
            </FormSection>
        </form>
    );
};

function mapStateToProps(state) {
    const { notification = {} } = formValueSelector("geofence_form")(
        state,
        "notification",
        "app_id",
    );
    const { data = {} } = notification;
    return {
        data,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            uploadFile,
        },
        dispatch,
    );
}

const AndroidGeofenceStep4RF = reduxForm({
    form: "geofence_form",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidGeofenceStep4);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AndroidGeofenceStep4RF),
    ),
);
