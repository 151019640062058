import { useState } from "react";
import { noafarinSignIn, noafarinSignUp, noafarinStart } from "@/actions/auth";
import { useDispatch } from "react-redux";

export const ACTION_PAGES = {
    START: 1,
    SIGN_IN: 2,
    SIGN_UP: 3,
};

export const useHandleApi = handleSubmit => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [innovativeCode, setInnovativeCode] = useState("");
    const [inProgress, toggleInProgress] = useState(false);
    const [actionPage, setActionPage] = useState(ACTION_PAGES.START);

    const startRequest = ({ innovative_code }) => {
        toggleInProgress(true);
        setInnovativeCode(innovative_code);
        setError("");

        noafarinStart(
            { innovative_code },
            {
                onSuccess: ({ is_new_user }) => {
                    setActionPage(
                        is_new_user
                            ? ACTION_PAGES.SIGN_UP
                            : ACTION_PAGES.SIGN_IN,
                    );
                    toggleInProgress(false);
                },
                onFailure: error => {
                    toggleInProgress(false);
                    setError(error);
                },
            },
        )(dispatch);
    };

    const resendCodeRequest = () => {
        if (!innovativeCode) return;

        startRequest({ innovative_code: innovativeCode });
    };

    const signInRequest = ({ verification_code }) => {
        if (!innovativeCode) return;
        toggleInProgress(true);
        setError("");

        noafarinSignIn(
            { verification_code, innovative_code: innovativeCode },
            {
                onSuccess: () => {
                    toggleInProgress(false);
                },
                onFailure: error => {
                    toggleInProgress(false);
                    setError(error);
                },
            },
        )(dispatch);
    };
    const signUpRequest = ({ email, verification_code }) => {
        if (!innovativeCode) return;
        toggleInProgress(true);
        setError("");

        noafarinSignUp(
            { email, verification_code, innovative_code: innovativeCode },
            {
                onSuccess: () => {
                    toggleInProgress(false);
                },
                onFailure: error => {
                    toggleInProgress(false);
                    setError(error);
                },
            },
        )(dispatch);
    };

    return {
        error,
        inProgress,
        actionPage,
        startRequest: handleSubmit(startRequest),
        signInRequest: handleSubmit(signInRequest),
        signUpRequest: handleSubmit(signUpRequest),
        resendCodeRequest,
    };
};
