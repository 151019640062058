/**
 * Given an error of any type and return error of type text or null
 *
 * @param error
 */
export function processError(error) {
    try {
        if (typeof error === "string") {
            return error;
        } else if (Array.isArray(error)) {
            return error[0];
        } else {
            error = Object.values(error);
            if (Array.isArray(error)) {
                return error[0];
            } else if (typeof error === "string") {
                return error;
            } else {
                return null;
            }
        }
    } catch (e) {
        return null;
    }
}
