import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";

const ProfilePassword = () => {
    return (
        <div className="profile__section">
            <h4 className="profile__section--header">تغییر رمز عبور</h4>

            <form>
                <div className="profile__fields--vertical">
                    <Field
                        name="old_password"
                        floatingLabelText="رمز عبور فعلی*"
                        component={TextField}
                        type="password"
                        autoComplete="off"
                    />

                    <Field
                        name="password"
                        floatingLabelText="رمز عبور جدید*"
                        component={TextField}
                        type="password"
                        autoComplete="off"
                    />

                    <Field
                        name="password_repeat"
                        floatingLabelText="تکرار رمز عبور جدید*"
                        component={TextField}
                        type="password"
                        autoComplete="off"
                    />
                </div>
            </form>
        </div>
    );
};

export default withErrorCatcher(ProfilePassword);
