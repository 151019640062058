import React from "react";
import "@/components/utils/styles/selectAppPlaceholder.scss";
import search from "@/../assets/images/download.svg";

export default class SelectAppPlaceholder extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="select-app-placeholder">
                <div className="select-app-background"></div>
                <img src={search} className="select-app-icon" />
                <p>
                    برای مشاهده نسخه‌های نصب <br />
                    از فیلتر اپلیکیشن یک مورد را انتخاب کنید.
                </p>
            </div>
        );
    }
}
