/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import DatePicker from "@/components/utils/date-picker";

export const SegmentDateField = ({ label, value, changeDate }) => {
    useEffect(() => {
        if (!value) changeDate(new Date());
    }, []);

    return (
        <div
            className="segments__value-with-unit"
            style={{ position: "relative", top: "10px" }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <span style={{ marginLeft: "10px" }}>{label}</span>
                <DatePicker
                    type="date"
                    minData={new Date()}
                    date={value ? new Date(value) : new Date()}
                    onDateChange={changeDate}
                />
            </div>
        </div>
    );
};

export default SegmentDateField;
