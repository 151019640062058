import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { fetchAlerts, readAlert, readALl } from "@/actions/alerts";
import { Scrollbars } from "react-custom-scrollbars";
import Util from "@/utils";
import { withRouter } from "react-router-dom";
import ModalDefault from "@/components/utils/modal-default";

const NotificationCenter = props => {
    const { open, handleClose, history } = props;
    const dispatch = useDispatch();

    const [displayModal, toggleDisplayModal] = useState(false);
    const [modalState, updateModalState] = useState({ title: "", desc: "" });

    useEffect(() => {
        fetchAlerts()(dispatch);
    }, [dispatch]);

    const alerts = useSelector(state => state?.alerts ?? {});
    const alertList = Array.isArray(alerts?.list) ? alerts.list : [];
    const unread = alerts?.unread ?? null;
    const isEmpty = alertList.length < 1;

    const markAllRead = () =>
        readALl(alertList.map(alert => alert.data.id))(dispatch);

    const clickOnAlert = id => {
        readAlert(id, { seen: true })(dispatch);

        const clickedAlert = alertList.find(alert => alert?.data?.id === id);
        if (!clickedAlert) return;

        const actionType = clickedAlert?.data?.action_type;
        const actionUrl = clickedAlert?.data?.url ?? "";
        const title = clickedAlert?.data?.title ?? "";
        const desc = clickedAlert?.data?.content ?? "";

        if (actionType === 3) {
            updateModalState({ title, desc });
            toggleDisplayModal(true);
        } else if (actionType === 2 && actionUrl.includes("http")) {
            window.open(clickedAlert?.data?.url);
        } else if (actionType === 2) {
            history.push(actionUrl);
        }
    };

    return (
        <>
            <div
                className={classNames("notification", {
                    "notification--open": open,
                    "notification--empty": isEmpty,
                })}
                onClick={e => {
                    e.stopPropagation();
                    handleClose();
                }}
            >
                {/* Notification Center Header */}
                <div className="notification-header">
                    <div className="notification-header__wrap">
                        {unread && (
                            <div className="notification-count">
                                <span>{unread}</span>
                                <p>نوتیفیکیشن</p>
                            </div>
                        )}

                        {!unread && (
                            <div className="notification-count">
                                <p>نوتیفیکیشن‌ها</p>
                            </div>
                        )}

                        {!isEmpty && (
                            <button onClick={markAllRead}>همه را خواندم</button>
                        )}
                    </div>
                </div>
                {/* -- End of Notification Center Header */}

                {/* Notification Center Body */}
                {isEmpty && (
                    <div className="notification-body">
                        <p>هیچ نوتیفیکیشنی برای نمایش وجود ندارد</p>
                    </div>
                )}

                {!isEmpty && (
                    <Scrollbars className="notification-body" autoHide>
                        <ul>
                            {alertList
                                .sort((a, b) =>
                                    a.seen === b.seen ? 0 : a.seen ? -1 : 1,
                                )
                                .map((item, idx) => (
                                    <li
                                        key={idx}
                                        className={classNames(
                                            "notification-item",
                                            {
                                                "notification-item--read":
                                                    item.seen,
                                            },
                                        )}
                                        onClick={() =>
                                            clickOnAlert(item?.data?.id)
                                        }
                                    >
                                        <span
                                            style={{
                                                backgroundImage: `url(${item?.data?.icon})`,
                                            }}
                                        />
                                        <div>
                                            <p style={{ fontSize: "0.87rem" }}>
                                                {item?.data?.title}
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: "400",
                                                    fontFamily: "IRANSansLight",
                                                }}
                                            >
                                                {item?.data?.content}
                                            </p>
                                            <time style={{ marginTop: "5px" }}>
                                                {Util._convertToLocalTimezone(
                                                    item?.data?.created_at,
                                                )}
                                            </time>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </Scrollbars>
                )}
                {/* -- End of Notification Center Body */}

                <div className="notification-underlay" onClick={handleClose} />
            </div>

            {displayModal && (
                <ModalDefault
                    title={modalState?.title}
                    desc={modalState?.desc}
                    onClose={() => {
                        toggleDisplayModal(false);
                        updateModalState({ title: "", desc: "" });
                    }}
                />
            )}
        </>
    );
};

export default withRouter(NotificationCenter);
