import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    token: null,
    accessLevel: null,
    profile: {
        permissions: [],
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.AUTH.SIGN_IN:
        case actionTypes.AUTH.SIGN_IN_DESKPRO:
        case actionTypes.AUTH.SIGN_UP:
            return {
                ...state,
                ["token"]: action.payload.token,
                ["accessLevel"]: action.payload.accessLevel,
                profile: {
                    ...state.profile,
                    permissions: action.payload.permissions,
                    plan: action.payload.plan,
                },
            };
        case actionTypes.AUTH.SIGN_OUT:
            return {
                ...state,
                ["token"]: null,
                ["accessLevel"]: null,
                ["profile"]: null,
            };
        case actionTypes.AUTH.FETCH_PROFILE:
            return {
                ...state,
                ["profile"]: action.payload,
            };
        case actionTypes.AUTH:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    console_settings: action.payload,
                },
            };
        case actionTypes.FINANCIAL.CHANGE_PLAN:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    plan: action.payload,
                },
            };
    }
    return state;
}
