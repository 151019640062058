import { useEffect } from "react";
import { errorToast, successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";

export const useHandleReturnFromBank = (history = {}) => {
    useEffect(() => {
        const search = history?.location?.search ?? "";

        if (search.indexOf("r=1") > -1) {
            // success
            successToast(
                TOAST_CONTENT.INVOICE_PAYMENT_SUCCESS.TITLE,
                TOAST_CONTENT.INVOICE_PAYMENT_SUCCESS.DESC,
                { immediate: true, autoClose: 20000 },
            );
        } else if (search.indexOf("r=0") > -1) {
            // failure
            errorToast(
                TOAST_CONTENT.INVOICE_PAYMENT_FAILURE.TITLE,
                TOAST_CONTENT.INVOICE_PAYMENT_FAILURE.DESC,
                { immediate: true, autoClose: 20000 },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
