import { mapValues, keys, pickBy, isEmpty } from "lodash";

export function normalize(data, normalizers, acceptByDefault) {
    const normalizedData = performNormalization(
        data,
        null,
        normalizers,
        data,
        acceptByDefault,
    );
    return clearEmptyValues(normalizedData);
}

function clearEmptyValues(normalizedData) {
    return Object.keys(normalizedData).reduce((acc, curr) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!normalizedData.hasOwnProperty(curr)) return acc;

        if (
            Array.isArray(normalizedData[curr]) ||
            typeof normalizedData[curr] === "object"
        ) {
            // If value is an array or an object
            if (!isEmpty(normalizedData[curr])) {
                acc[curr] = normalizedData[curr];
            }
        } else {
            acc[curr] = normalizedData[curr];
        }

        return acc;
    }, {});
}

function performNormalization(
    data,
    rootKey,
    normalizers,
    allData,
    acceptByDefault,
) {
    try {
        let normalizedValues = mapValues(normalizers, (normalizer, key) => {
            const value = data[key];

            if (normalizer === undefined) {
                return acceptByDefault ? value : undefined;
            } else if (typeof normalizer === "function") {
                return normalizer(value, key, allData);
            } else if (Array.isArray(normalizer)) {
                return normalizer.reduce(
                    (result, n, indx) =>
                        indx > 0 && result === undefined
                            ? undefined
                            : n(result, key, allData),
                    value,
                );
            } else if (typeof normalizer === "object") {
                if (value == null) return value;
                let normalizedResult = performNormalization(
                    value,
                    key,
                    normalizer,
                    allData,
                    acceptByDefault,
                );
                if (normalizer.__all__) {
                    normalizedResult = normalizer.__all__(
                        normalizedResult,
                        rootKey,
                        allData,
                    );
                }
                return normalizedResult;
            } else {
                throw new TypeError(
                    `Invalid value type for normalizer, ${key} : ${typeof normalizer}`,
                );
            }
        });

        if (acceptByDefault) {
            const normalizerKeys = new Set(keys(normalizers));
            const nonExistingNormalizerKeys = keys(data).filter(
                k => !normalizerKeys.has(k),
            );
            const nonNormalizedData = nonExistingNormalizerKeys.reduce(
                (o, k) => {
                    o[k] = data[k];
                    return o;
                },
                {},
            );
            normalizedValues = { ...normalizedValues, ...nonNormalizedData };
        }

        return pickBy(normalizedValues, v => v !== undefined);
    } catch (e) {
        console.error("EEEEEEEEEEEEEE is", e);
    }
}
