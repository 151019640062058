import { isEqual } from "lodash";
import { usePrevious } from "@/components/utilities";

/**
 * Accepts a list of <T = any> and check each item with it's previous version (use usePrevious hook)
 * Return a list of boolean
 *
 * @param list
 */
export function effectDeepCompare(list) {
    return list.map(item => !isEqual(usePrevious(item), item));
}
