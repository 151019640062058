import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { normalize } from "@/components/utils/normalize";
import { androidNormalizeNotificationCardData } from "@/components/notifications/notification/normalize";
import { omit, values } from "lodash";
import Highlight from "react-highlight/lib/optimized";

const DetailsTab = props => {
    const { notification } = props;

    let modifiedData = normalize(
        notification,
        androidNormalizeNotificationCardData,
    );
    const filtersData = modifiedData.filters;
    const hasFilters = filtersData && Object.keys(filtersData).length > 0;
    modifiedData = {
        ...omit(modifiedData, ["data", "filters"]),
        ...modifiedData.data,
    };
    return (
        <div className="preview-options">
            <ul>
                {modifiedData && values(modifiedData)}
                {hasFilters && (
                    <li>
                        <span>فیلترها: </span>
                        <Highlight className="json">
                            {values(filtersData)}
                        </Highlight>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default withErrorCatcher(DetailsTab);
