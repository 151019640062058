import React from "react";
import { defaults } from "react-chartjs-2";
import { CHART_LIST } from "./common";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
    DivSort: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "p &": {
            padding: "5px",
        },
    },
    ChartWrapper: {
        height: 300,
    },
});
function CustomChart({ dataChart, typeChart, height, options }) {
    const data = {
        labels: dataChart?.labels,
        datasets: dataChart?.data,
    };
    const classes = useStyle();
    const ComponentChart = CHART_LIST[typeChart].component;
    defaults.global.defaultFontFamily = "iranyekanwebbold";
    return (
        <div className={classes.ChartWrapper} style={{ height: height }}>
            <ComponentChart
                data={data}
                options={options || CHART_LIST[typeChart].options}
            />
        </div>
    );
}

export default CustomChart;
