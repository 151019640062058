/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { Close, Search } from "@/components/utils/lib/svgicons";
import Select from "@/components/utilities/commons/select";
import { TIME_FILTER_OPTION } from "application/constants";
import Input from "@/components/utils/input";
import Util from "@/utils";
import isEqual from "lodash/isEqual";
import { WEB_PUSH_DOMAIN_FOR_HTTP } from "application/constants";

class WebApplicationFilter extends React.Component {
    static propTypes = {
        applications: PropTypes.array.isRequired,
        offset: PropTypes.number.isRequired,
        onFilterChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        applications: [],
        offset: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            searchNameAndUrl: "",
            searchTimeFilter: "",
        };
    }

    componentDidMount() {
        this.handleFilterApplications();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !isEqual(prevProps.applications, this.props.applications) ||
            prevState.searchNameAndUrl !== this.state.searchNameAndUrl ||
            prevState.searchTimeFilter !== this.state.searchTimeFilter ||
            prevProps.offset !== this.props.offset
        ) {
            this.handleFilterApplications();
        }
    }

    handleFilterApplications() {
        const { searchNameAndUrl, searchTimeFilter } = this.state;
        const { applications, onFilterChange } = this.props;

        let filteredApplications = [...applications].filter(app => {
            if (searchNameAndUrl) {
                let name = app["name"] || "";
                let domain = app["domain"] || "";
                let pushe_subdomain = app["pushe_subdomain"]
                    ? app["pushe_subdomain"] + "." + WEB_PUSH_DOMAIN_FOR_HTTP
                    : "";

                return (
                    name
                        .toLowerCase()
                        .includes(searchNameAndUrl.toLowerCase()) ||
                    domain
                        .toLowerCase()
                        .includes(searchNameAndUrl.toLowerCase()) ||
                    pushe_subdomain
                        .toLowerCase()
                        .includes(searchNameAndUrl.toLowerCase())
                );
            }
            if (searchTimeFilter)
                return Util._isOlderThan(searchTimeFilter, app.created_at);
            return true;
        });

        onFilterChange({ filteredApplications });
    }

    render() {
        const { searchNameAndUrl } = this.state;

        return (
            <div className="table-row">
                {/* Website Name Filter */}
                <div className="table-col table-col--25">
                    <Input
                        className="input--border-round"
                        placeholder="نام و آدرس وب‌سایت"
                        value={searchNameAndUrl}
                        onChange={e =>
                            this.setState({ searchNameAndUrl: e.target.value })
                        }
                    />

                    {!!searchNameAndUrl ? (
                        <Close
                            className="input--close-icon"
                            viewBox="0 0 26 26"
                            style={{ fill: "#b3b3b3" }}
                            onClick={() =>
                                this.setState({ searchNameAndUrl: "" })
                            }
                        />
                    ) : (
                        <Search
                            className="input--search-icon"
                            viewBox="0 0 26 26"
                            style={{ fill: "#b3b3b3" }}
                        />
                    )}
                </div>

                {/* Time Filter */}
                <div className="table-col table-col--25">
                    <Select
                        defaultSelect={TIME_FILTER_OPTION[0]}
                        className="select--border-round"
                        itemList={TIME_FILTER_OPTION}
                        onItemChange={item =>
                            this.setState({ searchTimeFilter: item.value })
                        }
                    />
                </div>
            </div>
        );
    }
}

export default withErrorCatcher(WebApplicationFilter);
