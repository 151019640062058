import React from "react";
import Svgicon from "material-ui/SvgIcon";

// viewBox="0 0 561 561"
export const Clipboard = props => (
    <Svgicon {...props}>
        <g>
            <path d="M395.25,0h-306c-28.05,0-51,22.95-51,51v357h51V51h306V0z M471.75,102h-280.5c-28.05,0-51,22.95-51,51v357c0,28.05,22.95,51,51,51h280.5c28.05,0,51-22.95,51-51V153C522.75,124.95,499.8,102,471.75,102z M471.75,510h-280.5V153h280.5V510z" />
        </g>
    </Svgicon>
);

export const TestNotification = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="none"
            strokeWidth="2"
            d="M8.9997,0.99999995 L8.9997,8.0003 L1.9997,20.0003 L1.9997,23.0003 L21.9997,23.0003 L21.9997,20.0003 L14.9997,8.0003 L14.9997,0.99999995 M15,18 C15.5522847,18 16,17.5522847 16,17 C16,16.4477153 15.5522847,16 15,16 C14.4477153,16 14,16.4477153 14,17 C14,17.5522847 14.4477153,18 15,18 Z M9,20 C9.55228475,20 10,19.5522847 10,19 C10,18.4477153 9.55228475,18 9,18 C8.44771525,18 8,18.4477153 8,19 C8,19.5522847 8.44771525,20 9,20 Z M18,13 C11,9.99999996 12,17.0000002 6,14 M5.9997,1.0003 L17.9997,1.0003"
        ></path>
    </svg>
);

// viewBox="0 0 34 22"
export const GooglePlus = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M11.929,13.27h5.312c-0.933,2.666-3.458,4.577-6.415,4.555c-3.587-0.026-6.553-2.929-6.693-6.554
				c-0.149-3.886,2.934-7.095,6.744-7.095c1.742,0,3.332,0.671,4.53,1.771c0.284,0.261,0.716,0.262,0.995-0.003l1.951-1.857
				c0.306-0.291,0.307-0.781,0.002-1.072c-1.9-1.821-4.45-2.956-7.262-3.012C5.097-0.116,0.042,4.856,0,10.921
				C-0.042,17.032,4.844,22,10.877,22c5.802,0,10.542-4.594,10.859-10.381c0.009-0.073,0.015-2.525,0.015-2.525h-9.822
				c-0.403,0-0.729,0.33-0.729,0.737v2.701C11.2,12.939,11.526,13.27,11.929,13.27z"
            />
            <path
                d="M30.752,9.339V6.7c0-0.357-0.286-0.646-0.639-0.646h-2.197c-0.353,0-0.639,0.289-0.639,0.646v2.639h-2.609
				c-0.353,0-0.639,0.289-0.639,0.646v2.221c0,0.357,0.286,0.646,0.639,0.646h2.609v2.639c0,0.357,0.286,0.646,0.639,0.646h2.197
				c0.353,0,0.639-0.289,0.639-0.646v-2.639h2.609c0.353,0,0.639-0.289,0.639-0.646V9.985c0-0.357-0.286-0.646-0.639-0.646H30.752z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const Home = props => (
    <Svgicon {...props} viewBox="0 0 50 50">
        <g>
            <path d="M25,1a1,1,0,0,0-.58.21l-23,18a1,1,0,1,0,1.24,1.58L4,19.71V46a1,1,0,0,0,1,1H45a1,1,0,0,0,1-1V19.71l1.38,1.08a1,1,0,0,0,1.24-1.58h0l-23-18A1,1,0,0,0,25,1Zm0,2.27L44,18.14V45H6V18.14ZM22.62,15a1.56,1.56,0,0,0-1,.33,1.46,1.46,0,0,0-.56,1.12v-.09l-.23,2.35a7.05,7.05,0,0,0-1.11.66l-2.25-1h0a1.73,1.73,0,0,0-1.76.42l-.09.09-2.36,4.05,0-.05a1.57,1.57,0,0,0-.21,1.22,1.61,1.61,0,0,0,.67.9h0l2.09,1.28c0,.24-.08.55-.08.72s.05.47.06.71L14,29a1.64,1.64,0,0,0-.66,1,1.8,1.8,0,0,0,.08.9l0,.1L15.81,35l0,0a1.5,1.5,0,0,0,2.12.46l-.15.08,2.19-1c.37.24.75.46,1.13.67l.23,2.33v-.09a1.46,1.46,0,0,0,.56,1.12,1.59,1.59,0,0,0,1,.34h4.49a1.61,1.61,0,0,0,1-.34,1.48,1.48,0,0,0,.55-1.12v.09l.23-2.35a7.05,7.05,0,0,0,1.11-.66l2.25,1h0a1.73,1.73,0,0,0,1.76-.42l.09-.09,2.33-4A1.57,1.57,0,0,0,37,29.91a1.61,1.61,0,0,0-.67-.9h0L34.2,27.67c0-.22,0-.49,0-.67s0-.47-.06-.7L36,25a1.64,1.64,0,0,0,.66-1,1.69,1.69,0,0,0-.08-.9l0-.1L34.19,19l0,0a1.61,1.61,0,0,0-.9-.67,1.53,1.53,0,0,0-1.22.21l.15-.08-2.19,1c-.37-.23-.75-.46-1.13-.66l-.23-2.34v.09a1.46,1.46,0,0,0-.56-1.12,1.57,1.57,0,0,0-1-.33Zm.4,2h3.69L27,20l.5.25a13.69,13.69,0,0,1,1.88,1.11l.46.33,2.86-1.28,1.87,3.21-2.51,1.8.13.64a4.62,4.62,0,0,1,.07,1,4.62,4.62,0,0,1-.07,1l-.14.67.58.37,2.2,1.42L33,33.64l-2.92-1.31-.48.42a5.25,5.25,0,0,1-1.71,1l-.59.22L27,37H23.29l-.28-3-.49-.25a13.24,13.24,0,0,1-1.89-1.11l-.46-.33-2.86,1.28-1.86-3.21,2.5-1.8L17.83,28a4.06,4.06,0,0,1-.08-1,3.5,3.5,0,0,1,.1-1l.17-.71-2.83-1.74L17,20.36,20,21.67l.48-.42a5.44,5.44,0,0,1,1.71-1l.59-.22Zm2,5a5,5,0,1,0,5,5A5,5,0,0,0,25,22Zm0,2a3,3,0,1,1-3,3A2.92,2.92,0,0,1,25,24Z" />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const App = props => (
    <Svgicon {...props} viewBox="0 0 50 50">
        <g>
            <path d="M6,4A1.95,1.95,0,0,0,4,6V22a1.95,1.95,0,0,0,2,2H22a1.95,1.95,0,0,0,2-2V6a1.95,1.95,0,0,0-2-2ZM28,4a1.95,1.95,0,0,0-2,2V22a1.95,1.95,0,0,0,2,2H44a1.95,1.95,0,0,0,2-2V6a1.95,1.95,0,0,0-2-2ZM6,6H22V22H6ZM28,6H44V22H28ZM8,7A1,1,0,1,0,9,8,1,1,0,0,0,8,7Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,14,7Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,20,7Zm-9,3a1,1,0,1,0,1,1A1,1,0,0,0,11,10Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,17,10ZM8,13a1,1,0,1,0,1,1A1,1,0,0,0,8,13Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,14,13Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,20,13Zm-9,3a1,1,0,1,0,1,1A1,1,0,0,0,11,16Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,17,16ZM8,19a1,1,0,1,0,1,1A1,1,0,0,0,8,19Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,14,19Zm6,0a1,1,0,1,0,1,1A1,1,0,0,0,20,19Zm13,7H28a1.95,1.95,0,0,0-2,2v4.85a.92.92,0,0,0,0,.33v5.67a.92.92,0,0,0,0,.33V44a1.95,1.95,0,0,0,2,2h4.82a.91.91,0,0,0,.33,0h5.67a.91.91,0,0,0,.33,0H44a1.95,1.95,0,0,0,2-2V39.13a1.31,1.31,0,0,0,0-.28V33.13a1.31,1.31,0,0,0,0-.28V28a1.95,1.95,0,0,0-2-2H33ZM6,26a1.95,1.95,0,0,0-2,2V44a1.95,1.95,0,0,0,2,2H22a1.95,1.95,0,0,0,2-2V28a1.95,1.95,0,0,0-2-2Zm0,2H22V44H6Zm22,0h2.59L28,30.59Zm5.41,0h3.18L28,36.59V33.41Zm6,0h3.18L28,42.59V39.41ZM10,29a3,3,0,1,0,3,3A3,3,0,0,0,10,29Zm8,0a3,3,0,1,0,3,3A3,3,0,0,0,18,29Zm26,.41v3.18L32.59,44H29.41ZM10,31a1,1,0,1,1-1,1A1,1,0,0,1,10,31Zm8,0a1,1,0,1,1-1,1A1,1,0,0,1,18,31Zm26,4.41v3.18L38.59,44H35.41ZM10,37a3,3,0,1,0,3,3A3,3,0,0,0,10,37Zm8,0a3,3,0,1,0,3,3A3,3,0,0,0,18,37Zm-8,2a1,1,0,1,1-1,1A1,1,0,0,1,10,39Zm8,0a1,1,0,1,1-1,1A1,1,0,0,1,18,39Zm26,2.41V44H41.41Z" />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const Phone = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M15.672,20.644H5.328c-1.077,0-1.953-0.755-1.953-1.684V2.039c0-0.928,0.876-1.683,1.953-1.683h10.344
				c1.076,0,1.952,0.755,1.952,1.683V18.96C17.624,19.889,16.748,20.644,15.672,20.644z M5.328,1.306c-0.543,0-1.002,0.335-1.002,0.733
				V18.96c0,0.397,0.459,0.734,1.002,0.734h10.344c0.544,0,1.003-0.337,1.003-0.734V2.039c0-0.397-0.459-0.733-1.003-0.733H5.328z"
            />
            <path
                d="M11.688,15.741c0,0.634-0.316,0.95-0.95,0.95s-0.95-0.316-0.95-0.95c0-0.633,0.316-0.95,0.95-0.95
				S11.688,15.108,11.688,15.741z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const Notification = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M8.762,12.21L2,8.258l0.092-0.905L17.909,2l0.519,0.822l-9.055,9.305L8.762,12.21z M3.452,7.948l5.477,3.201l7.334-7.537
					L3.452,7.948z"
            />
            <path
                d="M12.61,18.878l-4.009-6.744l0.066-0.6l8.948-9.462l0.842,0.49l-4.939,16.206L12.61,18.878z M9.654,11.947l3.238,5.446
					l3.989-13.089L9.654,11.947z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const NotificationThick = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M7.729,14.033l-7.1-4.15l0.108-1.706l18.565-7.903l1.068,1.54L8.905,13.867L7.729,14.033z M3.238,9.191L8.042,12
				l7.754-8.154L3.238,9.191z"
            />
            <path
                d="M11.061,20.813l-3.947-7.465l0.152-1.107L18.73,0.188l1.586,1.003l-7.518,19.52L11.061,20.813z M9.125,13.061l2.644,5
				l5.033-13.07L9.125,13.061z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const User = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M10.5,19.15c-3.684,0-7.4-1.052-7.4-3.4c0-2.813,1.601-5.359,4.177-6.647l0.447,0.895C5.489,11.115,4.1,13.319,4.1,15.75
				c0,1.56,3.298,2.4,6.4,2.4s6.4-0.841,6.4-2.4c0-2.523-1.383-4.725-3.608-5.745l0.416-0.909c2.586,1.185,4.192,3.734,4.192,6.654
				C17.901,18.098,14.184,19.15,10.5,19.15z"
            />
            <path
                d="M10.4,11.05c-2.536,0-4.6-2.063-4.6-4.6s2.063-4.6,4.6-4.6s4.6,2.063,4.6,4.6S12.936,11.05,10.4,11.05z M10.4,2.85
				c-1.985,0-3.6,1.615-3.6,3.6s1.615,3.6,3.6,3.6c1.984,0,3.6-1.615,3.6-3.6S12.384,2.85,10.4,2.85z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const Info = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M10.5,19C5.813,19,2,15.187,2,10.5C2,5.813,5.813,2,10.5,2c4.687,0,8.5,3.813,8.5,8.5C19,15.187,15.187,19,10.5,19z M10.5,3
				C6.364,3,3,6.364,3,10.5S6.364,18,10.5,18s7.5-3.364,7.5-7.5S14.636,3,10.5,3z"
            />
            <path
                d="M10.5,15.07c-0.33,0-0.613-0.282-0.613-0.612V9.275c0-0.33,0.283-0.613,0.613-0.613s0.612,0.283,0.612,0.613v5.23
				C11.065,14.835,10.83,15.07,10.5,15.07z"
            />
            <path
                d="M11.396,6.448c0,0.597-0.299,0.895-0.896,0.895S9.604,7.044,9.604,6.448c0-0.597,0.299-0.896,0.896-0.896
				S11.396,5.851,11.396,6.448z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const Help = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M18.503,12.502h-3.318v-1H18.5c0.267,0,0.519-0.104,0.708-0.294c0.188-0.189,0.292-0.44,0.292-0.707c0-0.552-0.448-1-1-1
				h-3.273v-1H18.5c1.103,0,2,0.897,2,2C20.501,11.603,19.604,12.501,18.503,12.502z M5.687,12.502H2.5c-1.103,0-2-0.897-2-2
				s0.897-2,2-2h3.144v1H2.5c-0.551,0-1,0.449-1,1s0.449,1,1,1h3.187C5.688,11.502,5.688,12.502,5.687,12.502z"
            />
            <path
                d="M8.998,18.715c-0.05,0-0.099-0.008-0.146-0.022c-3.352-0.658-5.963-3.238-6.658-6.587c-0.056-0.271,0.118-0.535,0.388-0.591
				c0.269-0.064,0.534,0.117,0.591,0.388c0.616,2.969,2.939,5.252,5.918,5.817l0.331,0.063l0.066,0.331
				c0.054,0.271-0.121,0.536-0.392,0.591C9.064,18.711,9.031,18.715,8.998,18.715z M11.999,18.692c-0.233,0-0.441-0.164-0.489-0.401
				c-0.054-0.271,0.121-0.534,0.392-0.589c2.932-0.588,5.206-2.861,5.795-5.793c0.055-0.271,0.323-0.448,0.589-0.392
				c0.271,0.055,0.446,0.318,0.392,0.589c-0.669,3.327-3.251,5.909-6.578,6.576C12.065,18.689,12.032,18.692,11.999,18.692z
				 M10.433,15.89c-0.001,0-0.001,0-0.002,0c-3.008-0.001-5.454-2.449-5.453-5.457c0-1.458,0.568-2.827,1.599-3.857
				c1.03-1.029,2.4-1.596,3.856-1.596c0.001,0,0.001,0,0.002,0l0,0c3.003,0.003,5.449,2.449,5.452,5.453
				C15.887,13.444,13.439,15.89,10.433,15.89z M10.433,5.98c-1.189,0-2.308,0.463-3.149,1.303c-0.842,0.841-1.306,1.96-1.306,3.15
				c-0.001,2.457,1.997,4.456,4.453,4.457c0,0,0.001,0,0.002,0c2.456,0,4.454-1.997,4.455-4.453c-0.003-2.457-2-4.455-4.453-4.457
				C10.434,5.98,10.434,5.98,10.433,5.98z M18.215,9.508c-0.237,0-0.447-0.17-0.491-0.412c-0.54-3.004-2.823-5.328-5.818-5.92
				c-0.271-0.054-0.447-0.317-0.394-0.588s0.321-0.446,0.587-0.394c3.402,0.673,5.996,3.313,6.609,6.725
				c0.049,0.272-0.132,0.532-0.403,0.581C18.274,9.505,18.244,9.508,18.215,9.508z M2.655,9.505c-0.03,0-0.061-0.003-0.092-0.008
				c-0.271-0.051-0.45-0.312-0.4-0.583C2.798,5.512,5.51,2.8,8.91,2.164c0.273-0.045,0.533,0.128,0.583,0.4s-0.128,0.533-0.4,0.583
				c-3.044,0.57-5.378,2.905-5.947,5.95C3.102,9.337,2.892,9.505,2.655,9.505z"
            />
            <path
                d="M10.5,20.502c-1.103,0-2-0.897-2-2v-3.144h1v3.144c0,0.552,0.449,1,1,1s1-0.448,1-1v-3.187h1v3.187
				C12.5,19.604,11.603,20.502,10.5,20.502z M12.5,5.817h-1V2.501c0-0.268-0.104-0.519-0.293-0.708S10.796,1.465,10.5,1.501
				c-0.552,0-1,0.449-1,1v3.274h-1V2.501c0-1.103,0.897-2,2-2c0.001,0,0.001,0,0.002,0c0.532,0,1.034,0.208,1.411,0.584
				C12.292,1.463,12.5,1.965,12.5,2.5V5.817z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const FAQ = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M10.5,19C5.813,19,2,15.187,2,10.5C2,5.813,5.813,2,10.5,2c4.687,0,8.5,3.813,8.5,8.5C19,15.187,15.187,19,10.5,19z M10.5,3
				C6.364,3,3,6.364,3,10.5S6.364,18,10.5,18s7.5-3.364,7.5-7.5S14.636,3,10.5,3z"
            />
            <path
                d="M9.785,12.544c0.007-0.494,0.064-0.886,0.172-1.175c0.111-0.295,0.324-0.615,0.636-0.96l0.83-0.855
				c0.357-0.399,0.537-0.835,0.537-1.304c0-0.46-0.12-0.814-0.359-1.064c-0.24-0.258-0.585-0.387-1.033-0.387
				c-0.432,0-0.783,0.117-1.055,0.35C9.25,7.378,9.116,7.681,9.112,8.057H8.006c0.011-0.654,0.249-1.185,0.715-1.592
				c0.473-0.417,1.089-0.626,1.848-0.626c0.789,0,1.402,0.214,1.842,0.642c0.434,0.411,0.651,0.986,0.651,1.728
				c0,0.73-0.337,1.45-1.012,2.16v-0.006l-0.688,0.689c-0.313,0.337-0.471,0.824-0.471,1.46H9.754L9.785,12.544z M9.9,14.088
				c0.118-0.122,0.282-0.183,0.491-0.183c0.212,0,0.377,0.061,0.496,0.183c0.114,0.118,0.172,0.27,0.172,0.454
				c0,0.188-0.058,0.337-0.172,0.448c-0.119,0.118-0.284,0.178-0.496,0.178c-0.209,0-0.373-0.06-0.491-0.178
				c-0.108-0.111-0.162-0.261-0.162-0.448C9.738,14.354,9.792,14.202,9.9,14.088z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const Support = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M10.43,18.895c-6.096,0-7.537-5.193-7.578-7.195c-0.019-0.889,0-3.445,0-3.445c0-3.626,3.104-6.579,6.92-6.579h0.658
				c3.816,0,6.92,2.953,6.92,6.582v3.932h-0.863v-0.5h-0.137V8.257c0-3.078-2.656-5.582-5.92-5.582H9.772
				c-3.264,0-5.92,2.504-5.92,5.582c0,0.004-0.019,2.54,0,3.42c0.001,0.063,0.302,6.217,6.578,6.217V18.895z"
            />
            <path
                d="M10.372,19.324c-0.934,0-1.267-0.555-1.267-1.074c0-0.518,0.333-1.072,1.267-1.072s1.267,0.555,1.267,1.072
				C11.639,18.77,11.306,19.324,10.372,19.324z"
            />
            <path
                d="M16.757,13.402c-0.933,0-1.399-0.568-1.399-1.705c0-1.135,0.467-1.703,1.399-1.703c0.934,0,1.399,0.568,1.399,1.703
				C18.156,12.834,17.69,13.402,16.757,13.402z"
            />
            <path
                fill="#FFFFFF"
                d="M7.154,11.77V8.733c0-1.984,0.993-2.977,2.977-2.977c1.985,0,2.977,0.993,2.977,2.977v3.037
				c0,1.984-0.992,2.977-2.977,2.977C8.147,14.746,7.154,13.754,7.154,11.77z"
            />
            <g>
                <path
                    d="M10.131,15.246c-2.275,0-3.477-1.203-3.477-3.477V8.733c0-2.275,1.202-3.477,3.477-3.477s3.477,1.202,3.477,3.477v3.037
					C13.608,14.043,12.406,15.246,10.131,15.246z M10.131,6.256c-1.713,0-2.477,0.764-2.477,2.477v3.037
					c0,1.713,0.764,2.477,2.477,2.477c1.713,0,2.477-0.764,2.477-2.477V8.733C12.608,7.02,11.845,6.256,10.131,6.256z"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 25 25"
export const Setting = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M15.493,24.683l-0.396-0.678c-0.576-0.984-1.596-1.621-2.597-1.621c-1.001,0-2.021,0.637-2.598,1.621l-0.396,0.677
				l-0.751-0.223c-0.824-0.245-1.625-0.576-2.38-0.984l-0.69-0.373l0.199-0.76c0.28-1.072,0.085-1.871-0.633-2.59
				c-0.729-0.729-1.52-0.915-2.591-0.634l-0.759,0.198l-0.374-0.69c-0.405-0.748-0.736-1.548-0.984-2.379l-0.225-0.752l0.678-0.397
				c0.984-0.576,1.621-1.596,1.621-2.597c0-1.146-0.881-1.903-1.621-2.335L0.316,9.769l0.225-0.753c0.248-0.831,0.58-1.631,0.984-2.379
				l0.374-0.69l0.759,0.199C3.586,6.388,4.394,6.105,5.249,5.25c0.718-0.719,0.914-1.518,0.633-2.59L5.684,1.9l0.69-0.374
				c0.75-0.406,1.551-0.737,2.379-0.984l0.752-0.225l0.396,0.677c0.577,0.985,1.597,1.622,2.598,1.622c1.001,0,2.021-0.636,2.597-1.621
				l0.396-0.678l0.753,0.225c0.83,0.248,1.631,0.579,2.38,0.985l0.689,0.374L19.117,2.66c-0.281,1.072-0.086,1.871,0.634,2.589
				c0.856,0.857,1.666,1.139,2.59,0.896l0.76-0.199l0.373,0.69c0.404,0.75,0.736,1.549,0.984,2.378l0.226,0.753l-0.679,0.397
				c-0.739,0.433-1.621,1.188-1.621,2.335s0.882,1.903,1.621,2.335l0.679,0.397l-0.226,0.753c-0.248,0.828-0.58,1.628-0.984,2.378
				l-0.373,0.69l-0.76-0.198c-0.924-0.24-1.733,0.04-2.59,0.895c-0.719,0.719-0.914,1.518-0.634,2.59l0.198,0.759l-0.689,0.373
				c-0.751,0.407-1.552,0.739-2.38,0.985L15.493,24.683z M7.972,22.036c0.222,0.101,0.447,0.193,0.676,0.279
				c0.971-1.193,2.412-1.932,3.853-1.932c1.44,0,2.881,0.738,3.852,1.931c0.229-0.085,0.454-0.179,0.676-0.279
				c-0.164-1.422,0.274-2.663,1.31-3.698c1.125-1.126,2.358-1.643,3.69-1.554c0.102-0.222,0.195-0.448,0.281-0.679
				c-1.248-0.95-1.925-2.207-1.925-3.604s0.677-2.653,1.925-3.604c-0.086-0.23-0.18-0.457-0.281-0.679
				c-1.332,0.097-2.565-0.428-3.69-1.554c-1.036-1.035-1.475-2.276-1.31-3.698c-0.222-0.101-0.447-0.194-0.676-0.279
				C15.381,3.878,13.94,4.617,12.5,4.617c-1.441,0-2.881-0.738-3.852-1.931C8.419,2.771,8.193,2.864,7.972,2.965
				c0.165,1.422-0.273,2.663-1.309,3.699C5.537,7.789,4.3,8.314,2.972,8.218C2.871,8.44,2.776,8.667,2.69,8.897
				c1.248,0.95,1.925,2.207,1.925,3.604c0,1.441-0.738,2.881-1.93,3.851c0.085,0.229,0.179,0.456,0.279,0.677
				c1.432-0.166,2.668,0.278,3.7,1.31C7.698,19.373,8.137,20.614,7.972,22.036z M12.5,18.284c-3.189,0-5.784-2.595-5.784-5.784
				c0-3.189,2.595-5.784,5.784-5.784c3.188,0,5.783,2.595,5.783,5.784C18.283,15.689,15.688,18.284,12.5,18.284z M12.5,8.716
				c-2.086,0-3.784,1.698-3.784,3.784s1.698,3.784,3.784,3.784c2.086,0,3.783-1.697,3.783-3.784S14.586,8.716,12.5,8.716z"
            />
            <g>
                <polyline
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    points="25,0 0,0 0,25 25,25 	"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 24 24"
export const Bell = props => (
    <Svgicon {...props}>
        <g>
            <path
                id="Path_1879"
                fill="none"
                strokeMiterlimit="10"
                d="M20,20.25H4
                c-1.103-0.003-1.997-0.897-2-2v-1c0.003-1.103,0.897-1.997,2-2h16c1.103,0.003,1.997,0.897,2,2v1
                C21.997,19.353,21.103,20.247,20,20.25z"
            />
            <path
                id="Path_1880"
                fill="none"
                strokeMiterlimit="10"
                d="M12,4.25L12,4.25
                c-3.842-0.024-6.976,3.072-7,6.914c0,0.029,0,0.058,0,0.086v4h14v-4c0.024-3.842-3.072-6.976-6.914-7
                C12.058,4.25,12.029,4.25,12,4.25z"
            />
            <line
                id="Line_577"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="12"
                y1="1.25"
                x2="12"
                y2="4.25"
            />
            <path
                id="Path_1881"
                d="M15,20.75c0,1.657-1.343,3-3,3s-3-1.343-3-3"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 22"
export const Exit = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M18.863,22H9.995c-0.552,0-1-0.447-1-1s0.448-1,1-1h8.868C18.94,20,19,19.903,19,19.78V2.22C19,2.097,18.94,2,18.863,2
				H9.995c-0.552,0-1-0.448-1-1s0.448-1,1-1h8.868C20.062,0,21,0.975,21,2.22V19.78C21,21.025,20.062,22,18.863,22z"
            />
            <path
                d="M6.404,17.909c-0.271,0-0.541-0.109-0.738-0.325l-5.403-5.909c-0.35-0.382-0.35-0.968,0-1.35l5.403-5.909
				c0.373-0.408,1.004-0.438,1.413-0.063c0.408,0.373,0.436,1.005,0.063,1.413L2.355,11l4.786,5.234
				c0.373,0.407,0.345,1.04-0.063,1.413C6.886,17.822,6.645,17.909,6.404,17.909z"
            />
            <path
                d="M15.047,12.367H2.66c-0.552,0-1-0.447-1-1c0-0.552,0.448-1,1-1h12.387c0.553,0,1,0.448,1,1
				C16.047,11.92,15.6,12.367,15.047,12.367z"
            />
            <g>
                <polyline
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    points="21,0 0,0 0,22 21,22 	"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 72 86"
export const Android = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M28.744,85.909c-1.294,0-2.578-0.411-3.71-1.189c-1.833-1.269-2.925-3.431-2.925-5.785V68.838h-3.048
				c-2.708,0-4.911-2.203-4.911-4.911V28.315h43.933v35.612c0,2.708-2.203,4.911-4.911,4.911h-0.773v10.324
				c0,1.742-0.699,3.402-1.97,4.673c-1.246,1.246-2.934,1.961-4.628,1.961c-0.001,0,0,0-0.001,0c-1.295,0-2.578-0.412-3.71-1.19
				c-1.752-1.075-2.925-3.317-2.925-5.671V68.838h-3.822v10.438C35.343,82.872,32.321,85.909,28.744,85.909z M17.15,31.315v32.612
				c0,1.054,0.857,1.911,1.911,1.911h6.048v13.097c0,1.391,0.593,2.6,1.628,3.315c0.635,0.437,1.312,0.659,2.007,0.659
				c1.917,0,3.599-1.697,3.599-3.633V65.838h9.822v13.097c0,1.314,0.627,2.583,1.561,3.157c0.703,0.481,1.38,0.704,2.075,0.704
				c0.904,0,1.818-0.395,2.507-1.083c0.703-0.703,1.091-1.609,1.091-2.551V65.838h3.773c1.054,0,1.911-0.857,1.911-1.911V31.315H17.15
				z"
            />
            <path
                d="M65.595,65.317C65.595,65.317,65.595,65.317,65.595,65.317c-3.233-0.001-5.964-3.027-5.964-6.608V34.948
				c0-3.533,2.811-6.631,6.014-6.631c1.019,0,1.996,0.3,2.905,0.891c1.882,1.124,3.081,3.463,3.081,6.077v22.977
				c0,2.262-0.919,4.352-2.521,5.732C68.022,64.87,66.819,65.317,65.595,65.317z M65.645,31.316c-1.549,0-3.014,1.765-3.014,3.631
				v23.761c0,1.888,1.412,3.607,2.964,3.608l0,0c0.541,0,1.077-0.212,1.595-0.629c0.902-0.778,1.441-2.039,1.441-3.428V35.284
				c0-1.54-0.655-2.926-1.669-3.532C66.5,31.453,66.073,31.316,65.645,31.316z"
            />
            <path
                d="M6.333,65.317C6.332,65.317,6.332,65.317,6.333,65.317C3.1,65.316,0.369,62.29,0.369,58.709V34.948
				c0-3.533,2.81-6.631,6.014-6.631c1.019,0,1.996,0.3,2.905,0.891c1.882,1.124,3.082,3.464,3.082,6.077v22.977
				c0,2.261-0.918,4.35-2.521,5.731C8.76,64.87,7.558,65.317,6.333,65.317z M6.383,31.316c-1.549,0-3.014,1.765-3.014,3.631v23.761
				c0,1.888,1.412,3.607,2.963,3.608c0,0,0,0,0,0c0.541,0,1.078-0.212,1.596-0.629c0.901-0.779,1.441-2.04,1.441-3.428V35.284
				c0-1.54-0.655-2.926-1.669-3.532C7.238,31.453,6.811,31.316,6.383,31.316z"
            />
            <path
                d="M57.856,25.516H16.197v-2.523c0.238-4.563,1.848-8.756,4.658-12.205c0.781-0.861,1.598-1.718,2.524-2.504l-2.877-2.878
				c-1.18-1.18-1.234-3.275-0.114-4.395c1.228-1.227,3.168-1.226,4.395,0l3.817,3.817c2.474-1.04,5.041-1.577,7.801-1.636l0.12-0.119
				h0.619c2.986,0,5.742,0.549,8.392,1.675l3.737-3.737c1.225-1.227,3.169-1.227,4.396,0c0.57,0.57,0.864,1.407,0.805,2.297
				c-0.053,0.802-0.388,1.567-0.919,2.098l-2.962,2.882c0.912,0.753,1.758,1.599,2.494,2.498c2.82,3.187,4.528,7.51,4.771,12.128
				L57.856,25.516z M19.233,22.516h35.592c-0.295-3.689-1.716-7.173-4.026-9.786c-0.972-1.185-2.116-2.222-3.348-3.043l-1.544-1.029
				l5.537-5.387c0.015-0.024,0.035-0.122,0.034-0.193c-0.008-0.005-0.015-0.01-0.021-0.013c0,0.01-0.029,0.03-0.067,0.067
				l-4.493,4.494l-1.102,0.551l-0.662-0.317c-2.332-1.116-4.753-1.7-7.383-1.777l-0.105,0.105h-0.732
				c-2.866,0-5.481,0.584-7.995,1.786L27.962,8.43l-5.299-5.298c-0.038-0.038-0.068-0.059-0.085-0.068
				c0,0.002-0.001,0.004-0.002,0.006c-0.002,0.072,0.022,0.18,0.056,0.223l5.38,5.379l-1.522,1.015c-1.295,0.863-2.38,1.98-3.36,3.058
				C20.89,15.496,19.533,18.882,19.233,22.516z M45.555,20.968c-2.207,0-4.002-1.846-4.002-4.115s1.795-4.115,4.002-4.115
				c2.206,0,4.001,1.846,4.001,4.115C49.556,19.199,47.836,20.968,45.555,20.968z M45.555,15.738c-0.562,0-1.002,0.49-1.002,1.115
				s0.44,1.115,1.002,1.115c0.738,0,1.001-0.576,1.001-1.115C46.556,16.228,46.116,15.738,45.555,15.738z M28.613,20.968
				c-2.207,0-4.001-1.846-4.001-4.115s1.795-4.115,4.001-4.115s4.001,1.846,4.001,4.115S30.819,20.968,28.613,20.968z M28.613,15.738
				c-0.562,0-1.001,0.49-1.001,1.115s0.44,1.115,1.001,1.115s1.001-0.49,1.001-1.115S29.174,15.738,28.613,15.738z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 70 87"
export const Apple = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M23.609,87h-0.388c-5.902,0-10.167-5.463-14.029-11.059C1.417,64.553-3.015,45.74,4.076,33.292
				C8,26.53,14.864,22.271,22.441,21.889l0.311-0.002c2.994,0,5.757,1.07,8.195,2.014l0.559,0.216c0.792,0.348,2.998,1.32,4.157,1.32
				c0.552,0,1.686-0.446,2.782-0.877c0.366-0.145,0.75-0.295,1.148-0.446c3.081-1.271,6.449-2.463,9.921-2.463
				c0.312,0,0.585,0.035,0.858,0.074c0.138,0.021,0.275,0.044,0.433,0.044l0.121,0.005c4.808,0.389,11.496,2.242,16.094,8.862
				c0.427,0.533,0.427,1.167,0.427,1.388c0,0.854-0.486,1.345-0.669,1.529l-0.44,0.444h-0.276c-2.884,2.033-7.272,6.267-7.183,13.151
				c0.099,9.354,7.071,13.25,9.278,14.246c0.961,0.37,1.486,1.373,1.286,2.399l-0.05,0.321C68.87,66.093,67,71.132,63.668,76.049
				c-3.189,4.762-7.164,10.697-13.889,10.833c-0.171,0.007-0.369,0.012-0.564,0.012c-2.963,0-5.042-0.979-6.561-1.692
				c-1.749-0.771-3.434-1.514-6.052-1.514c-2.971,0-4.813,0.813-6.438,1.528c-1.743,0.821-3.712,1.647-6.52,1.782L23.609,87z
				 M22.517,24.887c-6.484,0.329-12.434,4.031-15.84,9.901c-6.483,11.379-2.27,28.823,4.989,39.455C15.212,79.382,18.769,84,23.221,84
				h0.315c2.242-0.113,3.827-0.78,5.385-1.514c1.74-0.768,4.078-1.799,7.681-1.799c3.251,0,5.399,0.947,7.296,1.784
				c1.854,0.87,3.41,1.517,5.788,1.411c5.194-0.104,8.541-5.102,11.493-9.511c2.852-4.208,4.539-8.448,5.168-10.517
				c-3.096-1.543-10.359-6.272-10.469-16.672c-0.078-6.03,2.906-11.629,8.218-15.47c-3.895-5.122-9.36-6.614-13.359-6.945
				c-0.284-0.005-0.538-0.038-0.791-0.074c-0.138-0.02-0.274-0.044-0.432-0.044c-2.95,0-5.981,1.083-8.818,2.253
				c-0.428,0.162-0.799,0.309-1.153,0.448c-1.542,0.605-2.76,1.085-3.88,1.085c-1.255,0-2.84-0.463-5.3-1.547l-0.499-0.191
				c-2.193-0.849-4.679-1.812-7.112-1.812H22.517z"
            />
            <path
                d="M35.664,22.758h-0.822c-1.146,0-2.021-0.835-2.083-1.963c-0.745-5.498,1.939-10.838,4.71-14.012
				C40.845,2.857,46.19,0.128,50.764,0h0.001c1.19,0,2.067,0.84,2.125,1.973c0.606,4.832-1.1,10.184-4.572,14.332
				C44.956,20.344,40.225,22.758,35.664,22.758z M49.981,3.059c-3.517,0.396-7.632,2.649-10.245,5.689
				c-2.134,2.442-4.393,6.711-4.071,11.01c3.625-0.001,7.59-2.06,10.349-5.375C48.79,11.065,50.241,6.89,49.981,3.059z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 100 82"
export const Web = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M92.544,81.557H7.457c-3.904,0-7.08-3.176-7.08-7.08V7.523c0-3.904,3.176-7.08,7.08-7.08h85.087
				c3.903,0,7.079,3.176,7.079,7.08v66.953C99.623,78.381,96.447,81.557,92.544,81.557z M7.457,3.444c-2.25,0-4.08,1.83-4.08,4.08
				v66.953c0,2.25,1.83,4.08,4.08,4.08h85.087c2.249,0,4.079-1.83,4.079-4.08V7.523c0-2.25-1.83-4.08-4.079-4.08H7.457z"
            />
            <path
                d="M99.623,22.972H0.377V7.523c0-3.904,3.176-7.08,7.08-7.08h85.087c3.903,0,7.079,3.176,7.079,7.08V22.972z M3.377,19.972
				h93.246V7.523c0-2.25-1.83-4.08-4.079-4.08H7.457c-2.25,0-4.08,1.83-4.08,4.08V19.972z"
            />
            <g>
                <path
                    d="M23.908,16.721h-1.4c-2.371,0-4.3-1.929-4.3-4.3v-1.4c0-2.371,1.929-4.3,4.3-4.3h1.4c2.371,0,4.3,1.929,4.3,4.3v1.4
					C28.208,14.792,26.279,16.721,23.908,16.721z M22.508,9.721c-0.717,0-1.3,0.583-1.3,1.3v1.4c0,0.717,0.583,1.3,1.3,1.3h1.4
					c0.717,0,1.3-0.583,1.3-1.3v-1.4c0-0.717-0.583-1.3-1.3-1.3H22.508z"
                />
            </g>
            <g>
                <polygon points="64.73,63.541 62.609,61.42 74.381,49.648 62.609,37.876 64.73,35.755 78.623,49.648 		" />
            </g>
            <g>
                <polygon points="35.13,63.541 21.237,49.648 35.13,35.755 37.251,37.876 25.479,49.648 37.251,61.42 		" />
            </g>
            <g>
                <rect
                    x="48.317"
                    y="37.928"
                    transform="matrix(0.9591 0.2832 -0.2832 0.9591 16.3383 -12.0422)"
                    width="3"
                    height="25.113"
                />
            </g>
            <g>
                <path
                    d="M12.908,16.721h-1.4c-2.371,0-4.3-1.929-4.3-4.3v-1.4c0-2.371,1.929-4.3,4.3-4.3h1.4c2.371,0,4.3,1.929,4.3,4.3v1.4
					C17.208,14.792,15.279,16.721,12.908,16.721z M11.508,9.721c-0.717,0-1.3,0.583-1.3,1.3v1.4c0,0.717,0.583,1.3,1.3,1.3h1.4
					c0.717,0,1.3-0.583,1.3-1.3v-1.4c0-0.717-0.583-1.3-1.3-1.3H11.508z"
                />
            </g>
            <g>
                <path
                    d="M34.908,16.721h-1.4c-2.371,0-4.3-1.929-4.3-4.3v-1.4c0-2.371,1.929-4.3,4.3-4.3h1.4c2.371,0,4.3,1.929,4.3,4.3v1.4
					C39.208,14.792,37.279,16.721,34.908,16.721z M33.508,9.721c-0.717,0-1.3,0.583-1.3,1.3v1.4c0,0.717,0.583,1.3,1.3,1.3h1.4
					c0.717,0,1.3-0.583,1.3-1.3v-1.4c0-0.717-0.583-1.3-1.3-1.3H33.508z"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 24 24"
export const Pencil = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M0.75,23.725c-0.132,0-0.26-0.052-0.355-0.148C0.271,23.451,0.222,23.27,0.266,23.1l1.914-7.39
				c0.022-0.086,0.067-0.165,0.129-0.228l14.45-14.516c0.888-0.887,2.506-0.894,3.464-0.016l2.787,2.787
				c0.484,0.484,0.755,1.146,0.74,1.816c-0.015,0.648-0.283,1.244-0.756,1.677L8.561,21.73c-0.064,0.065-0.146,0.11-0.234,0.133
				L0.87,23.71C0.831,23.72,0.79,23.725,0.75,23.725z M3.114,16.092l-1.669,6.445l6.506-1.611L22.302,6.509
				c0.287-0.264,0.44-0.605,0.448-0.978c0.009-0.404-0.15-0.791-0.447-1.087l-2.771-2.771c-0.542-0.497-1.545-0.518-2.064,0
				L3.114,16.092z"
            />
            <path
                d="M20.25,9.775c-0.131,0-0.262-0.051-0.36-0.153l-5.432-5.634c-0.191-0.199-0.186-0.516,0.014-0.707
				c0.198-0.192,0.515-0.186,0.707,0.013l5.432,5.634c0.191,0.199,0.186,0.516-0.014,0.707C20.5,9.729,20.375,9.775,20.25,9.775z"
            />
            <path
                d="M8.25,21.774c-0.13,0-0.259-0.05-0.357-0.15L2.44,16.052c-0.193-0.197-0.189-0.514,0.008-0.707
				c0.198-0.191,0.515-0.189,0.707,0.008l5.453,5.572C8.8,21.122,8.797,21.438,8.6,21.632C8.502,21.727,8.376,21.774,8.25,21.774z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 29 21"
export const GraduationHat = props => (
    <Svgicon {...props}>
        <g>
            <g>
                <path
                    d="M18.056,20.827h-7.111c-2.838,0-5.148-2.306-5.148-5.139V9.216l8.604,3.85c0-0.001,0-0.001,0-0.001
					c0.01,0,0.201,0,0.22-0.008l8.584-3.841v6.473C23.204,18.521,20.895,20.827,18.056,20.827z M6.796,10.759v4.93
					c0,2.282,1.861,4.139,4.148,4.139h7.111c2.287,0,4.148-1.856,4.148-4.139v-4.93l-7.196,3.219c-0.33,0.132-0.707,0.124-0.996,0.009
					L6.796,10.759z"
                />
            </g>
            <g>
                <rect x="25.851" y="8.395" width="1" height="6.122" />
            </g>
            <g>
                <path
                    d="M14.5,14.074c-0.169,0-0.336-0.029-0.481-0.084L0.592,7.982C0.224,7.808,0,7.479,0,7.118c0-0.361,0.224-0.69,0.584-0.859
					l13.409-5.979c0.273-0.141,0.719-0.151,1.038,0.011l13.377,5.964C28.776,6.429,29,6.757,29,7.118c0,0.36-0.223,0.69-0.583,0.859
					l-13.409,6.001C14.836,14.045,14.669,14.074,14.5,14.074z M1.117,7.116l13.284,5.95c0.012,0.002,0.161,0.014,0.226-0.012
					l13.258-5.934L14.5,1.167l-0.101,0.026L1.117,7.116z"
                />
            </g>
            <path
                d="M27.565,15.174c0,0.808-0.405,1.212-1.215,1.212c-0.811,0-1.216-0.404-1.216-1.212c0-0.809,0.405-1.213,1.216-1.213
				C27.16,13.961,27.565,14.365,27.565,15.174z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 17 21"
export const Note = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M13.667,6.865h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5S13.943,6.865,13.667,6.865z
				"
            />
            <path
                d="M11.667,10.865h-8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5S11.943,10.865,11.667,10.865
				z"
            />
            <path d="M9.667,14.865h-6c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5S9.943,14.865,9.667,14.865z" />
            <path
                d="M15,21H2c-1.271,0-2-0.729-2-2V2c0-1.271,0.729-2,2-2h13c1.271,0,2,0.729,2,2v17C17,20.271,16.271,21,15,21z M2,1
				C1.28,1,1,1.28,1,2v17c0,0.72,0.28,1,1,1h13c0.72,0,1-0.28,1-1V2c0-0.72-0.28-1-1-1H2z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 23 22"
export const Monitor = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M21.332,17.06H1.677c-0.804,0-1.483-0.7-1.483-1.529V1.734c0-0.829,0.679-1.529,1.483-1.529h19.655
				c0.804,0,1.482,0.7,1.482,1.529V15.53C22.814,16.359,22.136,17.06,21.332,17.06z M1.677,1.205c-0.248,0-0.483,0.257-0.483,0.529
				V15.53c0,0.272,0.235,0.529,0.483,0.529h19.655c0.248,0,0.482-0.257,0.482-0.529V1.734c0-0.272-0.234-0.529-0.482-0.529H1.677z"
            />
            <path
                d="M17.072,21.795H5.936c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h11.137c0.276,0,0.5,0.224,0.5,0.5
				S17.349,21.795,17.072,21.795z"
            />
            <path
                d="M11.5,21.701c-0.276,0-0.5-0.224-0.5-0.5v-4.163c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v4.163
				C12,21.478,11.776,21.701,11.5,21.701z"
            />
            <path
                d="M21.684,13.046H0.686c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h20.998c0.276,0,0.5,0.224,0.5,0.5
				S21.96,13.046,21.684,13.046z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 19 23"
export const Trash = props => (
    <Svgicon {...props}>
        <g>
            <path d="M18.5,5.041h-18c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h18c0.276,0,0.5,0.224,0.5,0.5S18.776,5.041,18.5,5.041z" />
            <path
                d="M15.017,22.701H4.352c-1.396,0-2.531-1.176-2.531-2.622V4.694c0-0.276,0.224-0.5,0.5-0.5h14.728c0.276,0,0.5,0.224,0.5,0.5
				v15.385C17.548,21.525,16.412,22.701,15.017,22.701z M2.82,5.194v14.885c0,0.895,0.687,1.622,1.531,1.622h10.665
				c0.845,0,1.531-0.728,1.531-1.622V5.194H2.82z"
            />
            <path
                d="M9.5,19.784c-0.276,0-0.5-0.224-0.5-0.5V7.617c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v11.667
				C10,19.561,9.776,19.784,9.5,19.784z"
            />
            <path
                d="M13.039,18.724c-0.276,0-0.5-0.224-0.5-0.5V8.678c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v9.546
				C13.539,18.5,13.315,18.724,13.039,18.724z"
            />
            <path
                d="M5.961,18.724c-0.276,0-0.5-0.224-0.5-0.5V8.678c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v9.546
				C6.461,18.5,6.237,18.724,5.961,18.724z"
            />
            <path
                d="M13.637,5.194H5.455c-0.276,0-0.5-0.224-0.5-0.5V2.746c0-1.395,0.875-2.447,2.034-2.447h5.113
				c1.122,0,2.034,1.098,2.034,2.447v1.948C14.137,4.97,13.913,5.194,13.637,5.194z M5.955,4.194h7.182V2.746
				c0-0.866-0.535-1.447-1.034-1.447H6.989c-0.58,0-1.034,0.636-1.034,1.447V4.194z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 20 23.402"
export const TrashThick = props => (
    <Svgicon {...props}>
        <g>
            <path d="M19,5.742H1c-0.552,0-1-0.448-1-1s0.448-1,1-1h18c0.553,0,1,0.448,1,1S19.553,5.742,19,5.742z" />
            <path
                d="M15.52,23.402H4.852c-1.714-0.044-3.062-1.452-3.031-3.141V4.894c0-0.552,0.448-1,1-1h14.727c0.553,0,1,0.448,1,1v15.385
				c0.031,1.667-1.314,3.075-3,3.122C15.538,23.402,15.528,23.402,15.52,23.402z M3.82,5.895V20.28
				c-0.011,0.612,0.463,1.107,1.057,1.122h10.627c0.587-0.022,1.055-0.515,1.043-1.104V5.894L3.82,5.895L3.82,5.895z"
            />
            <path
                d="M10,20.485c-0.552,0-1-0.447-1-1V7.818c0-0.552,0.448-1,1-1c0.553,0,1,0.448,1,1v11.667C11,20.038,10.553,20.485,10,20.485z
				"
            />
            <path
                d="M13.539,19.425c-0.553,0-1-0.447-1-1V8.879c0-0.552,0.447-1,1-1s1,0.448,1,1v9.546
				C14.539,18.978,14.092,19.425,13.539,19.425z"
            />
            <path d="M6.461,19.425c-0.552,0-1-0.447-1-1V8.879c0-0.552,0.448-1,1-1s1,0.448,1,1v9.546C7.461,18.978,7.013,19.425,6.461,19.425z" />
            <path
                d="M14.137,5.9H5.955c-0.552,0-1-0.448-1-1V2.987c-0.061-0.732,0.146-1.412,0.583-1.96C5.994,0.453,6.646,0.09,7.375,0.006
				C7.413,0.002,7.451,0,7.489,0h5.114c0.045,0,0.089,0.003,0.134,0.009c1.479,0.199,2.523,1.504,2.4,2.985V4.9
				C15.137,5.452,14.689,5.9,14.137,5.9z M6.955,3.9h6.182V2.948c0-0.034,0.002-0.068,0.005-0.103c0.042-0.405-0.228-0.766-0.619-0.846
				H7.558C7.378,2.031,7.219,2.125,7.104,2.27C6.98,2.426,6.924,2.621,6.947,2.819c0.004,0.038,0.008,0.09,0.008,0.128V3.9z"
            />
        </g>
    </Svgicon>
);

// viewBox="0 0 19 19"
export const Search = props => (
    <Svgicon {...props}>
        <g>
            <g>
                <path
                    d="M7.53,14.561c-1.932,0-3.604-0.693-4.971-2.06C1.193,11.134,0.5,9.462,0.5,7.53s0.693-3.604,2.059-4.971
					C3.926,1.193,5.599,0.5,7.53,0.5s3.604,0.693,4.971,2.059c1.366,1.367,2.06,3.039,2.06,4.971c0,1.932-0.693,3.604-2.06,4.971
					S9.462,14.561,7.53,14.561z M7.53,2.5c-1.402,0-2.565,0.482-3.557,1.473C2.982,4.965,2.5,6.128,2.5,7.53
					c0,1.401,0.482,2.565,1.473,3.557c0.992,0.991,2.155,1.474,3.557,1.474c1.401,0,2.564-0.482,3.557-1.474
					c0.991-0.991,1.474-2.155,1.474-3.557c0-1.402-0.482-2.565-1.474-3.557C10.095,2.982,8.932,2.5,7.53,2.5z"
                />
            </g>
            <g>
                <path
                    d="M17.5,18.5c-0.256,0-0.512-0.098-0.707-0.293l-4.021-4.02c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0
					l4.021,4.02c0.391,0.391,0.391,1.023,0,1.414C18.012,18.402,17.756,18.5,17.5,18.5z"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 18 17"
export const Refresh = props => (
    <Svgicon {...props}>
        <g>
            <g>
                <path
                    d="M8.451,17C3.791,17,0,13.187,0,8.5C0,3.813,3.791,0,8.451,0s8.451,3.813,8.451,8.5c0,2.088-0.764,4.063-2.209,5.712
					c-0.364,0.416-0.997,0.455-1.411,0.093c-0.415-0.364-0.457-0.996-0.093-1.411c0.781-0.892,1.713-2.378,1.713-4.394
					c0-3.523-2.954-6.5-6.451-6.5S2,4.977,2,8.5S4.954,15,8.451,15c0.553,0,1,0.447,1,1S9.004,17,8.451,17z"
                />
            </g>
            <g>
                <path d="M17,15.974h-5.549v-5.658c0-0.553,0.447-1,1-1s1,0.447,1,1v3.658H17c0.553,0,1,0.447,1,1S17.553,15.974,17,15.974z" />
            </g>
            <g>
                <rect
                    x="11.814"
                    y="13.114"
                    transform="matrix(0.5881 0.8088 -0.8088 0.5881 16.7972 -5.0064)"
                    width="2.999"
                    height="1.746"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const StarStroke = props => (
    <Svgicon {...props}>
        <g>
            <polygon
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="10.5,0.5
				13.591,7.084 20.5,8.14 15.5,13.274 16.681,20.5 10.5,17.084 4.319,20.5 5.5,13.274 0.5,8.14 7.41,7.084 "
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 16 16"
export const ArrowLeft = props => (
    <Svgicon {...props}>
        <g>
            <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                points="16,7 3.8,7 9.4,1.4 8,0 0,8 8,16 9.4,14.6 3.8,9 16,9 "
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 16 16"
export const ArrowRight = props => (
    <Svgicon {...props}>
        <g>
            <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                points="0,9 12.2,9 6.6,14.6 8,16 16,8 8,0 6.6,1.4 12.2,7 0,7 "
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 14 14"
export const Close = props => (
    <Svgicon {...props}>
        <g>
            <rect
                x="-1.404"
                y="6"
                transform="matrix(0.7071 0.7071 -0.7071 0.7071 7 -2.8995)"
                width="16.808"
                height="2"
            />
        </g>
        <g>
            <rect
                x="6"
                y="-1.404"
                transform="matrix(0.7071 0.7071 -0.7071 0.7071 7 -2.8995)"
                width="2"
                height="16.808"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 38 38"
export const TrashDelete = props => (
    <Svgicon {...props}>
        <g>
            <g>
                <path
                    d="M27.625,12.242h-18c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h18c0.276,0,0.5,0.224,0.5,0.5
					S27.901,12.242,27.625,12.242z"
                />
            </g>
            <g>
                <path
                    d="M24.141,29.902H13.477c-1.396,0-2.531-1.176-2.531-2.622V11.895c0-0.276,0.224-0.5,0.5-0.5h14.728
					c0.276,0,0.5,0.224,0.5,0.5V27.28C26.673,28.727,25.537,29.902,24.141,29.902z M11.945,12.395V27.28
					c0,0.895,0.687,1.622,1.531,1.622h10.664c0.845,0,1.532-0.728,1.532-1.622V12.395H11.945z"
                />
            </g>
            <g>
                <path
                    d="M18.625,26.985c-0.276,0-0.5-0.224-0.5-0.5V14.818c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v11.667
					C19.125,26.762,18.901,26.985,18.625,26.985z"
                />
            </g>
            <g>
                <path
                    d="M22.164,25.925c-0.276,0-0.5-0.224-0.5-0.5v-9.546c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v9.546
					C22.664,25.701,22.44,25.925,22.164,25.925z"
                />
            </g>
            <g>
                <path
                    d="M15.086,25.925c-0.276,0-0.5-0.224-0.5-0.5v-9.546c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v9.546
					C15.586,25.701,15.362,25.925,15.086,25.925z"
                />
            </g>
            <g>
                <path
                    d="M22.762,12.395H14.58c-0.276,0-0.5-0.224-0.5-0.5V9.948c0-1.396,0.875-2.448,2.034-2.448h5.113
					c1.122,0,2.034,1.098,2.034,2.448v1.947C23.262,12.171,23.038,12.395,22.762,12.395z M15.08,11.395h7.182V9.948
					c0-0.866-0.535-1.448-1.034-1.448h-5.113c-0.58,0-1.034,0.636-1.034,1.448V11.395z"
                />
            </g>
            <g>
                <path
                    d="M19,38c-2.507,0-4.953-0.486-7.271-1.446c-2.316-0.959-4.391-2.345-6.164-4.119c-1.772-1.771-3.158-3.846-4.119-6.163
					C0.487,23.955,0,21.509,0,19c0-2.508,0.487-4.955,1.446-7.271c0.96-2.317,2.346-4.391,4.119-6.164s3.847-3.159,6.164-4.119
					c4.634-1.919,9.91-1.918,14.542,0c2.317,0.96,4.392,2.346,6.163,4.119c1.774,1.773,3.16,3.848,4.119,6.164
					C37.514,14.047,38,16.493,38,19s-0.486,4.953-1.446,7.271c-0.96,2.317-2.346,4.391-4.119,6.163
					c-1.772,1.773-3.846,3.159-6.163,4.119C23.953,37.514,21.507,38,19,38z M19,1c-2.376,0-4.693,0.461-6.888,1.37
					c-2.195,0.91-4.16,2.222-5.84,3.902S3.28,9.917,2.37,12.112C1.461,14.307,1,16.624,1,19s0.461,4.694,1.37,6.889
					c0.91,2.195,2.223,4.16,3.902,5.839c1.68,1.681,3.645,2.993,5.84,3.902c4.392,1.818,9.386,1.818,13.777,0
					c2.195-0.909,4.159-2.223,5.839-3.902s2.993-3.644,3.902-5.839S37,21.375,37,19s-0.461-4.692-1.37-6.888
					c-0.909-2.195-2.222-4.16-3.902-5.84c-1.679-1.679-3.644-2.992-5.839-3.902C23.694,1.461,21.376,1,19,1z"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 38 38"
export const Forward = props => (
    <Svgicon {...props}>
        <g>
            <g>
                <path
                    d="M7.128,28.884c-0.19,0-0.368-0.085-0.488-0.232c-0.119-0.146-0.165-0.337-0.126-0.524
					c1.303-5.891,4.918-13.2,14.735-14.804V9.515c0-0.56,0.454-1.015,1.013-1.015c0.268,0,0.519,0.106,0.708,0.297l8.237,8.343
					c0.395,0.4,0.394,1.025,0,1.423l-8.246,8.351c-0.195,0.192-0.445,0.296-0.709,0.296l0,0c-0.558,0-1.011-0.454-1.011-1.012v-3.874
					C15.11,22.43,10.775,24.44,7.623,28.635C7.511,28.79,7.329,28.884,7.128,28.884z M22.259,9.5l-0.01,4.142
					c0,0.31-0.221,0.57-0.525,0.619c-8.616,1.281-12.281,7.172-13.82,12.465c3.316-3.654,7.718-5.378,13.712-5.405
					c0.346,0,0.626,0.28,0.626,0.625v4.253l8.255-8.338L22.259,9.5z"
                />
            </g>
            <g>
                <path
                    d="M19,38c-2.504,0-4.951-0.486-7.271-1.446c-2.315-0.957-4.389-2.343-6.165-4.119c-1.771-1.77-3.157-3.843-4.118-6.163
					C0.487,23.955,0,21.509,0,19c0-2.509,0.487-4.956,1.446-7.271c0.96-2.317,2.345-4.391,4.118-6.164s3.847-3.158,6.164-4.118
					c4.631-1.919,9.909-1.919,14.543,0c2.32,0.961,4.394,2.347,6.163,4.118c1.772,1.772,3.158,3.846,4.119,6.164
					C37.514,14.044,38,16.491,38,19c0,2.504-0.486,4.95-1.446,7.271c-0.959,2.317-2.345,4.391-4.119,6.163
					c-1.769,1.771-3.843,3.157-6.163,4.119C23.95,37.514,21.504,38,19,38z M19,1c-2.377,0-4.695,0.461-6.889,1.37
					c-2.196,0.909-4.16,2.222-5.84,3.901c-1.679,1.68-2.992,3.644-3.901,5.84C1.461,14.305,1,16.623,1,19s0.461,4.694,1.37,6.889
					c0.911,2.198,2.224,4.162,3.901,5.839c1.682,1.683,3.647,2.995,5.839,3.902c4.397,1.818,9.378,1.818,13.778,0
					c2.198-0.911,4.163-2.225,5.839-3.902c1.681-1.68,2.994-3.644,3.902-5.839C36.539,23.689,37,21.372,37,19
					c0-2.377-0.461-4.695-1.37-6.889c-0.91-2.196-2.223-4.161-3.902-5.84c-1.677-1.678-3.641-2.99-5.839-3.901
					C23.694,1.461,21.377,1,19,1z"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21"
export const Share = props => (
    <Svgicon {...props}>
        <path
            d="M14.763,16.5H6.168c0.025,0.338-0.008,0.678-0.106,1h8.686c-0.022-0.299,0.004-0.599,0.078-0.889
            C14.809,16.572,14.779,16.54,14.763,16.5z"
        />
        <path d="M8.962,6.495l-4.601,7.87c0.362,0.067,0.672,0.204,0.926,0.397l4.681-8.007C9.581,6.739,9.246,6.646,8.962,6.495z" />
        <path
            d="M10.5,7.5C8.57,7.5,7,5.93,7,4s1.57-3.5,3.5-3.5S14,2.07,14,4S12.43,7.5,10.5,7.5z M10.5,1.5C9.122,1.5,8,2.622,8,4
            s1.122,2.5,2.5,2.5C11.879,6.5,13,5.378,13,4S11.879,1.5,10.5,1.5z"
        />
        <path
            d="M3.5,20.5C1.57,20.5,0,18.93,0,17s1.57-3.5,3.5-3.5S7,15.07,7,17S5.43,20.5,3.5,20.5z M3.5,14.5C2.122,14.5,1,15.621,1,17
            s1.122,2.5,2.5,2.5S6,18.379,6,17S4.878,14.5,3.5,14.5z"
        />
        <path
            d="M17.5,20.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5S21,15.07,21,17S19.43,20.5,17.5,20.5z M17.5,14.5
            c-1.379,0-2.5,1.121-2.5,2.5s1.121,2.5,2.5,2.5S20,18.379,20,17S18.879,14.5,17.5,14.5z"
        />
    </Svgicon>
);
// viewBox="0 0 22 22"
export const Eye = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M11,18.5c-4.612,0-8.818-2.943-10.468-7.324L0.466,11l0.066-0.176C2.182,6.443,6.388,3.5,11,3.5
				c4.618,0,8.825,2.943,10.468,7.324L21.534,11l-0.066,0.176C19.818,15.557,15.611,18.5,11,18.5z M1.536,11
				c1.549,3.896,5.328,6.5,9.464,6.5s7.915-2.604,9.464-6.5C18.921,7.104,15.142,4.5,11,4.5C6.864,4.5,3.085,7.104,1.536,11z"
            />
        </g>
        <g>
            <path
                d="M11.131,14.368c-2.124,0-3.294-1.196-3.294-3.368c0-2.172,1.17-3.368,3.294-3.368S14.425,8.829,14.425,11
				C14.425,13.172,13.255,14.368,11.131,14.368z M11.131,8.633c-1.586,0-2.294,0.73-2.294,2.368c0,1.637,0.708,2.368,2.294,2.368
				c1.587,0,2.294-0.73,2.294-2.368C13.425,9.363,12.718,8.633,11.131,8.633z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 22 22"
export const Click = props => (
    <Svgicon {...props}>
        <g>
            <g>
                <path
                    d="M18.509,21.75c-0.376,0-0.714-0.139-0.979-0.402l-3.083-3.084l-1.066,1.852c-0.243,0.428-0.699,0.695-1.189,0.695
					l-0.116-0.006c-0.524-0.043-0.975-0.373-1.174-0.861L6.103,7.918c-0.203-0.52-0.08-1.104,0.31-1.494
					c0.387-0.387,0.981-0.51,1.49-0.311l12.03,4.799c0.493,0.202,0.823,0.652,0.867,1.176c0.045,0.535-0.22,1.037-0.689,1.307
					l-1.852,1.066l3.081,3.082c0.272,0.271,0.418,0.631,0.41,1.008c-0.008,0.373-0.164,0.719-0.441,0.975l-1.819,1.82
					C19.2,21.611,18.852,21.75,18.509,21.75z M14.123,15.818l4.387,4.387l1.712-1.713l-4.408-4.355l3.338-1.92L7.593,7.604
					l4.609,11.552L14.123,15.818z"
                />
            </g>
            <g>
                <path
                    d="M9.212,5.524c-0.192,0-0.384-0.073-0.53-0.22c-0.293-0.292-0.293-0.768,0-1.061l2.198-2.199
					c0.293-0.293,0.768-0.292,1.061,0c0.293,0.293,0.293,0.768,0,1.061L9.742,5.305C9.596,5.451,9.404,5.524,9.212,5.524z"
                />
            </g>
            <g>
                <path
                    d="M2.575,12.174c-0.191,0-0.383-0.072-0.529-0.219c-0.294-0.291-0.295-0.766-0.003-1.06l2.199-2.21
					c0.292-0.294,0.768-0.295,1.061-0.003c0.294,0.292,0.295,0.767,0.003,1.061l-2.199,2.21C2.96,12.102,2.767,12.174,2.575,12.174z"
                />
            </g>
            <g>
                <path
                    d="M5.07,5.821c-0.192,0-0.384-0.073-0.53-0.22L2.341,3.402c-0.293-0.293-0.293-0.768,0-1.061s0.768-0.293,1.061,0L5.6,4.541
					c0.293,0.293,0.293,0.768,0,1.061C5.454,5.748,5.262,5.821,5.07,5.821z"
                />
            </g>
            <g>
                <path
                    d="M7.197,4.87c-0.414,0-0.75-0.336-0.75-0.75V1c0-0.414,0.336-0.75,0.75-0.75S7.947,0.586,7.947,1v3.12
					C7.947,4.534,7.611,4.87,7.197,4.87z"
                />
            </g>
            <g>
                <path
                    d="M4.119,7.949H1c-0.414,0-0.75-0.336-0.75-0.75S0.586,6.449,1,6.449h3.119c0.414,0,0.75,0.336,0.75,0.75
					S4.533,7.949,4.119,7.949z"
                />
            </g>
        </g>
    </Svgicon>
);
// viewBox="0 0 22 22"
export const User2 = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M11,20.75c-4.091,0-8.219-1.105-8.219-3.574c0-4.367,3.687-7.92,8.219-7.92c4.532,0,8.219,3.553,8.219,7.92
				C19.219,19.645,15.091,20.75,11,20.75z M11,10.255c-3.98,0-7.219,3.104-7.219,6.92c0,1.469,3.104,2.574,7.219,2.574
				s7.219-1.105,7.219-2.574C18.219,13.359,14.98,10.255,11,10.255z"
            />
        </g>
        <path
            fill="#FFFFFF"
            d="M15.903,6.444c0,1.296-0.479,2.403-1.437,3.32S12.354,11.139,11,11.139c-1.354,0-2.51-0.458-3.467-1.375
			S6.097,7.74,6.097,6.444c0-1.296,0.479-2.403,1.436-3.319S9.646,1.75,11,1.75c1.354,0,2.51,0.458,3.467,1.375
			S15.903,5.148,15.903,6.444z"
        />
        <g>
            <path
                d="M11,11.639c-1.48,0-2.764-0.51-3.813-1.514c-1.055-1.01-1.59-2.249-1.59-3.681c0-1.432,0.535-2.67,1.59-3.681
				C8.236,1.759,9.52,1.25,11,1.25s2.764,0.509,3.813,1.514c1.056,1.011,1.591,2.25,1.591,3.681c0,1.432-0.535,2.67-1.591,3.681
				C13.764,11.129,12.48,11.639,11,11.639z M11,2.25c-1.231,0-2.252,0.404-3.122,1.236C7.016,4.311,6.597,5.279,6.597,6.444
				c0,1.166,0.419,2.133,1.282,2.958c0.869,0.832,1.89,1.236,3.122,1.236s2.252-0.404,3.121-1.236
				c0.862-0.826,1.282-1.794,1.282-2.958c0-1.164-0.42-2.132-1.282-2.958C13.252,2.654,12.231,2.25,11,2.25z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 12 12"
export const Status = props => (
    <Svgicon {...props}>
        <g>
            <rect y="1.6" width="2" height="10.4" />
        </g>
        <g>
            <rect x="5" width="2" height="12" />
        </g>
        <g>
            <rect x="10" y="4.8" width="2" height="7.2" />
        </g>
    </Svgicon>
);
// viewBox="0 0 36 36"
export const Play = props => (
    <Svgicon {...props}>
        <g>
            <path
                fill="none"
                stroke="#1284C6"
                strokeWidth="2"
                d="M35,18c0,2.254-0.432,4.423-1.294,6.506
				c-0.863,2.082-2.091,3.921-3.686,5.515c-1.594,1.595-3.433,2.822-5.515,3.686C22.423,34.568,20.254,35,18,35
				c-2.254,0-4.423-0.432-6.506-1.294c-2.083-0.863-3.921-2.091-5.515-3.686c-1.594-1.594-2.822-3.433-3.685-5.515
				C1.431,22.423,1,20.254,1,18c0-2.254,0.431-4.423,1.294-6.506c0.863-2.083,2.091-3.921,3.685-5.515
				c1.594-1.594,3.433-2.822,5.515-3.685C13.577,1.431,15.746,1,18,1c2.254,0,4.423,0.431,6.506,1.294
				c2.082,0.863,3.921,2.091,5.515,3.685c1.595,1.594,2.822,3.433,3.686,5.515C34.568,13.577,35,15.746,35,18z"
            />
            <path
                fill="none"
                stroke="#66B44E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
				M25.115,16.576l-10.181-6.487c-1.169-0.754-2.655,0.188-2.655,1.696v13.002c0,1.507,1.486,2.449,2.655,1.695l10.181-6.487
				C26.309,19.214,26.309,17.33,25.115,16.576z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 36 29.217"
export const Code = props => (
    <Svgicon {...props}>
        <g>
            <polyline
                fill="none"
                stroke="#70BF58"
                strokeWidth="2"
                strokeMiterlimit="10"
                points="22.753,9.766 27.329,14.526
				22.753,19.287 	"
            />
            <polyline
                fill="none"
                stroke="#70BF58"
                strokeWidth="2"
                strokeMiterlimit="10"
                points="12.954,9.766 8.378,14.526 12.954,19.287
					"
            />
            <line
                fill="none"
                stroke="#1284C6"
                strokeWidth="2"
                strokeMiterlimit="10"
                x1="19.083"
                y1="10.37"
                x2="16.545"
                y2="19.305"
            />
            <path
                fill="none"
                stroke="#1284C6"
                strokeWidth="2"
                d="M1,23.217V6c0-1.381,0.488-2.559,1.464-3.536C3.441,1.488,4.619,1,6,1h24
				c1.381,0,2.559,0.488,3.535,1.464C34.512,3.441,35,4.619,35,6v17.217c0,1.381-0.488,2.56-1.465,3.536S31.381,28.217,30,28.217H6
				c-1.381,0-2.559-0.487-3.536-1.464C1.488,25.776,1,24.598,1,23.217z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 29 36"
export const Files = props => (
    <Svgicon {...props}>
        <g>
            <path
                fill="none"
                stroke="#1284C6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
				M20.417,34.365H4.582c-1.935,0-3.583-1.591-3.583-3.616V8.981c0-1.953,1.577-3.616,3.583-3.616h15.835
				c1.935,0,3.582,1.591,3.582,3.616v21.768C23.999,32.702,22.352,34.365,20.417,34.365z"
            />
            <line
                fill="none"
                stroke="#66B44E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="7.188"
                y1="14.79"
                x2="17.979"
                y2="14.79"
            />
            <line
                fill="none"
                stroke="#66B44E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="7.188"
                y1="20.546"
                x2="17.979"
                y2="20.546"
            />
            <line
                fill="none"
                stroke="#66B44E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="7.188"
                y1="26.302"
                x2="17.979"
                y2="26.302"
            />
            <path
                fill="none"
                stroke="#1284C6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
				M8.001,1h16.311c1.992,0,3.689,1.567,3.689,3.562V26"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 38 38"
export const AndroidSolid = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M21.783,35.498c1.484,1.021,3.246-0.186,3.246-1.716v-4.871h0.928c0.789,0,1.393-0.649,1.393-1.392V13.604H10.65V27.52
				c0,0.789,0.649,1.392,1.392,1.392h1.855v4.731c0,0.742,0.325,1.438,0.928,1.855c1.484,1.021,3.247-0.186,3.247-1.716v-4.871h2.783
				v4.731C20.855,34.385,21.18,35.081,21.783,35.498z"
            />
            <path
                d="M33.379,25.015v-9.509c0-0.834-0.416-1.623-1.113-1.994c-1.438-0.835-3.061,0.417-3.061,1.855V25.2
				c0,1.531,1.762,2.783,3.246,1.717C33.055,26.453,33.379,25.711,33.379,25.015z"
            />
            <path
                d="M8.795,25.015v-9.509c0-0.834-0.417-1.623-1.113-1.994c-1.438-0.835-3.062,0.417-3.062,1.855V25.2
				c0,1.531,1.763,2.783,3.247,1.717C8.471,26.453,8.795,25.711,8.795,25.015z"
            />
            <path
                d="M22.85,9.43c-0.557,0-1.02-0.464-1.02-1.067c0-0.557,0.463-1.067,1.02-1.067s1.021,0.464,1.021,1.067
				C23.916,8.966,23.453,9.43,22.85,9.43z M15.938,9.43c-0.557,0-1.021-0.464-1.021-1.067c0-0.557,0.464-1.067,1.021-1.067
				s1.021,0.464,1.021,1.067S16.495,9.43,15.938,9.43z M25.68,3.26c0.232-0.232,0.277-0.696,0.047-0.928
				c-0.279-0.278-0.65-0.278-0.928,0l-1.764,1.763l-0.092,0.046c-1.066-0.51-2.227-0.789-3.525-0.789l-0.047,0.046h-0.047
				c-1.298,0-2.458,0.278-3.525,0.789l-0.046-0.093l-1.762-1.763c-0.278-0.278-0.649-0.278-0.928,0
				c-0.232,0.232-0.186,0.696,0.046,0.928l1.669,1.67c-0.556,0.371-1.02,0.835-1.484,1.345c-1.021,1.252-1.67,2.83-1.763,4.592v0.046
				c0,0.139,0,0.232,0,0.371h15.771c0-0.139,0-0.232,0-0.371v-0.046c-0.092-1.763-0.742-3.386-1.809-4.592
				c-0.418-0.51-0.928-0.974-1.484-1.345L25.68,3.26z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 38 38"
export const AppleSolid = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M5.96,14.896C3.03,19.999,4.899,27.729,8.133,32.429c1.617,2.324,3.284,4.447,5.508,4.447c0.05,0,0.101,0,0.151,0
				c1.061-0.051,1.819-0.354,2.577-0.708c0.808-0.354,1.667-0.707,3.032-0.707c1.263,0,2.071,0.354,2.88,0.707
				c0.758,0.354,1.566,0.708,2.779,0.657c2.526-0.051,4.093-2.324,5.457-4.346c1.415-2.071,2.173-4.144,2.375-4.901v-0.051
				c0.051-0.151,0-0.303-0.151-0.354c-0.455-0.202-4.346-1.92-4.396-6.67c-0.051-3.84,2.931-5.861,3.537-6.265h0.051
				c0.051-0.051,0.101-0.101,0.101-0.202c0-0.051,0-0.152-0.05-0.202c-2.072-2.981-5.205-3.436-6.468-3.537
				c-0.202,0-0.354-0.051-0.557-0.051c-1.465,0-2.931,0.556-4.042,1.011c-0.809,0.303-1.465,0.606-1.92,0.606
				c-0.505,0-1.213-0.253-2.021-0.606c-1.061-0.404-2.273-0.91-3.537-0.91c-0.051,0-0.051,0-0.101,0
				C10.306,10.5,7.527,12.217,5.96,14.896z"
            />
            <path
                d="M25.516,1c-1.819,0.05-3.941,1.162-5.256,2.678c-1.111,1.263-2.172,3.385-1.869,5.507c0,0.152,0.101,0.253,0.253,0.253
				c0.101,0,0.252,0,0.354,0c1.769,0,3.638-0.96,4.952-2.526c1.364-1.667,2.071-3.79,1.818-5.66C25.768,1.101,25.667,1,25.516,1z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 38 38"
export const WebSolid = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M35.61,35.775H2.39c-1.16,0-2.109-1.258-2.109-2.797V5.021c0-1.538,0.949-2.796,2.109-2.796H35.61
				c1.16,0,2.109,1.258,2.109,2.796v27.958C37.72,34.518,36.771,35.775,35.61,35.775z"
            />
            <line
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="2"
                x1="0.28"
                y1="12.759"
                x2="37.648"
                y2="12.759"
            />
            <path
                fill="#FFFFFF"
                d="M5.917,8.614c-0.827,0-1.503-0.676-1.503-1.503c0-0.827,0.676-1.503,1.503-1.503
				c0.827,0,1.503,0.676,1.503,1.503C7.42,7.938,6.744,8.614,5.917,8.614z"
            />
            <path
                fill="#FFFFFF"
                d="M10.427,8.614c-0.827,0-1.503-0.676-1.503-1.503c0-0.827,0.676-1.503,1.503-1.503
				c0.827,0,1.503,0.676,1.503,1.503C11.93,7.938,11.254,8.614,10.427,8.614z"
            />
            <path
                fill="#FFFFFF"
                d="M14.937,8.614c-0.827,0-1.503-0.676-1.503-1.503c0-0.827,0.676-1.503,1.503-1.503
				c0.827,0,1.503,0.676,1.503,1.503C16.44,7.938,15.764,8.614,14.937,8.614z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 20 15"
export const WebSite = props => (
    <Svgicon {...props}>
        <path
            d="M16.853,14.5H3.148c-1.103,0-2-0.897-2-2v-10c0-1.103,0.897-2,2-2h13.705c1.103,0,2,0.897,2,2v10
            C18.853,13.603,17.956,14.5,16.853,14.5z M3.148,1.5c-0.551,0-1,0.449-1,1v10c0,0.552,0.449,1,1,1h13.705c0.552,0,1-0.448,1-1v-10
            c0-0.551-0.448-1-1-1H3.148z"
        />
        <rect x="1.59" y="5.5" width="16.82" height="1" />
        <circle id="Ellipse_1059" cx="4.804" cy="3.5" r="1" />
        <circle id="Ellipse_1060" cx="7.459" cy="3.5" r="1" />
        <circle id="Ellipse_1061" cx="10.115" cy="3.5" r="1" />
    </Svgicon>
);
// viewBox="0 0 20 15"
export const WebPush = props => (
    <Svgicon {...props}>
        <rect x="1.75" y="2.5" width="13" height="1" />
        <circle id="Ellipse_1062" cx="17.25" cy="2.5" r="1" />
        <rect x="1.75" y="7.5" width="13" height="1" />
        <circle id="Ellipse_1063" cx="17.25" cy="7.5" r="1" />
        <rect x="1.75" y="12.5" width="13" height="1" />
        <circle id="Ellipse_1064" cx="17.25" cy="12.5" r="1" />
    </Svgicon>
);
// viewBox="0 0 20 15"
export const Wallet = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M15.998,14.998H1.999C0.897,14.998,0,14.102,0,12.999V2c0-1.103,0.897-2,1.999-2h13.999
				c1.103,0,1.999,0.897,1.999,2v3.066h-4.136c-1.196,0-2.169,1.121-2.169,2.499c0.014,1.376,0.987,2.5,2.169,2.5h4.136v2.935
				C17.997,14.102,17.101,14.998,15.998,14.998z M1.999,1C1.448,1,1,1.448,1,2v11c0,0.551,0.448,0.999,0.999,0.999h13.999
				c0.551,0,0.999-0.448,0.999-0.999v-1.935h-3.136c-1.728,0-3.149-1.567-3.169-3.495c0-1.934,1.422-3.504,3.169-3.504h3.136V2
				c0-0.551-0.448-1-0.999-1H1.999z"
            />
            <path
                d="M18.525,11.063h-4.666c-1.746,0-3.167-1.57-3.167-3.5s1.421-3.5,3.167-3.5h4.666
				c0.644,0,1.167,0.561,1.167,1.25v4.5C19.692,10.503,19.169,11.063,18.525,11.063z M13.859,5.063c-1.195,0-2.167,1.122-2.167,2.5
				c0,1.379,0.972,2.5,2.167,2.5h4.666c0.079,0,0.167-0.106,0.167-0.25v-4.5c0-0.143-0.088-0.25-0.167-0.25H13.859z"
            />
            <path
                d="M18.525,11.063h-4.666c-1.746,0-3.167-1.57-3.167-3.5s1.421-3.5,3.167-3.5h4.666
				c0.644,0,1.167,0.561,1.167,1.25v4.5C19.692,10.503,19.169,11.063,18.525,11.063z M13.859,5.063c-1.195,0-2.167,1.122-2.167,2.5
				c0,1.379,0.972,2.5,2.167,2.5h4.666c0.079,0,0.167-0.106,0.167-0.25v-4.5c0-0.143-0.088-0.25-0.167-0.25H13.859z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 32 33"
export const Print = props => (
    <Svgicon {...props}>
        <g>
            <g>
                <path
                    d="M29,26H3c-1.935,0-3-1.065-3-3V11c0-1.935,1.065-3,3-3h26c1.935,0,3,1.065,3,3v12C32,24.935,30.935,26,29,26z M3,9
					c-1.383,0-2,0.617-2,2v12c0,1.383,0.617,2,2,2h26c1.383,0,2-0.617,2-2V11c0-1.383-0.617-2-2-2H3z"
                />
            </g>
            <g>
                <path d="M25,9H7V3c0-1.935,1.065-3,3-3h12c1.935,0,3,1.065,3,3V9z M8,8h16V3c0-1.383-0.617-2-2-2H10C8.617,1,8,1.617,8,3V8z" />
            </g>
            <path
                fill="#FFFFFF"
                d="M24.5,19.5V30c0,1.667-0.833,2.5-2.5,2.5H10c-1.667,0-2.5-0.833-2.5-2.5V19.5H24.5z"
            />
            <g>
                <path
                    d="M22,33H10c-1.935,0-3-1.065-3-3V19h18v11C25,31.935,23.935,33,22,33z M8,20v10c0,1.383,0.617,2,2,2h12c1.383,0,2-0.617,2-2
					V20H8z"
                />
            </g>
            <path d="M11,23.5c0-0.333,0.167-0.5,0.5-0.5h9c0.333,0,0.5,0.167,0.5,0.5S20.833,24,20.5,24h-9C11.167,24,11,23.833,11,23.5z" />
            <path d="M11,26.5c0-0.333,0.167-0.5,0.5-0.5h9c0.333,0,0.5,0.167,0.5,0.5S20.833,27,20.5,27h-9C11.167,27,11,26.833,11,26.5z" />
            <path d="M11,29.5c0-0.333,0.167-0.5,0.5-0.5h9c0.333,0,0.5,0.167,0.5,0.5S20.833,30,20.5,30h-9C11.167,30,11,29.833,11,29.5z" />
            <path d="M7,13.5c0,1-0.5,1.5-1.5,1.5S4,14.5,4,13.5S4.5,12,5.5,12S7,12.5,7,13.5z" />
        </g>
    </Svgicon>
);
// viewBox="0 0 18 18"
export const HamBtn = props => (
    <Svgicon {...props}>
        <g>
            <path d="M17,4H1C0.448,4,0,3.552,0,3s0.448-1,1-1h16c0.553,0,1,0.448,1,1S17.553,4,17,4z" />
            <path d="M17,10H1c-0.552,0-1-0.447-1-1c0-0.552,0.448-1,1-1h16c0.553,0,1,0.448,1,1C18,9.553,17.553,10,17,10z" />
            <path d="M17,16H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h16c0.553,0,1,0.447,1,1S17.553,16,17,16z" />
        </g>
    </Svgicon>
);
// viewBox="0 0 19 19"
export const Flag = props => (
    <Svgicon {...props}>
        <g>
            <path
                id="Path_3473"
                d="M16.664,11.318H3.081V1.5h13.583c0.276,0.005,0.496,0.232,0.492,0.508c-0.002,0.117-0.045,0.23-0.122,0.319
				l-3.482,4.082l3.482,4.082c0.186,0.196,0.178,0.506-0.018,0.693C16.921,11.274,16.794,11.322,16.664,11.318z"
            />
            <path
                d="M3.081,18.75c-0.69,0-1.25-0.56-1.25-1.25v-16c0-0.69,0.56-1.25,1.25-1.25s1.25,0.56,1.25,1.25v16
				C4.331,18.19,3.771,18.75,3.081,18.75z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21 21.161"
export const Filter = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M13.157,21.161l-5.314-1.502v-6.947L0,3.847V0h21v3.847l-7.843,8.865V21.161z M8.843,18.903l3.314,0.936v-7.506L20,3.468V1
	H1v2.468l7.843,8.865V18.903z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 22 22.321"
export const FilterThick = props => (
    <Svgicon {...props}>
        <g>
            <path
                d="M14.157,22.321l-6.314-1.783v-7.137L0,4.536V0h22v4.536l-7.843,8.865V22.321z M9.843,19.024l2.314,0.654v-7.035L20,3.778V2
	H2v1.778l7.843,8.865V19.024z"
            />
        </g>
    </Svgicon>
);
// viewBox="0 0 21.8 21.9"
export const PencilThick = props => (
    <Svgicon {...props}>
        <path
            d="M0.8,21.9c-0.2,0-0.4-0.1-0.5-0.2C0,21.5,0,21.2,0,21l1.7-6.7c0-0.1,0.1-0.2,0.2-0.3L15,0.9c1-0.9,2.6-0.9,3.5,0
			c0,0,0,0,0,0l2.5,2.5c0.5,0.4,0.7,1.1,0.8,1.7c0,0.7-0.2,1.3-0.7,1.8c0,0-0.1,0.1-0.1,0.1L8,20c-0.1,0.1-0.2,0.2-0.4,0.2l-6.7,1.7
			C0.9,21.9,0.8,21.9,0.8,21.9z M3.2,14.9l-1.4,5.3l5.3-1.3L20,5.9c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7l-2.5-2.5
			c-0.4-0.4-1.1-0.4-1.5,0L3.2,14.9z"
        />
        <path
            d="M18.4,9.3c-0.2,0-0.4-0.1-0.5-0.2L12.9,4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l4.9,5.1c0.3,0.3,0.3,0.8,0,1.1
			C18.7,9.3,18.5,9.3,18.4,9.3z"
        />
        <path
            d="M7.5,20.2c-0.2,0-0.4-0.1-0.5-0.2l-4.9-5c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l4.9,5c0.3,0.3,0.3,0.8,0,1.1
			C7.9,20.1,7.7,20.2,7.5,20.2z"
        />
    </Svgicon>
);
// viewBox="0 0 23 19"
export const Camera = props => (
    <Svgicon {...props}>
        <path
            d="M3.725,19.001c-0.956,0-1.88-0.367-2.592-1.04C0.411,17.32-0.01,16.37,0,15.374V6.231c-0.01-0.986,0.411-1.937,1.156-2.598
			C1.862,2.964,2.83,2.605,3.82,2.615h1.754L5.88,1.854c0.257-0.58,0.656-1.033,1.167-1.345C7.497,0.196,8.08,0.009,8.686,0H14.3
			c0.623,0.01,1.206,0.197,1.703,0.543c0.459,0.277,0.858,0.729,1.103,1.276l0.32,0.797H19.2c1.003-0.034,1.929,0.346,2.655,1.029
			C22.593,4.305,23.01,5.25,23,6.241v9.144c0.01,0.986-0.412,1.937-1.156,2.598C21.138,18.65,20.172,19,19.181,19H3.8
			C3.775,19.001,3.75,19.001,3.725,19.001z M3.761,4.615c-0.48,0-0.917,0.174-1.254,0.493C2.172,5.405,1.996,5.803,2,6.221v9.164
			c-0.004,0.427,0.172,0.825,0.484,1.102C2.853,16.835,3.31,16.997,3.78,17H19.2c0.488-0.005,0.952-0.169,1.292-0.492
			c0.336-0.298,0.512-0.696,0.508-1.113V6.231c0.004-0.427-0.172-0.825-0.484-1.101l-0.023-0.022
			c-0.345-0.326-0.763-0.521-1.272-0.491h-3.146L15.265,2.6c-0.063-0.139-0.189-0.283-0.353-0.381C14.691,2.067,14.492,2.003,14.284,2
			H8.7C8.507,2.003,8.308,2.067,8.137,2.185C7.925,2.316,7.799,2.46,7.722,2.633L6.926,4.615H3.8C3.787,4.615,3.774,4.615,3.761,4.615
			z"
        />
        <path
            d="M11.843,14.66c-0.002,0-0.004,0-0.007,0c-2.485-0.005-4.502-2.03-4.498-4.515c0.004-2.481,2.025-4.498,4.506-4.498l0,0
			c1.203,0.009,2.364,0.47,3.198,1.332c0.829,0.829,1.311,1.994,1.308,3.183c-0.002,1.203-0.473,2.334-1.325,3.184
			C14.173,14.193,13.044,14.66,11.843,14.66z M11.844,7.648c-1.38,0-2.503,1.121-2.506,2.501c-0.002,1.382,1.12,2.508,2.501,2.511
			c0.001,0,0.003,0,0.004,0c0.667,0,1.296-0.26,1.769-0.731c0.475-0.472,0.736-1.101,0.737-1.771c0.001-0.662-0.266-1.309-0.733-1.776
			c-0.465-0.48-1.082-0.764-1.751-0.734h-0.017C11.847,7.648,11.845,7.648,11.844,7.648z"
        />
    </Svgicon>
);
// viewBox="0 0 15.414 15.414"
export const CloseX = props => (
    <Svgicon {...props}>
        <rect
            x="-2.192"
            y="6.707"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.1924 7.707)"
            width="19.799"
            height="2"
        />
        <rect
            x="6.707"
            y="-2.192"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.1924 7.707)"
            width="2"
            height="19.799"
        />
    </Svgicon>
);
// viewBox="0 0 17 21"
export const Download = props => (
    <Svgicon {...props}>
        <path
            d="M8.5,17.461c-0.281,0-0.551-0.111-0.75-0.311l-6.5-6.5c-0.414-0.415-0.414-1.086,0-1.5s1.086-0.414,1.5,0L8.5,14.9
			l5.75-5.75c0.414-0.414,1.086-0.414,1.5,0s0.414,1.085,0,1.5l-6.5,6.5C9.051,17.35,8.781,17.461,8.5,17.461z"
        />
        <path d="M16,21H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h15c0.553,0,1,0.447,1,1S16.553,21,16,21z" />
        <path d="M8.5,17c-0.552,0-1-0.447-1-1V1c0-0.552,0.448-1,1-1c0.553,0,1,0.448,1,1v15C9.5,16.553,9.053,17,8.5,17z" />
    </Svgicon>
);
// viewBox="0 0 23.438 21"
export const Menu = props => (
    <Svgicon {...props}>
        <path d="M13.698,3.367H1.001c-0.552,0-1-0.448-1-1s0.448-1,1-1h12.697c0.553,0,1,0.448,1,1S14.251,3.367,13.698,3.367z" />
        <path d="M13.698,11.367H1.001c-0.552,0-1-0.448-1-1s0.448-1,1-1h12.697c0.553,0,1,0.448,1,1S14.251,11.367,13.698,11.367z" />
        <path d="M13.698,19.367H1.001c-0.552,0-1-0.447-1-1s0.448-1,1-1h12.697c0.553,0,1,0.447,1,1S14.251,19.367,13.698,19.367z" />
        <circle id="Ellipse_939" cx="20.938" cy="2.5" r="2.5" />
        <circle id="Ellipse_940" cx="20.938" cy="10.5" r="2.5" />
        <circle id="Ellipse_941" cx="20.938" cy="18.5" r="2.5" />
    </Svgicon>
);
// viewBox="0 0 24 24"
export const QuestionGuide = props => (
    <Svgicon {...props}>
        <g transform="translate(143 22)">
            <path
                id="Path_4720"
                fill="none"
                d="M-121.475-15.522c-0.96-1.661-2.339-3.04-4-4c-1.675-0.982-3.584-1.493-5.525-1.478
                c-1.94-0.015-3.847,0.495-5.521,1.475c-1.661,0.96-3.04,2.339-4,4c-0.982,1.675-1.493,3.584-1.479,5.525
                c-0.015,1.94,0.495,3.848,1.475,5.522c0.96,1.66,2.339,3.04,4,4C-134.85,0.504-132.941,1.015-131,1
                c1.94,0.015,3.848-0.495,5.522-1.475c1.661-0.96,3.04-2.339,4-4C-120.496-6.15-119.985-8.059-120-10
                C-119.985-11.94-120.495-13.848-121.475-15.522z"
            />
        </g>
        <path
            id="Path_7112"
            strokeWidth="0"
            d="M11.113,15.703c-0.027-0.353-0.032-1.008,0.252-1.757c0.287-0.756,0.732-1.246,0.988-1.491l1.285-1.328
            c0.527-0.544,0.826-1.269,0.838-2.026c0.039-0.603-0.162-1.197-0.559-1.653c-0.423-0.419-1.005-0.638-1.6-0.6
            c-0.594-0.026-1.176,0.167-1.637,0.543c-0.409,0.353-0.638,0.87-0.624,1.41H8.338C8.324,7.854,8.73,6.949,9.448,6.33
            c0.801-0.672,1.824-1.018,2.868-0.972c1.049-0.063,2.079,0.297,2.86,1c0.701,0.709,1.068,1.683,1.009,2.678
            c-0.064,1.281-0.628,2.485-1.571,3.355v-0.008l-1.07,1.069c-0.524,0.635-0.785,1.446-0.729,2.267
            C12.247,15.714,11.68,15.708,11.113,15.703z M11.024,17.956c-0.011-0.257,0.079-0.508,0.251-0.7c0.203-0.199,0.48-0.303,0.763-0.288
            c0.285-0.017,0.564,0.086,0.77,0.284c0.179,0.188,0.275,0.44,0.267,0.7c0.014,0.261-0.083,0.515-0.267,0.7
            c-0.208,0.193-0.487,0.292-0.77,0.275c-0.281,0.016-0.556-0.083-0.762-0.275C11.102,18.463,11.011,18.212,11.024,17.956z"
        />
    </Svgicon>
);
// viewBox="0 0 20 20"
export const ThreeDot = props => (
    <Svgicon {...props}>
        <circle id="Ellipse_1005" cx="10" cy="2.967" r="1.8" />
        <circle id="Ellipse_1006" cx="10" cy="10.167" r="1.8" />
        <circle id="Ellipse_1007" cx="10" cy="17.367" r="1.8" />
    </Svgicon>
);
// viewBox="0 0 24.576 12.837"
export const CodeTransfer = props => (
    <Svgicon {...props}>
        <path
            d="M6.394,12.837c-0.128,0-0.255-0.049-0.353-0.146L0.25,6.916C0.122,6.792,0.045,6.618,0.042,6.431
            C0.038,6.244,0.108,6.067,0.237,5.933l5.804-5.787c0.196-0.194,0.512-0.195,0.707,0.001c0.195,0.195,0.194,0.512-0.001,0.707
            L1.167,6.418l5.581,5.564c0.196,0.195,0.196,0.512,0.001,0.708C6.65,12.788,6.522,12.837,6.394,12.837z"
        />
        <path
            d="M12.464,3.045c-2.007,0-3.64,1.633-3.64,3.64s1.633,3.64,3.64,3.64s3.64-1.633,3.64-3.64S14.471,3.045,12.464,3.045z
             M12.464,9.325c-1.456,0-2.64-1.185-2.64-2.64s1.184-2.64,2.64-2.64c1.455,0,2.64,1.185,2.64,2.64S13.919,9.325,12.464,9.325z"
        />
        <path
            d="M18.201,12.837c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l5.564-5.565l-5.564-5.564
            c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.773,5.774c0.271,0.259,0.28,0.703,0.014,0.982l-5.787,5.788
            C18.457,12.788,18.329,12.837,18.201,12.837z"
        />
    </Svgicon>
);
// viewBox="0 0 24 23.5"
export const Market = props => (
    <Svgicon {...props}>
        <path
            d="M21.5,12.5h-19C1.122,12.5,0,11.378,0,10s1.122-2.5,2.5-2.5h19c1.379,0,2.5,1.122,2.5,2.5S22.879,12.5,21.5,12.5z M2.5,8.5
            C1.673,8.5,1,9.173,1,10s0.673,1.5,1.5,1.5h19c0.827,0,1.5-0.673,1.5-1.5s-0.673-1.5-1.5-1.5H2.5z"
        />
        <path
            d="M20.377,8.443H2.932L3.004,7.88C3.575,3.395,7.415,0.007,11.935,0c4.525,0.004,8.367,3.391,8.938,7.878l0.071,0.563
            L20.377,8.443z M4.088,7.443h15.701C19.047,3.735,15.766,1.003,11.935,1C8.109,1.006,4.831,3.738,4.088,7.443z"
        />
        <path
            d="M17.5,23.5h-11C4.57,23.5,3,21.93,3,20v-8.5h18V20C21,21.93,19.43,23.5,17.5,23.5z M4,12.5V20c0,1.379,1.122,2.5,2.5,2.5h11
            c1.379,0,2.5-1.121,2.5-2.5v-7.5H4z"
        />
        <rect x="11.5" y="14.5" width="1" height="6" />
        <rect x="15.5" y="14.5" width="1" height="6" />
        <rect x="7.5" y="14.5" width="1" height="6" />
    </Svgicon>
);
// viewBox="0 0 28 28"
export const Prev = props => (
    <Svgicon {...props}>
        <polygon points="8.408,27.186 7.701,26.479 20.18,14 7.701,1.52 8.408,0.813 21.594,14" />
    </Svgicon>
);
// viewBox="0 0 28 28"
export const Next = props => (
    <Svgicon {...props}>
        <polygon points="20.887,27.186 21.594,26.479 9.115,14 21.594,1.52 20.887,0.813 7.701,14" />
    </Svgicon>
);

// viewBox="0 0 511.627 511.627"
export const Ascending = props => (
    <Svgicon viewBox="0 0 511.627 511.627" {...props}>
        <path
            d="M260.494,219.271H388.4c2.666,0,4.855-0.855,6.563-2.57c1.715-1.713,2.573-3.9,2.573-6.567v-54.816
        c0-2.667-0.858-4.854-2.573-6.567c-1.708-1.711-3.897-2.57-6.563-2.57H260.494c-2.666,0-4.853,0.855-6.567,2.57
        c-1.71,1.713-2.568,3.9-2.568,6.567v54.816c0,2.667,0.855,4.854,2.568,6.567C255.641,218.413,257.828,219.271,260.494,219.271z"
        />
        <path
            d="M260.497,73.089h73.087c2.666,0,4.856-0.855,6.563-2.568c1.718-1.714,2.563-3.901,2.563-6.567V9.136
        c0-2.663-0.846-4.853-2.563-6.567C338.44,0.859,336.25,0,333.584,0h-73.087c-2.666,0-4.853,0.855-6.567,2.568
        c-1.709,1.715-2.568,3.905-2.568,6.567v54.818c0,2.666,0.855,4.853,2.568,6.567C255.645,72.23,257.831,73.089,260.497,73.089z"
        />
        <path
            d="M196.54,401.991h-54.817V9.136c0-2.663-0.854-4.856-2.568-6.567C137.441,0.859,135.254,0,132.587,0H77.769
        c-2.663,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.905-2.568,6.567v392.855H13.816c-4.184,0-7.04,1.902-8.564,5.708
        c-1.525,3.621-0.855,6.95,1.997,9.996l91.361,91.365c2.094,1.707,4.281,2.562,6.567,2.562c2.474,0,4.665-0.855,6.567-2.562
        l91.076-91.078c1.906-2.279,2.856-4.571,2.856-6.844c0-2.676-0.859-4.859-2.568-6.584
        C201.395,402.847,199.208,401.991,196.54,401.991z"
        />
        <path
            d="M504.604,441.109c-1.715-1.718-3.901-2.573-6.567-2.573H260.497c-2.666,0-4.853,0.855-6.567,2.573
        c-1.709,1.711-2.568,3.901-2.568,6.564v54.815c0,2.673,0.855,4.853,2.568,6.571c1.715,1.711,3.901,2.566,6.567,2.566h237.539
        c2.666,0,4.853-0.855,6.567-2.566c1.711-1.719,2.566-3.898,2.566-6.571v-54.815C507.173,445.011,506.314,442.82,504.604,441.109z"
        />
        <path
            d="M260.494,365.445H443.22c2.663,0,4.853-0.855,6.57-2.566c1.708-1.711,2.563-3.901,2.563-6.563v-54.823
        c0-2.662-0.855-4.853-2.563-6.563c-1.718-1.711-3.907-2.566-6.57-2.566H260.494c-2.666,0-4.853,0.855-6.567,2.566
        c-1.71,1.711-2.568,3.901-2.568,6.563v54.823c0,2.662,0.855,4.853,2.568,6.563C255.641,364.59,257.828,365.445,260.494,365.445z"
        />
    </Svgicon>
);

// viewBox="0 0 511.627 511.627"
export const Descending = props => (
    <Svgicon viewBox="0 0 511.627 511.627" {...props}>
        <path
            d="M333.584,438.536h-73.087c-2.666,0-4.853,0.855-6.567,2.573c-1.709,1.711-2.568,3.901-2.568,6.564v54.815
			c0,2.673,0.855,4.853,2.568,6.571c1.715,1.711,3.901,2.566,6.567,2.566h73.087c2.666,0,4.856-0.855,6.563-2.566
			c1.718-1.719,2.563-3.898,2.563-6.571v-54.815c0-2.663-0.846-4.854-2.563-6.564C338.44,439.392,336.25,438.536,333.584,438.536z"
        />
        <path
            d="M196.54,401.991h-54.817V9.136c0-2.663-0.854-4.856-2.568-6.567C137.441,0.859,135.254,0,132.587,0H77.769
			c-2.663,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.905-2.568,6.567v392.855H13.816c-4.184,0-7.04,1.902-8.564,5.708
			c-1.525,3.621-0.855,6.95,1.997,9.996l91.361,91.365c2.094,1.707,4.281,2.562,6.567,2.562c2.474,0,4.665-0.855,6.567-2.562
			l91.076-91.078c1.906-2.279,2.856-4.571,2.856-6.844c0-2.676-0.859-4.859-2.568-6.584
			C201.395,402.847,199.208,401.991,196.54,401.991z"
        />
        <path
            d="M388.4,292.362H260.494c-2.666,0-4.853,0.855-6.567,2.566c-1.71,1.711-2.568,3.901-2.568,6.563v54.823
			c0,2.662,0.855,4.853,2.568,6.563c1.714,1.711,3.901,2.566,6.567,2.566H388.4c2.666,0,4.855-0.855,6.563-2.566
			c1.715-1.711,2.573-3.901,2.573-6.563v-54.823c0-2.662-0.858-4.853-2.573-6.563C393.256,293.218,391.066,292.362,388.4,292.362z"
        />
        <path
            d="M504.604,2.568C502.889,0.859,500.702,0,498.036,0H260.497c-2.666,0-4.853,0.855-6.567,2.568
			c-1.709,1.715-2.568,3.905-2.568,6.567v54.818c0,2.666,0.855,4.853,2.568,6.567c1.715,1.709,3.901,2.568,6.567,2.568h237.539
			c2.666,0,4.853-0.855,6.567-2.568c1.711-1.714,2.566-3.901,2.566-6.567V9.136C507.173,6.473,506.314,4.279,504.604,2.568z"
        />
        <path
            d="M443.22,146.181H260.494c-2.666,0-4.853,0.855-6.567,2.57c-1.71,1.713-2.568,3.9-2.568,6.567v54.816
			c0,2.667,0.855,4.854,2.568,6.567c1.714,1.711,3.901,2.57,6.567,2.57H443.22c2.663,0,4.853-0.855,6.57-2.57
			c1.708-1.713,2.563-3.9,2.563-6.567v-54.816c0-2.667-0.855-4.858-2.563-6.567C448.069,147.04,445.879,146.181,443.22,146.181z"
        />
    </Svgicon>
);

export const SortLeft = props => (
    <Svgicon viewBox="0 0 12 22" {...props}>
        <g className="st0">
            <path className="st1" d="M58.7-22.1v-32h32v32H58.7z" />
            <g id="surface1" className="st2">
                <path
                    className="st3"
                    d="M74.7-45.5L74-44.8l-10,10l-1.7,1.7h24.8l-1.7-1.7l-10-10L74.7-45.5z M74.7-42.6l7.6,7.6H67.2L74.7-42.6z"
                />
            </g>
        </g>
        <g>
            <path
                className="st4"
                d="M11,22c-0.3,0-0.5-0.1-0.7-0.3l-10-10c-0.4-0.4-0.4-1,0-1.4l10-10c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L2.4,11
		l9.3,9.3c0.4,0.4,0.4,1,0,1.4C11.5,21.9,11.3,22,11,22z"
            />
        </g>
    </Svgicon>
);

export const Dash = props => (
    <Svgicon viewBox="0 0 492 492" {...props}>
        <path
            d="M465.064,207.562H26.908C12.076,207.562,0,219.698,0,234.53v22.804c0,14.832,12.072,27.104,26.908,27.104h438.156
			c14.84,0,26.936-12.272,26.936-27.104V234.53C492,219.698,479.904,207.562,465.064,207.562z"
        />
    </Svgicon>
);

export const Envelop = props => (
    <Svgicon viewBox="0 0 480 480" {...props}>
        <g>
            <g>
                <path
                    d="M440,80H40C17.92,80.026,0.026,97.92,0,120v240c0.026,22.08,17.92,39.974,40,40h400c22.08-0.026,39.974-17.92,40-40V120
			C479.974,97.92,462.08,80.026,440,80z M464,360c0,13.255-10.745,24-24,24H40c-13.255,0-24-10.745-24-24V120
			c0-13.255,10.745-24,24-24h400c13.255,0,24,10.745,24,24V360z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M446.856,115.88c-2.276-3.786-7.189-5.011-10.976-2.736L260.576,218.32c-12.658,7.624-28.494,7.624-41.152,0
			L44.12,113.144c-3.828-2.207-8.72-0.893-10.927,2.934c-2.151,3.73-0.964,8.494,2.687,10.778L211.2,232.04
			c17.722,10.654,39.878,10.654,57.6,0l175.32-105.184C447.906,124.58,449.131,119.667,446.856,115.88z"
                />
            </g>
        </g>
        <g>
            <g>
                <path d="M40,144c-4.418,0-8,3.582-8,8v208c0,4.418,3.582,8,8,8s8-3.582,8-8V152C48,147.582,44.418,144,40,144z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M440,144c-4.418,0-8,3.582-8,8v208c0,4.418,3.582,8,8,8s8-3.582,8-8V152C448,147.582,444.418,144,440,144z" />
            </g>
        </g>
    </Svgicon>
);

export const TextMessage = props => (
    <Svgicon viewBox="0 0 512 512" {...props}>
        <g>
            <path d="M440,213.653h-48c-4.418,0-8,3.582-8,8s3.582,8,8,8h48c4.418,0,8-3.582,8-8S444.418,213.653,440,213.653z" />
            <path d="M72,229.653h288c4.418,0,8-3.582,8-8s-3.582-8-8-8H72c-4.418,0-8,3.582-8,8S67.582,229.653,72,229.653z" />
            <path d="M440,165.653H280c-4.418,0-8,3.582-8,8s3.582,8,8,8h160c4.418,0,8-3.582,8-8S444.418,165.653,440,165.653z" />
            <path d="M72,181.653h176c4.418,0,8-3.582,8-8s-3.582-8-8-8H72c-4.418,0-8,3.582-8,8S67.582,181.653,72,181.653z" />
            <path d="M440,261.653H144c-4.418,0-8,3.582-8,8s3.582,8,8,8h296c4.418,0,8-3.582,8-8S444.418,261.653,440,261.653z" />
            <path d="M72,277.653h40c4.418,0,8-3.582,8-8s-3.582-8-8-8H72c-4.418,0-8,3.582-8,8S67.582,277.653,72,277.653z" />
            <path d="M440,309.653H72c-4.418,0-8,3.582-8,8s3.582,8,8,8h368c4.418,0,8-3.582,8-8S444.418,309.653,440,309.653z" />
            <path d="M440,117.653H72c-4.418,0-8,3.582-8,8s3.582,8,8,8h368c4.418,0,8-3.582,8-8S444.418,117.653,440,117.653z" />
            <path
                d="M456,53.653H56c-30.878,0-56,25.122-56,56v224c0,28.163,20.897,51.537,48,55.43v53.256c0,6.494,3.877,12.296,9.877,14.782
		c1.992,0.825,4.075,1.226,6.14,1.226c4.155,0,8.229-1.626,11.297-4.694l64-64H456c30.878,0,56-25.122,56-56v-224
		C512,78.774,486.878,53.653,456,53.653z M496,333.653c0,22.056-17.944,40-40,40H136c-2.122,0-4.157,0.843-5.657,2.343L64,442.333
		v-60.68c0-4.418-3.582-8-8-8c-22.056,0-40-17.944-40-40v-224c0-22.056,17.944-40,40-40h400c22.056,0,40,17.944,40,40V333.653z"
            />
        </g>
    </Svgicon>
);
