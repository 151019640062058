import React from "react";
import NotificationTestModal from "@/components/notifications/notification/create/controls/NotifTestModal";
import { iosNotificationNormalizer } from "@/components/notifications/notification/normalize";
import IosNotifPreview from "@/components/notifications/notification/create/ios/IosNotifPreview";
import IosNotifStep1 from "./steps/IosNotifStep1";
import IosNotifStep2 from "@/components/notifications/notification/create/ios/steps/IosNotifStep2";
import { NOTIFICATION_MODE } from "@/actions/_types";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import { PLATFORMS } from "@/constants";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import { createInitialFormData } from "@/components/notifications/notification/create/controls/createInitialFormData";
import NotificationFormFinalStep from "@/components/notifications/notification/create/base/NotificationFormFinalStep";
import { clearForm } from "@/actions/app";
import {
    createNotification,
    sendTestNotification,
} from "@/actions/notifications";
import { emptyInprog } from "@/actions/inprog";
import { withErrorCatcher } from "@/components/utilities";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { normalize } from "@/components/utils/normalize";

const IosNotifWizard = props => {
    const {
        history,
        notification,
        notificationMode,
        clearForm,
        appId,
        emptyInprog,
        createNotification,
        sendTestNotification,
    } = props;

    const platform = PLATFORMS.IOS;

    return (
        <BaseWizard
            formName="notification_wizard"
            title="ارسال نوتیفیکیشن iOS"
            subtitle="با این فرم می‌توانید نوتیفیکیشن ساده و پیشرفته به اپلیکیشن‌های iOS بفرستید."
            history={history}
            steps={steps}
            stepFinale={NotificationFormFinalStep}
            preview={IosNotifPreview}
            testModal={NotificationTestModal}
            footer={WizardFooter}
            formInitialData={createInitialFormData(
                notification,
                notificationMode,
                appId,
            )}
            onUnmountCallback={() => {
                clearForm("notification_wizard");
                emptyInprog();
            }}
            submitAction={(data, onSuccess, onFailure) => {
                const normalizedData = normalize(
                    data,
                    iosNotificationNormalizer,
                );
                if (data.isTestMode) {
                    sendTestNotification(
                        normalizedData,
                        platform,
                        onSuccess,
                        onFailure,
                    );
                } else {
                    createNotification(
                        normalizedData,
                        platform,
                        notificationMode,
                        onSuccess,
                        onFailure,
                    );
                }
            }}
            hasReduxForm={true}
            passToSteps={{
                platform,
                notificationMode,
            }}
        />
    );
};

const steps = [
    { component: IosNotifStep1, showTestBtn: true },
    {
        component: IosNotifStep2,
        showTestBtn: true,
        isEnabled: values =>
            values.notificationMode === NOTIFICATION_MODE.NORMAL,
    },
];

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            clearForm,
            createNotification,
            emptyInprog,
            sendTestNotification,
        },
        dispatch,
    );
};

export default withErrorCatcher(
    connect(null, mapDispatchToProps)(IosNotifWizard),
);
