import React, { cloneElement } from "react";

export const PTableHeader = props => {
    const { children, columns } = props;
    const childrenProps = { columns, isHeader: true };

    return (
        <div className="PTableHeader">
            <div className="PTableHeader__inner">
                {React.Children.map(
                    children,
                    child => cloneElement(child, childrenProps),
                    null,
                )}
            </div>
        </div>
    );
};
