import React, { Component } from "react";
import Slider from "material-ui/Slider";
import PropTypes from "prop-types";

const propTypes = {
    title: PropTypes.any.isRequired,
    max: PropTypes.number.isRequired,
    sliderRange: PropTypes.array.isRequired,
    data_tip: PropTypes.any,
    min: PropTypes.number,
    step: PropTypes.number,
};

const defaultProps = {
    min: 0,
    step: 1,
};

class NotificationSliderComponent extends Component {
    constructor(props) {
        super(props);

        this.handleSlider = this.handleSlider.bind(this);
    }

    generateSliderLabel() {
        // ref
        const { sliderRef } = this;

        const { sliderRange } = this.props;

        /**
         * generate label DOM element and set its class
         * based on the position of slider pointer
         */
        const generateLabel = (array, currentPosition) => {
            if (typeof currentPosition === "undefined") {
                return;
            }
            let className = "";

            if (currentPosition === 0) {
                className = "mui-slider__label--right";
            } else if (currentPosition === array.length - 1) {
                className = "mui-slider__label--left";
            }

            let label = array[currentPosition];

            label = label ? label.name : "خارج از محدوده";

            return `<span class="mui-slider__label ${className}">${label}</span>`;
        };

        let $sliderLabel = generateLabel(sliderRange, this.props.input.value);

        if (sliderRef && sliderRef.getElementsByClassName("mui-slider")) {
            sliderRef.getElementsByClassName(
                "mui-slider",
            )[0].children[0].children[0].children[2].innerHTML = $sliderLabel;
        }
    }

    handleSlider(event, value) {
        this.props.input.onChange(value);
    }

    componentDidMount() {
        this.generateSliderLabel();
    }

    componentDidUpdate() {
        this.generateSliderLabel();
    }

    renderTitle() {
        const { title, data_tip } = this.props;

        return (
            <p>
                {title}
                {data_tip && (
                    <span className="more-info" data-tip={data_tip}>
                        ؟
                    </span>
                )}
            </p>
        );
    }

    render() {
        const { min, max, step } = this.props;

        return (
            <div
                ref={ref => {
                    this.sliderRef = ref;
                }}
            >
                {this.renderTitle()}
                <Slider
                    className="mui-slider mui-slider--bluefade"
                    min={min}
                    max={max}
                    step={step}
                    style={{ marginTop: "50px" }}
                    value={this.props.input.value}
                    sliderStyle={{ marginTop: 0, marginBottom: 0 }}
                    onChange={this.handleSlider}
                />
            </div>
        );
    }
}

NotificationSliderComponent.propTypes = propTypes;
NotificationSliderComponent.defaultProps = defaultProps;
export default NotificationSliderComponent;
