import React from "react";
import { getFormValues, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { withErrorCatcher } from "@/components/utilities";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux/es/redux";

const AutomationFinaleStep = ({ segmentName }) => {
    return (
        <div className="notif-block">
            <p style={{ whiteSpace: "nowrap", display: "flex" }}>
                آیا از ارسال نوتیفیکیشن به دسته
                <span
                    style={{
                        padding: "0 8px",
                        color: "#333",
                        backgroundColor: "#e6e6e6",
                    }}
                >
                    {segmentName}
                </span>
                اطمینان دارید؟
            </p>
        </div>
    );
};

const mapStateToProps = state => {
    const { segment_id: segmentId } =
        getFormValues("automation_form")(state) || {};

    const segmentName =
        state?.segmentations?.segmentationList?.find(
            s => s.segment_id === segmentId,
        )?.name ?? "";

    return {
        segmentName,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

const AutomationFinaleStepRF = reduxForm({
    form: "automation_form",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(AutomationFinaleStep);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AutomationFinaleStepRF),
    ),
);
