import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { useSelector } from "react-redux";
import EmailConfigTab from "@/components/app/profile/Tabs/ConfigTab/section/EmailConfigTab";
import { makeInitialValues } from "@/components/utilities/extra/makeInitialValues";
import SmsConfigTab from "@/components/app/profile/Tabs/ConfigTab/section/SmsConfigTab";
import { Divider } from "@material-ui/core";

const ConfigTab = () => {
    const email = useSelector(state => state?.config?.email ?? {});
    const sms = useSelector(state => state?.config?.sms ?? {});

    return (
        <div className={"profile__block"}>
            <EmailConfigTab
                email={email}
                initialValues={makeInitialValues(email[0], [
                    "name",
                    "username",
                    "password",
                    "host",
                    "port",
                ])}
            />
            <Divider />
            <SmsConfigTab
                sms={sms}
                initialValues={makeInitialValues(sms[0], [
                    "name",
                    "username",
                    "password",
                    "api_key",
                    "sender_number",
                    "provider",
                ])}
            />
        </div>
    );
};

export default withErrorCatcher(ConfigTab);
