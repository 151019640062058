import { axiosWithAuth, createUrl, getPlatformName } from "@/queries/constants";
export const WEB_FAV_KEYS = {
    LIST: () => `notif_list_${getPlatformName()}`,
    GET: () => `notif_${getPlatformName()}`,
    FAV: () => `notif_favorites_${getPlatformName()}`,
    AUTOMATION_LIST: () => `notif_automation_list_${getPlatformName()}`,
    AUTOMATION_GET: () => `notif_automation_${getPlatformName()}`,
};
export const fetchWebFavorites = async () => {
    const url = await createUrl(`favorites/${getPlatformName()}/`);

    return (await axiosWithAuth().get(url))?.data;
};
export const sendTestWeb = async data => {
    const url = await createUrl(
        `messaging/notifications/${getPlatformName()}/send_test_message/`,
    );

    return (await axiosWithAuth().post(url, data))?.data;
};
export const updateTestWeb = async data => {
    const { id, names } = data;
    const url = await createUrl(`favorites/${getPlatformName()}/${id}`);
    return (await axiosWithAuth().put(url, { name: names }))?.data;
};
export const removeWebFavorite = async id => {
    const url = await createUrl(`favorites/${getPlatformName()}/${id}`);

    await axiosWithAuth().delete(url);
    return id;
};
