import { FieldArray } from "redux-form";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import oneSignalIcon from "images/onesignal.png";
import NotificationButtonArray from "@/components/utilities/commons/wizard/controls/NotificationButtonArray";
import WebNotificationButtonArray from "@/components/notifications/notification/create/web/controls/WebNotificationButtonArray";

const NotificationButtonsComponent = ({
    title,
    hasOneSignal,
    actionsList,
    component,
    defaultSelectedAction,
    enableIcons = true,
}) => {
    return (
        <div className="notif-block notif-block--btncla">
            <header>
                <p>
                    {title}
                    {hasOneSignal && (
                        <img
                            src={oneSignalIcon}
                            className="notif-onesignal--img"
                        />
                    )}
                </p>
            </header>
            <FieldArray
                name="data.buttons"
                actions={actionsList}
                defaultSelectedAction={defaultSelectedAction}
                enableIcons={enableIcons}
                component={component}
            />
        </div>
    );
};

NotificationButtonsComponent.propTypes = {
    title: PropTypes.string,
    hasOneSignal: PropTypes.bool,
    actionsList: PropTypes.array,
    component: PropTypes.oneOf({
        NotificationButtonArray,
        WebNotificationButtonArray,
    }),
    defaultSelectedAction: PropTypes.object,
};
NotificationButtonsComponent.defaultProps = {
    title: "دکمه‌های نوتیفیکیشن",
    actionsList: [],
    component: NotificationButtonArray,
};

export default withErrorCatcher(NotificationButtonsComponent);
