/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getFormValues } from "redux-form";
import classNames from "classnames";
import { withErrorCatcher } from "@/components/utilities";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { useDispatch, useSelector } from "react-redux";
import filtersList from "@/components/notifications/notification/create/android/partials/filtersList";

const NotificationFiltersTab = props => {
    const { change } = props;
    const dispatch = useDispatch();
    const [fields, setFields] = useState([]);

    const { form } = useReduxForm();
    const { filters, app_ids } = useSelector(
        state => getFormValues(form)(state) ?? {},
    );
    const appVersion = useSelector(state => state?.inprog?.appVersion ?? []);

    useEffect(() => {
        const keys = Object.keys(filters ?? {}).map(i => `filters.${i}`);
        setFields(filtersList.filter(i => keys.indexOf(i.reduxKey) > -1) ?? []);
    }, []);

    const toggleFields = field => {
        const clonedFields = [...fields];
        const foundIndex = clonedFields.findIndex(i => i.key === field.key);

        if (foundIndex > -1) {
            // Remove
            clonedFields.splice(foundIndex, 1);
            setFields(clonedFields);
        } else {
            // Append
            setFields([...fields, field]);
        }
    };

    const applicationVersion = (appVersion =>
        appVersion.map(i => ({ key: i, name: i })))(appVersion);

    return (
        <div id="filterTabsWrap">
            <div className="row row--20">
                {filtersList.map(item => {
                    const isActive = item.isActive(filters);
                    const isDisabled = item.isDisabled;

                    return (
                        <div className="col-sm-3 col-xs-6" key={item.key}>
                            <div
                                className={classNames("card-b", {
                                    "card-b--active": isActive,
                                    "field-disable": isDisabled,
                                })}
                                data-tip={item?.tooltip}
                                tabIndex="0"
                                onClick={() => {
                                    if (isDisabled) return;
                                    const val = isActive
                                        ? ""
                                        : item.reduxKey === "csv"
                                        ? {}
                                        : [];

                                    item.action({
                                        dispatch,
                                        app_id: Array.isArray(app_ids)
                                            ? app_ids[0]
                                            : app_ids,
                                    });

                                    change(item.reduxKey, val);

                                    toggleFields(item);
                                }}
                            >
                                <svg>
                                    <use href={`#${item.icon}`} />
                                </svg>
                                <p>{item.title}</p>
                            </div>
                        </div>
                    );
                })}

                <div>
                    {fields.map(field => (
                        <field._parentComponent
                            key={field.key}
                            name={field.reduxKey}
                            title={field.title}
                            component={field._childComponent}
                            change={change}
                            {...field.childExtraProps({ applicationVersion })}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default withErrorCatcher(NotificationFiltersTab);
