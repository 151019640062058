import { FIRST_DAY_EMPTY_CHART_MESSAGE } from "@/constants";

const HighCharts = require("react-highcharts");
import BaseChart from "../types/_base";
import installationsImage from "images/charts/installations.jpeg";
import isEmpty from "lodash/isEmpty";
import EmptyChartImagePlaceHolder from "../../../utilities/emptyChartImagePlaceHolder";
import React from "react";

export default class DailyInstallations extends BaseChart {
    constructor(props) {
        super(props);
    }

    chartConfig = series => {
        const configs = this.chartsCommonConfigs(series);

        return {
            ...configs,
            chart: {
                type: "areaspline",
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.12,
                },
            },
        };
    };

    render() {
        const { helpText } = this.props;
        const series = this.getSeries("dailyInstallations");
        return (
            <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="card-g">
                    <header className="card-g__header">
                        <p className="card-g__title">
                            <svg>
                                <use xlinkHref="#svg_statistics"></use>
                            </svg>
                            نصب و حذف روزانه
                        </p>
                        <span
                            className="more-info"
                            data-class="tooltip tooltip--long"
                            data-tip={FIRST_DAY_EMPTY_CHART_MESSAGE}
                        >
                            ؟
                        </span>
                    </header>
                    <div className="card-g__body">
                        {helpText}
                        {this.isLoading() ||
                            (isEmpty(series?.[0]?.data) ? (
                                <EmptyChartImagePlaceHolder
                                    src={installationsImage}
                                    massage={FIRST_DAY_EMPTY_CHART_MESSAGE}
                                />
                            ) : (
                                <div className="chart">
                                    <HighCharts
                                        config={this.chartConfig(series)}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}
