import React from "react";
import css from "authentication/styles/signin.module";
import SignInBanner from "authentication/signin/partials/SignInBanner";
import SignInDeskProFrom from "authentication/signin/partials/SignInDeskProForm";
import logo from "images/svg/pushe-logo-horizontal.svg";
import {
    createValidator,
    isEmail,
    isRequired,
} from "@/components/utils/validation";
import { reduxForm } from "redux-form";
import { withErrorCatcher } from "@/components/utilities";
import { Link } from "react-router-dom";
import { useSignInDeskPro } from "authentication/signin/hooks/useSignInDeskPro";
import SignInDeskProButtons from "authentication/signin/partials/SignInDeskProButtons";
import { useSetCustomerEmail } from "authentication/signin/hooks/useSetCustomerEmail";

const SignInDeskProPage = props => {
    const { handleSubmit, change } = props;

    useSetCustomerEmail(change);
    const { inProgress, handleSignIn, signInError } =
        useSignInDeskPro(handleSubmit);

    return (
        <div className={css.wrapper}>
            <div className={css.innerWrapper}>
                <div className={css.banner}>
                    <SignInBanner />
                </div>

                <div className={css.block}>
                    <div className={css.signInLogo}>
                        <img src={logo} alt={"pushe logo"} />
                    </div>

                    <SignInDeskProFrom handleSignIn={handleSignIn} />

                    <SignInDeskProButtons
                        handleSignIn={handleSignIn}
                        inProgress={inProgress}
                    />

                    <Link className={css.goBackLink} to={"/signin"}>
                        بازگشت به صفحه ورود
                    </Link>

                    {signInError && (
                        <div className={css.signInError}>{signInError}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

const validate = createValidator({
    email: [isRequired, isEmail],
    customer_email: [isRequired, isEmail],
    password: isRequired,
});

const rf = reduxForm({
    form: "signInDeskPro",
    validate,
    destroyOnUnmount: true,
})(SignInDeskProPage);

export default withErrorCatcher(rf);
