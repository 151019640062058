import MobileTab from "@/components/notifications/notification/list/web/tabs/MobileTab";
import DetailsTab from "@/components/notifications/notification/list/web/tabs/DetailsTab";
import DesktopWebPreview from "./DesktopWebPreview";

export const tabs = [
    {
        label: "حالت موبایل",
        value: "1",
        display: () => true,
        component: MobileTab,
        wrapperClassName: "notif-card__body-wrapper",
        disabled: () => false,
    },
    {
        label: "حالت دسکتاپ",
        value: "2",
        display: () => true,
        component: DesktopWebPreview,
        wrapperClassName: "notif-card__body-wrapper",
        disabled: () => false,
    },
    {
        label: "جزئیات",
        value: "3",
        display: () => true,
        component: DetailsTab,
        wrapperClassName: "notif-card__body-wrapper",
        disabled: () => false,
    },
];
