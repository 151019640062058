import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { debouncer } from "@/components/utilities";
import { TOAST_ICON_CLASSES } from "@/constants";
import { store } from "@/store";
import "styles/modules/_mod-toast.scss";

const defaultToast = ({
    title,
    content,
    toastType,
    debounceTime = 6000,
    immediate = false,
    autoClose = true,
}) => {
    // In this case we are showing a dialog to user, then toast is not needed
    // Todo: maybe there is a better solution than checking the state here to disable to toast
    const shouldDisableToast =
        (store.getState()?.app?.permissionError?.errno ?? -1) > -1;
    if (shouldDisableToast) return;

    let iconClass = TOAST_ICON_CLASSES[toastType];

    let body = (
        <>
            {iconClass && <span className={iconClass} />}
            <div className="toast-text">
                <div className="toast-title">{title}</div>
                <div className="toast-desc">{content}</div>
            </div>
        </>
    );

    const options = { autoClose };

    if (immediate) {
        toast[toastType.toLowerCase()](body, options);
    } else {
        return debouncer(
            () => toast[toastType.toLowerCase()](body, options),
            debounceTime,
        );
    }
};

defaultToast.propTypes = {
    debounceTime: PropTypes.number,
    title: PropTypes.string.required,
    content: PropTypes.string.required,
    toastType: PropTypes.oneOf(["SUCCESS", "ERROR", "WARN"]).isRequired,
};

const successToast = (
    title,
    content,
    { debounceTime, immediate, autoClose } = {},
) =>
    defaultToast({
        title,
        content,
        immediate,
        autoClose,
        debounceTime,
        toastType: "SUCCESS",
    });
const errorToast = (
    title,
    content,
    { debounceTime, immediate, autoClose } = {},
) =>
    defaultToast({
        title,
        content,
        immediate,
        autoClose,
        debounceTime,
        toastType: "ERROR",
    });
const warnToast = (
    title,
    content,
    { debounceTime, immediate, autoClose } = {},
) =>
    defaultToast({
        title,
        content,
        immediate,
        autoClose,
        debounceTime,
        toastType: "WARN",
    });

export { successToast, errorToast, warnToast };
