export const steps = () => [
    {
        element: "#create-application-button",
        popover: {
            title: "ساخت اپلیکیشن",
            description:
                "با کلیک روی این دکمه صفحه ساخت اپلیکیشن برای پلتفرم اندروید باز می‌شود که مشابه آن در پلتفرم های دیگر قابل مشاهده می‌باشد",
        },
        onNext: currentElem => currentElem.node.click(),
    },
];

export const mobileSteps = () => [
    {
        element: "#create-application-button",
        popover: {
            title: "ساخت اپلیکیشن",
            description:
                "با کلیک روی این دکمه صفحه ساخت اپلیکیشن برای پلتفرم اندروید باز می‌شود که مشابه آن در پلتفرم های دیگر قابل مشاهده می‌باشد",
            position: "top-center",
        },
        onNext: currentElem => currentElem.node.click(),
    },
];
