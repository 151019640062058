import { generateGetParams, isValidPlatform } from "@/actions/_utils.jsx";
import { getApi } from "@/api";
import actionTypes from "@/actions/_types";
import { toggleLoading } from "@/actions/_utils";

export function fetchInstallations(params = {}, onSuccess, onFailure) {
    return function (dispatch, getState) {
        toggleLoading(actionTypes.LOADING.INSTALLATION, true, dispatch);

        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            const appId = getState().app.appId;
            if (appId) params.app_id = appId;
            return getApi(platform)
                .get(`installations/${platform}/${generateGetParams(params)}`)
                .then(resp => {
                    let payload = {
                        results: resp.data,
                        initial: params.offset === 0,
                    };
                    if (!Object.keys(resp.data).length) {
                        payload = {
                            results: [],
                            initial: params.offset === 0,
                        };
                    }
                    toggleLoading(
                        actionTypes.LOADING.INSTALLATION,
                        false,
                        dispatch,
                    );
                    dispatch({
                        payload,
                        type: actionTypes.INSTALLATION[
                            `FETCH_${platform.toUpperCase()}_INSTALLATIONS`
                        ],
                    });
                    if (onSuccess) onSuccess(payload.results.length);
                })
                .catch(() => {
                    if (onFailure) onFailure();

                    toggleLoading(
                        actionTypes.LOADING.INSTALLATION,
                        false,
                        dispatch,
                    );
                });
        }
    };
}

export function fetchFaveInstallations(params, onSuccess) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            const appId = getState().app.appId;
            if (appId) params.app_id = appId;
            return getApi(platform)
                .get(
                    `installations/${platform}/favorites/${generateGetParams(
                        params,
                    )}`,
                )
                .then(resp => {
                    let payload = {
                        results: resp.data,
                        initial: params.offset === 0,
                    };

                    if (!Object.keys(resp.data).length) {
                        payload = {
                            results: [],
                            initial: params.offset === 0,
                        };
                    }
                    dispatch({
                        payload,
                        type: actionTypes.INSTALLATION[
                            `FETCH_${platform.toUpperCase()}_FAVORITES_INSTALLATIONS`
                        ],
                    });
                    if (onSuccess) onSuccess(payload.results.length);
                })
                .catch(() => {});
        }
    };
}

export function faveInstallation(params = {}, onSuccess, onFailure) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .post(`favorites/${platform}/`, params)
                .then(resp => {
                    dispatch({
                        payload: resp.data,
                        type: actionTypes.INSTALLATION[
                            `FAVE_${platform.toUpperCase()}_INSTALLATIONS`
                        ],
                    });
                    dispatch({
                        payload: null,
                        type: actionTypes.APP.CLOSE_MODAL,
                    });

                    if (onSuccess) onSuccess();
                })
                .catch(resp => {
                    dispatch({
                        payload: (resp.response || {}).data,
                        type: actionTypes.APP.INPROG_ERROR,
                    });

                    if (onFailure) onFailure();
                });
        }
    };
}

export function fetchFavorites(params = {}) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch) && platform !== "web") {
            return getApi(platform)
                .fetchFavorites(params)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.INSTALLATION[
                            `FETCH_${platform.toUpperCase()}_FAVORITES`
                        ],
                    });
                })
                .catch(resp => {
                    dispatch({
                        payload: resp,
                        type: actionTypes.APP.INPROG_ERROR,
                    });
                });
        }
    };
}
export function webFetchFavorites(params = {}) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(`favorites/${platform}/${generateGetParams(params)}`)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results || resp.data,
                        type: actionTypes.INSTALLATION[
                            `FETCH_${platform.toUpperCase()}_FAVORITES`
                        ],
                    });
                })
                .catch(resp => {
                    dispatch({
                        payload: resp,
                        type: actionTypes.APP.INPROG_ERROR,
                    });
                });
        }
    };
}

export function unFaveDevice(params = {}, onSuccess, onFailure) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        const isWeb = platform === "web";
        if (isValidPlatform(platform, dispatch)) {
            let url = `favorites/${isWeb ? "" : `${platform}/`}`;
            return getApi(platform)
                .delete(`${url}${params.pushe_id}/`)
                .then(() => {
                    dispatch({
                        payload: params.pushe_id,
                        type: actionTypes.INSTALLATION[
                            `UNFAVE_${platform.toUpperCase()}_DEVICE`
                        ],
                    });
                    dispatch({
                        payload: null,
                        type: actionTypes.APP.CLOSE_MODAL,
                    });

                    if (onSuccess) onSuccess();
                })
                .catch(resp => {
                    dispatch({
                        payload: resp,
                        type: actionTypes.APP.INPROG_ERROR,
                    });
                    if (onFailure) onFailure();
                });
        }
    };
}
export function WebUnFaveDevice(params = {}, onSuccess, onFailure) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            let url = `favorites/${platform}/`;
            return getApi(platform)
                .delete(`${url}${params.id}/`)
                .then(() => {
                    dispatch({
                        payload: params.id,
                        type: actionTypes.INSTALLATION[
                            `UNFAVE_${platform.toUpperCase()}_DEVICE`
                        ],
                    });
                    if (onSuccess) onSuccess();
                })
                .catch(() => {
                    if (onFailure) onFailure();
                });
        }
    };
}
export function WebUpdateFaveDevice(params = {}, onSuccess, onFailure) {
    return function (dispatch, getState) {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            let url = `favorites/${platform}/`;
            return getApi(platform)
                .put(`${url}${params.id}/`, { name: params.name })
                .then(resp => {
                    dispatch({
                        payload: { ...resp.data, id: params.id },
                        type: actionTypes.INSTALLATION[
                            `FAVE_${platform.toUpperCase()}_UPDATE`
                        ],
                    });
                    if (onSuccess) onSuccess();
                })
                .catch(() => {
                    if (onFailure) onFailure();
                });
        }
    };
}

export const retrieveInstall =
    ({ platform, app_id }, onSuccess, onFailure) =>
    async dispatch => {
        try {
            // const platform = getState().app.platform;
            const response = await getApi().get(
                `installations/${platform}/?app_id=${app_id}&limit=1`,
            );
            let payload =
                Array.isArray(response.data) && response.data[0]
                    ? response.data
                    : [response.data];

            dispatch({
                type: actionTypes.INSTALLATION.RETRIEVE_INSTALL,
                app_id,
                payload,
            });

            if (onSuccess) onSuccess();
        } catch (e) {
            if (onFailure) onFailure();
        }
    };
