
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`نصب podهای لازم`}</h1>
    <p>{`وارد فولدر ios شوید و دستور زیر را اجرا کنید.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`pod install --repo-update
`}</code></pre>
    <p>{`پس از اجرای این دستور می‌توانید با بازکردن فایل `}<inlineCode parentName="p">{`YourProjectName.xcworkspace`}</inlineCode>{` برنامه ios خود را اجرا کنید.`}</p>
    <p>{`برای اطلاعات بیشتر به لینک‌های زیر مراجعه کنید:`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/ios/intro/"
      }}>{`مستندات پوشه برای ios`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/react-native/intro/"
      }}>{`مستندات پوشه برای ری‌اکت-نیتیو`}</a></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;