import React from "react";
import { useSelector } from "react-redux";
import { ReduxSimpleTextField, withErrorCatcher } from "@/components/utilities";
import { withRouter } from "react-router-dom";
import { Field, FormSection, reduxForm } from "redux-form";
import {
    createValidator,
    isRequired,
    withMessage,
} from "@/components/utils/validation";
import SelectApplicationComponent from "@/components/utilities/commons/wizard/components/SelectApplicationComponent";
import EmailMultiFormatContent from "@/components/email/email/create/android/partials/EmailMultiFormatContent";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
const _emailWizard = {
    web: "web_email_wizard",
    android: "android_email_wizard",
};

const WebEmailStep1 = props => {
    const { change, initialValues } = props;
    const platform = useSelector(s => s?.app?.platform);

    const applications = useSelector(s => s?.[platform]?.applications ?? []);

    const formValues = useReduxFormValues("web_email_wizard", ["data"]) ?? {};

    return (
        <form>
            <SelectApplicationComponent
                platform={platform}
                applications={applications}
                change={change}
            />

            <FormSection name="data">
                <div className="step-block">
                    <header>
                        <p>محتوی ایمیل</p>
                    </header>

                    <div className="step-block__column">
                        <Field
                            fullWidth
                            name="subject"
                            label="عنوان ایمیل"
                            required
                            component={ReduxSimpleTextField}
                        />

                        <EmailMultiFormatContent
                            change={change}
                            initialValues={initialValues}
                            formValues={formValues}
                        />
                    </div>
                </div>
            </FormSection>
        </form>
    );
};

const validators = createValidator({
    app_ids: (value, key, allValues) =>
        allValues.isTestMode || value?.length > 0
            ? undefined
            : FORM_ERRORS.NO_APP_SELECTED,
    data: {
        subject: withMessage(isRequired, FORM_ERRORS.EMAIL_FORM.REQUIRED_TITLE),
        content: (value, key, allValues) => {
            const emptyValue = value === "<p></p><br/>";
            return !allValues?.data?.["isHTML"] && emptyValue
                ? FORM_ERRORS.EMAIL_FORM.REQUIRED_CONTENT
                : undefined;
        },
        html: (value, key, allValues) =>
            allValues?.data?.["isHTML"] && !value
                ? FORM_ERRORS.EMAIL_FORM.REQUIRED_HTML
                : undefined,
    },
});
export default withRouter(
    withErrorCatcher(
        reduxForm({
            form: "web_email_wizard",
            validate: validators,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            onSubmitSuccess: (submitResult, dispatch, props) =>
                props.onSuccess(submitResult),
            onSubmitFail: (error, dispatch, submitError, props) =>
                props.onFailure(error),
        })(WebEmailStep1),
    ),
);
