import { Field, FormSection } from "redux-form";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import Checkbox from "material-ui/Checkbox";
import NotificationSoundComponent from "@/components/notifications/notification/create/controls/NotificationSoundComponent";
import NotificationLEDComponent from "@/components/notifications/notification/create/controls/NotificationLEDComponent";
import { ledOptions, soundOptions } from "@/constants";

const onesignal = require("images/onesignal.png");

const NotificationDisplaySettingsComponent = props => {
    const { title, hasOneSignal, data, change } = props;
    const { wake_screen, sound_url, led_color } = data;

    const getSoundList = () => {
        let soundList = [...soundOptions];
        const soundUrl = data["sound_url"];
        const hasPusheSound =
            soundUrl != null
                ? soundList.find(item => item.key === soundUrl)
                : true;
        if (!hasPusheSound) soundList.push({ key: soundUrl });
        return soundList;
    };

    return (
        <div className="notif-block">
            <FormSection name="data">
                <p className="notif-block__title">
                    {title}
                    {hasOneSignal && (
                        <img src={onesignal} className="notif-onesignal--img" />
                    )}
                </p>
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <Checkbox
                            className="mui-checkbox"
                            label="روشن شدن صفحه نمایش"
                            defaultChecked={wake_screen}
                            onCheck={(event, checked) => {
                                change("data.wake_screen", checked ? true : "");
                            }}
                            checked={wake_screen}
                            inputStyle={{ marginLeft: "0" }}
                        />
                        <p style={{ fontSize: "0.75rem", marginTop: "10px" }}>
                            صفحه نمایش کاربر هنگام دریافت نوتیفیکیشن روشن
                            می‌شود.
                        </p>
                    </div>
                    <Field
                        name="sound_url"
                        component={NotificationSoundComponent}
                        soundUrl={sound_url}
                        soundOptions={getSoundList()}
                    />
                    <Field
                        name="led_color"
                        component={NotificationLEDComponent}
                        ledColor={led_color}
                        ledOptions={ledOptions}
                    />
                </div>
            </FormSection>
        </div>
    );
};

NotificationDisplaySettingsComponent.propTypes = {
    title: PropTypes.string,
    hasOneSignal: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    change: PropTypes.func.isRequired,
};
NotificationDisplaySettingsComponent.defaultProps = {
    title: "تنظیمات نمایش",
};

export default withErrorCatcher(NotificationDisplaySettingsComponent);
