import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import BaseModal from "@/components/utilities/modal/baseModal";
import {
    FRAMEWORK_CAST_VALUE_INTO_NAME,
    MODAL_THEME,
    MODAL_TYPES,
} from "@/actions/_types";
import RenameApplicationModal from "application/components/modals/rename-application-modal";
import AndroidStudio from "images/app-builder/android-studio.svg";
import Basic4Android from "images/app-builder/basic4android.png";
import Cordova from "images/app-builder/cordova.svg";
import Eclipse from "images/app-builder/eclipse.svg";
import Unity from "images/app-builder/unity.svg";
import ReactNative from "images/app-builder/react-native.png";
import AppBuilder from "images/app-builder/default.png";
import cafeBazaar from "images/market/cafe-bazaar.jpg";
import googlePlay from "images/market/google-play.jpg";
import myket from "images/market/myket.jpg";
import NumberFormat from "react-number-format";
import HighlightWithCopy from "@/components/utils/HighlightWithCopy";

const uuid = require("uuid/v4");

class ApplicationModals extends React.Component {
    constructor(props) {
        super(props);
    }

    renderModal() {
        const {
            activeModal,
            handleExportCSV,
            currentApplication,
            closeModal: onClose,
            handleDeleteApplication,
            handleUpdateApplication,
            responseErrorInProg: error,
        } = this.props;

        const { package_name, app_token } = currentApplication;

        let title = "";
        let desc = "";
        let body = "";

        switch (activeModal.modalType) {
            // ------------------------------------------------
            //         delete application modal
            // ------------------------------------------------
            case MODAL_TYPES.APPLICATION.DELETE:
                title = "حذف اپلیکیشن";
                desc = "آیا مطمئن هستید؟";
                body = (
                    <div>
                        تمامی اطلاعات اپلیکیشن &nbsp;{package_name}&nbsp; حذف می
                        شود و دیگر نمی توانید آن ها را بازیابی کنید.
                    </div>
                );
                return (
                    <BaseModal
                        theme={MODAL_THEME.RED}
                        title={title}
                        useDefaultFooter={true}
                        desc={desc}
                        body={body}
                        error={error}
                        onClose={onClose}
                        onAccept={handleDeleteApplication}
                    />
                );

            // ------------------------------------------------
            //         update application modal
            // ------------------------------------------------
            case MODAL_TYPES.APPLICATION.UPDATE_RENAME:
                return (
                    <RenameApplicationModal
                        error={error}
                        applicationName={this.props?.currentApplication?.name}
                        onClose={onClose}
                        onAccept={handleUpdateApplication}
                    />
                );

            // ------------------------------------------------
            //         set application framework  modal
            // ------------------------------------------------
            case MODAL_TYPES.APPLICATION.SELECTING_FRAMEWORK: {
                const frameworks = [
                    { name: 1, icon_id: AndroidStudio },
                    { name: 2, icon_id: Basic4Android },
                    { name: 3, icon_id: Cordova },
                    { name: 4, icon_id: Eclipse },
                    { name: 5, icon_id: Unity },
                    { name: 6, icon_id: ReactNative },
                    { name: 7, icon_id: AppBuilder },
                ];

                const renderBody = (
                    <div className="main-block">
                        <header className="main-block__header">
                            <p
                                style={{
                                    marginRight: "1rem",
                                    marginBottom: "3rem",
                                    marginTop: "3rem",
                                }}
                                className="main-block__title"
                            >
                                اپلیکیشن خود را با کدام مورد ایجاد کرده اید؟
                            </p>
                            <div className="main-block__util">
                                {frameworks.map(value => {
                                    const framework =
                                        FRAMEWORK_CAST_VALUE_INTO_NAME[
                                            value.name
                                        ];
                                    return (
                                        <div
                                            className="col-md-4 col-sm-6 col-xs-6"
                                            key={uuid()}
                                            style={{ maxWidth: "200px" }}
                                        >
                                            <div
                                                className={"card-b"}
                                                style={{ padding: "10px" }}
                                                onClick={() =>
                                                    handleUpdateApplication({
                                                        framework: value.name,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={value.icon_id}
                                                    style={{
                                                        display: "block",
                                                        width: "80%",
                                                        height: "auto",
                                                    }}
                                                />
                                                <br />
                                                <p>{framework}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </header>
                    </div>
                );

                return (
                    <BaseModal
                        customClass="modal--market"
                        modalSize="md"
                        onClose={onClose}
                    >
                        {renderBody}
                    </BaseModal>
                );
            }
            // ------------------------------------------------
            //         get installation history per app modal
            // ------------------------------------------------
            case MODAL_TYPES.APPLICATION.INSTALLATION_HISTORY:
                title = "خروجی csv نصب‌ها";
                body = (
                    <div>
                        عملیات ایجاد خروجی مدتی طول خواهد کشید. به محض آماده‌شدن
                        فایل خروجی، این فایل به شما ایمیل خواهد شد. لطفا شکیبا
                        باشید
                    </div>
                );
                return (
                    <BaseModal
                        theme={MODAL_THEME.BLUE}
                        title={title}
                        useDefaultFooter={true}
                        body={body}
                        error={error}
                        onClose={onClose}
                        onAccept={() => handleExportCSV()}
                    />
                );

            // ------------------------------------------------
            //         application in market modal
            // ------------------------------------------------
            case MODAL_TYPES.APPLICATION.MARKET: {
                const sampleData = [
                    {
                        image: cafeBazaar,
                        title: "کافه بازار",
                        installed: 1987562,
                        version: "2.8.0",
                        rate: "3.5",
                    },
                    {
                        image: googlePlay,
                        title: "گوگل‌پلی",
                        installed: 890128,
                        version: "4.4.1",
                        rate: "5",
                    },
                    {
                        image: myket,
                        title: "مایکت",
                        installed: 98911321,
                        version: "0.18.7",
                        rate: "0.5",
                    },
                ];

                title = "در مارکت‌ها";
                body = (
                    <ul>
                        {sampleData.map((item, index) => (
                            <li className="market-item" key={index}>
                                <div>
                                    <div className="row">
                                        <div className="col">تعداد نصب‌ها:</div>
                                        <div className="col">
                                            <NumberFormat
                                                value={item.installed}
                                                thousandSeparator={true}
                                                displayType={"text"}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">شماره نسخه:</div>
                                        <div className="col">
                                            {item.version}
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    title={item.title}
                                />
                            </li>
                        ))}
                    </ul>
                );
                break;
            }
            case MODAL_TYPES.APPLICATION.APP_TOKEN: {
                title = "توکن اپلیکیشن";
                body = (
                    <ul>
                        <div>
                            با استفاده از این توکن می‌توانید دیتا اپلیکیشن خود
                            را به پوشه منتقل کنید.
                            <br />
                            <br />
                            <HighlightWithCopy
                                textToCopy={app_token}
                                // eslint-disable-next-line react/no-children-prop
                                children={app_token}
                            />
                        </div>
                    </ul>
                );
                return (
                    <BaseModal
                        theme={MODAL_THEME.BLUE}
                        title={title}
                        useDefaultFooter={false}
                        body={body}
                        error={error}
                        onClose={onClose}
                        onAccept={() => handleExportCSV()}
                    />
                );
            }
        }
    }

    render() {
        return <div>{this.renderModal()}</div>;
    }
}

export default withErrorCatcher(ApplicationModals);

ApplicationModals.propTypes = {};
