import { store } from "@/store";

/**
 * Accepts a serviceName and return true if it is the current service name of the user in auth.profile.plan.name,
 * otherwise returns false
 *
 * @param serviceName
 * @returns {boolean}
 */
const currentService = serviceName => {
    let result = false;

    try {
        const planName = store.getState().auth.profile.plan.name;
        result = planName === serviceName;
    } catch (e) {
        // ignore it!
    }

    return result;
};

export { currentService };
