import React, { useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import FloatingActionButton from "material-ui/FloatingActionButton";
import { PencilThick } from "@/components/utils/lib/svgicons";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";

const ProfileEmail = () => {
    const [enableEditEmail, toggleEnableEditEmail] = useState(false);
    const [newEmail, setNewEmail] = useState(false);

    return (
        <>
            <div className="col-xs-12">
                <FloatingActionButton
                    mini={true}
                    className="edit-email"
                    onClick={() => {
                        if (!enableEditEmail) toggleEnableEditEmail(true);
                        else {
                            toggleEnableEditEmail(false);
                        }
                    }}
                >
                    <PencilThick />
                </FloatingActionButton>

                <span onClick={() => toggleEnableEditEmail(!enableEditEmail)}>
                    <Field
                        name="email"
                        className="profile__email"
                        component={TextField}
                        hintText="پست الکترونیکی (ایمیل)"
                        floatingLabelText="پست الکترونیکی (ایمیل)"
                        fullWidth={true}
                        disabled={true}
                        style={{ cursor: "pointer" }}
                    />
                </span>
            </div>

            {enableEditEmail && (
                <div>
                    <div className="col-xs-12">
                        <Field
                            name="new_email"
                            component={TextField}
                            hintText="ایمیل جدید"
                            floatingLabelText="ایمیل جدید"
                            fullWidth={true}
                            onChange={e => setNewEmail(e.target.value)}
                            type="email"
                        />
                    </div>
                    <div className="col-xs-12">
                        <p className="text-md color-error">
                            برای استفاده از ایمیل جدید باید لینک فعال سازی
                            فرستاده شده به ایمیل‌ فعلی‌تان را کلیک نمایید.
                        </p>
                        <p className="text-md color-error">
                            سپس لینک فعال‌سازی دیگری به ایمیل جدیدی
                            {newEmail && `(${newEmail})`} که وارد کرده‌اید ارسال
                            می‌شود که نیاز است که لینک فعال‌سازی درون آن را نیز
                            کلیک نمایید.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default withErrorCatcher(ProfileEmail);
