import { humanizeToFarsi } from "@/components/utilities";

export const WebGeneratorInfoCard = (data, allData, history, platform) => {
    const value = function (type) {
        let newData;
        if (!data) {
            if (type === "historical") return [];
            return null;
            // eslint-disable-next-line no-prototype-builtins
        } else if (data && data.constructor.prototype.hasOwnProperty("map")) {
            newData = [...data];
        } else {
            newData = { ...data };
        }

        if (type === "apps" || type === "unreleased_apps") newData = allData;

        return humanizeToFarsi(newData[type]);
    };
    const cardsList = [
        {
            value: value("installs"),
            icon: "mobile_color",
            info: [
                {
                    title: "تعداد مشترکین",
                    value: value("installs"),
                },
            ],
        },
        {
            value: value("apps"),
            icon: "app_color",
            info: [
                {
                    title: "کل وب سایت ها",
                    value: value("apps"),
                },
                {
                    title: "وب سایت های غیرفعال",
                    value: value("unreleased_apps"),
                },
            ],
            buttonText: "اپلیکیشن جدید",
            onClick: () => history.push(`/application/${platform}/create`),
        },
        {
            value: value("recipients"),
            icon: "notification_color",
            info: [
                {
                    title: "نوتیفیکیشن فرستاده شده",
                    value: value("recipient"),
                },
            ],
            buttonText: "ارسال نوتیفیکیشن",
            onClick: () => history.push("/notification/web/create"),
        },
    ];
    return cardsList;
};
