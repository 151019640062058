import { webNotificationNormalizer } from "@/components/notifications/notification/normalize";
import { accept } from "@/components/utilities/data/normalizer";

export const automationToApiNormalizer = {
    name: accept,
    automation_id: accept,
    segment_id: accept,
    notification: {
        data: {
            title: accept,
            content: accept,
            big_title: accept,
            big_content: accept,
            ticker: accept,
            notif_icon: accept,
            icon: accept,
            image: accept,
            buttons: accept,
            action: accept,
            summary: accept,
            sound_url: accept,
            wake_screen: accept,
            led_color: accept,
            delay_until: accept,
        },
        custom_content: accept,
        time_to_live: accept,
        collapse_key: accept,
    },
};

export const webAutomationToApiNormalizer = {
    name: accept,
    automation_id: accept,
    segment_id: accept,
    notification: {
        data: {
            ...webNotificationNormalizer.data,
            action: accept,
            buttons: accept,
        },
        custom_content: accept,
        time_to_live: accept,
        collapse_key: accept,
    },
};

export const automationToFormNormalizer = {
    notificationTypes: accept,
    fullContentType: accept,
    name: accept,
    automation_id: accept,
    segment_id: accept,
    notification: {
        data: {
            title: accept,
            content: accept,
            big_title: accept,
            big_content: accept,
            ticker: accept,
            notif_icon: accept,
            icon: accept,
            image: accept,
            buttons: accept,
            action: accept,
            summary: accept,
            sound_url: accept,
            wake_screen: accept,
            led_color: accept,
            delay_until: accept,
        },
        custom_content: accept,
        time_to_live: accept,
        collapse_key: accept,
    },
};
