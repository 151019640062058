import Util from "@/utils";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { FRAMEWORK_CAST_VALUE_INTO_NAME } from "@/actions/_types";

const AppPlatform = ({ app, isSmallSize, colSize, handleFrameworkOrGuide }) => {
    let classes = !isSmallSize
        ? `table-col table-col--${colSize} color-gray-95`
        : "small-appBuilder";
    const { framework } = app;
    const framework_name = FRAMEWORK_CAST_VALUE_INTO_NAME[framework];

    return (
        <div className={classes}>
            <a
                href={void 0}
                onClick={() =>
                    handleFrameworkOrGuide("set", { ...app, page: null })
                }
                style={{ cursor: "pointer" }}
            >
                <img
                    src={Util._generateImageUrl(framework_name)}
                    alt={framework_name}
                    data-tip={
                        framework_name === "AppBuilder"
                            ? "اپلیکیشن ساز"
                            : framework_name
                    }
                    style={{ maxWidth: "40px", maxHeight: "30px" }}
                />
            </a>
        </div>
    );
};

AppPlatform.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
};

AppPlatform.defaultProps = {
    app: {},
    colSize: 15,
};

export default withErrorCatcher(AppPlatform);
