import React from "react";
import AndroidAutomationWizard from "@/components/notifications/automation/create/android/AndroidAutomationWizard";
import PageLoader from "@/components/utils/PageLoader";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux/es/redux";
import { retrieveAutomation } from "@/actions/automation";
import { withErrorCatcher } from "@/components/utilities";
import { retrieveNotification } from "@/actions/notifications";
import { isEmpty } from "lodash";
import { AUTOMATION_WIZARD_MODE } from "@/components/notifications/automation/constants";
import { useSelector } from "react-redux";
import WebAutomationWizard from "@/components/notifications/automation/create/web/webAutomationWizard";

const AutomationEditPage = props => {
    const {
        match: { params },
        history,
        retrieveAutomation,
        selectedNotifications,
        retrieveNotification,
    } = props;

    const platform = props?.match?.params?.platform;
    const { automationId } = params;
    const automation = useSelector(
        state => state?.automation?.automation?.[automationId] ?? {},
    );
    const { wrapper_id } = automation || {};
    const notification = wrapper_id ? selectedNotifications[wrapper_id] : {};

    const WizardComponent = PLATFORMS[platform];

    return (
        <PageLoader
            perform={onFail => {
                if (automationId && isEmpty(automation)) {
                    retrieveAutomation(automationId, null, onFail);
                }
                if (wrapper_id && isEmpty(notification)) {
                    retrieveNotification(
                        { notificationId: wrapper_id },
                        null,
                        onFail,
                    );
                }
            }}
            history={history}
            isReady={!isEmpty(automation) && !isEmpty(notification)}
        >
            <WizardComponent
                history={history}
                wizardMode={AUTOMATION_WIZARD_MODE.EDIT}
                automation={automation}
                notification={notification}
            />
        </PageLoader>
    );
};

const PLATFORMS = {
    android: AndroidAutomationWizard,
    web: WebAutomationWizard,
};

const mapStateToProps = state => {
    const {
        inprog: { notifications = {} },
    } = state;
    return {
        selectedNotifications: notifications,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { retrieveAutomation, retrieveNotification },
        dispatch,
    );
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(AutomationEditPage),
);
