import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import "styles/modules/_mod-fixed-header.scss";

const FixedPageHeader = props => {
    const { children } = props;

    return (
        <div className="fixed-header">
            <div className="fixed-header__inner">{children}</div>
        </div>
    );
};

export default withErrorCatcher(FixedPageHeader);
