import breakpoint from "@/components/utils/responsive/breakpoint";

/**
 * Use this func with onscroll event
 * to minimize and expand header on mobile version
 * when scrolling the page
 */
export const handleMinimizeHeader = () => {
    let cachedScrollTop = 0;

    const root = document.getElementById("root");
    const header = document.getElementById("header");
    const mainWrap = document.getElementById("mainWrap");

    return () => {
        if (root && header && mainWrap && !breakpoint(window, "md")) {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop ||
                0;
            let headerHeight = header.getBoundingClientRect().height;

            if (
                scrollTop > cachedScrollTop &&
                cachedScrollTop > headerHeight / 2
            ) {
                // ** GO DOWN
                header.classList.remove("header_in");
                root.classList.remove("header_opened");
                header.classList.add("header_out");
                root.classList.add("header_closed");
            } else {
                // ** GO UP
                header.classList.remove("header_out");
                root.classList.remove("header_closed");
                header.classList.add("header_in");
                root.classList.add("header_opened");
            }
            cachedScrollTop = scrollTop;
        }
    };
};
