export const FULL_CONTENT_TYPE_MODE = {
    IMAGE: "image",
    CONTENT: "content",
};

export const NOTIFICATION_TYPE = {
    NOTIF: "notif",
    CUSTOM_CONTENT: "json",
    HTML: "html",
};

export const FILTER_MODE = {
    FILTERS: "filters",
    TOPICS: "topics",
    SEGMENTS: "segment_ids",
    CSV: "csv",
};

export const NOTIF_TIMING_MODE = {
    ASAP: {
        value: "1",
        label: "در اسرع وقت",
    },
    ETA: {
        value: "2",
        label: "زمان‌بندی ارسال",
    },
};

export const NOTIF_PUBLISH_MODE = {
    ASAP: {
        value: "1",
        label: "به محض دریافت",
    },
    OPEN_APP: {
        value: "2",
        label: "هنگام باز کردن اپلیکیشن",
    },
    SCHEDULED_TIME: {
        value: "3",
        label: "زمان‌بندی نمایش",
    },
};

export const API_FILTER_ID_KEYS = {
    INSTANCE_ID: "instance_id",
    DEVICE_ID: "device_id",
    ANDROID_ID: "android_id",
    GOOGLE_AD_ID: "gaid",
    CUSTOM_ID: "custom_id",
    PHONE_NUMBER: "phone_number",
    EMAIL: "email",
};
