import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
    clearFetchedAutomationItems,
    retrieveAutomationList,
} from "@/actions/automation";
import { clearFetchedGeofenceItems } from "@/actions/geofence";
import { PLATFORM_NUMS, PLATFORMS } from "@/constants";

export const useFetchAutomationItems = (platform = PLATFORMS.ANDROID) => {
    const dispatch = useDispatch();

    const platformNum = PLATFORM_NUMS[platform];

    const [loading, toggleLoading] = useState(true);
    const [firstReq, updateFirstReq] = useState(true);
    const { hasMore, items = [] } = useSelector(
        state => state?.automation?.automations ?? {},
    );

    const onFinish = () => {
        updateFirstReq(false);
        toggleLoading(false);
    };

    const handleFetchAutomationItems = () => {
        if (!hasMore) return toggleLoading(false);
        if (loading && !firstReq) return;

        toggleLoading(true);

        retrieveAutomationList({ platformNum, onFinish })(dispatch);
    };

    const handleRefreshAutomationItems = () => {
        toggleLoading(true);

        const finish = () => {
            onFinish();
            window.scrollTo(0, 0);
        };

        clearFetchedGeofenceItems({
            onFinish: () =>
                retrieveAutomationList({
                    platformNum,
                    onFinish: finish,
                    offset: 0,
                    hasMore: true,
                })(dispatch),
        })(dispatch);
    };

    useEffect(() => {
        // handleFetchAutomationItems();
        window.scrollTo(0, 0);

        return clearFetchedAutomationItems()(dispatch);
    }, [dispatch]);

    return {
        hasMore,
        loading,
        automationItems: items,
        handleFetchAutomationItems,
        handleRefreshAutomationItems,
    };
};
