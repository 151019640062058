import React, { useState, useEffect } from "react";
import HeaderMenuDropDown from "@/components/app/header/HeaderMenuDropDown";
import NotificationCenter from "@/components/app/header/NotificationCenter";
import { withRouter } from "react-router-dom";
import HeaderButtons from "@/components/app/header/HeaderButtons";
import HeaderTemporaryTopBar from "@/components/app/header/HeaderTemporaryTopBar";
import Breadcrumb from "@/components/app/header/Breadcrumb";
import HeaderAppFilter from "@/components/app/header/HeaderAppFilter";
import { handleMinimizeHeader } from "@/components/app/header/headerEventHandlers";
import eventHandler from "@/components/utils/events/eventHandler";

const Header = props => {
    const { openSidebarMobileView, breadcrumb } = props;

    const [displayTempBar, toggleDisplayTempBar] = useState(false);
    const [openMenu, toggleMenu] = useState(false);
    const [openNotificationCenter, toggleNotificationCenter] = useState(false);

    useEffect(() => {
        eventHandler.on("scroll", handleMinimizeHeader(), {
            key: "handleMinimizeHeader",
        });
        // Bellow would clean up the handler on component unmount
        return () => eventHandler.remove("resize", "handleMinimizeHeader");
    }, []);

    return (
        <div className="header" id="header">
            <div className="header-wrap">
                {/* Temporary Top Bar above the header */}
                {displayTempBar && (
                    <HeaderTemporaryTopBar
                        handleClose={() => toggleDisplayTempBar(false)}
                    />
                )}

                <HeaderMenuDropDown
                    open={openMenu}
                    closeMenu={() => toggleMenu(false)}
                />

                <NotificationCenter
                    open={openNotificationCenter}
                    handleClose={() => toggleNotificationCenter(false)}
                />

                <div className="header-top">
                    <Breadcrumb breadcrumb={breadcrumb} />

                    <HeaderAppFilter />

                    <HeaderButtons
                        openSidebarMobileView={openSidebarMobileView}
                        openMenu={toggleMenu}
                        openNotificationCenter={() =>
                            toggleNotificationCenter(true)
                        }
                    />
                </div>
            </div>

            {/* Portal Breadcrumb & AppFilterSearch into this div to show in mobile view */}
            <div id="headerMobileOnlyContainer" />
        </div>
    );
};

export default withRouter(Header);
