import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import ApplicationDetails from "application/create/android/partials/ApplicationDetails";
import { reduxForm } from "redux-form";
import {
    createValidator,
    isAndroidPackageName,
    isRequired,
} from "@/components/utils/validation";
import ApplicationCredentials from "application/create/android/partials/ApplicationCredentials";

const AndroidAppCreateStep = props => {
    const { change } = props;

    return (
        <>
            <ApplicationDetails />
            <ApplicationCredentials change={change} />
        </>
    );
};

const validators = createValidator({
    name: [isRequired],
    package_name: [isRequired, isAndroidPackageName],
    framework: [isRequired],
    credentials: value => {
        if (!!value && value.server_key && value.client_key) {
            return undefined;
        }
        return {
            server_key:
                value && value.server_key ? undefined : "این فیلد الزامی است",
            client_key:
                value && value.client_key ? undefined : "این فیلد الزامی است",
        };
    },
});

const AndroidAppCreateStepForm = reduxForm({
    form: "application_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidAppCreateStep);

export default withErrorCatcher(AndroidAppCreateStepForm);
