import React, { useEffect, useState } from "react";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import RefreshButton from "@/components/utilities/commons/RefreshButton";
import CardPageFixedHeader from "@/components/utilities/commons/CardPageFixedHeader";

function NotificationFixedHeader(props) {
    const { onRefresh, isLoading, history, platform } = props;

    // Only show animation of the refresh page when click on it
    const [refreshClicked, toggleRefreshClicked] = useState(false);
    useEffect(() => {
        if (!isLoading) toggleRefreshClicked(false);
    }, [isLoading]);

    return (
        <CardPageFixedHeader title="نوتیفیکیشن‌ها">
            <div style={{ display: "flex", alignItems: "center" }}>
                <RefreshButton
                    animationIsRunning={refreshClicked && isLoading}
                    tooltipPosition={"right"}
                    onClick={() => {
                        toggleRefreshClicked(true);
                        typeof onRefresh === "function" && onRefresh();
                    }}
                />
                <span>
                    <FlatButton
                        className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                        rippleColor={blue900}
                        label="نوتیفیکیشن‌ جدید"
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                            history.push(`/notification/${platform}/create/`)
                        }
                    />
                </span>
            </div>
        </CardPageFixedHeader>
    );
}

export default NotificationFixedHeader;
