import React, { useState, useMemo } from "react";
import { useHandleNotificationList } from "@/components/notifications/notification/list/common/useHandleNotificationList";
import { CardListWrapper } from "@/components/utilities/modules/CardList";
import NotificationFixedHeader from "@/components/notifications/notification/list/common/NotificationFixedHeader";
import NotificationFilters from "../common/NotificationFilters";
import WebNotificationListItems from "./notification/WebNotificationListItems";

export const NOTIFICATION_TYPES = {
    TOGETHER: 1,
    NOTIFICATION: 2,
    DRAFT: 3,
};

const WebNotifList = props => {
    const { history } = props;
    const [notifType, setNotifType] = useState(NOTIFICATION_TYPES.TOGETHER);

    const platform = "web";

    const {
        hasMore,
        isLoading,
        refresh,
        notificationList,
        retrieveNotificationList,
        applications,
    } = useHandleNotificationList(platform, notifType);

    const drafts = useMemo(() => {
        return notificationList.filter(notif => notif.is_draft === true);
    }, [notificationList]);

    const notifications = useMemo(() => {
        return notificationList.filter(notif => notif.is_draft === false);
    }, [notificationList]);

    return (
        <>
            <NotificationFixedHeader
                platform={platform}
                history={history}
                onRefresh={refresh}
                isLoading={isLoading}
            />

            <NotificationFilters
                notifProps={{
                    notifOptions: [
                        {
                            id: 0,
                            title: "همه اعلان‌ها",
                            value: NOTIFICATION_TYPES.TOGETHER,
                        },
                        {
                            id: 1,
                            title: "نوتیفیکیشن‌ها",
                            value: NOTIFICATION_TYPES.NOTIFICATION,
                        },
                        {
                            id: 2,
                            title: "پیش‌نویس‌ها",
                            value: NOTIFICATION_TYPES.DRAFT,
                        },
                    ],
                    notifOptionsToggle: setNotifType,
                }}
            />
            <CardListWrapper
                hasMore={hasMore}
                isLoading={isLoading}
                onEnter={retrieveNotificationList}
                key={notifType}
            >
                {(notifType === NOTIFICATION_TYPES.NOTIFICATION ||
                    notifType === NOTIFICATION_TYPES.DRAFT) && (
                    <WebNotificationListItems
                        notificationList={
                            notifType === NOTIFICATION_TYPES.NOTIFICATION
                                ? notifications
                                : drafts
                        }
                        applications={applications}
                        notifType={notifType}
                    />
                )}
                {notifType === NOTIFICATION_TYPES.TOGETHER && (
                    <WebNotificationListItems
                        notificationList={notificationList}
                        applications={applications}
                    />
                )}
            </CardListWrapper>
        </>
    );
};

export default WebNotifList;
