import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import {
    Card,
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardListWrapper,
    DateTimeComponent,
    DeleteModalAction,
    DisplayAppComponent,
    DisplayStatistics,
    ResendModalAction,
    StatisticsComponent,
    StatusComponent,
} from "@/components/utilities/modules/CardList";
import { tabs } from "@/components/email/email/list/android/tabs/tabs";
import { createStatusObj } from "@/components/notifications/notification/list/common/createCardStatus";
import NotificationStatistics from "@/components/notifications/notification/list/common/notification-cards/NotificationStatistics";
import { Notification } from "@/components/utils/lib/svgicons";
import { useInfiniteQuery, useMutation } from "react-query";
import {
    EMAILS_KEYS,
    fetchEmails,
    removeEmail,
    resendEmail,
} from "@/queries/email";
import {
    commonGetFetchMore,
    onSuccessFilterList,
    onSuccessAppendToList,
} from "@/queries/constants";
import EmailPagePlaceholder from "@/components/email/email/list/EmailPagePlaceholder";
import EmailPageHeader from "@/components/email/email/common/EmailPageHeader";

const WebEmailList = props => {
    const { history } = props;
    const platform = "web";

    const { status, fetchMore, canFetchMore, isFetchingMore, data } =
        useInfiniteQuery(EMAILS_KEYS.LIST(), fetchEmails, {
            cacheTime: Infinity,
            staleTime: 0,
            getFetchMore: commonGetFetchMore,
        });

    const [
        resendEmailMutation,
        { error: resendError, isLoading: resendIsLoading },
    ] = useMutation(resendEmail, {
        onSuccess: data => onSuccessAppendToList(EMAILS_KEYS.LIST(), data),
    });

    const [removeMutation, { error: removeError, isLoading: removeIsLoading }] =
        useMutation(removeEmail, {
            onSuccess: wrapperId =>
                onSuccessFilterList(
                    EMAILS_KEYS.LIST(),
                    "wrapper_id",
                    wrapperId,
                ),
        });

    if (data?.[0]?.length === 0 && !isFetchingMore)
        return <EmailPagePlaceholder history={history} />;

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <EmailPageHeader
                pageTitle={"ارسال ایمیل برای وب سایت"}
                platform={platform}
                history={history}
            />
            <CardListWrapper
                hasMore={canFetchMore}
                isLoading={isFetchingMore || status === "loading"}
                onEnter={() => canFetchMore && !isFetchingMore && fetchMore()}
            >
                {data?.map((group, i) => (
                    <React.Fragment key={i}>
                        {group?.map(n => {
                            const statusList = createStatusObj(n);

                            return (
                                <Card key={n?.["wrapper_id"]}>
                                    <CardHeader
                                        StatusComponent={StatusComponent}
                                        statusProps={{
                                            statusList,
                                        }}
                                        DateTimeComponent={DateTimeComponent}
                                        dateTimeProps={{
                                            dateTime: n?.["created_at"],
                                        }}
                                    />
                                    <CardBody
                                        tabs={tabs}
                                        tabProps={{
                                            notification: n,
                                        }}
                                        fixedComponents={[DisplayAppComponent]}
                                        fixedProps={{
                                            appIds: n?.["app_ids"],
                                            platform: platform,
                                        }}
                                    />
                                    <CardFooter>
                                        <StatisticsComponent
                                            statistics={n?.statistics}
                                            meta={[
                                                {
                                                    key: "accurate_recipients",
                                                    icon: "svg_user_2",
                                                    name: "تعداد مخاطبین",
                                                },
                                                // {key: 'delivered', icon: 'svg_eye', name: 'تعداد دریافت‌ها'},
                                            ]}
                                        />
                                        <div className="notif-action-buttom-wrapper">
                                            <DisplayStatistics
                                                statisticsComponent={
                                                    NotificationStatistics
                                                }
                                                statisticsComponentProps={{
                                                    wrapperId:
                                                        n?.["wrapper_id"],
                                                }}
                                                disabled={true}
                                            />
                                            <CardActions
                                                actions={[
                                                    {
                                                        id: "1",
                                                        type: "button",
                                                        tip: "ارسال دوباره",
                                                        // eslint-disable-next-line react/display-name
                                                        iconComponent: () => (
                                                            <Notification viewBox="0 0 21 21" />
                                                        ),
                                                        modalAction:
                                                            ResendModalAction,
                                                        modalActionProps: {
                                                            title: "ارسال دوباره ایمیل",
                                                            body: (
                                                                <div>
                                                                    آیا از ارسال
                                                                    دوباره ایمیل
                                                                    {
                                                                        n?.[
                                                                            "wrapper_id"
                                                                        ]
                                                                    }
                                                                    مطمئن هستید؟
                                                                    ‍‍‍
                                                                </div>
                                                            ),
                                                            error: resendError
                                                                ?.response
                                                                ?.data,
                                                            isLoading:
                                                                resendIsLoading,
                                                            onAccept: () =>
                                                                resendEmailMutation(
                                                                    n?.[
                                                                        "wrapper_id"
                                                                    ],
                                                                ),
                                                        },
                                                    },
                                                    {
                                                        id: "2",
                                                        type: "link",
                                                        tip: "فوروارد",
                                                        iconName: "svg_forward",
                                                        url: `/email/${platform}/forward/${n?.["wrapper_id"]}`,
                                                    },
                                                    {
                                                        id: "3",
                                                        type: "button",
                                                        tip: "حذف",
                                                        iconName: "svg_trash",
                                                        modalAction:
                                                            DeleteModalAction,
                                                        modalActionProps: {
                                                            title: "حذف ایمیل",
                                                            body: (
                                                                <div>
                                                                    آیا از پاک
                                                                    شدن ایمیل
                                                                    &nbsp;
                                                                    {
                                                                        n?.[
                                                                            "wrapper_id"
                                                                        ]
                                                                    }
                                                                    &nbsp; مطمئن
                                                                    هستید؟ ‍‍‍
                                                                </div>
                                                            ),
                                                            isLoading:
                                                                removeIsLoading,
                                                            onAccept: () =>
                                                                removeMutation(
                                                                    n?.[
                                                                        "wrapper_id"
                                                                    ],
                                                                ),
                                                            error: removeError
                                                                ?.response
                                                                ?.data,
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </CardFooter>
                                </Card>
                            );
                        })}
                    </React.Fragment>
                ))}
            </CardListWrapper>
        </div>
    );
};

export default withErrorCatcher(WebEmailList);
