import { useEffect, useState } from "react";
import { fetchInvoiceItems, clearFetchedInvoices } from "@/actions/financial";
import { store } from "@/store";
import { useDispatch, useSelector } from "react-redux";

export const useFetchInvoiceItems = () => {
    const dispatch = useDispatch();

    const [firstReq, updateFirstReq] = useState(true);
    const [loading, toggleLoading] = useState(true);

    const { items, hasMore } = useSelector(
        state => state?.financial?.invoices ?? {},
    );

    useEffect(() => {
        // Clear all fetched invoices on component unmount
        return () => clearFetchedInvoices()(dispatch);
    }, [dispatch]);

    const handleFetchPaymentReceipts = () => {
        if (!hasMore) return toggleLoading(false);
        if (loading && !firstReq) return;

        toggleLoading(true);

        const onFinish = () => {
            if (firstReq) updateFirstReq(false);
            toggleLoading(false);
        };

        setTimeout(() => {
            fetchInvoiceItems({ onFinish })(dispatch, store.getState);
        }, 100);
    };

    return [loading, { items, hasMore }, handleFetchPaymentReceipts];
};
