/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GenericTextFieldComponent from "@/components/utilities/commons/wizard/components/GenericTextFieldComponent";
import GenericSelectFieldComponent from "@/components/utilities/commons/wizard/components/GenericSelectFieldComponent";
import { createValidator, isRequired } from "@/components/utils/validation";
import { fetchSegmentation } from "@/actions/segmentations";
import { selectOptionBuilderFromArray } from "@/components/utils/finals/selectOptionBuilder";
import {
    AUTOMATION_FORM_NAME,
    AUTOMATION_WIZARD_MODE,
} from "@/components/sms/constants";
import { PLATFORM_CODE_WEB } from "@/constants";

const WebAutomationStep1 = props => {
    const {
        passToSteps: { wizardMode },
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchSegmentation({ platform: PLATFORM_CODE_WEB })(dispatch);
    }, []);

    const segmentationList = useSelector(
        s => s?.segmentations?.segmentationList ?? [],
    );

    return (
        <form className="notif-step1">
            <div className="wizard-block">
                <GenericTextFieldComponent
                    fieldName="name"
                    labelText="نام پیامک اتوماتیک*"
                />
            </div>

            <div className="wizard-block">
                <GenericSelectFieldComponent
                    fieldName="segment_id"
                    title="انتخاب دسته‌بندی کاربران"
                    placeholder="انتخاب دسته‌بندی کاربران*"
                    options={selectOptionBuilderFromArray(
                        segmentationList,
                        "name",
                        "segment_id",
                    )}
                    readOnly={wizardMode === AUTOMATION_WIZARD_MODE.EDIT}
                />
            </div>
        </form>
    );
};

const validators = createValidator({
    name: isRequired,
    segment_id: isRequired,
});

const AndroidAutomationStep1RF = reduxForm({
    form: AUTOMATION_FORM_NAME.web,
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebAutomationStep1);

export default withRouter(withErrorCatcher(AndroidAutomationStep1RF));
