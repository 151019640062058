export function CheckArrayItemListWithOutZero(arr) {
    if (Array.isArray(arr)) {
        let lessZero = arr.filter(e => e !== 0);
        if (lessZero.length === 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}
