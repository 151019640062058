import React, { useState } from "react";
import {
    ImageUploader,
    UPLOAD_IMAGE_TYPE,
    withErrorCatcher,
} from "@/components/utilities";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { uploadFile } from "@/actions/app";
import { useDispatch } from "react-redux";
import BaseModal from "@/components/utilities/modal/baseModal";

const UploadIconSiteModal = props => {
    const dispatch = useDispatch();
    const [urlIcon, setUrlIcon] = useState(null);

    const { onClose, onAccept } = props;
    return (
        <>
            <BaseModal
                onClose={() => onClose()}
                title="بارگذاری آیکن سایت"
                desc="حداکثر حجم مجاز برای بارگذاری 1 مگابایت می باشد"
                body={
                    <div className="modal-body">
                        <div className="row">
                            <div
                                className="col-md-12"
                                style={{ height: "240px", marginBottom: 20 }}
                            >
                                <div className="upload-pro-field">
                                    <ImageUploader
                                        uploadFile={(...props) => {
                                            const [_, ...othersProps] = props;
                                            uploadFile(
                                                "application-icon",
                                                ...othersProps,
                                            )(dispatch);
                                        }}
                                        title="انتخاب عکس"
                                        subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                        type={UPLOAD_IMAGE_TYPE.PICTURE}
                                        imageUrl={urlIcon}
                                        aspectRatio={1}
                                        onRemoveFile={_url => setUrlIcon(null)}
                                        onFileUploaded={uploadedUrl =>
                                            setUrlIcon(uploadedUrl)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                footer={
                    <div className="modal-footer">
                        <FlatButton
                            disabled={!urlIcon}
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            label="تایید"
                            onClick={() => {
                                onAccept({ icon_url: urlIcon }, onClose());
                            }}
                        />
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            label="انصراف"
                            onClick={onClose}
                        />
                    </div>
                }
            />
        </>
    );
};

export default withErrorCatcher(UploadIconSiteModal);
