import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import createWebSteps from "application/guides/web/web/steps";
import wordpressSteps from "application/guides/web/wordpress/steps";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import { WEB_FRAEMWORKS } from "application/constants";
import { useCheckIsWebSettings } from "application/guides/wizards/partials/useCheckIsWebSettings";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import WebSettingsPreview from "application/guides/web/web/partials/WebSettingsPreview";

const WebGuideWizard = ({ application = {}, history }) => {
    const { is_https = true, framework } = application;
    let steps = createWebSteps({ nonHttps: !is_https });

    let title = `مشاهده راهنمای (وب)`;
    let subtitle = "راهنمای گام به گام و تنظیمات ساخت وب‌سایت";

    if (framework === WEB_FRAEMWORKS.WORDPRESS) {
        steps = wordpressSteps;
    }

    const isWebSettings = useCheckIsWebSettings();

    return (
        <BaseWizard
            formName={isWebSettings ? "webpush-settings" : "guide_wizard"}
            title={title}
            subtitle={subtitle}
            history={history}
            steps={steps}
            footer={WizardFooter}
            showNavFooter={true}
            preview={isWebSettings && WebSettingsPreview}
            buttonOptions={{
                submitButtonName: isWebSettings ? "بی‌خیال" : "بستن",
                submitButtonAction: () => history.replace("/application/web"),
                nextButtonName: isWebSettings ? "ذخیره و بعدی" : "بعدی",
            }}
            hasReduxForm={isWebSettings}
            passToSteps={{
                application,
            }}
        />
    );
};

export default withErrorCatcher(WebGuideWizard);
