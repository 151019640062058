import React, { Fragment } from "react";
import PageLoader from "@/components/utils/PageLoader";
import { normalize } from "@/components/utils/normalize";
import AndroidEmailWizard from "@/components/email/email/create/android/AndroidEmailWizard";
import { useQuery } from "react-query";
import { EMAILS_KEYS, fetchEmail } from "@/queries/email";
import { makeEmailForwardDataNormalizer } from "@/components/email/normalize";
import { withEmailCredential } from "@/components/utilities/conf/withCredentialWrapper";
import WebEmailWizard from "@/components/email/email/create/web/WebEmailWizard";

const ForwardEmailPage = ({ match: { params }, history }) => {
    const emailId = params.id;
    const platform = params.platform;

    const { data, isError, refetch } = useQuery(
        [EMAILS_KEYS.GET(), emailId],
        fetchEmail,
        { cacheTime: 0, staleTime: Infinity },
    );

    const PlatformWizard = platform ? (
        PLATFORM_NOTIF_FORMS[platform]
    ) : (
        <Fragment />
    );

    return (
        <PageLoader
            history={history}
            perform={onFail => (isError ? onFail() : refetch())}
            isReady={!!data}
        >
            {data && (
                <PlatformWizard
                    history={history}
                    email={normalize(data, makeEmailForwardDataNormalizer)}
                />
            )}
        </PageLoader>
    );
};

const PLATFORM_NOTIF_FORMS = {
    android: AndroidEmailWizard,
    web: WebEmailWizard,
};

export default withEmailCredential(ForwardEmailPage);
