import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TermsAndPrivacyModal from "@/components/app/termsPrivacy/TermsAndPrivacyModal";
import { updateProfile } from "@/actions/auth";

const TERMS_LATEST_VERSION = 4;

export const useHandleTermsPrivacyModal = component => {
    const dispatch = useDispatch();
    const [state, updateState] = useState({ termsPrivacyModal: false });

    const termsStatus = useSelector(
        state => state.auth?.profile?.["terms_status"],
    );
    const email = useSelector(state => state.auth?.profile?.email);

    useEffect(() => {
        // User already accepted the terms number TERMS_LATEST_VERSION
        // Check for email to ensure profile is already in redux (from api call)
        if (termsStatus >= TERMS_LATEST_VERSION || !email) return;

        updateState({ ...state, termsPrivacyModal: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component]);

    if (state?.termsPrivacyModal) {
        const termsPrivacyCallback = () => {
            updateProfile({ terms_status: TERMS_LATEST_VERSION, email })(
                dispatch,
            );
            updateState({ ...state, termsPrivacyModal: false });
        };

        return [
            TermsAndPrivacyModal,
            {
                onClose: termsPrivacyCallback,
                onAccept: termsPrivacyCallback,
            },
        ];
    }

    return [null, {}];
};
