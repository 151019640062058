import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import SpecificationsChart from "@/components/users/installations/common/SpecificationsChart";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { fetchDashboardData } from "@/actions/dashboard";
import IosInstallationsTable from "@/components/users/installations/ios/components/IosInstallationsTable";
import {
    fetchInstallations,
    faveInstallation,
    unFaveDevice,
    fetchFavorites,
} from "@/actions/installations";
import { faveInstallationInProgress } from "@/actions/inprog";
import { sendRapidNotification } from "@/actions/notifications";
import isEmpty from "lodash/isEmpty";
import { CircleLoader } from "@/components/utilities";
import { openModal, toggleDemo } from "@/actions/app";
import InstallationPagePlaceholder from "@/components/users/installations/common/InstallationPagePlaceholder";

class IosInstallationsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userHasInstall: false,
        };
    }

    componentDidMount() {
        const { fetchDashboardData, fetchFavorites, fetchInstallations } =
            this.props;

        fetchInstallations();
        fetchDashboardData();
        fetchFavorites();
    }

    static getDerivedStateFromProps(props, state) {
        // Todo: Reducer should be refactored then change this part to a proper implementation
        // Once it becomes true it never changes till reBuilding the component again
        if (!state.userHasInstall && !isEmpty(props.installationList)) {
            return { userHasInstall: true };
        }

        return null;
    }

    render() {
        const {
            dashboard,
            platform,
            fetchInstallations,
            installationLoading,
            installationList,
            favList,
            favPusheIds,
            faveInstallationInProgress,
            responseErrorInProg,
            currentInstallation,
            sendRapidNotification,
            faveInstallation,
            unFaveDevice,
        } = this.props;
        const { userHasInstall } = this.state;

        if (
            isEmpty(installationList) &&
            installationLoading &&
            !userHasInstall
        ) {
            return <CircleLoader size={50} thickness={4} />;
        } else if (isEmpty(installationList) && !userHasInstall) {
            return <InstallationPagePlaceholder />;
        }

        return (
            <>
                <SpecificationsChart
                    dashboard={dashboard}
                    platform={platform}
                />
                <IosInstallationsTable
                    favList={favList}
                    favPusheIds={favPusheIds}
                    fetchInstallations={fetchInstallations}
                    installationLoading={installationLoading}
                    installationList={installationList}
                    faveInstallationInProgress={faveInstallationInProgress}
                    responseErrorInProg={responseErrorInProg}
                    currentInstallation={currentInstallation}
                    sendRapidNotification={sendRapidNotification}
                    faveInstallation={faveInstallation}
                    unFaveDevice={unFaveDevice}
                />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { platform } = ownProps;
    let appId = state.app.appId;
    if (!appId) {
        appId = "all";
    }
    const { demo } = state.app;
    const dashboard = state[platform].dashboard[appId] || {};
    const installationList =
        (state[platform].installations || {}).installList || [];
    const favList = (state[platform].favorites || {}).favList || [];
    const favPusheIds = favList.reduce(
        (acc, curr) => [...acc, curr.pushe_id],
        [],
    );
    const { installationLoading } = state.loading;
    const responseErrorInProg = state.inprog.error;
    const currentInstallation = state.inprog.favorite;

    return {
        demo,
        favList,
        favPusheIds,
        dashboard,
        installationList,
        installationLoading,
        responseErrorInProg,
        currentInstallation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchDashboardData,
            fetchInstallations,
            faveInstallationInProgress,
            sendRapidNotification,
            faveInstallation,
            unFaveDevice,
            fetchFavorites,
            openModal,
            toggleDemo,
        },
        dispatch,
    );
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(IosInstallationsList),
);
