import React from "react";
import Loading from "@/components/utils/loading";
import uuid from "uuid/v4";
import ReactTooltip from "react-tooltip";
import DriverWrapper from "@/components/app/introduction/driverWrapper";
import {
    mobileSteps,
    steps,
} from "@/components/app/introduction/partials/introduction";
import { updateConsoleSettings } from "@/actions/auth";
import { withErrorCatcher } from "@/components/utilities";
import AllInstallationsNewChart from "@/components/newDashboard/component/AllInstallationsNewChart/AllInstallationsNewChart";
import DailyInstallationsNewChart from "@/components/newDashboard/component/DailyInstallationsNewChart/DailyInstallationsNewChart";
import { useWebDashboard } from "@/components/newDashboard/web/useWebDashbord";
import { useDispatch } from "react-redux";
import CardFlex from "@/components/newDashboard/component/listCard/CardFlex";
import { WebGeneratorInfoCard } from "@/components/newDashboard/web/config/WebGenreatorInfoCards";
import { optionsChartAllInstall } from "@/components/newDashboard/component/AllInstallationsNewChart/optionsAllInstalChart";
import { optionsChartDaily } from "@/components/newDashboard/component/DailyInstallationsNewChart/optionsDailyChart";
import { WebSeriesOfAll } from "@/components/newDashboard/web/config/AllInstallChartWeb";
import { webSeriesOfDaily } from "@/components/newDashboard/web/config/DailyInstallWeb";
import { Alert } from "@material-ui/lab";

function WebDashboard(props) {
    const { history } = props;
    let { platform, data, allData, onLoading } = useWebDashboard();
    const dispatch = useDispatch();

    if (onLoading) {
        return (
            <div className="table-loading">
                <Loading className="loading-circle--blue-gray loading-circle-xlarge" />
            </div>
        );
    }
    return (
        <div className="main-block dashboard">
            <div className="container">
                <div className="container">
                    <div className="row">
                        {WebGeneratorInfoCard(
                            data,
                            allData,
                            history,
                            platform,
                        ).map(value => (
                            <CardFlex col={4} key={uuid()} {...value} />
                        ))}
                    </div>
                </div>
                <div className="container">
                    <Alert severity="info">
                        {" "}
                        در پلتفرم وب آمار حذف مشترکین فقط در زمان ارسال اعلان
                        محاسبه می شود و همین مورد دلیل ریزش نمودار در زمان ارسال
                        اعلان است
                    </Alert>

                    <div className="row">
                        <AllInstallationsNewChart
                            optionsChart={optionsChartAllInstall}
                            height={400}
                            title={" آمار کل مشترکین"}
                            seriesOfAll={WebSeriesOfAll}
                            data={data["historical"] || []}
                        />
                        <DailyInstallationsNewChart
                            optionsChart={optionsChartDaily}
                            height={400}
                            title={"آمار روزانه مشترکین"}
                            seriesOfDaily={webSeriesOfDaily}
                            data={data["historical"] || []}
                        />
                    </div>
                </div>
                <ReactTooltip className="tooltip" effect="solid" />
            </div>
            <DriverWrapper
                steps={steps}
                mobileSteps={mobileSteps}
                driverName={"viewed_introduction_1"}
                resolvedCallback={updateConsoleSettings({
                    viewed_introduction_1: true,
                })(dispatch)}
            />
        </div>
    );
}
export default withErrorCatcher(WebDashboard);
