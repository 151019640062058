import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveCredentials } from "@/actions/credentials";

export const useRetrieveCredentials = () => {
    const dispatch = useDispatch();
    const [inProgress, toggleInProgress] = useState(false);

    const credentials = useSelector(state => state?.credentials ?? []);

    useEffect(() => {
        toggleInProgress(true);

        retrieveCredentials({
            onSuccess: () => toggleInProgress(false),
            onFailure: () => toggleInProgress(false),
        })(dispatch);
    }, [dispatch]);

    return {
        inProgress,
        credentials,
    };
};
