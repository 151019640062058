import React from "react";
import css from "authentication/styles/signup.module.scss";
import { PUSHE_PRIVACY_LINK, PUSHE_TERMS_LINK } from "authentication/constants";

function SignUpTerms() {
    return (
        <>
            <a
                href={PUSHE_TERMS_LINK}
                className={css.termsPrivacyLink}
                target={"_blank"}
                rel="noreferrer"
            >
                شرایط استفاده
            </a>
            و
            <a
                href={PUSHE_PRIVACY_LINK}
                className={css.termsPrivacyLink}
                target={"_blank"}
                rel="noreferrer"
            >
                حریم خصوصی
            </a>
            را قبول دارم.
        </>
    );
}

export default SignUpTerms;
