import Colors from "@/components/utils/lib/colors";

export function WebSeriesOfAll(chart) {
    let allInstallations = {
        data: chart["transactional_installs"],
        label: "آمار کلی مشترکین",
        fill: true,
        backgroundColor: "transparent",
        borderColor: Colors.blue_0,
        tension: 0.2,
        borderWidth: 2,
        pointBackgroundColor: Colors.blue_0,
        pointBorderWidth: 2,
        hoverBackgroundColor: "rgba(0,0,0,0.10)",
    };

    return [allInstallations];
}
