import React from "react";
import { useSelector } from "react-redux";
import { withErrorCatcher } from "@/components/utilities";
import PieChart from "@/components/utils/charts/types/_pieChartBase";

const ApplicationsChart = () => {
    const platform = useSelector(state => state?.app?.platform ?? "android");
    const appId = useSelector(state => state?.app?.appId ?? "all");
    const { pushe_version = {}, app_version = {} } = useSelector(
        state => state?.[platform]?.dashboard?.[appId]?.distributions ?? {},
    );

    return (
        <div className="main-block">
            <div className="main-block__body">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <PieChart
                            data={app_version}
                            appId={appId}
                            title="نسخه اپلیکیشن"
                            description="کاربران شما چه نسخه‌هایی از اپلیکیشن‌تان را نصب کرده‌اند."
                            svg="#svg_app_version"
                            id="version_chart"
                            showPlaceholderOnAll={true}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <PieChart
                            data={pushe_version}
                            appId={appId}
                            title="ورژن پوشه"
                            description=""
                            svg="#svg_app_version"
                            id="version_chart"
                            showPlaceholderOnAll={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withErrorCatcher(ApplicationsChart);
