
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import FirstInstall from "application/create/base/FirstInstall";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`تست و عیب‌یابی`}</h1>
    <ul>
      <li parentName="ul"><em parentName="li">{`APNs authentication key`}</em>{` را از `}<em parentName="li">{`Apple Developer Account`}</em>{` خود گرفته و در کنسول فایربیس آپلود کنید.`}</li>
      <li parentName="ul">{`اپلیکیشن خود را (که کتابخانه پوشه به آن اضافه شده) روی یک گوشی موبایل نصب کنید`}</li>
      <li parentName="ul">{`مطمئن شوید که دستگاه به اینترنت وصل است`}</li>
      <li parentName="ul">{`اپلیکیشن را روی گوشی خود باز کنید`}</li>
    </ul>
    <p>{`بعد با فشردن دکمه‌ی `}<em parentName="p">{`«بررسی و دریافت نصب»`}</em>{` در پایین این صفحه منتظر بمانید تا نصب شما در سیستم پوشه شناخته شود.`}</p>
    <p>{`به طور معمول ۳ دقیقه زمان لازم است تا نصب شما دریافت شود.
در صورتی که بیش از این طول کشید روی دستگاه‌های دیگر نصب کنید و در صورتی که همچنان موفق نشدید مراحل را مجددا بررسی کنید.`}</p>
    <p>{`همواره می‌توانید با مراجعه به صفحه‌ی نصب‌ها از رسیدن نصب جدید مطلع شوید.`}</p>
    <FirstInstall {...props.passToSteps} mdxType="FirstInstall" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;