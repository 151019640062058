import { humanizeToEnglish } from "@/components/utilities";

export const optionsChartDaily = {
    maintainAspectRatio: false,
    legend: {
        display: true,
        labels: {
            textAlign: "bottom",
            usePointStyle: true,
            boxWidth: 10,
        },
    },
    tooltips: {
        xPadding: 20,
        yPadding: 20,
        intersect: false,
        backgroundColor: "rgba(0,0,0,0.3)",
        titleFontFamily: "iranyekanwebbold",
    },
    hover: {
        intersect: true,
        onHover: function (e, item) {
            if (item.length) {
                // const data =
                // item[0]._chart.config.data.datasets[0].data[item[0]._index];
            }
        },
        mode: "index",
    },
    plugins: {
        filler: {
            propagate: false,
        },
    },
    scales: {
        xAxes: [
            {
                ticks: {
                    fontSize: 11,
                    fontColor: "#bcbcbc",
                    fontFamily: "iranyekanwebbold",
                    padding: 7,
                },
                reverse: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawTicks: true,
                    padding: 5,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    precision: 0,
                    padding: 12,
                    beginAtZero: true,
                    fontColor: "#bcbcbc",
                    callback(value) {
                        return humanizeToEnglish(value);
                    },
                },
                gridLines: {
                    drawBorder: false,
                    display: true,
                    drawTicks: false,
                },
            },
        ],
    },
};
