export const BREADCRUMBS = {
    DASHBOARD: {
        LABEL: "داشبورد",
    },
    FINANCIAL: {
        LABEL: "امور مالی",
    },
    NOTIFICATION: {
        NOTIFICATION: {
            LABEL: "نوتیفیکیشن‌ها",
        },
        AUTOMATION: {
            LABEL: "نوتیفیکیشن‌های اتوماتیک",
        },
        GEOFENCE: {
            LABEL: "نوتیفیکیشن‌های جغرافیایی",
        },
    },
    USERS: {
        INSTALLATION: {
            LABEL: "نصب‌ها",
        },
        ANALYTIC: {
            LABEL: "کاربران",
        },
        SEGMENTATION: {
            LABEL: "دسته‌بندی",
        },
    },
    APPLICATION: {
        LABEL: "اپلیکیشن‌ها",
    },
    EMAIL: {
        EMAIL: {
            LABEL: "ایمیل‌ها",
        },
        AUTOMATION: {
            LABEL: "ایمیل‌های اتوماتیک",
        },
    },
    SMS: {
        SMS: {
            LABEL: "پیامک‌ها",
        },
        AUTOMATION: {
            LABEL: "پیامک‌های اتوماتیک",
        },
    },
};

export const PUSHE_WEBPUSH_OPTIONS = {
    showDialog: true,
    showBell: false,
    icon: "https://pushe.co/sites/default/files/1398-10/Stationary-10_0.svg",
    title: "عضویت در وب‌پوش",
    content: "می‌خواهید از آخرین اخبار و پیشنهادات آگاه شوید؟",
    position: "top-center",
    direction: "rtl",
    acceptText: "قبول می کنم",
    rejectText: "تمایلی ندارم",
    promptTheme: "pushe-prompt-theme2",
    mobilePosition: "top",
    dialogRetryRate: 0,
};
