
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`مجوز‌های لازم`}</h1>
    <p>{`در صورتی که میخواهید از امکانات لوکیشن محور پوشه مثل اعلان جغرافیایی استفاده کنید
باید دسترسی لوکیشن را از کاربر خود بگیرید.
متن زیر را کپی و به تگ
`}<inlineCode parentName="p">{`application`}</inlineCode>{`
اضافه کنید.`}</p>
    <blockquote>
      <p parentName="blockquote">{`برای `}<em parentName="p">{`اندروید‌های ۶ به بالا`}</em>{` بعضی دسترسی‌ها باید در زمان اجرا نیز از کاربر گرفته شود.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<!-- ارسال اعلان بر اساس اپراتور -->
<uses-permission android:name="android.permission.ACCESS_NETWORK_STATE"/>

<!-- آمار موقعیت کاربران و ارسال اعلان بر اساس موقعیت -->
<uses-permission android:name="android.permission.ACCESS_COARSE_LOCATION"/>
<!-- ارسال اعلان جغرافیایی یا جئوفنس -->
<uses-permission android:name="android.permission.ACCESS_FINE_LOCATION"/>

<!-- Android 10 or higher -->
<uses-permission android:name="android.permission.ACCESS_BACKGROUND_LOCATION"/>
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;