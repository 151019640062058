import React from "react";
import AndroidAppCreateStep from "application/create/android/AndroidAppCreateStep";
import { androidApplicationNormalizer } from "application/create/normalize";
import BaseWizard, {
    WIZARD_BTN_OPTIONS,
} from "@/components/utilities/commons/wizard/base/BaseWizard";
import { PLATFORMS } from "@/constants";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import { normalize } from "@/components/utils/normalize";
import { clearForm } from "@/actions/app";
import { createApplication } from "@/actions/applications";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import PropTypes from "prop-types";

const AndroidApplicationWizard = props => {
    const { history, createApplication, clearForm } = props;

    const platform = PLATFORMS.ANDROID;

    return (
        <BaseWizard
            formName="application_wizard"
            title="ساخت اپلیکیشن اندروید"
            subtitle="با این فرم می‌توانید اپلیکیشن جدید بسازید."
            history={history}
            steps={steps}
            footer={WizardFooter}
            buttonOptions={{
                [WIZARD_BTN_OPTIONS.SUBMIT_BTN_NAME]: "ایجاد اپلیکیشن",
            }}
            onUnmountCallback={() => {
                clearForm("application_wizard");
            }}
            submitAction={(values, _, onFailure) => {
                const normalizedValues = normalize(
                    values,
                    androidApplicationNormalizer,
                );
                createApplication(
                    { data: normalizedValues, platform },
                    ({ app_id }) => {
                        history.replace(
                            `/application/${platform}/guide/${app_id}`,
                        );
                    },
                    onFailure,
                );
            }}
            hasReduxForm={true}
        />
    );
};

const steps = [{ component: AndroidAppCreateStep }];

AndroidApplicationWizard.propTypes = {
    history: PropTypes.object.isRequired,
    createApplication: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearForm,
            createApplication,
        },
        dispatch,
    );
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AndroidApplicationWizard),
);
