import React from "react";
import AndroidDashboard from "@/components/newDashboard/android/AndroidDashboard";
import WebDashboard from "@/components/newDashboard/web/WebDashboard";
import PropTypes from "prop-types";

const DashboardPage = ({ match: { params }, ...props }) => {
    const platform = params.platform;
    const DashboardPages = Dashboard_PAGE[platform];

    return <DashboardPages platform={platform} {...props} />;
};

const Dashboard_PAGE = {
    android: AndroidDashboard,
    web: WebDashboard,
};
DashboardPage.propTypes = {
    match: PropTypes.object.isRequired,
};

DashboardPage.defaultProps = {};
export default DashboardPage;
