import React, { useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { MODAL_THEME } from "@/actions/_types";
import BaseModal from "@/components/utilities/modal/baseModal";
import { deleteNotification } from "@/actions/notifications";

const DeleteModalAction = props => {
    const { title, desc, body, onClose, wrapperId } = props;

    const [error, setError] = useState("");
    const [isLoading, toggleIsLoading] = useState(false);

    const onAcceptCallback = () => {
        const onSuccess = () => {
            toggleIsLoading(false);
        };
        const onFailure = e => {
            toggleIsLoading(false);
            setError(e);
        };

        toggleIsLoading(true);

        deleteNotification(wrapperId, onSuccess, onFailure)();
    };

    return (
        <BaseModal
            theme={MODAL_THEME.RED}
            title={title}
            desc={desc}
            error={error}
            useDefaultFooter={true}
            body={body}
            onClose={onClose}
            isLoading={isLoading}
            onAccept={onAcceptCallback}
        />
    );
};

export default withErrorCatcher(DeleteModalAction);
