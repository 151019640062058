import React from "react";
import css from "authentication/styles/signin.module.scss";

function SignIn2Fa(props) {
    const { goBackFrom2FAPage } = props;

    return (
        <>
            <div className={css.enabled2fa}>
                <p>ورود دو مرحله‌ای با ایمیل برای شما فعال می‌باشد.</p>
                <p>
                    می توانید از طریق لینک ارسال شده به ایمیل‌تان وارد کنسول
                    شوید.
                </p>
            </div>

            <span className={css.enabled2faLink} onClick={goBackFrom2FAPage}>
                بازگشت به صفحه ورود
            </span>
        </>
    );
}

export default SignIn2Fa;
