/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { ReduxSimpleTextField, withErrorCatcher } from "@/components/utilities";
import { FormControlLabel, Switch } from "@material-ui/core";

function WebGoogleAnalytics(props) {
    const {
        utm_source = null,
        utm_campaign = null,
        utm_medium = null,
    } = props.actionIsActive;
    const { change } = props;
    const [isAnalytics, setIsAnalytics] = useState(false);
    const handleChange = e => {
        if (isAnalytics && !e.target.checked) {
            change("data.utm_source", null);
            change("data.utm_campaign", null);
            change("data.utm_medium", null);
        }
        setIsAnalytics(e.target.checked);
    };
    useEffect(() => {
        if (utm_source || utm_campaign || utm_medium) {
            setIsAnalytics(true);
        }
    }, []);
    return (
        <>
            <h4 className={"notif-block__header"}>
                تنظیمات آنالیتیکس
                <div className={"Subtitle"}>
                    <FormControlLabel
                        style={{ paddingRight: 10, marginTop: 7 }}
                        control={
                            <Switch
                                checked={isAnalytics}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        labelPlacement="start"
                        label=" فعال کردن تنظیمات گوگل آنالیتیکس "
                    />
                </div>
            </h4>
            {isAnalytics && (
                <div className="notif-block__body">
                    <div className="row">
                        <div className="col-sm-9 col-xs-12">
                            <Field
                                name={"utm_source"}
                                label={"utm_source"}
                                component={ReduxSimpleTextField}
                                helperText={" "}
                            />
                        </div>
                        <div className="col-sm-9 col-xs-12">
                            <Field
                                name={"utm_campaign"}
                                label={"utm_campaign"}
                                component={ReduxSimpleTextField}
                                helperText={"{id}"}
                            />
                        </div>
                        <div className="col-sm-9 col-xs-12">
                            <Field
                                name={"utm_medium"}
                                label={"utm_medium"}
                                component={ReduxSimpleTextField}
                                helperText={"webpush"}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default withErrorCatcher(WebGoogleAnalytics);
