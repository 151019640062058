import React from "react";
import { NOTIFICATION_MODE } from "@/actions/_types";
import AndroidNotifWizard from "@/components/notifications/notification/create/android/AndroidNotifWizard";
import WebNotifWizard from "@/components/notifications/notification/create/web/WebNotifWizard";
import IosNotifWizard from "@/components/notifications/notification/create/ios/IosNotifWizard";

const SendNotificationPage = ({ match: { params }, history }) => {
    const PlatformWizard = PLATFORM_NOTIF_FORMS[params.platform];
    return (
        <PlatformWizard
            history={history}
            notificationMode={NOTIFICATION_MODE.NORMAL}
        />
    );
};

const PLATFORM_NOTIF_FORMS = {
    android: AndroidNotifWizard,
    web: WebNotifWizard,
    ios: IosNotifWizard,
};

export default SendNotificationPage;
