import myStorage from "@/myStorage";
import { store } from "@/store";
import { getConfigs } from "@/index";
import { PLATFORM_NUMS } from "@/constants";
import { queryCache } from "react-query";
import axios from "axios";
import { handleErrors } from "@/actions/_handleErrors";

export const LIMIT = 20;

export const getBaseUrl = async (type = "root") =>
    (await getConfigs()).api[type];

export const getToken = () =>
    myStorage.getItem("token") || store.getState()?.auth?.token;

export const getHeaders = (options = {}) =>
    options?.isPublic ? {} : { Authorization: `jwt ${getToken()}` };

export const getPlatformName = () => store.getState()?.app?.platform;

export const getPlatformNum = () => PLATFORM_NUMS?.[getPlatformName()];

export const createUrl = async (path, type = "root") =>
    `${await getBaseUrl(type)}${path}`;

export const axiosWithAuth = () => {
    const axiosInstance = axios.create({
        timeout: 600000,
        headers: { ...getHeaders() },
    });

    axiosInstance.interceptors.response.use(
        r => r,
        e => {
            handleErrors(e);
            return Promise.reject(e);
        },
    );

    return axiosInstance;
};

export const commonGetFetchMore = (lastGroup, allGroup) => {
    const totalOffset = allGroup?.reduce((acc, curr) => {
        acc += curr?.length;
        return acc;
    }, 0);
    const resultLen = lastGroup?.length;
    return resultLen === LIMIT ? totalOffset : false;
};

export const onSuccessAppendToList = (queryKey, newData, flatList = false) => {
    return queryCache.setQueryData(queryKey, oldData => {
        return Array.isArray(oldData)
            ? [flatList ? newData : [newData], ...oldData]
            : [];
    });
};

export const onSuccessUpdateItemInList = (
    queryKey,
    filterKey,
    newData,
    flatList = false,
) => {
    const key = newData?.[filterKey];
    return queryCache.setQueryData(queryKey, groups => {
        if (flatList) {
            return groups?.map(item => {
                if (item?.[filterKey] === key) return { ...item, ...newData };
                else return item;
            });
        } else {
            return groups?.map(list => {
                return list?.map(item => {
                    if (item?.[filterKey] === key)
                        return { ...item, ...newData };
                    else return item;
                });
            });
        }
    });
};
export const onSuccessCustomUpdateItemInList = (
    queryKey,
    params,
    itemKey,
    filterKey,
    newData,
    flatList = false,
) => {
    const id = params?.id;

    return queryCache.setQueryData(queryKey, groups => {
        if (flatList) {
            return groups?.map(item => {
                if (item?.id === id)
                    return { ...item, [itemKey]: { ...newData } };
                else return item;
            });
        } else {
            return groups?.map(list => {
                return list?.map(item => {
                    if (item?.id === id) return { ...item, ...newData };
                    else return item;
                });
            });
        }
    });
};
export const onSuccessFilterList = (
    queryKey,
    filterKey,
    filterValue,
    flatList = false,
) => {
    return queryCache.setQueryData(queryKey, groups => {
        if (flatList) {
            return groups?.filter(item => item?.[filterKey] !== filterValue);
        } else {
            return groups?.map(list => {
                return list?.filter(item => item?.[filterKey] !== filterValue);
            });
        }
    });
};
