import React, { useState } from "react";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { withErrorCatcher } from "@/components/utilities";
import { NavLink } from "react-router-dom";
import { NotificationThick } from "@/components/utils/lib/svgicons";
import { PLATFORMS } from "@/constants";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import EmailIcon from "@material-ui/icons/Email";
import TextsmsIcon from "@material-ui/icons/Textsms";

const useStyles = makeStyles(theme => ({
    outlined: {
        border: `2px solid ${theme.palette.dashboard.blue}`,
        color: theme.palette.dashboard.blue,
        fontSize: "1rem",
        fontWeight: "bold",
        borderRadius: 0,
        transition: "all .3s",
        "&:hover": {
            color: theme.palette.dashboard.white,
            backgroundColor: theme.palette.dashboard.blue,
        },
        "&:hover svg": {
            fill: `${theme.palette.dashboard.white} !important`,
        },
    },
    startIcon: {
        marginLeft: "8px",
        marginRight: 0,
        "& svg": {
            transition: "all .3s",
            fill: `${theme.palette.dashboard.blue} !important`,
            width: "18px !important",
            height: "18px !important",
        },
    },
    paper: {
        margin: "10px",
        borderRadius: 0,
    },
    menuItemRoot: {
        color: theme.palette.dashboard.blue,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    iconListRoot: {
        minWidth: "20px",
        "& svg": {
            fill: `${theme.palette.dashboard.blue} !important`,
        },
    },
    itemTextRoot: {
        textAlign: "right",
        paddingRight: "10px",
        "& span": {
            fontWeight: "bold",
            fontSize: ".97rem",
        },
    },
}));

const SidebarButtons = props => {
    const { platform, history } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const styles = {
        headerBtn: {
            minWidth: "150px",
        },
    };

    return (
        <div>
            {platform === PLATFORMS.IOS && (
                <NavLink to={`/notification/${platform}/create`}>
                    <FlatButton
                        className="mui-btn mui-btn--md mui-btn--border mui-btn--blue"
                        id="sendNotifBtn"
                        style={styles.headerBtn}
                        rippleColor={blue900}
                        icon={
                            <NotificationThick
                                viewBox="0 0 21 21"
                                style={{ width: "21px", height: "21px" }}
                            />
                        }
                        label="ارسال نوتیفیکیشن"
                    />
                </NavLink>
            )}

            {platform === PLATFORMS.ANDROID && (
                <>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        disableElevation={true}
                        variant="outlined"
                        startIcon={
                            <NotificationThick
                                viewBox="0 0 21 21"
                                style={{ width: "21px", height: "21px" }}
                            />
                        }
                        classes={{
                            outlined: classes.outlined,
                            startIcon: classes.startIcon,
                        }}
                        onClick={e => setAnchorEl(e.currentTarget)}
                    >
                        ارسال پیام
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        getContentAnchorEl={null}
                        className={classes.paper}
                    >
                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                history.push(
                                    `/notification/${platform}/create`,
                                );
                            }}
                            classes={{ root: classes.menuItemRoot }}
                        >
                            <ListItemIcon
                                classes={{ root: classes.iconListRoot }}
                            >
                                <SendIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ root: classes.itemTextRoot }}
                                primary="ارسال نوتیفیکیشن"
                            />
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                history.push(`/email/${platform}/create`);
                            }}
                            classes={{ root: classes.menuItemRoot }}
                        >
                            <ListItemIcon
                                classes={{ root: classes.iconListRoot }}
                            >
                                <EmailIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ root: classes.itemTextRoot }}
                                primary="ارسال ایمیل"
                            />
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                history.push(`/sms/${platform}/create`);
                            }}
                            classes={{ root: classes.menuItemRoot }}
                        >
                            <ListItemIcon
                                classes={{ root: classes.iconListRoot }}
                            >
                                <TextsmsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ root: classes.itemTextRoot }}
                                primary="ارسال پیامک"
                            />
                        </MenuItem>
                    </Menu>
                </>
            )}
            {platform === PLATFORMS.WEB && (
                <>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        disableElevation={true}
                        variant="outlined"
                        startIcon={
                            <NotificationThick
                                viewBox="0 0 21 21"
                                style={{ width: "21px", height: "21px" }}
                            />
                        }
                        classes={{
                            outlined: classes.outlined,
                            startIcon: classes.startIcon,
                        }}
                        onClick={e => setAnchorEl(e.currentTarget)}
                    >
                        ارسال پیام
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        getContentAnchorEl={null}
                        className={classes.paper}
                    >
                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                history.push(
                                    `/notification/${platform}/create`,
                                );
                            }}
                            classes={{ root: classes.menuItemRoot }}
                        >
                            <ListItemIcon
                                classes={{ root: classes.iconListRoot }}
                            >
                                <SendIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ root: classes.itemTextRoot }}
                                primary="ارسال نوتیفیکیشن"
                            />
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                history.push(`/email/${platform}/create`);
                            }}
                            classes={{ root: classes.menuItemRoot }}
                        >
                            <ListItemIcon
                                classes={{ root: classes.iconListRoot }}
                            >
                                <EmailIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ root: classes.itemTextRoot }}
                                primary="ارسال ایمیل"
                            />
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                history.push(`/sms/${platform}/create`);
                            }}
                            classes={{ root: classes.menuItemRoot }}
                        >
                            <ListItemIcon
                                classes={{ root: classes.iconListRoot }}
                            >
                                <TextsmsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ root: classes.itemTextRoot }}
                                primary="ارسال پیامک"
                            />
                        </MenuItem>
                    </Menu>
                </>
            )}
        </div>
    );
};

export default withErrorCatcher(SidebarButtons);
