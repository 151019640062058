/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

const EmptyChartImagePlaceHolder = ({
    src,
    massage,
    fullWidth,
    title,
    svg,
}) => {
    const ref = useRef();
    const addEvent = () => {
        ref.current.classList.add("hover");
    };

    const removeEvent = () => {
        ref.current.classList.remove("hover");
    };
    useEffect(() => {
        ref.current.addEventListener("mouseenter", addEvent);
        ref.current.addEventListener("mouseleave", removeEvent);

        return () => {
            ref.current.removeEventListener("mouseenter", addEvent);
            ref.current.removeEventListener("mouseleave", removeEvent);
        };
    }, []);
    return (
        <div ref={ref} className={classNames("chart-image-placeholder")}>
            {title && (
                <header className="card-g__header">
                    <p className="card-g__title">
                        {svg && (
                            <svg>
                                <use xlinkHref={svg}></use>
                            </svg>
                        )}
                        {title}
                    </p>
                </header>
            )}
            <div
                className={classNames("chart-image-place-holder-inner", {
                    "full-width": fullWidth,
                })}
            >
                <img src={src} />
                <div className="chart-image-placeholder-text">{massage}</div>
            </div>
        </div>
    );
};

export default EmptyChartImagePlaceHolder;
