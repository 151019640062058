import React, { Fragment } from "react";
import PageLoader from "@/components/utils/PageLoader";
import { NOTIFICATION_MODE } from "@/actions/_types";
import { normalize } from "@/components/utils/normalize";
import { androidForwardNotificationNormalizer } from "@/components/notifications/notification/normalize";
import AndroidNotifWizard from "@/components/notifications/notification/create/android/AndroidNotifWizard";
import WebNotifWizard from "@/components/notifications/notification/create/web/WebNotifWizard";
import IosNotifWizard from "@/components/notifications/notification/create/ios/IosNotifWizard";
import { retrieveNotification } from "@/actions/notifications";
import { useDispatch, useSelector } from "react-redux";

const ForwardNotificationPage = ({ match: { params }, history }) => {
    const dispatch = useDispatch();
    const notificationId = params.notificationId;
    const platform = params.platform;

    const notification = useSelector(
        state => state?.inprog?.notifications?.[notificationId],
    );
    const PlatformWizard = platform ? (
        PLATFORM_NOTIF_FORMS[platform]
    ) : (
        <Fragment />
    );

    return (
        <PageLoader
            history={history}
            perform={onFail =>
                retrieveNotification(
                    { notificationId, showErrorToast: true },
                    null,
                    onFail,
                )(dispatch)
            }
            isReady={!!notification}
        >
            {notification && (
                <PlatformWizard
                    history={history}
                    notification={normalize(
                        notification,
                        androidForwardNotificationNormalizer,
                    )}
                    notificationMode={NOTIFICATION_MODE.NORMAL}
                />
            )}
        </PageLoader>
    );
};

const PLATFORM_NOTIF_FORMS = {
    android: AndroidNotifWizard,
    web: WebNotifWizard,
    ios: IosNotifWizard,
};

export default ForwardNotificationPage;
