/* eslint-disable no-unused-vars */
import React from "react";
import BaseModal from "@/components/utilities/modal/baseModal";
import { FileUploader, withErrorCatcher } from "@/components/utilities";

class InvoiceUploadDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            error: null,
        };
    }

    uploadFile = file => {
        /* const onSuccess = (url) => change('csv_id.id', url);

        const data = new FormData();
        data.append('items', file);
        data.append('type', csv_id.type);

        uploadNotificationCSVFile(data, {onSuccess})();*/
    };

    render() {
        const { app, onClose, onAccept } = this.props;
        const onSuccess = () => {
            onClose();
            this.setState({ error: null });
        };
        return (
            <BaseModal
                onClose={() => this.props.onClose()}
                title={`ارسال اصل فاکتور `}
                useDefaultFooter={false}
                body={
                    <>
                        <FileUploader
                            validateFile={file => {
                                // if (["image/*", "text/*","application/pdf"].indexOf(file?.type) < 0) {
                                //     return errorToast("خطا در آپلود فایل" , "نوع فایل انتخاب شده اشتباه می‌باشد")
                                // }
                                return true;
                            }}
                            onFileSelected={file => this.uploadFile(file)}
                            // onFileDeleted={() => change("csv_id.id", "")}
                            // hasInitFile={!!csv_id?.id}
                            inputAcceptFiles="image/*,text/*,application/pdf"
                        />
                    </>
                }
                onAccept={() => {}}
            />
        );
    }
}

export default withErrorCatcher(InvoiceUploadDialog);
