/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "@/components/utils/date-picker";
import { combineDateTime } from "@/utils";
import * as moment from "moment";
import "styles/modules/_mod-card-timing.scss";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

const DateTimePicker = ({
    value: _value,
    onChange,
    error,
    initDatetime,
    dateProps,
    timeProps,
}) => {
    const value =
        typeof _value === "string" && moment(_value).isValid()
            ? new Date(_value)
            : _value;

    const [time, setTime] = useState(value || initDatetime || null);
    const [date, setDate] = useState(value || initDatetime || null);

    useEffect(() => {
        if (!value && initDatetime) {
            setTime(new Date(new Date().getTime() + 30 * 60 * 1000));
            setDate(new Date());
        }
    }, []);

    useEffect(() => {
        if (isEqual(date, value) || isEqual(time, value)) return; // Prevents max call stack
        const datetime = moment.utc(combineDateTime(date, time)).toISOString();

        if (datetime) {
            onChange(datetime);
        }
    }, [date, time]);

    return (
        <div>
            <DatePicker
                type="date"
                minData={new Date()}
                date={date}
                onDateChange={date => {
                    setDate(date);
                    if (!initDatetime && !time) {
                        setTime(new Date(new Date().setHours(0, 0, 0, 0)));
                    }
                }}
                {...dateProps}
            />
            <DatePicker
                type="time"
                date={time}
                onDateChange={time => {
                    setTime(time);
                    if (!initDatetime && !date) {
                        setDate(new Date());
                    }
                }}
                {...timeProps}
            />
            {error && (
                <span
                    style={{
                        display: "block",
                        fontSize: ".8rem",
                        color: "red",
                        marginTop: "12px",
                    }}
                >
                    {error}
                </span>
            )}
        </div>
    );
};

DateTimePicker.defaultProps = {
    initDatetime: true,
};
DateTimePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    error: PropTypes.string,
    initDatetime: PropTypes.bool,
};

export default DateTimePicker;
