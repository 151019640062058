import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import defaultIcon from "images/app-icon/app-blank.png";
import { useSelector } from "react-redux";

const DisplayAppComponent = props => {
    const { platform, appIds } = props;

    let icon = defaultIcon;
    let name = "";

    const applications = useSelector(s => s?.[platform]?.applications ?? []);

    if (Array.isArray(appIds) && appIds.length === 1) {
        const app = applications.find(a => a.app_id === appIds[0]);
        icon = app?.icon_url ?? defaultIcon;
        name = app?.name;
    }

    return (
        <div className="card-list__app-wrapper">
            <span style={{ backgroundImage: `url(${icon || defaultIcon}` }} />
            <p className="text-fa"> {name || ""} </p>
        </div>
    );
};

export default withErrorCatcher(DisplayAppComponent);
