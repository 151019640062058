import { Field, FormSection } from "redux-form";
import React from "react";
import { UPLOAD_IMAGE_TYPE, withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { RadioButtonGroup, TextField } from "redux-form-material-ui";
import NotificationImageUpload from "@/components/utilities/commons/wizard/controls/NotificationImageUpload";
import oneSignalIcon from "images/onesignal.png";
import { RadioButton } from "material-ui/RadioButton";
import { FULL_CONTENT_TYPE_MODE } from "@/components/utilities/commons/wizard/constants";
import Checkbox from "material-ui/Checkbox";
import { SelectIconField } from "@/components/utilities/commons/select";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const NotificationDetailedContentComponent = ({
    title,
    hasOneSignal,
    uploadFile,
    fullContentType,
    notif_icon,
    change,
    preName,
}) => {
    const toggleUploaderProgress = status => {
        change("uploaderInProgress", status);
    };

    return (
        <div className="notif-block">
            <header>
                <p>
                    {" "}
                    {title}{" "}
                    {hasOneSignal && (
                        <img
                            src={oneSignalIcon}
                            className="notif-onesignal--img"
                        />
                    )}
                </p>
            </header>
            <div className="notif-block__body">
                <RadioGroup
                    row
                    name="fullContentType"
                    className="radiobtn-wrap-new"
                    defaultValue={fullContentType}
                    onChange={(e, value) => change("fullContentType", value)}
                >
                    <FormControlLabel
                        control={<Radio />}
                        className="mui-radiobtn"
                        value={FULL_CONTENT_TYPE_MODE.CONTENT}
                        label="متن کامل"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        className="mui-radiobtn"
                        value={FULL_CONTENT_TYPE_MODE.IMAGE}
                        label="عکس بزرگ"
                    />
                </RadioGroup>

                <RadioButtonGroup
                    name="fullContentType"
                    className="radiobtn-wrap"
                    valueSelected={fullContentType}
                    onChange={(e, value) => change("fullContentType", value)}
                >
                    <RadioButton
                        className="mui-radiobtn"
                        value={FULL_CONTENT_TYPE_MODE.CONTENT}
                        label="متن کامل"
                    />
                    <RadioButton
                        className="mui-radiobtn"
                        value={FULL_CONTENT_TYPE_MODE.IMAGE}
                        label="عکس بزرگ"
                    />
                </RadioButtonGroup>

                <FormSection name="data">
                    <div className="row row--nomargin">
                        <div className="col-xs-12">
                            <Field
                                name="big_title"
                                component={TextField}
                                className="mui-textfield"
                                floatingLabelText="تیتر کامل"
                                fullWidth={true}
                            />
                        </div>

                        {fullContentType === FULL_CONTENT_TYPE_MODE.CONTENT && (
                            <div className="col-xs-12">
                                <Field
                                    name="big_content"
                                    component={TextField}
                                    className="mui-textfield"
                                    floatingLabelText="متن کامل"
                                    fullWidth={true}
                                    multiLine={true}
                                    rows={5}
                                />
                            </div>
                        )}
                        {fullContentType === FULL_CONTENT_TYPE_MODE.IMAGE && (
                            <div>
                                <div
                                    className="col-xs-12"
                                    style={{
                                        height: "240px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <Field
                                            component={NotificationImageUpload}
                                            name="image"
                                            uploadFile={uploadFile}
                                            title="انتخاب عکس"
                                            subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                            type={UPLOAD_IMAGE_TYPE.IMAGE}
                                            toggleUploaderProgress={
                                                toggleUploaderProgress
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            style={
                                fullContentType === FULL_CONTENT_TYPE_MODE.IMAGE
                                    ? { marginTop: "30px" }
                                    : {}
                            }
                        >
                            <div className="col-sm-6 col-xs-12">
                                <Field
                                    name="summary"
                                    component={TextField}
                                    className="mui-textfield"
                                    floatingLabelText="خلاصه"
                                    fullWidth={true}
                                />
                            </div>

                            <div className="col-sm-6 col-xs-12">
                                <Field
                                    name="ticker"
                                    component={TextField}
                                    className="mui-textfield"
                                    floatingLabelText="متن نوار نوتیفیکیشن"
                                    fullWidth={true}
                                />
                            </div>
                        </div>

                        <div
                            className="col-xs-12"
                            style={{ marginTop: "20px" }}
                        >
                            <div className="row">
                                <div className="col-xs-6">
                                    <Checkbox
                                        className="mui-checkbox"
                                        label="آیکون نوار نوتیفیکیشن"
                                        defaultChecked={notif_icon}
                                        onCheck={(event, checked) =>
                                            change(
                                                preName
                                                    ? `${preName}.data.notif_icon`
                                                    : "data.notif_icon",
                                                checked ? "add_box" : "",
                                            )
                                        }
                                        inputStyle={{ marginLeft: "0" }}
                                        checked={notif_icon}
                                    />
                                </div>
                                {notif_icon && (
                                    <div className="col-xs-6">
                                        <Field
                                            component={SelectIconField}
                                            name="notif_icon"
                                            className="select--btncla disable-before"
                                            floatingLabelText="انتخاب آیکن کوچک اپلیکیشن"
                                            label="انتخاب آیکن کوچک اپلیکیشن"
                                            hintText="انتخاب آیکن کوچک اپلیکیشن"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </FormSection>
            </div>
        </div>
    );
};

NotificationDetailedContentComponent.propTypes = {
    title: PropTypes.string,
    change: PropTypes.func.isRequired,
    hasOneSignal: PropTypes.bool,
    uploadFile: PropTypes.func.isRequired,
    fullContentType: PropTypes.string,
    notif_icon: PropTypes.bool,
    formName: PropTypes.string,
    preName: PropTypes.string,
};
NotificationDetailedContentComponent.defaultProps = {
    title: "محتوای تکمیلی نوتیفیکیشن",
    hasOneSignal: false,
};

export default withErrorCatcher(NotificationDetailedContentComponent);
