import React, { useEffect } from "react";
import { withErrorCatcher } from "@/components/utilities";
import css from "authentication/styles/forgotpass.module.scss";
import logo from "images/svg/pushe-logo-horizontal.svg";
import { Link, Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { clearToken } from "@/actions/auth";

export const EmailVerificationPage = props => {
    const { history } = props;
    const urlParams = new URLSearchParams(history.location.search);
    const newEmail = urlParams.get("email");
    const dispatch = useDispatch();

    useEffect(() => {
        clearToken()(dispatch);
    }, []);

    return (
        <div className={css.wrapper}>
            <div className={css.innerWrapper}>
                <div className={css.banner}>
                    <div className={css.bannerWrapper}>
                        <img src={logo} alt={"pushe logo"} />
                    </div>
                </div>
                <div className={css.block}>
                    {newEmail ? (
                        <>
                            <Typography variant="body1">
                                جهت ادامه مراحل تغییر ایمیل، می‌بایست ایمیل
                                فعال‌سازی را که به آدرس {newEmail} ارسال شده است
                                را نیز تایید کنید.
                            </Typography>
                        </>
                    ) : (
                        <Redirect to="/signin" />
                    )}
                    <Link className={css.goToSignInPage} to={"/signin"}>
                        بازگشت به صفحه ورود
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default withErrorCatcher(EmailVerificationPage);
