import React, { useEffect, useState } from "react";
import { toFarsiNumbers, withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import {
    PTable,
    PTableBody,
    PTableHeader,
    PTableItem,
    PTableRow,
} from "@/components/utilities/modules/PTable";
import Util from "@/utils";
import { Eye, Trash, User } from "@/components/utils/lib/svgicons";
import { IconButton } from "@material-ui/core";
import { normalize } from "@/components/utils/normalize";
import { segmentationToFormNormalizer } from "@/components/users/segmentation/normalize";
import SegmentRemoveModal from "@/components/users/segmentation/components/SegmentRemoveModal";
import Loading from "@/components/utils/loading";
import { FetchUserRecipient } from "@/actions/segmentations";

const SegmentsTableBody = ({
    segmentationList,
    toggleSegmentModal,
    deleteSegmentation,
    isReFetchLoading,
}) => {
    const [segmentDelete, toggleDeleteModal] = useState({
        segment_id: null,
        name: "",
    });
    const [countRecipient, setCountRecipient] = useState([
        { count: null, segment_id: null },
    ]);
    const [isLoading, setIsLoading] = useState({
        hasLoad: false,
        segment_id: null,
    });

    async function getRecipientCount(segment_id, runLoading = true) {
        if (runLoading) {
            setIsLoading(() => ({ hasLoad: true, segment_id: segment_id }));
        }
        const resp = await FetchUserRecipient(segment_id);
        setCountRecipient(prevState => [
            ...prevState,
            { count: resp?.data.count, segment_id: segment_id },
        ]);
        setIsLoading(() => ({ hasLoad: false, segment_id: null }));
    }

    useEffect(() => {
        if (!isReFetchLoading) {
            setCountRecipient([{ count: null, segment_id: null }]);
        }
        getRecipientCount(segmentationList[0].segment_id, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReFetchLoading]);

    const recipientUI = segment_id => {
        let recipient;
        recipient = countRecipient.find(e => e.segment_id === segment_id);
        return (
            <>
                {isLoading.hasLoad && isLoading.segment_id === segment_id ? (
                    <Loading className="loading-circle--blue-gray loading-circle-medium" />
                ) : (
                    <>
                        {recipient ? toFarsiNumbers(recipient.count) : null}
                        <IconButton
                            className={
                                recipient?.segment_id === segment_id
                                    ? "table-btn-selected" + " " + "table-btn"
                                    : "table-btn"
                            }
                            data-tip="تعداد کاربر"
                            tooltipPosition="top-center"
                            onClick={() => getRecipientCount(segment_id)}
                        >
                            <User viewBox="0 0 21 21" />
                        </IconButton>
                    </>
                )}
            </>
        );
    };
    return (
        <div className={"segments-table-wrapper"}>
            <PTable columns={6} mobileSizeMax={"1199px"}>
                <PTableHeader>
                    <PTableItem expand={2}>نام دسته‌بندی</PTableItem>
                    <PTableItem expand={2}>تاریخ ایجاد</PTableItem>
                    <PTableItem>تعداد کاربر</PTableItem>
                    <PTableItem>مشاهده</PTableItem>
                    <PTableItem>حذف</PTableItem>
                </PTableHeader>
                <PTableBody>
                    {segmentationList.map(segment => {
                        const { segment_id, name, created_at } = segment;

                        return (
                            <PTableRow key={segment_id}>
                                <PTableItem expand={2} inMobileHeader>
                                    {name}
                                </PTableItem>
                                <PTableItem
                                    expand={2}
                                    mobileTitle={"تاریخ ایجاد: "}
                                >
                                    {Util._convertToLocalTimezone(created_at)}
                                </PTableItem>
                                <PTableItem
                                    mobileTitle={"تعداد کاربر: "}
                                    mobileChildren={recipientUI(segment_id)}
                                >
                                    {recipientUI(segment_id)}
                                </PTableItem>
                                <PTableItem inMobileHeader>
                                    <IconButton
                                        className="table-btn"
                                        data-tip="مشاهده دسته‌بندی"
                                        onClick={() => {
                                            const normalizedData = normalize(
                                                segment,
                                                segmentationToFormNormalizer,
                                            );
                                            // ** readonly: true is important to make the model readonly
                                            toggleSegmentModal({
                                                show: true,
                                                initialValues: normalizedData,
                                                readonly: true,
                                            });
                                        }}
                                    >
                                        <Eye viewBox="0 0 19 23" />
                                    </IconButton>
                                </PTableItem>
                                <PTableItem inMobileFooter>
                                    <IconButton
                                        className="table-btn"
                                        data-tip="حذف دسته‌بندی"
                                        onClick={() =>
                                            toggleDeleteModal({
                                                segment_id,
                                                name,
                                            })
                                        }
                                    >
                                        <Trash viewBox="0 0 19 23" />
                                    </IconButton>
                                </PTableItem>
                            </PTableRow>
                        );
                    })}
                </PTableBody>
            </PTable>

            {segmentDelete.segment_id && (
                <SegmentRemoveModal
                    onAccept={() => {
                        const callback = () =>
                            toggleDeleteModal({ name: "", segment_id: null });
                        deleteSegmentation(
                            segmentDelete.segment_id,
                            callback,
                            callback,
                        );
                    }}
                    onClose={() =>
                        toggleDeleteModal({ name: "", segment_id: null })
                    }
                    name={segmentDelete.name}
                />
            )}
        </div>
    );
};

SegmentsTableBody.proptypes = {
    segmentationList: PropTypes.array.isRequired,
    toggleSegmentModal: PropTypes.func.isRequired,
    deleteSegmentation: PropTypes.func.isRequired,
};

export default withErrorCatcher(SegmentsTableBody);
