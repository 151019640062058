import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { createValidator } from "@/components/utils/validation";
import StepFilterTabs from "@/components/utilities/commons/wizard/StepFilterTabs";
import filterTabs from "@/components/email/email/create/android/controls/filterTabs";

const WebEmailStep2 = props => {
    const { change } = props;

    return (
        <form>
            <StepFilterTabs change={change} filterTabs={filterTabs} />
        </form>
    );
};

const validators = createValidator({});

export default withRouter(
    withErrorCatcher(
        reduxForm({
            form: "web_email_wizard",
            validate: validators,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            onSubmitSuccess: (submitResult, dispatch, props) =>
                props.onSuccess(submitResult),
            onSubmitFail: (error, dispatch, submitError, props) =>
                props.onFailure(error),
        })(WebEmailStep2),
    ),
);
