export const GEOFENCE_WIZARD_MODE = {
    CREATE: 1,
    EDIT: 2,
};

export const GEOFENCE_TRIGGER_TYPES = {
    ENTER: 1,
    LEAVE: 2,
};

export const GEOFENCE_TRIGGER_TYPES_TRANSLATIONS = {
    [GEOFENCE_TRIGGER_TYPES.ENTER]: "ورود",
    [GEOFENCE_TRIGGER_TYPES.LEAVE]: "خروج",
};
