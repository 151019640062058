import React, { cloneElement } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const PTableRow = props => {
    const { children, columns, isHeader, className, mobileSizeMax } = props;

    const isMobile = useMediaQuery(`(max-width:${mobileSizeMax})`, {
        noSsr: true,
    });

    // If PTableRow is placed in PTableHeader only return the children
    if (isHeader) return children;

    const inMobileHeaderItems = [];
    const inMobileFooterItems = [];
    const mobileItems = [];

    React.Children.forEach(
        children,
        child => {
            const props = child.props;

            if (props?.["inMobileHeader"]) {
                inMobileHeaderItems.push(child);
            } else if (props?.["inMobileFooter"]) {
                inMobileFooterItems.push(child);
            } else {
                mobileItems.push(child);
            }
        },
        null,
    );

    return (
        <div className={`PTableRow ${className ?? ""}`}>
            {/* for desktop */}
            {!isMobile && (
                <div className="PTableRow__desktop">
                    {React.Children.map(
                        children,
                        child =>
                            cloneElement(child, { columns, isDesktop: true }),
                        null,
                    )}
                </div>
            )}

            {/* for mobile */}
            {isMobile && (
                <>
                    <div className="PTableRow__mobile-header">
                        {inMobileHeaderItems}
                    </div>
                    <div className="PTableRow__mobile-main">{mobileItems}</div>
                    <div className="PTableRow__mobile-footer">
                        {inMobileFooterItems}
                    </div>
                </>
            )}
        </div>
    );
};
