import { getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";
import to from "await-to-js";

export const retrieveCredentials =
    (options = {}) =>
    async dispatch => {
        const [error, response] = await to(
            getCommonApi().retrieveCredentials(),
        );

        if (error) {
            if (typeof options?.onFailure === "function") options.onFailure();
            return;
        }

        dispatch({
            type: actionTypes.CREDENTIAL.FETCH_CREDENTIALS,
            payload: response.data,
        });

        if (typeof options?.onSuccess === "function") options.onSuccess();
    };

export const createCredential = (data, options) => async dispatch => {
    const [error, response] = await to(getCommonApi().createCredential(data));

    if (error) {
        if (typeof options?.onFailure === "function") options.onFailure();
        return;
    }

    dispatch({
        type: actionTypes.CREDENTIAL.CREATE_CREDENTIALS,
        payload: response.data,
    });

    if (typeof options?.onSuccess === "function") options.onSuccess();
};

export const deleteCredential = (id, options) => async dispatch => {
    // eslint-disable-next-line no-unused-vars
    const [error, _] = await to(getCommonApi().deleteCredential(id));

    if (error) {
        if (typeof options?.onFailure === "function") options.onFailure();
        return;
    }

    dispatch({
        id,
        type: actionTypes.CREDENTIAL.DELETE_CREDENTIALS,
    });

    if (typeof options?.onSuccess === "function") options.onSuccess();
};
