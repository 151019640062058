import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import "styles/modules/_mod-segments.scss";
import PropTypes from "prop-types";

import BaseModal from "@/components/utilities/modal/baseModal";
import { MODAL_THEME } from "@/actions/_types";

const DeleteGeofenceModal = ({ onAccept, onClose, name }) => {
    return (
        <BaseModal
            theme={MODAL_THEME.RED}
            title={"حذف نوتیفیکیشن خودکار"}
            useDefaultFooter={true}
            body={<div>{`آیا از حذف  ${name} مطمئن هستید؟`}</div>}
            error={""}
            onClose={onClose}
            onAccept={onAccept}
        />
    );
};

DeleteGeofenceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default withErrorCatcher(DeleteGeofenceModal);
