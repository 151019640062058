
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`اضافه کردن فایل سرویس ورکر`}</h1>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`نکته:`}</em>{`
این مرحله فقط برای سایت‌های با پروتکل `}<em parentName="p">{`https`}</em>{` می‌باشد.
در صورتی که سایت شما `}<em parentName="p">{`http`}</em>{` می‌باشد از این مرحله صرف‌نظر کنید.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`یک فایل با نام
`}<inlineCode parentName="li">{`pushe-sw.js`}</inlineCode>{`
در شاخه اصلی (روت) سایت بسازید`}</li>
      <li parentName="ul">{`کد زیر را درون فایل ساخته شده قرار دهید.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`importScripts("https://static.pushe.co/pusheweb-sw.js");
`}</code></pre>
    <br />
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`نکته:`}</em>{`
الزامی به قرار گرفتن فایل
`}<inlineCode parentName="p">{`pushe-sw.js`}</inlineCode>{`
در روت پروژه وجود ندارد ولی `}<em parentName="p">{`حتما`}</em>{` باید بتوانید از طریق
وارد کردن `}<em parentName="p">{`url`}</em>{` زیر ، محتوای قرار داده شده در فایل مذکور
را درون مرورگرتان ببینید.
`}<inlineCode parentName="p">{`https://your-site.ir/pushe-sw.js`}</inlineCode></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`نکته: نحوه استفاده از پوشه در ‍`}<inlineCode parentName="em">{`PWA`}</inlineCode>{` و یا استفاده از یک سرویس ورکر دیگر در کنار سرویس ورکر پوشه:`}</em>{`
در صورتی که از `}<inlineCode parentName="p">{`PWA`}</inlineCode>{` استفاده می‌کنید و یا سرویس ورکر دیگری در سایت خود دارید برای استفاده از سرویس ورکر پوشه
و سرویس ورکر خودتان به طور همزمان `}<a parentName="p" {...{
          "href": "https://docs.pushe.co/docs/webpush/pwa/"
        }}>{`این راهنما را مشاهده کنید`}</a>{`.`}</p>
    </blockquote>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;