export const TIME_FILTER_OPTION = [
    { id: 0, title: "زمان ایجاد", value: "" },
    { id: 1, title: "یک روز گذشته", value: 1 },
    { id: 2, title: "یک هفته‌ گذشته", value: 7 },
    { id: 3, title: "دو هفته‌ گذشته", value: 14 },
    { id: 4, title: "یک ماه گذشته", value: 30 },
];

export const WEB_PUSH_DOMAIN_FOR_HTTP = "wp.pushe.co";

export const WEB_FRAEMWORKS = {
    NORMAL: 1,
    WORDPRESS: 2,
};

export const WEB_FRAMEWORKS = {
    NORMAL: 1,
    WORDPRESS: 2,
};

export const ANDROID_FRAMEWORKS = {
    ANDROID_STUDIO: {
        VALUE: 2,
        LABEL: "اندروید استودیو",
    },
    REACT_NATIVE: {
        VALUE: 6,
        LABEL: "ری‌اکت‌نیتیو",
    },
    FLUTTER: {
        VALUE: 9,
        LABEL: "فلاتر",
    },
    UNITY: {
        VALUE: 4,
        LABEL: "یونیتی",
    },
};

export const IOS_FRAMEWORKS = {
    XCODE: {
        VALUE: 8,
        LABEL: "اکس کد",
    },
    REACT_NATIVE: {
        VALUE: 6,
        LABEL: "ری‌اکت‌نیتیو",
    },
    FLUTTER: {
        VALUE: 9,
        LABEL: "فلاتر",
    },
};

export const SDK_DESKTOP_POSITIONS = [
    { label: "بالا وسط", value: "top-center" },
    { label: "بالا راست", value: "top-right" },
    { label: "بالا چپ", value: "top-left" },
    { label: "پایین وسط", value: "bottom-center" },
    { label: "پایین راست", value: "bottom-right" },
    { label: "پایین چپ", value: "bottom-left" },
];

export const SDK_MOBILE_POSITIONS = [
    { label: "بالا", value: "top" },
    { label: "پایین", value: "bottom" },
];

export const prompt_defaults = {
    showDialog: true,
    icon: "https://static.pushe.co/d/webpush/default-icon.png",
    title: "عضویت در وب‌پوش",
    content: "می‌خواهید از آخرین اخبار و پیشنهادات آگاه شوید؟",
    acceptText: "قبول می کنم",
    rejectText: "تمایلی ندارم",
    dialogRetryRate: 0,
    direction: "rtl",
    position: "top-center",
    mobilePosition: "top",
    promptTheme: "pushe-prompt-theme2",
    unblockPrompt: {
        enableUnblockPrompt: false,
        properties: {
            displayInDesktop: true,
            displayInMobile: true,
        },
        text: {
            body: "تنظیمات دریافت نوتیفیکیشن برای شما غیرفعال شده است. جهت مشاهده نحوه فعال سازی آن بر روی دکمه زیر کلیک کنید.",
            button: "مشاهده راهنما",
        },
    },
    overlaySubscription: {
        isEnabled: false,
        clickTrigger: false,
        displayIn: {
            mobile: true,
            desktop: true,
        },
    },
    dialogType: "pushe",
};

export const bell_defaults = {
    showBell: false,
    properties: {
        position: "right",
        displayInMobile: true,
        displayInDesktop: true,
    },
};

export const geolocation_defaults = {
    isEnabled: false,
    showDialog: false,
    text: {
        title: "دریافت موقعیت مکانی",
        content:
            "جهت ارایه خدمات بهتر،آیا مایلید موقعیت مکانی خود را با ما به اشتراک بگذارید؟",
        rejectBtn: "تمایلی ندارم",
        acceptBtn: "قبول می‌کنم",
    },
    position: {
        mobile: "top",
        desktop: "top-center",
    },
    displayIn: {
        desktop: false,
        mobile: true,
    },
};
