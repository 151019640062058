import {
    Download,
    GraduationHat,
    Pencil,
    ThreeDot,
    Trash,
} from "@/components/utils/lib/svgicons";
import { PopoverAnimationVertical } from "material-ui/Popover";
import { blue900 } from "material-ui/styles/colors";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import MenuItem from "material-ui/MenuItem";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import FlatButton from "material-ui/FlatButton";
import ReactTooltip from "react-tooltip";
import { MODAL_THEME } from "@/actions/_types";
import PropTypes from "prop-types";
import Colors from "@/components/utils/lib/colors";
import BaseModal from "@/components/utilities/modal/baseModal";
import * as ReactDOM from "react-dom";
import RenameApplicationModal from "@/components/application/components/modals/rename-application-modal";

const styles = {
    iconButtonUser: {
        display: "inline-block",
        verticalAlign: "middle",
        width: "1.875rem",
        height: "1.875rem",
        padding: "0",
    },
    menu: {
        // shown in wW < 1200px
        item: {
            main: {
                lineHeight: "2.375rem",
                minHeight: "2.375rem",
            },
            innerDiv: {
                padding: "0 3.125rem 0 0.3125rem",
                color: Colors.gray_8,
                fontSize: "0.875rem",
            },
            icon: {
                margin: "0.375rem 0.75",
                fill: Colors.gray_8,
            },
        },
    },
    manifest: {
        builder: null,
        packageName: null,
        clientKey: null,
    },
};

class Menus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteAppModal: false,
            deleteApplicationError: null,
            showUpdateAppModal: false,
            updateAppModalError: null,
            showInstallHistoryModal: false,
        };
    }

    handleExportCSV() {
        const {
            app: { app_id },
            exportCSV,
        } = this.props;
        const onSuccess = () =>
            this.setState({
                showInstallHistoryModal: false,
                installHistoryError: null,
            });
        const onFailure = error =>
            this.setState({ installHistoryError: error });
        exportCSV({ app_id }, onSuccess, onFailure);
    }

    handleDeleteApplication() {
        const {
            app: { app_id },
            deleteApplication,
            platform,
        } = this.props;
        const onError = error =>
            this.setState({ deleteApplicationError: error });
        deleteApplication({ app_id, platform }, null, onError);
    }

    handleUpdateApplication(values) {
        const {
            app: { app_id },
            updateApplication,
            platform,
        } = this.props;
        const onSuccess = () =>
            this.setState({
                showUpdateAppModal: false,
                updateAppModalError: null,
            });
        const onError = err => this.setState({ updateAppModalError: err });
        updateApplication({ app_id, platform, ...values }, onSuccess, onError);
    }

    render() {
        const {
            showDeleteAppModal,
            showUpdateAppModal,
            showInstallHistoryModal,
            updateAppModalError,
            deleteApplicationError,
            showInstallHistoryError,
        } = this.state;
        const {
            app = {},
            isSmallSize,
            colSize,
            handleFrameworkOrGuide,
        } = this.props;
        const { framework, app_id, has_one_signal_user } = app;
        let classes = !isSmallSize
            ? `table-col table-col--${colSize}`
            : "small-CardMenus";

        return (
            <>
                <div className={classes}>
                    <IconMenu
                        className=""
                        iconButtonElement={
                            <IconButton>
                                <ThreeDot viewBox="0 0 20 20" />
                            </IconButton>
                        }
                        animation={PopoverAnimationVertical}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                        targetOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <MenuItem
                            innerDivStyle={styles.menu.item.innerDiv}
                            style={styles.menu.item.main}
                            primaryText="تغییر نام اپلیکیشن"
                            leftIcon={
                                <Pencil
                                    viewBox="0 0 24 24"
                                    style={styles.menu.item.icon}
                                />
                            }
                            onClick={() =>
                                this.setState({ showUpdateAppModal: true })
                            }
                        />
                        <MenuItem
                            innerDivStyle={styles.menu.item.innerDiv}
                            style={styles.menu.item.main}
                            primaryText="حذف اپلیکیشن"
                            leftIcon={
                                <Trash
                                    viewBox="0 0 19 23"
                                    style={styles.menu.item.icon}
                                />
                            }
                            onClick={() =>
                                this.setState({ showDeleteAppModal: true })
                            }
                        />
                        {!has_one_signal_user && (
                            <>
                                <MenuItem
                                    innerDivStyle={styles.menu.item.innerDiv}
                                    style={styles.menu.item.main}
                                    primaryText={"دریافت تاریخچه نصب"}
                                    leftIcon={
                                        <Download
                                            viewBox="0 0 19 23"
                                            style={styles.menu.item.icon}
                                        />
                                    }
                                    onClick={() =>
                                        this.setState({
                                            showInstallHistoryModal: true,
                                        })
                                    }
                                />
                                {!isSmallSize && (
                                    <MenuItem
                                        innerDivStyle={
                                            styles.menu.item.innerDiv
                                        }
                                        style={styles.menu.item.main}
                                        primaryText="آموزش نصب"
                                        leftIcon={
                                            <GraduationHat
                                                viewBox="0 0 29 21"
                                                style={styles.menu.item.icon}
                                            />
                                        }
                                        onClick={() =>
                                            handleFrameworkOrGuide("set", {
                                                app_id,
                                                framework,
                                                page: "guide",
                                            })
                                        }
                                    />
                                )}
                            </>
                        )}
                    </IconMenu>

                    {isSmallSize && !has_one_signal_user && (
                        <div className="">
                            <FlatButton
                                className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                                rippleColor={blue900}
                                label="آموزش"
                                onClick={() =>
                                    handleFrameworkOrGuide("set", {
                                        app_id,
                                        framework,
                                        page: "guide",
                                    })
                                }
                            />
                        </div>
                    )}

                    <ReactTooltip className="tooltip" effect="solid" />
                </div>

                {showDeleteAppModal &&
                    ReactDOM.createPortal(
                        <BaseModal
                            theme={MODAL_THEME.RED}
                            title={"حذف اپلیکیشن"}
                            useDefaultFooter={true}
                            desc={"آیا مطمئن هستید؟"}
                            body={
                                <div>
                                    تمامی اطلاعات اپلیکیشن &nbsp;
                                    {app.package_name}&nbsp; حذف می شود و دیگر
                                    نمی توانید آن ها را بازیابی کنید.
                                </div>
                            }
                            error={deleteApplicationError}
                            onClose={() =>
                                this.setState({
                                    showDeleteAppModal: false,
                                    deleteApplicationError: null,
                                })
                            }
                            onAccept={this.handleDeleteApplication.bind(this)}
                        />,

                        document.getElementById("mainWrap"),
                    )}

                {showUpdateAppModal &&
                    ReactDOM.createPortal(
                        <RenameApplicationModal
                            error={updateAppModalError}
                            onClose={() =>
                                this.setState({
                                    showUpdateAppModal: false,
                                    updateAppModalError: null,
                                })
                            }
                            applicationName={this.props?.app?.name}
                            onAccept={this.handleUpdateApplication.bind(this)}
                        />,
                        document.getElementById("mainWrap"),
                    )}

                {showInstallHistoryModal &&
                    ReactDOM.createPortal(
                        <BaseModal
                            theme={MODAL_THEME.BLUE}
                            title={"خروجی csv نصب‌ها"}
                            useDefaultFooter={true}
                            body={
                                <div>
                                    عملیات ایجاد خروجی مدتی طول خواهد کشید. به
                                    محض آماده‌شدن فایل خروجی، این فایل به شما
                                    ایمیل خواهد شد. لطفا شکیبا باشید
                                </div>
                            }
                            error={showInstallHistoryError}
                            onClose={() =>
                                this.setState({
                                    showInstallHistoryModal: false,
                                    installHistoryError: null,
                                })
                            }
                            onAccept={this.handleExportCSV.bind(this)}
                        />,

                        document.getElementById("mainWrap"),
                    )}
            </>
        );
    }
}

Menus.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    colSize: PropTypes.number,
};

Menus.defaultProps = {
    app: {},
    colSize: 10,
};

export default withErrorCatcher(Menus);
