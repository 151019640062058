import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "material-ui/Checkbox";
import classNames from "classnames";

const uuid = require("uuid/v4");

class NotificationLEDComponent extends Component {
    constructor(props) {
        super(props);
        this.ledHander = this.ledHander.bind(this);
    }

    ledHander(ledColor) {
        this.props.input.onChange(ledColor);
    }

    renderSelectLED(ledColor, ledOptions) {
        return (
            <div
                className="radio-group radio-group--color"
                style={{
                    marginTop: "10px",
                    minHeight: "26px",
                }}
            >
                <ul>
                    {ledOptions.map(item => {
                        const code = item.code;
                        const value = item.value;
                        const isActive = code === ledColor;

                        return (
                            <li
                                key={uuid()}
                                className={classNames({ active: isActive })}
                                onClick={() => this.ledHander(code)}
                            >
                                <input className="mui-radiobtn" value={value} />
                                <span style={{ color: value }}>1</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    render() {
        const { ledColor, ledOptions } = this.props;

        return (
            <div className="col-xs-12">
                <div className="led-wrap">
                    <Checkbox
                        className="mui-checkbox"
                        label="LED"
                        defaultChecked={!!ledColor}
                        onCheck={(event, checked) =>
                            this.ledHander(checked ? ledOptions[0].code : "")
                        }
                        checked={!!ledColor}
                        inputStyle={{ marginLeft: "0" }}
                    />
                    {ledColor && this.renderSelectLED(ledColor, ledOptions)}
                </div>
            </div>
        );
    }
}

NotificationLEDComponent.propTypes = {
    ledColor: PropTypes.object,
    ledOptions: PropTypes.array.isRequired,
};

NotificationLEDComponent.defaultProps = {};

export default NotificationLEDComponent;
