const accept = v => v;
const gtZero = value =>
    typeof value === "number" && value > 0 ? value : undefined;

export const geofenceToApiNormalizer = {
    name: accept,
    geofence_id: accept,
    app_id: accept,
    notification: {
        data: {
            title: accept,
            content: accept,
            big_title: accept,
            big_content: accept,
            ticker: accept,
            notif_icon: accept,
            icon: accept,
            image: accept,
            buttons: accept,
            action: accept,
            summary: accept,
            sound_url: accept,
            wake_screen: accept,
            led_color: accept,
            delay_until: accept,
        },
        custom_content: accept,
        time_to_live: accept,
        collapse_key: accept,
    },
    lat: [accept, val => Number(val)],
    long: [accept, val => Number(val)],
    radius: accept,
    limit: accept,
    trigger: accept,
    rate_limit: accept,
    dwell_time: gtZero,
    expiration_date: accept,
};

export const geofenceToFormNormalizer = {
    notificationTypes: accept,
    fullContentType: accept,
    name: accept,
    geofence_id: accept,
    app_id: accept,
    notification: {
        data: {
            title: accept,
            content: accept,
            big_title: accept,
            big_content: accept,
            ticker: accept,
            notif_icon: accept,
            icon: accept,
            image: accept,
            buttons: accept,
            action: accept,
            summary: accept,
            sound_url: accept,
            wake_screen: accept,
            led_color: accept,
            delay_until: accept,
        },
        custom_content: accept,
        time_to_live: accept,
        collapse_key: accept,
    },
    lat: [accept, val => val.toString()],
    long: [accept, val => val.toString()],
    radius: accept,
    limit: accept,
    trigger: accept,
    rate_limit: accept,
    dwell_time: accept,
    expiration_date: accept,
};
