import React from "react";
import { CHART_LIST } from "@/components/utils/NewCharts/common";
import CustomChart from "@/components/utils/NewCharts/CustomChart";
import isEmpty from "lodash/isEmpty";
import EmptyChartImagePlaceHolder from "@/components/utilities/emptyChartImagePlaceHolder";
import dailyStatistics from "images/charts/daily_statistics.jpeg";
import { FIRST_DAY_EMPTY_CHART_MESSAGE } from "@/constants";
import Util from "@/utils";
import { optionsChartDaily } from "./optionsDailyChart";
import { CheckArrayItemListWithOutZero } from "@/components/utils/checkArrayItemListWithOutZero";

const DailyInstallationsNewChart = props => {
    const {
        data = [],
        seriesOfDaily,
        title,
        helper,
        height = 400,
        placeHolderText,
    } = props;

    const gateDate = () => {
        let jalaliDate;
        jalaliDate =
            data?.date &&
            data?.date.map(
                day => Util._convertToLocalTimezone(day).split(" ")[1],
            );
        return jalaliDate;
    };
    const ChartData = seriesOfDaily(data);
    function chartConfig(chart) {
        return {
            labels: gateDate(),
            data: chart,
        };
    }
    return (
        <>
            <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="card-g">
                    <header className="card-g__header">
                        <p className="card-g__title">
                            <svg>
                                <use xlinkHref="#svg_statistics"></use>
                            </svg>
                            {title}
                        </p>
                        {helper && (
                            <span
                                className="more-info"
                                data-class="tooltip tooltip--long"
                                data-tip={helper}
                            >
                                ؟
                            </span>
                        )}
                    </header>
                    <div className="card-g__body">
                        {isEmpty(ChartData?.[0]?.data) ||
                        CheckArrayItemListWithOutZero(ChartData?.[0]?.data) ? (
                            <EmptyChartImagePlaceHolder
                                src={dailyStatistics}
                                massage={
                                    placeHolderText ||
                                    FIRST_DAY_EMPTY_CHART_MESSAGE
                                }
                            />
                        ) : (
                            <div className="chart">
                                <CustomChart
                                    height={height}
                                    options={optionsChartDaily}
                                    dataChart={chartConfig(ChartData)}
                                    typeChart={CHART_LIST.Line.name}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default DailyInstallationsNewChart;
