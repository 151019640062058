/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import css from "./styles.module.scss";
import { Field, FormSection } from "redux-form";
import {
    ReduxCheckbox,
    reduxRadixButtons,
    reduxSelectBox,
    ReduxSimpleTextField,
    ColorPicker,
    UPLOAD_IMAGE_TYPE,
} from "@/components/utilities";
import { isEmpty } from "lodash";
import NotificationImageUpload from "@/components/utilities/commons/wizard/controls/NotificationImageUpload";
import { uploadPromptIcon } from "@/actions/app";
import { useDispatch } from "react-redux";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
import {
    prompt_defaults,
    SDK_DESKTOP_POSITIONS,
    SDK_MOBILE_POSITIONS,
} from "@/components/application/constants";
import {
    minValue,
    maxValue,
    minTimeLengthValidator,
    maxTimeLengthValidator,
} from "@/components/utils/validation";
import { PROMPT_TYPE } from "@/constants";

const minValue0 = minValue(0);
const maxValue100 = maxValue(100);
const minTime0 = minTimeLengthValidator(0);
const maxTime14400 = maxTimeLengthValidator(14400);

export default props => {
    const { isHttps, change } = props;
    const dispatch = useDispatch();

    const formValues = useReduxFormValues("webpush-settings", ["prompt"]);
    const {
        showDialog = false,
        delayingPrompt = false,
        unblockPrompt,
        overlaySubscription,
        dialogType,
        ...prompt
    } = formValues?.prompt ?? {};

    useEffect(() => {
        if (isEmpty(prompt)) {
            change("prompt", { ...prompt_defaults, showDialog });
        }
    }, [showDialog]);

    useEffect(() => {
        if (dialogType === PROMPT_TYPE.PUSHE) {
            change("prompt", {
                ...formValues?.prompt,
                showDialog: true,
                overlaySubscription: {
                    ...formValues?.prompt?.overlaySubscription,
                    isEnabled: false,
                },
            });
        } else if (dialogType === PROMPT_TYPE.OVERLAY) {
            change("prompt", {
                ...formValues?.prompt,
                showDialog: false,
                overlaySubscription: {
                    ...formValues?.prompt?.overlaySubscription,
                    isEnabled: true,
                },
            });
        } else if (dialogType === PROMPT_TYPE.DEFAULT) {
            change("prompt", {
                ...formValues?.prompt,
                showDialog: false,
                overlaySubscription: {
                    ...formValues?.prompt?.overlaySubscription,
                    isEnabled: false,
                },
            });
        }
    }, [dialogType]);

    return (
        <div className={css.settingsWrapper}>
            <FormSection name={"prompt"}>
                <div className={css.settingsRetryTimeWrapper}>
                    <span className={css.settingsRowHeading}> نوع دیالوگ</span>
                    <Field
                        name={"dialogType"}
                        component={reduxSelectBox}
                        defaultValue={"pushe"}
                        label={""}
                        options={
                            isHttps
                                ? [
                                      {
                                          label: "دیالوگ عضویت وب پوش",
                                          value: PROMPT_TYPE.PUSHE,
                                      },
                                      {
                                          label: "دیالوگ تمام صفحه",
                                          value: PROMPT_TYPE.OVERLAY,
                                      },
                                      {
                                          label: "دیالوگ پیشفرض مرورگر",
                                          value: PROMPT_TYPE.DEFAULT,
                                      },
                                  ]
                                : [
                                      {
                                          label: "دیالوگ عضویت وب پوش",
                                          value: PROMPT_TYPE.PUSHE,
                                      },
                                  ]
                        }
                    />
                </div>

                {dialogType === "pushe" && (
                    <>
                        <div className={css.settingsLineSeparator} />

                        <h3 className={css.settingsBlockHeading}>
                            محتوی دیالوگ
                        </h3>

                        <div className={css.contentWrapper}>
                            <div className={css.contentBlock}>
                                <Field
                                    name="title"
                                    component={ReduxSimpleTextField}
                                    placeholder="تیتر"
                                />
                                <Field
                                    name="content"
                                    component={ReduxSimpleTextField}
                                    placeholder="متن"
                                    multiline
                                />
                                <Field
                                    name="acceptText"
                                    component={ReduxSimpleTextField}
                                    placeholder="متن دکمه پذیرش"
                                />
                                <Field
                                    name="rejectText"
                                    component={ReduxSimpleTextField}
                                    placeholder="متن دکمه عدم پذیرش"
                                />
                            </div>

                            <div className={css.iconBlock}>
                                <Field
                                    component={NotificationImageUpload}
                                    name="icon"
                                    uploadFile={(...args) =>
                                        uploadPromptIcon(...args)(dispatch)
                                    }
                                    title="انتخاب آیکون"
                                    subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                    type={UPLOAD_IMAGE_TYPE.PROMPT_ICON}
                                    aspectRatio={1}
                                    // To respect React 15 input behavior there is defaultFormat = value => value == null ? '' : value
                                    // internally used. To disable that you can pass null as format prop.
                                    // remove this will cause change null value into into ''
                                    format={null}
                                    // toggleUploaderProgress={toggleUploaderProgress}
                                />
                            </div>
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <div className={css.settingsRetryTimeWrapper}>
                            <span className={css.settingsRowHeading}>
                                {" "}
                                مدت زمان عدم نمایش بعد از عدم پذیرش
                            </span>
                            <Field
                                name={"dialogRetryRate"}
                                component={reduxSelectBox}
                                defaultValue={0}
                                label={""}
                                normalize={x => Number(x)}
                                format={x => x?.toString()}
                                options={[
                                    { label: "بلافاصله", value: 0 },
                                    { label: "۱ دقیقه", value: 60 },
                                    { label: "۲ دقیقه", value: 120 },
                                    { label: "۵ دقیقه", value: 300 },
                                    { label: "۱۰ دقیقه", value: 600 },
                                    { label: "۳۰ دقیقه", value: 1800 },
                                    { label: "۱ ساعت", value: 3600 },
                                    { label: "۲ ساعت", value: 7200 },
                                    { label: "۴ ساعت", value: 14400 },
                                    { label: "۸ ساعت", value: 28800 },
                                    { label: "۱۲ ساعت", value: 43200 },
                                    { label: "۲۴ ساعت", value: 86400 },
                                    { label: "۱ هفته", value: 604800 },
                                    { label: "۱ ماه", value: 2592000 },
                                    { label: "۳ ماه", value: 7776000 },
                                    { label: "۶ ماه", value: 15552000 },
                                ]}
                            />
                        </div>

                        <div className={css.settingsDirectionWrapper}>
                            <span className={css.settingsRowHeading}>
                                جهت نوشتاری دیالوگ
                            </span>
                            <div>
                                <Field
                                    name={"direction"}
                                    component={reduxRadixButtons}
                                    defaultValue={"rtl"}
                                    options={[
                                        { label: "راست‌چین", value: "rtl" },
                                        { label: "چپ‌چین", value: "ltr" },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <div className={css.settingsPositionWrapper}>
                            <Field
                                name={"position"}
                                component={reduxSelectBox}
                                label={"مکان نمایش در دسکتاپ"}
                                options={[
                                    { label: "بالا وسط", value: "top-center" },
                                    { label: "بالا راست", value: "top-right" },
                                    { label: "بالا چپ", value: "top-left" },
                                    {
                                        label: "پایین وسط",
                                        value: "bottom-center",
                                    },
                                    {
                                        label: "پایین راست",
                                        value: "bottom-right",
                                    },
                                    { label: "پایین چپ", value: "bottom-left" },
                                ]}
                            />

                            <Field
                                name={"mobilePosition"}
                                component={reduxSelectBox}
                                label={"مکان نمایش در موبایل"}
                                options={[
                                    { label: "بالا", value: "top" },
                                    { label: "پایین", value: "bottom" },
                                ]}
                            />
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <DelayingPrompt delayingPrompt={delayingPrompt} />

                        {/* 
              <div className={css.settingsLineSeparator} />
              <UnblockPrompt unblockPrompt={unblockPrompt} /> 
           */}
                    </>
                )}
                {dialogType === "overlay" && isHttps && (
                    <>
                        <div className={css.settingsLineSeparator} />

                        <div className={css.contentWrapper}>
                            <div className={css.divideColumnInHalf}>
                                <Field
                                    component={ReduxCheckbox}
                                    name="overlaySubscription.displayIn.desktop"
                                    label="نمایش در دسکتاپ"
                                />
                            </div>
                            <div className={css.divideColumnInHalf}>
                                <Field
                                    component={ReduxCheckbox}
                                    name="overlaySubscription.displayIn.mobile"
                                    label="نمایش در موبایل"
                                />
                            </div>
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <h3 className={css.settingsBlockHeading}>
                            محتوی دیالوگ
                        </h3>

                        <div className={css.contentWrapper}>
                            <Field
                                name="overlaySubscription.backgroundText"
                                component={ReduxSimpleTextField}
                                placeholder="متن دیالوگ"
                            />
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <div className={css.contentWrapper}>
                            <div className={css.overlayClickTrigger}>
                                <Field
                                    component={ReduxCheckbox}
                                    name="overlaySubscription.clickTrigger"
                                    label="نمایش با کلیک کاربر"
                                />
                                <p className={css.hintText}>
                                    توضیحات: بدلیل محدودیت مرورگرها، "دیالوگ
                                    تمام صفحه" در مرورگر Firefox با رخداد کلیک
                                    کاربر فعال می‌شود. غیرفعال بودن این گزینه به
                                    معنای نمایش "دیالوگ عضویت وب‌پوش" در مرورگر
                                    Firefox خواهد بود.
                                </p>
                            </div>
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <h3 className={css.settingsBlockHeading}>تنظیم رنگ</h3>

                        <div className={css.mb20}>
                            <Field
                                name="overlaySubscription.color.text"
                                component={ColorPicker}
                                label="رنگ متن"
                                defaultValue={"#eeeeee"}
                            />
                        </div>
                        <div className={css.contentWrapper}>
                            <Field
                                name="overlaySubscription.color.background"
                                component={ColorPicker}
                                label="رنگ پس زمینه"
                                defaultValue={"#000000"}
                            />
                        </div>

                        <div className={css.settingsLineSeparator} />

                        <DelayingPrompt delayingPrompt={delayingPrompt} />

                        {/*
             <div className={css.settingsLineSeparator}/>
             <UnblockPrompt unblockPrompt={unblockPrompt} /> 
             */}
                    </>
                )}

                {dialogType === "default" && isHttps && (
                    <>
                        {!showDialog && (
                            <p className={css.noDialogText}>
                                در این حالت دیالوگ عضویت پوشه نمایش داده نخواهد
                                شد و مستقیما دیالوگ درخواست دریافت اعلان خود
                                مرورگر نمایش داده می‌شود.
                                <br />
                                (به دلیل محدودیت‌های جدید مرورگر‌ها این حالت
                                پیشنهاد نمی‌شود)
                            </p>
                        )}
                        <div className={css.settingsLineSeparator} />
                        <DelayingPrompt delayingPrompt={delayingPrompt} />
                        {/* 
             <div className={css.settingsLineSeparator}/>
             <UnblockPrompt unblockPrompt={unblockPrompt} /> 
            */}
                    </>
                )}
            </FormSection>
        </div>
    );
};

const UnblockPrompt = ({ unblockPrompt }) => {
    return (
        <>
            <div className={css.contentWrapper}>
                <div className={(css.contentBlock, css.fullWidth)}>
                    <h3 className={css.settingsBlockHeading}>
                        تنظیم دیالوگِ راهنمایی کاربر جهت چگونگی غیرفعال کردن عدم
                        دریافت پوش
                    </h3>
                    <span className={css.hintText}>
                        توضیحات: این دیالوگ تنها زمانی نمایش داده می‌شود که
                        کاربر قبلا(قبل از پیاده‌سازی پوشه توسط شما)، دریافت پوش
                        را غیر فعال کرده باشد. این دیالوگ تنها یک مرتبه نمایش
                        داده می‌شود.
                    </span>
                    <Field
                        name="unblockPrompt.enableUnblockPrompt"
                        component={ReduxCheckbox}
                        label="فعال‌سازی"
                    />
                    {unblockPrompt?.enableUnblockPrompt && (
                        <>
                            <div className={css.contentWrapper}>
                                <div className={css.divideColumnInHalf}>
                                    <Field
                                        component={ReduxCheckbox}
                                        name="unblockPrompt.properties.displayInDesktop"
                                        label="نمایش در دسکتاپ"
                                    />
                                </div>
                                {unblockPrompt?.properties
                                    ?.displayInDesktop && (
                                    <div className={css.divideColumnInHalf}>
                                        <Field
                                            name={
                                                "unblockPrompt.position.desktop"
                                            }
                                            component={reduxSelectBox}
                                            label={"مکان نمایش در دسکتاپ"}
                                            options={SDK_DESKTOP_POSITIONS}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={css.contentWrapper}>
                                <div className={css.divideColumnInHalf}>
                                    <Field
                                        component={ReduxCheckbox}
                                        name="unblockPrompt.properties.displayInMobile"
                                        label="نمایش در موبایل"
                                    />
                                </div>
                                {unblockPrompt?.properties?.displayInMobile && (
                                    <div className={css.divideColumnInHalf}>
                                        <Field
                                            name={
                                                "unblockPrompt.position.mobile"
                                            }
                                            component={reduxSelectBox}
                                            label={"مکان نمایش در موبایل"}
                                            options={SDK_MOBILE_POSITIONS}
                                        />
                                    </div>
                                )}
                            </div>
                            {(unblockPrompt?.properties?.displayInDesktop ||
                                unblockPrompt?.properties?.displayInMobile) && (
                                <div style={{ marginTop: "14px" }}>
                                    <div className={css.fullWidth}>
                                        <Field
                                            name="unblockPrompt.text.body"
                                            component={ReduxSimpleTextField}
                                            placeholder={
                                                prompt_defaults.unblockPrompt
                                                    .text.body
                                            }
                                            label="متن بدنه دیالوگ"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                    <div className={css.fullWidth}>
                                        <Field
                                            name="unblockPrompt.text.button"
                                            component={ReduxSimpleTextField}
                                            placeholder={
                                                prompt_defaults.unblockPrompt
                                                    .text.button
                                            }
                                            label="متن دکمه دیالوگ"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const DelayingPrompt = ({ delayingPrompt }) => {
    return (
        <>
            <h3 className={css.settingsBlockHeading}>تاخیر در نمایش دیالوگ</h3>
            <div className={css.contentWrapper}>
                <div className={(css.contentBlock, css.fullWidth)}>
                    <Field
                        name="delayingPrompt"
                        component={ReduxCheckbox}
                        label="فعال‌سازی"
                    />
                    {delayingPrompt && (
                        <>
                            <div className={css.delayedPromptSection}>
                                <div className={css.delayedPromptSectionInput}>
                                    <Field
                                        name="delayingPrompt.surfedPages"
                                        component={ReduxSimpleTextField}
                                        placeholder="تعداد صفحات"
                                        label="پس از مشاهده چند صفحه"
                                        normalize={x => Number(x)}
                                        type="number"
                                        validate={minValue0}
                                    />
                                </div>
                                <div className={css.delayedPromptSectionInput}>
                                    <Field
                                        name="delayingPrompt.timeSpent"
                                        component={ReduxSimpleTextField}
                                        placeholder="تعداد ثانیه"
                                        label="پس از گذراندن چند ثانیه"
                                        normalize={x => Number(x)}
                                        type="number"
                                        validate={[minTime0, maxTime14400]}
                                    />
                                </div>
                            </div>
                            <div className={css.delayedPromptSection}>
                                <div className={css.delayedPromptSectionInput}>
                                    <Field
                                        name="delayingPrompt.percentileScrolled"
                                        component={ReduxSimpleTextField}
                                        label="پس از اسکرول چند درصد از صفحه"
                                        placeholder="درصد اسکرول"
                                        type="number"
                                        normalize={x => Number(x)}
                                        validate={[minValue0, maxValue100]}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
