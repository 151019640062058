/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import css from "authentication/styles/noafarinSignUp.module";
import logo from "images/svg/pushe-logo-horizontal.svg";
import { Field } from "redux-form";
import {
    CircleLoader,
    FlatButton,
    ReduxSimpleTextField,
    toFarsiNumbers,
} from "@/components/utilities";
import { useTimer } from "authentication/noafarin/hooks/useTimer";
import ClassNames from "classnames";
import NotRegisteredInNoafarin from "authentication/noafarin/partials/NotRegisteredInNoafarin";

function NoafarinSignUpStep(props) {
    const { handleSubmit, handleResend, inProgress, error } = props;
    const { start, seconds, reset, isFinished } = useTimer();

    useEffect(() => {
        start();
        return () => reset();
    }, []);

    const resend = () => {
        if (!isFinished()) return;

        reset();
        start();
        handleResend();
    };

    return (
        <>
            <div className={css.pusheLogo}>
                <img src={logo} alt={"pushe logo"} />
            </div>

            <div className={css.bodyWrapper}>
                <p>
                    رمز یکبار مصرف به شماره همراهی که در سامانه نوآفرین ثبت شده
                    است،‌ ارسال شد. برای ورود لطفا آن را در کادر زیر وارد
                    نمایید.
                    <br />
                    به علاوه برای ثبت نام در پوشه لازم هست تا یک ایمیل معتبر
                    وارد نمایید.
                </p>

                <div className={css.formWrapper}>
                    <Field
                        autoFocus
                        name="verification_code"
                        label={"کد تایید تلفن همراه"}
                        component={ReduxSimpleTextField}
                        onKeyPress={e => e.charCode === 13 && handleSubmit()}
                    />
                    <Field
                        name="email"
                        label={"ایمیل"}
                        component={ReduxSimpleTextField}
                        onKeyPress={e => e.charCode === 13 && handleSubmit()}
                    />

                    <p className={css.timerTextWrapper}>
                        بعد از
                        <span className={css.timerSeconds}>
                            {toFarsiNumbers(seconds)}
                        </span>{" "}
                        ثانیه دوباره تلاش کنید.
                        <span
                            onClick={resend}
                            className={ClassNames(css.timerResend, {
                                [css.disabled]: !isFinished(),
                            })}
                        >
                            ارسال مجدد
                        </span>
                    </p>
                </div>

                <div className={css.buttonWrapper}>
                    <FlatButton
                        fullWidth
                        label={"ورود"}
                        onClick={handleSubmit}
                        endIcon={inProgress ? <CircleLoader /> : null}
                        disabled={inProgress}
                    />
                </div>

                {error && <p className={css.error}>{error}</p>}

                <NotRegisteredInNoafarin />
            </div>
        </>
    );
}

export default NoafarinSignUpStep;
