import React from "react";
import {
    CircleLoader,
    FlatButton,
    ReduxSimpleTextField,
    withErrorCatcher,
} from "@/components/utilities";
import css from "authentication/styles/forgotpass.module.scss";
import ForgotPassBanner from "authentication/forgotpass/partials/ForgotPassBanner";
import logo from "images/svg/pushe-logo-horizontal.svg";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
    createValidator,
    isEmail,
    isRequired,
} from "@/components/utils/validation";
import { useForgotPass } from "authentication/forgotpass/hooks/useForgotPass";
import ForgotPassSuccess from "authentication/forgotpass/partials/ForgotPassSuccess";

export const ForgotPassPage = props => {
    const { handleSubmit } = props;

    const { submitForgotPass, inProgress, showSuccessMessage, error } =
        useForgotPass(handleSubmit);

    return (
        <div className={css.wrapper}>
            <div className={css.innerWrapper}>
                <div className={css.banner}>
                    <ForgotPassBanner />
                </div>
                <div className={css.block}>
                    <div className={css.forgotPassLogo}>
                        <img src={logo} alt={"pushe logo"} />
                    </div>

                    {showSuccessMessage && <ForgotPassSuccess />}

                    {!showSuccessMessage && (
                        <>
                            <div className={css.forgotPassQuestion}>
                                آیا رمز عبور خود را فراموش کرده اید؟
                            </div>

                            <form onSubmit={e => e.preventDefault()}>
                                <Field
                                    autoFocus
                                    name="email"
                                    component={ReduxSimpleTextField}
                                    autoComplete={"email"}
                                    required
                                    label="ایمیل"
                                    onKeyPress={e =>
                                        e.charCode === 13 && submitForgotPass()
                                    }
                                />
                            </form>

                            <div className={css.buttonsPart}>
                                <FlatButton
                                    fullWidth
                                    label={"بازیابی رمز عبور"}
                                    onClick={submitForgotPass}
                                    endIcon={
                                        inProgress ? <CircleLoader /> : null
                                    }
                                    disabled={inProgress}
                                />
                            </div>

                            <Link className={css.goToSignInPage} to={"/signin"}>
                                بازگشت به صفحه ورود
                            </Link>

                            {error && (
                                <div className={css.forgotPassError}>
                                    {error}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const validate = createValidator({
    email: [isRequired, isEmail],
});

const rf = reduxForm({
    form: "forgot-password",
    validate,
    destroyOnUnmount: true,
})(ForgotPassPage);

export default withErrorCatcher(rf);
