import { useState } from "react";
import { store } from "@/store";
import { submitForm } from "@/actions/app";

export default function useSubmitForm(formName) {
    const [promisesList, changePromisesList] = useState([]);

    const submit = beforeSubmit => {
        if (typeof beforeSubmit === "function") {
            beforeSubmit();
        }

        return new Promise((resolve, reject) => {
            changePromisesList([...promisesList, { resolve, reject }]);
            setTimeout(() => {
                store.dispatch(submitForm(formName));
            });
        });
    };

    const onSubmitResult = ({ errors, values }) => {
        promisesList.forEach(item =>
            errors ? item.reject(errors) : item.resolve(values),
        );
        changePromisesList([]);
    };

    return [submit, onSubmitResult];
}
