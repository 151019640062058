import React from "react";
import PropTypes from "prop-types";
import Checkbox from "material-ui/Checkbox";
import classNames from "classnames";

const uuid = require("uuid/v4");

const NotificationSoundComponent = ({ soundUrl, soundOptions, input }) => {
    const audio = new Audio(soundUrl);
    if (soundUrl) audio.play();

    const soundHander = url => input.onChange(url);

    return (
        <div className="col-sm-6 col-xs-12">
            <Checkbox
                className="mui-checkbox"
                label="صدای نوتیفیکیشن"
                defaultChecked={!!soundUrl}
                onCheck={(event, checked) => {
                    soundHander(checked ? soundOptions[1].key : "");
                }}
                checked={!!soundUrl}
                inputStyle={{ marginLeft: "0" }}
            />
            {soundUrl && (
                <div
                    className="radio-group radio-group--sound"
                    style={{
                        marginTop: "10px",
                        minHeight: "26px",
                    }}
                >
                    <ul>
                        {soundOptions.map(item => {
                            const url = item.key;
                            const isUrlEmpty = url === "";
                            return (
                                <li
                                    key={uuid()}
                                    className={classNames({
                                        mute: isUrlEmpty,
                                        active: url === soundUrl,
                                    })}
                                    onClick={() => soundHander(url)}
                                >
                                    <input
                                        type="radio"
                                        name="sound_url"
                                        value={url}
                                    />
                                    <span>
                                        {isUrlEmpty && (
                                            <svg>
                                                <use xlinkHref="#svg_mute" />
                                            </svg>
                                        )}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

const propTypes = {
    soundUrl: PropTypes.string,
    soundOptions: PropTypes.array.isRequired,
};

const defaultProps = {};

NotificationSoundComponent.propTypes = propTypes;
NotificationSoundComponent.defaultProps = defaultProps;

export default NotificationSoundComponent;
