import actionTypes from "@/actions/_types";
import { getCommonApi } from "@/api";
import to from "await-to-js";
import { errorToast } from "@/components/utils/Toasts";
import { processError } from "@/components/utilities";
import { financialInitState } from "@/reducers/financial";

export const fetchInvoiceItems = options => async (dispatch, getState) => {
    const limit = 15;

    let { offset, hasMore } = getState()?.financial?.invoices ?? {};

    const [error, response] = await to(
        getCommonApi().fetchInvoiceItems({ limit, offset }),
    );

    if (
        Array.isArray(response?.data?.results) &&
        response.data.results.length >= limit
    )
        offset += limit;
    else hasMore = false;

    if (error) {
        errorToast("خطا در دریافت", processError(error));
    } else {
        dispatch({
            type: actionTypes.FINANCIAL.FETCH_INVOICE_ITEMS,
            payload: { offset, hasMore, items: response.data.results },
        });
    }

    if (typeof options?.onFinish === "function") options.onFinish();
};

export const fetchInvoiceDetails = options => async dispatch => {
    const { invoiceId } = options;

    const [error, response] = await to(
        getCommonApi().fetchInvoiceDetails(invoiceId),
    );

    if (error) {
        errorToast("خطا در دریافت جزئیات", processError(error));
    } else {
        dispatch({
            type: actionTypes.FINANCIAL.FETCH_INVOICE_DETAILS,
            payload: { invoiceId, invoice: response.data },
        });
    }

    if (typeof options?.onFinish === "function") options.onFinish();
};

export const payInvoices = async options => {
    const { invoiceIds, onFinish } = options;

    const [error1, response] = await to(getCommonApi().payInvoices(invoiceIds));

    if (error1) return errorToast("خطا در اتصال به سرور", processError(error1));

    if (typeof onFinish === "function") onFinish();

    window.location.href = `https://pec.shaparak.ir/NewIPG/?token=${response.data["token"]}`;
};

export const clearFetchedInvoices = () => dispatch => {
    dispatch({
        type: actionTypes.FINANCIAL.CLEAR_FETCHED_INVOICES_STORE,
        payload: {
            invoice: { ...financialInitState.invoice },
            invoices: { ...financialInitState.invoices },
        },
    });
};
