import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { FRAMEWORK_CAST_VALUE_INTO_NAME } from "@/actions/_types";
import { buildSteps } from "application/guides/wizards/AndroidBuildSteps";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";

const AndroidGuideWizard = props => {
    const {
        application: { framework },
        application = {},
        isManifestOnly,
        history,
        applications,
        installs,
        retrieveInstall,
    } = props;

    const steps = buildSteps(framework, isManifestOnly);

    let title = `مشاهده راهنمای (${FRAMEWORK_CAST_VALUE_INTO_NAME[framework]})`;
    let subtitle = "راهنمای گام به گام ساخت اپلیکیشن";

    return (
        <BaseWizard
            formName="guide_wizard"
            title={title}
            subtitle={subtitle}
            history={history}
            steps={steps}
            footer={WizardFooter}
            buttonOptions={{
                submitButtonName: "بستن",
                submitButtonAction: () => history.goBack(),
            }}
            passToSteps={{
                installs,
                application,
                applications,
                isManifestOnly,
                retrieveInstall,
            }}
        />
    );
};

export default withErrorCatcher(AndroidGuideWizard);
