import React from "react";
import "styles/pages/first-loader.scss";

export default class FirstLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="intro-loading">
                <div>
                    <div className="intro-loading__circle">
                        <span></span>
                    </div>
                    <svg>
                        <use href={`#first_loader_svg`} />
                    </svg>
                </div>
            </div>
        );
    }
}
