import { sendRangeOptions, ttlOptions } from "@/constants";
import {
    FILTER_MODE,
    FULL_CONTENT_TYPE_MODE,
    NOTIFICATION_TYPE,
} from "@/components/notifications/notification/constants";
import { isEmpty } from "lodash";

export const createInitialFormData = (
    notification,
    notificationMode,
    appId,
) => {
    try {
        // set application id for update notification
        const app_ids = appId ? { app_ids: [appId] } : {};
        const update_av_code = (notification || {}).update_av_code
            ? { update_av_code: notification.update_av_code }
            : {};

        const commons = {
            unique: true,
            time_to_live: ttlOptions[15].key,
            rate_limit: sendRangeOptions[0].key,
            priority: 2,
            isTestMode: false,
            data_type: notificationMode,
            notificationMode,
            filterType: "filters",
            ...app_ids,
        };

        if (isEmpty(notification)) {
            return {
                ...commons,
                notificationTypes: {
                    [NOTIFICATION_TYPE.NOTIF]: true,
                    [NOTIFICATION_TYPE.CUSTOM_CONTENT]: false,
                },
                data: {
                    action: {
                        id: "open_app",
                        action_type: "A",
                    },
                },
                topics: [],
            };
        } else {
            const custom_content = !isEmpty(notification.custom_content)
                ? JSON.stringify(notification.custom_content)
                : undefined;

            return {
                ...commons,
                notificationTypes: {
                    [NOTIFICATION_TYPE.NOTIF]:
                        (notification.data || {}).title ||
                        !(
                            notification.custom_content &&
                            notification.custom_content !== {}
                        ),
                    [NOTIFICATION_TYPE.CUSTOM_CONTENT]: !isEmpty(
                        notification.custom_content,
                    ),
                },
                fullContentType:
                    notification.data && notification.data.image
                        ? FULL_CONTENT_TYPE_MODE.IMAGE
                        : FULL_CONTENT_TYPE_MODE.CONTENT,
                filterType:
                    notification.topics && !isEmpty[notification.topics]
                        ? FILTER_MODE.TOPICS
                        : FILTER_MODE.FILTERS,
                data: {
                    ...notification.data,
                },
                custom_content,
                topics: notification.topics || [],
                ...update_av_code,
            };
        }
    } catch (e) {
        return {};
    }
};
