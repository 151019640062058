import React from "react";
import Util from "@/utils";
import { withErrorCatcher } from "@/components/utilities";

const DateTimeComponent = props => {
    const { dateTime } = props;

    return (
        <div className="card-list__datetime">
            {dateTime && Util._convertToLocalTimezone(dateTime)}
        </div>
    );
};

export default withErrorCatcher(DateTimeComponent);
