/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    RadioButtons,
    ReduxSimpleTextField,
    SelectBox,
    withErrorCatcher,
} from "@/components/utilities";
import css from "application/create/styles/createApp.module";
import { Field } from "redux-form";
import { retrieveCredentials } from "@/actions/credentials";
import { useDispatch, useSelector } from "react-redux";
import CredentialListItem from "application/create/android/partials/CredentialListItem";

const CREDENTIAL_TYPES = {
    ADD_NEW: "1",
    FROM_LIST: "2",
};

const ApplicationCredentials = props => {
    const { change } = props;

    const dispatch = useDispatch();

    const [selectedType, setSelectedType] = useState(CREDENTIAL_TYPES.ADD_NEW);
    const credentials = useSelector(state => state?.credentials ?? []);

    useEffect(() => {
        retrieveCredentials()(dispatch);
    }, []);

    return (
        <div className={css.createAppCredentials}>
            <div className={css.createAppHeading}>
                کریدنشیال فایربیس
                <a
                    className={css.subLink}
                    href="https://docs.pushe.co/docs/general/firebase/create-project"
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <span>( راهنمایی درباره کریدنشیال فایربیس )</span>
                </a>
            </div>

            <div className={css.createAppBody}>
                <RadioButtons
                    options={[
                        {
                            label: "ساخت شناسه جدید",
                            value: CREDENTIAL_TYPES.ADD_NEW,
                        },
                        {
                            label: "انتخاب از لیست شناسه‌ها",
                            value: CREDENTIAL_TYPES.FROM_LIST,
                        },
                    ]}
                    onChange={value => {
                        setSelectedType(value);
                        change("credentials.client_key", "");
                        change("credentials.server_key", "");
                    }}
                    defaultValue={CREDENTIAL_TYPES.ADD_NEW}
                />

                {selectedType === CREDENTIAL_TYPES.ADD_NEW && (
                    <>
                        <Field
                            name={"credentials.client_key"}
                            label={"*FCM Sender ID"}
                            component={ReduxSimpleTextField}
                        />
                        <Field
                            name={"credentials.server_key"}
                            label={"*FCM Server Key"}
                            component={ReduxSimpleTextField}
                        />
                    </>
                )}

                {selectedType === CREDENTIAL_TYPES.FROM_LIST && (
                    <Field
                        name={"credentials"}
                        component={props => {
                            const {
                                input: { onChange, value },
                                meta: { touched, error },
                            } = props;

                            return (
                                <SelectBox
                                    label={"انتخاب شناسه"}
                                    options={credentials.map(credential => ({
                                        label: (
                                            <CredentialListItem
                                                client_key={
                                                    credential.client_key
                                                }
                                                server_key={
                                                    credential.server_key
                                                }
                                            />
                                        ),
                                        value: credential.pk,
                                    }))}
                                    value={
                                        (credentials ?? []).find(i => {
                                            return (
                                                value.server_key ===
                                                    i.server_key &&
                                                value.client_key ===
                                                    i.client_key
                                            );
                                        })?.pk
                                    }
                                    onChange={value => {
                                        if (!value) return;
                                        const credential =
                                            (credentials ?? []).find(
                                                i => i.pk === value,
                                            ) ?? {};

                                        onChange({
                                            client_key: credential?.client_key,
                                            server_key: credential?.server_key,
                                        });
                                    }}
                                    error={touched && error?.client_key}
                                />
                            );
                        }}
                    />
                )}
                <div className={css.hmsBox}>
                    <div className={css.createAppHeading}>
                        کریدنشیال هواوی
                        <a
                            className={css.subLink}
                            href="https://docs.pushe.co/docs/android/hms/intro"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <span>( راهنمایی درباره کریدنشیال هواوی )</span>
                        </a>
                    </div>
                    <Field
                        name={"hms_app_id"}
                        label={"HMS App ID "}
                        component={ReduxSimpleTextField}
                    />
                    <Field
                        name={"hms_app_secret"}
                        label={"HMS App Secret"}
                        component={ReduxSimpleTextField}
                    />
                </div>
            </div>
        </div>
    );
};

export default withErrorCatcher(ApplicationCredentials);
