import React, { useState } from "react";
import { FlatButton, withErrorCatcher } from "@/components/utilities";
import ReactTooltip from "react-tooltip";

const DisplayStatistics = props => {
    const [display, toggleDisplay] = useState(false);
    const {
        statisticsComponent: SComp,
        statisticsComponentProps = {},
        disabled = false,
    } = props;

    return (
        <div className="card-list__display-statistics">
            <FlatButton
                label="نمایش آمار"
                disabled={disabled}
                onClick={() => toggleDisplay(true)}
            />

            {display && SComp && (
                <SComp
                    onClose={() => toggleDisplay(false)}
                    {...statisticsComponentProps}
                />
            )}
            <ReactTooltip className="tooltip" effect="solid" place="right" />
        </div>
    );
};

export default withErrorCatcher(DisplayStatistics);
