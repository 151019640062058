import React from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { formValueSelector, reduxForm } from "redux-form";
import { uploadFile } from "@/actions/app";
import { NOTIFICATION_MODE } from "@/actions/_types";
import { hasOneSignal as checkHasOneSignal } from "@/utils";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { isNotificationActionValid } from "@/components/notifications/notification/validation";
import { AndroidActions } from "@/components/notifications/notification/actions";
import {
    arrayValidator,
    createValidator,
    isJsonString,
    isRequired,
    withMessage,
} from "@/components/utils/validation";
import { NOTIFICATION_TYPE } from "@/components/notifications/notification/constants";
import { withErrorCatcher } from "@/components/utilities";
import { some, values } from "lodash";
import SelectNotificationType from "@/components/utilities/commons/wizard/components/SelectNotificationTypeComponent";
import SelectApplicationComponent from "@/components/utilities/commons/wizard/components/SelectApplicationComponent";
import NotificationContentComponent from "@/components/utilities/commons/wizard/components/NotificationContentComponent";
import NotificationButtonsComponent from "@/components/utilities/commons/wizard/components/NotificationButtonsComponent";
import ChooseVersionComponent from "@/components/utilities/commons/wizard/components/ChooseVersionComponent";
import NotificationJsonComponent from "@/components/utilities/commons/wizard/components/NotificationJsonComponent";

const AndroidNotifStep1 = props => {
    const platform = useSelector(s => s?.app?.platform);
    const {
        passToSteps: { notificationMode },
        applications,
        notificationTypes,
        uploadFile,
        appSelectionMethod,
        hasOneSignal,
        change,
    } = props;

    return (
        <form className="notif-step1">
            <SelectNotificationType />

            {notificationMode === NOTIFICATION_MODE.NORMAL && (
                <SelectApplicationComponent
                    platform={platform}
                    applications={applications}
                    appSelectionMethod={appSelectionMethod}
                    hasOneSignal={hasOneSignal}
                    change={change}
                />
            )}

            {notificationTypes[NOTIFICATION_TYPE.NOTIF] && (
                <NotificationContentComponent
                    change={change}
                    uploadFile={uploadFile}
                    actionsList={AndroidActions}
                />
            )}
            {notificationMode === NOTIFICATION_MODE.UPDATE && (
                <ChooseVersionComponent fieldName={"update_av_code"} />
            )}

            <NotificationButtonsComponent
                hasOneSignal={hasOneSignal}
                actionsList={AndroidActions}
                defaultSelectedAction={AndroidActions.find(
                    a => a.id === "open_app",
                )}
            />

            {notificationTypes[NOTIFICATION_TYPE.CUSTOM_CONTENT] && (
                <NotificationJsonComponent />
            )}
        </form>
    );
};

const validators = createValidator({
    notificationTypes: value =>
        some(values(value), x => x === true)
            ? undefined
            : FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TYPE,
    app_ids: (value, key, allValues) =>
        allValues.isTestMode ||
        (value && value.length) > 0 ||
        (allValues.partners && allValues.partners.length > 0)
            ? undefined
            : FORM_ERRORS.NO_APP_SELECTED,
    data: {
        title: withMessage(
            isRequired,
            FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TITLE,
        ),
        content: withMessage(
            isRequired,
            FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_CONTENT,
        ),
        action: isNotificationActionValid(AndroidActions),
        buttons: arrayValidator({
            btn_content: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_ICON_AND_FIELD,
            ),
            btn_icon: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_ICON_AND_FIELD,
            ), // TODO icon errors not showing
            btn_action: isNotificationActionValid(AndroidActions),
        }),
    },
    update_av_code: (value, key, allValues) =>
        allValues.notificationMode === NOTIFICATION_MODE.UPDATE && !value
            ? FORM_ERRORS.REQUIRED_FIELD
            : undefined,
    custom_content: isJsonString,
});

function mapStateToProps(state, ownProps) {
    const {
        passToSteps: { platform },
    } = ownProps;

    const {
        notificationTypes,
        appSelectionMethod,
        app_ids = [],
    } = formValueSelector("notification_wizard")(
        state,
        "notificationTypes",
        "appSelectionMethod",
        "app_ids",
    );
    const applications = state[platform].applications || [];
    const hasOneSignal = checkHasOneSignal(app_ids, applications);

    return {
        hasOneSignal,
        applications,
        appSelectionMethod,
        notificationTypes: notificationTypes || {},
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ uploadFile }, dispatch);
}

const AndroidNotifStep1RF = reduxForm({
    form: "notification_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidNotifStep1);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AndroidNotifStep1RF),
    ),
);
