import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    CircleLoader,
    toEnglishNumbers,
    withErrorCatcher,
} from "@/components/utilities";
import { Field, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { updateProfile } from "@/actions/auth";
import {
    createValidator,
    isEmail,
    isFarsiCharacter,
    isFinancialNumber,
    isNationalLegalNumber,
    isPostalCode,
} from "@/components/utils/validation";
import ProfileEmail from "@/components/app/profile/partials/ProfileEmail";
import ProfileImage from "@/components/app/profile/partials/ProfileImage";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { normalize } from "@/components/utils/normalize";
import { noneEmpty } from "@/components/utilities/data/normalizer";
const UserInfoTab = props => {
    const dispatch = useDispatch();
    const [loading, toggleLoading] = useState(false);
    const { handleSubmit, invalid, change } = props;

    const submitUserInfo = values => {
        toggleLoading(true);

        const cb = () => toggleLoading(false);
        const normalizedValues = normalize(values, sendNormalizer);

        updateProfile(normalizedValues, cb, cb)(dispatch);
    };

    return (
        <div className="profile__block">
            <ProfileImage change={change} />

            <ProfileEmail />

            <div className="profile__section">
                <h4 className="profile__section--header">مشخصات کاربری</h4>
                <form>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="first_name"
                                component={TextField}
                                floatingLabelText="نام"
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="last_name"
                                component={TextField}
                                floatingLabelText="نام خانوادگی"
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="national_number"
                                component={TextField}
                                floatingLabelText="شماره ملی"
                                normalize={value => toEnglishNumbers(value)}
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="phone_number_1"
                                component={TextField}
                                floatingLabelText="تلفن همراه"
                                normalize={value => toEnglishNumbers(value)}
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </form>
                <h4 className="profile__section--header">مشخصات حقوقی</h4>
                <form>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="national_legal_id"
                                component={TextField}
                                floatingLabelText="شناسه ملی"
                                normalize={value => toEnglishNumbers(value)}
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="financial_number"
                                component={TextField}
                                floatingLabelText="شماره اقتصادی"
                                normalize={value => toEnglishNumbers(value)}
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="postal_code"
                                component={TextField}
                                floatingLabelText="کدپستی"
                                normalize={value => toEnglishNumbers(value)}
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="legal_phone_number"
                                component={TextField}
                                floatingLabelText="شماره تماس"
                                normalize={value => toEnglishNumbers(value)}
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-xs-12">
                            <Field
                                name="address"
                                component={TextField}
                                floatingLabelText="آدرس"
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className="profile__section--footer">
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        paddingTop: "60px",
                    }}
                >
                    <FlatButton
                        className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                        rippleColor={blue900}
                        label={
                            loading ? (
                                <CircleLoader
                                    size={25}
                                    color="#32a9e0"
                                    style={{ top: "8px" }}
                                />
                            ) : (
                                "ذخیره"
                            )
                        }
                        onClick={handleSubmit(values => {
                            if (invalid) return;
                            submitUserInfo(values);
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

const sendNormalizer = {
    email: noneEmpty,
    new_email: noneEmpty,
    first_name: noneEmpty,
    last_name: noneEmpty,
    phone_number_1: noneEmpty,
    national_number: noneEmpty,
    picture: noneEmpty,
    national_legal_id: noneEmpty,
    financial_number: noneEmpty,
    postal_code: noneEmpty,
    address: noneEmpty,
    legal_phone_number: noneEmpty,
};

const validators = createValidator({
    first_name: [isFarsiCharacter],
    last_name: [isFarsiCharacter],
    address: [isFarsiCharacter],
    new_email: [isEmail],
    financial_number: [isFinancialNumber],
    postal_code: [isPostalCode],
    national_legal_id: [isNationalLegalNumber],
});

const UserInfoTabForm = reduxForm({
    form: "AccountUserInfo",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: validators,
})(UserInfoTab);

export default withErrorCatcher(UserInfoTabForm);
