const accept = v => v;

/**
 * Used to normalize notification form data when sending a notification create request
 */
export const androidApplicationNormalizer = {
    name: accept,
    package_name: accept,
    framework: accept,
    pushe_major_version: accept,
    hms_app_id: accept,
    hms_app_secret: accept,
    client_key: (value, key, allValues) =>
        (allValues.credentials || {}).client_key,
    server_key: (value, key, allValues) =>
        (allValues.credentials || {}).server_key,
};

export const iosApplicationNormalizer = {
    name: accept,
    package_name: accept,
    framework: accept,
    client_key: (value, key, allValues) =>
        (allValues.credentials || {}).client_key,
    server_key: (value, key, allValues) =>
        (allValues.credentials || {}).server_key,
};

export const webApplicationNormalizer = {
    name: accept,
    domain: accept,
    is_https: accept,
    pushe_subdomain: accept,
    framework: accept,
    icon_url: accept,
};
