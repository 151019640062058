import { combineReducers } from "redux";

import AnalyticsReducer from "./analytics";
import ApplicationsReducer from "./applications";
import CrashesReducer from "./crashes";
import InstallationsReducer from "./installations";
import NotificationsReducer from "./notifications";
import DashboardReducer from "./dashboard";
import HintsReducer from "./hints";
import favoriteReducer from "./favorites";

export default combineReducers({
    analytics: AnalyticsReducer,
    applications: ApplicationsReducer,
    crashes: CrashesReducer,
    installations: InstallationsReducer,
    notifications: NotificationsReducer,
    dashboard: DashboardReducer,
    hints: HintsReducer,
    favorites: favoriteReducer,
});
