import React, { useEffect, useState } from "react";
import Header from "@/components/app/header/Header";
import Sidebar from "@/components/app/sidebar/Sidebar";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useHandleTermsPrivacyModal } from "@/components/app/termsPrivacy/useHandleTermsPrivacyModal";
import myStorage from "@/myStorage";
import { useSyncPlatformWithRedux } from "@/routing/layouts/hooks/useSyncPlatformWithRedux";
import Footer from "@/components/app/components/footer";
import FirstLoader from "@/components/app/components/FirstLoader";
import { fetchProfile } from "@/actions/auth";
import { useDispatch } from "react-redux";
import { useGlobalInitialApiCalls } from "@/routing/layouts/hooks/useGlobalInitialApiCalls";
import { ToastContainer, ToastPosition } from "react-toastify";
import { useHasPermission } from "@/components/utilities";
import { PermissionPlaceholder } from "@/components/utilities/extra/PermissionPlaceholders";
import { useDisplayPermissionModal } from "@/components/utilities/modal/permission/useDisplayPermissionModal";
import { PUSHE_WEBPUSH_OPTIONS } from "@/routing/constants";
import {
    fetchSetUpConfigEmail,
    fetchSetUpConfigSMS,
} from "@/actions/configEmailSMS";

const PrivateLayout = ({
    component: Component,
    computedMatch,
    permission,
    breadcrumb,
    ...otherProps
}) => {
    const dispatch = useDispatch();

    // This is for page overlay and sidebar in mobile view, so default should be false
    const [openSidebarMobileView, toggleOpenSidebarMobileView] =
        useState(false);
    const [inProgress, updateInProgress] = useState(true);

    // Hooks
    useSyncPlatformWithRedux(
        computedMatch?.params?.platform,
        computedMatch?.url,
    );
    useGlobalInitialApiCalls(inProgress);

    const [TermsPrivacyModal, TermsPrivacyModalProps] =
        useHandleTermsPrivacyModal(Component);

    // Has token or not
    const notAuthenticated = !myStorage.getItem("token");

    // To Show a loader before check if user token is already valid or not
    useEffect(() => {
        const cb = () => updateInProgress(false);
        Promise.all([
            fetchProfile(cb, cb)(dispatch),
            fetchSetUpConfigEmail({})(dispatch),
            fetchSetUpConfigSMS({})(dispatch),
        ]);
        /*   fetchProfile(cb, cb)(dispatch);
        fetchSetUpConfigEmail({})(dispatch)
        fetchSetUpConfigSMS()(dispatch)*/
    }, [dispatch]);

    useEffect(() => {
        Pushe.subscribe(PUSHE_WEBPUSH_OPTIONS);
    }, []);

    const hasPermission = useHasPermission(permission);

    // Callback to pass in Route
    const routeRenderCB = props => {
        return notAuthenticated ? (
            <Redirect
                to={`/signin?redirect=${otherProps?.location?.pathname}`}
            />
        ) : (
            <Component {...otherProps} {...props} />
        );
    };

    const { PermissionModal } = useDisplayPermissionModal();

    if (permission && !hasPermission) return <PermissionPlaceholder isPage />;
    if (inProgress) return <FirstLoader />;

    return (
        <>
            <Header
                openSidebarMobileView={() => toggleOpenSidebarMobileView(true)}
                breadcrumb={breadcrumb || ""}
            />

            <Sidebar
                open={openSidebarMobileView}
                handleClose={() => toggleOpenSidebarMobileView(false)}
            />

            <div className="main">
                <div className="main-wrap" id="mainWrap">
                    <Route {...otherProps} render={routeRenderCB} />
                </div>
            </div>

            <Footer />

            <ToastContainer
                rtl={true}
                className="toast"
                toastClassName="toast-wrap"
                bodyClassName="toast-body-default"
                progressClassName="toast-progress-default"
                pauseOnHover={true}
                position={ToastPosition.BOTTOM_LEFT}
                autoClose={6000}
            />

            {TermsPrivacyModal && (
                <TermsPrivacyModal {...TermsPrivacyModalProps} />
            )}

            {otherProps.history.pathname !== "/financial" &&
                PermissionModal && (
                    <PermissionModal
                        onClick={() => otherProps.history.push("/financial")}
                    />
                )}
        </>
    );
};

export default withRouter(PrivateLayout);
