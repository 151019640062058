import React, { cloneElement } from "react";

export const PTable = props => {
    const {
        children,
        columns,
        fixedHeader = false,
        mobileSizeMax = "992px",
        className = "",
    } = props;
    const childrenProps = { columns, fixedHeader, mobileSizeMax };

    return (
        <div className={`PTable ${className}`}>
            <div className="PTable__inner">
                {React.Children.map(
                    children,
                    child => cloneElement(child, childrenProps),
                    null,
                )}
            </div>
        </div>
    );
};
