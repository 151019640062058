import { Field } from "redux-form";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { TextField } from "redux-form-material-ui";
import PropTypes from "prop-types";

const GenericTextFieldComponent = props => {
    const {
        title,
        fieldName,
        labelText,
        noRow,
        colClasses,
        unit,
        format,
        normalize,
        fieldType,
    } = props;

    const renderField = (
        <div className={colClasses}>
            {title && (
                <header>
                    <p>{title}</p>
                </header>
            )}

            <div style={{ display: "flex", alignItems: "baseline" }}>
                <Field
                    name={fieldName}
                    component={TextField}
                    className="mui-textfield"
                    floatingLabelText={labelText}
                    fullWidth={true}
                    {...{ format, normalize, type: fieldType }}
                />

                {unit && <div className="input-unit">{unit}</div>}
            </div>
        </div>
    );

    if (noRow) {
        return renderField;
    } else {
        return <div className="row">{renderField}</div>;
    }
};

GenericTextFieldComponent.propTypes = {
    fieldName: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    noRow: PropTypes.bool,
    colClasses: PropTypes.string,
    title: PropTypes.string,
    unit: PropTypes.string,
    format: PropTypes.func,
    normalize: PropTypes.func,
    fieldType: PropTypes.oneOf(["number", "text"]),
};

GenericTextFieldComponent.defaultProps = {
    noRow: false,
    colClasses: "col-sm-12 col-xs-12",
    fieldType: "text",
};

export default withErrorCatcher(GenericTextFieldComponent);
