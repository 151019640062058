import React from "react";
import ReactTooltip from "react-tooltip";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { NOTIFICATION_MODE } from "@/actions/_types";
import "styles/modules/_mod-card-timing.scss";
import "styles/modules/_mod-more-info.scss";
import { withErrorCatcher } from "@/components/utilities";
import { createValidator } from "@/components/utils/validation";
import moment from "moment";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import NotificationTiming from "@/components/notifications/notification/create/controls/NotificationTiming";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";

const IosNotifStep2 = props => {
    const {
        passToSteps: { notificationMode },
        change,
    } = props;

    const fields = ["app_ids", "data", "eta", "scheduled_time"];
    const formValues = useReduxFormValues("notification_wizard", fields);

    const isModalNormal = notificationMode === NOTIFICATION_MODE.NORMAL;

    return (
        <form className="notif-step4">
            {isModalNormal && (
                <div className="notif-block">
                    <div className="notif-block__body">
                        <header>
                            <p>تعیین زمان ارسال اعلان</p>
                        </header>

                        <br />

                        <NotificationTiming
                            eta={formValues?.eta}
                            change={change}
                        />
                    </div>
                </div>
            )}

            <ReactTooltip className="tooltip" effect="solid" />
        </form>
    );
};

const validators = createValidator({
    eta: (value, key, values) => {
        let eta = values.eta;
        if (eta) {
            let momentObject = moment.utc(eta);
            let now = moment.utc();
            const minutesInTheFuture = moment
                .duration(momentObject.diff(now))
                .asMinutes();
            if (minutesInTheFuture < 15) {
                return FORM_ERRORS.NOTIFICATION_TIME_ERROR;
            }
        }
    },
});

const IosNotifStep2RF = reduxForm({
    form: "notification_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(IosNotifStep2);

export default withRouter(withErrorCatcher(IosNotifStep2RF));
