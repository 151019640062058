import { Bar, Doughnut, Line, Pie, Radar } from "react-chartjs-2";
import { humanizeToEnglish } from "@/components/utilities";

export let CHART_LIST = {
    Bar: {
        name: "Bar",
        component: Bar,
        options: {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        stacked: false,
                        ticks: {
                            stepSize: 200000,
                            callback(value) {
                                return humanizeToEnglish(value);
                            },
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: false,
                        gridLines: {
                            color: "transparent",
                        },
                    },
                ],
            },
        },
    },
    Doughnut: {
        name: "Doughnut",
        component: Doughnut,
        options: {
            maintainAspectRatio: false,
            cutoutPercentage: 65,
            legend: {
                display: false,
            },
        },
    },
    Line: {
        name: "Line",
        component: Line,
        options: {
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    textAlign: "bottom",
                    usePointStyle: true,
                    boxWidth: 10,
                },
            },
            tooltips: {
                xPadding: 15,
                yPadding: 15,
                intersect: false,
                backgroundColor: "rgba(0,0,0,0.3)",
                titleFontFamily: "iranyekanwebbold",
            },
            hover: {
                intersect: true,
            },
            plugins: {
                filler: {
                    propagate: false,
                },
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            fontSize: 11,
                            fontColor: "#bcbcbc",
                            fontFamily: "iranyekanwebbold",
                            padding: 5,
                        },
                        reverse: true,
                        gridLines: {
                            drawOnChartArea: false,
                            drawTicks: true,
                            padding: 5,
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            stepSize: 5,
                            precision: 0,
                            padding: 10,
                            fontColor: "#bcbcbc",
                            callback(value) {
                                return humanizeToEnglish(value);
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: true,
                            drawTicks: false,
                        },
                    },
                ],
            },
        },
    },
    Pie: {
        name: "Pie",
        component: Pie,
        options: {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
        },
    },
    Radar: {
        name: "Radar",
        component: Radar,
        options: { maintainAspectRatio: false },
    },
};
