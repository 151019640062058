// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--4-1!../../../../node_modules/sass-loader/dist/cjs.js?sourceMap!./sign.module.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".baseWrapper___3rA1x{display:flex;margin:0;padding:0;position:absolute;right:0;left:0;top:0;height:100%;width:100%}.baseInnerWrapper___1W-CU{display:flex;flex-direction:row;width:100%}.baseBlock___16KRK{display:flex;height:100%;background-color:#fff}.baseMiddlePageBlock___1ASA7{width:100%;flex-direction:column;padding:40px 10%}@media (min-width: 768px){.baseMiddlePageBlock___1ASA7{padding:40px 20%}}@media (min-width: 992px){.baseMiddlePageBlock___1ASA7{width:50%;flex:0 1 50%;justify-content:center;padding:40px 50px}}@media (min-width: 1200px){.baseMiddlePageBlock___1ASA7{padding:40px 100px 40px 200px}}@media (min-width: 1921px){.baseMiddlePageBlock___1ASA7{padding:40px 100px 40px 300px}}.baseBanner___2pxS-{display:none;height:100%;background-color:#f4f7fb}@media (min-width: 992px){.baseBanner___2pxS-{display:flex}}.baseBannerWrapper___3o6CU{display:flex;justify-content:center;align-content:center;height:100%;width:100%;background-color:#f4f7fb}.baseBannerWrapper___3o6CU img{display:block;width:80%;height:auto}@media (min-width: 1200px){.baseBannerWrapper___3o6CU img{width:65%}}@media (min-width: 1921px){.baseBannerWrapper___3o6CU img{width:55%}}.wrapper___LShG3{}.innerWrapper___LPb-R{}.block___2w2bW{}.banner___3QV-t{width:50%;flex:0 1 50%}.bannerWrapper___1-pJZ{}.forgotPassLogo___8yPiZ{display:flex;justify-content:center;padding:50px 0}@media (min-width: 992px){.forgotPassLogo___8yPiZ{justify-content:start}}.forgotPassLogo___8yPiZ img{width:45%}.buttonsPart___3y3Vf{padding:15px 0;display:flex;justify-content:center}.goToSignInPage___16DMv{color:#0065ff;transition:color 0.3s;padding:15px 0}.goToSignInPage___16DMv:hover{color:#003d99}.forgotPassError___VN5ao{color:#f44336;text-align:center;font-weight:bold;padding:10px}\n", ""]);
// Exports
exports.locals = {
	"baseWrapper": "baseWrapper___3rA1x",
	"baseInnerWrapper": "baseInnerWrapper___1W-CU",
	"baseBlock": "baseBlock___16KRK",
	"baseMiddlePageBlock": "baseMiddlePageBlock___1ASA7",
	"baseBanner": "baseBanner___2pxS-",
	"baseBannerWrapper": "baseBannerWrapper___3o6CU",
	"wrapper": "wrapper___LShG3 baseWrapper___3rA1x",
	"innerWrapper": "innerWrapper___LPb-R " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseInnerWrapper"] + "",
	"block": "block___2w2bW " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseBlock"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseMiddlePageBlock"] + "",
	"banner": "banner___3QV-t " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseBanner"] + "",
	"bannerWrapper": "bannerWrapper___1-pJZ " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseBannerWrapper"] + "",
	"forgotPassLogo": "forgotPassLogo___8yPiZ",
	"buttonsPart": "buttonsPart___3y3Vf",
	"goToSignInPage": "goToSignInPage___16DMv",
	"forgotPassError": "forgotPassError___VN5ao"
};
module.exports = exports;
