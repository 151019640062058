import React from "react";
import EmptyChartImagePlaceHolder from "@/components/utilities/emptyChartImagePlaceHolder";
import cardStatisticsPlaceholder from "images/cards/card-statistics-placeholder.png";

function CardStatisticsPlaceholder() {
    return (
        <div className="card-list__statistics--placeholder">
            <EmptyChartImagePlaceHolder
                src={cardStatisticsPlaceholder}
                massage={"پیش‌نویس‌ها فاقد آمار می‌باشند"}
            />
        </div>
    );
}

export default CardStatisticsPlaceholder;
