import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import classNames from "classnames";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { FilterThick, Refresh, Search } from "@/components/utils/lib/svgicons";
import FloatingActionButton from "material-ui/FloatingActionButton";
import Input from "@/components/utils/input";
import IconButton from "material-ui/IconButton";
import Toggle from "material-ui/Toggle";
import PropTypes from "prop-types";

class IosTableFilter extends React.Component {
    static propTypes = {
        isMenuThin: PropTypes.bool,
        fetchInstallationsData: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            isFilterOpen: false,
            favOnly: false,
            searchParams: {
                favOnly: false,
                pushe_id: null,
                appVersion: null,
            },
        };
    }

    render() {
        const { isFilterOpen, searchParams } = this.state;
        const { isMenuThin, fetchInstallationsData } = this.props;

        return (
            <div
                className={classNames("table-filter", {
                    "table-filter--show": isFilterOpen,
                })}
            >
                <div
                    className="table-filter__bg"
                    onClick={() => this.setState({ isFilterOpen: false })}
                />
                <div className="table-filter__in">
                    <div className="table-filter__form">
                        <div className="table-row">
                            <div className="table-col table-col--25">
                                <Input
                                    className="input--border-round"
                                    placeholder="شناسه دستگاه"
                                    onChange={event =>
                                        this.setState({
                                            searchParams: {
                                                ...searchParams,
                                                pushe_id: event.target.value,
                                            },
                                        })
                                    }
                                    value={searchParams.pushe_id}
                                />
                            </div>
                            <div className="table-col table-col--25">
                                <Input
                                    className="input--border-round"
                                    placeholder="نسخه اپلیکیشن"
                                    onChange={event =>
                                        this.setState({
                                            searchParams: {
                                                ...searchParams,
                                                appVersion: event.target.value,
                                            },
                                        })
                                    }
                                    value={searchParams.appVersion}
                                />
                            </div>
                            {!isFilterOpen && (
                                <div className="table-col table-col--08">
                                    <IconButton
                                        className="table-btn"
                                        data-tip="جستجو"
                                        tooltipPosition="top-center"
                                        onClick={() =>
                                            fetchInstallationsData({
                                                shouldResetOffset: true,
                                                ...searchParams,
                                            })
                                        }
                                    >
                                        <Search viewBox="0 0 19 19" />
                                    </IconButton>
                                </div>
                            )}
                            <div className="table-col table-col--08">
                                <IconButton
                                    className="table-btn"
                                    data-tip="به‌روز‌رسانی"
                                    tooltipPosition="top-center"
                                    onClick={() =>
                                        fetchInstallationsData({
                                            shouldResetOffset: true,
                                            ...searchParams,
                                        })
                                    }
                                >
                                    <Refresh viewBox="0 0 18 17" />
                                </IconButton>
                            </div>

                            <div
                                className={`table-col table-col--20`}
                                style={{ whiteSpace: "nowrap", float: "left" }}
                            >
                                <Toggle
                                    defaultToggled={
                                        this.state.searchParams.favOnly
                                    }
                                    label="فقط مورد علاقه‌ها"
                                    className="mui-toggle"
                                    onToggle={(e, checked) => {
                                        this.setState(
                                            {
                                                searchParams: {
                                                    ...searchParams,
                                                    favOnly: checked,
                                                },
                                            },
                                            () => {
                                                fetchInstallationsData({
                                                    shouldResetOffset: true,
                                                    ...searchParams,
                                                    favOnly: checked,
                                                });
                                            },
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className="table-filter__submit"
                            onClick={() =>
                                this.setState({ isFilterOpen: false })
                            }
                        >
                            <FlatButton
                                className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                                rippleColor={blue900}
                                label="اعمال فیلتر"
                                onClick={() =>
                                    fetchInstallationsData({
                                        shouldResetOffset: true,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <button
                        className="table-filter__close btn-close btn-close--md btn-close--darkblue"
                        onClick={() => this.setState({ isFilterOpen: false })}
                    ></button>
                </div>
                <div className="table-filter__btn-wrap">
                    <FloatingActionButton
                        className={classNames("btn-filter", {
                            "btn-filter--push": isMenuThin,
                        })}
                        onClick={() => this.setState({ isFilterOpen: true })}
                    >
                        <FilterThick viewBox="0 0 22 22.321" />
                    </FloatingActionButton>
                </div>
            </div>
        );
    }
}

export default withErrorCatcher(IosTableFilter);
