import React from "react";
import PropTypes from "prop-types";
import FlatButton from "material-ui/FlatButton";
import { red900 } from "material-ui/styles/colors";
import ModalDefault from "@/components/utils/modal-default";

export default class UnFaveModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ModalDefault
                onClose={() => this.props.onModalClose()}
                title="حذف دستگاه مورد علاقه"
                desc="آیا مطمئن هستید؟"
                body={
                    <div>
                        دستگاه
                        {this.props.data}
                        از لیست مورد علاقه شما خارج می شود.
                    </div>
                }
                footer={
                    <div>
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--red"
                            rippleColor={red900}
                            label="حذف"
                            onClick={() => this.props.onUnFaveInstallation()}
                        />
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--red-white"
                            rippleColor={red900}
                            label="انصراف"
                            onClick={() => this.props.onModalClose()}
                        />
                    </div>
                }
            />
        );
    }
}

UnFaveModal.propTypes = {
    onModalClose: PropTypes.func.isRequired,
    onUnFaveInstallation: PropTypes.func.isRequired,
};
