
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`اضافه کردن پلاگین`}</h1>
    <p>{`برای مشاهده‌ی آموزش کامل به
`}<a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/flutter/intro/"
      }}>{`مستندات پوشه برای فلاتر`}</a>{`
مراجعه کنید.`}</p>
    <ul>
      <li parentName="ul">{`برای اضافه کردن پلاگین به اپلیکیشن خود کد زیر را در فایل
`}<inlineCode parentName="li">{`pubspec.yaml`}</inlineCode>{`
اضافه کنید:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`dependencies:
    pushe_flutter: ^2.5.0
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://pub.dev/packages/pushe_flutter"
      }}><strong parentName="a">{`پلاگین پوشه در  pub`}</strong></a></p>
    <ul>
      <li parentName="ul">{`سپس با دستور زیر پلاگین را دریافت کنید:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`flutter pub get
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;