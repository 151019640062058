import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHints } from "@/actions/hints";
import { fetchProfile } from "@/actions/auth";
import { fetchApplications } from "@/actions/applications";
import { fetchDashboardDataNew } from "@/actions/dashboard";
import { fetchFavorites } from "@/actions/installations";
import { store } from "@/store";

export const useAndroidDashboard = () => {
    const [onLoading, setOnLoading] = useState(false);
    const { platform, appId, openModal, demo } = useSelector(
        state => state?.app,
    );
    const dispatch = useDispatch();
    const fetchAllData = useCallback(() => {
        const callback = () => setOnLoading(false);
        setOnLoading(true);
        fetchDashboardDataNew(callback, callback)(dispatch, store.getState);
        fetchApplications({ platform: platform })(dispatch, store.getState);
        fetchFavorites()(dispatch, store.getState);
        fetchHints()(dispatch, store.getState);
        fetchProfile()(dispatch, store.getState);
    }, [dispatch, platform]);

    useEffect(() => {
        fetchAllData();
    }, [appId, fetchAllData]);

    const dashboardData = useSelector(state =>
            platform ? state[platform].dashboard : null,
        ),
        hints = useSelector(state => (platform ? state[platform].hints : null)),
        applications = useSelector(state =>
            platform ? state[platform].applications : [],
        ),
        installsCount = useSelector(state =>
            state[platform].dashboard.all
                ? state[platform].dashboard.all.installs
                : 0,
        ),
        { profile } = useSelector(state => state?.auth),
        NewData = (appId, dashboardData) => {
            if (!platform) {
                return [];
            } else if (appId) {
                return dashboardData[appId];
            } else {
                return dashboardData["all"];
            }
        };

    return {
        applications,
        demo,
        hints,
        appId,
        platform,
        installsCount,
        isWeb: platform === "web",
        userProfile: profile,
        activeModal: openModal,
        data: NewData(appId, dashboardData) || {},
        allData: NewData(null, dashboardData),
        onLoading,
    };
};
