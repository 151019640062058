import ReactGA from "react-ga";
let platform = "";
let currentPage = "";

const getOptions = () => {
    if (platform) {
        return { platform };
    }
    return {};
};

const trackPage = page => {
    let options = getOptions();
    let { platform = "" } = options;
    if (platform) {
        page = `${platform}${page}`;
    }

    ReactGA.set({
        page,
        // UI_VERSION,
        ...options,
    });

    ReactGA.pageview(page);
};

export const googleAnalytics = store => next => action => {
    let newPlatform = store.getState()?.app?.platform;

    if (action.type === "@@router/LOCATION_CHANGE") {
        const nextPage = `${action?.payload?.location?.pathname ?? ""}${
            action?.payload?.location?.search ?? ""
        }`;

        if (currentPage !== nextPage) {
            platform = newPlatform;
            currentPage = nextPage;
            trackPage(nextPage);
        }
    }

    if (newPlatform !== platform) {
        platform = newPlatform;
        trackPage(currentPage);
    }

    return next(action);
};
