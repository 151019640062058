/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { renderPermission, withErrorCatcher } from "@/components/utilities";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { PermissionPlaceholder } from "@/components/utilities/extra/PermissionPlaceholders";

const useStyles = makeStyles(theme => ({
    paperRoot: {
        backgroundColor: theme.palette.dashboard.white,
    },
    root: {
        color: theme.palette.dashboard.black,
        fontFamily: theme.typography.fontFamily.bold,
    },
    indicator: {
        backgroundColor: theme.palette.dashboard.blue,
    },
}));

const StepFilterTabs = props => {
    const { filterTabs, change } = props;
    const classes = useStyles();

    // first omit the tabs that display (or display()) of them return false
    const displayableTabs = useMemo(
        () =>
            (filterTabs => {
                return filterTabs.filter(t =>
                    typeof t.display === "function" ? t.display() : t.display,
                );
            })(filterTabs),
        [filterTabs],
    );

    const { form } = useReduxForm();
    const { filterType } = useSelector(state => getFormValues(form)(state));

    useEffect(() => {
        if (!filterType && typeof change === "function")
            change("filterType", displayableTabs[0]?.value);
    }, []);

    const selectedTab = displayableTabs.find(t => t.value === filterType);
    const Component = selectedTab?.component;

    return (
        <>
            <Paper square classes={{ root: classes.paperRoot }} elevation={0}>
                <Tabs
                    value={
                        displayableTabs.find(t => t.value === filterType)?.[
                            "value"
                        ] ?? displayableTabs[0]?.value
                    }
                    onChange={(_, value) => change("filterType", value)}
                    variant="fullWidth"
                    aria-label="Filter Tabs"
                    classes={{
                        indicator: classes.indicator,
                        root: classes.root,
                    }}
                >
                    {displayableTabs.map(tab => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                        />
                    ))}
                </Tabs>
            </Paper>

            <div className="filter-tabs-wrapper">
                {renderPermission(
                    selectedTab?.hasPermission(),
                    selectedTab && <Component change={change} />,
                    <PermissionPlaceholder />,
                )}
            </div>
        </>
    );
};

export default withErrorCatcher(StepFilterTabs);
