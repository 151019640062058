import React from "react";
import BaseWizard, {
    WIZARD_BTN_OPTIONS,
} from "@/components/utilities/commons/wizard/base/BaseWizard";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import { withErrorCatcher } from "@/components/utilities";
import { createInitialFormData } from "@/components/notifications/automation/create/android/createInitialFormData";
import AutomationFinaleStep from "@/components/notifications/automation/create/android/common/AutomationFinaleStep";
import AndroidNotifPreview from "@/components/notifications/notification/create/android/AndroidNotifPreview";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import {
    createAutomation,
    updateAutomation,
    updateAutomationLocalState,
} from "@/actions/automation";
import { sendTestNotification } from "@/actions/notifications";
import { normalize } from "@/components/utils/normalize";
import { automationToApiNormalizer } from "@/components/notifications/automation/normalize";
import { clearForm } from "@/actions/app";
import { AUTOMATION_WIZARD_MODE } from "@/components/notifications/automation/constants";
import NotificationTestModal from "@/components/notifications/notification/create/controls/NotifTestModal";
import { androidNotificationNormalizer } from "@/components/notifications/notification/normalize";
import { PLATFORMS } from "@/constants";
import AndroidAutomationStep1 from "@/components/notifications/automation/create/android/steps/AndroidAutomationStep1";
import AndroidAutomationStep2 from "@/components/notifications/automation/create/android/steps/AndroidAutomationStep2";
import AndroidAutomationStep3 from "@/components/notifications/automation/create/android/steps/AndroidAutomationStep3";
import AndroidAutomationStep4 from "@/components/notifications/automation/create/android/steps/AndroidAutomationStep4";

const AndroidAutomationWizard = props => {
    const {
        history,
        wizardMode,
        createAutomation,
        updateAutomation,
        sendTestNotification,
        automation,
        notification,
        clearForm,
        updateAutomationLocalState,
    } = props;

    const platform = PLATFORMS.ANDROID;

    return (
        <BaseWizard
            formName="automation_form"
            title="ارسال اعلان اتوماتیک اندروید"
            subtitle="ساخت ارسال اعلان اتوماتیک اندروید"
            steps={ANDROID_AUTOMATION_STEPS}
            footer={WizardFooter}
            history={history}
            formInitialData={createInitialFormData(automation, notification)}
            stepFinale={AutomationFinaleStep}
            preview={AndroidNotifPreview}
            testModal={NotificationTestModal}
            submitAction={(data, onSuccess, onFailure) => {
                if (data.isTestMode) {
                    sendTestNotification(
                        normalize(data, androidNotificationNormalizer),
                        platform,
                        onSuccess,
                        onFailure,
                    );
                } else {
                    const normalizedData = normalize(
                        data,
                        automationToApiNormalizer,
                    );

                    if (wizardMode === AUTOMATION_WIZARD_MODE.CREATE) {
                        createAutomation(normalizedData, onSuccess, onFailure);
                    } else if (wizardMode === AUTOMATION_WIZARD_MODE.EDIT) {
                        const updateLocalState = data =>
                            updateAutomationLocalState(
                                normalizedData.automation_id,
                                data,
                                onSuccess,
                            );
                        updateAutomation(
                            normalizedData.automation_id,
                            normalizedData,
                            updateLocalState,
                            onFailure,
                        );
                    }
                }
            }}
            onUnmountCallback={() => {
                clearForm("automation_form");
            }}
            hasReduxForm={true}
            buttonOptions={{
                [WIZARD_BTN_OPTIONS.SUBMIT_BTN_NAME]: "ذخیره",
            }}
            passToSteps={{
                wizardMode,
                platform,
            }}
        />
    );
};

const ANDROID_AUTOMATION_STEPS = [
    { component: AndroidAutomationStep1, hideSubmitBtn: true },
    { component: AndroidAutomationStep2 },
    { component: AndroidAutomationStep3 },
    { component: AndroidAutomationStep4 },
];

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            createAutomation,
            updateAutomation,
            clearForm,
            sendTestNotification,
            updateAutomationLocalState,
        },
        dispatch,
    );
};

export default withErrorCatcher(
    connect(null, mapDispatchToProps)(AndroidAutomationWizard),
);
