import { isEmpty } from "lodash";
import { NOTIFICATION_STATUS_TYPES } from "@/constants";

const moment = require("moment");

export const createStatusObj = notification => {
    let notificationTypes = [];

    if (notification?.is_draft) notificationTypes.push("draft");

    if (!isEmpty(notification?.["custom_content"] ?? {}))
        notificationTypes.push("custom_content");

    if (notification?.status === 2 || notification?.status === 3)
        notificationTypes.push("notSent");

    if (
        notification.eta &&
        moment(notification.eta).utc() > moment(new Date()).utc()
    ) {
        notificationTypes.push("scheduled");
    } else if (notification["dispatch_ended_at"]) {
        notificationTypes.push("sent");
    } else if (!notificationTypes.includes("draft")) {
        // If it is not a draft, then you can assign 'sending' badge to it.
        notificationTypes.push("sending");
    }

    if (notification?.type === 1) notificationTypes.push("test");

    return notificationTypes.map(type => NOTIFICATION_STATUS_TYPES[type]);
};
