import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import NotificationSliderComponent from "@/components/notifications/notification/create/controls/NotificationSliderComponent";
import { collapseKeyOptions, sendRangeOptions, ttlOptions } from "@/constants";
import { SelectField } from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import Colors from "@/components/utils/lib/colors";
const uuid = require("uuid/v4");

const NotificationDeliverySettingsComponent = ({
    showCollapseKeyBlock,
    // eslint-disable-next-line no-unused-vars
    showDelayUntilBlock,
    showRateLimitBlock,
    showTimeToLiveBlock,
}) => {
    const styles = {
        sliderIn: {
            marginTop: 0,
            marginBottom: 0,
        },
        selectField: {
            item: {
                color: Colors.blue,
            },
        },
    };

    const renderRateLimitBlock = () => {
        return (
            <div className="col-sm-6 col-xs-12">
                <div className="card-timing">
                    <Field
                        component={NotificationSliderComponent}
                        name="rate_limit"
                        title={`ارسال در بازه زمانی`}
                        data_tip={`نوتیفیکیشن‌ها به طور تدریجی در بازه‌ی زمانی مورد نظر ارسال خواهند شد.`}
                        min={0}
                        max={sendRangeOptions.length - 1}
                        step={1}
                        format={value =>
                            sendRangeOptions.findIndex(
                                item => item.key === value,
                            )
                        }
                        normalize={value => sendRangeOptions[value].key}
                        sliderRange={sendRangeOptions}
                    />
                </div>
            </div>
        );
    };

    const renderTimeToLiveBlock = () => {
        return (
            <div className="col-sm-6 col-xs-12">
                <div className="card-timing">
                    <Field
                        component={NotificationSliderComponent}
                        name="time_to_live"
                        title={`زمان زنده ماندن`}
                        data_tip={`می‌توانید تعیین کنید نوتیفیکیشن تا چند روز بعد از ارسال توسط کاربر قابل دریافت باشد.`}
                        min={0}
                        max={ttlOptions.length - 1}
                        step={1}
                        format={value =>
                            ttlOptions.findIndex(item => item.key === value)
                        }
                        normalize={value => ttlOptions[value].key}
                        sliderRange={ttlOptions}
                    />
                </div>
            </div>
        );
    };

    const renderCollapseKeyBlock = () => {
        return (
            <div className="col-sm-6 col-xs-12">
                <div className="card-timing">
                    <p>
                        کلید جایگزینی
                        <span
                            className="more-info"
                            data-tip={`پیام‌هایی که تا به حال با این کلید ارسال شده باشند،
                                             در صورتی که کاربر آن ها را ندیده باشد، با این پیام جدید شما جایگزین خواهند شد.
                                              با استفاده از این کلید می‌توانید جلوی ارسال چندباره‌ی پیام‌های مشابه به کاربرانتان را بگیرید.`}
                        >
                            ?
                        </span>
                    </p>
                    <div style={{ marginTop: "10px" }}>
                        <Field
                            name="collapse_key"
                            component={SelectField}
                            defaultValue={0}
                            className="mui-select"
                            selectedMenuItemStyle={styles.selectField.item}
                            fullWidth={true}
                            maxHeight={240}
                        >
                            {collapseKeyOptions.map(item => (
                                <MenuItem
                                    key={uuid()}
                                    value={item.key}
                                    primaryText={item.name}
                                />
                            ))}
                        </Field>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="notif-block">
            <div className="row row--20">
                {showCollapseKeyBlock && renderCollapseKeyBlock()}
                {showRateLimitBlock && renderRateLimitBlock()}
                {showTimeToLiveBlock && renderTimeToLiveBlock()}
            </div>
        </div>
    );
};
NotificationDeliverySettingsComponent.propTypes = {
    showCollapseKeyBlock: PropTypes.bool,
    showDelayUntilBlock: PropTypes.bool,
    showRateLimitBlock: PropTypes.bool,
    showTimeToLiveBlock: PropTypes.bool,
};
NotificationDeliverySettingsComponent.defaultProps = {
    showCollapseKeyBlock: true,
    showDelayUntilBlock: true,
    showRateLimitBlock: false,
    showTimeToLiveBlock: true,
};

export default withErrorCatcher(NotificationDeliverySettingsComponent);
