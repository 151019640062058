import React from "react";
import { Field } from "redux-form";
import css from "authentication/styles/signin.module.scss";
import { TextField } from "redux-form-material-ui";

function SignInDesktopProForm(props) {
    const { handleSignIn } = props;

    return (
        <form className={css.signInFormWrapper}>
            <Field
                autoComplete
                name="email"
                component={TextField}
                floatingLabelText="ایمیل*"
                fullWidth={true}
                onKeyPress={e => e.charCode === 13 && handleSignIn()}
            />
            <Field
                component={TextField}
                name="password"
                type={"password"}
                fullWidth={true}
                autoComplete={true}
                floatingLabelText="پسورد*"
                onKeyPress={e => e.charCode === 13 && handleSignIn()}
            />
            <Field
                autoFocus
                name="customer_email"
                component={TextField}
                fullWidth
                autoComplete={"customer_email"}
                floatingLabelText="ایمیل مشتری*"
                onKeyPress={e => e.charCode === 13 && handleSignIn()}
            />
        </form>
    );
}

export default SignInDesktopProForm;
