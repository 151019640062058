import React from "react";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { withErrorCatcher } from "@/components/utilities";
import { useSelector } from "react-redux";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
import { AUTOMATION_FORM_NAME } from "@/components/email/constants";

const AutomationFinaleStep = () => {
    const { segment_id } = useReduxFormValues(AUTOMATION_FORM_NAME.android, [
        "segment_id",
    ]);
    const segmentations = useSelector(
        s => s?.segmentations?.segmentationList ?? [],
    );
    const segmentName = segmentations?.find(
        s => s.segment_id === segment_id,
    )?.name;

    return (
        <div className="notif-block">
            <p>
                آیا از ارسال این ایمیل اتوماتیک به دسته
                <span className="finale-step-segment-name">{segmentName}</span>
                اطمینان دارید؟
            </p>
        </div>
    );
};

const AutomationFinaleStepRF = reduxForm({
    form: AUTOMATION_FORM_NAME.android,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(AutomationFinaleStep);

export default withRouter(withErrorCatcher(AutomationFinaleStepRF));
