import React from "react";
import { thousandSeparatorFarsi, toFarsiNumbers } from "@/components/utilities";
import { renderServiceName } from "@/components/financial/helpers";

const InvoicePDF = ({ invoice }) => {
    return (
        <div className="financialCostDialog ">
            <div className="financialCostDialog__header">
                <div className="right">
                    <span className="number">ردیف</span>
                    <span className="services-label">شرح خدمات</span>
                </div>
                <div className="left">
                    <span className="const-label">قیمت (ریال)</span>
                    <span className="const-label">تخفیف (ریال)</span>
                </div>
            </div>
            <div className="financialCostDialog__body">
                {Array.isArray(invoice?.["details"]?.["services"]) &&
                    invoice["details"]["services"].map((i, idx) => {
                        return (
                            <div
                                className="financialCostDialog_row"
                                key={i["log_id"]}
                            >
                                <div className="right">
                                    <span className="number">
                                        {toFarsiNumbers(idx + 1)}
                                    </span>
                                    <span className="services-value">
                                        {renderServiceName(
                                            i["service_name"],
                                            i["service_type"],
                                        )}
                                    </span>
                                </div>
                                <div className="left">
                                    <span className="const-value">
                                        {thousandSeparatorFarsi(
                                            i["net_amount"],
                                        )}
                                    </span>
                                    <span className="const-value">
                                        {thousandSeparatorFarsi(
                                            i["discount_amount"],
                                        )}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="financialCostDialog__footer">
                <div className="financialCostDialog_row">
                    <span className="label">جمع خدمات:‌ </span>
                    <span className="value">
                        {thousandSeparatorFarsi(
                            invoice?.["details"]?.["net_amount"] ?? 0,
                        )}
                    </span>
                </div>
                <div className="financialCostDialog_row">
                    <span className="label">تخفیف( کد تخفیف ): </span>
                    <span className="value">
                        {thousandSeparatorFarsi(
                            invoice?.["details"]?.["discount_amount"] ?? 0,
                        )}
                    </span>
                </div>
                <div className="financialCostDialog_row">
                    <span className="label">مالیات بر ارزش افزوده: </span>
                    <span className="value">
                        {thousandSeparatorFarsi(
                            invoice?.["details"]?.["added_value_tax"] ?? 0,
                        )}
                    </span>
                </div>
                <div className="financialCostDialog_row total">
                    <span className="label">جمع نهایی: </span>
                    <span className="value">
                        {thousandSeparatorFarsi(
                            invoice?.["details"]?.["payable_amount"] ?? 0,
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};
export default InvoicePDF;
