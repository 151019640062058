import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    email: [],
    sms: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.CONFIG.EMAIL.FETCH_CONFIG_EMAIL:
            return {
                ...state,
                ["email"]: [...action.payload],
            };
        case actionTypes.CONFIG.EMAIL.CREATE_CONFIG_EMAIL:
            return {
                ...state,
                ["email"]: [action.payload, ...state.email],
            };
        case actionTypes.CONFIG.EMAIL.UPDATE_CONFIG_EMAIL:
            return {
                ...state,
                email: state.email.map(em => {
                    if (em.segment_id === action.payload.segment_id) {
                        return action.payload;
                    }
                    return em;
                }),
            };
        case actionTypes.CONFIG.SMS.FETCH_CONFIG_SMS:
            return {
                ...state,
                ["sms"]: [...action.payload],
            };
        case actionTypes.CONFIG.SMS.CREATE_CONFIG_SMS:
            return {
                ...state,
                ["sms"]: [action.payload, ...state.email],
            };
        case actionTypes.CONFIG.SMS.UPDATE_CONFIG_SMS:
            return {
                ...state,
                sms: state.sms.map(sm => {
                    if (sm.segment_id === action.payload.segment_id) {
                        return action.payload;
                    }
                    return sm;
                }),
            };
    }
    return state;
}
