import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Colors from "@/components/utils/lib/colors";
import { TextField } from "redux-form-material-ui";
import NotificationActionSelect from "@/components/notifications/notification/create/controls/NotificationActionSelect";

export const WebNotificationButtonArray = ({
    fields,
    // eslint-disable-next-line no-unused-vars
    meta: { submitFailed },
    actions,
    defaultSelectedAction,
}) => (
    <div className="notif-block__body">
        {fields.map((btn, index) => (
            <div key={index} className="row">
                <div className="col-sm-6 col-xs-12">
                    <div className="btncla btncla--icon-select">
                        <button
                            className="btncla-close"
                            onClick={e => {
                                e.preventDefault();
                                fields.remove(index);
                            }}
                        >
                            <span />
                        </button>

                        <Field
                            name={`${btn}.btn_content`}
                            type="text"
                            placeholder="متن دکمه..."
                            component={TextField}
                            normalize={value => (!value ? " " : value)}
                            errorStyle={{
                                marginTop: "15px",
                            }}
                            underlineShow={false}
                            format={value => {
                                if (!value) return (value = "");
                                return value
                                    .toString()
                                    .replace(/^[\s]*(.*)$/, "$1");
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <Field
                        component={NotificationActionSelect}
                        name={`${btn}.btn_action`}
                        className="mui-select"
                        actions={actions}
                        defaultSelectedAction={defaultSelectedAction}
                        hintText="عملکرد مورد نظر را انتخاب کنید"
                        selectedMenuItemStyle={{ color: Colors.blue }}
                        fullWidth={true}
                        maxHeight={240}
                    />
                </div>
            </div>
        ))}

        {fields.length < 2 && (
            <button
                className="btncla-add"
                onClick={event => {
                    event.preventDefault();
                    fields.push({
                        btn_content: "",
                        btn_action: {},
                    });
                }}
            >
                + دکمه جدید
            </button>
        )}
    </div>
);

WebNotificationButtonArray.propTypes = {
    actions: PropTypes.array.isRequired,
    defaultSelectedAction: PropTypes.object,
};

export default WebNotificationButtonArray;
