import React from "react";
import PropTypes from "prop-types";
import { withErrorCatcher } from "@/components/utilities";
import { User } from "@/components/utils/lib/svgicons";
import IconButton from "material-ui/IconButton";
import { toFarsiNumbers } from "@/components/utilities";

const CountOfUsers = ({
    app: { transactional_users },
    isSmallSize,
    colSize,
}) => {
    const classes = !isSmallSize
        ? `table-col table-col--${colSize} color-gray-95`
        : "small-dateTime";

    return (
        <div className={classes}>
            {toFarsiNumbers(transactional_users)}
            <IconButton
                className="table-btn"
                data-tip="تعداد کاربر"
                tooltipPosition="top-center"
            >
                <User viewBox="0 0 21 21" />
            </IconButton>
        </div>
    );
};

CountOfUsers.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    colSize: PropTypes.number,
};

CountOfUsers.defaultProps = {
    app: {},
    colSize: 10,
};

export default withErrorCatcher(CountOfUsers);
