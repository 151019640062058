/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SegmentSelectOptionField from "@/components/users/segmentation/components/fields/SegmentSelectOptionField";
import { getApplicationTagsKeys } from "@/actions/applications";
import { formValueSelector } from "redux-form";

const SegmentTagKeysOptions = ({
    appId,
    getApplicationTagsKeys,
    tagKeys,
    ...props
}) => {
    const [customError, setCustomError] = useState(
        "ابتدا یک app id انتخاب کنید",
    );

    const onFailure = () => {
        setCustomError("هیچ مقداری پیدا نشد");
    };

    useEffect(() => {
        if (appId) {
            getApplicationTagsKeys({ app_id: appId }, { onFailure });
            setCustomError("");
        } else {
            setCustomError("ابتدا یک app id انتخاب کنید");
        }
    }, [appId]);
    return (
        <SegmentSelectOptionField
            {...props}
            errorText={props.errorText || customError}
            items={
                !appId && tagKeys
                    ? []
                    : tagKeys.map(item => ({ translation: item, value: item }))
            }
        />
    );
};

const mapStateToProps = state => {
    const {
        inprog: { tagKeys },
    } = state;
    const { app_id: appId } = formValueSelector("segments")(
        state,
        "app_id",
        "",
    );

    return {
        tagKeys: tagKeys ?? [],
        appId,
    };
};

const mapDispatchToProps = {
    getApplicationTagsKeys,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SegmentTagKeysOptions);
