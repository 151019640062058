
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`فعالسازی کتابخانه`}</h1>
    <p>{`برای فعالسازی لایبرری در پوشه بایستی ابتدا پلاگین نیتیو را به گریدل اضافه کنید.`}</p>
    <p>{`با توجه به وجود مشکل
`}<a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/unity/intro"
      }}><strong parentName="a">{`MultiDex`}</strong></a>{`
در صورت اضافه‌شدن پوشه و رخ‌ندادن این مشکل در صورتی که
minSDKVersion
برابر ۲۱ یا بالاتر باشد،`}</p>
    <Tabs isRtl='true' mdxType="Tabs">
      <Tab id={0} label="minSDK 21 or higher" mdxType="Tab">
        <p>{`در این حالت کافیست پس از ایمپورت کردن پکیچ از منوی زیر اقدام به Resolve کردن نمایید`}</p>
        <div dir='ltr'>
          <b>Assets -> External Dependency Manager -> Android Resolver -> Resolve (Force resolve یا)</b>
        </div>
        <p>{`سپس EDM4U اقدام به دریافت لایبرری‌ها خواهد کرد.`}</p>
      </Tab>
      <Tab id={1} label="minSDK lower than 21" mdxType="Tab">
        <p>{`با توجه به نسخه‌ی یونیتی فایل
`}<inlineCode parentName="p">{`mainTemplate.gradle`}</inlineCode>{`
یا
`}<inlineCode parentName="p">{`launcherTemplate.gradle`}</inlineCode>{`
را از مسیر
`}<inlineCode parentName="p">{`Assets/Pushe/Templates/`}</inlineCode>{`
به
`}<inlineCode parentName="p">{`Assets/Plugins/Android`}</inlineCode>{`
انتقال دهید.`}</p>
        <p>{`از منوی زیر Resolve را انتخاب کنید تا EDM4U پلاگین را سینک کند.`}</p>
        <div dir='ltr'>
          <p>{`Assets -> External Dependency Manager -> Android Resolver -> Resolve (Force resolve یا)`}</p>
        </div>
      </Tab>
    </Tabs>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;