import Step1 from "./step1.mdx";
import Step2 from "./step2.mdx";
import Step3 from "./step3.mdx";
import Step4 from "./step4.mdx";
import Step5 from "./step5.mdx";
import Step6 from "./step6.mdx";

export default [
    { component: Step1 },
    { component: Step2 },
    { component: Step3 },
    { component: Step4 },
    { component: Step5 },
    { component: Step6 },
];
