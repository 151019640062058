import React from "react";
import { NOTIFICATION_MODE } from "@/actions/_types";
import AndroidNotifWizard from "@/components/notifications/notification/create/android/AndroidNotifWizard";
import PageLoader from "@/components/utils/PageLoader";
import { bindActionCreators } from "redux/es/redux";
import { retrieveApplication } from "@/actions/applications";
import { retrieveNotification } from "@/actions/notifications";
import connect from "react-redux/es/connect/connect";
import IosNotifWizard from "@/components/notifications/notification/create/ios/IosNotifWizard";

const UpdateNotificationPage = ({
    match: { params },
    retrieveNotification,
    storedNotifications,
    history,
}) => {
    const { appId, platform, notificationId } = params;
    const PlatformWizard = PLATFORM_NOTIF_FORMS[platform];
    const notification = notificationId
        ? storedNotifications[notificationId]
        : {};

    return (
        <PageLoader
            history={history}
            perform={onFail => {
                if (notificationId)
                    retrieveNotification(
                        { notificationId, showErrorToast: true },
                        null,
                        onFail,
                    );
            }}
            isReady={!!notification}
        >
            <PlatformWizard
                history={history}
                notification={notification}
                notificationMode={NOTIFICATION_MODE.UPDATE}
                appId={appId}
            />
        </PageLoader>
    );
};

const PLATFORM_NOTIF_FORMS = {
    android: AndroidNotifWizard,
    ios: IosNotifWizard,
};

function mapStateToProps(state) {
    const { notifications } = state.inprog;
    return {
        storedNotifications: notifications,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { retrieveApplication, retrieveNotification },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateNotificationPage);
