import React, { useState } from "react";
import logo from "images/svg/pushe-logo-horizontal.svg";
import {
    createValidator,
    isEmail,
    isRequired,
} from "@/components/utils/validation";
import { Field, getFormSyncErrors, reduxForm } from "redux-form";
import SignUpTerms from "authentication/signup/partials/SignUpTerms";
import {
    CircleLoader,
    FlatButton,
    ReduxCheckbox,
    reduxSelectBox,
    ReduxSimpleTextField,
} from "@/components/utilities";
import { useDispatch, useSelector } from "react-redux";
import { SignUpPlus } from "@/actions/auth";
import SignUpSuccess from "authentication/signup/partials/SignUpSuccess";
import SignUpBanners from "authentication/signup/partials/SignUpBanners";
import css from "authentication/styles/signup.module";

const SignUpPage = props => {
    const { form, handleSubmit, submitFailed } = props;

    const dispatch = useDispatch();
    const [loading, toggleLoading] = useState(false);
    const [apiError, updateApiError] = useState(null);
    const [showCheckEmailPage, setShowCheckEmailPage] = useState(false);

    const formError = useSelector(state => getFormSyncErrors(form)(state));

    const handleSubmitForm = data => {
        updateApiError(null);
        toggleLoading(true);

        const extra = {
            onSuccess: () => {
                toggleLoading(false);
                setShowCheckEmailPage(true);
            },
            onFailure: apiError => {
                toggleLoading(false);
                updateApiError(apiError);
            },
        };

        SignUpPlus(data, extra)(dispatch);
    };
    const monthly_claimed_users = [
        { label: "کمتر از 50k", value: 1 },
        { label: "50k - 100k", value: 2 },
        { label: "100k - 500k", value: 3 },
        { label: "500k - 1M", value: 4 },
        { label: "بیشتر از 1M", value: 5 },
    ];
    return (
        <div className={css.wrapper}>
            <div className={css.innerWrapper}>
                <div className={css.banner}>
                    <SignUpBanners />
                </div>
                <div className={css.block}>
                    {showCheckEmailPage && <SignUpSuccess />}

                    {!showCheckEmailPage && (
                        <form className={css.formWrapper}>
                            <div className={css.logoPart}>
                                <img src={logo} alt="pushe.co logo" />
                            </div>
                            <div>
                                <Field
                                    autoFocus
                                    name="name"
                                    component={ReduxSimpleTextField}
                                    label="نام و نام‌خانوادگی"
                                />
                                <Field
                                    name={"monthly_claimed_users"}
                                    label={
                                        "کاربر فعال اپلیکیشن/وب‌سایت شما در ماه"
                                    }
                                    options={monthly_claimed_users}
                                    component={reduxSelectBox}
                                />
                                <Field
                                    name="phone"
                                    component={ReduxSimpleTextField}
                                    label="شماره‌تماس"
                                />
                                <Field
                                    name="email"
                                    component={ReduxSimpleTextField}
                                    label="ایمیل"
                                    type="email"
                                />
                                <Field
                                    name="app_name"
                                    component={ReduxSimpleTextField}
                                    label="آدرس مارکت اپلیکیشن"
                                />
                                <Field
                                    name="website_url"
                                    component={ReduxSimpleTextField}
                                    label="آدرس وب‌سایت"
                                />
                                <Field
                                    name="password"
                                    component={ReduxSimpleTextField}
                                    label="رمز عبور"
                                    type="password"
                                />
                                <Field
                                    name="passwordConfirm"
                                    component={ReduxSimpleTextField}
                                    label="تکرار رمز عبور"
                                    type="password"
                                />
                            </div>
                            <div className={css.termsPart}>
                                <div className={css.termsInner}>
                                    <Field
                                        name="agreeToTerms"
                                        component={ReduxCheckbox}
                                    />
                                    <SignUpTerms />
                                </div>

                                {submitFailed && formError?.agreeToTerms && (
                                    <div className={css.termsError}>
                                        لطفا شرایط استفاده و حریم خصوصی را قبول
                                        کنید.
                                    </div>
                                )}
                            </div>

                            <div className={css.buttonsPart}>
                                <FlatButton
                                    fullWidth
                                    label={"ثبت‌نام در پوشه"}
                                    onClick={handleSubmit(handleSubmitForm)}
                                    endIcon={loading ? <CircleLoader /> : null}
                                    disabled={loading}
                                />
                            </div>

                            {apiError && (
                                <p className={css.apiErrors}>{apiError}</p>
                            )}

                            <div className={css.mobileViewPricingLink}>
                                <a
                                    target="_blank"
                                    href="https://pushe.co/pricing-mobile"
                                    rel="noreferrer"
                                >
                                    قیمت‌گذاری{" "}
                                    <span className={css.arrow}>&#8592;</span>
                                </a>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

const validate = createValidator({
    name: isRequired,
    phone: isRequired,
    monthly_claimed_users: isRequired,
    email: [isRequired, isEmail],
    password: isRequired,
    passwordConfirm: isRequired,
    agreeToTerms: isRequired,
    app_name: (value, key, values) => {
        let website_url = values?.["website_url"];
        if (!website_url)
            return (
                isRequired(value) &&
                "حداقل یکی از دو فیلد آدرس مارکت اپلیکیشن یا آدرس وب‌سایت الزامی است"
            );
        return undefined;
    },
    website_url: (value, key, values) => {
        let app_name = values?.["app_name"];
        if (!app_name)
            return (
                isRequired(value) &&
                "حداقل یکی از دو فیلد آدرس مارکت اپلیکیشن یا آدرس وب‌سایت الزامی است"
            );
        return undefined;
    },
});

export default reduxForm({ form: "SignUp", validate })(SignUpPage);
