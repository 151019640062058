import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import { TextField } from "redux-form-material-ui";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: theme.typography.fontFamily.fnLight,
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: theme.palette.dashboard.blue,
        },
        color: theme.palette.dashboard.black,
        paddingRight: "0 !important",
        paddingLeft: "24px",
    },
    formControl: {
        margin: "10px 0",
        width: "100%",
    },
    labelRoot: {
        left: "unset",
        right: 0,
        transformOrigin: "top right",
        fontFamily: "inherit",
    },
    labelShrink: {
        transformOrigin: "top right",
        color: `${theme.palette.dashboard.blue} !important`,
    },
    selectIcon: {
        right: "unset",
        left: 0,
    },
    input: {
        padding: "8px 0 7px",
        color: theme.palette.dashboard.black,
    },
    inputUnderline: {
        borderBottom: "unset",
        "&:hover:not(.Mui-disabled)::before": {
            borderBottom: `1px solid ${theme.palette.dashboard.gray}`,
        },
        "&:before": {
            borderBottom: `1px solid ${theme.palette.dashboard.gray}`,
        },
        "&:after": {
            borderBottom: `2px solid ${theme.palette.dashboard.blue}`,
        },
    },
    errorRoot: {
        textAlign: "right",
    },
}));

const colorPickerStyles = {
    colorPickerRoot: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "flex-start",
    },
    colorPickerLabel: {
        marginRight: "10px",
    },
};

export const SelectBox = props => {
    const classes = useStyles();
    const { options, onChange, value, label, error, ...otherProps } = props;

    const [val, setValue] = useState(null);

    const handleChangeValue = event => {
        const v = event.target.value;
        if (typeof onChange === "function") return onChange(v);

        setValue(v);
    };

    return (
        <FormControl className={classes.formControl} error={error}>
            <InputLabel
                id="simple-select-label"
                classes={{
                    root: classes.labelRoot,
                    shrink: classes.labelShrink,
                }}
            >
                {label}
            </InputLabel>
            <Select
                labelId="simple-select-label"
                id="demo-simple-select"
                value={value || val || ""}
                input={
                    <Input classes={{ underline: classes.inputUnderline }} />
                }
                classes={{
                    root: classes.root,
                    icon: classes.selectIcon,
                }}
                onChange={handleChangeValue}
                {...otherProps}
            >
                {options &&
                    options.map((option, idx) => (
                        <MenuItem key={idx} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
            </Select>
            {error && (
                <FormHelperText classes={{ root: classes.errorRoot }}>
                    {error}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export const reduxSelectBox = props => {
    const {
        meta: { touched, error },
        input: { value, onChange },
        ...otherProps
    } = props;

    return (
        <SelectBox
            error={touched && error}
            value={value}
            onChange={onChange}
            {...otherProps}
        />
    );
};

export const ColorPicker = ({ input, label, defaultValue, ...otherProps }) => {
    const { onChange, name } = input;
    let { value } = input;

    if (!value) {
        value = defaultValue;
    }
    return (
        <div style={colorPickerStyles.colorPickerRoot}>
            <InputLabel style={colorPickerStyles.colorPickerLabel}>
                {label}
            </InputLabel>
            <TextField
                name={name}
                {...otherProps}
                value={value}
                type="color"
                onChange={onChange}
                style={{
                    marginLeft: "auto",
                    padding: "5px",
                    boxShadow: "1px 0 7px 1px rgba(0,0,0,.4)",
                    borderRadius: "2px",
                    cursor: "pointer",
                }}
                underlineShow={false}
            />
        </div>
    );
};
