import React from "react";
import AndroidGeofenceWizard from "@/components/notifications/geofence/create/android/AndroidGeofenceWizard";
import PageLoader from "@/components/utils/PageLoader";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux/es/redux";
import { retrieveGeofence } from "@/actions/geofence";
import { withErrorCatcher } from "@/components/utilities";
import { retrieveNotification } from "@/actions/notifications";
import { isEmpty } from "lodash";
import { GEOFENCE_WIZARD_MODE } from "@/components/notifications/geofence/constants";
import { useSelector } from "react-redux";

const GeofenceEditPage = props => {
    const {
        match: { params },
        history,
        retrieveGeofence,
        selectedNotifications,
        retrieveNotification,
    } = props;

    const { geofenceId } = params;
    const geofence = useSelector(
        state => state?.geofence?.geofence?.[geofenceId] ?? {},
    );
    const { wrapper_id } = geofence || {};
    const notification = wrapper_id ? selectedNotifications[wrapper_id] : {};

    return (
        <PageLoader
            perform={onFail => {
                if (geofenceId && isEmpty(geofence)) {
                    retrieveGeofence(geofenceId, null, onFail);
                }
                if (wrapper_id && isEmpty(notification)) {
                    retrieveNotification(
                        { notificationId: wrapper_id },
                        null,
                        onFail,
                    );
                }
            }}
            history={history}
            isReady={!isEmpty(geofence) && !isEmpty(notification)}
        >
            <AndroidGeofenceWizard
                history={history}
                wizardMode={GEOFENCE_WIZARD_MODE.EDIT}
                geofence={geofence}
                notification={notification}
            />
        </PageLoader>
    );
};

const mapStateToProps = state => {
    const {
        inprog: { notifications = {} },
    } = state;
    return {
        selectedNotifications: notifications,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { retrieveGeofence, retrieveNotification },
        dispatch,
    );
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(GeofenceEditPage),
);
