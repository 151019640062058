import actionTypes from "@/actions/_types";

export const financialInitState = {
    invoice: {},
    invoices: {
        offset: 0,
        hasMore: true,
        items: [],
    },
};

export default (state = financialInitState, action) => {
    switch (action.type) {
        case actionTypes.FINANCIAL.FETCH_INVOICE_ITEMS:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    offset: action.payload.offset,
                    hasMore: action.payload.hasMore,
                    items: [...state.invoices.items, ...action.payload.items],
                },
            };
        case actionTypes.FINANCIAL.FETCH_INVOICE_DETAILS:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    [action.payload.invoiceId]: action.payload.invoice,
                },
            };
        case actionTypes.FINANCIAL.CLEAR_FETCHED_INVOICES_STORE:
            return {
                ...state,
                invoice: action.payload.invoice,
                invoices: action.payload.invoices,
            };
        default:
            return state;
    }
};
