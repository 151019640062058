class MyStorage {
    constructor() {
        this.supportsLocalStorage = true;
    }

    setItem(id, value) {
        return localStorage.setItem(id, value);
    }

    getItem(id) {
        return localStorage.getItem(id);
    }

    removeItem(id) {
        return localStorage.removeItem(id);
    }

    clear() {
        return localStorage.clear();
    }
}

let myStorage;

let fallbackStorage = {
    _data: {},
    setItem: function (id, val) {
        this._data[id] = String(val);
        return undefined;
    },
    getItem: function (id) {
        return id in this._data ? this._data[id] : undefined;
    },
    removeItem: function (id) {
        delete this._data[id];
        return undefined;
    },
    clear: function () {
        this._data = {};
        return undefined;
    },
    supportsLocalStorage: false,
};

try {
    if ("localStorage" in window && window.localStorage !== null) {
        myStorage = new MyStorage();
    } else {
        myStorage = fallbackStorage;
    }
} catch (e) {
    myStorage = fallbackStorage;
}

export default myStorage;
