import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "styles/modules/_mod-modal.scss";
import eventHandler from "@/components/utils/events/eventHandler";

export default class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
        };

        this.onKeyUp = this.onKeyUp.bind(this);
        this.onKill = this.onKill.bind(this);
    }

    componentDidMount() {
        setTimeout(() => this.setState({ isMounted: true }));
        eventHandler.on("keyup", this.onKeyUp, { key: "modalKeyUp" });
    }

    componentWillUnmount() {
        eventHandler.remove("keyup", "modalKeyUp");
    }

    onKeyUp(e) {
        if (e.keyCode === 27) {
            this.props.isModalInside && this.onKill();
        }
    }

    onKill() {
        const onModalClose =
            typeof this.props.onModalClose !== "undefined"
                ? this.props.onModalClose
                : false;
        this.setState({ isMounted: false });

        if (onModalClose) {
            // after modals close animation has ended
            setTimeout(onModalClose, this.props.modalCloseDelay);
        }
    }

    render() {
        const { children, id } = this.props;
        const { isMounted } = this.state;

        const onModalClose =
            typeof this.props.onModalClose !== "undefined"
                ? this.props.onModalClose
                : false;
        const className =
            typeof this.props.className !== "undefined"
                ? this.props.className
                : "";

        return (
            <div
                className={classNames("modal", `${className}`, {
                    "modal--show": isMounted,
                })}
                ref={ref => (this.modal = ref)}
                // onClick={this.onKill}
                onKeyUp={this.onKeyUp}
                id={id}
            >
                <div className="modal-wrap" onClick={e => e.stopPropagation()}>
                    {onModalClose && (
                        <button
                            className="modal-close"
                            onClick={this.onKill}
                        ></button>
                    )}
                    {children}
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    onModalClose: PropTypes.func,
    modalCloseDelay: PropTypes.number,
    className: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.node,
};

Modal.defaultProps = {
    modalCloseDelay: 300,
};
