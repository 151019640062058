/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withErrorCatcher } from "@/components/utilities";
import PagePlaceholder from "@/components/utilities/extra/PagePlaceholder";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import ReactTooltip from "react-tooltip";
import AppIcon from "images/svg-inline/app.svg";
import {
    steps,
    mobileSteps,
} from "@/components/app/introduction/partials/application";
import { useDriver } from "@/components/app/introduction/useDriver";

const ApplicationPagePlaceholder = ({ history, platform }) => {
    const isWeb = platform === "web";

    const { run, driver } = useDriver({ steps, mobileSteps, allowClose: true });

    useEffect(() => {
        platform === "android" && run();
    }, []);

    return (
        <>
            <PagePlaceholder
                icon={<AppIcon />}
                body={
                    <>
                        <h4>
                            شما تا کنون هیچ
                            {isWeb ? " وب‌سایتی " : " اپلیکیشنی "}
                            ایجاد نکرده‌اید
                        </h4>
                        <p>
                            برای ارسال نوتیفیکیشن به
                            {isWeb ? " وب‌سایت‌های " : " اپ‌های "}
                            خود ابتدا آنها را ایجاد کنید.
                        </p>
                    </>
                }
                footer={
                    <FlatButton
                        id="create-application-button"
                        className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                        rippleColor={blue900}
                        label={
                            isWeb ? "ساخت ‌وب‌سایت جدید" : "ساخت اپلیکیشن جدید"
                        }
                        onClick={() => {
                            if (driver) driver.reset();
                            history.push(`/application/${platform}/create`);
                        }}
                    />
                }
            />
            <ReactTooltip className="tooltip" effect="solid" place="bottom" />
        </>
    );
};

export default withErrorCatcher(ApplicationPagePlaceholder);
