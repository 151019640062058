import actionTypes from "@/actions/_types";

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.HINT.FETCH_ANDROID_HINTS:
            return action.payload;
    }
    return state;
}
