/**
 * Features Permission list
 */

const PERMISSIONS = {
    // CSV Filter File
    CSV_FILTER_TAB: "mobile_nt_csv_filter_perm",
    SEGMENT_IDS_FILTER_TAB: "mobile_nt_segment_id_filter_perm",

    // Automation
    NOTIFICATION_AUTOMATION_LIST: "list_notification_automation_perm",
    NOTIFICATION_AUTOMATION_CREATE: "create_notification_automation_perm",

    // Geofence
    GEOFENCE_LIST: "list_geofence_perm",
    GEOFENCE_CREATE: "create_geofence_perm",

    // Segmentation
    SEGMENTATION_LIST: "list_segmentation_perm",
    SEGMENTATION_CREATE: "create_segmentation_perm",
    SEGMENTATION_EVENTS: "seg_events_perm",
    SEGMENTATION_SESSIONS: "seg_sessions_perm",
    SEGMENTATION_TAGS: "seg_tags_perm",

    EMAIL_LIST: "list_email_perm",
    EMAIL_CREATE: "create_email_perm",
    EMAIL_AUTOMATION_LIST: "list_email_automation_perm",
    EMAIL_AUTOMATION_CREATE: "create_email_automation_perm",

    SMS_LIST: "list_sms_perm",
    SMS_CREATE: "create_sms_perm",
    SMS_AUTOMATION_LIST: "list_sms_automation_perm",
    SMS_AUTOMATION_CREATE: "create_sms_automation_perm",
};

export { PERMISSIONS };
