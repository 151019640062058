import React from "react";
import { humanizeToEnglish, withErrorCatcher } from "@/components/utilities";
import CardStatisticsPlaceholder from "../components/CardStatisticsPlaceholder";

const StatisticsComponent = ({ statistics, meta, isDraft }) => {
    return !isDraft ? (
        <div className="card-list__statistics">
            {!isDraft &&
                meta.map((m, idx) => {
                    const key = m.key;
                    const icon = m.icon;
                    const name = m.name;

                    return (
                        <div data-tip={name} key={idx}>
                            <svg>
                                <use xlinkHref={`#${icon}`} />
                            </svg>
                            <span className="card-list__statistics-value">
                                {humanizeToEnglish(statistics?.[key])}
                            </span>
                        </div>
                    );
                })}
        </div>
    ) : (
        <CardStatisticsPlaceholder />
    );
};

export default withErrorCatcher(StatisticsComponent);
