import * as events from "./events";
import ReactGA from "react-ga";
import { isEmpty } from "lodash";

export const ANALYTICS_EVENTS_ACTION = {
    LIMITED: "LIMITED",
    EVENTS_FILTER: "EVENTS_FILTER",
    SESSION_FILTER: "SESSION_FILTER",
    NOTIFICATION_ACTION: "NOTIFICATION_ACTION",
};

function analyticEventManager(type, data) {
    let event = {};

    switch (type) {
        case ANALYTICS_EVENTS_ACTION.LIMITED:
            event = events[ANALYTICS_EVENTS_ACTION.LIMITED];
            break;
        case ANALYTICS_EVENTS_ACTION.EVENTS_FILTER:
            event = events[ANALYTICS_EVENTS_ACTION.EVENTS_FILTER];
            break;
        case ANALYTICS_EVENTS_ACTION.SESSION_FILTER:
            event = events[ANALYTICS_EVENTS_ACTION.SESSION_FILTER];
            break;
        case ANALYTICS_EVENTS_ACTION.NOTIFICATION_ACTION:
            event = events[ANALYTICS_EVENTS_ACTION.NOTIFICATION_ACTION];
            break;
    }

    if (data && !isEmpty(data)) {
        const obj = {
            label: data.wrapperId || data.label,
            action: data.actionText,
        };

        event = { ...event, ...obj };
    }

    if (!isEmpty(event)) {
        ReactGA.event(event);
    }
}

export default analyticEventManager;
