import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const PagePlaceholder = props => {
    const { body, footer, icon: Icon, component: Component } = props;

    return (
        <div className="modPlaceholder">
            {Component && { Component }}
            {!Component && (
                <>
                    <div className="modPlaceholder__icon-wrap">
                        {typeof Icon === "object" ? Icon : <Icon />}
                    </div>
                    <div className="modPlaceholder__body">{body}</div>
                    <div className="modPlaceholder__footer">{footer}</div>
                </>
            )}
        </div>
    );
};

export default withErrorCatcher(PagePlaceholder);
