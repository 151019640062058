import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import CardListBaseTabs from "@/components/utilities/modules/CardList/components/base/CardListBaseTabs";

const CardBody = props => {
    const {
        children,
        tabs,
        tabProps,
        fixedComponents = [],
        fixedProps = {},
    } = props;

    return (
        <div className="card-list__body">
            {children || (
                <>
                    <CardListBaseTabs tabs={tabs} tabProps={tabProps} />
                    {fixedComponents.map((Component, idx) => (
                        <Component key={idx} {...fixedProps} />
                    ))}
                </>
            )}
        </div>
    );
};

export default withErrorCatcher(CardBody);
