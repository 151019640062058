import React, { useMemo, useState } from "react";
import FinancialTable from "@/components/financial/partials/FinancialTable";
import {
    thousandSeparatorFarsi,
} from "@/components/utilities";
import { Link } from "react-router-dom";
import { useHandleReturnFromBank } from "@/components/financial/hooks/useHandleReturnFromBank";

const FinancialPage = props => {
    const { history } = props;

    const [selectedItems, updateSelectedItems] = useState([]);

    const totalPrice = useMemo(() => {
        return (selectedItems => {
            if (!Array.isArray(selectedItems)) return 0;
            return selectedItems.reduce((acc, curr) => {
                acc += curr["payable_amount"];
                return acc;
            }, 0);
        })(selectedItems);
    }, [selectedItems]);

    useHandleReturnFromBank(history);

    const changePlanIsEnabled = false;

    return (
        <div className="financial-page">
            <FinancialTable onSelectedItemsChanged={updateSelectedItems} />

            <div className="financial-page__footer">
                {/*<div className="financial-page__offline-info">*/}
                {/*    <span>{BANK_NAME}</span>*/}
                {/*    <div>*/}
                {/*        <span>شماره شبا: </span>*/}
                {/*        <span className="number">*/}
                {/*            <CPTextToClipboard>*/}
                {/*                {BANK_SHEBA_NUMBER}*/}
                {/*            </CPTextToClipboard>*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <a*/}
                {/*        className="link"*/}
                {/*        href={TAX_CERTIFICATE_LINK}*/}
                {/*        target="_blank"*/}
                {/*        rel="noreferrer"*/}
                {/*    >*/}
                {/*        گواهی مالیات بر ارزش افزوده*/}
                {/*    </a>*/}
                {/*</div>*/}

                <div className="financial-page__buttons" id="financialButtons">
                    {totalPrice > 0 && (
                        <div className="top">
                            <span className="total-price-title">
                                مجموع قیمت
                            </span>
                            <span className="total-price-number">
                                {thousandSeparatorFarsi(totalPrice)}
                                <span className="total-price-unit"> ریال</span>
                            </span>
                        </div>
                    )}
                    <div className="bottom">
                        {changePlanIsEnabled && (
                            <Link to="/">
                                تغییر سرویس <span>&#10229;</span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinancialPage;
