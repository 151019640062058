import React from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { formValueSelector, reduxForm } from "redux-form";
import { uploadFile } from "@/actions/app";
import { NOTIFICATION_MODE } from "@/actions/_types";
import { hasOneSignal as checkHasOneSignal } from "@/utils";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { isNotificationActionValid } from "@/components/notifications/notification/validation";
import { IOS_ACTIONS } from "@/components/notifications/notification/actions";
import {
    arrayValidator,
    createValidator,
    isJsonString,
    isRequired,
    withMessage,
} from "@/components/utils/validation";
import { NOTIFICATION_TYPE } from "@/components/notifications/notification/constants";
import { withErrorCatcher } from "@/components/utilities";
import { some, values } from "lodash";
import SelectNotificationTypeComponent from "@/components/utilities/commons/wizard/components/SelectNotificationTypeComponent";
import SelectApplicationComponent from "@/components/utilities/commons/wizard/components/SelectApplicationComponent";
import NotificationContentComponent from "@/components/utilities/commons/wizard/components/NotificationContentComponent";
import ChooseVersionComponent from "@/components/utilities/commons/wizard/components/ChooseVersionComponent";
import NotificationJsonComponent from "@/components/utilities/commons/wizard/components/NotificationJsonComponent";
import NotificationButtonsComponent from "@/components/utilities/commons/wizard/components/NotificationButtonsComponent";
import { NOTIFICATION_TYPES } from "@/components/utilities/commons/wizard/constants";

const IosNotifStep1 = props => {
    const {
        applications,
        notificationTypes,
        uploadFile,
        appSelectionMethod,
        hasOneSignal,
        change,
        passToSteps: { notificationMode },
    } = props;
    const platform = useSelector(s => s?.app?.platform);
    return (
        <form className="notif-step1">
            <SelectNotificationTypeComponent
                notificationTypes={NOTIFICATION_TYPES.map(i => {
                    // Because we dont' support json already, disable it here
                    if (i.name === NOTIFICATION_TYPE.CUSTOM_CONTENT) {
                        i.enabled = false;
                    }
                    return i;
                })}
            />

            {notificationMode === NOTIFICATION_MODE.NORMAL && (
                <SelectApplicationComponent
                    platform={platform}
                    applications={applications}
                    appSelectionMethod={appSelectionMethod}
                    hasOneSignal={hasOneSignal}
                    change={change}
                />
            )}

            {notificationTypes[NOTIFICATION_TYPE.NOTIF] && (
                <NotificationContentComponent
                    change={change}
                    uploadFile={uploadFile}
                    actionsList={IOS_ACTIONS}
                    hasIconField={false}
                    hasImageField={true}
                    hasSubTitleField={true}
                />
            )}
            {notificationMode === NOTIFICATION_MODE.UPDATE && (
                <ChooseVersionComponent fieldName={"av_code"} />
            )}

            {/* We don't support json yet, so disable it here */}
            {notificationTypes[NOTIFICATION_TYPE.CUSTOM_CONTENT] && false && (
                <NotificationJsonComponent />
            )}

            <NotificationButtonsComponent
                hasOneSignal={hasOneSignal}
                actionsList={IOS_ACTIONS}
                defaultSelectedAction={IOS_ACTIONS.find(
                    a => a.id === "open_app",
                )}
                enableIcons={false}
            />
        </form>
    );
};

const validators = createValidator({
    notificationTypes: value =>
        some(values(value), x => x === true)
            ? undefined
            : FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TYPE,
    app_ids: (value, key, allValues) =>
        allValues.isTestMode ||
        (value && value.length) > 0 ||
        (allValues.partners && allValues.partners.length > 0)
            ? undefined
            : FORM_ERRORS.NO_APP_SELECTED,
    data: {
        title: withMessage(
            isRequired,
            FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TITLE,
        ),
        content: withMessage(
            isRequired,
            FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_CONTENT,
        ),
        action: isNotificationActionValid(IOS_ACTIONS),
        buttons: arrayValidator({
            btn_content: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_CONTENT_FIELD,
            ),
            btn_action: isNotificationActionValid(IOS_ACTIONS),
        }),
    },
    custom_content: isJsonString,
});

function mapStateToProps(state) {
    const platform = "ios";

    const {
        notificationTypes,
        appSelectionMethod,
        app_ids = [],
    } = formValueSelector("notification_wizard")(
        state,
        "notificationTypes",
        "appSelectionMethod",
        "app_ids",
    );
    const { profile = {} } = state.auth;
    const { email } = profile;
    const applications = state[platform].applications || [];
    const hasOneSignal = checkHasOneSignal(app_ids, applications);
    const { aggregations = {} } = state[platform];
    const { contracts: aggregatorContracts = [] } = aggregations;

    return {
        hasOneSignal,
        applications,
        aggregatorContracts: aggregatorContracts.filter(
            item => item.aggregator === email,
        ),
        appSelectionMethod,
        notificationTypes: notificationTypes || {},
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ uploadFile }, dispatch);
}

const IosNotifStep1RF = reduxForm({
    form: "notification_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(IosNotifStep1);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(IosNotifStep1RF),
    ),
);
