import FixedPageHeader from "@/components/utilities/commons/FixedPageHeader";
import React from "react";

function CardPageFixedHeader(props) {
    const { title, children } = props;

    return (
        <FixedPageHeader>
            <div className="fixed-header__page-header">
                {title && (
                    <header>
                        <p>{title}</p>
                    </header>
                )}

                {children}
            </div>
        </FixedPageHeader>
    );
}

export default CardPageFixedHeader;
