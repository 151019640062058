import IconButton from "material-ui/IconButton";
import FlatButton from "material-ui/FlatButton";
import { User } from "@/components/utils/lib/svgicons";
import { blue900 } from "material-ui/styles/colors";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

const styles = {
    iconButtonUser: {
        display: "inline-block",
        verticalAlign: "middle",
        width: "1.875rem",
        height: "1.875rem",
        padding: "0",
    },
};

const NumberOfUsers = ({
    app = {},
    isSmallSize,
    colSize,
    handleFrameworkOrGuide,
}) => {
    const { app_id, framework, transactional_users } = app;
    let classes = !isSmallSize
        ? `table-col table-col--${colSize}`
        : "small-numberOfUsers";

    return (
        <div className={classes}>
            {transactional_users ? (
                <div style={{ display: "inline-block" }}>
                    <span className="text-fa">{transactional_users}</span>
                    <IconButton
                        className="table-btn"
                        data-tip="تعداد کاربر"
                        tooltipPosition="top-center"
                        style={styles.iconButtonUser}
                    >
                        <User viewBox="0 0 21 21" />
                    </IconButton>
                </div>
            ) : (
                <div style={{ display: "inline-block" }}>
                    {!isSmallSize && (
                        <FlatButton
                            className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                            rippleColor={blue900}
                            label="آموزش"
                            onClick={() =>
                                handleFrameworkOrGuide("set", {
                                    app_id,
                                    framework,
                                    page: "guide",
                                })
                            }
                        />
                    )}
                </div>
            )}

            <ReactTooltip className="tooltip" effect="solid" />
        </div>
    );
};

NumberOfUsers.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    colSize: PropTypes.number,
};

NumberOfUsers.defaultProps = {
    app: {},
    colSize: 15,
};

export default withErrorCatcher(NumberOfUsers);
