import React, { useState } from "react";
import {
    CircleLoader,
    CPTextToClipboard,
    withErrorCatcher,
    FlatButton,
} from "@/components/utilities";
import { useRetrieveCredentials } from "@/components/app/profile/hooks/useRetrieveCredentials";
import css from "@/components/app/profile/styles/credential.module.scss";
import {
    PTable,
    PTableBody,
    PTableHeader,
    PTableItem,
    PTableRow,
} from "@/components/utilities/modules/PTable";
import { CREDENTIAL_STATUS } from "@/components/app/profile/constants";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteCredential } from "@/actions/credentials";
import CredentialDeleteConfirm from "@/components/app/profile/partials/CredentialDeleteConfirm";
import { useDispatch } from "react-redux";
import CredentialAdd from "@/components/app/profile/partials/CredentialAdd";

const CredentialsTab = () => {
    const [openConfirmDelete, toggleConfirmDelete] = useState(false);
    const [openAdd, toggleDisplayAdd] = useState(false);
    const { inProgress, credentials } = useRetrieveCredentials();
    const dispatch = useDispatch();

    const handleDeleteCredential = pk => {
        deleteCredential(pk)(dispatch);
        toggleConfirmDelete(false);
    };

    return (
        <>
            <div className={css.tabHeader}>
                <div>مدیریت شناسه‌ها</div>
                <FlatButton
                    label={"اضافه کردن شناسه جدید"}
                    onClick={() => toggleDisplayAdd(true)}
                />
            </div>

            <PTable columns={4} className={css.credentialWrapper}>
                <PTableHeader>
                    <PTableItem expand={1.5} className={css.tableHeader}>
                        Client Key
                    </PTableItem>
                    <PTableItem expand={2.5} className={css.tableHeader}>
                        Server Key
                    </PTableItem>
                    <PTableItem className={css.tableHeader}>وضعیت</PTableItem>
                    <PTableItem className={css.tableHeader}>حذف</PTableItem>
                </PTableHeader>

                <PTableBody>
                    {inProgress && <CircleLoader />}

                    {!inProgress &&
                        credentials &&
                        credentials.map(credential => (
                            <PTableRow
                                key={credential.pk}
                                className={css.credentialMainWrapper}
                            >
                                <PTableItem
                                    expand={1.5}
                                    className={css.credentialClientKey}
                                    mobileTitle="Client Key: "
                                >
                                    {credential.client_key}
                                    <CPTextToClipboard
                                        text={credential.client_key}
                                    >
                                        <button
                                            className={css.credentialKeyCopy}
                                        >
                                            copy
                                        </button>
                                    </CPTextToClipboard>
                                </PTableItem>

                                <PTableItem
                                    expand={2.5}
                                    className={css.credentialServerKey}
                                    mobileTitle="Server Key: "
                                >
                                    <p>
                                        {credential.server_key}
                                        <CPTextToClipboard
                                            text={credential.server_key}
                                        >
                                            <button
                                                className={
                                                    css.credentialKeyCopy
                                                }
                                            >
                                                copy
                                            </button>
                                        </CPTextToClipboard>
                                    </p>
                                </PTableItem>

                                <PTableItem mobileTitle="status: ">
                                    {CREDENTIAL_STATUS[credential.status]}
                                </PTableItem>

                                <PTableItem>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() =>
                                            toggleConfirmDelete(credential.pk)
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </PTableItem>

                                <CredentialDeleteConfirm
                                    open={openConfirmDelete === credential.pk}
                                    onClose={() => toggleConfirmDelete(false)}
                                    onAccept={() =>
                                        handleDeleteCredential(credential.pk)
                                    }
                                />
                            </PTableRow>
                        ))}
                </PTableBody>
            </PTable>

            {openAdd && (
                <CredentialAdd
                    open={true}
                    onClose={() => toggleDisplayAdd(false)}
                />
            )}
        </>
    );
};

export default withErrorCatcher(CredentialsTab);
