import React from "react";
import { CHART_LIST } from "@/components/utils/NewCharts/common";
import CustomChart from "@/components/utils/NewCharts/CustomChart";
import isEmpty from "lodash/isEmpty";
import EmptyChartImagePlaceHolder from "@/components/utilities/emptyChartImagePlaceHolder";
import dailyStatistics from "images/charts/daily_statistics.jpeg";
import { FIRST_DAY_EMPTY_CHART_MESSAGE } from "@/constants";
import Util from "@/utils";
import { optionsChartAllInstall } from "./optionsAllInstalChart";
import { CheckArrayItemListWithOutZero } from "@/components/utils/checkArrayItemListWithOutZero";
const uuid = require("uuid/v4");

const AllInstallChartMore = props => {
    const {
        data = [],
        seriesOfAll,
        title,
        helper,
        height = 400,
        placeHolderText,
    } = props;

    const gateDate = () => {
        let jalaliDate;
        jalaliDate =
            data?.date &&
            data?.date.map(
                day => Util._convertToLocalTimezone(day).split(" ")[1],
            );
        return jalaliDate;
    };
    const ChartData = seriesOfAll(data);
    function chartConfig(chart) {
        return {
            labels: gateDate(),
            data: chart,
        };
    }
    let isMultiple = ChartData.length > 1;

    return (
        <>
            <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="card-g">
                    <header className="card-g__header">
                        <p className="card-g__title">
                            <svg>
                                <use xlinkHref="#svg_installed_all"></use>
                            </svg>
                            {title}
                        </p>
                        {helper && (
                            <span
                                className="more-info"
                                data-class="tooltip tooltip--long"
                                data-tip={helper}
                            >
                                ؟
                            </span>
                        )}
                    </header>
                    {isEmpty(ChartData?.[0]?.[0]?.data) ||
                    CheckArrayItemListWithOutZero(ChartData?.[0]?.[0]?.data) ? (
                        <EmptyChartImagePlaceHolder
                            src={dailyStatistics}
                            massage={
                                placeHolderText || FIRST_DAY_EMPTY_CHART_MESSAGE
                            }
                        />
                    ) : (
                        ChartData.map(chart => (
                            <div
                                className={`card-g__body ${
                                    isMultiple ? "small" : ""
                                }`}
                                key={uuid()}
                            >
                                <div className="chart">
                                    <CustomChart
                                        height={height}
                                        options={optionsChartAllInstall}
                                        dataChart={chartConfig(chart)}
                                        typeChart={CHART_LIST.Line.name}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    );
};
export default AllInstallChartMore;
