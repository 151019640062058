import React, { useState } from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
    FLAT_BUTTON_TYPE,
    FlatButton,
    SimpleTextField,
    toEnglishNumbers,
    withErrorCatcher,
} from "@/components/utilities";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(_theme => ({
    saveContentTextRoot: {
        textAlign: "right",
        fontSize: ".9rem",
        color: "#000",
        padding: "5px 20px",
        marginBottom: 0,
    },
}));

const AddFaveItemComponent = props => {
    const { onToggle, addItemMutation, isLoading } = props;

    const classes = useStyles();

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const handleSaveAction = async () => {
        if (phoneNumber && name) {
            await addItemMutation(
                { name, phone_number: phoneNumber },
                {
                    onSuccess: () => {
                        setPhoneNumber("");
                        setName("");
                        onToggle(false);
                    },
                },
            );
        } else {
            if (!phoneNumber) setPhoneNumberError("این فیلد اجباری است");
            if (!name) setNameError("این فیلد اجباری است");
        }
    };

    return (
        <DialogContent>
            <DialogContentText classes={{ root: classes.saveContentTextRoot }}>
                شماره ای که می‌خواهید به عنوان مورد علاقه ذخیره کنید را به همراه
                نام وارد کنید و دکمه ذخیره را بفشارید.
            </DialogContentText>
            <SimpleTextField
                autoFocus
                margin="dense"
                id="number"
                label="شماره همراه"
                error={phoneNumberError}
                helperText={phoneNumberError}
                type="text"
                fullWidth
                value={phoneNumber}
                onChange={e => {
                    if (phoneNumberError) setPhoneNumberError("");
                    setPhoneNumber(toEnglishNumbers(e.target.value));
                }}
                onKeyPress={e => e.charCode === 13 && handleSaveAction()}
            />
            <SimpleTextField
                margin="dense"
                id="name"
                label="نام"
                error={nameError}
                helperText={nameError}
                type="text"
                fullWidth
                value={name}
                onChange={e => {
                    if (nameError) setNameError("");
                    setName(e.target.value);
                }}
                onKeyPress={e => e.charCode === 13 && handleSaveAction()}
            />
            <FlatButton
                label="بی‌خیال"
                fullwidth
                buttonType={FLAT_BUTTON_TYPE.GRAY}
                onClick={() => {
                    setName("");
                    setPhoneNumber("");
                    onToggle(false);
                }}
            />
            <FlatButton
                label="ذخیره"
                fullwidth
                disabled={isLoading}
                onClick={handleSaveAction}
            />
        </DialogContent>
    );
};

export default withErrorCatcher(AddFaveItemComponent);
