import { useEffect } from "react";
import { useDispatch } from "react-redux";
import myStorage from "@/myStorage";
import { signOut } from "@/actions/auth";

export const useHandleSignOutUser = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (myStorage.getItem("token")) {
            signOut({ destPage: "/noafarin" })(dispatch);
        }
    }, [dispatch]);
};
