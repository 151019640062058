import { NOTIFICATION_TYPE } from "@/components/notifications/notification/constants";
import { isEmpty } from "lodash";
import { ttlOptions } from "@/constants";
import { normalize } from "@/components/utils/normalize";
import { automationToFormNormalizer } from "@/components/notifications/automation/normalize";

export const createInitialFormData = (automation, notification = {}) => {
    try {
        if (isEmpty(automation)) {
            return {
                notificationTypes: {
                    [NOTIFICATION_TYPE.NOTIF]: true,
                    [NOTIFICATION_TYPE.CUSTOM_CONTENT]: false,
                },
                notification: {
                    data: {
                        action: {
                            id: "open_app",
                            action_type: "A",
                        },
                    },
                    time_to_live: ttlOptions[15].key,
                },
                fullContentType: "content",
            };
        } else {
            const custom_content = !isEmpty(notification.custom_content)
                ? JSON.stringify(notification.custom_content)
                : undefined;

            const createdData = {
                notificationTypes: {
                    [NOTIFICATION_TYPE.NOTIF]:
                        (notification.data || {}).title ||
                        !(
                            notification.custom_content &&
                            notification.custom_content !== {}
                        ),
                    [NOTIFICATION_TYPE.CUSTOM_CONTENT]: !isEmpty(
                        notification.custom_content,
                    ),
                },
                ...automation,
                notification: {
                    data: {
                        action: {
                            id: "open_app",
                            action_type: "A",
                        },
                        ...notification.data,
                    },
                    ...notification,
                    custom_content,
                },
            };

            return normalize(createdData, automationToFormNormalizer);
        }
    } catch (e) {
        return {};
    }
};
