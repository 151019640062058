import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import AndroidGeofenceCreatePage from "@/components/notifications/geofence/create/android/AndroidGeofenceWizard";
import { GEOFENCE_WIZARD_MODE } from "@/components/notifications/geofence/constants";

const GeofenceCreatePage = ({ history }) => {
    return (
        <AndroidGeofenceCreatePage
            history={history}
            wizardMode={GEOFENCE_WIZARD_MODE.CREATE}
        />
    );
};

export default withErrorCatcher(GeofenceCreatePage);
