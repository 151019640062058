import React from "react";

import { getDataCharts } from "@/components/users/installations/web/component/WebInstallationsChart/Pie/common";
import PieCu from "./Pie/PieCu";

const WebInstallationsChart = props => {
    const { dashboard } = props;
    let chartData = (getDataCharts(dashboard) || [])["pie"] || [];
    return (
        <>
            {chartData.length > 0 &&
                chartData.map((pieChart, index) => (
                    <div
                        key={index}
                        className="col-lg-3 col-md-6 col-sm-12 col-xs-12"
                    >
                        <PieCu
                            data={pieChart.data}
                            title={pieChart.title}
                            svg={pieChart.svg}
                            id={pieChart.id}
                        />
                    </div>
                ))}
        </>
    );
};
export default WebInstallationsChart;
