// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--4-1!../../../../node_modules/sass-loader/dist/cjs.js?sourceMap!./sign.module.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".baseWrapper___22FNs{display:flex;margin:0;padding:0;position:absolute;right:0;left:0;top:0;height:100%;width:100%}.baseInnerWrapper___3htTI{display:flex;flex-direction:row;width:100%}.baseBlock___3CQ9s{display:flex;height:100%;background-color:#fff}.baseMiddlePageBlock___E2LPF{width:100%;flex-direction:column;padding:40px 10%}@media (min-width: 768px){.baseMiddlePageBlock___E2LPF{padding:40px 20%}}@media (min-width: 992px){.baseMiddlePageBlock___E2LPF{width:50%;flex:0 1 50%;justify-content:center;padding:40px 50px}}@media (min-width: 1200px){.baseMiddlePageBlock___E2LPF{padding:40px 100px 40px 200px}}@media (min-width: 1921px){.baseMiddlePageBlock___E2LPF{padding:40px 100px 40px 300px}}.baseBanner___2EURG{display:none;height:100%;background-color:#f4f7fb}@media (min-width: 992px){.baseBanner___2EURG{display:flex}}.baseBannerWrapper___2koFH{display:flex;justify-content:center;align-content:center;height:100%;width:100%;background-color:#f4f7fb}.baseBannerWrapper___2koFH img{display:block;width:80%;height:auto}@media (min-width: 1200px){.baseBannerWrapper___2koFH img{width:65%}}@media (min-width: 1921px){.baseBannerWrapper___2koFH img{width:55%}}.wrapper___Jjgp8{}.innerWrapper___3IZs8{}.block___BNNJU{background-color:#f4f7fb}.banner___3Z1x3{width:50%;flex:0 1 50%}.bannerWrapper___PAFK7{}\n", ""]);
// Exports
exports.locals = {
	"baseWrapper": "baseWrapper___22FNs",
	"baseInnerWrapper": "baseInnerWrapper___3htTI",
	"baseBlock": "baseBlock___3CQ9s",
	"baseMiddlePageBlock": "baseMiddlePageBlock___E2LPF",
	"baseBanner": "baseBanner___2EURG",
	"baseBannerWrapper": "baseBannerWrapper___2koFH",
	"wrapper": "wrapper___Jjgp8 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseWrapper"] + "",
	"innerWrapper": "innerWrapper___3IZs8 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseInnerWrapper"] + "",
	"block": "block___BNNJU " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseBlock"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseMiddlePageBlock"] + "",
	"banner": "banner___3Z1x3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseBanner"] + "",
	"bannerWrapper": "bannerWrapper___PAFK7 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["baseBannerWrapper"] + ""
};
module.exports = exports;
