import React from "react";
import {
    CircleLoader,
    FlatButton,
    withErrorCatcher,
} from "@/components/utilities";
import css from "authentication/styles/forgotpass.module.scss";
import ForgotPassBanner from "authentication/forgotpass/partials/ForgotPassBanner";
import logo from "images/svg/pushe-logo-horizontal.svg";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import {
    createValidator,
    isPassword,
    isRequired,
    repeatPassword,
} from "@/components/utils/validation";
import { useResetPass } from "authentication/forgotpass/hooks/useResetPass";
import ResetPassForm from "authentication/forgotpass/partials/ResetPassForm";
import ResetPassSuccess from "authentication/forgotpass/partials/ResetPassSuccess";
import { useGetResetPassToken } from "authentication/forgotpass/hooks/useGetResetPassToken";

export const ForgotPassPage = props => {
    const { handleSubmit } = props;

    const resetPassToken = useGetResetPassToken();
    const { submitResetPass, inProgress, showSuccessMessage, error } =
        useResetPass(handleSubmit, resetPassToken);

    return (
        <div className={css.wrapper}>
            <div className={css.innerWrapper}>
                <div className={css.banner}>
                    <ForgotPassBanner />
                </div>
                <div className={css.block}>
                    <div className={css.forgotPassLogo}>
                        <img src={logo} alt={"pushe logo"} />
                    </div>

                    {showSuccessMessage && <ResetPassSuccess />}

                    {!showSuccessMessage && (
                        <>
                            <ResetPassForm submitResetPass={submitResetPass} />

                            <div className={css.buttonsPart}>
                                <FlatButton
                                    fullWidth
                                    label={"تغییر رمز عبور"}
                                    onClick={submitResetPass}
                                    endIcon={
                                        inProgress ? <CircleLoader /> : null
                                    }
                                    disabled={inProgress}
                                />
                            </div>

                            <Link className={css.goToSignInPage} to={"/signin"}>
                                بازگشت به صفحه ورود
                            </Link>

                            {error && (
                                <div className={css.forgotPassError}>
                                    {error}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const validate = createValidator({
    password: [isRequired, isPassword],
    password_confirm: [isRequired, repeatPassword],
});

const rf = reduxForm({
    form: "forgot-password",
    validate,
    destroyOnUnmount: true,
})(ForgotPassPage);

export default withErrorCatcher(rf);
