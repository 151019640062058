import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    notifications: {},
    applications: {},
    application: {},
    favorite: {},
    transfer: {},
    appTopics: [],
    appVersion: [],
    browserList: [],
    deviceTypes: [],
    error: null,
    recipientCount: null,
    financialGateWay: {},
    installs: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.NOTIFICATION.FETCH_STATISTICS:
            return state;

        case actionTypes.NOTIFICATION.NOTIFICATION_STEP:
            return {
                ...state,
                notifications: { ...state.notification, ...action.payload },
            };
        case actionTypes.NOTIFICATION.SAVE_NOTIFICATION:
            return {
                ...state,
                notifications: {
                    ...(state.notifications || {}),
                    [action.payload.wrapper_id]: action.payload,
                },
            };

        case actionTypes.INSTALLATION.FAVE_INSTALLATION_IN_PROGRESS:
            return {
                ...state,
                favorite: action.payload,
            };
        case actionTypes.APPLICATION.SAVE_APPLICATION_Id_IN_PROGRESS:
            return {
                ...state,
                application: action.payload,
            };
        case actionTypes.APP.INPROG_DONE:
            return INITIAL_STATE;

        case actionTypes.APP.INPROG_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case actionTypes.NOTIFICATION.ESTIMATE_NOTIFICATION:
            return {
                ...state,
                recipientCount: action.payload,
            };
        case actionTypes.APPLICATION.GET_APPLICATION_VERSION:
            return {
                ...state,
                appVersion: action.payload,
            };
        case actionTypes.APPLICATION.GET_APPLICATION_BROWSER:
            return {
                ...state,
                browserList: action.payload,
            };
        case actionTypes.APPLICATION.GET_APPLICATION_DEVICE_TYPES:
            return {
                ...state,
                deviceTypes: action.payload,
            };
        case actionTypes.APPLICATION.GET_APPLICATION_TOPICS:
            return {
                ...state,
                appTopics: action.payload,
            };
        case actionTypes.APPLICATION.GET_APPLICATION_TAGS:
            return {
                ...state,
                appTags: action.payload,
            };
        case actionTypes.APPLICATION.SAVE_APPLICATION:
            return {
                ...state,
                applications: {
                    ...(state.applications || {}),
                    [action.payload.app_id]: action.payload,
                },
            };

        case actionTypes.INSTALLATION.RETRIEVE_INSTALL:
            return {
                ...state,
                installs: {
                    ...(state.installs || {}),
                    [action.app_id]: action.payload,
                },
            };
        case actionTypes.APPLICATION.GET_TAG_KEYS:
            return {
                ...state,
                tagKeys: action.payload,
            };
        case actionTypes.APPLICATION.GET_TAG_VALUES:
            return {
                ...state,
                tagValues: action.payload,
            };
    }
    return state;
}
