import React from "react";
import PropTypes from "prop-types";
import { CircleLoader } from "@/components/utilities";

/**
 * A component which can wrap content and show a loading screen while the content is being loaded
 *
 * Sample Usage:
 * ```
 * <PageLoader
 *      perform={(onSuccess, onFail) => loadData(onSuccess, onFail)}
 *      isReady={() => data !== null}
 * >
 *     {renderData()}
 * </PageLoader>
 * ```
 *
 * @param perform The function to perform if the loader is not ready (e.g., to fetch data from server). The function will
 *                be passed two callbacks (onSuccess and onFail) which should be called when loading succeeds or fails.
 * @param isReady A function which should return `true` if the data is ready and the content inside the loader should be rendered
 * @param history The react-router history. If this parameter is given and loading data fails, `history.goBack()` will be called
 *                on the object
 * @param children
 */
const PageLoader = ({ perform, isReady, children, history }) => {
    if (isReady) {
        return children;
    } else {
        perform(() => history && history.goBack());
        return (
            <div className="modal modal--notif modal--show">
                <CircleLoader size={80} thickness={8} color={"#fff"} />
            </div>
        );
    }
};

PageLoader.propTypes = {
    perform: PropTypes.func,
    isReady: PropTypes.bool.isRequired,
    history: PropTypes.object,
};

PageLoader.defaultProps = {
    initialize: () => {},
};

export default PageLoader;
