import actionTypes from "@/actions/_types";
import Util from "@/utils";

const INITIAL_STATE = {
    list: [],
    unread: 0,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.ALERTS.FETCH_ALERTS:
            return {
                list: action.payload,
                unread: Util._calculteUnReadAlerts(
                    action.payload,
                    "seen",
                    false,
                ),
            };
        case actionTypes.ALERTS.READ_ALERT: {
            const alert = state.list.map(alert => {
                if (alert.data.id === action.payload.data.id) {
                    return {
                        ...alert,
                        ...action.payload,
                    };
                }
                return alert;
            });
            return {
                list: alert,
                unread: Util._calculteUnReadAlerts(alert, "seen", false),
            };
        }
        case actionTypes.ALERTS.READ_ALL:
            return {
                list: state.list.map(alert => {
                    alert.seen = true;
                    return alert;
                }),
                unread: 0,
            };
    }
    return state;
}
