import React from "react";
import BaseWizard, {
    WIZARD_BTN_OPTIONS,
} from "@/components/utilities/commons/wizard/base/BaseWizard";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import { withErrorCatcher } from "@/components/utilities";
import { createInitialFormData } from "@/components/sms/automation/create/android/createInitialFormData";
import WebAutomationStepFinale from "@/components/sms/automation/create/web/steps/WebAutomationStepFinale";
import { useDispatch, useSelector } from "react-redux";
import { normalize } from "@/components/utils/normalize";
import { clearForm } from "@/actions/app";
import { AUTOMATION_WIZARD_MODE } from "@/components/notifications/automation/constants";
import { createAutomation, SMS_KEYS, updateAutomation } from "@/queries/sms";
import { AUTOMATION_FORM_NAME } from "@/components/sms/constants";
import { useMutation } from "react-query";
import AndroidSendTestModal from "@/components/sms/sms/create/android/partials/AndroidSendTestModal";
import { automationToApiNormalizer } from "@/components/sms/normalize";
import {
    onSuccessAppendToList,
    onSuccessUpdateItemInList,
} from "@/queries/constants";
import WebAutomationStep1 from "@/components/sms/automation/create/web/steps/WebAutomationStep1";
import WebAutomationStep2 from "@/components/sms/automation/create/web/steps/WebAutomationStep2";

const WebAutomationWizard = props => {
    const { history, wizardMode, automation, notification } = props;
    const platform = useSelector(state => state?.app?.platform);

    const dispatch = useDispatch();

    const [createMutation] = useMutation(createAutomation, {
        onSuccess: newData =>
            onSuccessAppendToList(SMS_KEYS.AUTOMATION_LIST(), newData),
    });
    const [updateMutation] = useMutation(updateAutomation, {
        onSuccess: newData =>
            onSuccessUpdateItemInList(
                SMS_KEYS.AUTOMATION_LIST(),
                "automation_id",
                newData,
            ),
    });
    const formNameAutomation = AUTOMATION_FORM_NAME[platform];
    return (
        <BaseWizard
            formName={formNameAutomation}
            title="ارسال پیامک اتوماتیک"
            subtitle="ساخت پیامک اتوماتیک"
            steps={ANDROID_AUTOMATION_STEPS}
            footer={WizardFooter}
            history={history}
            formInitialData={createInitialFormData(automation, notification)}
            stepFinale={WebAutomationStepFinale}
            preview={undefined}
            testModal={AndroidSendTestModal}
            submitAction={(data, onSuccess, onFailure) => {
                const normalizedData = normalize(
                    data,
                    automationToApiNormalizer,
                );

                if (wizardMode === AUTOMATION_WIZARD_MODE.CREATE) {
                    createMutation(normalizedData, {
                        onSuccess,
                        onError: onFailure,
                    }).then();
                } else if (wizardMode === AUTOMATION_WIZARD_MODE.EDIT) {
                    updateMutation(
                        {
                            automationId: normalizedData.automation_id,
                            data: normalizedData,
                        },
                        {
                            onSuccess,
                            onError: onFailure,
                        },
                    ).then();
                }
            }}
            onUnmountCallback={() => {
                clearForm(formNameAutomation)(dispatch);
            }}
            hasReduxForm={true}
            buttonOptions={{
                [WIZARD_BTN_OPTIONS.SUBMIT_BTN_NAME]: "ذخیره",
            }}
            passToSteps={{
                platform,
                wizardMode,
            }}
        />
    );
};

const ANDROID_AUTOMATION_STEPS = [
    { component: WebAutomationStep1, hideSubmitBtn: true },
    { component: WebAutomationStep2, showTestBtn: true },
];

export default withErrorCatcher(WebAutomationWizard);
