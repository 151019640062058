import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.dashboard.blue,
        color: "#ffffff",
        fontWeight: "bold",
        borderRadius: 0,
        padding: "6px 8px 4px 8px",
        fontSize: ".78rem",
    },
}));

const CPTextToClipboard = props => {
    const classes = useStyles();

    const [open, toggleOpen] = useState(false);
    const { text, children } = props;

    useEffect(() => {
        if (open) {
            setTimeout(() => toggleOpen(false), 1500);
        }
    }, [open]);

    const openTooltip = (_, result) => result && toggleOpen(true);

    return (
        <Tooltip
            title="کپی شد"
            placement="top"
            open={open}
            classes={{ tooltip: classes.tooltip }}
        >
            <CopyToClipboard text={text || children} onCopy={openTooltip}>
                <span>{children}</span>
            </CopyToClipboard>
        </Tooltip>
    );
};

export { CPTextToClipboard };
