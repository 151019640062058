import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { FileUploader, withErrorCatcher } from "@/components/utilities";
import { uploadNotificationCSVFile } from "@/actions/app";
import { Field, FormSection, getFormValues } from "redux-form";
import PageFilterSelect from "@/components/utils/finals/PageFilterSelect";
import { API_FILTER_ID_KEYS } from "@/components/notifications/notification/constants";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { errorToast } from "@/components/utils/Toasts";

const styles = {
    container: {
        padding: "15px 0",
    },
    placeholder: {
        justifyContent: "center",
        display: "flex",
        background: "#eee9",
        padding: "40px",
    },
};

const UploadWebFilterFile = props => {
    const { change } = props;
    const { form } = useReduxForm();
    const { csv_id = {} } = useSelector(state => getFormValues(form)(state));

    useEffect(() => {
        if (!csv_id?.id) change("csv_id.id", "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadFile = file => {
        const onSuccess = url => change("csv_id.id", url);

        const data = new FormData();
        data.append("items", file);
        data.append("type", csv_id.type);

        uploadNotificationCSVFile(data, { onSuccess })();
    };

    const options = [
        { label: "Device Id", value: API_FILTER_ID_KEYS.DEVICE_ID },
        { label: "Custom Id", value: API_FILTER_ID_KEYS.CUSTOM_ID },
        { label: "Phone Number", value: API_FILTER_ID_KEYS.PHONE_NUMBER },
        { label: "Email", value: API_FILTER_ID_KEYS.EMAIL },
    ];

    return (
        <div style={styles.container}>
            <h5 className="filterSelectField-title">آپلود فایل</h5>
            <p style={{ fontSize: ".9rem" }}>
                فرمت فایل باید csv یا txt باشد و در داخل فایل به جز آی‌دی‌ هیچ
                مقدار دیگری (هدر و ...) وجود نداشته باشد و هر آی‌دی در یک خط
                قرار گیرد.
            </p>

            <FormSection name="csv_id">
                <Field
                    name="type"
                    component={({ input: { onChange, value } }) => {
                        return (
                            <PageFilterSelect
                                placeholder="انتخاب نوع آی‌دی"
                                onSelect={onChange}
                                value={
                                    (options || []).find(
                                        op => op.value === value,
                                    ) || []
                                }
                                onClear={() => onChange("")}
                                isClearable={true}
                                options={options}
                            />
                        );
                    }}
                />
            </FormSection>

            {csv_id?.type && (
                <FileUploader
                    validateFile={file => {
                        if (
                            ["text/plain", "text/csv"].indexOf(file?.type) < 0
                        ) {
                            return errorToast(
                                "خطا در آپلود فایل",
                                "نوع فایل انتخاب شده اشتباه می‌باشد",
                            );
                        }
                        return true;
                    }}
                    onFileSelected={file => uploadFile(file)}
                    onFileDeleted={() => change("csv_id.id", "")}
                    hasInitFile={!!csv_id?.id}
                    inputAcceptFiles=".csv,.txt"
                />
            )}

            {!csv_id?.type && (
                <div style={styles.placeholder}>
                    برای آپلود فایل ابتدا نوع آی‌دی را مشخص کنید
                </div>
            )}
        </div>
    );
};

export default withErrorCatcher(UploadWebFilterFile);
