import { useState } from "react";
import { useDispatch } from "react-redux";
import { signInDeskPro } from "@/actions/auth";

export const useSignInDeskPro = handleSubmit => {
    const dispatch = useDispatch();
    const [inProgress, toggleInProgress] = useState(false);
    const [signInError, setSignInError] = useState("");

    const handleSignIn = ({ email, customer_email, password }) => {
        if (!email || !customer_email || !password) return;

        setSignInError(false);
        toggleInProgress(true);

        signInDeskPro(email, password, customer_email, {
            onSuccess: () => toggleInProgress(false),
            onFailure: error => {
                setSignInError(error);
                toggleInProgress(false);
            },
        })(dispatch);
    };

    return {
        inProgress,
        handleSignIn: handleSubmit(handleSignIn),
        signInError,
    };
};
