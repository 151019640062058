import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    iconButtonRoot: {
        ["@media (max-width: 1199px)"]: {
            padding: 0,
            margin: 0,
        },
    },
    root: {
        "&:hover": {
            color: theme.palette.dashboard.blue,
        },
        fontSize: ".87rem",
        minHeight: "28px",
        ["@media (min-width: 1200px)"]: {
            fontSize: "1rem",
            minHeight: "48px",
        },
    },
    iconRoot: {
        color: "inherit",
        minWidth: "30px",
    },
}));

const MoreVertMenu = props => {
    const { options } = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                classes={{ root: classes.iconButtonRoot }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { width: "230" },
                }}
            >
                {options.map((option, idx) => (
                    <MenuItem
                        disabled={option.disabled}
                        key={idx}
                        onClick={() => {
                            option.onClick();
                            handleClose();
                        }}
                        classes={{
                            root: classes.root,
                        }}
                    >
                        {option.icon && (
                            <ListItemIcon classes={{ root: classes.iconRoot }}>
                                <option.icon fontSize="small" />
                            </ListItemIcon>
                        )}
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export { MoreVertMenu };
