import { useState } from "react";
import { resetPassword } from "@/actions/auth";

export const useResetPass = (handleSubmit, resetPassToken) => {
    const [error, setError] = useState("");
    const [inProgress, toggleInProgress] = useState(false);
    const [showSuccessMessage, toggleShowSuccessMessage] = useState(false);

    const submitResetPass = ({ password }) => {
        if (!password || !resetPassToken) return;

        toggleInProgress(true);
        setError("");

        resetPassword(password, resetPassToken, {
            onFailure: error => {
                setError(error);
                toggleInProgress(false);
            },
            onSuccess: () => {
                toggleInProgress(false);
                toggleShowSuccessMessage(true);
            },
        })();
    };

    return {
        error,
        inProgress,
        submitResetPass: handleSubmit(submitResetPass),
        showSuccessMessage,
    };
};
