import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { retrieveApplication } from "@/actions/applications";
import PageLoader from "@/components/utils/PageLoader";
import { retrieveInstall } from "@/actions/installations";
import { PLATFORMS } from "@/constants";
import AndroidGuideWizard from "application/guides/wizards/AndroidGuideWizard";
import IosGuideWizard from "application/guides/wizards/IosGuideWizard";
import WebGuideWizard from "application/guides/wizards/WebGuideWizard";

const GuidePage = props => {
    const {
        match: { params },
        history,
    } = props;
    const dispatch = useDispatch();

    const { appId, platform = "android" } = params || {};
    const { applications: storedApplications, installs } = useSelector(
        state => state?.inprog ?? {},
    );
    const applications = useSelector(
        state => state?.[platform]?.applications ?? [],
    );

    const application = storedApplications[appId] || undefined;
    const Component = platform ? GUIDE_PAGES[platform] : undefined;

    return (
        <PageLoader
            history={history}
            perform={onFail =>
                retrieveApplication(
                    { appId, platform, showErrorToast: true },
                    null,
                    onFail,
                )(dispatch)
            }
            isReady={!!application}
        >
            {Component && (
                <Component
                    history={history}
                    application={application}
                    applications={applications}
                    installs={installs}
                    retrieveInstall={(...params) =>
                        retrieveInstall(
                            { ...params[0], platform },
                            ...params.splice(1),
                        )(dispatch)
                    }
                />
            )}
        </PageLoader>
    );
};

const GUIDE_PAGES = {
    [PLATFORMS.ANDROID]: AndroidGuideWizard,
    [PLATFORMS.WEB]: WebGuideWizard,
    [PLATFORMS.IOS]: IosGuideWizard,
};

export default withErrorCatcher(withRouter(GuidePage));
