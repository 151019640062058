import React, { useState } from "react";
import { List, makeSelectable } from "material-ui/List";

const SidebarWrapState = ComposedComponent =>
    function SidebarWrapper({ defaultValue, children }) {
        const [selectedIndex, updateSelectedIndex] = useState(defaultValue);

        return (
            <ComposedComponent
                value={selectedIndex}
                onChange={(_evt, index) => {
                    if (index !== "no-select") {
                        updateSelectedIndex(index);
                    }
                }}
                selectedItemStyle={{
                    backgroundColor: "#ecf9ff",
                }}
            >
                {children}
            </ComposedComponent>
        );
    };

export default SidebarWrapState(makeSelectable(List));
