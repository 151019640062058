export const CREDENTIAL_STATUS = {
    0: "درحال بررسی",
    1: "سالم",
    2: "خراب",
};

export const CONFIG_SMS_TYPE = {
    KAVE_NEGAR: 1,
    NIK_SMS: 2,
};
export const PROFILE_TABS = {
    INFORMATION: {
        KEY: "1",
        VALUE: "",
        LABEL: "اطلاعات‌کاربری",
    },
    API: {
        KEY: "2",
        VALUE: "api",
        LABEL: "وب‌سرویس - api",
    },
    CREDENTIALS: {
        KEY: "3",
        VALUE: "credentials",
        LABEL: "مدیریت شناسه‌ها",
    },
    SECURITY: {
        KEY: "4",
        VALUE: "security",
        LABEL: "امنیت",
    },
    CONFIG: {
        KEY: "5",
        VALUE: "services",
        LABEL: "پیامک و ایمیل",
    },
};
