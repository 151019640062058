import React from "react";
const HighCharts = require("react-highcharts");
import BaseChart from "./_base";
import Colors from "@/components/utils/lib/colors";
import SelectAppPlaceholder from "@/components/utils/charts/SelectAppPlaceholder";
import { CircleLoader } from "@/components/utilities";

export default class PieChartBase extends BaseChart {
    constructor(props) {
        super(props);
    }

    getPlotOptions() {
        return {
            pie: {
                allowPointSelect: true,
                credits: {
                    enabled: false,
                },
                cursor: "pointer",
                borderWidth: 1,
                showInLegend: true,
                center: ["50%", "50%"],
                style: {
                    fontFamily: "iranyekanwebbold",
                },
                // dataLabels: {
                //     enabled: true,
                //     style: {
                //         color: 'black',
                //         fontFamily: 'iranyekanwebbold'
                //     },
                //     startAngle: -90,
                //     endAngle: 90,
                //     center: ['50%', '75%'],
                // },
                tooltip: {
                    headerFormat:
                        '<span style="font-family:iranyekanwebbold">{point.key}</span><br />',
                    pointFormat:
                        '<span style="font-family:iranyekanwebbold">% {point.y}</span>',
                },
            },
        };
    }

    getDataSeries() {
        const data = this.props.data || {};
        const total = data["#all"] || 1;

        return Object.entries(data)
            .filter(value => value[0] !== "#all")
            .filter(i => !(i[0] === "#other" && i[1] === 0))
            .map(value => {
                return {
                    name: value[0] === "#other" ? "سایر" : value[0],
                    y: parseFloat(((value[1] / total) * 100).toFixed(2)),
                };
            });
    }

    getSeries() {
        return [
            {
                name: this.props.title || "",
                type: "pie",
                innerSize: "65%",
                colorByPoint: true,
                data: this.getDataSeries(),
                style: {
                    fontFamily: "iranyekanwebbold",
                },
                dataLabels: {
                    enabled: false,
                },
            },
        ];
    }

    getLegend() {
        return {
            align: "center",
            // verticalAlign: 'middle',
            layout: "horizontal",
            rtl: true,
            borderWidth: 0,
            margin: 0,
            itemStyle: {
                color: Colors.gray_8,
                transition: "all 0.2s",
                fontFamily: "iranyekanwebbold",
            },
            itemHoverStyle: {
                color: Colors.gray_7,
            },
            itemMarginBottom: 5,
            style: {
                fontFamily: "iranyekanwebbold",
            },
        };
    }

    render() {
        const pieChartOptions = {
            chart: {
                type: "pie",
            },
            credits: {
                enabled: false,
            },
            colors: this.getChartColors(),
            title: "",
            tooltip: this.getToolTipOptions(),
            plotOptions: this.getPlotOptions(),
            series: this.getSeries(),
            legend: this.getLegend(),
        };

        const { showPlaceholderOnAll, svg, description, appId, title } =
            this.props;
        return (
            <div className="card-g">
                <header className="card-g__header">
                    <p className="card-g__title">
                        <svg>
                            <use xlinkHref={svg}></use>
                        </svg>
                        {title || "Set Title"}
                    </p>
                </header>
                <div className="card-g__body">
                    <p>{description}</p>
                    {this.getDataSeries().length > 0 ? (
                        appId === "all" && showPlaceholderOnAll ? (
                            <SelectAppPlaceholder />
                        ) : (
                            <HighCharts config={pieChartOptions} />
                        )
                    ) : (
                        <CircleLoader />
                    )}
                </div>
            </div>
        );
    }
}
