import React from "react";
import {
    Card,
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardListWrapper,
    DateTimeComponent,
    DisplayAppComponent,
    DisplayStatistics,
    NotificationDeleteModalAction,
    NotificationResendModalAction,
    StatisticsComponent,
    StatusComponent,
} from "@/components/utilities/modules/CardList";
import { createStatusObj } from "@/components/notifications/notification/list/common/createCardStatus";
import { tabs } from "@/components/notifications/notification/list/ios/tabs/tabs";
import NotificationStatistics from "@/components/notifications/notification/list/common/notification-cards/NotificationStatistics";
import { Notification } from "@/components/utils/lib/svgicons";
import { useHandleNotificationList } from "@/components/notifications/notification/list/common/useHandleNotificationList";
import NotificationFixedHeader from "@/components/notifications/notification/list/common/NotificationFixedHeader";

const IosNotifList = props => {
    const { history } = props;

    const platform = "ios";

    const {
        hasMore,
        isLoading,
        refresh,
        notificationList,
        retrieveNotificationList,
    } = useHandleNotificationList(platform);

    return (
        <>
            <NotificationFixedHeader
                platform={platform}
                history={history}
                onRefresh={refresh}
                isLoading={isLoading}
            />

            <CardListWrapper
                hasMore={hasMore}
                isLoading={isLoading}
                onEnter={retrieveNotificationList}
            >
                {notificationList.map(n => {
                    const statusList = createStatusObj(n);

                    return (
                        <Card key={n?.["wrapper_id"]}>
                            <CardHeader
                                StatusComponent={StatusComponent}
                                statusProps={{
                                    statusList,
                                }}
                                DateTimeComponent={DateTimeComponent}
                                dateTimeProps={{
                                    dateTime: n?.["created_at"],
                                }}
                            />
                            <CardBody
                                tabs={tabs}
                                tabProps={{
                                    notification: n,
                                }}
                                fixedComponents={[DisplayAppComponent]}
                                fixedProps={{
                                    appIds: n?.["app_ids"],
                                    platform: platform,
                                }}
                            />
                            <CardFooter>
                                <StatisticsComponent
                                    statistics={n?.statistics}
                                    meta={[
                                        {
                                            key: "accurate_recipients",
                                            icon: "svg_user_2",
                                            name: "تعداد مخاطبین",
                                        },
                                        {
                                            key: "delivered",
                                            icon: "svg_eye",
                                            name: "تعداد دریافت‌ها",
                                        },
                                        {
                                            key: "clicked",
                                            icon: "svg_click",
                                            name: "تعداد کلیک‌ها",
                                        },
                                    ]}
                                />
                                <div className="notif-action-buttom-wrapper">
                                    <DisplayStatistics
                                        statisticsComponent={
                                            NotificationStatistics
                                        }
                                        statisticsComponentProps={{
                                            wrapperId: n?.["wrapper_id"],
                                        }}
                                    />
                                    <CardActions
                                        actions={[
                                            {
                                                id: "1",
                                                type: "button",
                                                tip: "ارسال دوباره",
                                                // eslint-disable-next-line react/display-name
                                                iconComponent: () => (
                                                    <Notification viewBox="0 0 21 21" />
                                                ),
                                                modalAction:
                                                    NotificationResendModalAction,
                                                modalActionProps: {
                                                    title: "ارسال دوباره نوتیفیکیشن",
                                                    body: (
                                                        <div>
                                                            آیا از ارسال دوباره
                                                            نوتیفیکیشن
                                                            {n?.["wrapper_id"]}
                                                            مطمئن هستید؟ ‍‍‍
                                                        </div>
                                                    ),
                                                    wrapperId:
                                                        n?.["wrapper_id"],
                                                },
                                            },
                                            {
                                                id: "2",
                                                type: "link",
                                                tip: "فوروارد",
                                                iconName: "svg_forward",
                                                url: `/notification/${platform}/forward/${n?.["wrapper_id"]}`,
                                            },
                                            {
                                                id: "3",
                                                type: "button",
                                                tip: "حذف",
                                                iconName: "svg_trash",
                                                modalAction:
                                                    NotificationDeleteModalAction,
                                                modalActionProps: {
                                                    title: "حذف نوتیفیکیشن",
                                                    body: (
                                                        <div>
                                                            آیا از پاک شدن
                                                            نوتیفیکیشن &nbsp;
                                                            {n?.["wrapper_id"]}
                                                            &nbsp; مطمئن هستید؟
                                                            ‍‍‍
                                                        </div>
                                                    ),
                                                    wrapperId:
                                                        n?.["wrapper_id"],
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </CardFooter>
                        </Card>
                    );
                })}
            </CardListWrapper>
        </>
    );
};

export default IosNotifList;
