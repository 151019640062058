import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withErrorCatcher } from "@/components/utilities";
import AndroidApplicationWizard from "application/create/android/AndroidApplicationWizard";
import WebApplicationWizard from "application/create/web/WebApplicationWizard";
import { submitForm } from "@/actions/app";
import IosApplicationWizard from "application/create/ios/IosApplicationWizard";
import PropTypes from "prop-types";

const ApplicationPage = ({ match: { params }, _location, ...otherProps }) => {
    const platform = params.platform;
    const Component = APPLICATION_PLATFORM_FORM[platform] || undefined;

    return <Component {...otherProps} platform={platform} />;
};

ApplicationPage.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

ApplicationPage.defaultProps = {};

const APPLICATION_PLATFORM_FORM = {
    android: AndroidApplicationWizard,
    web: WebApplicationWizard,
    ios: IosApplicationWizard,
};

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ submitForm }, dispatch);
};

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(ApplicationPage),
);
