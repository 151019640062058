/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    DateTimePicker,
    RadioButtons,
    usePrevious,
} from "@/components/utilities";
import classNames from "classnames";
import "styles/modules/_mod-card-timing.scss";
import { isEqual } from "lodash";
import { NOTIF_TIMING_MODE } from "@/components/notifications/notification/constants";
import { Field } from "redux-form";

export const NotificationTiming = ({ eta, change, label }) => {
    const [mode, setMode] = useState(
        eta ? NOTIF_TIMING_MODE.ETA.value : NOTIF_TIMING_MODE.ASAP.value,
    );
    const prevMode = usePrevious(mode);

    useEffect(() => {
        if (mode === NOTIF_TIMING_MODE.ASAP.value) change("eta", null);
    }, [!isEqual(prevMode, mode)]);

    return (
        <>
            <div className="notif-block__body notif-timing">
                <div className="notif-timing-type">
                    <RadioButtons
                        label={label || "زمان ارسال اعلان"}
                        defaultValue={mode}
                        options={[
                            {
                                label: NOTIF_TIMING_MODE.ASAP.label,
                                value: NOTIF_TIMING_MODE.ASAP.value,
                            },
                            {
                                label: NOTIF_TIMING_MODE.ETA.label,
                                value: NOTIF_TIMING_MODE.ETA.value,
                            },
                        ]}
                        onChange={setMode}
                    />
                </div>

                <div className="datepicker-wrapper">
                    <div
                        className={classNames({
                            "field-disable":
                                mode === NOTIF_TIMING_MODE.ASAP.value,
                        })}
                    >
                        {mode === NOTIF_TIMING_MODE.ETA.value && (
                            <Field
                                name="eta"
                                component={({
                                    input: { onChange, value },
                                    meta: { error },
                                }) => {
                                    return (
                                        <DateTimePicker
                                            onChange={onChange}
                                            dateProps={{
                                                minData: new Date(),
                                            }}
                                            value={value}
                                            error={error}
                                        />
                                    );
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotificationTiming;
