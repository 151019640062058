import React, { useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { MODAL_THEME } from "@/actions/_types";
import BaseModal from "@/components/utilities/modal/baseModal";
import { sendDraft } from "@/actions/notifications";
import { useDispatch } from "react-redux";

const SendDraftModalAction = props => {
    const dispatch = useDispatch();
    const { title, desc, body, onClose, wrapperId, app_ids } = props;

    const [error, setError] = useState("");
    const [isLoading, toggleIsLoading] = useState(false);

    const onAcceptCallback = () => {
        const onSuccess = () => {
            toggleIsLoading(false);
            onClose();
        };
        const onFailure = error => {
            toggleIsLoading(false);
            setError(error);
        };

        toggleIsLoading(true);

        if (error) setError("");

        sendDraft(
            { app_ids, wrapper_id: wrapperId },
            onSuccess,
            onFailure,
        )(dispatch);
    };

    return (
        <BaseModal
            theme={MODAL_THEME.BLUE}
            title={title}
            desc={desc}
            error={error}
            useDefaultFooter={true}
            body={body}
            onClose={onClose}
            isLoading={isLoading}
            onAccept={onAcceptCallback}
        />
    );
};

export default withErrorCatcher(SendDraftModalAction);
