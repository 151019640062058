import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    installList: [],
    hasMore: true,
    limit: 20,
    favInstallationList: [],
    hasMoreFave: true,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.INSTALLATION.FETCH_IOS_INSTALLATIONS:
            if (action.payload.initial)
                return {
                    installList: action.payload.results,
                    hasMore: action.payload.results.length >= state.limit,
                    limit: state.limit,
                    favInstallationList: state.favInstallationList,
                    hasMoreFave: state.hasMoreFave,
                };
            return {
                installList: [
                    ...state.installList,
                    ...action.payload.results.filter(val => {
                        if (state.installList.length)
                            for (
                                let idx = 0;
                                idx < state.installList.length;
                                idx++
                            )
                                if (state.installList[idx].id === val.id)
                                    return false;
                        return true;
                    }),
                ],
                hasMore: action.payload.results.length >= state.limit,
                limit: state.limit,
                favInstallationList: state.favInstallationList,
                hasMoreFave: state.hasMoreFave,
            };
        case actionTypes.INSTALLATION.SEARCH_IOS_INSTALLATIONS:
            return {
                installList: action.payload.results,
                hasMore: action.payload.results.length >= state.limit,
                limit: state.limit,
                favInstallationList: state.favInstallationList,
                hasMoreFave: state.hasMoreFave,
            };
        case actionTypes.INSTALLATION.FETCH_IOS_FAVORITES_INSTALLATIONS:
            if (action.payload.initial)
                return {
                    favInstallationList: action.payload.results,
                    hasMoreFave: action.payload.results.length >= state.limit,
                    limit: state.limit,
                    hasMore: state.hasMore,
                    installList: state.installList,
                };
            return {
                favInstallationList: [
                    ...state.favInstallationList,
                    ...action.payload.results.filter(val => {
                        if (state.installList.length)
                            for (
                                let idx = 0;
                                idx < state.favInstallationList.length;
                                idx++
                            )
                                if (
                                    state.favInstallationList[idx].id === val.id
                                )
                                    return false;
                        return true;
                    }),
                ],
                hasMoreFave: action.payload.results.length >= state.limit,
                limit: state.limit,
                installList: state.installList,
                hasMore: state.hasMore,
            };
    }
    return state;
}
