import { getApi } from "@/api";
import actionTypes from "@/actions/_types";

export function fetchAlerts() {
    return function (dispatch) {
        return getApi()
            .get(`alerts/`)
            .then(resp => {
                dispatch({
                    payload: resp.data,
                    type: actionTypes.ALERTS[`FETCH_ALERTS`],
                });
            })
            .catch(() => {});
    };
}

export function readAlert(id, params = {}) {
    return function (dispatch) {
        return getApi()
            .put(`alerts/${id}/`, params)
            .then(resp => {
                dispatch({
                    payload: resp.data,
                    type: actionTypes.ALERTS[`READ_ALERT`],
                });
            })
            .catch(() => {});
    };
}

export function readALl(params = {}) {
    return function (dispatch) {
        return getApi()
            .post(`alerts/seen_all/`, params)
            .then(resp => {
                dispatch({
                    payload: resp.data.results,
                    type: actionTypes.ALERTS[`READ_ALL`],
                });
            })
            .catch(() => {});
    };
}
