import { NOTIFICATION_TYPE } from "@/components/notifications/notification/constants";

export const NOTIFICATION_TYPES = [
    {
        title: "نمایش نوتیفیکیشن",
        icon: "svg_notification",
        name: NOTIFICATION_TYPE.NOTIF,
    },
    {
        title: "ارسال اطلاعات JSON",
        icon: "svg_json_data",
        name: NOTIFICATION_TYPE.CUSTOM_CONTENT,
    },
    {
        title: "نمایش قالب HTML",
        icon: "svg_html_template",
        name: NOTIFICATION_TYPE.HTML,
        enabled: false,
    },
];
export const FULL_CONTENT_TYPE_MODE = {
    IMAGE: "image",
    CONTENT: "content",
};
