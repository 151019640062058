/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SegmentFilterSelectionField from "@/components/users/segmentation/components/fields/SegmentFilterSelectionField";
import { getApplicationTagValues } from "@/actions/applications";
import { getFormValues } from "redux-form";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { withErrorCatcher } from "@/components/utilities";
import { store } from "@/store";

const SegmentTagValuesSelection = props => {
    const { fieldValues } = props;

    let key = fieldValues?.vKey ?? null;

    const dispatch = useDispatch();
    const { form } = useReduxForm();

    const tagValues = useSelector(state => state?.inprog?.tagValues ?? []);
    const appId = useSelector(state => getFormValues(form)(state)?.app_id);

    useEffect(() => {
        if (appId && key) {
            getApplicationTagValues({ app_id: appId, tag_key: key })(
                dispatch,
                store.getState,
            );
        }
    }, [appId, key]);

    return (
        <SegmentFilterSelectionField
            {...props}
            list={
                !appId || !key
                    ? []
                    : tagValues.map(item => ({ label: item, value: item }))
            }
        />
    );
};

export default withErrorCatcher(SegmentTagValuesSelection);
