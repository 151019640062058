import { getApi, getCommonApi, getWebApi } from "@/api";
import {
    errorHandlerInprog,
    generateGetParams,
    isValidPlatform,
} from "@/actions/_utils.jsx";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import { errorToast, successToast } from "@/components/utils/Toasts";
import actionTypes from "@/actions/_types";
import { processErrorResponse, toggleLoading } from "@/actions/_utils";
import to from "await-to-js";

const getUrl = (platform, appId) => {
    if (platform === "web" && !appId) {
        return `applications/`;
    } else {
        return `applications/${platform}/`;
    }
};

export const fetchApplications =
    ({ platform } = {}, onSuccess, onFailure) =>
    async dispatch => {
        if (isValidPlatform(platform, dispatch)) {
            try {
                toggleLoading(actionTypes.LOADING.APPLICATION, true, dispatch);

                const resp = await getApi(platform).getApplications();
                let payload = resp.data;
                if (!Object.keys(payload).length) payload = [];

                dispatch({
                    payload,
                    type: actionTypes.APPLICATION[
                        `FETCH_${platform.toUpperCase()}_APPLICATIONS`
                    ],
                });

                toggleLoading(actionTypes.LOADING.APPLICATION, false, dispatch);

                if (onSuccess) onSuccess();
            } catch (e) {
                if (onFailure) onFailure(e);
            }
        }
    };

export const createApplication =
    ({ data, platform }, onSuccess, onFailure) =>
    async dispatch => {
        if (isValidPlatform(platform, dispatch)) {
            try {
                toggleLoading(
                    actionTypes.LOADING.CREATE_APPLICATION,
                    true,
                    dispatch,
                );
                const resp = await getApi(platform).createApplication(data);
                dispatch({
                    payload: resp.data,
                    type: actionTypes.APPLICATION[
                        `CREATE_${platform.toUpperCase()}_APPLICATION`
                    ],
                });

                toggleLoading(
                    actionTypes.LOADING.CREATE_APPLICATION,
                    false,
                    dispatch,
                );
                if (onSuccess) onSuccess(resp.data);

                successToast(
                    TOAST_CONTENT.CREATE_APPLICATION_SUCCESS.TITLE,
                    TOAST_CONTENT.CREATE_APPLICATION_SUCCESS.DESC,
                );
            } catch (e) {
                toggleLoading(
                    actionTypes.LOADING.CREATE_APPLICATION,
                    false,
                    dispatch,
                );
                if (onFailure) onFailure(e.response);
            }
        }
    };

export function exportCSV({ app_id }, onSuccess, onFailure) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        if (isValidPlatform(platform, dispatch)) {
            let url = getUrl(platform);
            return getApi(platform)
                .post(`${url}${app_id}/export/`)
                .then(() => {
                    dispatch({
                        type: actionTypes.APP.CLOSE_MODAL,
                        payload: null,
                    });
                    successToast(
                        TOAST_CONTENT.EXPORT_CV_SUCCESS.TITLE,
                        TOAST_CONTENT.EXPORT_CV_SUCCESS.DESC,
                    );
                    if (onSuccess) onSuccess();
                })
                .catch(resp => {
                    if (onFailure) onFailure(processErrorResponse(resp));

                    // dispatch({
                    //     type: actionTypes.APP.CLOSE_MODAL,
                    //     payload: null
                    // });
                    // errorHandlerInprog(resp, dispatch)
                });
        }
    };
}

export function updateApplication(
    { app_id, platform, ...data },
    onSuccess,
    onFailure,
) {
    return dispatch => {
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .updateApplication({ app_id, data })
                .then(() => {
                    dispatch({
                        payload: { app_id, ...data },
                        type: actionTypes.APPLICATION[
                            `UPDATE_${platform.toUpperCase()}_APPLICATION`
                        ],
                    });
                    if (onSuccess) onSuccess();
                    dispatch({
                        payload: null,
                        type: actionTypes.APP.CLOSE_MODAL,
                    });
                })
                .catch(resp => {
                    if (onFailure) onFailure(processErrorResponse(resp));
                    errorHandlerInprog(resp, dispatch);
                });
        }
    };
}

export function deleteApplication({ app_id, platform }, onSuccess, onFailure) {
    return dispatch => {
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .destroyApplication(app_id)
                .then(() => {
                    dispatch({
                        payload: app_id,
                        type: actionTypes.APPLICATION[
                            `DELETE_${platform.toUpperCase()}_APPLICATION`
                        ],
                    });

                    if (onSuccess) onSuccess();
                    successToast(
                        TOAST_CONTENT.DELETE_APPLICATION_SUCCESS.TITLE,
                        TOAST_CONTENT.DELETE_APPLICATION_SUCCESS.DESC,
                    );

                    dispatch({
                        payload: null,
                        type: actionTypes.APP.CLOSE_MODAL,
                    });
                })
                .catch(resp => {
                    if (onFailure) onFailure(processErrorResponse(resp));
                    dispatch({
                        payload: resp.response.data["detail"],
                        type: actionTypes.APP.INPROG_ERROR,
                    });
                });
        }
    };
}

export function getApplicationVersion(params) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        const { app_id } = params;
        let url = `applications/${platform}/${app_id}/app_versions/`;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.APPLICATION.GET_APPLICATION_VERSION,
                    });
                })
                .catch(() => {});
        }
    };
}
export function getApplicationِِِDeviceType(params) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        const { app_id } = params;
        let url = `applications/${platform}/${app_id}/device_types/`;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.APPLICATION
                            .GET_APPLICATION_DEVICE_TYPES,
                    });
                })
                .catch(() => {});
        }
    };
}
export function getApplicationِِBrowserList(params) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        const { app_id } = params;
        let url = `applications/${platform}/${app_id}/browsers/`;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.APPLICATION.GET_APPLICATION_BROWSER,
                    });
                })
                .catch(() => {});
        }
    };
}

export function getApplicationTopics(params) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        const { app_id } = params;
        let url = `applications/${platform}/${app_id}/topics/`;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.APPLICATION.GET_APPLICATION_TOPICS,
                    });
                })
                .catch(() => {});
        }
    };
}

export function getApplicationTags(params) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        const { app_id } = params;
        let url = `applications/${platform}/${app_id}/tags/`;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.APPLICATION.GET_APPLICATION_TAGS,
                    });
                })
                .catch(() => {});
        }
    };
}
export const submitHMSCredential = async (app_id, HMS_data, onSuccess) => {
    const [, response] = await to(
        getCommonApi().createHMSCredential(app_id, { ...HMS_data }),
    );
    if (response && typeof onSuccess === "function") {
        onSuccess({ platform: "android" });
        return response;
    }
};
export function getApplicationTagsKeys(params, extra) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        const { app_id } = params;
        let url = `tags/${platform}/${app_id}/keys/`;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.APPLICATION.GET_TAG_KEYS,
                    });
                })
                .catch(() => {
                    if (extra?.onFailure) extra.onFailure();
                });
        }
    };
}

export function getApplicationTagValues(params) {
    return (dispatch, getState) => {
        const platform = getState().app.platform;
        const { app_id, tag_key } = params;
        let url = `tags/${platform}/${app_id}/values/${generateGetParams({
            tag: [tag_key],
        })}`;
        if (isValidPlatform(platform, dispatch)) {
            return getApi(platform)
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data.results,
                        type: actionTypes.APPLICATION.GET_TAG_VALUES,
                    });
                })
                .catch(() => {});
        }
    };
}

export const retrieveApplication =
    ({ appId, platform, showErrorToast }, onSuccess, onFail) =>
    async dispatch => {
        try {
            const response = await getApi(platform).getApplication({ appId });
            let payload =
                Array.isArray(response.data) && response.data[0]
                    ? response.data[0]
                    : response.data;

            dispatch({
                type: actionTypes.APPLICATION.SAVE_APPLICATION,
                payload,
            });

            if (onSuccess) onSuccess();
        } catch (e) {
            if (showErrorToast) {
                errorToast(
                    TOAST_CONTENT.APPLICATION_FETCH_ERROR.TITLE,
                    TOAST_CONTENT.APPLICATION_FETCH_ERROR.DESC,
                );
            }
            if (onFail) onFail();
        }
    };

export const saveWebSettings = (appId, data, options) => async dispatch => {
    const [error, response] = await to(
        getWebApi().saveWebSettings(appId, data),
    );

    if (error) {
        if (typeof options?.onFailure === "function") options.onFailure();
        errorToast(
            "خطا در ذخیره تنظیمات",
            processErrorResponse(error.response?.data),
        );
        return;
    }

    dispatch({
        type: actionTypes.APPLICATION.SAVE_APPLICATION,
        payload: response.data,
    });

    if (typeof options?.onSuccess === "function") options.onSuccess();
};
