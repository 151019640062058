/* eslint-disable no-unused-vars */
import { push } from "react-router-redux";
import { errorToast, successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import { getApi, getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";
import myStorage from "@/myStorage";
import * as Sentry from "@sentry/browser";
import { toggleLoading } from "@/actions/_utils";
import ReactGA from "react-ga";
import to from "await-to-js";
import { processError } from "@/components/utilities";
import { store } from "@/store";
import { useDispatch } from "react-redux";
import { PREFERRED_PLATFORM } from "@/constants";

export const googleSignIn = async () => {
    await getCommonApi().googleSignIn();
};

export const signInDeskPro =
    (email, password, customer_email, options) => async dispatch => {
        const data = { email, password, customer_email };

        const [error, response] = await to(
            getCommonApi({ isPublic: true }).signInDeskPro(data),
        );

        if (error) {
            if (typeof options?.onFailure === "function")
                options.onFailure(processError(error?.response?.data));
            return;
        }

        myStorage.setItem("token", response.data.token);

        dispatch({
            type: actionTypes.AUTH.SIGN_IN_DESKPRO,
            payload: response.data,
        });

        dispatch(push(`/dashboard/${PREFERRED_PLATFORM}`));

        if (typeof options?.onSuccess === "function") options.onSuccess();
    };
// "recaptcha" add in parameter in function signIn:
export const signIn =
    (email, password, options = {}) =>
    async dispatch => {
        //const [error, response] = await to(getCommonApi({isPublic: true}).signIn({email, password ,recaptcha}));
        const [error, response] = await to(
            getCommonApi({ isPublic: true }).signIn({ email, password }),
        );

        if (error) {
            if (typeof options?.onFailure === "function")
                options.onFailure(processError(error?.response?.data));
            return;
        }

        if (response?.data?.["two_step_auth"]) {
            if (typeof options?.onSuccess === "function")
                options.onSuccess(response.data);
            return;
        }

        myStorage.setItem("token", response.data.token);

        /*
         ** If builtIn localStorage is not available
         * for the user send a custom event to Sentry
         */
        if (!myStorage.supportsLocalStorage) {
            Sentry.captureEvent({
                message:
                    "window.localStorage is not available and using custom localStorage",
                level: Sentry.Severity.Info,
                user: {
                    email: email,
                },
            });
        }

        dispatch({
            type: actionTypes.AUTH.SIGN_IN,
            payload: response.data,
        });

        ReactGA.set({ email });

        dispatch(
            push(
                options?.["redirectPath"] ?? `/dashboard/${PREFERRED_PLATFORM}`,
            ),
        );
    };

export const signOut =
    (options = {}) =>
    async dispatch => {
        let { destPage = null } = options;

        myStorage.removeItem("token");

        if (!destPage) destPage = "/signin";

        dispatch(push(destPage));
        dispatch({ type: actionTypes.AUTH.SIGN_OUT });

        if (typeof options?.onFinish === "function") options.onFinish();
    };

export const forgotPassword =
    (email, options = {}) =>
    async () => {
        const [error] = await to(
            getCommonApi({ publicApi: true }).forgotPassword({ email }),
        );

        if (error) {
            if (typeof options?.onFailure === "function")
                options.onFailure(processError(error?.response?.data));
            return;
        }

        // eslint-disable-next-line no-constant-condition
        if (typeof options?.onSuccess) options.onSuccess();
    };

export const resetPassword =
    (password, token, options = {}) =>
    async () => {
        const [error] = await to(
            getCommonApi({ publicApi: true }).resetPassword(token, {
                password,
            }),
        );

        if (error) {
            if (typeof options?.onFailure === "function")
                options.onFailure(processError(error?.response?.data));
            return;
        }

        // eslint-disable-next-line no-constant-condition
        if (typeof options?.onSuccess) options.onSuccess();
    };

export function fetchProfile(onSuccess, onFailure) {
    return function (dispatch) {
        getApi()
            .get(`auth/profile/`)
            .then(resp => {
                dispatch({
                    payload: { ...resp.data },
                    type: actionTypes.AUTH.FETCH_PROFILE,
                });
                if (onSuccess) onSuccess();
            })
            .catch(() => {
                if (onFailure) onFailure();
            });
    };
}

export const setToken = token => async dispatch => {
    myStorage.setItem("token", token);

    dispatch({
        payload: { token },
        type: actionTypes.AUTH.SIGN_IN,
    });
    dispatch(push(`/dashboard/${PREFERRED_PLATFORM}`));
};

export const clearToken = () => async dispatch => {
    myStorage.removeItem("token");

    dispatch({
        type: actionTypes.AUTH.SIGN_OUT,
    });
};

export const updateProfile =
    (values, onSuccess, onFailure) => async dispatch => {
        // Omit password (has it's specific action),
        // new_email (change it's key to email below),
        // picture (profile picture would be set on upload)
        const { new_email, ...cleanedValues } = values;

        let finalValues;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!new_email) {
            finalValues = {
                ...cleanedValues,
                email: new_email,
            };
        } else {
            finalValues = cleanedValues;
        }

        try {
            const response = await getApi().put("auth/profile/0/", finalValues);

            dispatch({
                payload: response.data,
                type: actionTypes.AUTH.FETCH_PROFILE,
            });

            if (onSuccess) {
                onSuccess();
            }
            successToast(
                // eslint-disable-next-line no-extra-boolean-cast
                !!new_email
                    ? TOAST_CONTENT.SET_EMAIL_CHANGE_IN_PROFILE.TITLE
                    : TOAST_CONTENT.PROFILE_CHANGE_SUCCESS.TITLE,
                // eslint-disable-next-line no-extra-boolean-cast
                !!new_email
                    ? TOAST_CONTENT.SET_EMAIL_CHANGE_IN_PROFILE.DESC
                    : TOAST_CONTENT.PROFILE_CHANGE_SUCCESS.DESC,
            );
        } catch (e) {
            if (onFailure) {
                onFailure();
            }
        }
    };

export function changePassword(values, onSuccess, onFailure) {
    return function () {
        getApi()
            .post("auth/change_password/", values)
            .then(() => {
                if (onSuccess) onSuccess();
            })
            .catch(err => {
                errorToast(
                    "خطا در تغییر پسورد",
                    processError(err?.response?.data ?? ""),
                    { immediate: true },
                );
                if (
                    err.response.data["old_password"] &&
                    err.response.data["old_password"][0].includes("Invalid") &&
                    onFailure
                ) {
                    onFailure("invalid");
                } else {
                    if (onFailure) onFailure("unsuccessful");
                }
            });
    };
}

export const refreshApiToken =
    ({ showToast = true } = {}, onSuccess, onFailure) =>
    async dispatch => {
        try {
            toggleLoading(
                actionTypes.LOADING.REFRESH_API_TOKEN,
                true,
                dispatch,
            );

            const response = await getApi().refreshApiToken();

            dispatch({
                type: actionTypes.AUTH.FETCH_PROFILE,
                payload: response.data,
            });

            toggleLoading(
                actionTypes.LOADING.REFRESH_API_TOKEN,
                false,
                dispatch,
            );

            if (onSuccess) onSuccess();
        } catch (e) {
            if (onFailure) onFailure();

            toggleLoading(
                actionTypes.LOADING.REFRESH_API_TOKEN,
                false,
                dispatch,
            );

            errorToast(
                TOAST_CONTENT.REFRESH_API_TOKEN.TITLE,
                TOAST_CONTENT.REFRESH_API_TOKEN.DESC,
            );
        }
    };

export const SignUpPlus = (data, extra) => async () => {
    const [error] = await to(getApi().post("/auth/signup-plus/", data));

    if (error) {
        // eslint-disable-next-line no-constant-condition
        if (typeof extra?.onFailure)
            extra.onFailure(
                processError(error?.response?.data ?? "خطایی رخ داده"),
            );
        return;
    }

    if (extra?.onSuccess) extra.onSuccess();
};

export const noafarinStart = (data, options) => async () => {
    const [error, response] = await to(getCommonApi().noafarinStart(data));

    if (error) {
        if (typeof options?.onFailure === "function")
            options.onFailure(processError(error?.response?.data));
        return;
    }

    if (typeof options?.onSuccess === "function")
        options.onSuccess(response.data);
};

export const noafarinSignUp = (data, options) => async dispatch => {
    const [error, response] = await to(getCommonApi().noafarinSignUp(data));

    if (error) {
        if (typeof options?.onFailure === "function")
            options.onFailure(processError(error?.response?.data));
        return;
    }

    myStorage.setItem("token", response.data.token);

    dispatch({
        type: actionTypes.AUTH.SIGN_IN,
        payload: response.data,
    });

    dispatch(push(`/dashboard/${PREFERRED_PLATFORM}`));

    if (typeof options?.onSuccess === "function")
        options.onSuccess(response.data);
};

export const noafarinSignIn = (data, options) => async dispatch => {
    const [error, response] = await to(getCommonApi().noafarinSignIn(data));

    if (error) {
        if (typeof options?.onFailure === "function")
            options.onFailure(processError(error?.response?.data));
        return;
    }

    myStorage.setItem("token", response.data.token);

    dispatch({
        type: actionTypes.AUTH.SIGN_IN,
        payload: response.data,
    });

    dispatch(push(`/dashboard/${PREFERRED_PLATFORM}`));

    if (typeof options?.onSuccess === "function")
        options.onSuccess(response.data);
};

export const updateConsoleSettings = (values, options) => async dispatch => {
    const alreadySettings =
        store.getState()?.auth?.profile?.["console_settings"] ?? {};
    const settings = { console_settings: { ...alreadySettings, ...values } };

    const [error, response] = await to(
        getCommonApi().updateConsoleSettings(settings),
    );

    if (error) {
        if (typeof options?.onFailure === "function") options.onFailure();
        return;
    }

    dispatch({
        type: actionTypes.AUTH.UPDATE_CONSOLE_SETTINGS,
        payload: response.data,
    });

    if (typeof options?.onSuccess === "function") options.onSuccess();
};

export const otpAuth = (token, options) => async dispatch => {
    const [error, response] = await to(getCommonApi().otpAuth(token));

    if (error) {
        const processedError = processError(error?.response?.data);
        errorToast(TOAST_CONTENT.DEFAULT_ERROR_TOAST.TITLE, processedError);

        if (typeof options?.onFailure === "function") {
            options.onFailure(processedError);
        }
        return;
    }

    if (typeof options?.onSuccess === "function") {
        if (response?.data?.["two_step_auth"]) {
            options.onSuccess(response.data);
            return;
        }
        options.onSuccess(response.data);
    }

    myStorage.setItem("token", response.data.token);

    dispatch({
        type: actionTypes.AUTH.SIGN_IN,
        payload: response.data,
    });

    dispatch(push(`/dashboard/${PREFERRED_PLATFORM}`));
};
