import { getApi, getMediaApi } from "@/api";
import actionTypes from "@/actions/_types";
import { isValidPlatform } from "@/actions/_utils.jsx";
const getUrl = (platform, appId = null) => {
    if (appId) {
        return `dashboards/${platform}/${appId}/`;
    } else {
        return `dashboards/${platform}/`;
    }
};
const getUrlNew = (platform, appId = null) => {
    if (appId) {
        return `dashboards/${platform}-plus/${appId}/`;
    } else {
        return `dashboards/${platform}-plus/`;
    }
};

export function fetchDashboardDataNew(onSuccess, onFailure) {
    return function (dispatch, getState) {
        const { platform, appId } = getState().app;
        if (isValidPlatform(platform, dispatch)) {
            let url = getUrlNew(platform, appId);
            getApi()
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data,
                        type: actionTypes.DASHBOARD[
                            `FETCH_${platform.toUpperCase()}_DASHBOARD_DATA`
                        ],
                    });
                    if (onSuccess) onSuccess();
                })
                .catch(() => {
                    if (onFailure) onFailure();
                });
        }
    };
}
export function fetchDashboardData(onSuccess, onFailure) {
    return function (dispatch, getState) {
        const { platform, appId } = getState().app;
        if (isValidPlatform(platform, dispatch)) {
            let url = getUrl(platform, appId);
            getApi()
                .get(url)
                .then(resp => {
                    dispatch({
                        payload: resp.data,
                        type: actionTypes.DASHBOARD[
                            `FETCH_${platform.toUpperCase()}_DASHBOARD_DATA`
                        ],
                    });
                    if (onSuccess) onSuccess();
                })
                .catch(() => {
                    if (onFailure) onFailure();
                });
        }
    };
}

export const sendTicket =
    ({ ...data }, onSuccess, onFailure) =>
    () => {
        getMediaApi()
            .post(`home/tickets/`, data)
            .then(resp => {
                if (onSuccess) onSuccess(resp.data);
            })
            .catch(resp => {
                if (onFailure) onFailure(resp.data);
            });
    };
