import Colors from "@/components/utils/lib/colors";

export function androidSeriesOfAll(chart) {
    let inAccessUsers = {
        data: chart["transactional"],
        label: "کاربران دردسترس",
        fill: true,
        backgroundColor: "transparent",
        borderColor: Colors.orange,
        tension: 0.2,
        borderWidth: 2,
        pointBackgroundColor: Colors.orange,
        pointBorderWidth: 2,
        hoverBackgroundColor: "rgba(0,0,0,0.10)",
    };
    let activeInstallations = {
        data: chart["transactional_installs"],
        label: "نصب های دردسترس",
        fill: true,
        backgroundColor: "transparent",
        borderColor: Colors.blue_0,
        tension: 0.2,
        borderWidth: 2,
        pointBackgroundColor: Colors.blue_0,
        pointBorderWidth: 2,
        hoverBackgroundColor: "rgba(0,0,0,0.10)",
    };

    let allInstallations = {
        data: chart["total"],
        label: "تمامی نصب ها",
        fill: true,
        backgroundColor: "transparent",
        borderColor: Colors.green_0,
        tension: 0.2,
        borderWidth: 2,
        pointBackgroundColor: Colors.green_0,
        pointBorderWidth: 2,
        hoverBackgroundColor: "rgba(0,0,0,0.10)",
    };

    return [[inAccessUsers, activeInstallations], [allInstallations]];
}
