import to from "await-to-js";
import { getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";
import { successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import { store } from "@/store";

export const retrieveGeofence =
    (geofenceId, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().retrieveGeofence(geofenceId),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.GEOFENCE.RETRIEVE_GEOFENCE,
            payload: response.data,
        });

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const createGeofence =
    (data, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(getCommonApi().createGeofence(data));

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.GEOFENCE.CREATE_GEOFENCE,
            payload: response.data,
        });

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const updateGeofence =
    (geofenceId, data, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().updateGeofence(geofenceId, data),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.GEOFENCE.UPDATE_GEOFENCE,
            payload: response.data,
        });

        if (typeof onSuccess === "function") {
            onSuccess(response.data);
        }
    };

export const updateGeofenceLocalState =
    (geofenceId, data, onFinish) => dispatch => {
        dispatch({
            type: actionTypes.GEOFENCE.UPDATE_GEOFENCE_LOCAL_STATE,
            payload: {
                id: geofenceId,
                data,
            },
        });

        if (typeof onFinish === "function") {
            onFinish();
        }
    };

export const toggleEnableGeofence =
    (geofenceId, data, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().toggleEnableGeofence(geofenceId, data),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.GEOFENCE.UPDATE_GEOFENCE,
            payload: response.data,
        });

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const deleteGeofence =
    (geofenceId, onSuccess, onFailure) => async dispatch => {
        // eslint-disable-next-line no-unused-vars
        const [error, response] = await to(
            getCommonApi().deleteGeofence(geofenceId),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.GEOFENCE.DELETE_GEOFENCE,
            payload: { geofence_id: geofenceId },
        });

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const exportCSVGeofence =
    (geofenceId, onSuccess, onFailure) => async () => {
        // eslint-disable-next-line no-unused-vars
        const [error, response] = await to(
            getCommonApi().exportGeofence(geofenceId),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }
        successToast(
            TOAST_CONTENT.EXPORT_CV_SUCCESS.TITLE,
            TOAST_CONTENT.EXPORT_CV_SUCCESS.DESC,
        );

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const retrieveGeofenceList = options => async dispatch => {
    const limit = 15;
    let { offset, hasMore } = store.getState()?.geofence?.geofences ?? {};

    offset = typeof options.offset === "number" ? options.offset : offset;
    hasMore = typeof options.hasMore === "boolean" ? options.hasMore : hasMore;

    const shouldReset = offset === 0;

    const [error, response] = await to(
        getCommonApi().retrieveGeofenceList({ offset, limit }),
    );

    if (
        Array.isArray(response?.data?.results) &&
        response.data.results.length >= limit
    )
        offset += limit;
    else hasMore = false;

    if (error && typeof options?.onFailure === "function") {
        options?.onFailure();
        return;
    }

    dispatch({
        type: actionTypes.GEOFENCE.RETRIEVE_GEOFENCE_LIST,
        payload: {
            offset,
            hasMore,
            items: response.data.results,
            shouldReset,
        },
    });

    if (typeof options?.onFinish === "function") {
        options.onFinish();
    }
};

export const clearFetchedGeofenceItems = options => dispatch => {
    dispatch({
        type: actionTypes.GEOFENCE.CLEAR_FETCHED_GEOFENCE_ITEMS,
    });

    if (typeof options?.onFinish === "function") options.onFinish();
};
