export const CONTENT_TYPE = {
    SIMPLE: "1",
    HTML: "2",
};

export const AUTOMATION_WIZARD_MODE = {
    CREATE: 1,
    EDIT: 2,
};

export const AUTOMATION_FORM_NAME = {
    android: "android_email_automation_form",
    web: "web_email_automation_form",
};
