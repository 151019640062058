import actionTypes from "@/actions/_types";

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.INSTALLATION.FETCH_WEB_FAVORITES:
            return action.payload;
        case actionTypes.INSTALLATION.FAVE_WEB_INSTALLATIONS:
            return [...state, action.payload];
        case actionTypes.INSTALLATION.UNFAVE_WEB_DEVICE:
            return state.filter(app => app.id !== action.payload);
        case actionTypes.INSTALLATION.FAVE_WEB_UPDATE:
            return state.map(val => {
                if (val.id === action.payload.id)
                    return {
                        ...val,
                        ["favorite_data"]: {
                            ...val.favorite_data,
                            name: action.payload.name,
                        },
                    };
                return val;
            });
    }
    return state;
}
