import SMSTab from "@/components/sms/sms/list/android/tabs/SMSTab";
import DetailsTab from "@/components/sms/sms/list/android/tabs/DetailsTab";

export const tabs = [
    {
        label: "پیامک",
        value: "sms_mode",
        display: () => true,
        component: SMSTab,
        disabled: () => false,
        wrapperClassName:
            "card-list__custom-body--wrapper card-list__tab-details",
    },
    {
        label: "جزئیات",
        value: "details",
        display: () => true,
        component: DetailsTab,
        disabled: () => false,
        wrapperClassName:
            "card-list__custom-body--wrapper card-list__tab-details",
    },
];
