import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import "styles/modules/_mod-segments.scss";

import BaseModal from "@/components/utilities/modal/baseModal";
import { MODAL_THEME } from "@/actions/_types";

const DeleteModal = ({ onAccept, onClose, error, title, body }) => {
    return (
        <BaseModal
            theme={MODAL_THEME.RED}
            title={title}
            useDefaultFooter={true}
            body={body}
            error={error}
            onClose={onClose}
            onAccept={onAccept}
        />
    );
};

export default withErrorCatcher(DeleteModal);
