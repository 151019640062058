/**
 * Use this to create initialValues for redux form
 *
 * This function get an object as the first parameter and a list of strings as the second one
 * return an object with the keys from the array and value from the object
 *
 * @param allValues
 * @param listOfKeys
 * @returns {*}
 */
export const makeInitialValues = (allValues, listOfKeys) => {
    if (!Array.isArray(listOfKeys)) {
        throw new Error(
            "`makeInitialValues` accepts an array as the second parameter",
        );
    }

    const initials = listOfKeys.reduce((acc, curr) => {
        acc[curr] = allValues?.[curr];
        return acc;
    }, {});

    return { ...initials };
};
