import React from "react";
import {
    CircleLoader,
    FlatButton,
    withErrorCatcher,
} from "@/components/utilities";
import css from "authentication/styles/signin.module";

export default withErrorCatcher(props => {
    const { handleSignIn, inProgress } = props;

    return (
        <div className={css.buttonsPart}>
            <FlatButton
                fullWidth
                label={"ورود به پوشه"}
                onClick={handleSignIn}
                endIcon={inProgress ? <CircleLoader /> : null}
                disabled={inProgress}
            />
        </div>
    );
});
