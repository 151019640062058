/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";

const BlueRadio = withStyles(theme => ({
    root: {
        color: theme.palette.dashboard.blueLight,
        "&$checked": {
            color: theme.palette.dashboard.blue,
        },
    },
    checked: {},
}))(props => <Radio color="default" {...props} />);

const useStyles = makeStyles(() => ({
    root: {
        flexDirection: "row",
    },
    labelRoot: {
        marginRight: 0,
        marginLeft: "40px",
    },
}));

export const RadioButtons = props => {
    const { label, options, value, defaultValue = "", onChange } = props;
    const [val, setValue] = useState(defaultValue.toString());

    const classes = useStyles();

    useEffect(() => {
        if (typeof onChange === "function") onChange(val);
    }, [val]);

    return (
        <FormControl component="fieldset">
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <RadioGroup
                aria-label=""
                name=""
                value={value || val}
                fullWidth
                onChange={event => setValue(event.target.value)}
                classes={{ root: classes.root }}
            >
                {options &&
                    options.map((option, idx) => (
                        <FormControlLabel
                            key={idx}
                            value={option.value.toString()}
                            control={<BlueRadio />}
                            classes={{ root: classes.labelRoot }}
                            label={option.label}
                        />
                    ))}
            </RadioGroup>
        </FormControl>
    );
};

// TODO: this is not tested yet
export const reduxRadixButtons = props => {
    const {
        input: { value, onChange },
        ...otherProps
    } = props;

    return <RadioButtons value={value} onChange={onChange} {...otherProps} />;
};
