import React from "react";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import CardPageFixedHeader from "@/components/utilities/commons/CardPageFixedHeader";

const FixedHeader = props => {
    const { title, labelBtn, redirectClicked } = props;

    return (
        <CardPageFixedHeader title={title}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <span>
                    <FlatButton
                        className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                        rippleColor={blue900}
                        label={labelBtn}
                        style={{ marginLeft: "10px" }}
                        onClick={redirectClicked}
                    />
                </span>
            </div>
        </CardPageFixedHeader>
    );
};
export default FixedHeader;
