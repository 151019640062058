import NotificationTopicsFilterTab from "@/components/notifications/notification/create/android/partials/NotificationTopicsFilterTab";
import NotificationFiltersTab from "@/components/notifications/notification/create/android/partials/NotificationFiltersTab";
import NotificationSegmentsFilterTab from "@/components/notifications/notification/create/android/partials/NotificationSegmentsFilterTab";
import { hasPermission } from "@/components/utilities";
import { PERMISSIONS } from "@/components/utilities/permission/permissions";
import UploadFilterFile from "@/components/notifications/notification/create/android/partials/UploadFilterFile";
import { FILTER_MODE } from "@/components/notifications/notification/constants";

export default [
    {
        label: "فیلتر",
        value: FILTER_MODE.FILTERS,
        display: true,
        hasPermission: () => true,
        component: NotificationFiltersTab,
    },
    {
        label: "تاپیک",
        value: FILTER_MODE.TOPICS,
        display: true,
        hasPermission: () => true,
        component: NotificationTopicsFilterTab,
    },
    {
        label: "دسته‌بندی‌ها",
        value: FILTER_MODE.SEGMENTS,
        display: true,
        hasPermission: () => hasPermission(PERMISSIONS.SEGMENT_IDS_FILTER_TAB),
        component: NotificationSegmentsFilterTab,
    },
    {
        label: "آپلود فایل",
        value: FILTER_MODE.CSV,
        display: true,
        hasPermission: () => hasPermission(PERMISSIONS.CSV_FILTER_TAB),
        component: UploadFilterFile,
    },
];
