import { Field, FormSection } from "redux-form";
import React from "react";
import { UPLOAD_IMAGE_TYPE, withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { Checkbox, TextField } from "redux-form-material-ui";
import NotificationActionSelect from "@/components/utilities/commons/wizard/controls/NotificationActionSelect";
import NotificationImageUpload from "@/components/utilities/commons/wizard/controls/NotificationImageUpload";
import WebGoogleAnalytics from "@/components/notifications/notification/create/web/controls/WebGoogleAnalytics";

const NotificationContentComponent = props => {
    const {
        title,
        change,
        uploadFile,
        hasTitleField,
        hasSubTitleField,
        hasContentField,
        hasActionField,
        hasCloseOnClickField,
        hasIconField,
        hasImageField,
        hasWebImageField,
        actionsList,
        actionIsActive,
    } = props;
    const toggleUploaderProgress = status => {
        change("uploaderInProgress", status);
    };

    return (
        <FormSection name="data">
            <div className="notif-block">
                <header>
                    <p>{title}</p>
                </header>
                <div className="notif-block__body">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            {hasTitleField && (
                                <Field
                                    component={TextField}
                                    className="mui-textfield"
                                    floatingLabelText="تیتر*"
                                    name="title"
                                    style={{ color: "red" }}
                                    fullWidth={true}
                                    format={value =>
                                        value !== undefined
                                            ? value.length < 255
                                                ? value
                                                : value.substring(0, 255)
                                            : ""
                                    }
                                />
                            )}
                            {hasSubTitleField && (
                                <Field
                                    component={TextField}
                                    className="mui-textfield"
                                    floatingLabelText="تیتر کوچک"
                                    name="subtitle"
                                    fullWidth={true}
                                    format={value =>
                                        value !== undefined
                                            ? value.length < 255
                                                ? value
                                                : value.substring(0, 255)
                                            : ""
                                    }
                                />
                            )}
                            {hasContentField && (
                                <Field
                                    component={TextField}
                                    className="mui-textfield"
                                    floatingLabelText="متن نوتیفیکیشن*"
                                    name="content"
                                    fullWidth={true}
                                    multiLine={true}
                                    rows={4}
                                    format={value =>
                                        value !== undefined
                                            ? value.length < 255
                                                ? value
                                                : value.substring(0, 255)
                                            : value
                                    }
                                />
                            )}
                            {hasActionField && (
                                <Field
                                    component={NotificationActionSelect}
                                    name="action"
                                    actions={actionsList}
                                    floatingLabelText="عملکرد مورد نظر را انتخاب کنید"
                                />
                            )}
                            {hasCloseOnClickField && (
                                <Field
                                    style={{ marginTop: "20px" }}
                                    component={Checkbox}
                                    className="mui-checkbox"
                                    name="close_on_click"
                                    label="باقی ماندن پیام تا کلیک کاربر"
                                />
                            )}
                        </div>

                        {hasIconField && (
                            <div
                                className="col-md-6 col-sm-12 col-xs-12"
                                style={{ height: "240px" }}
                            >
                                <div style={{ width: "100%", height: "100%" }}>
                                    <Field
                                        component={NotificationImageUpload}
                                        name="icon"
                                        uploadFile={uploadFile}
                                        title="انتخاب آیکون"
                                        subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                        type={UPLOAD_IMAGE_TYPE.ICON}
                                        // To respect React 15 input behavior there is defaultFormat = value => value == null ? '' : value
                                        // internally used. To disable that you can pass null as format prop.
                                        // remove this will cause change null value into into ''
                                        format={null}
                                        toggleUploaderProgress={
                                            toggleUploaderProgress
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {actionIsActive?.action?.id === "open_link" && (
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <WebGoogleAnalytics
                                    actionIsActive={actionIsActive}
                                    change={change}
                                />
                            </div>
                        )}

                        {hasImageField && (
                            <div
                                className="col-md-6 col-sm-12 col-xs-12"
                                style={{ height: "240px" }}
                            >
                                <div style={{ width: "100%", height: "100%" }}>
                                    <br />
                                    <br />
                                    <Field
                                        component={NotificationImageUpload}
                                        name="image"
                                        uploadFile={uploadFile}
                                        title="انتخاب عکس"
                                        subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                        type="image"
                                        // To respect React 15 input behavior there is defaultFormat = value => value == null ? '' : value
                                        // internally used. To disable that you can pass null as format prop.
                                        // remove this will cause change null value into into ''
                                        format={null}
                                        toggleUploaderProgress={
                                            toggleUploaderProgress
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {hasWebImageField && (
                <div className="notif-block">
                    <header>
                        <p>
                            عکس بزرگ (فقط برای برخی ورژن‌های Chrome ، Edge و
                            Opera)
                        </p>
                    </header>
                    <div className="notif-block__body">
                        <div
                            className="col-sm-12 col-xs-12"
                            style={{ height: "240px" }}
                        >
                            <div style={{ width: "100%", height: "100%" }}>
                                <Field
                                    component={NotificationImageUpload}
                                    name="image"
                                    type="image"
                                    title="انتخاب عکس بزرگ"
                                    subtitle="عکس را به اینجا بکشید یا روی اینجا کلیک کنید"
                                    uploadFile={uploadFile}
                                    toggleUploaderProgress={
                                        toggleUploaderProgress
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </FormSection>
    );
};

NotificationContentComponent.propTypes = {
    title: PropTypes.string,
    change: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    actionsList: PropTypes.array,
    hasTitleField: PropTypes.bool,
    hasSubTitleField: PropTypes.bool,
    hasContentField: PropTypes.bool,
    hasActionField: PropTypes.bool,
    hasCloseOnClickField: PropTypes.bool,
    hasIconField: PropTypes.bool,
    hasImageField: PropTypes.bool,
    hasWebImageField: PropTypes.bool,
};
NotificationContentComponent.defaultProps = {
    title: "محتوای نوتیفیکیشن",
    actionsList: [],
    hasTitleField: true,
    hasSubTitleField: false,
    hasContentField: true,
    hasActionField: true,
    hasCloseOnClickField: false,
    hasIconField: true,
    hasImageField: false,
    hasWebImageField: false,
};

export default withErrorCatcher(NotificationContentComponent);
