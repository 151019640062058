import React from "react";
import { formValueSelector, reduxForm, FormSection } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { uploadFile } from "@/actions/app";
import { hasOneSignal as checkHasOneSignal } from "@/utils";
import { FULL_CONTENT_TYPE_MODE } from "@/components/notifications/notification/constants";
import { withErrorCatcher } from "@/components/utilities";
import NotificationDetailedContentComponent from "@/components/utilities/commons/wizard/components/NotificationDetailedContentComponent";

const AndroidGeofenceStep3 = ({
    hasOneSignal,
    uploadFile,
    fullContentType,
    notif_icon,
    change,
}) => {
    if (!fullContentType) {
        change("fullContentType", FULL_CONTENT_TYPE_MODE.CONTENT);
    }

    return (
        <form className="notif-step2">
            <FormSection name="notification">
                <NotificationDetailedContentComponent
                    hasOneSignal={hasOneSignal}
                    uploadFile={uploadFile}
                    fullContentType={fullContentType}
                    notif_icon={notif_icon}
                    change={change}
                />
            </FormSection>
        </form>
    );
};

function mapStateToProps(state) {
    const { platform } = state.app;
    const {
        fullContentType,
        data,
        app_ids = [],
    } = formValueSelector("geofence_form")(
        state,
        "fullContentType",
        "data",
        "app_ids",
    );
    const hasOneSignal = checkHasOneSignal(
        app_ids,
        state[platform].applications || [],
    );

    return {
        fullContentType,
        notif_icon: (data || {}).notif_icon,
        hasOneSignal,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            uploadFile,
        },
        dispatch,
    );
}

const AndroidGeofenceStep3RF = reduxForm({
    form: "geofence_form",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidGeofenceStep3);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AndroidGeofenceStep3RF),
    ),
);
