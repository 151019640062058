import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { otpAuth } from "@/actions/auth";
import SignInPage from "authentication/signin/SignInPage";

function OTPAuth(props) {
    const dispatch = useDispatch();

    const [twoStepIsEnabled, setTwoStepIsEnabled] = useState(false);
    const [authError, setAuthError] = useState("");

    const getToken = () =>
        (window.location.pathname.split("/").filter(x => x) ?? [])[1];

    useEffect(() => {
        const token = getToken();

        if (!token) window.location = "/";

        otpAuth(token, {
            onSuccess: data =>
                data?.["two_step_auth"] && setTwoStepIsEnabled(true),
            onFailure: error => setAuthError(error),
        })(dispatch);
    }, [dispatch]);

    if (twoStepIsEnabled || authError)
        return <SignInPage enabled2fa={twoStepIsEnabled} {...props} />;

    return <></>;
}

export default OTPAuth;
