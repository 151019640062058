import React from "react";
import { ANDROID_FRAMEWORKS } from "@/actions/_types";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import { withErrorCatcher } from "@/components/utilities";
import BaseModal from "@/components/utilities/modal/baseModal";
import "styles/modules/_mod-application.scss";

const images = {
    AndroidStudio: require("images/app-builder/android-studio.svg"),
    Unity: require("images/app-builder/unity.svg"),
    ReactNative: require("images/app-builder/react-native.png"),
    Xcode: require("images/app-builder/xcode.png"),
    Flutter: require("images/app-builder/flutter_gray.svg"),
};

class FrameworkModal extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        onClick: PropTypes.func,
        onClose: PropTypes.func.isRequired,
    };

    onClick({ framework }) {
        const { onClick } = this.props;

        if (onClick) onClick(framework);
    }

    render() {
        const { onClose } = this.props;
        const frameworkNumbers = ANDROID_FRAMEWORKS.NUMBERS;

        return (
            <BaseModal
                customClass="modal--market"
                modalSize="md"
                title={"انتخاب فریمورک"}
                body={
                    <div className="frameworksModal">
                        <div className="frameworksModal__content">
                            {Object.keys(frameworkNumbers).map(number => {
                                return (
                                    <div
                                        className={`frameworksModal__box`}
                                        key={uuid()}
                                        onClick={() =>
                                            this.onClick({ framework: number })
                                        }
                                    >
                                        <img
                                            src={
                                                images[
                                                    ANDROID_FRAMEWORKS._getName(
                                                        number,
                                                    )
                                                ]
                                            }
                                        />
                                        <p>
                                            {ANDROID_FRAMEWORKS._getName(
                                                number,
                                            )}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
                onClose={onClose}
            ></BaseModal>
        );
    }
}

export default withErrorCatcher(FrameworkModal);
