import { createTheme } from "@material-ui/core/styles";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import Colors from "@/components/utils/lib/colors";

export default createTheme({
    palette: {
        dashboard: {
            black: "#000000",
            blue: "#0065ff",
            blueLight: "#0065ff30",
            green: "#00feb6",
            greenLight: "#00feb630",
            white: "#ffffff",
            gray: "#d8d8d8",
            red: "#f44336",
        },
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: "rgba(0, 0, 0, 0.5)",
            },
        },
    },
    typography: {
        fontFamily: {
            light: "iranyekanweblight",
            bold: "iranyekanwebbold",
            fnLight: "iranyekanfnlight",
            fnBold: "iranyekanfnbold",
        },
    },
});

// This is for material v0 and after refactor to new material this should be deleted
export const legacyMaterialTheme = getMuiTheme({
    fontFamily: "",
    isRtl: true,
    toggle: {
        labelColor: Colors.gray_9,
        thumbOnColor: "#22A9E0",
        thumbOffColor: "#F0EFEF",
        trackOnColor: "#CEF1FF",
        trackOffColor: "#D5D3D3",
    },
    textField: {
        textColor: Colors.gray_8,
    },
    radioButton: {
        labelColor: Colors.gray_9,
    },
    checkbox: {
        labelColor: Colors.gray_9,
    },
    tabs: {
        backgroundColor: "#ffffff",
        textColor: Colors.gray_8,
        selectedTextColor: "inherit",
    },
});
