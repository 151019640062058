import Step1 from "./step1.mdx";
import Step2 from "./step2.mdx";
import Step3 from "./step3.mdx";
import Step4 from "./step4.mdx";

export const manifest = [{ component: Step2 }];

export default [
    { component: Step1 },
    { component: Step2 },
    { component: Step3 },
    { component: Step4 },
];
