import { mapValues } from "lodash";
import {
    isAndroidPackageName,
    isCellPhoneNumber,
    isEmail,
    isHtppUrl,
    isPhoneNumber,
    isRequired,
    isUrl,
} from "@/components/utils/validation";
import { PLATFORMS } from "@/constants";

/**
 * ## Action Options Format ##
 * The following parameters are available for defining actions
 *
 * `id`            The action it (e.g, open_app, open_link)
 * `actionType`    The action type (e.g., U, A, etc.)
 * `text`          The description to show in the UI
 * `params`        The action parameters to ask for in the console UI. Should be a list of objects defining
 *                 the parameter options. See below for more details.
 * `paramsToData`  Defines how the action parameters obtained from the UI should be converted to action data
 *                 to be passed to the server. Can either be a function which returns the data object or an
 *                 object which contains separate functions for each data value. In either case, the functions
 *                 will be passed an object containing the parameter values as their first input.
 * `dataToParams`  Defines how the action data should be parsed into parameter values to show in the UI. This
 *                 is used when we want to show an action obtained from the server in the UI (e.g., when
 *                 forwarding notification). It should be a function which takes the data object as input and
 *                 returns an object containing the parameter values or returns `undefined` if the data does
 *                 not belong to the action.
 * ‍‍`parsePriority` Defines in what order the actions should be checked when parsing action data to an action
 *                 type and parameters using the `dataToParams` field. Actions with higher priority will be
 *                 checked first.
 */

const BASE_ACTIONS = {
    OPEN_LINK: {
        id: "open_link",
        actionType: "U",
        text: "باز کردن لینک",
        params: [
            {
                name: "url",
                label: "آدرس لینک *",
                type: "text",
                validate: [isRequired, isHtppUrl],
            },
        ],
        paramsToData: ({ url }) => ({ url }),
        dataToParams: ({ url }) => ({ url }),
        parsePriority: 0,
    },
    OPEN_APP: {
        id: "open_app",
        actionType: "A",
        text: "باز کردن نرم‌افزار",
    },
    ANDROID_APP_PAGE_IN_MARKET: {
        id: "app_market",
        actionType: "U",
        text: "صفحه اپ در مارکت",
        params: [
            {
                name: "package_name",
                label: "نام پکیج *",
                type: "text",
                validate: [isRequired, isAndroidPackageName],
            },
            {
                name: "market",
                label: "انتخاب مارکت مورد نظر *",
                type: "select",
                items: [
                    { value: "google_play", text: "گوگل پلی" },
                    { value: "bazaar", text: "بازار" },
                    { value: "myket", text: "مایکت" },
                    { value: "iranapps", text: "ایران اپس" },
                ],
            },
        ],
        paramsToData: ({ market, package_name }) => {
            switch (market) {
                case "google_play":
                    return {
                        url: `market://details?id=${package_name}`,
                        market_package_name: "com.android.vending",
                    };
                case "bazaar":
                    return {
                        url: `bazaar://details?id=${package_name}`,
                        market_package_name: "com.farsitel.bazaar",
                    };
                case "myket":
                    return {
                        url: `myket://application/#Intent;scheme=myket;package=${package_name}`,
                        market_package_name: "ir.mservices.market",
                    };
                case "iranapps":
                    return {
                        url: `iranapps://app/${package_name}`,
                        market_package_name: "ir.tgbs.android.iranapp",
                    };
            }
        },
        dataToParams: ({ url, market_package_name }) => {
            let [market, packageNameRegex] = [null, null];

            switch (market_package_name) {
                case "com.android.vending":
                    market = "google_play";
                    packageNameRegex = /^\s*market:\/\/details\?id=(.+)\s*$/;
                    break;
                case "com.farsitel.bazaar":
                    market = "bazaar";
                    packageNameRegex = /^\s*bazaar:\/\/details\?id=(.+)\s*$/;
                    break;
                case "ir.mservices.market":
                    market = "myket";
                    packageNameRegex =
                        /^\s*myket:\/\/application\/#Intent;scheme=myket;package=(.+)\s*$/;
                    break;
                case "ir.tgbs.android.iranapp":
                    market = "iranapps";
                    packageNameRegex = /^\s*iranapps:\/\/app\/(.+)\s*$/;
                    break;
            }

            if (!packageNameRegex) {
                return undefined;
            }

            const match = packageNameRegex.exec(url);

            return market != null
                ? {
                      market,
                      package_name:
                          match && match.length >= 2 ? match[1] : undefined,
                  }
                : undefined;
        },
        parsePriority: 2,
    },
    OPEN_DIALOGUE: {
        id: "open_dialogue",
        actionType: "G",
        text: "باز کردن دیالوگ",
    },
    OTHER_INTENTS: platform => {
        if (platform === PLATFORMS.IOS) {
            return {
                id: "other_intents",
                actionType: "U",
                text: "باز کردن اپ",
                params: [
                    {
                        name: "url",
                        label: "* URI",
                        type: "text",
                        validate: [isRequired],
                    },
                ],
                paramsToData: ({ url }) => ({ url }),
                dataToParams: ({ url }) => ({ url }),
                parsePriority: 0,
            };
        } else {
            return {
                id: "other_intents",
                actionType: "U",
                text: "سایر اینتنت‌ها",
                params: [
                    {
                        name: "url",
                        label: "* URI",
                        type: "text",
                        validate: [isRequired, isUrl],
                    },
                ],
                paramsToData: ({ url }) => ({ url }),
                dataToParams: ({ url }) => ({ url }),
                parsePriority: 0,
            };
        }
    },
    OPEN_SMS_APP: {
        id: "open_sms",
        actionType: "U",
        text: "باز کردن صفحه پیامک",
        params: [
            {
                name: "sms_number",
                label: "شماره *",
                type: "text",
                validate: [isRequired, isCellPhoneNumber],
            },
            {
                name: "sms_content",
                label: "متن پیامک *",
                type: "text",
                validate: [isRequired],
            },
        ],
        paramsToData: {
            url: ({ sms_number, sms_content }) =>
                `sms:${sms_number}?body=${sms_content}`,
        },
        dataToParams: ({ url }) => {
            const match = /^\s*sms:(\d+)(?:\?(?:body=(.*))?)?\s*$/.exec(url);
            return match && match.length >= 2
                ? {
                      sms_number:
                          match && match.length >= 2 ? match[1] : undefined,
                      sms_content:
                          match && match.length >= 3 ? match[2] : undefined,
                  }
                : undefined;
        },
        parsePriority: 2,
    },
    OPEN_WEB_VIEW: {
        id: "web_page",
        actionType: "W",
        text: "باز کردن وب ویو",
        params: [
            {
                name: "url",
                label: "لینک وب ویو *",
                type: "text",
                validate: [isRequired, isHtppUrl],
            },
        ],
        paramsToData: ({ url }) => ({ url }),
        dataToParams: ({ url }) => ({ url }),
    },
    CLOSE_NOTIFICATION: {
        id: "close_notif",
        actionType: "D",
        text: "بستن نوتیفیکیشن",
    },
    APP_ACTIVITY: {
        id: "app_activity",
        actionType: "T",
        text: "نمایش اکتیویتی خاص اپلیکیشن",
        params: [
            {
                name: "activity",
                label: "نام اکتیویتی مورد نظر *",
                type: "text",
                validate: [isRequired],
            },
        ],
        paramsToData: ({ activity }) => ({
            action_data: activity,
            url: "",
            market_package_name: "",
        }),
        dataToParams: ({ action_data }) => ({ activity: action_data }),
    },
    OPEN_TELEPHONE_APP: {
        id: "open_tel",
        actionType: "U",
        text: "باز کردن شماره‌گیر",
        params: [
            {
                name: "tel",
                label: "شماره تلفن *",
                type: "text",
                validate: [isRequired, isPhoneNumber],
            },
        ],
        paramsToData: {
            url: ({ tel }) => `tel:${tel}`,
        },
        dataToParams: ({ url }) => {
            const match = /^\s*(?:tel:)?(\d+)\s*$/.exec(url);
            return match && match.length >= 2 ? { tel: match[1] } : undefined;
        },
        parsePriority: 2,
    },
    INVITE_TELEGRAM: {
        id: "invite_telegram",
        actionType: "U",
        text: "دعوت به کانال تلگرام",
        params: [
            {
                name: "channel",
                label: "نام کانال *",
                type: "text",
                validate: [isRequired],
            },
        ],
        paramsToData: {
            url: ({ channel }) => `tg://resolve?domain=${channel}`,
        },
        dataToParams: ({ url }) => {
            const match = /^\s*tg:\/\/resolve\?domain=(.+)\s*$/.exec(url);
            return match && match.length >= 2
                ? { channel: match[1] }
                : undefined;
        },
        parsePriority: 2,
    },
    OPEN_EMAIL_APP: {
        id: "open_email",
        actionType: "U",
        text: "باز کردن ایمیل",
        params: [
            {
                name: "email",
                label: "ایمیل مخاطب *",
                type: "text",
                validate: [isRequired, isEmail],
            },
            {
                name: "subject",
                label: "عنوان ایمیل *",
                type: "text",
                validate: [isRequired],
            },
            {
                name: "text",
                label: "متن ایمیل *",
                type: "text",
                validate: [isRequired],
            },
        ],
        paramsToData: {
            url: ({ email, subject, text }) =>
                `mailto:${email}?subject=${subject}&body=${text}`,
        },
        dataToParams: ({ url }) => {
            const parsedUrl = new URL(url);
            if (
                parsedUrl.protocol !== "mailto" &&
                parsedUrl.protocol !== "mailto:"
            ) {
                return undefined;
            }
            return {
                email: /^.+@.+\..+$/.test(parsedUrl.pathname)
                    ? parsedUrl.pathname
                    : null,
                subject: parsedUrl.searchParams.get("subject"),
                text: parsedUrl.searchParams.get("body"),
            };
        },
        parsePriority: 2,
    },
    IOS_VIEW_CONTROLLER: {
        id: "view_controller",
        actionType: "U",
        text: "نمایش ویو کنترلر",
        params: [
            {
                name: "vc_id",
                label: "آی دی ویو کنترلر",
                type: "text",
                validate: [isRequired],
            },
            {
                name: "storyboard",
                label: "storyboard",
                type: "text",
                validate: [isRequired],
            },
        ],
    },
};

export const AndroidActions = [
    BASE_ACTIONS.OPEN_LINK,
    BASE_ACTIONS.OPEN_APP,
    BASE_ACTIONS.ANDROID_APP_PAGE_IN_MARKET,
    BASE_ACTIONS.OPEN_DIALOGUE,
    BASE_ACTIONS.OTHER_INTENTS(PLATFORMS.ANDROID),
    BASE_ACTIONS.OPEN_SMS_APP,
    BASE_ACTIONS.OPEN_WEB_VIEW,
    BASE_ACTIONS.CLOSE_NOTIFICATION,
    BASE_ACTIONS.APP_ACTIVITY,
    BASE_ACTIONS.OPEN_TELEPHONE_APP,
    BASE_ACTIONS.INVITE_TELEGRAM,
    BASE_ACTIONS.OPEN_EMAIL_APP,
];

export const WEB_ACTIONS = [
    BASE_ACTIONS.CLOSE_NOTIFICATION,
    BASE_ACTIONS.OPEN_LINK,
];

export const IOS_ACTIONS = [
    BASE_ACTIONS.OPEN_LINK,
    BASE_ACTIONS.OPEN_APP,
    BASE_ACTIONS.OPEN_SMS_APP,
    BASE_ACTIONS.OPEN_WEB_VIEW,
    BASE_ACTIONS.CLOSE_NOTIFICATION,
    BASE_ACTIONS.APP_ACTIVITY,
    BASE_ACTIONS.OPEN_TELEPHONE_APP,
    BASE_ACTIONS.INVITE_TELEGRAM,
    BASE_ACTIONS.OPEN_EMAIL_APP,
    BASE_ACTIONS.OTHER_INTENTS(PLATFORMS.IOS),
    BASE_ACTIONS.IOS_VIEW_CONTROLLER,
];

export function getActionDataFromParams(actionOptions, paramValues) {
    if (typeof actionOptions.paramsToData === "function") {
        return actionOptions.paramsToData(paramValues);
    } else if (typeof actionOptions.paramsToData === "object") {
        return mapValues(actionOptions.paramsToData, paramToData => {
            if (typeof paramToData === "function") {
                return paramToData(paramValues);
            }
            return paramToData;
        });
    }
    return {};
}

export function findAndParseAction(allActions, actionData) {
    const candidActions = allActions
        .filter(a => a.actionType === actionData.action_type)
        .sort((a, b) => {
            const diff = (b.parsePriority || 0) - (a.parsePriority || 0);
            if (diff < 0) {
                return -1;
            } else if (diff > 0) {
                return 1;
            }
            // Give priority to actions with a defined `dataToParams`
            if (diff === 0) {
                return a.dataToParams === undefined ? 1 : 0;
            }
        });

    for (let action of candidActions) {
        if (!action.dataToParams) {
            return { action, params: {} };
        }

        const params = action.dataToParams(actionData);
        if (params !== undefined) {
            return { action, params };
        }
    }
}
