/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDriver } from "@/components/app/introduction/useDriver";

export default props => {
    const {
        steps,
        mobileSteps,
        resolvedCallback,
        driverName,
        allowClose,
        shouldRun,
    } = props;

    const { run } = useDriver({
        steps,
        mobileSteps,
        resolvedCallback,
        driverName,
        allowClose,
    });

    useEffect(() => {
        if (typeof shouldRun === "boolean") {
            // if shouldRun is defined as true or false
            shouldRun && run();
        } else {
            // otherwise run it as soon as component did mount
            run();
        }
    }, []);

    return null;
};
