
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`استفاده از `}<em parentName="h1">{`Pushe`}</em>{` درون پروژه`}</h1>
    <p>{`بعد از طی کردن مراحل قبل ، پوشه درون پروژه‌تان راه اندازی شده و می‌توانید
مشابه زیر آن را `}<inlineCode parentName="p">{`import`}</inlineCode>{` کرده و از آن استفاده کنید.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Pushe from "pushe-react-native";
`}</code></pre>
    <p>{`برای مشاهده امکانات و متد‌های کتابخانه پوشه می‌توانید `}<a parentName="p" {...{
        "href": "http://docs.pushe.co/docs/react-native/intro"
      }}>{`به راهنما پوشه`}</a>{` مراجعه کنید.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;