import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import reducers from "@/reducers";
import { createBrowserHistory } from "history";
import { googleAnalytics } from "@/components/utils/reactGAMiddlewares";
import { sentryReporterMiddleware } from "configs/sentry";
import { PREFERRED_PLATFORM } from "@/constants";

/**
 * Changes a `history` object so that calling `goBack()` on the object
 * won't cause the browser to exit the website.
 *
 * By default if you call `history.goBack()` on the initial website landing
 * page you will exit the website (similar to pressing the browser back button).
 * If this patch is applied you will be navigated to the website root instead.
 *
 * Taken from here: https://github.com/ReactTraining/history/issues/26
 *
 * @param history A `history` object obtained by calling `createHistory()`
 */
function patchHistory(history) {
    let previous = 0;
    const _push = history.push;
    const _goBack = history.goBack;

    history.push = (path, state) => {
        previous += 1;
        _push(path, state);
    };

    history.goBack = () => {
        const pathname = history.location.pathname;
        const r =
            /((email|sms|notification|application)(?:\/)(automation|geofence)?\/?)(android|ios|web)/;

        if (pathname === "/" || pathname.includes("signin")) {
            previous = 0;
        } else if (r.test(pathname)) {
            const result = pathname.match(r);
            if (!result) return;
            const len = result.length;
            const mainPath = result[1];
            const platform = result[len - 1];
            history.push(`/${mainPath}${platform}`);
        } else if (previous > 0) {
            previous -= 1;
            _goBack();
        } else {
            history.push(`/dashboard/${PREFERRED_PLATFORM}`);
        }
    };
    return history;
}

export const history = patchHistory(createBrowserHistory());

const IS_DEVELOPMENT = CONSOLE_ENV === "development";
const IS_PRODUCTION = CONSOLE_ENV === "production";
const IS_MOOX = CONSOLE_ENV === "moox";

const composeEnhancers =
    (IS_DEVELOPMENT || IS_MOOX) &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

let middlewares = [thunk, routerMiddleware(history), sentryReporterMiddleware];

if (IS_PRODUCTION) {
    middlewares.push(googleAnalytics);
}

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares)),
);
