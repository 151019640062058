/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import BaseModal from "@/components/utilities/modal/baseModal";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import { useFetchInvoiceDetails } from "@/components/financial/hooks/useFetchInvoiceDetailes";
import InvoicePDF from "@/components/financial/partials/component/InvoicePDF";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
    boxBtn: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
        "['@media print']": {
            display: "none",
        },
    },
});
const InvoiceDownloadDialog = props => {
    const classes = useStyle();
    const { invoiceId, onClose, onAccept } = props;
    const [state, setState] = useState({
        name: "",
        error: null,
    });
    const [inProgress, invoice, fetchInvoiceDetails] =
        useFetchInvoiceDetails(invoiceId);

    useEffect(() => {
        if (open && !invoice) fetchInvoiceDetails();
    }, [open]);

    const onSuccess = () => {
        onClose();
        setState(prevState => ({ ...prevState, error: null }));
    };

    return (
        <BaseModal
            modalSize="lg"
            onClose={() => props.onClose()}
            title={` دانلود فاکتور  `}
            useDefaultFooter={false}
            body={
                <>
                    {inProgress && <CircleLoader />}

                    {!inProgress && (
                        <>
                            <InvoicePDF invoice={invoice} />
                            <div className={`${classes.boxBtn} no-printme`}>
                                <Button
                                    color={"default"}
                                    variant={"outlined"}
                                    onClick={print}
                                    className={"noprint"}
                                >
                                    دریافت فاکتور
                                </Button>
                            </div>
                        </>
                    )}
                </>
            }
            onAccept={() => onAccept()}
        />
    );
};

export default withErrorCatcher(InvoiceDownloadDialog);
