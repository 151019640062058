import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: theme.typography.fontFamily.fnLight,
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: theme.palette.dashboard.blue,
        },
        color: theme.palette.dashboard.blue,
        margin: "10px 0",
    },

    input: {
        padding: "8px 0 7px",
        color: theme.palette.dashboard.black,
    },

    labelRoot: {
        left: "unset",
        right: 0,
        transformOrigin: "top right",
        fontFamily: "inherit",
    },
    labelShrink: {
        transformOrigin: "top right",
        color: "inherit !important",
    },
    inputUnderline: {
        borderBottom: "unset",
        "&:hover:not(.Mui-disabled)::before": {
            borderBottom: `1px solid ${theme.palette.dashboard.gray}`,
        },
        "&:before": {
            borderBottom: `1px solid ${theme.palette.dashboard.gray}`,
        },
        "&::after": {
            borderBottom: `2px solid ${theme.palette.dashboard.blue}`,
        },
    },
    labelError: {
        color: `${theme.palette.dashboard.red} !important`,
    },

    helperRoot: {
        textAlign: "right",
    },
}));

export const SimpleTextField = props => {
    const classes = useStyles();
    const { autoComplete = "off", ...otherProps } = props;

    return (
        <TextField
            fullWidth
            autoComplete={autoComplete}
            classes={{ root: classes.root }}
            InputLabelProps={{
                classes: {
                    root: classes.labelRoot,
                    shrink: classes.labelShrink,
                    error: classes.labelError,
                },
            }}
            FormHelperTextProps={{
                classes: {
                    root: classes.helperRoot,
                },
            }}
            InputProps={{
                classes: {
                    underline: classes.inputUnderline,
                    input: classes.input,
                },
            }}
            {...otherProps}
        />
    );
};

export const ReduxSimpleTextField = props => {
    const {
        meta: { touched, invalid, error },
        input,
        helperText,
        ...rest
    } = props;

    return (
        <SimpleTextField
            error={touched && invalid}
            helperText={(touched && error) || helperText}
            {...input}
            {...rest}
        />
    );
};
