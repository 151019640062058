import React from "react";
import css from "authentication/styles/noafarinSignIn.module.scss";
import banner from "images/authentication/noafarin_signin.svg";

function SignInBanner() {
    return (
        <div className={css.bannerWrapper}>
            <img src={banner} alt={"sign in banner image"} />
        </div>
    );
}

export default SignInBanner;
