import React from "react";
import { ReduxSimpleTextField } from "@/components/utilities";
import { Field } from "redux-form";
import css from "authentication/styles/signin.module.scss";

function ResetPassForm(props) {
    const { submitResetPass } = props;

    return (
        <form className={css.signInFormWrapper}>
            <Field
                autoFocus
                name="password"
                type={"password"}
                component={ReduxSimpleTextField}
                required
                label="رمز عبور جدید"
                onKeyPress={e => e.charCode === 13 && submitResetPass()}
            />
            <Field
                name="password_confirm"
                type={"password"}
                component={ReduxSimpleTextField}
                required
                label="تکرار رمز عبور جدید"
                onKeyPress={e => e.charCode === 13 && submitResetPass()}
            />
        </form>
    );
}

export default ResetPassForm;
