import React, { useState } from "react";
import { createPortal } from "react-dom";
import { withRouter } from "react-router-dom";
import { withErrorCatcher } from "@/components/utilities";
import defaultIcon from "images/app-icon/app-blank.png";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { selectApplication } from "@/actions/app";

const Option = props => {
    let { icon, label, value: packageName } = props.data;
    return (
        <components.Option {...props}>
            <div className="filter-search-container">
                <img
                    src={icon || defaultIcon}
                    className="filter-search-icon"
                    alt=""
                />
                <div className="filter-search-labels">
                    <p className="filter-search-name">{label || packageName}</p>
                    <p className="filter-search-package">{packageName}</p>
                </div>
            </div>
        </components.Option>
    );
};

const Group = props => <components.Group {...props} />;

const AppFilterBase = () => {
    const dispatch = useDispatch();

    const [search, updateSearch] = useState("");
    const [onFocus, toggleOnFocus] = useState(false);

    const platform = useSelector(state => state?.app?.platform ?? "android");
    const applications = useSelector(
        state => state?.[platform]?.applications ?? [],
    ).filter(
        app => app["transactional_users"] && app["transactional_users"] > 0,
    );
    const isLoading = useSelector(state => state?.loading?.appLoading);
    let appLen = applications.length;

    const handleOptions = () => {
        let result = applications.filter(app => {
            let { name = "", package_name = "" } = app;

            return (
                name.toLowerCase().includes(search.toLowerCase()) ||
                package_name.toLowerCase().includes(search.toLowerCase())
            );
        });

        result = result.map(app => ({
            icon: app?.icon_url,
            label: app?.name,
            value: app?.app_id,
        }));

        let totalLen = appLen;
        let resultLen = result.length;
        let showingLen = resultLen >= 50 ? 50 : resultLen;
        let foundText =
            resultLen < 50
                ? `${resultLen} اپلیکیشن یافت شد`
                : `نمایش ${showingLen} از ${resultLen} اپلیکیشن`;

        let label = (
            <div className="filter-search-heading">
                <span>{`${totalLen} اپ منتشرشده`}</span>
                <span>{foundText}</span>
            </div>
        );

        if (result.length > 50) result = result.slice(0, 50);

        return [{ label, options: result }];
    };

    const handleOnChange = (values = {}, { action }) => {
        if (action === "select-option") {
            const { value: appId } = values;
            dispatch(selectApplication(appId));
        } else if (action === "clear") {
            dispatch(selectApplication(null));
        }
    };

    const handleInputChange = (input, { action }) => {
        if (action === "input-change") updateSearch(input);
    };

    let noInputMessage =
        isLoading && appLen === 0
            ? "در حال دریافت لیست اپلیکیشن‌ها"
            : "هیچ موردی یافت نشد";

    return (
        <Select
            components={{
                Option,
                Group,
            }}
            options={handleOptions()}
            classNamePrefix="filter-search"
            isClearable={true}
            placeholder={
                onFocus
                    ? "نام اپلیکیشن یا پکیج نیم را جست و جو کنید ..."
                    : "فیلتر اپلیکیشن"
            }
            noOptionsMessage={() => noInputMessage}
            onChange={handleOnChange}
            onInputChange={handleInputChange}
            onFocus={() => toggleOnFocus(true)}
            onBlur={() => toggleOnFocus(false)}
        />
    );
};

const HeaderAppFilter = () => {
    const headerContainer = document.getElementById(
        "headerMobileOnlyContainer",
    );

    return (
        <>
            <div className="filterapp" id="headerSearchFilter">
                <AppFilterBase />
            </div>

            {/* Use ReactDOM.createPortal to portal this into headerContainer and for mobile view*/}
            {headerContainer &&
                createPortal(
                    <div className="mobile-app-filter only-mobile">
                        <div className="withReload__container">
                            <AppFilterBase />
                            <div
                                className="reload-container"
                                id="reloadContainer"
                            />
                        </div>
                    </div>,
                    headerContainer,
                )}
        </>
    );
};

export default withRouter(withErrorCatcher(HeaderAppFilter));
