import actionTypes from "@/actions/_types";

const initial_state = {
    segmentationList: [],
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case actionTypes.SEGMENTATION.CREATE_SEGMENTATION:
            return {
                ...state,
                segmentationList: [action.payload, ...state.segmentationList],
            };
        case actionTypes.SEGMENTATION.UPDATE_SEGMENTATION:
            return {
                ...state,
                segmentationList: state.segmentationList.map(sg => {
                    if (sg.segment_id === action.payload.segment_id) {
                        return action.payload;
                    }
                    return sg;
                }),
            };
        case actionTypes.SEGMENTATION.DELETE_SEGMENTATION:
            return {
                ...state,
                segmentationList: state.segmentationList.filter(
                    sg => sg.segment_id !== action.payload.segmentId,
                ),
            };
        case actionTypes.SEGMENTATION.FETCH_SEGMENTATION:
            return {
                ...state,
                segmentationList: [...action.payload],
            };
        default:
            return state;
    }
};
