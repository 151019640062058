import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "@/store";
import { fetchNotifications } from "@/actions/notifications";
import { NOTIFICATION_TYPES } from "@/components/notifications/notification/list/web/WebNotifList";

const { getState, dispatch } = store;

export const useHandleNotificationList = (platform, notifType) => {
    const [isLoading, toggleIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState({});

    const { notificationList, hasMore } = useSelector(
        s => s?.[platform]?.notifications ?? {},
    );
    const { applications } = useSelector(s => s?.[platform] ?? {});

    useEffect(() => {
        setOffset(notificationList.length);
    }, [notificationList]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifType]);

    const retrieveNotificationList = (_, reset = false) => {
        if ((isLoading || !hasMore) && !reset) return;

        toggleIsLoading(true);

        const onSuccess = () => {
            toggleIsLoading(false);
            if (reset) window.scrollTo(0, 0);
        };

        const onFailure = () => {
            toggleIsLoading(false);
        };

        const payload = {
            offset: reset ? 0 : offset,
            ...filters,
        };

        if (notifType === NOTIFICATION_TYPES.DRAFT) {
            fetchNotifications(
                { ...payload, is_draft: true },
                onSuccess,
                onFailure,
            )(dispatch, getState);
        } else if (notifType === NOTIFICATION_TYPES.NOTIFICATION) {
            fetchNotifications(
                { ...payload, is_draft: false },
                onSuccess,
                onFailure,
            )(dispatch, getState);
        } else {
            // Notifications and drafts together
            fetchNotifications(
                payload,
                onSuccess,
                onFailure,
            )(dispatch, getState);
        }
    };

    const refresh = () => {
        setOffset(0);
        retrieveNotificationList(null, true);
    };

    const search = (newFilters = {}) => {
        setOffset(0);
        setFilters({ ...filters, ...newFilters });
    };

    const clearSearch = () => {
        setOffset(0);
        setFilters({});
    };

    return {
        hasMore,
        refresh,
        search,
        clearSearch,
        isLoading,
        notificationList,
        retrieveNotificationList,
        applications,
    };
};
