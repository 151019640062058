import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as Driver from "driver.js";
import "driver.js/dist/driver.min.css";

let driver;
let promise;

/**
 * Returns a list of element id(s) without '#' sign in front
 *
 * @param steps
 * @returns {*[]}
 */
const getElementIds = (steps = []) => {
    return steps.map(step => {
        return step.element.replace("#", "");
    });
};

/**
 * This is the main hook to control the (driver.js) library in react way
 *
 * @param steps
 * @param resolvedCallback
 * @param driverName
 * @returns {{run: run}}
 */
export const useDriver = ({
    steps,
    mobileSteps,
    resolvedCallback,
    driverName,
    allowClose = false,
}) => {
    const [r, setR] = useState({});
    const isMobile = useMediaQuery("(max-width:991px)", { noSsr: true });

    useEffect(() => {
        driver = new Driver({
            allowClose,
            opacity: ".6",
            doneBtnText: "اتمام",
            closeBtnText: "بلدم !",
            nextBtnText: "بعدی",
            prevBtnText: "قبلی",
            onReset: () => {
                if (typeof resolvedCallback === "function") resolvedCallback();
            },
        });

        promise = new Promise(resolve => setR({ resolve }));

        // CleanUp
        return () => {
            driver = undefined;
            promise = undefined;
        };
    }, [allowClose, resolvedCallback]);

    // Use this effect to check if dom element is already loaded, in order to show the highlight
    useEffect(() => {
        if (r.resolve && !!document.getElementById(getElementIds(steps())[0])) {
            r.resolve();
            setR({ resolve: null });
        }
    }, [r, steps]);

    // Check if this driver is already viewed by the user, in order to prevent over show to user
    const consoleSettings = useSelector(
        state => state?.auth?.profile?.["console_settings"] ?? {},
    );
    const alreadyViewed = consoleSettings?.[driverName];

    const run = () => {
        if (alreadyViewed || !driver) return;

        promise?.then(() => {
            const finalSteps =
                typeof mobileSteps === "function" && isMobile
                    ? mobileSteps
                    : steps;

            driver.defineSteps(finalSteps(driver));
            driver.start();
        });
    };

    // call `run` method to display the highlight
    return { run, driver };
};
