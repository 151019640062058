/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterSelectionField from "@/components/utils/FilterSelectionField";

const SegmentFilterSelectionField = props => {
    const {
        title,
        placeholder,
        isCreatable,
        list,
        value,
        onChange,
        errorText,
        mapping,
    } = props;

    return (
        <>
            <FilterSelectionField
                title={title}
                placeholder={placeholder}
                isCreatable={isCreatable}
                list={list}
                input={{ value, onChange }}
                mapping={mapping}
            />
            {errorText && (
                <div style={{ fontSize: ".75rem", color: "#f44538" }}>
                    {errorText}
                </div>
            )}
        </>
    );
};

export default SegmentFilterSelectionField;
