import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplications } from "@/actions/applications";
import { selectApplication } from "@/actions/app";

/**
 * This is for calling global initial api calls (ones that should be called after any reload of first load)
 * This would get a variable (preventApiCalls) , this variable would be `inProgress` in
 * the privateLayout and ****** `inProgress` is get true when user token is get validated first
 *
 * Also the api calls that needed to be called on every platform change can be done in this hook
 */
export const useGlobalInitialApiCalls = preventApiCalls => {
    const dispatch = useDispatch();
    const [state, setState] = useState({ resolve: null });
    const platform = useSelector(state => state.app?.platform);
    /**
     * ****************************
     * Put any global api calls here
     * this callbacks only calls if existing token get validated
     */
    const apiCallbacks = useCallback(() => {
        fetchApplications({ platform })(dispatch);
    }, [dispatch, platform]);

    // If any api calls need to be called on platform change
    // put it here, this would also ensure to not call these
    // methods in the first `apiCallbacks` to prevent multiple calls
    const onPlatformChangeApiCalls = useCallback(() => {
        dispatch(selectApplication(null));

        fetchApplications({ platform })(dispatch);
    }, [dispatch, platform]);

    useEffect(() => {
        new Promise(resolve => setState({ resolve })).then(apiCallbacks);
    }, [apiCallbacks]);

    useEffect(() => {
        if (!preventApiCalls && state?.resolve) {
            state.resolve();
            setState({ resolve: null });
        }
    }, [preventApiCalls, state]);

    useEffect(() => {
        // Only call this if first `apiCallbacks` is done
        // This would run on every platform change
        if (!preventApiCalls && !state?.resolve) {
            onPlatformChangeApiCalls();
        }
    }, [onPlatformChangeApiCalls, platform, preventApiCalls, state]);
};
