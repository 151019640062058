let delayThrowToast = true;

class Debouncer {
    constructor(callback, timeout) {
        this.debounce(callback, timeout);
    }

    debounce(callback, timeout) {
        if (delayThrowToast) {
            const promise = new Promise(resolve => {
                callback();
                resolve();
            });

            promise
                .then(() => (delayThrowToast = false))
                .then(() =>
                    setTimeout(() => (delayThrowToast = true), timeout),
                );
        }
    }
}

export const debouncer = (callback, timeout) => {
    return new Debouncer(callback, timeout);
};
