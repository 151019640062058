import React from "react";
import css from "authentication/styles/noafarinSignUp.module.scss";
import {
    createValidator,
    isEmail,
    isRequired,
} from "@/components/utils/validation";
import { reduxForm } from "redux-form";
import { withErrorCatcher } from "@/components/utilities";
import {
    ACTION_PAGES,
    useHandleApi,
} from "authentication/noafarin/hooks/useHandleApi";
import SignInBanner from "authentication/noafarin/partials/SignInBanner";
import SignUpBanner from "authentication/noafarin/partials/SignUpBanner";
import NoafarinStartStep from "authentication/noafarin/partials/NoafarinStartStep";
import NoafarinSignInStep from "authentication/noafarin/partials/NoafarinSignInStep";
import NoafarinSignUnStep from "authentication/noafarin/partials/NoafarinSignUpStep";
import { useHandleSignOutUser } from "authentication/noafarin/hooks/useHandleSignOutUser";

const NoafarinAuthPage = props => {
    const { handleSubmit } = props;
    const {
        actionPage,
        inProgress,
        startRequest,
        signInRequest,
        signUpRequest,
        resendCodeRequest,
        goToSignInStep,
        error,
    } = useHandleApi(handleSubmit);

    useHandleSignOutUser();

    return (
        <div className={css.wrapper}>
            <div className={css.innerWrapper}>
                <div className={css.banner}>
                    {(actionPage === ACTION_PAGES.START ||
                        actionPage === ACTION_PAGES.SIGN_UP) && (
                        <SignUpBanner />
                    )}
                    {actionPage === ACTION_PAGES.SIGN_IN && <SignInBanner />}
                </div>

                <div className={css.block}>
                    <div className={css.innerBlock}>
                        {actionPage === ACTION_PAGES.START && (
                            <NoafarinStartStep
                                error={error}
                                handleSubmit={startRequest}
                                goToSignInStep={goToSignInStep}
                                inProgress={inProgress}
                            />
                        )}

                        {actionPage === ACTION_PAGES.SIGN_IN && (
                            <NoafarinSignInStep
                                error={error}
                                handleSubmit={signInRequest}
                                handleResend={resendCodeRequest}
                                inProgress={inProgress}
                            />
                        )}

                        {actionPage === ACTION_PAGES.SIGN_UP && (
                            <NoafarinSignUnStep
                                error={error}
                                handleSubmit={signUpRequest}
                                handleResend={resendCodeRequest}
                                goToSignInStep={goToSignInStep}
                                inProgress={inProgress}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const validate = createValidator({
    innovative_code: isRequired,
    verification_code: isRequired,
    email: [isRequired, isEmail],
});

const rf = reduxForm({
    form: "noafarin-auth",
    validate,
    destroyOnUnmount: true,
})(NoafarinAuthPage);
export default withErrorCatcher(rf);
