import React from "react";
import app_blank from "images/app-icon/app-blank.png";
import PropTypes from "prop-types";
import { withErrorCatcher } from "@/components/utilities";
import { WEB_PUSH_DOMAIN_FOR_HTTP } from "application/constants";

const AppName = ({
    app: { name, icon_url, domain, pushe_subdomain, app_id },
    isSmallSize,
    colSize,
}) => {
    const classes = !isSmallSize
        ? `table-col table-col--${colSize} table-col--right`
        : "small-appName";

    return (
        <div className={classes}>
            <div className="card-table" style={{ padding: "0 12px" }}>
                <img src={icon_url || app_blank} />

                <div className="card-table__text">
                    <span>{name}</span>
                    <p>{domain}</p>
                    <p>
                        {pushe_subdomain &&
                            pushe_subdomain + "." + WEB_PUSH_DOMAIN_FOR_HTTP}
                    </p>
                    <p>
                        <span style={{ color: "#ccc", fontSize: ".9rem" }}>
                            app_id:
                        </span>{" "}
                        {app_id}
                    </p>
                </div>
            </div>
        </div>
    );
};

AppName.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    colSize: PropTypes.number,
};

AppName.defaultProps = {
    app: {},
    colSize: 30,
};

export default withErrorCatcher(AppName);
