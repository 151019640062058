import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { reduxForm } from "redux-form";
import {
    createValidator,
    isDomainName,
    isRequired,
    isSubDomainName,
} from "@/components/utils/validation";
import WebsiteDetails from "application/create/web/partials/WebsiteDetails";

const WebAppCreateStep = props => {
    const { change } = props;

    return (
        <>
            <WebsiteDetails change={change} />
            {/*<WebGoogleAnalytics/>*/}
        </>
    );
};

const validators = createValidator({
    name: isRequired,
    domain: [isRequired, isDomainName],
    pushe_subdomain: isSubDomainName,
});

const WebAppCreateStepForm = reduxForm({
    form: "application_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebAppCreateStep);

export default withErrorCatcher(WebAppCreateStepForm);
