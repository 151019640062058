import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "styles/modules/_mod-input.scss";

export default class Input extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { onGetValue } = this.props;

        if (typeof onGetValue !== "undefined") {
            onGetValue(this.state.value);
        }
    }

    render() {
        const { className, placeholder, icon, style, submit, tip } = this.props;
        const inputClassProps = className ? className : "";
        const tipText = typeof tip !== "undefined" ? tip : false;
        return (
            <div
                className={classNames(`input ${inputClassProps}`, {
                    "input--icon": icon || submit,
                })}
                style={style}
            >
                <input
                    type="text"
                    placeholder={placeholder}
                    value={this.props.value || this.state.value}
                    onChange={
                        this.props.onChange ||
                        function (event) {
                            this.setState({ value: event.target.value });
                        }
                    }
                    onKeyUp={e => {
                        if (e.keyCode === 13) {
                            this.onSubmit();
                        }
                    }}
                    data-tip={tipText}
                    data-tip-disable={!tipText}
                />
                {submit && (
                    <button onClick={this.onSubmit} tabIndex={-1}>
                        {submit}
                    </button>
                )}
                {icon}
            </div>
        );
    }
}

Input.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onGetValue: PropTypes.func,
    onChange: PropTypes.func,
    tip: PropTypes.string /* tooltip to show on hover */,
    icon: PropTypes.element /** svg element as input icon    */,
    submit: PropTypes.element /** svg element for submit icon    */,
    value: PropTypes.any,
};
