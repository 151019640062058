import React from "react";
import BaseChart from "@/components/utils/charts/types/_base";
import isEmpty from "lodash/isEmpty";
import EmptyChartImagePlaceHolder from "@/components/utilities/emptyChartImagePlaceHolder";
import dailyStatistics from "images/charts/daily_statistics.jpeg";
import { FIRST_DAY_EMPTY_CHART_MESSAGE } from "@/constants";
const HighCharts = require("react-highcharts");
const uuid = require("uuid/v4");

export default class AllInstallationsChart extends BaseChart {
    constructor(props) {
        super(props);
    }
    chartConfig = series => {
        const isMultiple = !!series.length;
        const configs = this.chartsCommonConfigs(series);
        const { xAxis } = configs;

        return {
            ...configs,
            chart: {
                type: "line",
                height: "203px",
            },
            xAxis: {
                ...xAxis,
                labels: {
                    ...xAxis.labels,
                    enabled: !isMultiple,
                },
            },
            series: isMultiple ? [...series] : [series],
        };
    };

    render() {
        const series = this.getSeries("allInstallations");
        let isMultiple = series.length > 1;

        return (
            <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="card-g">
                    <header className="card-g__header">
                        <p className="card-g__title">
                            <svg>
                                <use xlinkHref="#svg_installed_all"></use>
                            </svg>
                            آمار روزانه نصب‌ها و کاربران
                        </p>
                        <span
                            className="more-info"
                            data-class="tooltip tooltip--long"
                            data-tip={`
                                نصب دردسترس: تعداد نصب هایی که در یک ماه اخیر با سرور پوشه در ارتباط بوده اند.

                                کاربر دردسترس: تعداد گوشی هایی که حداقل یکی از اپلیکیشن‌های شما را دارا هستند و در یک ماه اخیر با سرورهای پوشه ارتباط داشته اند.
                            `}
                        >
                            ؟
                        </span>
                    </header>
                    {isEmpty(series?.[0]?.[0]?.data) ? (
                        <EmptyChartImagePlaceHolder
                            src={dailyStatistics}
                            massage={FIRST_DAY_EMPTY_CHART_MESSAGE}
                        />
                    ) : (
                        series.map(chart => (
                            <div
                                className={`card-g__body ${
                                    isMultiple ? "small" : ""
                                }`}
                                key={uuid()}
                            >
                                {this.isLoading() || (
                                    <div className="chart">
                                        <HighCharts
                                            config={this.chartConfig(chart)}
                                        />
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </div>
        );
    }
}
