import React from "react";
import { Field, FormSection, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { ReduxSimpleTextField, withErrorCatcher } from "@/components/utilities";
import { createValidator, isRequired } from "@/components/utils/validation";
import { AUTOMATION_FORM_NAME } from "@/components/sms/constants";
import { useCreateSMSRemaining } from "@/components/sms/sms/create/general/hooks/useCreateSMSRemaining";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";

const WebAutomationStep2 = () => {
    const content =
        useReduxFormValues(AUTOMATION_FORM_NAME.web, [
            "notification.data.content",
        ])?.notification?.data?.content ?? "";
    const remainingElem = useCreateSMSRemaining({ content });

    return (
        <form className="notif-step2">
            <FormSection name="notification.data">
                <div className="step-block">
                    <header>
                        <p>محتوی پیامک</p>
                    </header>

                    <div className="step-block__column">
                        <Field
                            fullWidth
                            multiline
                            rows={4}
                            helperText={remainingElem}
                            name="content"
                            label="متن پیامک"
                            required
                            component={ReduxSimpleTextField}
                        />
                    </div>
                </div>
            </FormSection>
        </form>
    );
};

const validator = createValidator({
    notification: {
        data: {
            content: isRequired,
        },
    },
});

const AndroidAutomationStep2RF = reduxForm({
    form: AUTOMATION_FORM_NAME.web,
    validate: validator,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebAutomationStep2);

export default withRouter(withErrorCatcher(AndroidAutomationStep2RF));
