import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PagePlaceholder from "@/components/utilities/extra/PagePlaceholder";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import ReactTooltip from "react-tooltip";
import MobileIcon from "images/svg-inline/mobile.svg";

const AutomationPagePlaceholder = () => {
    return (
        <>
            <PagePlaceholder
                icon={<MobileIcon />}
                body={
                    <>
                        <h4>شما تا کنون هیچ نصب موفقی نداشته‌اید</h4>
                        <p>
                            شما هیچ نصب موفقی ندارید. ابتدا باید پوشه را در
                            اپلیکیشن خود قرار دهید. اگر در مورد نصب سوالی دارید
                            با ما تماس بگیرید.
                        </p>
                    </>
                }
                footer={
                    <FlatButton
                        className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                        rippleColor={blue900}
                        label={"آموزش نصب"}
                        onClick={() =>
                            window.open("https://pushe.co/docs", "_blank")
                        }
                    />
                }
            />
            <ReactTooltip className="tooltip" effect="solid" place="bottom" />
        </>
    );
};

export default withErrorCatcher(AutomationPagePlaceholder);
