import React from "react";
import {
    CircleLoader,
    FlatButton,
    withErrorCatcher,
} from "@/components/utilities";
import css from "authentication/styles/signin.module";
import googleLogo from "images/authentication/google-signin-logo.svg";

export default withErrorCatcher(props => {
    const { handleGoogleSignIn, handleSignIn, inProgress } = props;

    return (
        <div className={css.buttonsPart}>
            <FlatButton
                fullWidth
                label={"ورود به پوشه"}
                onClick={handleSignIn}
                endIcon={inProgress ? <CircleLoader /> : null}
                disabled={inProgress}
            />
            <FlatButton
                fullWidth
                label={"ورود با گوگل"}
                onClick={handleGoogleSignIn}
                startIcon={<img src={googleLogo} className={css.googleLogo} />}
            />
        </div>
    );
});
