
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Manifest = makeShortcode("Manifest");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`اضافه کردن کتابخانه`}</h1>
    <p>{`برای اضافه کردن کتابخانه پوشه و اجرای آن اسکریپت‌های زیر را در انتهای تگ
`}<em parentName="p">{`body`}</em>{`
(قبل از تگ `}<inlineCode parentName="p">{`<body/>‍`}</inlineCode>{`)
قرار دهید.`}</p>
    <Manifest {...props} mdxType="Manifest">
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<script src="https://static.pushe.co/pusheweb.js"></script>
<script>
  Pushe.init("{{appId}}");
  {{handle_bell}}
  Pushe.subscribe();
  {{handle_geolocation}}
</script>
`}</code></pre>
    </Manifest>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`نکته:`}</em>{`
در صورتی که در سایت‌تان یک فایل مجزا مثلا برای فوتر دارید که در تمامی صفحات از آن استفاده می‌شود ، می‌توانید اسکریپت‌های ذکر شده
را درون این فایل قرار دهید تا در تمامی صفحات این اسکریپت اجرا شود ، و در صورتی که صفحات سایت‌تان همگی از هم مجزا می‌باشند
باید اسکریپت‌ها را در تک تک صفحات قرار دهید.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "http://docs.pushe.co/docs/webpush/intro"
      }}>{`مشاهده راهنمای کامل وب‌پوش`}</a></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;