import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const CredentialDeleteConfirm = props => {
    const { open, onClose, onAccept } = props;

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={(_, reason) => {
                if (reason === "escapeKeyDown") onClose();
            }}
        >
            <DialogTitle id="confirmation-dialog-title">
                حذف کریدنشیال
            </DialogTitle>
            <DialogContent>
                آیا از حذف این کریدنشیال اطمینان دارید؟
                <br />
                در صورت حذف این کریدنشیال اطلاعات و نصب های مربوط به آن غیر قابل
                استفاده و بازگردانی می‌باشد.
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    خیر
                </Button>
                <Button onClick={onAccept} color="primary">
                    بلی
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CredentialDeleteConfirm;
