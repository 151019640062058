import actionTypes from "@/actions/_types";

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.CREDENTIAL.FETCH_CREDENTIALS:
            return [...action.payload];
        case actionTypes.CREDENTIAL.CREATE_CREDENTIALS:
            return [...state, action.payload];
        case actionTypes.CREDENTIAL.DELETE_CREDENTIALS:
            return state.filter(i => i.pk !== action.id);
        default:
            return state;
    }
}
