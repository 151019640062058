/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import classNames from "classnames";
import FlatButton from "material-ui/FlatButton";
import { blue900, red900 } from "material-ui/styles/colors";
import { CircleLoader } from "@/components/utilities";
import { errorToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import "cropperjs/dist/cropper.css";

export const UPLOAD_IMAGE_TYPE = {
    ICON: "icon",
    IMAGE: "image",
    PICTURE: "picture",
    NATIONAL_CARD: "nationalcard",
    PROMPT_ICON: "prompt-icon",
};

const BOX_STATES = {
    PLACEHOLDER: 1,
    CROPPER: 2,
    IMAGE: 3,
};

/**
 * Image Uploader with cropper
 *
 * @param props
 * @constructor
 */
const ImageUploader = props => {
    const {
        imageUrl,
        multiple = false,
        aspectRatio = NaN,
        maxSize = 1000000,
        title = "انتخاب عکس",
        toggleUploaderProgress,
        subtitle = "عکس را به اینجا بکشید و یا روی اینجا کلیک کنید",
        accept = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml"],
    } = props;

    // init cropper ref
    const cropperRef = useRef(null);

    // states
    const [file, saveFile] = useState(null);
    const [boxState, setBoxState] = useState(BOX_STATES.PLACEHOLDER);
    const [isUploading, toggleIsUploading] = useState(false);

    // Only runs once when mounting
    useEffect(() => {
        if (!!imageUrl) {
            setBoxState(BOX_STATES.IMAGE);
        }
        if (!imageUrl && boxState === BOX_STATES.IMAGE) {
            setBoxState(BOX_STATES.PLACEHOLDER);
        }
    }, [imageUrl]);

    /**
     * Toggle inprogress state of this file uploader
     * if **toggleIsUploading props is provided
     * use boxState & isUploading to check the state
     */
    useEffect(() => {
        if (toggleUploaderProgress) {
            if (boxState === BOX_STATES.CROPPER || isUploading) {
                toggleUploaderProgress(true);
            } else {
                toggleUploaderProgress(false);
            }
        }
    }, [boxState, isUploading]);

    // Dropzone's hook
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        maxSize,
        multiple,
        disabled: boxState !== BOX_STATES.PLACEHOLDER,
        onDropAccepted: files => {
            saveFile({
                ...files[0],
                preview: URL.createObjectURL(files[0]),
            });
        },
        onDropRejected: files => {
            // MaxSize exceed error
            if (files[0].size > maxSize) {
                errorToast(
                    TOAST_CONTENT.FILE_SIZE_EXCEEDED.TITLE,
                    TOAST_CONTENT.FILE_SIZE_EXCEEDED.DESC,
                );
                return;
            }
            // accept type error
            if (!!accept) {
                if (
                    (Array.isArray(accept) &&
                        accept.indexOf(files[0].type) < 0) ||
                    files[0].type !== accept
                ) {
                    errorToast(
                        TOAST_CONTENT.FILE_SELECT_ERROR.TITLE,
                        TOAST_CONTENT.FILE_SELECT_ERROR.DESC,
                    );
                }
            }
        },
    });

    /**
     * Called in Cropping mode
     *
     * 1. Create a DataURL from the file
     * 2. Upload file to server with **uploadFile** props
     * 3. If successfully uploaded:
     *      - Call **onFileUploaded** props to update redux forms's field
     *      - Toggle isUploading inner state to false
     *      - Set box state to BOX_STATE_IMAGE
     */
    const onUploadImage = () => {
        const { uploadFile, onFileUploaded, type } = props;
        let dataUrl;

        if (cropperRef.current) {
            dataUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
            // Disable cropper to prevent it from resizing when is uploading
            cropperRef.current.cropper.disable();
        }
        if (dataUrl) {
            toggleIsUploading(true);
            uploadFile(
                type,
                dataUrl,
                data => {
                    onFileUploaded(data.url);
                    toggleIsUploading(false);
                    setBoxState(BOX_STATES.IMAGE);
                    destroyCropBox();
                },
                () => {
                    toggleIsUploading(false);
                },
            );
        }
    };

    const destroyCropBox = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.clear();
            cropperRef.current.cropper.destroy();
        }
    };

    /**
     * Called in Image mode
     *
     * 1. Delete file from inner state
     * 2. Change Box state to BOX_STATE_PLACEHOLDER
     * 3. Call onRemoveFile props to empty the redux form's field
     */
    const onDeleteImage = () => {
        const { onRemoveFile } = props;
        saveFile(null);
        onRemoveFile(UPLOAD_IMAGE_TYPE.ICON);
        setBoxState(BOX_STATES.PLACEHOLDER);
        destroyCropBox();
    };

    /**
     * Called in Cropping mode
     *
     * 1. Remove file from inner state
     * 2. Set box state to BOX_STATES.PLACEHOLDER
     */
    const onChangeImage = () => {
        saveFile(null);
        setBoxState(BOX_STATES.PLACEHOLDER);
        destroyCropBox();
    };

    return (
        <div
            {...getRootProps({
                className: classNames(
                    "file-upload",
                    {
                        "file-upload--is-placeholder":
                            boxState === BOX_STATES.PLACEHOLDER,
                    },
                    {
                        "file-upload--is-cropper":
                            boxState === BOX_STATES.CROPPER,
                    },
                    { "file-upload--is-image": boxState === BOX_STATES.IMAGE },
                ),
            })}
        >
            <input {...getInputProps()} />

            {/* PLACEHOLDER */}
            {boxState === BOX_STATES.PLACEHOLDER && (
                <div className="file-upload__placeholder">
                    <i className="file-upload__dashed-box" />
                    <p className="file-upload__title">{title}</p>
                    {!!subtitle && (
                        <p className="file-upload__subtitle">{subtitle}</p>
                    )}
                </div>
            )}

            {/* CROPPER */}
            {boxState !== BOX_STATES.IMAGE && (
                <Cropper
                    viewMode={2}
                    guides={true}
                    autoCropArea={0.9}
                    responsive={true}
                    ref={cropperRef}
                    aspectRatio={aspectRatio}
                    className="file-upload__cropper"
                    src={!!file ? file.preview : ""}
                    ready={() => setBoxState(BOX_STATES.CROPPER)}
                />
            )}

            {/* BUTTONS */}
            {boxState !== BOX_STATES.PLACEHOLDER && (
                <div className="file-upload__buttons">
                    {boxState === BOX_STATES.IMAGE ? (
                        <FlatButton
                            className="mui-btn mui-btn--sm mui-btn--solid mui-btn--blue"
                            rippleColor={red900}
                            label="حذف عکس"
                            onClick={onDeleteImage}
                        />
                    ) : (
                        <>
                            <FlatButton
                                className="mui-btn mui-btn--sm mui-btn--solid mui-btn--blue"
                                rippleColor={blue900}
                                label="آپلود"
                                onClick={onUploadImage}
                                disabled={isUploading}
                            />
                            <FlatButton
                                className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                                rippleColor={blue900}
                                label="تغییر  عکس"
                                onClick={onChangeImage}
                                disabled={isUploading}
                            />
                        </>
                    )}
                </div>
            )}

            {/* Uploaded Image */}
            {boxState === BOX_STATES.IMAGE && (
                <div
                    className="file-upload__image"
                    style={
                        imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}
                    }
                />
            )}

            {/* Uploading Progress Loader */}
            {isUploading && <CircleLoader />}
        </div>
    );
};

export { ImageUploader };
