export default {
    APP: {
        CHANGE_PLATFORM: "change_platform",
        SELECT_APPLICATION: "select_application",
        OPEN_MODAL: "open_modal",
        CLOSE_MODAL: "close_modal",
        TOGGLE_DEMO: "toggle_demo",
        INPROG_DONE: "inprog_done",
        INPROG_ERROR: "inprog_error",
        HAS_UPDATE: "has_update",
        REPORT: "report",
        DISPLAY_PERMISSION_ERROR: "display_permission_error",
    },
    APPLICATION: {
        FETCH_ANDROID_APPLICATIONS: "fetch_android_applications",
        FETCH_IOS_APPLICATIONS: "fetch_ios_applications",
        FETCH_WEB_APPLICATIONS: "fetch_web_applications",
        CREATE_ANDROID_APPLICATION: "create_android_applications",
        CREATE_IOS_APPLICATION: "create_ios_applications",
        CREATE_WEB_APPLICATION: "create_web_applications",
        UPDATE_ANDROID_APPLICATION: "update_android_applications",
        UPDATE_IOS_APPLICATION: "update_ios_applications",
        UPDATE_WEB_APPLICATION: "update_web_applications",
        DELETE_ANDROID_APPLICATION: "delete_android_application",
        DELETE_IOS_APPLICATION: "delete_ios_application",
        DELETE_WEB_APPLICATION: "delete_web_application",
        SAVE_APPLICATION_Id_IN_PROGRESS: "save_application_id_in_progress",
        GET_APPLICATION_VERSION: "get_application_version",
        GET_APPLICATION_DEVICE_TYPES: "get_application_device-types",
        GET_APPLICATION_BROWSER: "get_application_browser",
        GET_APPLICATION_TOPICS: "get_application_topics",
        GET_APPLICATION_TAGS: "get_application_tags",
        GET_TAG_KEYS: "get_tag_keys",
        SELECT_TAG: "select_tag",
        GET_TAG_VALUES: "get_tag_values",
        SAVE_APPLICATION: "save_application",
    },
    DASHBOARD: {
        FETCH_ANDROID_DASHBOARD_DATA: "fetch_android_dashboard_data",
        FETCH_IOS_DASHBOARD_DATA: "fetch_ios_dashboard_data",
        FETCH_WEB_DASHBOARD_DATA: "fetch_web_dashboard_data",
    },
    FINANCIAL: {
        CHANGE_PLAN: "change_plan",

        FETCH_INVOICE_ITEMS: "fetch_invoice_items",
        FETCH_INVOICE_DETAILS: "fetch_invoice_details",
        CLEAR_FETCHED_INVOICES_STORE: "clear_fetched_invoices_store",
    },
    HINT: {
        FETCH_ANDROID_HINTS: "fetch_web_hints",
        FETCH_IOS_HINTS: "fetch_web_hints",
        FETCH_WEB_HINTS: "fetch_web_hints",
    },
    AUTH: {
        SIGN_IN: "sign_in",
        SIGN_IN_DESKPRO: "sign_in_deskpro",
        SIGN_UP: "sign_up",
        SIGN_OUT: "sign_out",
        FETCH_PROFILE: "fetch_profile",
        UPDATE_CONSOLE_SETTINGS: "update_console_settings",
    },
    INSTALLATION: {
        FETCH_ANDROID_INSTALLATIONS: "fetch_android_installations",
        SEARCH_ANDROID_INSTALLATIONS: "search_android_installations",
        FETCH_IOS_INSTALLATIONS: "fetch_ios_installations",
        SEARCH_IOS_INSTALLATIONS: "search_ios_installations",
        FETCH_WEB_INSTALLATIONS: "fetch_web_installations",
        SEARCH_WEB_INSTALLATIONS: "search_web_installations",
        SEND_TEST_NOTIFICATION: "send_test_notification",
        FAVE_ANDROID_INSTALLATIONS: "fave_android_installations",
        FAVE_IOS_INSTALLATIONS: "fave_ios_installations",
        FAVE_WEB_INSTALLATIONS: "fave_web_installations",
        FAVE_WEB_UPDATE: "fave_web_update",
        UNFAVE_ANDROID_DEVICE: "unfave_android_device",
        UNFAVE_IOS_DEVICE: "unfave_ios_device",
        UNFAVE_WEB_DEVICE: "unfave_web_device",
        FETCH_ANDROID_FAVORITES: "fetch_android_favorites",
        FETCH_IOS_FAVORITES: "fetch_ios_favorites",
        FETCH_WEB_FAVORITES: "fetch_web_favorites",
        FAVE_INSTALLATION_IN_PROGRESS: "fave_installation_in_progress",

        FETCH_ANDROID_FAVORITES_INSTALLATIONS:
            "fetch_android_favorites_installations",
        FETCH_IOS_FAVORITES_INSTALLATIONS: "fetch_ios_favorites_installations",
        FETCH_WEB_FAVORITES_INSTALLATIONS: "fetch_web_favorites_installations",

        RETRIEVE_INSTALL: "retrieve_install",
    },
    NOTIFICATION: {
        NOTIFICATION_STEP: "notification_step",
        FETCH_STATISTICS: "fetch_notification_statistics",
        // Fetching a chunk of notification
        FETCH_ANDROID_NOTIFICATIONS: "fetch_android_notifications",
        FETCH_IOS_NOTIFICATIONS: "fetch_ios_notifications",
        FETCH_WEB_NOTIFICATIONS: "fetch_web_notifications",

        UPDATE_ANDROID_NOTIFICATION: "update_android_notification",
        UPDATE_IOS_NOTIFICATION: "update_ios_notification",
        UPDATE_WEB_NOTIFICATION: "update_web_notification",

        SEARCH_ANDROID_NOTIFICATIONS: "search_android_notifications",
        SEARCH_IOS_NOTIFICATIONS: "search_ios_notifications",
        SEARCH_WEB_NOTIFICATIONS: "search_web_notifications",

        CREATE_ANDROID_NOTIFICATION: "create_android_notifications",
        CREATE_IOS_NOTIFICATION: "create_ios_notifications",
        CREATE_WEB_NOTIFICATION: "create_web_notifications",

        ESTIMATE_NOTIFICATION: "estimate_notification",

        SAVE_NOTIFICATION: "save_notification",

        DELETEALL_ANDROID_NOTIFICATION: "deleteall_android_application",
        DELETEALL_IOS_NOTIFICATION: "deleteall_ios_application",
        DELETEALL_WEB_NOTIFICATION: "deleteall_web_application",

        FETCH_WEB_DRAFTS: "fetch_web_draft",
        SEND_WEB_DRAFT: "send_web_draft",
        UPDATE_WEB_DRAFT: "update_web_draft",
    },
    CONFIG: {
        EMAIL: {
            FETCH_CONFIG_EMAIL: "fetch_config_email",
            UPDATE_CONFIG_EMAIL: "update_config_email",
            CREATE_CONFIG_EMAIL: "create_config_email",
        },
        SMS: {
            FETCH_CONFIG_SMS: "fetch_config_sms",
            UPDATE_CONFIG_SMS: "update_config_sms",
            CREATE_CONFIG_SMS: "create_config_sms",
        },
    },
    EMAIL: {
        RETRIEVE_ANDROID_EMAIL_LIST: "retrieve_android_email_list",
        RETRIEVE_EMAIL: "retrieve_email",
    },

    AUTOMATION: {
        RETRIEVE_AUTOMATION_LIST: "retrieve_automation_list",
        RETRIEVE_AUTOMATION: "retrieve_automation",
        CREATE_AUTOMATION: "create_automation",
        UPDATE_AUTOMATION: "update_automation",
        DELETE_AUTOMATION: "delete_automation",
        UPDATE_AUTOMATION_LOCAL_STATE: "update_automation_local_state",
        CLEAR_FETCHED_AUTOMATION_ITEMS: "clear_fetched_automation_items",
    },

    GEOFENCE: {
        RETRIEVE_GEOFENCE_LIST: "retrieve_geofence_list",
        RETRIEVE_GEOFENCE: "retrieve_geofence",
        CREATE_GEOFENCE: "create_geofence",
        UPDATE_GEOFENCE: "update_geofence",
        DELETE_GEOFENCE: "delete_geofence",
        UPDATE_GEOFENCE_LOCAL_STATE: "update_geofence_local_state",
        CLEAR_FETCHED_GEOFENCE_ITEMS: "clear_fetched_geofence_items",
    },

    CREDENTIAL: {
        FETCH_CREDENTIALS: "fetch_credentials",
        DELETE_CREDENTIALS: "delete_credential",
        CREATE_CREDENTIALS: "create_credentials",
    },

    ALERTS: {
        FETCH_ALERTS: "fetch_alerts",
        READ_ALL: "read_all",
        READ_ALERT: "read_alert",
    },

    PLANS: {
        FREE: "Free",
        PROFESSIONAL: "Professional",
        ENTERPRISE: "Enterprise",
    },

    LOADING: {
        APPLICATION: "application_is_loading",
        INSTALLATION: "installation_is_loading",
        CREATE_APPLICATION: "create_application_loading",
        REFRESH_API_TOKEN: "refresh_api_token_loading",
        APK_LIST: "apk_list_loading",
    },
    Web: {
        SEGMENTATION: {
            CREATE_SEGMENTATION: "create_segmentation",
            UPDATE_SEGMENTATION: "update_segmentation",
            DELETE_SEGMENTATION: "delete_segmentation",
            FETCH_SEGMENTATION: "fetch_segmentation",
        },
    },
    SEGMENTATION: {
        CREATE_SEGMENTATION: "create_segmentation",
        UPDATE_SEGMENTATION: "update_segmentation",
        DELETE_SEGMENTATION: "delete_segmentation",
        FETCH_SEGMENTATION: "fetch_segmentation",
    },
};

export const MODAL_TYPES = {
    FIRST_TIME_LOGIN: {
        HELLO_WORLD: 1,
    },
    APPLICATION: {
        NEW: 100,
        TUTORIAL: 101,
        DELETE: 102,
        UPDATE_RENAME: 103,
        TRANSFER: 104,
        INSTALLATION_HISTORY: 105,
        SELECTING_FRAMEWORK: 106,
        ACTIVE_WELCOME_NOTIFICATION: 107,
        RESEND_TRANSFER_EMAIL: 108,
        VERIFY_TRANSFER_EMAIL: 109,
        DEMO_REQUEST_TRANSFER: 110,
        MARKET: 111,
        APP_TOKEN: 112,
    },
    NOTIFICATION: {
        NEW: 200,
        DELETE: 202,
        RESEND: 203,
        REPORT_URL: 204,
        CREATE_REPORT_URL: 205,
        REMOVE_REPORT_URL: 206,
        REACH_MAX_CAPACITY: 207,
        REACHING_MAX_CAPACITY: 208,
    },
    INSTALLATION: {
        FAVORITE: 300,
        UNFAVORITE: 301,
    },
    MISC: {
        REPORT: 400,
        TOGGLE_DEMO: 401,
    },
    ALERTS: {
        CONTENT: 500,
    },
    FINANCIAL: {
        CHANGE_TO_PRO_PLAN: 600,
        CHANGE_TO_FREE_PLAN: 601,
    },
};

export const PLATFORMS = {
    ANDROID: "android",
    IOS: "ios",
    WEB: "web",
};

export const PLATFORMS_ID = {
    1: "android",
    2: "web",
    3: "ios",
};

export const NOTIFICATION_MODE = {
    TEST: 0,
    NORMAL: 1,
    WELCOME: 2,
    UPDATE: 3,
    DRAFT: 4,
};

export const MODAL_THEME = {
    RED: "red",
    BLUE: "blue",
    BLACK: "black",
    WHITE: "white",
};

// application framework list
// values are server side don't change them

export const ANDROID_FRAMEWORKS = {
    NAMES: {
        androidstudio: 2,
        unity: 4,
        reactnative: 6,
        flutter: 9,
    },
    NUMBERS: {
        2: "AndroidStudio",
        6: "ReactNative",
        4: "Unity",
        9: "Flutter",
    },
    _getName: function (param) {
        let num =
            !!param && typeof Number(param) === "number" ? Number(param) : 0;
        if (num in this.NUMBERS) return this.NUMBERS[num];
    },
    _getNumber: function (param) {
        let name = param.toLowerCase();
        if (name in this.NAMES) return this.NAMES[name];
    },
};

export const IOS_FRAMEWORKS = {
    NAMES: {
        reactnative: 6,
        xcode: 8,
        flutter: 9,
    },
    NUMBERS: {
        6: "ReactNative",
        8: "Xcode",
        9: "Flutter",
    },
    _getName: function (param) {
        let num =
            !!param && typeof Number(param) === "number" ? Number(param) : 0;
        if (num in this.NUMBERS) return this.NUMBERS[num];
    },
    _getNumber: function (param) {
        let name = param.toLowerCase();
        if (name in this.NAMES) return this.NAMES[name];
    },
};

export const FRAMEWORK_CAST_VALUE_INTO_NAME = {
    undefined: "",
    0: "Default",
    1: "Cordova",
    2: "AndroidStudio",
    3: "Basic4Android",
    4: "Unity",
    5: "Eclipse",
    6: "ReactNative",
    7: "AppBuilder",
    8: "Xcode",
    9: "Flutter",
};

export const FRAMEWORK_CAST_NAME_INTO_VALUE = {
    Cordova: 1,
    AndroidStudio: 2,
    Basic4Android: 3,
    Unity: 4,
    Eclipse: 5,
    ReactNative: 6,
    AppBuilder: 7,
    Xcode: 8,
    Flutter: 9,
};

export const FRAMEWORK_CAST_VALUE_INTO_TUTORIAL_LINK = {
    1: "https://pushe.co/docs/cordova/",
    2: "https://pushe.co/docs/android-studio/",
    3: "https://pushe.co/docs/basic4Android/",
    4: "https://pushe.co/docs/unity/",
    5: "https://pushe.co/docs/eclipse/",
    6: "https://pushe.co/docs/react-native/",
    7: "https://pushe.co/docs/app-builders/",
    8: "https://pushe.co/docs/ios/",
    9: "https://pushe.co/docs/webpush/",
};

export const FRAMEWORK_CAST_VALUE_INTO_STEP_COUNT = {
    1: 5,
    2: 5,
    3: 5,
    4: 4,
    5: 6,
    6: 1,
    7: 2,
    8: 1,
    9: 3,
};
