/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    paperRoot: {
        backgroundColor: theme.palette.dashboard.white,
    },
    root: {
        color: theme.palette.dashboard.black,
        fontFamily: theme.typography.fontFamily.bold,
    },
    indicator: {
        backgroundColor: theme.palette.dashboard.blue,
    },
    tabRoot: {
        minWidth: "unset",
        color: theme.palette.dashboard.black,
        fontSize: ".87rem",
        whiteSpace: "nowrap",
    },
}));

const CardListBaseTabs = props => {
    const { tabs, tabProps } = props;
    const classes = useStyles();

    const [selected, toggleSelected] = useState(tabs?.[0]?.value);

    const selectedTab = tabs.find(t => t.value === selected);
    const Component = selectedTab?.component ?? React.Fragment;

    useEffect(() => {
        // this would select tab that is not disabled
        toggleSelected(tabs.filter(t => !t.disabled(tabProps))[0]?.value);
    }, []);

    return (
        <>
            <Paper square classes={{ root: classes.paperRoot }} elevation={0}>
                <Tabs
                    value={selected}
                    onChange={(_, value) => toggleSelected(value)}
                    variant="fullWidth"
                    aria-label="Filter Tabs"
                    classes={{
                        indicator: classes.indicator,
                        root: classes.root,
                    }}
                >
                    {tabs.map(tab => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                            disabled={tab.disabled(tabProps)}
                            classes={{ root: classes.tabRoot }}
                        />
                    ))}
                </Tabs>
            </Paper>

            <div
                className={classNames(
                    "card-list__tab-body",
                    selectedTab?.wrapperClassName,
                )}
            >
                {selectedTab?.display() && <Component {...tabProps} />}
            </div>
        </>
    );
};

export default withErrorCatcher(CardListBaseTabs);
