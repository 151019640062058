import React, { Fragment } from "react";
import PageLoader from "@/components/utils/PageLoader";
import { normalize } from "@/components/utils/normalize";
import { useQuery } from "react-query";
import AndroidSMSWizard from "@/components/sms/sms/create/android/AndroidSMSWizard";
import { fetchSMS, SMS_KEYS } from "@/queries/sms";
import { smsForwardNormalizer } from "@/components/sms/normalize";
import { withSMSCredential } from "@/components/utilities/conf/withCredentialWrapper";
import WebSMSWizard from "@/components/sms/sms/create/web/WebSMSWizard";

const ForwardSMSPage = ({ match: { params }, history }) => {
    const wrapperId = params.id;
    const platform = params.platform;

    const { data, isError, refetch } = useQuery(
        [SMS_KEYS.GET(), wrapperId],
        fetchSMS,
        { cacheTime: 0, staleTime: Infinity },
    );

    const PlatformWizard = platform ? (
        PLATFORM_NOTIF_FORMS[platform]
    ) : (
        <Fragment />
    );

    return (
        <PageLoader
            history={history}
            perform={onFail => (isError ? onFail() : refetch())}
            isReady={!!data}
        >
            {data && (
                <PlatformWizard
                    history={history}
                    sms={normalize(data, smsForwardNormalizer)}
                />
            )}
        </PageLoader>
    );
};

const PLATFORM_NOTIF_FORMS = {
    android: AndroidSMSWizard,
    web: WebSMSWizard,
};

export default withSMSCredential(ForwardSMSPage);
