import React from "react";
import { pagePermissionWrapper, PERMISSIONS } from "@/components/utilities";
import AndroidNotificationAutomationList from "@/components/notifications/automation/list/android/AndroidNotificationAutomationList";
import WebNotificationAutomationList from "@/components/notifications/automation/list/web/WebNotificationAutomationList";

const SMSAutomationListPage = props => {
    const {
        match: { params },
        ...otherProps
    } = props;

    const NotificationAutomationPage = NOTIFICATIONS_PAGE[params.platform];

    return <NotificationAutomationPage {...otherProps} />;
};

const NOTIFICATIONS_PAGE = {
    android: AndroidNotificationAutomationList,
    web: WebNotificationAutomationList,
};

export default pagePermissionWrapper(
    PERMISSIONS.NOTIFICATION_AUTOMATION_LIST,
    SMSAutomationListPage,
);
