
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`نصب کتابخانه پوشه`}</h1>
    <p>{`برای نصب کتابخانه پوشه نیازمند استفاده از
`}<a parentName="p" {...{
        "href": "https://cocoapods.org/"
      }}>{`CocoaPods`}</a>{`
می‌باشید.`}</p>
    <p>{`در `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{`
برای `}<inlineCode parentName="p">{`Target`}</inlineCode>{`
اصلی و
`}<inlineCode parentName="p">{`NotificationServiceExtension`}</inlineCode>{`
پوشه را مطابق زیر اضافه کنید.`}</p>
    <pre><code parentName="pre" {...{}}>{`target 'PusheDemo' do
    use_frameworks!
        pod 'Pushe', \`1.0.5\`

    target 'PusheDemoTests' do
        inherit! :search_paths
        # Pods for testing
    end

    target 'PusheDemoUITests' do
        inherit! :search_paths
        # Pods for testing
    end

end

target 'DemoNotificationServiceExtension' do
    use_frameworks!
        pod 'Pushe', 1.0.5
end
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;