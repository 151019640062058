import React from "react";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import SelectAppPlaceholder from "@/components/utils/charts/SelectAppPlaceholder";
import CustomChart from "@/components/utils/NewCharts/CustomChart";
import { CHART_LIST } from "@/components/utils/NewCharts/common";
import { getDataSeries } from "./common";
import { options } from "./optionsAllInstalChart";

const PieCu = props => {
    const {
        showPlaceholderOnAll,
        svg,
        description,
        appId,
        title,
        data = {},
    } = props;
    let generateDataChart = getDataSeries(data);
    const dataCharts = {
        labels: generateDataChart?.labels,
        data: [generateDataChart],
    };
    return (
        <div className="card-g">
            <header className="card-g__header">
                <p className="card-g__title">
                    <svg>
                        <use xlinkHref={svg}></use>
                    </svg>
                    {title || "Set Title"}
                </p>
            </header>
            <div className="card-g__body">
                <p>{description}</p>
                {generateDataChart?.data.length > 0 ? (
                    appId === "all" && showPlaceholderOnAll ? (
                        <SelectAppPlaceholder />
                    ) : (
                        <div className="chart">
                            <CustomChart
                                height={300}
                                options={options}
                                dataChart={dataCharts}
                                typeChart={CHART_LIST.Doughnut.name}
                            />
                        </div>
                    )
                ) : (
                    <CircleLoader />
                )}
            </div>
        </div>
    );
};
export default withErrorCatcher(PieCu);
