import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const CardHeader = props => {
    const {
        children, // either use children or (StatusComponent + DateTimeComponent)
        StatusComponent,
        DateTimeComponent,
        statusProps = {},
        dateTimeProps = {},
    } = props;

    return (
        <div className="card-list__card-header">
            {children || (
                <div className="card-list__card-header-bar">
                    {StatusComponent ? (
                        <StatusComponent {...statusProps} />
                    ) : (
                        <div className="card-list__card-status" />
                    )}

                    {DateTimeComponent ? (
                        <DateTimeComponent {...dateTimeProps} />
                    ) : (
                        <div className="card-list__card-datetime" />
                    )}
                </div>
            )}
        </div>
    );
};

export default withErrorCatcher(CardHeader);
