import React from "react";
import logo from "images/svg/logo.svg";
import css from "authentication/styles/signup.module.scss";

function SignUpSuccess() {
    return (
        <div className={css.successBlock}>
            <div className={css.successLogoWrapper}>
                <img src={logo} alt="Logo" />
            </div>
            <p className={css.successTopParagraph}>
                ایمیل تایید برای شما ارسال شد ، ‌لطفا به صندوق ایمیل خود مراجعه
                کنید.
            </p>
            <p className={css.successBottomParagraph}>
                در صورتی که قبلا در پوشه عضو شده اید از طریق
                <span
                    className={css.successSignInLink}
                    onClick={() => (location.href = "/signin")}
                >
                    صفحه لاگین{" "}
                </span>
                می توانید به داشبورد خود وارد شوید.
            </p>
        </div>
    );
}

export default SignUpSuccess;
