import React from "react";
import css from "authentication/styles/signin.module";
import SignInBanner from "authentication/signin/partials/SignInBanner";
import SignInForm from "authentication/signin/partials/SignInForm";
import logo from "images/svg/pushe-logo-horizontal.svg";
import {
    createValidator,
    isEmail,
    isRequired,
} from "@/components/utils/validation";
import { reduxForm } from "redux-form";
import { withErrorCatcher } from "@/components/utilities";
import SignInButtons from "authentication/signin/partials/SignInButtons";
import { useSignIn } from "authentication/signin/hooks/useSignIn";
import { Link } from "react-router-dom";
import SignIn2FA from "authentication/signin/partials/SignIn2FA";
import { useHandleSignedInUser } from "authentication/signin/hooks/useHandleSignedInUser";

const SignInPage = props => {
    const { handleSubmit, history, enabled2fa } = props;

    const {
        inProgress,
        enabled2fa: enabled2Step,
        signInError,
        handleSignIn,
        goBackFrom2FAPage,
        handleGoogleSignIn,
    } = useSignIn(handleSubmit);

    useHandleSignedInUser(history);

    const hasTwoStep = enabled2fa || enabled2Step;

    return (
        <div className={css.wrapper}>
            <div className={css.innerWrapper}>
                <div className={css.banner}>
                    <SignInBanner />
                </div>
                <div className={css.block}>
                    <div className={css.signInLogo}>
                        <img src={logo} alt={"pushe logo"} />
                    </div>

                    {hasTwoStep && (
                        <SignIn2FA goBackFrom2FAPage={goBackFrom2FAPage} />
                    )}

                    {!hasTwoStep && (
                        <>
                            <div className={css.goToSignUpWrapper}>
                                <span>در پوشه حساب کاربری ندارید؟</span>
                                <Link to={"/signup"}>شروع کنید</Link>
                            </div>

                            <SignInForm handleSignIn={handleSignIn} />
                            <SignInButtons
                                handleGoogleSignIn={handleGoogleSignIn}
                                inProgress={inProgress}
                                handleSignIn={handleSignIn}
                            />

                            <Link
                                className={css.goBackLink}
                                to={"/forgot-password"}
                            >
                                رمز عبور خود را فراموش کرده اید؟
                            </Link>

                            {signInError && (
                                <div className={css.signInError}>
                                    {signInError}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const validate = createValidator({
    email: [isRequired, isEmail],
    password: isRequired,
    recaptcha: isRequired,
});

const rf = reduxForm({ form: "signIn", validate, destroyOnUnmount: true })(
    SignInPage,
);

export default withErrorCatcher(rf);
