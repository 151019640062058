import React, { Fragment } from "react";
import PageLoader from "@/components/utils/PageLoader";
import { NOTIFICATION_MODE } from "@/actions/_types";
import { normalize } from "@/components/utils/normalize";
import { editNotificationNormalizer } from "@/components/notifications/notification/normalize";
import WebNotifWizard from "@/components/notifications/notification/create/web/WebNotifWizard";
import { retrieveNotification } from "@/actions/notifications";
import { useDispatch, useSelector } from "react-redux";

const EditDraftPage = ({ match: { params }, history }) => {
    const dispatch = useDispatch();
    const notificationId = params.notificationId;
    const platform = params.platform;

    const notification = useSelector(
        state => state?.inprog?.notifications?.[notificationId],
    );
    const PlatformWizard = platform ? (
        PLATFORM_NOTIF_FORMS[platform]
    ) : (
        <Fragment />
    );

    return (
        <PageLoader
            history={history}
            perform={onFail =>
                retrieveNotification(
                    { notificationId, showErrorToast: true },
                    null,
                    onFail,
                )(dispatch)
            }
            isReady={!!notification}
        >
            {notification && (
                <PlatformWizard
                    history={history}
                    notification={normalize(
                        notification,
                        editNotificationNormalizer,
                    )}
                    notificationMode={NOTIFICATION_MODE.DRAFT}
                />
            )}
        </PageLoader>
    );
};

const PLATFORM_NOTIF_FORMS = {
    web: WebNotifWizard,
};

export default EditDraftPage;
