import actionTypes from "@/actions/_types";

const initial_state = {
    appLoading: true,
    installationLoading: false,
    createApplicationLoading: false,
    refreshApiTokenLoading: false,
    apkListLoading: false,
};

export default function (state = initial_state, action) {
    switch (action.type) {
        case actionTypes.LOADING.APPLICATION:
            return {
                ...state,
                appLoading: action.payload,
            };
        case actionTypes.LOADING.INSTALLATION:
            return {
                ...state,
                installationLoading: action.payload,
            };
        case actionTypes.LOADING.CREATE_APPLICATION:
            return {
                ...state,
                createApplicationLoading: action.payload,
            };
        case actionTypes.LOADING.REFRESH_API_TOKEN:
            return {
                ...state,
                refreshApiTokenLoading: action.payload,
            };
        case actionTypes.LOADING.APK_LIST:
            return {
                ...state,
                apkListLoading: action.payload,
            };
    }

    return state;
}
