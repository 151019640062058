import actionTypes from "@/actions/_types";

const INITIAL_STATE = {
    openModal: {
        modalType: null,
        params: {},
    },
    demo: false,
    platform: "web", // one of('android', 'ios', 'web')
    appId: null,
    isMenuOpen: false,
    showOnboarding: false,
    hasUpdate: false,
    permissionError: { errno: -1, errmsg: "" },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.APP.CHANGE_PLATFORM:
            return {
                ...state,
                ["platform"]: action.payload,
                ["appId"]: null,
            };
        case actionTypes.APP.SELECT_APPLICATION:
            return {
                ...state,
                ["appId"]: action.payload,
            };
        case actionTypes.APP.OPEN_MODAL:
            return {
                ...state,
                openModal: action.payload,
            };
        case actionTypes.APP.CLOSE_MODAL:
            return {
                ...state,
                openModal: {
                    modalType: null,
                    params: {},
                },
            };
        case actionTypes.APP.TOGGLE_DEMO:
            return {
                ...state,
                demo: action.payload,
                appId: null,
            };
        case actionTypes.APP.HAS_UPDATE:
            return {
                ...state,
                hasUpdate: action.payload,
            };
        case actionTypes.APP.DISPLAY_PERMISSION_ERROR:
            return {
                ...state,
                permissionError: {
                    errno: action.payload.errno,
                    errmsg: action.payload.errmsg,
                },
            };
    }
    return state;
}
