import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AllInstallationsChart from "@/components/utils/charts/installations/all-installations";
import DailyInstallations from "@/components/utils/charts/installations/daily-installations";
import InfoCard from "../components/cards";
import { fetchHints } from "@/actions/hints";
import { fetchProfile, signOut, updateConsoleSettings } from "@/actions/auth";
import { closeModal, openModal } from "@/actions/app";
import { fetchApplications } from "@/actions/applications";
import { fetchDashboardData } from "@/actions/dashboard";
import { fetchFavorites } from "@/actions/installations";
import { PLATFORMS } from "@/actions/_types";
import Loading from "@/components/utils/loading";
import { humanizeToFarsi, withErrorCatcher } from "@/components/utilities";
import uuid from "uuid/v4";
import DriverWrapper from "@/components/app/introduction/driverWrapper";
import {
    steps,
    mobileSteps,
} from "@/components/app/introduction/partials/introduction";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPlatform: PLATFORMS.ANDROID,
            onLoading: true,
            showReachingMaxCapacityContractSizeModal: null,
            showProfileModal: null,
            verificationError: null,
        };
    }

    fetchDatas() {
        const callback = () => {
            this.setState({ onLoading: false });
        };

        this.setState(
            () => ({ onLoading: true }),
            () => {
                this.props.fetchDashboardData(callback, callback);
                this.props.fetchApplications({ platform: this.props.platform });
                this.props.fetchFavorites();
                this.props.fetchHints();
            },
        );
    }

    componentDidMount() {
        this.fetchDatas();
        this.props.fetchProfile();
    }

    componentDidUpdate(prevProps) {
        const { platform, appId } = this.props;

        if (prevProps.platform !== platform || prevProps.appId !== appId) {
            this.fetchDatas();
        }
    }

    render() {
        if (this.state.onLoading) {
            return (
                <div className="table-loading">
                    <Loading className="loading-circle--blue-gray loading-circle-xlarge" />
                </div>
            );
        }

        let { platform, data, allData, history } = this.props;
        let loading = !this.props.data;

        const value = function (type) {
            if (loading) {
                if (type === "historical") return [];
                return null;
            }

            let clonedData;
            // eslint-disable-next-line no-prototype-builtins
            if (data && data.constructor.prototype.hasOwnProperty("map")) {
                clonedData = [...data];
            } else {
                clonedData = { ...data };
            }

            if (type === "apps" || type === "unreleased_apps")
                clonedData = allData;

            return humanizeToFarsi(clonedData[type]);
        };

        const cards = [
            {
                value: value("installs"),
                icon: "mobile_color",
                info: [
                    {
                        title: "کل نصب‌ها",
                        value: value("installs"),
                    },
                    {
                        title: "نصب‌ دردسترس",
                        value: value("transactional_installs"),
                    },
                ],
                buttonText: "مشاهده آمار نصب‌ها",
                onClick: () => this.props.history.push("/installation/android"),
            },
            {
                value: value("transactional_unique"),
                icon: "user_color",
                info: [
                    {
                        title: "کاربر دردسترس",
                        value: value("transactional_unique"),
                    },
                ],
                buttonText: "مشاهده آمار کاربران",
                onClick: () => this.props.history.push("/user/android"),
            },
            {
                value: value("apps"),
                icon: "app_color",
                info: [
                    {
                        title: "اپلیکیشن‌ها",
                        value: value("apps"),
                    },
                    {
                        title: "اپلیکیشن منتشر‌نشده",
                        value: value("unreleased_apps"),
                    },
                ],
                buttonText: "اپلیکیشن جدید",
                onClick: () => history.push(`/application/${platform}/create`),
            },
            {
                value: value("recipients"),
                icon: "notification_color",
                info: [
                    {
                        title: "نوتیفیکیشن فرستاده شده",
                        value: value("recipient"),
                    },
                    // TODO: uncomment these lines
                    // {
                    //     title: 'نوتیفیکیشن دریافت شده',
                    //     value: value('delivered')
                    // }
                ],
                buttonText: "ارسال نوتیفیکیشن",
                onClick: () =>
                    this.props.history.push("/notification/android/create"),
            },
        ];

        return (
            <div className="main-block dashboard">
                <div className="container">
                    <div className="container">
                        <div className="row">
                            {cards.map(value => (
                                <InfoCard key={uuid()} {...value} />
                            ))}
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <AllInstallationsChart
                                data={data["historical"] || []}
                                platform={this.props.platform}
                            />
                            <DailyInstallations
                                data={data["historical"] || []}
                            />
                        </div>
                    </div>
                    <ReactTooltip className="tooltip" effect="solid" />
                </div>

                <DriverWrapper
                    steps={steps}
                    mobileSteps={mobileSteps}
                    driverName={"viewed_introduction_1"}
                    resolvedCallback={this.props.updateConsoleSettings.bind(
                        null,
                        {
                            viewed_introduction_1: true,
                        },
                    )}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { platform, appId, openModal, demo } = state.app;
    const dashboardData = platform ? state[platform].dashboard : null;
    const hints = platform ? state[platform].hints : null;
    const applications = platform ? state[platform].applications : [];
    const installsCount = state[platform].dashboard.all
        ? state[platform].dashboard.all.installs
        : 0;
    const { profile } = state.auth;

    const data = (appId, dashboardData) => {
        if (!platform) {
            return [];
        } else if (appId) {
            return dashboardData[appId];
        } else {
            return dashboardData["all"];
        }
    };

    return {
        demo,
        hints,
        appId,
        platform,
        applications,
        installsCount,
        isWeb: platform === "web",
        userProfile: profile,
        activeModal: openModal,
        data: data(appId, dashboardData) || {},
        allData: data(null, dashboardData),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchHints,
            fetchDashboardData,
            openModal,
            closeModal,
            fetchFavorites,
            fetchProfile,
            fetchApplications,
            signOut,
            updateConsoleSettings,
        },
        dispatch,
    );
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);
