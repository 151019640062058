import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PagePlaceholder from "@/components/utilities/extra/PagePlaceholder";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { Envelop } from "@/components/utils/lib/svgicons";

const EmailPagePlaceholder = ({ history }) => {
    const platform = useSelector(s => s?.app?.platform ?? "android");

    return (
        <>
            <PagePlaceholder
                icon={<Envelop />}
                body={"هیچ ایمیلی یافت نشد"}
                footer={
                    <div data-tip={""}>
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            disabled={false}
                            label={"ارسال ایمیل"}
                            onClick={() =>
                                history.push(`/email/${platform}/create/`)
                            }
                        />
                    </div>
                }
            />
            <ReactTooltip className="tooltip" effect="solid" place="bottom" />
        </>
    );
};

export default withErrorCatcher(EmailPagePlaceholder);
