import React from "react";
import PropTypes from "prop-types";
import Highlight from "react-highlight";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withErrorCatcher } from "@/components/utilities";
import { successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import "styles/modules/_mod-highlight.scss";

class HighlightWithCopy extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, textToCopy, toast, language } = this.props;
        return (
            <div className="highlight-with-copy">
                <CopyToClipboard
                    text={textToCopy || children}
                    onCopy={() =>
                        toast &&
                        successToast(
                            TOAST_CONTENT.MANIFEST_COPY_SUCCESS.TITLE,
                            TOAST_CONTENT.MANIFEST_COPY_SUCCESS.DESC,
                        )
                    }
                >
                    <span className="copy-button">کپی</span>
                </CopyToClipboard>

                <Highlight className={"code-block " + (language || "")}>
                    {children}
                </Highlight>
            </div>
        );
    }
}

HighlightWithCopy.defaultProps = {
    toast: true,
};

HighlightWithCopy.propTypes = {
    children: PropTypes.any.isRequired,
    textToCopy: PropTypes.string,
    language: PropTypes.string,
    toast: PropTypes.bool,
};

export default withErrorCatcher(HighlightWithCopy);
