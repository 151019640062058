import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    dialogTitleRoot: {
        textAlign: "right",
        color: theme.palette.dashboard.black,
    },
    dialogActionRoot: {
        direction: "ltr",
        flexDirection: "row",
        width: "100%",
        padding: "10px 10px",
    },
    buttonTextRoot: {
        textAlign: "right",
    },
    listRoot: {
        maxHeight: "400px",
        minHeight: "50px",
        overflowY: "auto",
        direction: "ltr",
    },
    saveContentTextRoot: {
        textAlign: "right",
        fontSize: ".9rem",
        color: "#000",
        padding: "5px 20px",
        marginBottom: 0,
    },
}));

const SendTestDialogWeb = props => {
    const {
        onModalClose: onClose,
        AddItemComponent,
        items,
        isLoading,
        removeMutation,
        sendTestFunc,
        updateItemMutation,
        itemKey,
        dialogTitle,
        itemDel,
    } = props;
    const classes = useStyles();

    const [displaySave, toggleDisplaySave] = useState(false);
    const [selectEdit, setSelectEdit] = useState(null);
    const handleClick = e => {
        setSelectEdit(e);
        toggleDisplaySave(true);
    };
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
            scroll={"body"}
            classes={{ scrollPaper: classes.scrollPaper }}
            open={true}
        >
            <DialogTitle
                classes={{ root: classes.dialogTitleRoot }}
                id="simple-dialog-title"
            >
                {dialogTitle}
            </DialogTitle>
            <List classes={{ root: classes.listRoot }}>
                {isLoading && <CircleLoader />}

                {!displaySave &&
                    items?.map(item => (
                        <ListItem
                            key={item[itemKey]}
                            button
                            alignItems="flex-start"
                            disabled={isLoading}
                        >
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item["favorite_data"]["name"]}
                                onClick={() => sendTestFunc(item[itemKey])}
                                secondary={
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {`${item["device_type"]} , ${item["browser"]["name"]}`}
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction
                                style={{ paddingRight: 48 }}
                                onClick={() => handleClick(item)}
                            >
                                <IconButton edge="end">
                                    <Tooltip
                                        title="ویرایش نام"
                                        aria-label="edit"
                                    >
                                        <EditIcon />
                                    </Tooltip>
                                </IconButton>
                            </ListItemSecondaryAction>
                            <ListItemSecondaryAction
                                onClick={() => removeMutation(item[itemDel])}
                            >
                                <IconButton edge="end" aria-label="delete">
                                    <Tooltip title=" حذف" aria-label="delete">
                                        <DeleteIcon />
                                    </Tooltip>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}

                {items?.length === 0 && (
                    <div style={{ textAlign: "center" }}>
                        <p>شما هنوز هیچ نصب مورد علاقه ای ایجاد نکرده اید</p>
                        <p
                            style={{
                                fontSize: "1.1rem",
                                padding: "10px 10px",
                                direction: "rtl",
                                marginTop: "10px",
                            }}
                        >
                            برای ایجاد نصب مورد علاقه به صفحه &nbsp;
                            <span style={{ fontWeight: "bold" }}>
                                وب‌سایت‌ها
                            </span>
                            &nbsp; رفته و از ستون &nbsp;
                            <span style={{ fontWeight: "bold" }}>امکانات</span>
                            &nbsp; منوی &nbsp;
                            <span style={{ fontWeight: "bold" }}>
                                افزودن به لیست علاقه مندی
                            </span>
                            &nbsp; را انتخاب کنید.
                        </p>
                    </div>
                )}
                {displaySave && AddItemComponent && (
                    <AddItemComponent
                        data={selectEdit}
                        isLoading={isLoading}
                        onToggle={() => toggleDisplaySave(false)}
                        updateItemMutation={updateItemMutation}
                    />
                )}
            </List>
        </Dialog>
    );
};

export default withErrorCatcher(SendTestDialogWeb);
