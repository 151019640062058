import React from "react";
import CircularProgress from "material-ui/CircularProgress";
import classNames from "classnames";
import "styles/modules/_mod-circleloader.scss";

const circleLoaderColors = {
    blue: "#0065ff",
    white: "#ffffff",
};

/**
 * This is a wrapper around material-ui CircularProgress
 *
 * Could be used in to mode:
 *  1: with ternary (either show this modal or your desired)
 *  2: Show this modal on top of your modal until your desired time
 *      ** To achieve this (mode 2) use enableBackgroundMode = true
 *
 */
const CircleLoader = ({ ...props }) => {
    const {
        size,
        thickness,
        color,
        enableBackgroundMode = false,
        style,
    } = props;

    const defaultProps = {
        size: size || 25,
        color: color || circleLoaderColors.blue,
        thickness: thickness || 3,
        style: { top: 0, ...style },
    };

    return (
        <div
            className={classNames("circle-loader", {
                "circle-loader--grey-background": enableBackgroundMode,
            })}
        >
            <CircularProgress {...defaultProps} />
        </div>
    );
};

export { CircleLoader, circleLoaderColors };
