/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { findSegment } from "@/components/users/segmentation/constants";
import classNames from "classnames";

const SegmentField = ({
    input: { onChange },
    initData,
    meta: { touched, error = {} },
    isReadonly,
}) => {
    // Original field data in constants array (@/components/segmentation/constants)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const fieldsData = (iid => useMemo(() => findSegment(iid), [iid]))(
        initData.iid,
    );
    const [fieldValue, setFieldValue] = useState({ ...initData });

    useEffect(() => {
        onChange(fieldValue);
    }, [fieldValue]);

    // Returns fields of render key in fieldData in @/components/segmentation/constants
    const getRenderField = key => {
        const valueIsFunc =
            typeof fieldsData?.meta?.render?.[key] === "function";
        const renderKey = valueIsFunc
            ? fieldsData?.meta?.render?.[key](fieldValue?.type, fieldValue?.op)
            : fieldsData?.meta?.render?.[key];
        return [
            renderKey?.comp,
            renderKey?.options,
            renderKey?.options?.valueType,
            renderKey?.options?.valueUnit,
            renderKey?.options?.fieldType,
        ];
    };

    return (
        <div className="segments__fieldsWrapper">
            <h3 className="segments__field-title">
                {fieldsData?.meta?.translation}
            </h3>
            <div className="row">
                {(fieldsData?.meta?.availableKeys ?? []).map(
                    (availableKey, idx) => {
                        let renderedField;
                        const value = fieldValue?.[availableKey];
                        const errorText = touched && error?.[availableKey];
                        const [
                            Field,
                            options,
                            valueType,
                            valueUnit,
                            fieldType,
                        ] = getRenderField(availableKey);

                        // This will handle setting segment type
                        const itemApiType = fieldsData.meta.getType(
                            fieldValue?.type,
                            fieldValue?.op,
                        );
                        if (
                            !!itemApiType &&
                            fieldValue?.["type"] !== itemApiType
                        ) {
                            setFieldValue({ ...fieldValue, type: itemApiType });
                        }

                        if (valueType === "selectOptionField") {
                            renderedField = Field && (
                                <Field
                                    value={value ?? []}
                                    defaultValue={options?.defaultValue?.value}
                                    label={options?.label}
                                    onChange={val => {
                                        if (isReadonly) return;

                                        setFieldValue({
                                            ...fieldValue,
                                            [availableKey]: val,
                                        });
                                    }}
                                    items={options?.items ?? []}
                                    errorText={errorText}
                                />
                            );
                        }
                        if (valueType === "selectAutoComplete") {
                            renderedField = Field && (
                                <Field
                                    isReadonly={isReadonly}
                                    value={value ?? []}
                                    defaultValue={options?.defaultValue?.value}
                                    label={options?.label}
                                    onChange={val => {
                                        if (isReadonly) return;

                                        setFieldValue({
                                            ...fieldValue,
                                            [availableKey]: val,
                                        });
                                    }}
                                    items={options?.items ?? []}
                                    errorText={errorText}
                                />
                            );
                        }

                        if (valueType === "textField") {
                            renderedField = Field && (
                                <Field
                                    value={value}
                                    label={options?.label}
                                    reduxName={availableKey}
                                    onChange={val => {
                                        if (isReadonly) return;
                                        if (
                                            options?.preventFn &&
                                            options.preventFn(val)
                                        )
                                            return;
                                        const normalizedValue =
                                            options?.normalizeFn
                                                ? options?.normalizeFn(val)
                                                : val;
                                        setFieldValue({
                                            ...fieldValue,
                                            [availableKey]: normalizedValue,
                                        });
                                    }}
                                    errorText={errorText}
                                    type={fieldValue?.type}
                                    valueUnit={valueUnit}
                                    fieldType={fieldType}
                                />
                            );
                        }

                        if (valueType === "selectField") {
                            renderedField = Field && (
                                <Field
                                    placeholder={options?.placeholder}
                                    isCreatable={options?.isCreatable}
                                    mapping={options?.mapping}
                                    value={value ?? []}
                                    list={options?.list ?? []}
                                    onChange={values => {
                                        if (isReadonly) return;
                                        setFieldValue({
                                            ...fieldValue,
                                            value: [...values],
                                        });
                                    }}
                                    errorText={errorText}
                                    fieldValues={fieldValue}
                                />
                            );
                        }

                        if (valueType === "dateField") {
                            renderedField = Field && (
                                <Field
                                    label={options?.label}
                                    value={value}
                                    changeDate={value => {
                                        if (isReadonly) return;

                                        setFieldValue({ ...fieldValue, value });
                                    }}
                                />
                            );
                        }

                        return (
                            renderedField && (
                                <div
                                    className={classNames(
                                        { "col-md-12": options?.fullWidth },
                                        { "col-md-6": !options?.fullWidth },
                                        "col-sm-12 col-xs-12 segments__col",
                                    )}
                                    key={idx}
                                >
                                    {renderedField}
                                </div>
                            )
                        );
                    },
                )}
            </div>
        </div>
    );
};

export default withErrorCatcher(SegmentField);
