import React from "react";
import PropTypes from "prop-types";
import { withErrorCatcher } from "@/components/utilities";
import "./styles.scss";
import Select from "@/components/utilities/commons/select";

function NotificationFilters({ notifProps }) {
    const { notifOptions, notifOptionsToggle } = notifProps;
    return (
        <div className="notificationfilters__wrapper">
            <div className="table-row">
                <div className="table-col table-col--25 table-col-sm--33">
                    <Select
                        defaultSelect={notifOptions[0]}
                        className="select--border-round"
                        itemList={notifOptions}
                        onItemChange={option =>
                            notifOptionsToggle(option.value)
                        }
                    />
                </div>
            </div>
        </div>
    );
}

NotificationFilters.propTypes = {
    notifType: PropTypes.number,
    notifProps: PropTypes.object.isRequired,
};

export default withErrorCatcher(NotificationFilters);
