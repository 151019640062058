import { useEffect, useRef } from "react";

/**
 * Use as a hook to get previous value
 *
 * @param value: Date | string | number | boolean | ...
 */

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}
