import React, { useState } from "react";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import { createValidator } from "@/components/utils/validation";
import { Field, reduxForm } from "redux-form";
import { Toggle } from "redux-form-material-ui";
import ProfilePassword from "@/components/app/profile/partials/ProfilePassword";
import ReactTooltip from "react-tooltip";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { updateProfile, changePassword } from "@/actions/auth";
import { useDispatch } from "react-redux";

const AccountSecurityTab = props => {
    const dispatch = useDispatch();
    const { handleSubmit, change, invalid } = props;
    const [loading, toggleLoading] = useState(false);

    const submitAccountSecurity = values => {
        toggleLoading(true);
        const cb = () => toggleLoading(false);

        // If user entered new password the update the password
        if (values["password"]) {
            changePassword({
                old_password: values?.["old_password"],
                password: values?.["password"],
            })(dispatch);
        }

        // Update profile (2fa)
        updateProfile(
            {
                email_2fa_is_active: values?.["email_2fa_is_active"],
                email: values?.["email"], // Email is required in this api
            },
            cb,
            cb,
        )(dispatch);
    };

    return (
        <>
            <div className="profile__block">
                <div className="profile__section">
                    <h4 className="profile__section--header">
                        ورود به حساب دومرحله‌ای
                    </h4>
                    <Field
                        name="email_2fa_is_active"
                        label="ورود به حساب دو مرحله‌ای"
                        component={Toggle}
                        style={{ fontSize: "1rem", color: "#000" }}
                        labelPosition="right"
                    />
                </div>

                <ProfilePassword change={change} />

                <div className="profile__section--footer">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "80px",
                            width: "100%",
                        }}
                    >
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            label={
                                loading ? (
                                    <CircleLoader
                                        size={25}
                                        color="#32a9e0"
                                        style={{ top: "8px" }}
                                    />
                                ) : (
                                    "ذخیره"
                                )
                            }
                            onClick={handleSubmit(values => {
                                if (invalid) return;
                                submitAccountSecurity(values);
                            })}
                        />
                    </div>
                </div>
            </div>

            <ReactTooltip className="tooltip" effect="solid" />
        </>
    );
};

const validators = createValidator({
    old_password: (value, key, values) => {
        if (!value && values?.["password"] && values?.["password_repeat"]) {
            return "برای تغییر رمز ، رمز عبور فعلی الزامی است";
        }
        return undefined;
    },
    password: (value, key, values) => {
        if (!value && values["old_password"])
            return "برای تغییر رمز عبور باید رمز جدید وارد کنید";
        if (!values?.["password_repeat"]) return undefined;
        return values["password_repeat"] === value
            ? undefined
            : "عدم تطابق پسورد با تکرار پسورد";
    },
    password_repeat: (value, key, values) => {
        if (!value && values["old_password"])
            return "برای تغییر رمز عبور باید تکرار رمز جدید وارد کنید";
        if (!values?.["password"]) return undefined;
        return values["password"] === value
            ? undefined
            : "عدم تطابق تکرار پسورد با پسورد";
    },
});

const AccountSettingsForm = reduxForm({
    form: "AccountSecurity",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: validators,
})(AccountSecurityTab);

export default withErrorCatcher(AccountSettingsForm);
