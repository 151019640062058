import Colors from "@/components/utils/lib/colors";

export function webSeriesOfDaily(chart) {
    let installs = {
        data: chart["last_installs"],
        label: "آمار روزانه مشترکین ",
        fill: true,
        backgroundColor: "rgba(0, 230, 151, 0.12)",
        borderColor: Colors.green_0,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: Colors.green_0,
        pointBorderWidth: 2,
    };

    return [installs];
}
