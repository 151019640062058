/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";

const SegmentSelectOptionField = ({
    value,
    defaultValue,
    label,
    onChange,
    items,
    errorText,
}) => {
    useEffect(() => {
        // Set default value
        if (
            (!value || (Array.isArray(value) && value.length === 0)) &&
            defaultValue
        )
            onChange(defaultValue);
    }, []);

    return (
        <>
            <SelectField
                className="mui-select segments__field-item"
                value={value}
                hintText={label}
                onChange={(_, __, value) => onChange(value)}
                fullWidth={true}
                errorText={errorText}
                disabled={(items || []).length === 0}
            >
                {items &&
                    items.map((item, idx) => {
                        return (
                            <MenuItem
                                key={idx}
                                value={item.value}
                                primaryText={item.translation}
                            />
                        );
                    })}
            </SelectField>
        </>
    );
};

export default SegmentSelectOptionField;
