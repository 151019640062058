import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import css from "./styles.module.scss";
import { formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { STATIC_PUSHE_URL } from "@/constants";
import { PROMPT_TYPE } from "@/constants";

export default withErrorCatcher(() => {
    const state = useSelector(state => state);
    const { prompt, bell, geolocation, activeTab } =
        formValueSelector("webpush-settings")(
            state,
            "prompt",
            "bell",
            "geolocation",
            "activeTab",
        ) ?? {};

    const geoPrompt = {
        title: geolocation.text.title,
        content: geolocation.text.content,
        rejectText: geolocation.text.rejectBtn,
        acceptText: geolocation.text.acceptBtn,
        icon: "https://static.pushe.co/d/webpush/default-geo-icon.png",
    };

    return (
        <div className={css.previewWrapper}>
            {activeTab === "1" && <SubscriptionPromptPreview prompt={prompt} />}

            {activeTab === "2" && <BellPreview bell={bell} />}

            {activeTab === "3" && <GeoPromptPreview prompt={geoPrompt} />}
        </div>
    );
});

const DialogPreview = ({ prompt }) => {
    const { dialogType = PROMPT_TYPE.PUSHE } = prompt;
    const noIcon = !prompt?.icon;
    const ltr = prompt?.direction === "ltr";

    if (dialogType === PROMPT_TYPE.PUSHE) {
        return (
            <div className={css.promptWrapper}>
                <div className={classNames(css.promptBody, { [css.ltr]: ltr })}>
                    <div
                        className={classNames(css.promptIconWrapper, {
                            [css.noIcon]: noIcon,
                        })}
                    >
                        <img
                            className={css.promptIcon}
                            src={prompt?.icon ?? ""}
                            alt="prompt icon"
                        />
                    </div>
                    <div
                        className={classNames(css.promptContentWrapper, {
                            [css.noIcon]: noIcon,
                        })}
                    >
                        <p className={css.promptTitle}>
                            {prompt?.title ?? "تیتر دیالوگ"}
                        </p>
                        <p className={css.promptContent}>
                            {prompt?.content ?? "متن دیالوگ"}
                        </p>
                        <div className={css.promptButtonsWrapper}>
                            <a className={css.promptRejectButton}>
                                {prompt?.rejectText ?? "دکمه رد"}
                            </a>
                            <a className={css.promptAcceptButton}>
                                {prompt?.acceptText ?? "دکمه قبول"}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (dialogType === PROMPT_TYPE.OVERLAY) {
        return (
            <div className={css.overlayWrapper}>
                <div
                    className={css.overlayBackground}
                    style={{
                        backgroundColor:
                            prompt?.overlaySubscription?.color?.background ??
                            "#000000",
                    }}
                >
                    <div className={css.overlayBrowserPromptWrapper}>
                        <img
                            className={css.overlayBrowserPromptImg}
                            src={`${STATIC_PUSHE_URL}/d/webpush/default-browser-prompt.png`}
                        />
                    </div>

                    <p
                        className={css.overlayText}
                        style={{
                            color:
                                prompt?.overlaySubscription?.color?.text ??
                                "#eeeeee",
                        }}
                    >
                        <span className={css.overlaySubText}>
                            {prompt?.overlaySubscription?.backgroundText ??
                                "اگر مایل به دریافت آخرین اخبار هستید، بر روی دکمه Allow کلیک کنید"}
                        </span>
                        <span className={css.overlayTextArrow}>&#10548;</span>
                    </p>
                </div>
            </div>
        );
    } else if (dialogType === PROMPT_TYPE.DEFAULT) {
        return (
            <div className={css.promptWrapper}>
                <img
                    className={css.defaultBrowserPrompt}
                    src={`${STATIC_PUSHE_URL}/d/webpush/default-browser-prompt.png`}
                />
            </div>
        );
    }
};

const BellPreview = ({ bell }) => {
    return (
        <div className={css.bellWrapper}>
            <div
                className={css.bellInner}
                style={{
                    backgroundColor: bell?.properties?.backgroundColor
                        ? bell?.properties?.backgroundColor
                        : "#0065ff",
                }}
            >
                <div className={css.bellLineTop}></div>
                <div className={css.bellLineBottom}></div>
                <svg
                    viewBox="0 0 111.9 111.9"
                    style={{
                        fill: bell?.properties?.bellColor
                            ? bell?.properties?.bellColor
                            : "#ffffff",
                    }}
                >
                    <path d="M56.06,75.11a134,134,0,0,1-23-1.74l.44-5H34a2.41,2.41,0,0,0,2.46-2.35l0-20.65a19,19,0,0,1,5.69-13.55,19.83,19.83,0,0,1,33,9h0a18.21,18.21,0,0,1,.59,4.58l-.11,20.7a2.4,2.4,0,0,0,2.46,2.34h.32l.44,5A134.41,134.41,0,0,1,56.06,75.11ZM40.67,69.38c4.62.47,9.95.73,15.39.73s10.78-.25,15.38-.72a7.29,7.29,0,0,1-.79-3.29l.11-20.7a13.3,13.3,0,0,0-.43-3.31h0a14.76,14.76,0,0,0-24.67-6.68,14,14,0,0,0-4.22,10l0,20.65A7.18,7.18,0,0,1,40.67,69.38Z"></path>
                    <path d="M51.21,77.39V79a4.24,4.24,0,0,0,4.1,4.33H56.6A4.23,4.23,0,0,0,60.69,79V77.39Z"></path>
                </svg>
                <div className={css.bellTooltip}>
                    <span className={css.bellTooltipText}>
                        برای عضویت کلیک کنید
                    </span>
                </div>
            </div>
        </div>
    );
};

const GeoPromptPreview = ({ prompt }) => <DialogPreview prompt={prompt} />;
const SubscriptionPromptPreview = ({ prompt }) => (
    <DialogPreview prompt={prompt} />
);
