import { AndroidActions } from "@/components/notifications/notification/actions";
import analyticEventManager, {
    ANALYTICS_EVENTS_ACTION,
} from "@/components/utilities/analytics/analyticEventManager";

export const NOTIFICATION_ANALYTICS_TYPE = {
    success: 1,
    error: 2,
};

export function notificationAnalyticsHandler(type, resp) {
    try {
        if (type === NOTIFICATION_ANALYTICS_TYPE.success) {
            const notificationData = resp.data;

            if (
                "data" in notificationData &&
                typeof notificationData.data === "object" &&
                notificationData.data !== null &&
                "action" in notificationData.data &&
                typeof notificationData.data.action === "object" &&
                notificationData.data.action !== null &&
                "id" in notificationData.data.action
            ) {
                const actionObj = AndroidActions.find(
                    obj => obj.id === notificationData.data.action.id,
                );

                analyticEventManager(
                    ANALYTICS_EVENTS_ACTION.NOTIFICATION_ACTION,
                    {
                        actionText: actionObj.id,
                        label: actionObj.text,
                    },
                );
            }

            if (
                "filters" in notificationData &&
                typeof notificationData.filters === "object" &&
                notificationData.filters !== null &&
                "events" in notificationData.filters
            ) {
                analyticEventManager(ANALYTICS_EVENTS_ACTION.EVENTS_FILTER, {
                    wrapperId: notificationData.wrapper_id,
                });
            }

            if (
                "filters" in notificationData &&
                typeof notificationData.filters === "object" &&
                notificationData.filters !== null &&
                "visits" in notificationData.filters
            ) {
                analyticEventManager(ANALYTICS_EVENTS_ACTION.SESSION_FILTER, {
                    wrapperId: notificationData.wrapper_id,
                });
            }
        }

        if (type === NOTIFICATION_ANALYTICS_TYPE.error) {
            if ("response" in resp) {
                // Axios Error
                const data = resp.response.data;

                if ("detail" in data && data.detail === "sending_capacity") {
                    analyticEventManager(ANALYTICS_EVENTS_ACTION.LIMITED);
                }
            } else {
                // Something else went wrong ...
                console.error(resp);
            }
        }
    } catch (error) {
        console.error(
            `There is some error in notificationAnalyticsHandler \n ${error}`,
        );
    }
}
