/**
 * color code is the first letter of HEX number
 */
const colors = {};

colors.gray_2 = "#222222";
colors.gray_3 = "#333333";
colors.gray_5 = "#5C5C5C";
colors.gray_7 = "#777";
colors.gray_8 = "#808080";
colors.gray_9 = "#95989A";
colors.gray_b = "#B3B3B3";
colors.gray_e = "#E6E6E6";

colors.green = "#70BF58";
colors.green_0 = "#00E697";
colors.green_b = "#B7DA69";
colors.green_4 = "#40E2C9";
colors.green_3 = "#3FFF00";
colors.green_6 = "#66B44E";
colors.green_1e = "#1e3617";

colors.blue = "#22A9E0";
colors.blue_0b = "#0B3647";
colors.blue_0 = "#00C4FF";
colors.blue_8 = "#8ED5FF";
colors.blue_24 = "#24AAFF";
colors.blue_3 = "#3EFEFF";

colors.orange = "#FF9E7C";
colors.orange_e = "#E8976D";
colors.orange_6 = "#6e3a1f";

colors.pink_f = "#FFC6FD";

colors.purple_9 = "#96BCFF";
colors.purple_f = "#FF7EBF";
colors.purple_b = "#BD78D0";
colors.purple_a = "#AA00FF";
colors.purple_8 = "#8653A5";

colors.red = "#F23C24";
colors.red_f = "#FF0000";
colors.red_e = "#E85D4A";

colors.yellow_f = "#FFFF01";

export default colors;
