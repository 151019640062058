import to from "await-to-js";
import { getCommonApi } from "@/api";
import actionTypes from "@/actions/_types";
import { store } from "@/store";
import { PLATFORM_CODE_ANDROID } from "@/constants";

export const retrieveAutomation =
    (automationId, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().retrieveAutomation(automationId),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.AUTOMATION.RETRIEVE_AUTOMATION,
            payload: response.data,
        });

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const createAutomation =
    (data, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().createAutomation(data),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.AUTOMATION.CREATE_AUTOMATION,
            payload: response.data,
        });

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const updateAutomation =
    (automationId, data, onSuccess, onFailure) => async dispatch => {
        const [error, response] = await to(
            getCommonApi().updateAutomation(automationId, data),
        );

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.AUTOMATION.UPDATE_AUTOMATION,
            payload: response.data,
        });

        if (typeof onSuccess === "function") {
            onSuccess(response.data);
        }
    };

export const updateAutomationLocalState =
    (automationId, data, onFinish) => dispatch => {
        dispatch({
            type: actionTypes.AUTOMATION.UPDATE_AUTOMATION_LOCAL_STATE,
            payload: {
                id: automationId,
                data,
            },
        });

        if (typeof onFinish === "function") {
            onFinish();
        }
    };

export const deleteAutomation =
    (automationId, onSuccess, onFailure) => async dispatch => {
        const [error] = await to(getCommonApi().deleteAutomation(automationId));

        if (error && typeof onFailure === "function") {
            onFailure();
            return;
        }

        dispatch({
            type: actionTypes.AUTOMATION.DELETE_AUTOMATION,
            payload: { automation_id: automationId },
        });

        if (typeof onSuccess === "function") {
            onSuccess();
        }
    };

export const retrieveAutomationList = options => async dispatch => {
    const limit = 15;
    let { offset, hasMore } = store.getState()?.automation?.automations ?? {};

    offset = typeof options.offset === "number" ? options.offset : offset;
    hasMore = typeof options.hasMore === "boolean" ? options.hasMore : hasMore;
    const platform = options?.["platformNum"] ?? PLATFORM_CODE_ANDROID;

    const shouldReset = offset === 0;

    const [error, response] = await to(
        getCommonApi().retrieveAutomationList({ platform, offset, limit }),
    );

    if (
        Array.isArray(response?.data?.results) &&
        response.data.results.length >= limit
    )
        offset += limit;
    else hasMore = false;

    if (error && typeof options?.onFailure === "function") {
        options.onFailure();
        return;
    }

    dispatch({
        type: actionTypes.AUTOMATION.RETRIEVE_AUTOMATION_LIST,
        payload: {
            offset,
            hasMore,
            items: response?.data?.results,
            shouldReset,
        },
    });

    if (typeof options?.onFinish === "function") {
        options?.onFinish();
    }
};

export const clearFetchedAutomationItems = options => dispatch => {
    dispatch({
        type: actionTypes.AUTOMATION.CLEAR_FETCHED_AUTOMATION_ITEMS,
    });

    if (typeof options?.onFinish === "function") options?.onFinish();
};
