import Colors from "@/components/utils/lib/colors";
export function getDataCharts(dashboard) {
    /* const {app_version = null} = distributions;

     if (!app_version) return [];*/
    return {
        pie: [
            {
                title: "نوع دستگاه",
                data: dashboard.distributions?.device_type,
                svg: "#svg_smartphone",
                id: "chart_pie_model",
            },
            {
                data: dashboard.distributions?.browser_name,
                title: "نام مرورگر",
                svg: "#svg_device_screeen",
                id: "chart_pie_screen",
            },
            {
                data: dashboard.distributions?.pushe_version,
                title: "ورژن پوشه",
                svg: "#svg_android_logo",
                id: "chart_pie_version",
            },
            {
                data: dashboard.distributions?.browser_version,
                title: "ورژن مرورگر",
                svg: "#svg_device_brand",
                id: "chart_pie_device",
            },
        ],
    };
}

export const getDataSeries = data => {
    const total = data["#all"] || 1;
    const colors = [
        Colors.orange,
        Colors.pink_f,
        Colors.blue_0,
        Colors.purple_b,
        Colors.blue_0,
        Colors.green,
        Colors.purple_f,
        Colors.blue_8,
        Colors.gray_5,
        Colors.gray_9,
        Colors.gray_e,
        Colors.orange_6,
        Colors.gray_8,
    ];
    let chartConfig = { labels: [], data: [], backgroundColor: colors };
    const newData = Object.entries(data)
        .filter(value => value[0] !== "#all")
        .filter(i => !(i[0] === "#other" && i[1] === 0));

    for (let i in newData) {
        if (newData[i][0] !== "#all") {
            chartConfig = {
                ...chartConfig,
                labels: [
                    ...chartConfig.labels,
                    newData[i][0] === "#other" ? "سایر" : newData[i][0],
                ],
                data: [
                    ...chartConfig.data,
                    parseFloat(((newData[i][1] / total) * 100).toFixed(2)),
                ],
            };
        }
    }
    return chartConfig;
};
