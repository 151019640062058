import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { htmlParser } from "@/components/utilities/extra/htmlParser";

const EmailTab = props => {
    const notification = props?.notification ?? {};
    const { subject, content, isHTML } = notification?.data ?? {};

    const s1 = {
        fontSize: ".8rem",
        fontWeight: "bold",
        padding: "2px 4px",
    };
    const s2 = {
        padding: "4px 14px",
    };

    const x =
        "<style>body {width:250px;font-size:10px;transform: scale(.35);transform-origin: top left;}</style>" +
        content;

    return (
        <div>
            <div>
                <span style={s1}>عنوان:</span>
                <div style={s2}>{subject}</div>
            </div>

            <div>
                <span style={s1}>متن:</span>
                <div style={s1}>
                    {isHTML && (
                        <iframe
                            className="card-html-email-wrapper"
                            srcDoc={x}
                            width="250"
                            height="160"
                        />
                    )}

                    {!isHTML && (
                        <div className="card-html-email-wrapper">
                            {htmlParser.parse(content)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withErrorCatcher(EmailTab);
