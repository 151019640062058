import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import FloatingActionButton from "material-ui/FloatingActionButton";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import classname from "classnames";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const RefreshButton = props => {
    const {
        backgroundColor,
        tooltip,
        tooltipPosition,
        onClick,
        animationIsRunning,
    } = props;

    return (
        <>
            <FloatingActionButton
                className={classname("refresh-button", {
                    rotate: animationIsRunning,
                })}
                tooltipPosition="top-center"
                data-tip={tooltip}
                zDepth={0}
                mini={true}
                disabled={animationIsRunning}
                backgroundColor={backgroundColor}
                onClick={typeof onClick === "function" && onClick}
            >
                <RefreshIcon />
            </FloatingActionButton>
            <ReactTooltip
                className="tooltip"
                effect="solid"
                place={tooltipPosition}
            />
        </>
    );
};

RefreshButton.defaultProps = {
    backgroundColor: "#0065ff",
    tooltip: "به‌روز‌رسانی",
    tooltipPosition: "top",
    animationIsRunning: false,
};

RefreshIcon.propTypes = {
    backgroundColor: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipPosition: PropTypes.oneOf(["top", "right", "left", "bottom"]),
    animationIsRunning: PropTypes.bool,
    onClick: PropTypes.func,
};

export default withErrorCatcher(RefreshButton);
