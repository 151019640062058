import React, { useEffect } from "react";
import { setToken } from "@/actions/auth";
import { useDispatch } from "react-redux";

/**
 * This would be used in 2fa login when api
 * pass a token in url to /activation address
 *
 */
function Activation() {
    const dispatch = useDispatch();
    const getToken = () =>
        (window.location.search.match(/token=(.+)/) ?? [])[1];

    useEffect(() => {
        const token = getToken();

        if (!token) window.location = "/";

        setToken(token)(dispatch);
    }, [dispatch]);

    return <></>;
}

export default Activation;
