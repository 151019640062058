import { SEG_KEYS } from "@/components/users/segmentation/constants";
import { defaultTo as dt, isEmpty } from "lodash";

const accept = v => v;
const prevent = () => undefined;

const flattenTransforms = (
    _segmentsList,
    segmentsList = dt(_segmentsList, []),
) => {
    const sgList = segmentsList.map(item => {
        const acceptedValues = {
            key: item.key,
            name: item.name,
            type: item.type,
            value: item.value,
            op: item.op?.split("-d")[0] ?? null,
            vKey: item?.vKey ?? null,
        };

        return { ...acceptedValues };
    });

    if (!isEmpty(sgList)) {
        return sgList;
    }

    return undefined;
};

const unFlattenTransforms = (
    _segmentsList,
    segmentsList = dt(_segmentsList, []),
) => {
    return segmentsList.map(item => {
        const copiedItem = { ...item };

        if (
            (item.key === SEG_KEYS.SESSION_UPDATED_AT.API_KEY &&
                item.type ===
                    SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT
                        .value) ||
            (item.key === SEG_KEYS.SESSION_RELATIVE_CREATED_AT.API_KEY &&
                item.type ===
                    SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._.value)
        ) {
            copiedItem.op = `${copiedItem.op}-d`;
        }

        if (
            item.key === SEG_KEYS.SESSION_USER_LIFE.API_KEY &&
            item.type === SEG_KEYS.SESSION_USER_LIFE.TYPES._.value
        ) {
            copiedItem.iid = SEG_KEYS.SESSION_USER_LIFE.ID;
        } else if (
            item.key === SEG_KEYS.SESSION_RELATIVE_CREATED_AT.API_KEY &&
            item.type === SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._.value
        ) {
            copiedItem.iid = SEG_KEYS.SESSION_RELATIVE_CREATED_AT.ID;
        } else if (
            item.key === SEG_KEYS.SESSION_UPDATED_AT.API_KEY &&
            (item.type ===
                SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT.value ||
                item.type ===
                    SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value)
        ) {
            copiedItem.iid = SEG_KEYS.SESSION_UPDATED_AT.ID;
        } else {
            copiedItem.iid = copiedItem.key;
        }

        return copiedItem;
    });
};
const flattenSegments = _values => {
    return dt(_values, [])
        .map(val => {
            const segmentsArray = val.segments;
            if (segmentsArray) {
                return flattenTransforms(segmentsArray);
            }
            return val;
        })
        .filter(val => !isEmpty(val));
};

const unFlattenSegments = _values => {
    return dt(_values, []).map(val => ({
        segments: unFlattenTransforms(val),
    }));
};

// To send values to api
export const segmentationToApiNormalizer = {
    name: accept,
    app_id: accept,
    expression: flattenSegments,
};

// To show values in redux form
export const segmentationToFormNormalizer = {
    name: accept,
    app_id: accept,
    segment_id: accept,
    expression: unFlattenSegments,
    is_active: prevent,
    created_at: prevent,
    updated_at: prevent,
    recipient_count: prevent,
};
