import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";

const GuideButton = ({
    app,
    isSmallSize,
    colSize,
    history,
    className,
    platform,
}) => {
    const classes = !isSmallSize
        ? `table-col table-col--${colSize} color-gray-95 ${className}`
        : `small-dateTime ${className}`;

    return (
        <div className={classes}>
            <FlatButton
                className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                rippleColor={blue900}
                label="آموزش و تنظیمات"
                onClick={() =>
                    history.push(`/application/${platform}/guide/${app.app_id}`)
                }
            />
        </div>
    );
};

GuideButton.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    colSize: PropTypes.number,
};

GuideButton.defaultProps = {
    app: {},
    isSmallSize: false,
    colSize: 20,
};

export default withErrorCatcher(GuideButton);
