import React from "react";
import { useAndroidDashboard } from "@/components/newDashboard/android/useAndroidDashbord";
import Loading from "@/components/utils/loading";
import uuid from "uuid/v4";
import ReactTooltip from "react-tooltip";
import { AndroidGeneratorInfoCard } from "@/components/newDashboard/android/config/AndroidGenreatorInfoCards";
import DriverWrapper from "@/components/app/introduction/driverWrapper";
import {
    mobileSteps,
    steps,
} from "@/components/app/introduction/partials/introduction";
import { updateConsoleSettings } from "@/actions/auth";
import { useDispatch } from "react-redux";
import { withErrorCatcher } from "@/components/utilities";
import { All_INSTALL_HELPER, FIRST_DAY_EMPTY_CHART_MESSAGE } from "@/constants";
import CardFlex from "@/components/newDashboard/component/listCard/CardFlex";
import { androidSeriesOfAll } from "@/components/newDashboard/android/config/AllInstallChartAndroid";
import { AndroidSeriesOfDaily } from "@/components/newDashboard/android/config/DailyInstallChartAndroid";
import DailyInstallationsNewChart from "@/components/newDashboard/component/DailyInstallationsNewChart/DailyInstallationsNewChart";
import AllInstallChartMore from "@/components/newDashboard/component/AllInstallationsNewChart/AllInstallChartMore";

function AndroidDashboard(props) {
    const dispatch = useDispatch();
    const { history } = props;
    let { platform, data, allData, onLoading } = useAndroidDashboard();

    if (onLoading) {
        return (
            <div className="table-loading">
                <Loading className="loading-circle--blue-gray loading-circle-xlarge" />
            </div>
        );
    }

    return (
        <div className="main-block dashboard">
            <div className="container">
                <div className="container">
                    <div className="row">
                        {AndroidGeneratorInfoCard(
                            data,
                            allData,
                            history,
                            platform,
                        ).map(value => (
                            <CardFlex key={uuid()} {...value} />
                        ))}
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <AllInstallChartMore
                            height={200}
                            title={" آمار روزانه نصب‌ها و کاربران"}
                            helper={All_INSTALL_HELPER}
                            seriesOfAll={androidSeriesOfAll}
                            data={data["historical"] || []}
                        />
                        <DailyInstallationsNewChart
                            height={400}
                            title={" نصب و حذف روزانه"}
                            helper={FIRST_DAY_EMPTY_CHART_MESSAGE}
                            seriesOfDaily={AndroidSeriesOfDaily}
                            data={data["historical"] || []}
                        />
                    </div>
                </div>
                <ReactTooltip className="tooltip" effect="solid" />
            </div>
            <DriverWrapper
                steps={steps}
                mobileSteps={mobileSteps}
                driverName={"viewed_introduction_1"}
                resolvedCallback={updateConsoleSettings({
                    viewed_introduction_1: true,
                })(dispatch)}
            />
        </div>
    );
}
export default withErrorCatcher(AndroidDashboard);
