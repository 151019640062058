import React from "react";
import AndroidAutomationList from "@/components/sms/automation/list/android/AndroidAutomationList";
import { pagePermissionWrapper, PERMISSIONS } from "@/components/utilities";
import WebAutomationList from "@/components/sms/automation/list/web/WebAutomationList";

const SMSAutomationListPage = props => {
    const {
        match: { params },
        ...otherProps
    } = props;

    const SMSPage = NOTIFICATIONS_PAGE[params.platform];

    return <SMSPage {...otherProps} />;
};

const NOTIFICATIONS_PAGE = {
    android: AndroidAutomationList,
    web: WebAutomationList,
};

export default pagePermissionWrapper(
    PERMISSIONS.SMS_AUTOMATION_LIST,
    SMSAutomationListPage,
);
