import * as React from "react";
import Loading from "@/components/utils/loading";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";

function CardFlex(props) {
    const { col = 3 } = props;
    return (
        <>
            <div className={`col-lg-${col} col-md-${col} col-sm-12 col-xs-12`}>
                <div className="card-a card-a--detail">
                    <div className="card-a__img">
                        <svg>
                            <use href={`#svg_${props.icon}`} />
                        </svg>
                    </div>
                    <div className="card-a__text">
                        <div>
                            {props.info.map((infoItem, index) => (
                                <div className="card-a__info" key={index}>
                                    <var>
                                        {infoItem.value != null ? (
                                            infoItem.value
                                        ) : (
                                            <Loading />
                                        )}
                                    </var>
                                    <span>{infoItem.title}</span>
                                </div>
                            ))}
                        </div>
                        {props.buttonText && (
                            <FlatButton
                                className="mui-btn mui-btn--xs mui-btn--border mui-btn--gray-blue"
                                rippleColor={blue900}
                                label={props.buttonText}
                                onClick={props.onClick}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default CardFlex;
