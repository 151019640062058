import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const AndroidTableHeader = () => {
    return (
        <div className="table-row">
            <div className="table-col table-col--13">برند دستگاه</div>
            <div className="table-col table-col--14">مدل دستگاه</div>
            <div className="table-col table-col--17">نام و شناسه دستگاه</div>
            <div className="table-col table-col--17">تاریخ نصب</div>
            <div className="table-col table-col--11">آیکن برنامه</div>
            <div className="table-col table-col--17">نام و نسخه برنامه</div>
            <div className="table-col table-col--11 table-col--empty" />
        </div>
    );
};

export default withErrorCatcher(AndroidTableHeader);
