import { FRAMEWORK_CAST_NAME_INTO_VALUE } from "@/actions/_types";
import androidStudioSteps, {
    manifest as androidStudioManifest,
} from "application/guides/android/androidStudio/steps";
import reactNativeSteps, {
    manifest as reactNativeManifest,
} from "application/guides/android/reactNative/steps";
import flutterSteps, {
    manifest as flutterManifest,
} from "application/guides/android/flutter/steps";
import unitySteps, {
    manifest as unityManifest,
} from "application/guides/android/unity/steps";

const stepsMap = {
    [FRAMEWORK_CAST_NAME_INTO_VALUE["AndroidStudio"]]: androidStudioSteps,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["ReactNative"]]: reactNativeSteps,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["Flutter"]]: flutterSteps,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["Unity"]]: unitySteps,
};

const stepsManifest = {
    [FRAMEWORK_CAST_NAME_INTO_VALUE["AndroidStudio"]]: androidStudioManifest,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["ReactNative"]]: reactNativeManifest,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["Flutter"]]: flutterManifest,
    [FRAMEWORK_CAST_NAME_INTO_VALUE["Unity"]]: unityManifest,
};

export const buildSteps = (framework, isManifestOnly = false) => {
    const manifest = stepsManifest[framework];
    const steps = stepsMap[framework];

    if (isManifestOnly)
        return typeof manifest === "function" ? manifest() : manifest;
    return typeof steps === "function" ? steps() : steps;
};
