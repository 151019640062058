
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Manifest = makeShortcode("Manifest");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`مانیفست`}</h1>
    <p>{`متن زیر را کپی و در تگ
`}<inlineCode parentName="p">{`application`}</inlineCode>{`
مانیفست خود درج کنید.
`}<a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/android/intro/#%D8%A7%D8%B6%D8%A7%D9%81%D9%87%DA%A9%D8%B1%D8%AF%D9%86-%D9%85%D8%AD%D8%AA%D9%88%D8%A7%DB%8C-%D9%85%D8%A7%D9%86%DB%8C%D9%81%D8%B3%D8%AA"
      }}>{`اطلاعات بیشتر`}</a></p>
    <Manifest {...props} mdxType="Manifest">
      <pre><code parentName="pre" {...{
          "className": "language-xml"
        }}>{`<meta-data
    android:name="pushe_token"
    android:value="{{manifest}}"/>
`}</code></pre>
    </Manifest>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;