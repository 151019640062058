import React from "react";
import BaseWizard, {
    WIZARD_BTN_OPTIONS,
} from "@/components/utilities/commons/wizard/base/BaseWizard";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import { withErrorCatcher } from "@/components/utilities";
import { createInitialFormData } from "@/components/notifications/automation/create/web/createInitialFormData";
import WebNotifPreview from "@/components/notifications/notification/create/web/WebNotifPreview";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import {
    createAutomation,
    updateAutomation,
    updateAutomationLocalState,
} from "@/actions/automation";
import { sendTestNotification } from "@/actions/notifications";
import { normalize } from "@/components/utils/normalize";
import { webAutomationToApiNormalizer } from "@/components/notifications/automation/normalize";
import { clearForm } from "@/actions/app";
import { AUTOMATION_WIZARD_MODE } from "@/components/notifications/automation/constants";
import NotificationTestModal from "@/components/notifications/notification/create/controls/NotifTestModal";
import { webNotificationNormalizer } from "@/components/notifications/notification/normalize";
import WebAutomationStep1 from "@/components/notifications/automation/create/web/steps/WebAutomationStep1";
import WebAutomationStep2 from "@/components/notifications/automation/create/web/steps/WebAutomationStep2";
import AutomationFinaleStep from "@/components/notifications/automation/create/web/common/AutomationFinaleStep";

const WebAutomationWizard = props => {
    const {
        history,
        wizardMode,
        createAutomation,
        updateAutomation,
        sendTestNotification,
        automation,
        notification,
        clearForm,
        updateAutomationLocalState,
    } = props;

    const platform = useSelector(state => state?.app?.platform);

    return (
        <BaseWizard
            formName="web_automation_form"
            title="ارسال اعلان اتوماتیک وب"
            subtitle="ساخت ارسال اعلان اتوماتیک وب"
            steps={WEB_AUTOMATION_STEPS}
            footer={WizardFooter}
            history={history}
            formInitialData={createInitialFormData(automation, notification)}
            stepFinale={AutomationFinaleStep}
            preview={WebNotifPreview}
            testModal={NotificationTestModal}
            submitAction={(data, onSuccess, onFailure) => {
                if (data.isTestMode) {
                    sendTestNotification(
                        normalize(data, webNotificationNormalizer),
                        platform,
                        onSuccess,
                        onFailure,
                    );
                } else {
                    const normalizedData = normalize(
                        data,
                        webAutomationToApiNormalizer,
                    );

                    if (wizardMode === AUTOMATION_WIZARD_MODE.CREATE) {
                        createAutomation(normalizedData, onSuccess, onFailure);
                    } else if (wizardMode === AUTOMATION_WIZARD_MODE.EDIT) {
                        const updateLocalState = data =>
                            updateAutomationLocalState(
                                normalizedData.automation_id,
                                data,
                                onSuccess,
                            );
                        updateAutomation(
                            normalizedData.automation_id,
                            normalizedData,
                            updateLocalState,
                            onFailure,
                        );
                    }
                }
            }}
            onUnmountCallback={() => {
                clearForm("web_automation_form");
            }}
            hasReduxForm={true}
            buttonOptions={{
                [WIZARD_BTN_OPTIONS.SUBMIT_BTN_NAME]: "ذخیره",
            }}
            passToSteps={{
                wizardMode,
                platform,
            }}
        />
    );
};

const WEB_AUTOMATION_STEPS = [
    { component: WebAutomationStep1, hideSubmitBtn: true },
    { component: WebAutomationStep2 },
];

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            createAutomation,
            updateAutomation,
            clearForm,
            sendTestNotification,
            updateAutomationLocalState,
        },
        dispatch,
    );
};

export default withErrorCatcher(
    connect(null, mapDispatchToProps)(WebAutomationWizard),
);
