import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceDetails } from "@/actions/financial";

export const useFetchInvoiceDetails = invoiceId => {
    const dispatch = useDispatch();
    const [inProgress, toggleProgress] = useState(false);

    const invoice = useSelector(
        state => state?.financial?.invoice?.[invoiceId],
    );

    const handleFetchInvoiceDetails = () => {
        toggleProgress(true);
        const onFinish = () => toggleProgress(false);

        fetchInvoiceDetails({ invoiceId, onFinish })(dispatch);
    };

    return [inProgress, invoice, handleFetchInvoiceDetails];
};
