import React from "react";
import { FormSection, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { withRouter } from "react-router-dom";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import NotificationDeliverySettingsComponent from "@/components/utilities/commons/wizard/components/NotificationDeliverySettingsComponent";
import NotificationDisplaySettingsComponent from "@/components/utilities/commons/wizard/components/NotificationDisplaySettingsComponent";
import ReactTooltip from "react-tooltip";
import { hasOneSignal as checkHasOneSignal } from "@/utils";

const AndroidAutomationStep4 = props => {
    const { change, data, hasOneSignal } = props;

    return (
        <form className="notif-step4">
            <FormSection name="notification">
                <NotificationDeliverySettingsComponent
                    showCollapseKeyBlock={true}
                    showDelayUntilBlock={true}
                    showRateLimitBlock={false}
                    showTimeToLiveBlock={true}
                />

                <NotificationDisplaySettingsComponent
                    data={data}
                    hasOneSignal={hasOneSignal}
                    change={change}
                />
            </FormSection>

            <ReactTooltip className="tooltip" effect="solid" />
        </form>
    );
};

function mapStateToProps(state, ownProps) {
    const {
        passToSteps: { platform },
    } = ownProps;

    let { app_ids = [], notification = {} } = formValueSelector(
        "automation_form",
    )(state, "app_ids", "notification");
    const { data = {} } = notification;

    let applications = state[platform].applications || [];
    const hasOneSignal = checkHasOneSignal(app_ids, applications);

    return {
        data,
        hasOneSignal,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

const AndroidAutomationStep4RF = reduxForm({
    form: "automation_form",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidAutomationStep4);

AndroidAutomationStep4.propTypes = {
    uploadFile: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    notificationTypes: PropTypes.object.isRequired,
    passToSteps: PropTypes.string.isRequired,
};

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AndroidAutomationStep4RF),
    ),
);
