import React, { useEffect, useState } from "react";

export const isContentFarsi = (str = "") =>
    new RegExp("[آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ۰۱۲۳۴۵۶۷۸۹]", "mg").test(
        str,
    );

const calculateRemaining = content => {
    const len = content.length;
    const isFarsi = isContentFarsi(content);

    const [x1, x2, x3] = isFarsi ? [70, 64, 67] : [160, 146, 153];
    let messageCount = 1,
        remaining = 0;

    if (len <= x1) {
        messageCount = 1;
        remaining = x1 - len;
    } else if (len <= x1 + x2) {
        messageCount = 2;
        remaining = x1 + x2 - len;
    } else if (x1 + x2 < len) {
        messageCount = Math.ceil((len - (x1 + x2)) / x3) + 2;
        remaining = x1 + x2 + (messageCount - 2) * x3 - len;
    }

    return [messageCount, remaining];
};

export const useCreateSMSRemaining = ({ content = "" }) => {
    const [remaining, setRemaining] = useState(0);
    const [messageCount, setMessageCount] = useState(1);

    useEffect(() => {
        const [count, remain] = calculateRemaining(content);

        setRemaining(remain);
        setMessageCount(count);
    }, [content]);

    if (content.length > 0) {
        return (
            <span className="sms-counter">
                {messageCount > 1
                    ? `${remaining} / ${messageCount}`
                    : `${remaining}`}
            </span>
        );
    } else {
        return React.Fragment;
    }
};
