
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import FirstInstall from "application/create/base/FirstInstall";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`تست و عیب‌یابی`}</h1>
    <blockquote>
      <p parentName="blockquote">{`در صورتی که هنگام اجرای برنامه به خطای `}<inlineCode parentName="p">{`MultiDex`}</inlineCode>{` برخورد کردید بایستی این قابلیت را فعال نمایید. `}<a parentName="p" {...{
          "href": "https://docs.pushe.co/docs/flutter/multidex"
        }}>{`اطلاعات بیشتر`}</a></p>
    </blockquote>
    <ul>
      <li parentName="ul">{`اپلیکیشن خود را (که کتابخانه‌ی پوشه به آن اضافه شده‌است) روی یک گوشی موبایل یا ایمولاتور نصب کنید`}</li>
      <li parentName="ul">{`مطمئن شوید که `}<inlineCode parentName="li">{`google play services`}</inlineCode>{` روی دستگاه شما نصب است`}</li>
      <li parentName="ul">{`مطمئن شوید دستگاه به اینترنت وصل است`}</li>
      <li parentName="ul">{`اپلیکیشن را روی گوشی خود باز کنید`}</li>
    </ul>
    <p>{`بعد با فشردن دکمه‌ی `}<em parentName="p">{`«بررسی و دریافت نصب»`}</em>{` در پایین صفحه منتظر بمانید تا نصب شما در سیستم پوشه شناخته شود.`}</p>
    <p>{`به طور معمول ۳ دقیقه زمان لازم است تا نصب شما دریافت شود.
در صورتی که این عملیات بیش از حد معمول طول کشید،‌لاگ خروجی از دستگاه را با استفاده از `}<strong parentName="p">{`Logcat`}</strong>{` مشاهده‌کنید و خطای رخ‌داده از سمت `}<inlineCode parentName="p">{`Pushe`}</inlineCode>{` را بررسی‌کنید و به بخش `}<a parentName="p" {...{
        "href": "https://docs.pushe.co/docs/flutter/troubleshoot"
      }}>{`خطا‌ها در مستندات`}</a>{` مراجعه کنید.`}</p>
    <p>{`می‌توانید با مراجعه به صفحه‌ی نصب‌ها از رسیدن نصب جدید مطلع شوید.`}</p>
    <FirstInstall {...props.passToSteps} mdxType="FirstInstall" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;