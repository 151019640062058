import React from "react";
import ReactDOM from "react-dom";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import classNames from "classnames";
import Util from "@/utils";
import IconButton from "material-ui/IconButton";
import { Notification, StarStroke } from "@/components/utils/lib/svgicons";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import uuid from "uuid/v4";
import Image_asus from "images/brand/asus.png";
import Image_htc from "images/brand/htc.png";
import Image_huawei from "images/brand/huawei.png";
import Image_lenovo from "images/brand/lenovo.png";
import Image_LGE from "images/brand/LGE.png";
import Image_samsung from "images/brand/samsung.png";
import Image_sony from "images/brand/sony.png";
import Image_xiaomi from "images/brand/xiaomi.png";
import Image_default from "images/app-builder/default.png";
import FaveModal from "@/components/users/installations/common/modals/fav";
import UnFaveModal from "@/components/users/installations/common/modals/unfave";
import blank_app from "images/app-icon/app-blank.png";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const mappingBrandInto = {
    asus: Image_asus,
    htc: Image_htc,
    huawei: Image_huawei,
    lenovo: Image_lenovo,
    LGE: Image_LGE,
    lge: Image_LGE,
    samsung: Image_samsung,
    sony: Image_sony,
    xiaomi: Image_xiaomi,
    undefined: Image_default,
    null: Image_default,
};

class AndroidTableRows extends React.Component {
    static propTypes = {
        sendRapidNotification: PropTypes.func,
        installationList: PropTypes.array,
        responseErrorInProg: PropTypes.string,
        favPusheIds: PropTypes.array,
        favList: PropTypes.array,
        unFaveDevice: PropTypes.func,
        faveInstallation: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            sendInProgress: false,
            showFavModal: false,
            showUnFavModal: false,
            clickedPusheId: null,
        };

        this.rowRef = React.createRef();
    }

    sentTestPushNotification({ app_id, pids }) {
        const { sendRapidNotification } = this.props;
        const callback = () =>
            setTimeout(() => this.setState({ sendInProgress: false }), 2000);

        const data = {
            app_id: app_id,
            pids: pids,
            data: {
                title: "پوشه",
                content: "تست نوتیفیکیشن",
                sound: "default",
                action: {
                    type: "open_app",
                    action_type: "A",
                },
            },
        };

        this.setState({ sendInProgress: true }, () =>
            sendRapidNotification(data, callback, callback),
        );
    }

    render() {
        const { installationList, responseErrorInProg, favPusheIds } =
            this.props;
        const { showFavModal, showUnFavModal, clickedPusheId } = this.state;

        const brandIcon = ({ row, classes }) => (
            <div
                className={classNames("table-col", classes)}
                id={`install-brand`}
                style={{ fontSize: 0 }}
            >
                <img
                    className="table-img"
                    src={mappingBrandInto[row.brand] || Image_default}
                    alt=""
                />
            </div>
        );

        const deviceModel = ({ row, classes }) => (
            <div className={classNames("table-col table-col--bold", classes)}>
                {row.model || ""}
            </div>
        );

        const androidId = ({ row, classes }) => {
            const showFavName = p =>
                (this.props.favList.find(i => i["pushe_id"] === p) || {})
                    .name || "";

            return (
                <div className={classNames("table-col", classes)}>
                    {
                        <>
                            <div style={{ fontSize: "1rem" }}>
                                {(() => showFavName(row.pushe_id))()}
                            </div>
                            {row["android_id"]}
                        </>
                    }
                </div>
            );
        };

        const installationDate = ({ row, classes }) => (
            <div className={classNames("table-col", classes)}>
                {Util._convertToLocalTimezone(row.created_at)}
            </div>
        );

        const appIcon = ({ row, classes }) => (
            <div className={classNames("table-col text-fa", classes)}>
                {
                    <div className="card-table card-table--notext">
                        <img src={`${row.app_info.icon || blank_app}`} alt="" />
                    </div>
                }
            </div>
        );

        const nameAndAppVersion = ({ row, classes }) => (
            <div className={classNames("table-col", classes)}>
                {
                    <div className="app-info">
                        <p title={row.app_info.name}>{row.app_info.name}</p>
                        <small title={row.app_version}>{row.app_version}</small>
                    </div>
                }
            </div>
        );

        const sendTestIconButton = ({ row }) => (
            <IconButton
                className="table-btn"
                disabled={this.state.sendInProgress}
                data-tip="ارسال نوتیفیکیشن تستی"
                onClick={() =>
                    this.sentTestPushNotification({
                        app_id: row.app_info.id,
                        pids: [row.pushe_id],
                    })
                }
            >
                <Notification viewBox="0 0 21 21" />
            </IconButton>
        );

        const favedIconButton = ({ row, wrapper = false, classes }) => {
            const Elem = (
                <IconButton
                    className="table-btn table-btn--star"
                    data-tip="علاقه‌مندی"
                    onClick={() => {
                        let params;
                        if (!row.isFave) {
                            params = { showFavModal: true };
                        } else {
                            params = { showUnFavModal: true };
                        }
                        this.setState({
                            ...params,
                            clickedPusheId: row.pushe_id,
                        });
                    }}
                >
                    <StarStroke viewBox="0 0 21 21" />
                </IconButton>
            );

            return wrapper ? (
                <div className={classNames("table-col", classes)}>{Elem}</div>
            ) : (
                Elem
            );
        };

        const MobileSendButton = ({ row }) => (
            <div className="table-row__footer">
                <div className="text-center">
                    <FlatButton
                        className="mui-btn mui-btn--xs mui-btn--border mui-btn--blue"
                        rippleColor={blue900}
                        label={
                            this.state.sendInProgress ? (
                                <CircleLoader
                                    thickness={2}
                                    size={12}
                                    color="#32a9e0"
                                    style={{ top: "7px" }}
                                />
                            ) : (
                                "ارسال نوتیفیکیشن"
                            )
                        }
                        disabled={this.state.sendInProgress}
                        onClick={() =>
                            this.sentTestPushNotification({
                                app_id: row.app_info.id,
                                pids: [row.pushe_id],
                            })
                        }
                    />
                </div>
            </div>
        );

        /**
         * use this function for sorting table rows based on
         * featured property being enabled/disabled
         */
        return (installationList || []).map(row => {
            // Check if row is in favList
            if ((favPusheIds || []).includes(row.pushe_id)) {
                row.isFave = true;
            }

            return (
                <div
                    key={uuid()}
                    className={classNames("table-row", {
                        "table-row--featured": row.isFave,
                    })}
                >
                    {/* Mobile Version: */}
                    <div className="single-row is-max-lg">
                        <div
                            className="row"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {installationDate({
                                row,
                                classes: "table-col--5 text-right",
                            })}
                            {favedIconButton({
                                row,
                                wrapper: true,
                                classes: "table-col--5 text-left",
                            })}
                        </div>
                        <div className="row text-right">
                            {brandIcon({ row })}
                            <br />
                            {deviceModel({ row })}
                        </div>
                        <div className="row text-right">
                            {appIcon({ row })}
                            {nameAndAppVersion({ row })}
                        </div>
                        <div className="row text-right">
                            {androidId({ row })}
                        </div>
                        <div className="row text-right">
                            {MobileSendButton({ row })}
                        </div>
                    </div>

                    {/* Desktop Version: */}
                    <div className={`single-row is-min-lg`}>
                        {brandIcon({ row, classes: "table-col--13" })}
                        {deviceModel({ row, classes: "table-col--14" })}
                        {androidId({ row, classes: "table-col--17" })}
                        {installationDate({ row, classes: "table-col--17" })}
                        {appIcon({ row, classes: "table-col--11" })}
                        {nameAndAppVersion({ row, classes: "table-col--17" })}
                        <div className="table-col table-col--11">
                            {sendTestIconButton({ row })}
                            {favedIconButton({ row })}
                        </div>
                    </div>

                    {showFavModal &&
                        clickedPusheId === row.pushe_id &&
                        ReactDOM.createPortal(
                            <FaveModal
                                responseError={responseErrorInProg}
                                onModalClose={() =>
                                    this.setState({ showFavModal: false })
                                }
                                onFaveInstallation={faveName => {
                                    const { faveInstallation } = this.props;
                                    const { clickedPusheId: pushe_id } =
                                        this.state;
                                    const onSuccess = () =>
                                        this.setState({
                                            showFavModal: false,
                                            clickedPusheId: null,
                                        });
                                    faveInstallation(
                                        { name: faveName, pushe_id },
                                        onSuccess,
                                        null,
                                    );
                                }}
                            />,
                            document.getElementById("mainWrap"),
                        )}

                    {showUnFavModal &&
                        clickedPusheId === row.pushe_id &&
                        ReactDOM.createPortal(
                            <UnFaveModal
                                data={this.state.clickedPusheId}
                                responseError={responseErrorInProg}
                                onModalClose={() =>
                                    this.setState({ showUnFavModal: false })
                                }
                                onUnFaveInstallation={() => {
                                    const { unFaveDevice } = this.props;
                                    const { clickedPusheId: pushe_id } =
                                        this.state;
                                    const callback = () =>
                                        this.setState({
                                            showUnFavModal: false,
                                            clickedPusheId: null,
                                        });
                                    unFaveDevice(
                                        { pushe_id },
                                        callback,
                                        callback,
                                    );
                                }}
                            />,
                            document.getElementById("mainWrap"),
                        )}

                    <ReactTooltip className="tooltip" effect="solid" />
                </div>
            );
        });
    }
}

export default withErrorCatcher(AndroidTableRows);
