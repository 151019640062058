import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import classNames from "classnames";
import { PLATFORMS } from "@/constants";
import { NavLink } from "react-router-dom";
import { ListItem } from "material-ui/List";
import { App, Notification, Phone } from "@/components/utils/lib/svgicons";
import PropTypes from "prop-types";

const SidebarMenuIos = props => {
    const { platform, checkPermissionOnClick, onMenuItemClick, history } =
        props;

    return (
        <div
            className={classNames("menu-catg", {
                "menu-catg--open": platform === PLATFORMS.IOS,
            })}
            onClick={() =>
                checkPermissionOnClick(PLATFORMS.IOS, null, () =>
                    history.push("/application/ios"),
                )
            }
        >
            <div className="menu-catg__header" id="ios-platform-button">
                <svg className="icon-ios">
                    <use href="#svg_ios_fill" />
                </svg>
                <span>iOS</span>
            </div>

            <div className="menu-catg__body">
                <NavLink to="/application/ios">
                    <ListItem
                        key={21}
                        className="menu-item"
                        primaryText="اپلیکیشن‌ها"
                        leftIcon={<App viewBox="0 0 21 21" />}
                        hoverColor="#ffffff"
                        onClick={onMenuItemClick}
                    />
                </NavLink>

                <NavLink to="/installation/ios">
                    <ListItem
                        key={22}
                        className="menu-item"
                        primaryText="نصب‌ها"
                        leftIcon={<Phone viewBox="0 0 21 21" />}
                        hoverColor="#ffffff"
                        onClick={onMenuItemClick}
                    />
                </NavLink>

                <NavLink to="/notification/ios">
                    <ListItem
                        key={23}
                        className="menu-item"
                        primaryText="نوتیفیکیشن‌ها"
                        leftIcon={<Notification viewBox="0 0 21 21" />}
                        hoverColor="#ffffff"
                        onClick={onMenuItemClick}
                    />
                </NavLink>
            </div>
        </div>
    );
};

SidebarMenuIos.defaultProps = {};
SidebarMenuIos.propTypes = {
    platform: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
    checkPermissionOnClick: PropTypes.func.isRequired,
};

export default withErrorCatcher(SidebarMenuIos);
