/**
 * use this function in async function to
 * await this and pause the code for provided ms
 *
 * @param ms
 * @returns {Promise<function(*=): number>}
 */

export const sleep = (ms = 0) =>
    Promise.resolve(resolve => setTimeout(resolve, ms));
