import React from "react";
import {
    CircleLoader,
    PERMISSIONS,
    useHasPermission,
    withErrorCatcher,
} from "@/components/utilities";
import { isEmpty } from "lodash";
import FixedPageHeader from "@/components/utilities/commons/FixedPageHeader";
import RefreshButton from "@/components/utilities/commons/RefreshButton";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import AutomationTableBody from "@/components/sms/automation/list/web/AutomationTableBody";
import WayPoint from "react-waypoint";
import ReactTooltip from "react-tooltip";
import AutomationPagePlaceholder from "@/components/sms/automation/list/AutomationPagePlaceholder";
import { useSMSAutomationApi } from "@/components/sms/hooks";
import { PLATFORMS } from "@/actions/_types";

const WebAutomationList = props => {
    const { history } = props;
    const platform = PLATFORMS.WEB;
    const hasCreatePermission = useHasPermission(
        PERMISSIONS.SMS_AUTOMATION_CREATE,
    );

    const {
        refetch,
        isLoading,
        fetchMore,
        noMoreData,
        automationItems,
        displayPagePlaceholder,
        updateMutation,
        deleteMutation,
    } = useSMSAutomationApi();

    if (displayPagePlaceholder)
        return <AutomationPagePlaceholder history={history} />;

    return (
        <div className="segments table--featured">
            {!isEmpty(automationItems) && !isLoading && (
                <FixedPageHeader>
                    <div className="segments__page-header">
                        <header>
                            <p>پیامک اتوماتیک</p>
                        </header>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <RefreshButton
                                animationIsRunning={isLoading}
                                tooltipPosition={"right"}
                                onClick={refetch}
                            />
                            <span
                                data-tip={
                                    hasCreatePermission
                                        ? ""
                                        : "برای ساخت پیامک اتوماتیک باید پنل خود را ارتقا دهید"
                                }
                            >
                                <FlatButton
                                    disabled={!hasCreatePermission}
                                    className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                                    rippleColor={blue900}
                                    label="پیامک اتوماتیک جدید"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() =>
                                        history.push(
                                            `/sms/automation/web/create/`,
                                        )
                                    }
                                />
                            </span>
                        </div>
                    </div>
                </FixedPageHeader>
            )}

            {!isEmpty(automationItems) && (
                <AutomationTableBody
                    history={history}
                    automationItems={automationItems}
                    updateAutomation={updateMutation}
                    deleteAutomation={deleteMutation}
                    platform={platform}
                />
            )}

            <div className="financial__loader">
                {isLoading && <CircleLoader size={30} />}
                {noMoreData && (
                    <p style={{ textAlign: "center", padding: "0 0 20px" }}>
                        اطلاعات جدیدی وجود ندارد
                    </p>
                )}
            </div>

            <WayPoint onEnter={() => fetchMore()} />

            <ReactTooltip className="tooltip" effect="solid" place="right" />
        </div>
    );
};

export default withErrorCatcher(WebAutomationList);
