import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import FloatingActionButton from "material-ui/FloatingActionButton";
import { FilterThick } from "@/components/utils/lib/svgicons";
import { withErrorCatcher } from "@/components/utilities";

const ApplicationFilterWrapper = ({
    isFilterOpen,
    closeFilter,
    openFilter,
    render,
}) => {
    return (
        <div
            className={classNames("table-filter", {
                "table-filter--show": isFilterOpen,
            })}
        >
            <div className="table-filter__bg" onClick={closeFilter} />
            <div className="table-filter__in">
                <div className="table-filter__form">
                    {render()}

                    <div className="table-filter__submit" onClick={closeFilter}>
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            label="اعمال فیلتر"
                            onClick={closeFilter}
                        />
                    </div>
                </div>
                <button
                    className="table-filter__close btn-close btn-close--md btn-close--darkblue"
                    onClick={closeFilter}
                ></button>
            </div>
            <div className="table-filter__btn-wrap">
                <FloatingActionButton
                    className="btn-filter"
                    onClick={openFilter}
                >
                    <FilterThick viewBox="0 0 22 22.321" />
                </FloatingActionButton>
            </div>
        </div>
    );
};

ApplicationFilterWrapper.propTypes = {
    closeFilter: PropTypes.func.isRequired,
    openFilter: PropTypes.func.isRequired,
    isFilterOpen: PropTypes.bool.isRequired,
};

ApplicationFilterWrapper.defaultProps = {};

export default withErrorCatcher(ApplicationFilterWrapper);
