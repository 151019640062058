/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PromptSettings from "application/guides/web/web/partials/PromptSettings";
import BellSettings from "application/guides/web/web/partials/BellSettings";
import GeolocationSettings from "application/guides/web/web/partials/GeolocationSettings";
import { saveWebSettings } from "@/actions/applications";
import { useDispatch } from "react-redux";
import {
    bell_defaults,
    prompt_defaults,
    geolocation_defaults,
} from "application/constants";
import {
    refineSDKPromptOptions,
    refineSDKGeolocationPromptOptions,
} from "@/components/utils/SDK/utils";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    tabsRoot: {
        padding: "20px 0",
    },
    tabRoot: {
        width: "33.33%",
    },
    tab: {
        color: `${theme.palette.dashboard.black} !important`,
        fontWeight: "bold",
        fontSize: "1rem",
    },
    underline: {
        backgroundColor: `${theme.palette.dashboard.blue} !important`,
        width: "33.33% !important",
    },
    footer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
    },
}));

export const TABS = {
    PROMPT: "1",
    BELL: "2",
    LOCATION: "3",
};

const WebSettingsStep = ({
    passToSteps: { application },
    initialize,
    handleSubmit,
    change,
}) => {
    const classes = useStyles();
    const [value, setValue] = useState(TABS.PROMPT);
    const handleChange = (_, newValue) => {
        change("activeTab", newValue);
        setValue(newValue);
    };

    useEffect(() => {
        const promptSettings = mergeSDKOptionsObjects(
            "prompt",
            prompt_defaults,
            application?.webpush_settings?.["prompt"],
        );
        const bellSettings = mergeSDKOptionsObjects(
            "bell",
            bell_defaults,
            application?.webpush_settings?.["bell"],
        );
        const geolocationSettings = mergeSDKOptionsObjects(
            "geolocation",
            geolocation_defaults,
            application?.webpush_settings?.["geolocation"],
        );

        initialize({
            prompt: { ...promptSettings },
            bell: { ...bellSettings },
            geolocation: { ...geolocationSettings },
        });

        change("activeTab", value);
    }, []);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Paper className={classes.root} elevation={0}>
                    <Grow in={true}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                            classes={{
                                root: classes.tabsRoot,
                                indicator: classes.underline,
                            }}
                        >
                            <Tab
                                classes={{
                                    root: classes.tabRoot,
                                    selected: classes.tab,
                                }}
                                value={TABS.PROMPT}
                                label="تنظیمات دیالوگ"
                            />
                            <Tab
                                classes={{
                                    root: classes.tabRoot,
                                    selected: classes.tab,
                                }}
                                value={TABS.BELL}
                                label="تنظیمات زنگوله"
                            />
                            <Tab
                                classes={{
                                    root: classes.tabRoot,
                                    selected: classes.tab,
                                }}
                                value={TABS.LOCATION}
                                label="تنظیمات موقعیت مکانی"
                            />
                        </Tabs>
                    </Grow>

                    {value === TABS.PROMPT && (
                        <PromptSettings
                            change={change}
                            isHttps={application?.is_https ?? false}
                        />
                    )}
                    {value === TABS.BELL && <BellSettings change={change} />}
                    {value === TABS.LOCATION && (
                        <GeolocationSettings
                            change={change}
                            isHttps={application.is_https ?? false}
                        />
                    )}
                </Paper>
            </form>
        </div>
    );
};

function mergeSDKOptionsObjects(type, defaults, options) {
    if (type === "prompt") {
        return {
            ...defaults,
            ...options,
        };
    } else if (type === "bell") {
        return {
            ...defaults,
            ...options,
            properties: {
                ...defaults?.properties,
                ...options?.properties,
            },
        };
    } else if (type === "geolocation") {
        return {
            ...defaults,
            ...options,
        };
    }
}

const WebSettingsRF = reduxForm({
    form: "webpush-settings",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebSettingsStep);

// In order to override the `BaseWizard` onSubmit props
// use this `wrap` component
const wrap = props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    const { application } = props?.passToSteps ?? {};

    const handleSubmitForm = values => {
        const prompt = refineSDKPromptOptions(values?.prompt, "API");
        const geolocation = refineSDKGeolocationPromptOptions(
            values?.geolocation,
        );
        const bell = values?.bell?.showBell
            ? values?.bell
            : { showBell: false };

        const payload = {
            prompt: { ...(prompt ?? {}) },
            bell: { ...(bell ?? {}) },
            geolocation: { ...(geolocation ?? {}) },
        };
        saveWebSettings(application.app_id, { webpush_settings: payload })(
            dispatch,
        );
    };

    return (
        <WebSettingsRF
            {...props}
            onSubmit={values => {
                handleSubmitForm(values);
                return values;
            }}
        />
    );
};

export default withErrorCatcher(wrap);
