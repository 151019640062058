/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    DateTimePicker,
    RadioButtons,
    usePrevious,
} from "@/components/utilities";
import classNames from "classnames";
import "styles/modules/_mod-card-timing.scss";
import { isEqual } from "lodash";
import { NOTIF_PUBLISH_MODE } from "@/components/notifications/notification/constants";
import { Field } from "redux-form";
import moment from "moment-timezone";

export const NotificationDisplay = ({
    scheduled_time,
    delay_until,
    change,
}) => {
    const [mode, setMode] = useState(
        delay_until
            ? NOTIF_PUBLISH_MODE.OPEN_APP.value
            : scheduled_time
            ? NOTIF_PUBLISH_MODE.SCHEDULED_TIME.value
            : NOTIF_PUBLISH_MODE.ASAP.value,
    );
    const prevMode = usePrevious(mode);

    useEffect(() => {
        if (mode === NOTIF_PUBLISH_MODE.ASAP.value) {
            change("data.scheduled_time", null);
            change("data.delay_until", null);
        } else if (mode === NOTIF_PUBLISH_MODE.OPEN_APP.value) {
            change("data.scheduled_time", null);
            change("data.delay_until", "open_app");
        } else if (mode === NOTIF_PUBLISH_MODE.SCHEDULED_TIME.value) {
            change("data.delay_until", null);
        }
    }, [!isEqual(prevMode, mode)]);

    return (
        <>
            <div className="notif-block__body notif-timing">
                <div className="notif-timing-type">
                    <RadioButtons
                        label={"زمان نمایش اعلان"}
                        defaultValue={mode}
                        options={[
                            {
                                label: NOTIF_PUBLISH_MODE.ASAP.label,
                                value: NOTIF_PUBLISH_MODE.ASAP.value,
                            },
                            {
                                label: NOTIF_PUBLISH_MODE.OPEN_APP.label,
                                value: NOTIF_PUBLISH_MODE.OPEN_APP.value,
                            },
                            {
                                label: NOTIF_PUBLISH_MODE.SCHEDULED_TIME.label,
                                value: NOTIF_PUBLISH_MODE.SCHEDULED_TIME.value,
                            },
                        ]}
                        onChange={setMode}
                    />
                </div>

                <div className="datepicker-wrapper">
                    <div
                        className={classNames({
                            "field-disable":
                                mode === NOTIF_PUBLISH_MODE.ASAP.value ||
                                mode === NOTIF_PUBLISH_MODE.OPEN_APP.value,
                        })}
                    >
                        {mode === NOTIF_PUBLISH_MODE.SCHEDULED_TIME.value && (
                            <Field
                                name="data.scheduled_time"
                                normalize={v =>
                                    moment(v).tz(moment.tz.guess()).format()
                                }
                                component={({
                                    input: { onChange, value },
                                    meta: { error },
                                }) => {
                                    return (
                                        <DateTimePicker
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            initDatetime={
                                                new Date(
                                                    new Date().getTime() +
                                                        60 * 60 * 24 * 1000 +
                                                        60000,
                                                )
                                            }
                                        />
                                    );
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotificationDisplay;
