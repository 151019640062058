/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
    CircleLoader,
    thousandSeparatorFarsi,
    toFarsiNumbers,
    withErrorCatcher,
} from "@/components/utilities";
import { useFetchInvoiceDetails } from "@/components/financial/hooks/useFetchInvoiceDetailes";
import { renderServiceName } from "@/components/financial/helpers";

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: "absolute",
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.dashboard.gray,
    },
    dialogContentRoot: {
        padding: "30px 10px",
        ["@media (min-width: 1200px)"]: {
            padding: "40px",
        },
    },
    dialogPaperRoot: {
        ["@media (max-width: 1199px)"]: {
            margin: "8px",
        },
        minWidth: "95%",
        ["@media (min-width: 1200px)"]: {
            minWidth: "680px",
        },
    },
}));

export const InvoiceDetailsDialog = props => {
    const { invoiceId, buttonLabel } = props;

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [inProgress, invoice, fetchInvoiceDetails] =
        useFetchInvoiceDetails(invoiceId);

    useEffect(() => {
        if (open && !invoice) fetchInvoiceDetails();
    }, [open]);

    return (
        <div>
            <Button
                variant={"outlined"}
                color={"primary"}
                disableRipple={true}
                onClick={handleClickOpen}
            >
                {buttonLabel}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="payment details dialog"
                maxWidth="lg"
                PaperProps={{
                    elevation: 0,
                    square: true,
                    classes: { root: classes.dialogPaperRoot },
                }}
            >
                <DialogTitle id="form-dialog-title">
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    {inProgress && <CircleLoader />}

                    {!inProgress && (
                        <div className="financialCostDialog">
                            <div className="financialCostDialog__header">
                                <div className="right">
                                    <span className="number">ردیف</span>
                                    <span className="services-label">
                                        شرح خدمات
                                    </span>
                                </div>
                                <div className="left">
                                    <span className="const-label">
                                        قیمت (ریال)
                                    </span>
                                    <span className="const-label">
                                        تخفیف (ریال)
                                    </span>
                                </div>
                            </div>
                            <div className="financialCostDialog__body">
                                {Array.isArray(
                                    invoice?.["details"]?.["services"],
                                ) &&
                                    invoice["details"]["services"].map(
                                        (i, idx) => {
                                            return (
                                                <div
                                                    className="financialCostDialog_row"
                                                    key={i["log_id"]}
                                                >
                                                    <div className="right">
                                                        <span className="number">
                                                            {toFarsiNumbers(
                                                                idx + 1,
                                                            )}
                                                        </span>
                                                        <span className="services-value">
                                                            {renderServiceName(
                                                                i[
                                                                    "service_name"
                                                                ],
                                                                i[
                                                                    "service_type"
                                                                ],
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="left">
                                                        <span className="const-value">
                                                            {thousandSeparatorFarsi(
                                                                i["net_amount"],
                                                            )}
                                                        </span>
                                                        <span className="const-value">
                                                            {thousandSeparatorFarsi(
                                                                i[
                                                                    "discount_amount"
                                                                ],
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                            </div>
                            <div className="financialCostDialog__footer">
                                <div className="financialCostDialog_row">
                                    <span className="label">جمع خدمات:‌ </span>
                                    <span className="value">
                                        {thousandSeparatorFarsi(
                                            invoice?.["details"]?.[
                                                "net_amount"
                                            ] ?? 0,
                                        )}
                                    </span>
                                </div>
                                <div className="financialCostDialog_row">
                                    <span className="label">
                                        تخفیف( کد تخفیف ):{" "}
                                    </span>
                                    <span className="value">
                                        {thousandSeparatorFarsi(
                                            invoice?.["details"]?.[
                                                "discount_amount"
                                            ] ?? 0,
                                        )}
                                    </span>
                                </div>
                                <div className="financialCostDialog_row">
                                    <span className="label">
                                        مالیات بر ارزش افزوده:{" "}
                                    </span>
                                    <span className="value">
                                        {thousandSeparatorFarsi(
                                            invoice?.["details"]?.[
                                                "added_value_tax"
                                            ] ?? 0,
                                        )}
                                    </span>
                                </div>
                                <div className="financialCostDialog_row total">
                                    <span className="label">جمع نهایی: </span>
                                    <span className="value">
                                        {thousandSeparatorFarsi(
                                            invoice?.["details"]?.[
                                                "payable_amount"
                                            ] ?? 0,
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default withErrorCatcher(InvoiceDetailsDialog);
