/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { Close, Search } from "@/components/utils/lib/svgicons";
import Select from "@/components/utilities/commons/select";
import { TIME_FILTER_OPTION } from "@/components/application/constants";
import Input from "@/components/utils/input";
import Util from "@/utils";
import isEqual from "lodash/isEqual";

class IosApplicationFilter extends React.Component {
    static propTypes = {
        applications: PropTypes.array.isRequired,
        offset: PropTypes.number.isRequired,
        onFilterChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        applications: [],
        offset: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            searchName: "",
            searchPkgName: "",
            searchTimeFilter: "",
        };
    }

    componentDidMount() {
        this.handleFilterApplications();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !isEqual(prevProps.applications, this.props.applications) ||
            prevState.searchName !== this.state.searchName ||
            prevState.searchTimeFilter !== this.state.searchTimeFilter ||
            prevProps.offset !== this.props.offset
        ) {
            this.handleFilterApplications();
        }
    }

    handleFilterApplications() {
        const { searchName, searchPkgName, searchTimeFilter } = this.state;
        const { applications, onFilterChange } = this.props;

        let filteredApplications = applications.filter(app => {
            if (searchName) {
                let name = app["name"] || "";

                return name.toLowerCase().includes(searchName.toLowerCase());
            }
            if (searchTimeFilter) {
                return Util._isOlderThan(searchTimeFilter, app.created_at);
            }
            if (searchPkgName) {
                let packageName = app["package_name"] || "";
                return packageName
                    .toLocaleLowerCase()
                    .includes(searchPkgName.toLowerCase());
            }
            return true;
        });

        onFilterChange({ filteredApplications });
    }

    render() {
        const { searchName, searchPkgName } = this.state;

        return (
            <div className="table-row">
                {/* Application Name Filter */}
                <div className="table-col table-col--25">
                    <Input
                        className="input--border-round"
                        placeholder="نام اپلیکیشن"
                        value={searchName}
                        onChange={e =>
                            this.setState({ searchName: e.target.value })
                        }
                    />

                    {!!searchName ? (
                        <Close
                            className="input--close-icon"
                            viewBox="0 0 26 26"
                            style={{ fill: "#b3b3b3" }}
                            onClick={() => this.setState({ searchName: "" })}
                        />
                    ) : (
                        <Search
                            className="input--search-icon"
                            viewBox="0 0 26 26"
                            style={{ fill: "#b3b3b3" }}
                        />
                    )}
                </div>

                {/* Application Package Name Filter */}
                <div className="table-col table-col--25">
                    <Input
                        className="input--border-round"
                        placeholder="نام اپلیکیشن"
                        value={searchPkgName}
                        onChange={e =>
                            this.setState({ searchPkgName: e.target.value })
                        }
                    />

                    {!!searchPkgName ? (
                        <Close
                            className="input--close-icon"
                            viewBox="0 0 26 26"
                            style={{ fill: "#b3b3b3" }}
                            onClick={() => this.setState({ searchPkgName: "" })}
                        />
                    ) : (
                        <Search
                            className="input--search-icon"
                            viewBox="0 0 26 26"
                            style={{ fill: "#b3b3b3" }}
                        />
                    )}
                </div>

                {/* Time Filter */}
                <div className="table-col table-col--25">
                    <Select
                        defaultSelect={TIME_FILTER_OPTION[0]}
                        className="select--border-round"
                        itemList={TIME_FILTER_OPTION}
                        onItemChange={item =>
                            this.setState({ searchTimeFilter: item.value })
                        }
                    />
                </div>
            </div>
        );
    }
}

export default withErrorCatcher(IosApplicationFilter);
