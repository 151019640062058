/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Util from "@/utils";
import classNames from "classnames";
import { iconList as ICONS_LIST } from "@/constants";

import "styles/modules/_mod-select";
import isEmpty from "lodash/isEmpty";
export default class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            selectedOption: props.defaultSelect || {},
        };
        this.$select = null;
    }

    componentDidMount() {
        this.$select = this.refs.select;

        this.$select.addEventListener("focus", function () {
            Util._addClass(this, "select--focus");
        });
        this.$select.addEventListener("blur", function () {
            Util._removeClass(this, "select--focus");
        });
    }

    render() {
        const { onItemChange, label, className, itemList } = this.props;
        const { isOpen, selectedOption } = this.state;

        const type =
            typeof this.props.type !== "undefined" ? this.props.type : "normal";
        const defaultSelect =
            typeof this.props.defaultSelect !== "undefined"
                ? this.props.defaultSelect
                : null;

        let selectActive;

        const selectList = itemList.map((item, index) => (
            <li
                className={classNames({ divider: item.isDivide })}
                tabIndex={-1}
                key={index}
                onClick={e => {
                    e.stopPropagation();
                    this.setState(
                        {
                            selectedOption: item,
                            isOpen: false,
                        },
                        onItemChange(item),
                    );
                }}
            >
                {type === "title-icon" && (
                    <p className="select-item select-item--icon">
                        <svg>
                            <use xlinkHref={`#${item.icon}`} />
                        </svg>
                        <span>{item.title}</span>
                    </p>
                )}
                {type === "normal" && (
                    <div className="select-item">
                        <span>{item.title}</span>
                    </div>
                )}
                {type === "icon" && (
                    <i className="material-icons">{item.title}</i>
                )}
            </li>
        ));

        const title = selectedOption.activeTitle || selectedOption.title;
        if (defaultSelect && type === "icon") {
            selectActive = <i className="material-icons">{title}</i>;
        } else {
            selectActive = title;
        }

        const isPlaceholder = !!selectedOption.placeholder;
        return (
            <div
                className={classNames(
                    "select",
                    { "select--open": isOpen },
                    `${className}`,
                )}
                ref="select"
                tabIndex={0}
                onClick={() => this.setState({ isOpen: true })}
                onKeyUp={e => {
                    switch (e.keyCode) {
                        case 32: // space
                        case 13: // enter
                            this.setState(prevState => ({
                                isOpen: !prevState.isOpen,
                            }));
                            break;
                    }
                }}
            >
                {label && isEmpty(selectedOption) && (
                    <div className="select-label">{label}</div>
                )}
                <div
                    className="select-underlay"
                    onClick={e => {
                        e.stopPropagation();
                        this.setState({
                            isOpen: false,
                        });
                    }}
                />

                <div
                    className={`select-active ${
                        isPlaceholder ? "select-placeholder" : ""
                    }`}
                >
                    {selectActive}
                </div>

                <ul className="select-list" ref="selectList">
                    {selectList}
                </ul>
            </div>
        );
    }
}

Select.propTypes = {
    className: PropTypes.string,
    defaultSelect: PropTypes.object,
    onItemChange: PropTypes.func,
    label: PropTypes.string,
};

export const SelectIcon = props => {
    const { onItemChange, label, icon } = props;
    const selectClass = props.className !== undefined ? props.className : "";

    let selectedIcon = ICONS_LIST[0];
    ICONS_LIST.map(item => {
        if (icon === item.title) {
            selectedIcon = item;
        }
    });

    onItemChange(selectedIcon);

    return (
        <Select
            className={`select--icon ${selectClass}`}
            onItemChange={onItemChange}
            type="icon"
            label={label}
            defaultSelect={selectedIcon}
            itemList={ICONS_LIST}
        />
    );
};

SelectIcon.propTypes = {
    className: PropTypes.string,
    defaultSelect: PropTypes.object,
    onItemChange: PropTypes.func.isRequired,
    label: PropTypes.string,
};

export const SelectIconField = ({ input: { value, onChange }, className }) => {
    return (
        <SelectIcon
            className={className}
            icon={value}
            onItemChange={v => onChange(v.title)}
        />
    );
};
