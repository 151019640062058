class EventHandler {
    constructor() {
        this.eventList = {};

        this.initWindowEvents();
    }

    initWindowEvents() {
        let resizeCallback = event => this.emit("resize", event);
        let scrollCallback = event => this.emit("scroll", event);
        let keyUpCallback = event => this.emit("keyup", event);

        window.addEventListener("resize", resizeCallback);
        window.addEventListener("scroll", scrollCallback);
        document.addEventListener("keyup", keyUpCallback);
    }

    on(event, callback, { key } = {}) {
        if (!this.eventList[event]) {
            this.eventList[event] = [];
        }

        if (callback) {
            this.eventList[event].push({ callback, key });
        }

        return this;
    }

    emit(event, args) {
        if (event in this.eventList) {
            this.eventList[event].forEach(({ callback }) => {
                if (typeof callback === "function") {
                    callback(args);
                }
            });
        }
    }

    remove(event, callbackOrKey) {
        if (!(event in this.eventList) || !Array.isArray(this.eventList[event]))
            return false;

        this.eventList[event] = this.eventList[event].filter(item => {
            return !(
                (typeof callbackOrKey === "string" &&
                    item.key === callbackOrKey) ||
                (typeof callbackOrKey === "function" &&
                    item.callback === callbackOrKey)
            );
        });
    }
}

export default new EventHandler();
