import Colors from "@/components/utils/lib/colors";

export function AndroidSeriesOfDaily(chart) {
    let uninstalls = {
        data: chart["last_uninstalls"],
        label: "حذف روزانه",
        fill: true,
        backgroundColor: "rgba(255, 158, 124, 0.12)",
        borderColor: Colors.orange,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: Colors.orange,
        pointBorderWidth: 2,
    };

    let installs = {
        data: chart["last_installs"],
        label: "نصب روزانه",
        fill: true,
        backgroundColor: "rgba(0, 230, 151, 0.12)",
        borderColor: Colors.green_0,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: Colors.green_0,
        pointBorderWidth: 2,
    };

    return [uninstalls, installs];
}
