import { defaultTo as dt, isFinite } from "lodash";
import SegmentSelectOptionField from "@/components/users/segmentation/components/fields/SegmentSelectOptionField";
import SegmentTextValueField from "@/components/users/segmentation/components/fields/SegmentTextValueField";
import SegmentAppVersion from "@/components/users/segmentation/components/fields/SegmentAppVersion";
import {
    hasPermission,
    PERMISSIONS,
    toEnglishNumbers,
} from "@/components/utilities";
import SegmentDateField from "@/components/users/segmentation/components/fields/SegmentDateField";
import SegmentTagKeysOptions from "@/components/users/segmentation/components/fields/SegmentTagKeysOptions";
import SegmentTagValuesSelection from "@/components/users/segmentation/components/fields/SegmentTagValuesSelection";
import SegmentAutoCompelet from "@/components/users/segmentation/components/fields/SegmentAutoCompelet";

export const SEGMENTS_VALUE_UNITS = {
    DAYS_AGO: "days_ago",
    SESSIONS: "sessions",
    MINUTES: "minutes",
    HOURS: "hours",
    TIMES: "times",
    DAYS: "days",
};

export const SEGMENTS_VALUE_UNITS_TRANSLATION = {
    [SEGMENTS_VALUE_UNITS.DAYS_AGO]: "روز پیش",
    [SEGMENTS_VALUE_UNITS.DAYS]: "روز",
    [SEGMENTS_VALUE_UNITS.SESSIONS]: "بازدید",
    [SEGMENTS_VALUE_UNITS.MINUTES]: "دقیقه پیش",
    [SEGMENTS_VALUE_UNITS.HOURS]: "ساعت پیش",
    [SEGMENTS_VALUE_UNITS.TIMES]: "بار",
};

export const SEG_KEYS = {
    SESSION_USER_LIFE: {
        ID: "user_life",
        API_KEY: "session",
        TYPES: {
            _: {
                value: "user_life",
                translation: "اولین بازدید - عمر کاربر",
            },
        },
    },
    SESSION_RELATIVE_CREATED_AT: {
        ID: "first_start_time",
        API_KEY: "session",
        TYPES: {
            _: {
                value: "first_start_time",
                translation: "اولین بازدید - تاریخ نصب",
            },
        },
    },
    SESSION_UPDATED_AT: {
        ID: "last_start_time",
        API_KEY: "session",
        TYPES: {
            RELATIVE_UPDATED_AT: {
                value: "last_start_time",
                translation: "آخرین بازدید",
            },
            UPDATED_AT: {
                value: "relative_visit_app",
                translation: "آخرین بازدید",
            },
        },
    },
    SESSION_COUNT: {
        ID: "session_count",
        API_KEY: "session",
        TYPES: {
            _: {
                value: "count",
                translation: "تعداد بازدید",
            },
        },
    },
    APP_VERSION: {
        ID: "app_version",
        API_KEY: "app_version",
        TYPES: null,
    },
    TEST_USERS: {
        ID: "test_users",
        API_KEY: "test_users",
        TYPES: null,
    },
    EVENTS: {
        ID: "events",
        API_KEY: "events",
        TYPES: {
            CREATED_AT: {
                value: "relative_created_at",
                translation: "اولین بار",
            },
            UPDATED_AT: {
                value: "relative_updated_at",
                translation: "آخرین بار",
            },
            COUNT: {
                value: "count",
                translation: "تعداد",
            },
        },
        TYPES_NAME: [
            {
                value: "test_1",
                translation: "تست 1",
            },
            {
                value: "test_2",
                translation: "تست 2",
            },
            {
                value: "test_3",
                translation: "تست3 ",
            },
        ],
    },

    TAG: {
        ID: "tags",
        API_KEY: "tags",
        TYPES: {
            _: {
                value: "count",
                translation: "مقدار",
            },
        },
    },
    STATE: {
        ID: "state",
        API_KEY: "state",
        TYPES: null,
    },
};

export const SEG_OPS = {
    LTE: (typeOrId, isD) => ({
        value: isD ? "lte-d" : "lte",
        translation: (type => {
            switch (type) {
                case SEG_KEYS.SESSION_USER_LIFE.TYPES._.value:
                case SEG_KEYS.SESSION_COUNT.ID:
                case SEG_KEYS.EVENTS.TYPES.COUNT.value:
                    return "کمتر از";
                case SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._.value:
                case SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value:
                case SEG_KEYS.EVENTS.TYPES.CREATED_AT.value:
                case SEG_KEYS.EVENTS.TYPES.UPDATED_AT.value:
                    return "قبل از";
                case SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT
                    .value:
                    return "قبل از تاریخ";
            }
        })(typeOrId),
    }),
    GTE: (typeOrId, isD) => ({
        value: isD ? "gte-d" : "gte",
        translation: (type => {
            switch (type) {
                case SEG_KEYS.SESSION_USER_LIFE.TYPES._.value:
                case SEG_KEYS.SESSION_COUNT.ID:
                case SEG_KEYS.EVENTS.TYPES.COUNT.value:
                    return "بیشتر از";
                case SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._.value:
                case SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value:
                case SEG_KEYS.EVENTS.TYPES.CREATED_AT.value:
                case SEG_KEYS.EVENTS.TYPES.UPDATED_AT.value:
                    return "بعد از";
                case SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT
                    .value:
                    return "بعد از تاریخ";
            }
        })(typeOrId),
    }),
    EQ: (typeOrId, isD) => ({
        value: isD ? "eq-d" : "eq",
        translation: (type => {
            switch (type) {
                case SEG_KEYS.SESSION_USER_LIFE.TYPES._.value:
                case SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._.value:
                case SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value:
                case SEG_KEYS.SESSION_COUNT.ID:
                case SEG_KEYS.EVENTS.TYPES.COUNT.value:
                case SEG_KEYS.EVENTS.TYPES.CREATED_AT.value:
                case SEG_KEYS.EVENTS.TYPES.UPDATED_AT.value:
                    return "مساوی";
                case SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT
                    .value:
                    return "در روز";
            }
        })(typeOrId),
    }),
    IN: {
        value: "in",
        translation: "همه موارد انتخاب شده",
    },
    NIN: {
        value: "nin",
        translation: "به جز موارد انتخاب شده",
    },
};
const seg_base_Items = {
    [SEG_KEYS.SESSION_USER_LIFE.ID]: {
        iid: SEG_KEYS.SESSION_USER_LIFE.ID,
        key: SEG_KEYS.SESSION_USER_LIFE.API_KEY,
        display: () => true,
        meta: {
            translation: SEG_KEYS.SESSION_USER_LIFE.TYPES._.translation,
            availableKeys: ["key", "op", "value"],
            getType: () => SEG_KEYS.SESSION_USER_LIFE.TYPES._.value,
            render: {
                op: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        label: "نوع عملیات*",
                        items: [
                            SEG_OPS.LTE(
                                SEG_KEYS.SESSION_USER_LIFE.TYPES._.value,
                            ),
                            SEG_OPS.GTE(
                                SEG_KEYS.SESSION_USER_LIFE.TYPES._.value,
                            ),
                            SEG_OPS.EQ(
                                SEG_KEYS.SESSION_USER_LIFE.TYPES._.value,
                            ),
                        ],
                    },
                },
                value: {
                    comp: SegmentTextValueField,
                    options: {
                        valueType: "textField",
                        valueUnit: SEGMENTS_VALUE_UNITS.DAYS,
                        fieldType: "text",
                        label: "مقدار*",
                        normalizeFn: value => {
                            const parseToEn = parseInt(toEnglishNumbers(value));
                            return !!value && isFinite(parseToEn)
                                ? parseToEn
                                : "";
                        },
                    },
                },
            },
        },
    },
    [SEG_KEYS.SESSION_UPDATED_AT.ID]: {
        iid: SEG_KEYS.SESSION_UPDATED_AT.ID,
        key: SEG_KEYS.SESSION_UPDATED_AT.API_KEY,
        display: () => true,
        meta: {
            translation: "آخرین بازدید",
            availableKeys: ["key", "op", "value"],
            getType: (_, op) => {
                switch (op) {
                    case SEG_OPS.LTE(
                        SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value,
                    ).value:
                    case SEG_OPS.GTE(
                        SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value,
                    ).value:
                    case SEG_OPS.EQ(
                        SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value,
                    ).value:
                        return SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT
                            .value;
                    case SEG_OPS.LTE(
                        SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT
                            .value,
                        true,
                    ).value:
                    case SEG_OPS.GTE(
                        SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT
                            .value,
                        true,
                    ).value:
                    case SEG_OPS.EQ(
                        SEG_KEYS.SESSION_UPDATED_AT.TYPES.RELATIVE_UPDATED_AT
                            .value,
                        true,
                    ).value:
                        return SEG_KEYS.SESSION_UPDATED_AT.TYPES
                            .RELATIVE_UPDATED_AT.value;
                }
            },
            render: {
                op: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        label: "نوع عملیات*",
                        items: [
                            SEG_OPS.LTE(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT
                                    .value,
                            ),
                            SEG_OPS.GTE(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT
                                    .value,
                            ),
                            SEG_OPS.EQ(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT
                                    .value,
                            ),
                            SEG_OPS.LTE(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES
                                    .RELATIVE_UPDATED_AT.value,
                                true,
                            ),
                            SEG_OPS.GTE(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES
                                    .RELATIVE_UPDATED_AT.value,
                                true,
                            ),
                            SEG_OPS.EQ(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES
                                    .RELATIVE_UPDATED_AT.value,
                                true,
                            ),
                        ],
                    },
                },
                value: (
                    _,
                    opType = SEG_OPS.LTE(
                        SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value,
                    ).value,
                ) => {
                    return opType ===
                        SEG_OPS.LTE(
                            SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT.value,
                        ).value ||
                        opType ===
                            SEG_OPS.GTE(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT
                                    .value,
                            ).value ||
                        opType ===
                            SEG_OPS.EQ(
                                SEG_KEYS.SESSION_UPDATED_AT.TYPES.UPDATED_AT
                                    .value,
                            ).value
                        ? {
                              comp: SegmentTextValueField,
                              options: {
                                  valueType: "textField",
                                  valueUnit: SEGMENTS_VALUE_UNITS.DAYS_AGO,
                                  fieldType: "text",
                                  label: "مقدار*",
                                  normalizeFn: value => {
                                      const parseToEn = parseInt(
                                          toEnglishNumbers(value),
                                      );
                                      return !!value && isFinite(parseToEn)
                                          ? parseToEn
                                          : "";
                                  },
                              },
                          }
                        : {
                              comp: SegmentDateField,
                              options: {
                                  valueType: "dateField",
                                  valueUnit: SEGMENTS_VALUE_UNITS.DAYS_AGO,
                                  label: "تاریخ",
                                  normalizeFn: value => {
                                      const parseToEn = parseInt(
                                          toEnglishNumbers(value),
                                      );
                                      return !!value && isFinite(parseToEn)
                                          ? parseToEn
                                          : "";
                                  },
                              },
                          };
                },
            },
        },
    },
    [SEG_KEYS.SESSION_COUNT.ID]: {
        iid: SEG_KEYS.SESSION_COUNT.ID,
        key: SEG_KEYS.SESSION_COUNT.API_KEY,
        display: () => true,
        meta: {
            translation: "تعداد بازدید‌ها",
            availableKeys: ["key", "op", "value"],
            getType: () => SEG_KEYS.SESSION_COUNT.TYPES._.value,
            render: {
                op: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        label: "نوع عملیات*",
                        items: [
                            SEG_OPS.LTE(SEG_KEYS.SESSION_COUNT.ID),
                            SEG_OPS.GTE(SEG_KEYS.SESSION_COUNT.ID),
                            SEG_OPS.EQ(SEG_KEYS.SESSION_COUNT.ID),
                        ],
                    },
                },
                value: {
                    comp: SegmentTextValueField,
                    options: {
                        valueType: "textField",
                        valueUnit: SEGMENTS_VALUE_UNITS.SESSIONS,
                        fieldType: "text",
                        label: "مقدار*",
                        normalizeFn: value => {
                            const parseToEn = parseInt(toEnglishNumbers(value));
                            return !!value && isFinite(parseToEn)
                                ? parseToEn
                                : "";
                        },
                    },
                },
            },
        },
    },
    [SEG_KEYS.APP_VERSION.ID]: {
        iid: SEG_KEYS.APP_VERSION.ID,
        key: SEG_KEYS.APP_VERSION.API_KEY,
        display: () => true,
        meta: {
            translation: "نسخه‌ی برنامه",
            availableKeys: ["key", "op", "value"],
            getType: () => null,
            render: {
                op: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        label: "نوع عملیات*",
                        items: [SEG_OPS.IN, SEG_OPS.NIN],
                        defaultValue: SEG_OPS.IN,
                    },
                },
                value: {
                    comp: SegmentAppVersion,
                    options: {
                        valueType: "selectField",
                        isCreatable: true,
                        placeholder: "انتخاب ورژن اپلیکیشن*",
                        mapping: { label: "label", value: "value" },
                    },
                },
            },
        },
    },
    [SEG_KEYS.TEST_USERS.ID]: {
        iid: SEG_KEYS.TEST_USERS.ID,
        key: SEG_KEYS.TEST_USERS.API_KEY,
        display: () => true,
        meta: {
            translation: "کاربران تستی",
            availableKeys: ["key", "value"],
            getType: () => null,
            render: {
                value: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        isCreatable: false,
                        items: [{ value: true, translation: "بله" }],
                        defaultValue: { value: true, translation: "بله" },
                    },
                },
            },
        },
    },
    [SEG_KEYS.EVENTS.ID]: {
        iid: SEG_KEYS.EVENTS.ID,
        key: SEG_KEYS.EVENTS.API_KEY,
        display: () => true,
        meta: {
            translation: "روید‌‌ادها",
            availableKeys: ["key", "name", "type", "op", "value"],
            getType: () => null,
            render: {
                name: {
                    comp: SegmentAutoCompelet,
                    options: {
                        valueType: "selectAutoComplete",
                        label: `نام*`,
                        // normalizeFn: (value) => {
                        //     return value.replace(/[\s]/, '_');
                        // }
                        items: [...SEG_KEYS.EVENTS.TYPES_NAME],
                    },
                },
                type: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        label: `نوع*`,
                        items: [
                            SEG_KEYS.EVENTS.TYPES.CREATED_AT,
                            SEG_KEYS.EVENTS.TYPES.UPDATED_AT,
                            SEG_KEYS.EVENTS.TYPES.COUNT,
                        ],
                    },
                },
                op: (kind = SEG_KEYS.EVENTS.TYPES.COUNT.value) => ({
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        label: "نوع عملیات*",
                        items:
                            kind === SEG_KEYS.EVENTS.TYPES.COUNT.value
                                ? [
                                      SEG_OPS.LTE(
                                          SEG_KEYS.EVENTS.TYPES.COUNT.value,
                                      ),
                                      SEG_OPS.GTE(
                                          SEG_KEYS.EVENTS.TYPES.COUNT.value,
                                      ),
                                      SEG_OPS.EQ(
                                          SEG_KEYS.EVENTS.TYPES.COUNT.value,
                                      ),
                                  ]
                                : [
                                      SEG_OPS.LTE(
                                          SEG_KEYS.EVENTS.TYPES.UPDATED_AT
                                              .value,
                                      ), // or CREATED_AT
                                      SEG_OPS.GTE(
                                          SEG_KEYS.EVENTS.TYPES.UPDATED_AT
                                              .value,
                                      ), // or CREATED_AT
                                      SEG_OPS.EQ(
                                          SEG_KEYS.EVENTS.TYPES.UPDATED_AT
                                              .value,
                                      ), // or CREATED_AT
                                  ],
                    },
                }),
                value: (kind = SEG_KEYS.EVENTS.TYPES.COUNT.value) => {
                    return kind === SEG_KEYS.EVENTS.TYPES.COUNT.value
                        ? {
                              comp: SegmentTextValueField,
                              options: {
                                  valueType: "textField",
                                  label: "مقدار*",
                                  valueUnit: SEGMENTS_VALUE_UNITS.TIMES,
                                  fieldType: "text",
                                  normalizeFn: value => {
                                      const parseToEn = parseInt(
                                          toEnglishNumbers(value),
                                      );
                                      return !!value && isFinite(parseToEn)
                                          ? parseToEn
                                          : "";
                                  },
                              },
                          }
                        : {
                              comp: SegmentTextValueField,
                              options: {
                                  valueType: "textField",
                                  valueUnit: SEGMENTS_VALUE_UNITS.DAYS_AGO,
                                  label: "مقدار",
                                  normalizeFn: value => {
                                      const parseToEn = parseInt(
                                          toEnglishNumbers(value),
                                      );
                                      return !!value && isFinite(parseToEn)
                                          ? parseToEn
                                          : "";
                                  },
                              },
                          };
                },
            },
        },
    },
    [SEG_KEYS.TAG.ID]: {
        iid: SEG_KEYS.TAG.ID,
        key: SEG_KEYS.TAG.API_KEY, // tags
        display: () => true,
        meta: {
            translation: "تگ‌ها",
            availableKeys: ["op", "vKey", "value"], // Key === "tags" , vKey === key that user enters

            getType: () => null,
            render: {
                op: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        isCreatable: false,
                        label: "نوع عملیات*",
                        items: [SEG_OPS.IN, SEG_OPS.NIN],
                    },
                },
                vKey: {
                    comp: SegmentTagKeysOptions,
                    options: {
                        valueType: "selectOptionField",
                        isCreatable: false,
                        label: "انتخاب تگ*",
                    },
                },
                value: {
                    comp: SegmentTagValuesSelection,
                    options: {
                        valueType: "selectField",
                        isCreatable: true,
                        placeholder: `مقادیر تگ*`,
                        fullWidth: true,
                        mapping: { label: "label", value: "value" },
                    },
                },
            },
        },
    },
    [SEG_KEYS.SESSION_RELATIVE_CREATED_AT.ID]: {
        iid: SEG_KEYS.SESSION_RELATIVE_CREATED_AT.ID,
        key: SEG_KEYS.SESSION_RELATIVE_CREATED_AT.API_KEY,
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
        meta: {
            translation: "اولین بازدید - تاریخ نصب",
            availableKeys: ["key", "op", "value"],
            getType: () => SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._.value,
            render: {
                op: {
                    comp: SegmentSelectOptionField,
                    options: {
                        valueType: "selectOptionField",
                        label: "نوع عملیات*",
                        items: [
                            SEG_OPS.GTE(
                                SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._
                                    .value,
                            ),
                            SEG_OPS.LTE(
                                SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._
                                    .value,
                            ),
                            SEG_OPS.EQ(
                                SEG_KEYS.SESSION_RELATIVE_CREATED_AT.TYPES._
                                    .value,
                            ),
                        ],
                    },
                },
                value: {
                    comp: SegmentDateField,
                    options: {
                        valueType: "dateField",
                        label: "تاریخ*",
                        valueUnit: SEGMENTS_VALUE_UNITS.DAYS_AGO,
                        valuePrefix: "absolute",
                        normalizeFn: value => {
                            const parseToEn = parseInt(toEnglishNumbers(value));
                            return !!value && isFinite(parseToEn)
                                ? parseToEn
                                : "";
                        },
                    },
                },
            },
        },
    },
    // [ SEG_KEYS.STATE.ID ]  :  {
    //     iid: SEG_KEYS.STATE.ID,
    //     key: SEG_KEYS.STATE.API_KEY,
    //     display: () => true,
    //     meta: {
    //         translation: "استان‌ها",
    //         availableKeys: ["key", "op", "value"],
    //         getType: () => null,
    //         render: {
    //             op: {
    //                 comp: SegmentSelectOptionField,
    //                 options: {
    //                     valueType: "selectOptionField",
    //                     items: [
    //                         SEG_OPS.IN,
    //                         SEG_OPS.NIN,
    //                     ],
    //                     defaultValue: SEG_OPS.IN,
    //                 }
    //             },
    //             value: {
    //                 comp: SegmentFilterSelectionField,
    //                 options: {
    //                     valueType: "selectField",
    //                     list: selectOptionBuilderFromArray(cities, "name", "key"),
    //                     isCreatable: false,
    //                     mapping: {label: "label", value: "value"},
    //                 }
    //             }
    //         }
    //     },
    // },
};
const ANDROID_SEG_ITEMS = [
    {
        ...seg_base_Items[SEG_KEYS.SESSION_USER_LIFE.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.SESSION_UPDATED_AT.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.SESSION_COUNT.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.APP_VERSION.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.TEST_USERS.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.EVENTS.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_EVENTS),
    },
    {
        ...seg_base_Items[SEG_KEYS.TAG.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_TAGS),
    },
];
const WEB_SEG_ITEMS = [
    {
        ...seg_base_Items[SEG_KEYS.SESSION_USER_LIFE.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.SESSION_UPDATED_AT.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.SESSION_COUNT.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_SESSIONS),
    },
    {
        ...seg_base_Items[SEG_KEYS.EVENTS.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_EVENTS),
    },
    {
        ...seg_base_Items[SEG_KEYS.TAG.ID],
        display: () => hasPermission(PERMISSIONS.SEGMENTATION_TAGS),
    },
];

// In order to have permissions, should run it in lazy mode
export const SEG_ITEMS = platform => {
    if (platform === "web") {
        return WEB_SEG_ITEMS.filter(i => i.display());
    } else {
        return ANDROID_SEG_ITEMS.filter(i => i.display());
    }
};

export const findSegment = iid => {
    const segment = SEG_ITEMS().find(item => item.iid === iid);
    return dt(segment, []);
};
