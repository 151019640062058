import React from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector, reduxForm } from "redux-form";
import { uploadFile } from "@/actions/app";
import { hasOneSignal as checkHasOneSignal } from "@/utils";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { isNotificationActionValid } from "@/components/notifications/notification/validation";
import {
    AndroidActions,
    WEB_ACTIONS,
} from "@/components/notifications/notification/actions";
import {
    arrayValidator,
    createValidator,
    isRequired,
    isJsonString,
    withMessage,
} from "@/components/utils/validation";
import WebNotificationButtonArray from "@/components/notifications/notification/create/web/controls/WebNotificationButtonArray";
import { withErrorCatcher } from "@/components/utilities";
import SelectApplicationComponent from "@/components/utilities/commons/wizard/components/SelectApplicationComponent";
import NotificationContentComponent from "@/components/utilities/commons/wizard/components/NotificationContentComponent";
import NotificationButtonsComponent from "@/components/utilities/commons/wizard/components/NotificationButtonsComponent";
import SelectNotificationType from "@/components/utilities/commons/wizard/components/SelectNotificationTypeComponent";
import { NOTIFICATION_MODE } from "@/actions/_types";
import { NOTIFICATION_TYPE } from "@/components/notifications/notification/constants";
import NotificationJsonComponent from "@/components/utilities/commons/wizard/components/NotificationJsonComponent";
import { some, values } from "lodash";

const WebNotifStep1 = ({
    applications,
    uploadFile,
    appSelectionMethod,
    hasOneSignal,
    change,
    passToSteps: { notificationMode },
    actionIsActive,
    notificationTypes,
}) => {
    const _toggleUploaderProgress = status => {
        change("uploaderInProgress", status);
    };
    const platform = useSelector(s => s?.app?.platform);
    return (
        <form className="notif-step1">
            <SelectNotificationType />
            {notificationMode === NOTIFICATION_MODE.NORMAL && (
                <SelectApplicationComponent
                    change={change}
                    hasOneSignal={hasOneSignal}
                    applications={applications}
                    appSelectionMethod={appSelectionMethod}
                    title={"وب‌سایت‌هایی که این نوتیفیکیشن را دریافت می‌کنند"}
                    platform={platform}
                    modelName={"وب‌سایت"}
                    modelNamePlural={"وب‌سایت‌ها"}
                />
            )}
            {notificationTypes[NOTIFICATION_TYPE.NOTIF] && (
                <NotificationContentComponent
                    change={change}
                    uploadFile={uploadFile}
                    actionsList={WEB_ACTIONS}
                    hasCloseOnClickField={true}
                    hasWebImageField={true}
                    actionIsActive={actionIsActive}
                />
            )}
            {notificationTypes[NOTIFICATION_TYPE.CUSTOM_CONTENT] &&
                !notificationTypes[NOTIFICATION_TYPE.NOTIF] && (
                    <NotificationContentComponent
                        change={change}
                        uploadFile={uploadFile}
                        actionsList={WEB_ACTIONS}
                        hasCloseOnClickField={false}
                        hasWebImageField={false}
                        hasSubTitleField={false}
                        hasIconField={false}
                        hasImageField={false}
                        hasActionField={false}
                        hasContentField={true}
                        hasTitleField={true}
                        actionIsActive={actionIsActive}
                    />
                )}
            <NotificationButtonsComponent
                title={
                    "دکمه‌های نوتیفیکیشن (فقط برای برخی ورژن‌های Chrome ، Edge و Opera)"
                }
                hasOneSignal={hasOneSignal}
                actionsList={WEB_ACTIONS}
                component={WebNotificationButtonArray}
                defaultSelectedAction={WEB_ACTIONS.find(
                    a => a.id === "close_notif",
                )}
            />

            {notificationTypes[NOTIFICATION_TYPE.CUSTOM_CONTENT] && (
                <NotificationJsonComponent />
            )}
        </form>
    );
};

const validators = createValidator({
    notificationTypes: value =>
        some(values(value), x => x === true)
            ? undefined
            : FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TYPE,
    app_ids: (value, key, values) =>
        values.isTestMode || (value && value.length) > 0
            ? undefined
            : FORM_ERRORS.NO_APP_SELECTED,
    data: {
        title: withMessage(
            isRequired,
            FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_TITLE,
        ),
        content: withMessage(
            isRequired,
            FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_CONTENT,
        ),
        action: isNotificationActionValid(AndroidActions),
        buttons: arrayValidator({
            btn_content: withMessage(
                isRequired,
                FORM_ERRORS.NOTIFICATION_FORM.REQUIRED_BTN_AND_ACTION,
            ),
            btn_action: isNotificationActionValid(AndroidActions),
        }),
    },
    update_av_code: (value, key, allValues) =>
        allValues.notificationMode === NOTIFICATION_MODE.UPDATE && !value
            ? FORM_ERRORS.REQUIRED_FIELD
            : undefined,
    custom_content: isJsonString,
});
function mapStateToProps(state) {
    const platform = "web";
    const {
        notificationTypes,
        appSelectionMethod,
        app_ids = [],
        data,
    } = formValueSelector("notification_wizard")(
        state,
        "notificationTypes",
        "appSelectionMethod",
        "app_ids",
        "data",
    );

    const applications = state[platform].applications || [];
    let hasOneSignal = checkHasOneSignal(app_ids, applications);

    return {
        hasOneSignal,
        platform,
        applications,
        appSelectionMethod,
        actionIsActive: data || [],
        notificationTypes: notificationTypes || {},
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ uploadFile }, dispatch);
}

const WebNotifStep1RF = reduxForm({
    form: "notification_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(WebNotifStep1);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(WebNotifStep1RF),
    ),
);
