import React from "react";
import connect from "react-redux/es/connect/connect";
import { getFormValues, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux/es/redux";
import PropTypes from "prop-types";
import FlatButton from "material-ui/FlatButton/index";
import { CircleLoader } from "@/components/utilities";
import { NOTIFICATION_MODE } from "@/actions/_types";
import { withErrorCatcher } from "@/components/utilities";
import { blue900 } from "material-ui/styles/colors";
import { estimateRecipients } from "@/actions/notifications";
import { normalize } from "@/components/utils/normalize";
import { androidNotificationNormalizer } from "@/components/notifications/notification/normalize";

class NotificationFormFinalStep extends React.Component {
    static propTypes = {
        onEstimateRecipients: PropTypes.func,
        notificationMode: PropTypes.number,
    };

    static defaultProps = {
        notificationMode: NOTIFICATION_MODE.NORMAL,
    };

    constructor(props) {
        super(props);
        this.isNormalMode =
            this.props.notificationMode === NOTIFICATION_MODE.NORMAL;
        this.notifBodyText =
            this.isNormalMode && !this.props.draftMode
                ? "آیا از ارسال این پیام به همه کاربرانتان اطمینان دارید؟"
                : this.props.draftMode
                ? "آیا از ذخیره این پیش‌نویس اطمینان دارید؟"
                : "آیا از ذخیره‌کردن این پیام اطمینان دارید؟";

        this.state = {
            loading: false,
            estimateCount: null,
        };
    }

    UNSAFE_componentWillUpdate(_, prevState) {
        if (this.state.estimateCount !== prevState.estimateCount) {
            this.setState({ loading: false });
        }
    }

    renderEstimateButton() {
        const { loading, estimateCount } = this.state;

        return (
            <div className="card">
                {!loading && (
                    <svg className="card-icon">
                        <use xlinkHref="#svg_audience" />
                    </svg>
                )}

                {!loading && estimateCount == null && (
                    <FlatButton
                        className="mui-btn mui-btn--md mui-btn--blue mui-btn--solid"
                        rippleColor={blue900}
                        label="تخمین تعداد مخاطب"
                        onClick={e => {
                            e.preventDefault();
                            this.setState({ loading: true }, () => {
                                this.props.estimateRecipients(
                                    normalize(
                                        this.props.formValues,
                                        androidNotificationNormalizer,
                                    ),
                                    estimateCount =>
                                        this.setState({ estimateCount }),
                                    () => this.setState({ loading: false }),
                                );
                            });
                        }}
                    />
                )}

                {loading && (
                    <CircleLoader size={40} style={{ margin: "20px auto 0" }} />
                )}

                {estimateCount != null && (
                    <p className="card-text text-fa">{` حدودا:${estimateCount}`}</p>
                )}
            </div>
        );
    }

    render() {
        return (
            <form className="notif-finale">
                <div className="notif-block">
                    <header>
                        {this.props.draftMode ? (
                            <p className="notif-save-reassurance__title">
                                ذخیره پیش‌نویس
                            </p>
                        ) : (
                            <p className="notif-save-reassurance__title">
                                نتیجه‌ی ارسال نوتیفیکیشن
                            </p>
                        )}
                    </header>
                    <div className="notif-block__body">
                        <p>{this.notifBodyText}</p>
                        {this.isNormalMode &&
                            this.props.platform !== "web" &&
                            this.renderEstimateButton()}
                        {!this.props.draftMode && (
                            <p className="color-error-c text-sm">
                                پیش نمایش نوتیفیکیشن با نمایش آن در گوشی‌های
                                مختلف متفاوت خواهد بود
                            </p>
                        )}
                    </div>
                </div>
            </form>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const {
        passToSteps: { platform },
    } = ownProps;

    return {
        platform,
        formValues: getFormValues("notification_wizard")(state) || {},
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ estimateRecipients }, dispatch);
}

const NotificationFormFinalStepRF = reduxForm({
    form: "notification_wizard",
    validate: NotificationFormFinalStep.validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(NotificationFormFinalStep);

export default withRouter(
    withErrorCatcher(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(NotificationFormFinalStepRF),
    ),
);
