import React from "react";
import Util from "@/utils";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";

const CreatedAt = ({ app: { created_at }, isSmallSize, colSize }) => {
    const classes = !isSmallSize
        ? `table-col table-col--${colSize} color-gray-95`
        : "small-dateTime";

    return (
        <div className={classes}>
            {Util._convertToLocalTimezone(created_at)}
        </div>
    );
};

CreatedAt.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
};

CreatedAt.defaultProps = {
    app: {},
    colSize: 20,
};

export default withErrorCatcher(CreatedAt);
