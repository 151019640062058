import { isEmpty } from "lodash";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";

export const isNotificationActionValid = actionList => value => {
    if (!value || isEmpty(value)) {
        return;
    }

    const errors = {};
    const actionData = value || {};
    const paramData = actionData.params || {};

    const actionDetails = actionList.find(a => a.id === actionData.id);

    if (!actionDetails) {
        return FORM_ERRORS.NOTIFICATION_FORM.INVALID_ACTION;
    }

    for (let paramOptions of actionDetails.params || []) {
        if (!paramOptions.validate) {
            continue;
        }

        const validators = Array.isArray(paramOptions.validate)
            ? paramOptions.validate
            : [paramOptions.validate];
        for (let validator of validators) {
            const error = validator(paramData[paramOptions.name]);
            if (error) {
                errors[paramOptions.name] = error;
                break;
            }
        }
    }

    return isEmpty(errors) ? undefined : errors;
};
