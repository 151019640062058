import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import WizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import { createInitialFormData } from "@/components/email/email/create/android/controls/createInitialFormData";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import { PLATFORMS } from "@/constants";
import { emailSendNormalizer } from "@/components/email/normalize";
import { normalize } from "@/components/utils/normalize";
import { EMAILS_KEYS, estimateRecipients, sendEmail } from "@/queries/email";
import { onSuccessAppendToList } from "@/queries/constants";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { clearForm } from "@/actions/app";
import WizardStepFinale from "@/components/utilities/commons/wizard/controls/WizardStepFinale";
import AndroidSendTestModal from "@/components/email/email/create/android/partials/AndroidSendTestModal";
import { successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import WebEmailStep1 from "@/components/email/email/create/web/steps/WebEmailStep1";
import WebEmailStep2 from "@/components/email/email/create/web/steps/WebEmailStep2";
import WebEmailStep3 from "@/components/email/email/create/web/steps/WebEmailStep3";

const WebEmailWizard = props => {
    const { history, email } = props;
    const dispatch = useDispatch();

    const [sendEmailMutation] = useMutation(sendEmail, {
        onSuccess: data => {
            onSuccessAppendToList(EMAILS_KEYS.LIST(), data);
            successToast(
                TOAST_CONTENT.SEND_EMAIL_SUCCESS.TITLE,
                TOAST_CONTENT.SEND_EMAIL_SUCCESS.DESC,
            );
        },
    });

    return (
        <BaseWizard
            formName="web_email_wizard"
            title="ارسال ایمیل"
            subtitle="با این فرم می‌توانید به کاربران سایت خود ایمیل ارسال کنید"
            history={history}
            steps={steps}
            stepFinale={WizardStepFinale}
            preview={undefined}
            testModal={AndroidSendTestModal}
            footer={WizardFooter}
            formInitialData={createInitialFormData(email)}
            onMountCallback={() => {
                // Do nothing yet
            }}
            onUnmountCallback={() => {
                clearForm("web_email_wizard ")(dispatch);
                history.push(`/email/web`);
            }}
            submitAction={async (data, onSuccess, onError) => {
                const normalizedValues = normalize(data, emailSendNormalizer);
                await sendEmailMutation(normalizedValues, {
                    onSuccess,
                    onError,
                });
            }}
            buttonOptions={{}}
            hasReduxForm={true}
            passToSteps={{
                platform: PLATFORMS.WEB,
                // Only for final step
                estimateMutationFunc: estimateRecipients,
                estimateNormalizer: emailSendNormalizer,
                messageTypeName: "ایمیل",
            }}
        />
    );
};

const steps = [
    {
        component: WebEmailStep1,
        showTestBtn: true,
        isEnabled: _values => true,
    },
    {
        component: WebEmailStep2,
        showTestBtn: true,
        isEnabled: _values => true,
    },
    {
        component: WebEmailStep3,
        showTestBtn: true,
    },
];

export default withErrorCatcher(WebEmailWizard);
