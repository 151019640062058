import { getApi } from "@/api";
import actionTypes from "@/actions/_types";
import { isValidPlatform } from "@/actions/_utils.jsx";

export function fetchHints() {
    return function (dispatch, getState) {
        const { platform } = getState().app;
        if (isValidPlatform(platform, dispatch)) {
            getApi()
                .get(`dashboards/hints/${platform}/`)
                .then(resp => {
                    dispatch({
                        payload: resp.data,
                        type: actionTypes.HINT[
                            `FETCH_${platform.toUpperCase()}_HINTS`
                        ],
                    });
                });
        }
    };
}
