import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { isContentFarsi } from "@/components/sms/sms/create/general/hooks/useCreateSMSRemaining";
import { htmlParser } from "@/components/utilities/extra/htmlParser";

const SMSTab = props => {
    const { notification } = props;
    const { content = "" } = notification?.data ?? {};

    const isFarsi = () => isContentFarsi(content);
    const normalizeContent = content => content?.replace(/\n/g, "<br/>");
    const normalizedContent = normalizeContent(content);

    const style = {
        direction: isFarsi() ? "rtl" : "ltr",
        padding: "4px 14px",
    };

    return <div style={style}>{htmlParser.parse(normalizedContent)}</div>;
};

export default withErrorCatcher(SMSTab);
