import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import "styles/modules/_mod-not-found.scss";
const images = {
    four: require("images/notFoundPage/four.png"),
    bell: require("images/notFoundPage/bell.png"),
    fancyUnderline: require("images/notFoundPage/fancy-underline.png"),
    marsTop: require("images/notFoundPage/mars-top.png"),
};

const NotFoundPage = () => {
    return (
        <div className="notFoundPage" data-page="error">
            <div className="fourOfour">
                <div className="sign">
                    <div className="sign-image">
                        <img className="four" src={images.four} />
                        <img className="bell" src={images.bell} />
                        <img className="four" src={images.four} />
                    </div>
                    <div className="sign-title">یک پیام جدید!</div>
                    <div className="sign-subtitle">
                        شما راهتان را گم کرده‌اید!
                    </div>
                    <a className="way-out" href="/">
                        مرا به خانه برگردان!
                        <br />
                        <svg>
                            <use href="#svg_paper-plane" />
                        </svg>
                        <img src={images.fancyUnderline} />
                    </a>
                </div>
                <div className="footer-sign">
                    <img src={images.marsTop} />
                </div>
                <ul className="bg-error-circule">
                    <li />
                    <li />
                    <li />
                </ul>
                <ul className="bg-error-star">
                    <li>
                        <svg>
                            <use href="#svg_star" />
                        </svg>
                    </li>
                    <li>
                        <svg>
                            <use href="#svg_star" />
                        </svg>
                    </li>
                    <li>
                        <svg>
                            <use href="#svg_star" />
                        </svg>
                    </li>
                    <li>
                        <svg>
                            <use href="#svg_star" />
                        </svg>
                    </li>
                    <li>
                        <svg>
                            <use href="#svg_star" />
                        </svg>
                    </li>
                    <li>
                        <svg>
                            <use href="#svg_star" />
                        </svg>
                    </li>

                    <li className="dot" />
                    <li className="dot" />
                    <li className="dot" />
                    <li className="dot" />
                </ul>
                <ul className="bg-error-paper-plan">
                    <li id="plane_1">
                        <svg>
                            <use href="#svg_paper-plane" />
                        </svg>
                    </li>
                    <li id="plane_2">
                        <svg>
                            <use href="#svg_paper-plane" />
                        </svg>
                    </li>
                    <li id="plane_3">
                        <svg>
                            <use href="#svg_paper-plane" />
                        </svg>
                    </li>
                    <li id="plane_4">
                        <svg>
                            <use href="#svg_paper-plane" />
                        </svg>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default withErrorCatcher(NotFoundPage);
