/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { Field, formValueSelector } from "redux-form";
import PageFilterSelect from "@/components/utils/finals/PageFilterSelect";
import { selectOptionBuilderFromArray } from "@/components/utils/finals/selectOptionBuilder";
import SegmentField from "@/components/users/segmentation/components/SegmentField";
import { SEG_ITEMS } from "@/components/users/segmentation/constants";
import { connect } from "react-redux";

const SegmentsFields = ({
    fields,
    deleteWrapperFields,
    isReadonly,
    platform,
}) => {
    const segmentsOptions = useMemo(
        () =>
            SEG_ITEMS(platform).map(item => ({
                iid: item.iid,
                key: item.key,
                label: item.meta.translation,
            })),
        [SEG_ITEMS()],
    );
    return (
        <div className="segments__andWrapper">
            {fields.map((item, idx, fields) => {
                const allFields = fields.getAll();
                const showJoinIcon = allFields.length - 1 > idx;
                return (
                    <div
                        style={{ position: "relative" }}
                        key={allFields[idx].iid}
                    >
                        <Field
                            name={item}
                            component={SegmentField}
                            isReadonly={isReadonly}
                            initData={allFields[idx]}
                        />

                        {showJoinIcon && (
                            <svg className="segments__join-icon">
                                <use href="#svg_join" />
                            </svg>
                        )}

                        {/* Remove Row button */}
                        {!isReadonly && (
                            <svg
                                className="segments__trash-icon"
                                onClick={() => {
                                    fields.remove(idx);
                                    // Delete the wrapper
                                    if (fields.length === 1) {
                                        deleteWrapperFields();
                                    }
                                }}
                            >
                                <use href="#svg_trash" />
                            </svg>
                        )}
                    </div>
                );
            })}

            {!isReadonly && (
                <PageFilterSelect
                    placeholder="اضافه کردن فیلتر جدید"
                    options={selectOptionBuilderFromArray(
                        segmentsOptions,
                        "label",
                        "iid",
                    )}
                    clearBoxAfterSelect={true}
                    renderFormTag={false}
                    onSelect={value => {
                        const segment = segmentsOptions.find(
                            i => i.iid === value,
                        );
                        if (segment) {
                            fields.push(segment);
                        }
                    }}
                />
            )}
        </div>
    );
};
const mapStateToProps = state => {
    const appId = formValueSelector("segments")(
        state,
        "segment",
        "name",
        "app_id",
        "segment_id",
    );
    const { platform } = state.app;
    return {
        appId,
        platform,
    };
};
export default withErrorCatcher(connect(mapStateToProps)(SegmentsFields));
