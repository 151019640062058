export const LIMITED = {
    category: "Accounting",
    action: "Limited",
    label: "Sending Capacity Reached in Functional",
};

export const EVENTS_FILTER = {
    category: "Send Notif",
    action: "IEvents Filter",
    label: "",
};

export const SESSION_FILTER = {
    category: "Send Notif",
    action: "Session Filter",
    label: "",
};

export const NOTIFICATION_ACTION = {
    category: "Send Notif",
    action: "",
    label: "",
};
