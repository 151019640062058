import React from "react";
import InstallationPagePlaceholder from "@/components/users/installations/common/InstallationPagePlaceholder";
import InstallationsTableBody from "@/components/users/installations/web/component/table/webInnstallationsTable";
import WebInstallationsChart from "@/components/users/installations/web/component/WebInstallationsChart/WebInstallationsChart";
import UseWebInstallationList from "@/components/users/installations/web/useWebInstallationList";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";

const WebInstallationsList = () => {
    const {
        platform,
        dashboard,
        isLoading,
        favorites,
        deleteFavInstall,
        updateWebFavInstall,
    } = UseWebInstallationList();

    if (isLoading) {
        return <CircleLoader size={50} thickness={4} />;
    } else if (dashboard?.distributions?.is_empty && !isLoading) {
        return <InstallationPagePlaceholder />;
    }

    return (
        <>
            <WebInstallationsChart dashboard={dashboard} platform={platform} />
            {favorites.length > 0 ? (
                <InstallationsTableBody
                    isReFetchLoading={isLoading}
                    installationList={favorites}
                    deleteFavInstall={deleteFavInstall}
                    updateFavInstall={updateWebFavInstall}
                />
            ) : (
                "لیست شما خالی است "
            )}
        </>
    );
};

export default withErrorCatcher(WebInstallationsList);
