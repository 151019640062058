import React, { useMemo } from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import "styles/modules/_mod-step";

const WizardStepsHeader = ({ steps, activeStep }) => {
    const renderLists = (activeStep, steps) =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useMemo(() => {
            const lists = [...new Array(steps)].map((_, idx) => {
                const liClass = classNames(
                    "step-item",
                    { "step-item--passed": idx < activeStep },
                    { "step-item--active": idx === activeStep - 1 },
                );

                return (
                    <li
                        className={liClass}
                        key={idx}
                        style={{ right: `${(100 / (steps - 1)) * idx}%` }}
                    >
                        <span className="text-fa">{idx + 1}</span>
                    </li>
                );
            });

            return !isEmpty(lists) && <ul>{lists}</ul>;
        }, [activeStep, steps]);

    return (
        <div className="step">
            <div className="step-wrap">
                <div className="step-line text-fa">
                    <span
                        style={{
                            transform: `translateX(${-(
                                ((activeStep - 1) / (steps - 1)) *
                                100
                            )}%)`,
                        }}
                    />
                </div>

                {renderLists(activeStep, steps)}
            </div>
        </div>
    );
};

WizardStepsHeader.propTypes = {
    steps: PropTypes.number.isRequired,
    activeStep: PropTypes.number.isRequired,
};

export default withErrorCatcher(WizardStepsHeader);
