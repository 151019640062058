import { Field } from "redux-form";
import FilterSelectionField from "@/components/utils/FilterSelectionField";
import {
    getApplicationِِBrowserList,
    getApplicationِِِDeviceType,
} from "@/actions/applications";
import { store } from "@/store";

export default [
    {
        title: "نوع دستگاه",
        key: "device_type",
        reduxKey: "filters.device_type",
        icon: "svg_filter_version",
        isActive: filters => !!filters?.deviceTypes,
        isDisabled: false,
        tooltip: undefined,
        action: ({ dispatch, app_id }) => {
            if (app_id)
                getApplicationِِِDeviceType({ app_id })(
                    dispatch,
                    store.getState,
                );
        },
        _parentComponent: Field,
        _childComponent: FilterSelectionField,
        childExtraProps: ({ DeviceTypes }) => {
            return {
                list: DeviceTypes,
            };
        },
    },
    {
        title: "مرورگر",
        key: "browser",
        reduxKey: "filters.browser",
        icon: "svg_filter_operator",
        isActive: filters => !!filters?.browser,
        isDisabled: false,
        tooltip: undefined,
        action: ({ dispatch, app_id }) => {
            if (app_id)
                getApplicationِِBrowserList({ app_id })(
                    dispatch,
                    store.getState,
                );
        },
        _parentComponent: Field,
        _childComponent: FilterSelectionField,
        childExtraProps: ({ BrowserList }) => {
            return {
                list: BrowserList,
            };
        },
    },
];
