import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";
import WebServiceTab from "@/components/app/profile/Tabs/WebServiceTab";
import AccountSettings from "@/components/app/profile/Tabs/AccountSecurityTab";
import { makeInitialValues } from "@/components/utilities/extra/makeInitialValues";
import UserInfoTab from "@/components/app/profile/Tabs/UserInfoTab";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";
import HttpIcon from "@material-ui/icons/Http";
import KeyIcon from "@material-ui/icons/VpnKey";
import CredentialsTab from "@/components/app/profile/Tabs/CredentialsTab";
import { PROFILE_TABS } from "@/components/app/profile/constants";
import Config from "@/components/app/profile/Tabs/ConfigTab/ConfigTab";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles(theme => ({
    paperRoot: {
        backgroundColor: theme.palette.dashboard.blue,
    },
    root: {
        color: theme.palette.dashboard.white,
        fontFamily: theme.typography.fontFamily.bold,
    },
    indicator: {
        backgroundColor: theme.palette.dashboard.white,
    },
}));

const ProfileTabs = props => {
    const { profileTab, history } = props;
    const classes = useStyles();
    const profile = useSelector(state => state?.auth?.profile ?? {});

    return (
        <>
            <Paper square classes={{ root: classes.paperRoot }}>
                <Grow in={true}>
                    <Tabs
                        value={
                            Object.values(PROFILE_TABS)?.find(
                                t => t.KEY === profileTab,
                            )?.VALUE ?? PROFILE_TABS.INFORMATION.VALUE
                        }
                        onChange={(_, val) => history.push(`/account/${val}`)}
                        variant="fullWidth"
                        aria-label="Profile Tabs"
                        classes={{
                            indicator: classes.indicator,
                            root: classes.root,
                        }}
                    >
                        <Tab
                            icon={<PersonIcon />}
                            value={PROFILE_TABS.INFORMATION.VALUE}
                            label={PROFILE_TABS.INFORMATION.LABEL}
                        />
                        <Tab
                            icon={<HttpIcon />}
                            value={PROFILE_TABS.API.VALUE}
                            label={PROFILE_TABS.API.LABEL}
                        />
                        <Tab
                            icon={<KeyIcon />}
                            value={PROFILE_TABS.CREDENTIALS.VALUE}
                            label={PROFILE_TABS.CREDENTIALS.LABEL}
                        />
                        <Tab
                            icon={<VerifiedUserIcon />}
                            value={PROFILE_TABS.SECURITY.VALUE}
                            label={PROFILE_TABS.SECURITY.LABEL}
                        />
                        <Tab
                            icon={<SettingsIcon />}
                            value={PROFILE_TABS.CONFIG.VALUE}
                            label={PROFILE_TABS.CONFIG.LABEL}
                        />
                    </Tabs>
                </Grow>
            </Paper>

            {profileTab === PROFILE_TABS.INFORMATION.KEY && (
                <UserInfoTab
                    initialValues={makeInitialValues(profile, [
                        "email",
                        "first_name",
                        "last_name",
                        "phone_number_1",
                        "national_number",
                        "picture",
                        "national_legal_id",
                        "financial_number",
                        "postal_code",
                        "address",
                        "legal_phone_number",
                    ])}
                />
            )}

            {profileTab === PROFILE_TABS.API.KEY && <WebServiceTab />}

            {profileTab === PROFILE_TABS.CREDENTIALS.KEY && <CredentialsTab />}

            {profileTab === PROFILE_TABS.SECURITY.KEY && (
                <AccountSettings
                    initialValues={makeInitialValues(profile, [
                        "email",
                        "email_2fa_is_active",
                    ])}
                />
            )}
            {profileTab === PROFILE_TABS.CONFIG.KEY && <Config />}
        </>
    );
};

export default withRouter(ProfileTabs);
