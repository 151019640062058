/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    CircleLoader,
    PERMISSIONS,
    useHasPermission,
    usePrevious,
    withErrorCatcher,
} from "@/components/utilities";
import "styles/modules/_mod-segments.scss";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import SegmentsTableBody from "@/components/users/segmentation/components/SegmentsTableBody";
import SegmentsModal from "@/components/users/segmentation/components/SegmentsModal";
import { bindActionCreators } from "redux";
import { deleteSegmentation, fetchSegmentation } from "@/actions/segmentations";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import RefreshButton from "@/components/utilities/commons/RefreshButton";
import FixedPageHeader from "@/components/utilities/commons/FixedPageHeader";
import ReactTooltip from "react-tooltip";
import SegmentationPagePlaceholder from "@/components/users/segmentation/SegmentationPagePlaceholder";

const SegmentationPage = ({
    fetchSegmentation,
    segmentationList,
    deleteSegmentation,
}) => {
    const [isLoading, toggleIsLoading] = useState(true);
    // To check if initial request resolved when page is loaded
    const [finishedInitLoad, toggleInitLoad] = useState(false);
    const [showSegmentModal, toggleSegmentModal] = useState({
        show: false,
        initialValues: null,
        action: "none",
        readonly: false,
    });
    const openAndInitializeModal = () =>
        toggleSegmentModal({ show: true, initialValues: null });

    const prevShowModal = usePrevious(showSegmentModal || false);
    const handleFetchSegmentation = (init = false) => {
        // After req resolve or reject,
        // check isLoading to false,
        // check finishedInitLoad to true (in init req)
        const callback = () =>
            setTimeout(() => {
                toggleIsLoading(false);
                init && toggleInitLoad(true);
            }, 500);

        toggleIsLoading(true);
        fetchSegmentation({}, callback, callback);
    };

    // Init fetchSegmentation req
    useEffect(() => {
        handleFetchSegmentation(true);

        // Scroll to top of page to make all row visible
        window.scrollTo(0, 0);
    }, []);
    // Whenever modal closed (with save action)
    useEffect(() => {
        if (
            prevShowModal &&
            !showSegmentModal.show &&
            showSegmentModal.action === "save"
        ) {
            setTimeout(() => handleFetchSegmentation(), 1000);
        }
    }, [showSegmentModal]);

    const hasCreatePermission = useHasPermission(
        PERMISSIONS.SEGMENTATION_CREATE,
    );

    // No segment is created yet, show placeholder of the page
    const pageIsEmpty = isEmpty(segmentationList);

    return (
        <div className="segments segments__page table--featured">
            {!pageIsEmpty && finishedInitLoad && (
                <>
                    <FixedPageHeader>
                        <div className="segments__page-header">
                            <h4 className="segments__page-title">
                                دسته‌بندی کاربران
                            </h4>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <RefreshButton
                                    onClick={handleFetchSegmentation}
                                    animationIsRunning={isLoading}
                                    tooltipPosition={"right"}
                                />
                                <span
                                    data-tip={
                                        hasCreatePermission
                                            ? ""
                                            : "برای ساخت دسته‌بندی باید پنل خود را ارتقا دهید"
                                    }
                                >
                                    <FlatButton
                                        disabled={!hasCreatePermission}
                                        className="mui-btn mui-btn--sm mui-btn--border mui-btn--blue"
                                        rippleColor={blue900}
                                        label="ساخت دسته‌بندی جدید"
                                        style={{ marginLeft: "10px" }}
                                        onClick={openAndInitializeModal}
                                    />
                                </span>
                            </div>
                        </div>
                    </FixedPageHeader>

                    <SegmentsTableBody
                        isReFetchLoading={isLoading}
                        segmentationList={segmentationList}
                        toggleSegmentModal={toggleSegmentModal}
                        deleteSegmentation={deleteSegmentation}
                    />
                </>
            )}

            {finishedInitLoad && pageIsEmpty && (
                <SegmentationPagePlaceholder onClick={openAndInitializeModal} />
            )}

            {showSegmentModal.show && (
                <SegmentsModal
                    onClose={() =>
                        toggleSegmentModal({
                            show: false,
                            initialValues: null,
                            action: "close",
                            readonly: false,
                        })
                    }
                    onSave={() =>
                        toggleSegmentModal({
                            show: false,
                            initialValues: null,
                            action: showSegmentModal.readonly
                                ? "close"
                                : "save",
                            readonly: false,
                        })
                    }
                    initialValues={showSegmentModal.initialValues} // Set initialValues of reduxForm
                    buttonLabel={
                        showSegmentModal.readonly ? "بستن" : "ذخیره دسته‌بندی"
                    }
                    isReadonly={showSegmentModal.readonly}
                />
            )}

            {(isLoading || !finishedInitLoad) && (
                <CircleLoader size={40} thickness={4} />
            )}

            <ReactTooltip className="tooltip" effect="solid" place="right" />
        </div>
    );
};

SegmentationPage.propTypes = {
    segmentationList: PropTypes.array.isRequired,
    fetchSegmentation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ segmentations: { segmentationList } }) => {
    return {
        segmentationList,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        { fetchSegmentation, deleteSegmentation },
        dispatch,
    );
};

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(SegmentationPage),
);
