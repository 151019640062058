/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import SegmentFilterSelectionField from "@/components/users/segmentation/components/fields/SegmentFilterSelectionField";
import { getApplicationVersion } from "@/actions/applications";
import { formValueSelector } from "redux-form";

const SegmentAppVersion = ({
    appId,
    getApplicationVersion,
    appVersion,
    ...props
}) => {
    useEffect(() => {
        if (appId) {
            getApplicationVersion({ app_id: appId });
        }
    }, [appId]);

    return (
        <SegmentFilterSelectionField
            {...props}
            list={
                !appId && appVersion
                    ? []
                    : appVersion.map(item => ({ label: item, value: item }))
            }
        />
    );
};

const mapStateToProps = state => {
    const {
        inprog: { appVersion },
    } = state;
    const { app_id: appId } = formValueSelector("segments")(
        state,
        "app_id",
        "",
    );

    return {
        appVersion: appVersion ?? [],
        appId,
    };
};

const mapDispatchToProps = {
    getApplicationVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentAppVersion);
