import Toggle from "material-ui/Toggle";
import classNames from "classnames";
import { Pencil } from "@/components/utils/lib/svgicons";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import { PLATFORMS_ID } from "@/actions/_types";
import ReactTooltip from "react-tooltip";
import IconButton from "material-ui/IconButton";

const WelcomeMessage = ({
    app = {},
    isSmallSize,
    colSize,
    updateApplication,
    history,
    platform,
}) => {
    let classes = !isSmallSize
        ? `table-col table-col--${colSize}`
        : "small-welcome";

    function welcomeNotification(app) {
        const { welcome_template_v2: notificationId, app_id: appId } = app;
        let platform = PLATFORMS_ID[app.type];
        let welcomeUrl;
        if (notificationId) {
            welcomeUrl = `/notification/${platform}/welcome/${appId}/${notificationId}`;
        } else {
            welcomeUrl = `/notification/${platform}/welcome/${appId}`;
        }
        history.push(welcomeUrl);
    }

    function activateWelcomeNotification({ app_id }) {
        updateApplication({ app_id, platform, welcome_active: true });
    }
    function deactivateWelcomeNotification({ app_id }) {
        updateApplication({ app_id, platform, welcome_active: false });
    }

    return (
        <div className={classes}>
            <Toggle
                className="toggle"
                style={{ cursor: "pointer", zIndex: 0 }}
                labelPosition="right"
                label="پیام خوش‌آمد"
                data-tip={"پیام خوش‌آمد"}
                toggled={app.welcome_active}
                onToggle={(event, isInputChecked) => {
                    if (!app.welcome_template_v2) {
                        welcomeNotification(app);
                    } else if (isInputChecked) {
                        activateWelcomeNotification(app);
                    } else {
                        deactivateWelcomeNotification(app);
                    }
                }}
            />
            {app.welcome_template_v2 && (
                <IconButton
                    className={classNames("table-btn", {
                        "field-disable": !app.welcome_template_v2,
                    })}
                    data-tip="ویرایش پیام خوش‌آمد"
                    onClick={() => welcomeNotification(app)}
                >
                    <Pencil viewBox="0 0 24 24" />
                </IconButton>
            )}

            <ReactTooltip className="tooltip" effect="solid" />
        </div>
    );
};

WelcomeMessage.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    colSize: PropTypes.number,
};

WelcomeMessage.defaultProps = {
    app: {},
    colSize: 10,
};

export default withErrorCatcher(WelcomeMessage);
