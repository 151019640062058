import React from "react";
import PropTypes from "prop-types";
import { withErrorCatcher } from "@/components/utilities";
import PageFilterSelect from "@/components/utils/finals/PageFilterSelect";
import { Field } from "redux-form";

const GenericSelectFieldComponent = props => {
    const {
        fieldName,
        title,
        placeholder,
        noRow,
        options,
        readOnly,
        classes,
        isMulti = false,
    } = props;
    const processValue = value => {
        if (Array.isArray(value)) {
            return value.map(v => options.find(op => op.value === v));
        } else {
            return options.find(op => op.value === value);
        }
    };
    const renderField = (
        <div className={classes}>
            {title && (
                <header>
                    <p>{title}</p>
                </header>
            )}

            <Field
                className={"mui-textfield"}
                name={fieldName}
                component={props => {
                    const {
                        meta: { error, touched, initial },
                        input: { onChange, value },
                    } = props;
                    return (
                        <>
                            <PageFilterSelect
                                isMulti={isMulti}
                                onSelect={onChange}
                                value={processValue(value || initial)}
                                readOnly={readOnly}
                                onClear={() => onChange("")}
                                isClearable={true}
                                {...props}
                            />
                            {error && touched && (
                                <p
                                    className="errors"
                                    style={{
                                        fontSize: ".78rem",
                                        color: "#f54e3c",
                                        margin: "-15px 0",
                                    }}
                                >
                                    {error.toString()}
                                </p>
                            )}
                        </>
                    );
                }}
                placeholder={placeholder}
                {...props}
            />
        </div>
    );

    return noRow ? renderField : <div className="row">{renderField}</div>;
};

GenericSelectFieldComponent.propTypes = {
    fieldName: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    noRow: PropTypes.bool,
    title: PropTypes.string,
    readOnly: PropTypes.bool,
    classes: PropTypes.string,
};

GenericSelectFieldComponent.defaultProps = {
    noRow: false,
    readOnly: false,
    classes: "col-xs-12 col-md-12",
};

export default withErrorCatcher(GenericSelectFieldComponent);
