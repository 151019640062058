import React from "react";
import { withErrorCatcher } from "@/components/utilities";

const Card = props => {
    const { children } = props;

    return (
        <div className="card-list__card">
            <div className="card-list__card-inner">{children}</div>
        </div>
    );
};

export default withErrorCatcher(Card);
