/**
 * This func returns a geoJSONCircle base on long & lat & a radius
 *
 * https://stackoverflow.com/a/39006388/4850817
 */
export const geoJsonCircle = (center, radiusInKm = 1, points = 64) => {
    const coords = {
        latitude: center[1],
        longitude: center[0],
    };

    const km = radiusInKm;

    const ret = [];
    const distanceX =
        km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta, x, y;
    for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);
        ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: [ret],
        },
    };
};
