import React, { useEffect, useState } from "react";
import { GeofenceMap, withErrorCatcher } from "@/components/utilities";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import PropTypes from "prop-types";

const GeofenceMapComponent = ({
    long,
    lat,
    radius,
    change,
    initMapWithDefaultValues,
}) => {
    const geofenceCenter =
        long && lat ? [Number(long), Number(lat)] : undefined;

    const [zoom, updateZoom] = useState();

    useEffect(() => {
        updateZoom([11]);
    }, [long, lat]);

    return (
        <>
            <div className="map-block">
                <GeofenceMap
                    onGeofenceChanged={([long, lat], radius) => {
                        change("lat", lat);
                        change("long", long);
                        change("radius", radius * 1000);
                    }}
                    geofenceCenter={geofenceCenter}
                    center={geofenceCenter}
                    // eslint-disable-next-line no-extra-boolean-cast
                    geofenceRadius={!!radius ? radius / 1000 : radius}
                    zoom={zoom}
                    initMapWithDefaultValues={initMapWithDefaultValues}
                />
            </div>

            <div className="wizard-block row">
                <div className="col-xs-12 col-sm-4">
                    <Field
                        name="lat"
                        component={TextField}
                        className="mui-textfield"
                        type="number"
                        floatingLabelText="lat"
                        normalize={val =>
                            Number(val) > 90
                                ? "90"
                                : Number(val) < -90
                                ? "-90"
                                : val
                        }
                        style={{
                            width: "100%",
                        }}
                    />
                </div>
                <div className="col-xs-12 col-sm-4">
                    <Field
                        name="long"
                        component={TextField}
                        className="mui-textfield"
                        type="number"
                        floatingLabelText="long"
                        normalize={val =>
                            Number(val) > 180
                                ? "180"
                                : Number(val) < -180
                                ? "-180"
                                : val
                        }
                        style={{
                            width: "100%",
                        }}
                    />
                </div>
                <div className="col-xs-12 col-sm-4">
                    <Field
                        name="radius"
                        component={TextField}
                        className="mui-textfield"
                        type="number"
                        min={0.1}
                        step={0.1}
                        floatingLabelText="radius km"
                        // api accepts meter so convert km to meter
                        normalize={value =>
                            !isNaN(Number(value)) ? Number(value) * 1000 : ""
                        }
                        format={value =>
                            !isNaN(Number(value)) && value !== 0
                                ? Number(value) / 1000
                                : 0.1
                        }
                        style={{
                            width: "100%",
                        }}
                    />
                </div>
            </div>
        </>
    );
};

GeofenceMapComponent.defaultProps = {
    initMapWithDefaultValues: true,
};

GeofenceMapComponent.propTypes = {
    long: PropTypes.PropTypes.node,
    lat: PropTypes.PropTypes.node,
    radius: PropTypes.number,
    change: PropTypes.func.isRequired,
    initMapWithDefaultValues: PropTypes.bool,
};

export default withErrorCatcher(GeofenceMapComponent);
