import defaultEnvs from "configs/envs/default";
import axios from "axios";
import to from "await-to-js";

const initializeEnvs = async () => {
    const env = CONSOLE_ENV;
    let configs = { ...defaultEnvs };

    // On Staging & Production download ./configs.json
    if (env === "production" || env === "staging") {
        const [error, result] = await to(axios.get("./config.json"));

        if (error) console.error(error); // TODO: Send event to sentry
        if (result) configs = { ...result.data };
    }

    // On Development (because we use webpack devServer)
    // just import the configs/envs/development.json
    if (env === "development") {
        configs = { ...(await import("configs/envs/development"))["default"] };
    }

    if (env === "moox") {
        configs = { ...(await import("configs/envs/moox.json"))["default"] };
    }

    return configs;
};

export default () => {
    const envs = initializeEnvs();

    return () => envs;
};
