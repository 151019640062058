import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { useDispatch } from "react-redux";

import {
    CreateSetUpConfigEmail,
    UpdateSetUpConfigEmail,
} from "@/actions/configEmailSMS";
import { createValidator, isRequired } from "@/components/utils/validation";

const EmailConfigTab = props => {
    const dispatch = useDispatch();
    const [loading, toggleLoading] = useState(false);
    const [passFake, setPassFake] = useState("********");
    const { handleSubmit, invalid, email } = props;
    const {
        initialValues: { username = "" },
    } = props;
    const submitUserInfo = values => {
        toggleLoading(true);
        const cb = () => toggleLoading(false);
        // const normalizedValues = normalize(values, sendNormalizer);
        const { id } = !!email?.length && email[0];
        if (email?.length) {
            UpdateSetUpConfigEmail(id, values, cb, cb)(dispatch);
        } else {
            CreateSetUpConfigEmail(values, cb, cb)(dispatch);
        }
    };

    return (
        <div className={"profile__block"}>
            <div className="profile__section">
                <h4 className="profile__section--header">تنظیمات ایمیل</h4>
                <form>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <Field
                                name="name"
                                component={TextField}
                                floatingLabelText="نام*"
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-sm-12 col-xs-12">
                            <Field
                                name="username"
                                component={TextField}
                                floatingLabelText="نام کاربری*"
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-sm-12 col-xs-12">
                            {username && passFake ? (
                                <TextField
                                    type={"password"}
                                    value={passFake}
                                    autoFocus={false}
                                    autoComplete="new-password"
                                    fullWidth={true}
                                    floatingLabelText="پسورد"
                                    onChange={() => {
                                        setPassFake("");
                                    }}
                                />
                            ) : (
                                <>
                                    <Field
                                        autoFocus
                                        name="password"
                                        component={TextField}
                                        floatingLabelText="پسورد"
                                        autoComplete="new-password"
                                        fullWidth={true}
                                        type={"password"}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="port"
                                component={TextField}
                                floatingLabelText="پورت*"
                                type="number"
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Field
                                name="host"
                                component={TextField}
                                floatingLabelText="هاست*"
                                autoComplete="off"
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="profile__section--footer">
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        paddingTop: "10px",
                    }}
                >
                    <FlatButton
                        className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                        rippleColor={blue900}
                        label={
                            loading ? (
                                <CircleLoader
                                    size={25}
                                    color="#32a9e0"
                                    style={{ top: "8px" }}
                                />
                            ) : (
                                "ذخیره"
                            )
                        }
                        onClick={handleSubmit(values => {
                            if (invalid) return;
                            submitUserInfo(values);
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

const validators = createValidator({
    name: isRequired,
    username: isRequired,
    port: isRequired,
    host: isRequired,
});

const EmailConfigTabForm = reduxForm({
    validate: validators,
    form: "config_EMAIL",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(EmailConfigTab);

export default withErrorCatcher(EmailConfigTabForm);
