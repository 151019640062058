import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import classNames from "classnames";
import AppName from "application/list/ios/columns/AppName";
import CreatedAt from "application/list/common/columns/CreatedAt";
import PropTypes from "prop-types";
import AppPlatform from "application/list/ios/columns/AppPlatform";
import WelcomeMessage from "application/list/ios/columns/WelcomeMessage";
import NumberOfUsers from "application/list/ios/columns/NumberOfUsers";
import Menus from "application/list/ios/columns/Menus";
import IosFrameworkModal from "application/list/ios/components/IosFrameworkModal";

class IosApplicationRow extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        updateApplication: PropTypes.func,
        platform: PropTypes.string.isRequired,
        app: PropTypes.object,
        isSmallSize: PropTypes.bool,
        deleteApplication: PropTypes.func,
    };

    static defaultProps = {
        app: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            showFrameworkModal: false,
            onClickFrameworkModal: null,
        };
    }

    handleFrameworkOrGuide(
        type = "unset",
        { app_id, framework = null, page = "guide" } = {},
    ) {
        const handleSet = () => {
            const { history, updateApplication, platform } = this.props;
            const changePageAction = () =>
                history.push(`/application/${platform}/${page}/${app_id}`);
            const onClickFrameworkModal = framework =>
                updateApplication({ framework, app_id, platform }, () => {
                    if (page !== null) {
                        // change page
                        changePageAction();
                    } else {
                        // close modal
                        this.setState({
                            showFrameworkModal: false,
                            onClickFrameworkModal: null,
                        });
                    }
                });

            // framework already set then redirect to page
            if (framework && page !== null) {
                return changePageAction();
            }

            this.setState(() => ({
                showFrameworkModal: true,
                onClickFrameworkModal,
            }));
        };

        const handleUnset = () => {
            this.setState(() => ({
                showFrameworkModal: false,
                onClickFrameworkModal: null,
            }));
        };

        switch (type) {
            case "set":
                handleSet();
                break;
            case "unset":
                handleUnset();
                break;
        }
    }

    render() {
        const { showFrameworkModal, onClickFrameworkModal } = this.state;
        const {
            app,
            history,
            isSmallSize,
            updateApplication,
            platform,
            deleteApplication,
            exportCSV,
        } = this.props;
        const { transactional_users } = app;
        const commonProps = { history, isSmallSize, platform };
        return (
            <div className="table-body mobile-table">
                <div
                    className={classNames("table-row", {
                        "table-row--featured": transactional_users > 0,
                        small: isSmallSize,
                    })}
                >
                    <AppName app={app} {...commonProps} colSize={24} />
                    <CreatedAt app={app} {...commonProps} />
                    <AppPlatform
                        app={app}
                        handleFrameworkOrGuide={this.handleFrameworkOrGuide.bind(
                            this,
                        )}
                        {...commonProps}
                    />

                    <WelcomeMessage
                        app={app}
                        updateApplication={updateApplication}
                        {...commonProps}
                    />

                    {/*<UpdateMessage*/}
                    {/*    app={app}*/}
                    {/*    {...commonProps}*/}
                    {/*/>*/}

                    <NumberOfUsers
                        app={app}
                        handleFrameworkOrGuide={this.handleFrameworkOrGuide.bind(
                            this,
                        )}
                        {...commonProps}
                    />

                    <Menus
                        app={app}
                        deleteApplication={deleteApplication}
                        updateApplication={updateApplication}
                        exportCSV={exportCSV}
                        handleFrameworkOrGuide={this.handleFrameworkOrGuide.bind(
                            this,
                        )}
                        {...commonProps}
                    />
                </div>

                {showFrameworkModal && (
                    <IosFrameworkModal
                        onClick={onClickFrameworkModal}
                        onClose={() => this.handleFrameworkOrGuide("unset")}
                    />
                )}
            </div>
        );
    }
}

IosApplicationRow.propTypes = {
    app: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool.isRequired,
    handleUpdateApplication: PropTypes.func.isRequired,
    handleDeleteApplication: PropTypes.func.isRequired,
    updateApplication: PropTypes.func.isRequired,
};

export default withErrorCatcher(IosApplicationRow);
