/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { store } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { fetchDashboardData } from "@/actions/dashboard";
import PieChart from "@/components/utils/charts/types/_pieChartBase";
import IranMapCharts from "@/components/users/analytics/IranMapCharts";
import operatorDistributionImage from "images/analytics/users.jpeg";
import internetDistributionImage from "images/analytics/users.jpeg";
import EmptyChartImagePlaceHolder from "@/components/utilities/emptyChartImagePlaceHolder";
import { FIRST_DAY_EMPTY_CHART_MESSAGE } from "@/constants";
const UsersAnalyticsPage = () => {
    const dispatch = useDispatch();
    const { platform, appId } = useSelector(state => state.app);
    const distributions = useSelector(
        s =>
            s?.[platform]?.dashboard?.[appId ?? "all"]?.["distributions"] ?? {},
    );

    useEffect(() => {
        fetchDashboardData()(dispatch, store.getState);
    }, [appId]);
    return (
        <>
            <div className="main-block">
                <div className="main-block__body users-page">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        {Object.keys(distributions?.["operator"] ?? {})
                            .length <= 2 ? (
                            <EmptyChartImagePlaceHolder
                                src={operatorDistributionImage}
                                fullWidth={true}
                                massage={
                                    "نمودارها یک روز بعد از دریافت اولین نصب از اپلیکیشن شما و هر ۲۴ ساعت یک بار به روز رسانی می شوند"
                                }
                                svg="#svg_operator"
                                title="اپراتور"
                            />
                        ) : (
                            <PieChart
                                data={distributions?.["operator"] ?? []}
                                title="اپراتور"
                                svg="#svg_operator"
                                id="operator_chart"
                            />
                        )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        {Object.keys(distributions?.["mobile_net"] ?? {})
                            .length <= 2 ? (
                            <EmptyChartImagePlaceHolder
                                src={internetDistributionImage}
                                fullWidth={true}
                                title="نوع اینترنت"
                                svg="#svg_net_type"
                                massage={FIRST_DAY_EMPTY_CHART_MESSAGE}
                            />
                        ) : (
                            <PieChart
                                data={distributions?.["mobile_net"] ?? []}
                                title="نوع اینترنت"
                                svg="#svg_net_type"
                                id="net_type_chart"
                            />
                        )}
                    </div>

                    <div className="col-lg-12 col-xs-12">
                        <IranMapCharts data={distributions?.["state"] ?? []} />
                    </div>
                </div>
            </div>

            <ReactTooltip className="tooltip" effect="solid" />
        </>
    );
};

export default UsersAnalyticsPage;
