// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".baseWrapper___1EtBF{display:flex;margin:0;padding:0;position:absolute;right:0;left:0;top:0;height:100%;width:100%}.baseInnerWrapper___2cQz3{display:flex;flex-direction:row;width:100%}.baseBlock___2s61i{display:flex;height:100%;background-color:#fff}.baseMiddlePageBlock___1vc1P{width:100%;flex-direction:column;padding:40px 10%}@media (min-width: 768px){.baseMiddlePageBlock___1vc1P{padding:40px 20%}}@media (min-width: 992px){.baseMiddlePageBlock___1vc1P{width:50%;flex:0 1 50%;justify-content:center;padding:40px 50px}}@media (min-width: 1200px){.baseMiddlePageBlock___1vc1P{padding:40px 100px 40px 200px}}@media (min-width: 1921px){.baseMiddlePageBlock___1vc1P{padding:40px 100px 40px 300px}}.baseBanner___-X7QE{display:none;height:100%;background-color:#f4f7fb}@media (min-width: 992px){.baseBanner___-X7QE{display:flex}}.baseBannerWrapper___37TJO{display:flex;justify-content:center;align-content:center;height:100%;width:100%;background-color:#f4f7fb}.baseBannerWrapper___37TJO img{display:block;width:80%;height:auto}@media (min-width: 1200px){.baseBannerWrapper___37TJO img{width:65%}}@media (min-width: 1921px){.baseBannerWrapper___37TJO img{width:55%}}\n", ""]);
// Exports
exports.locals = {
	"baseWrapper": "baseWrapper___1EtBF",
	"baseInnerWrapper": "baseInnerWrapper___2cQz3",
	"baseBlock": "baseBlock___2s61i",
	"baseMiddlePageBlock": "baseMiddlePageBlock___1vc1P",
	"baseBanner": "baseBanner___-X7QE",
	"baseBannerWrapper": "baseBannerWrapper___37TJO"
};
module.exports = exports;
