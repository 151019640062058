import React from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

const Breadcrumb = props => {
    const { breadcrumb } = props;
    const platform = useSelector(state => state?.app?.platform);

    const headerContainer = document.getElementById(
        "headerMobileOnlyContainer",
    );

    return (
        <>
            <div className="breadcrumb">
                {platform && (
                    <div className="breadcrumb-platform">
                        <svg className={`icon-${platform}`}>
                            <use xlinkHref={`#svg_${platform}_fill`} />
                        </svg>
                    </div>
                )}
                <ul>
                    <li>
                        <Link to="" />
                    </li>
                    <li>
                        <Link to="">{breadcrumb}</Link>
                    </li>
                </ul>
            </div>

            {/* Portal the mobile view breadcrumb into the header */}
            {headerContainer &&
                createPortal(
                    <div className="breadcrumb-mobile only-mobile">
                        <ul>
                            <li>
                                <span>
                                    {platform === "android" && "اندروید"}
                                    {platform === "ios" && "آی‌او‌اس"}
                                    {platform === "web" && "وب"}
                                </span>
                            </li>
                            <li>
                                <span>{breadcrumb}</span>
                            </li>
                        </ul>
                    </div>,
                    headerContainer,
                )}
        </>
    );
};

export default withRouter(Breadcrumb);
