import { push } from "react-router-redux";
import _ from "lodash";
import actionTypes from "@/actions/_types";
import { PREFERRED_PLATFORM } from "@/constants";

const platformMapping = {
    android: 1,
    web: 2,
    ios: 3,
};

export function isValidPlatform(platform, dispatch) {
    if (["android", "ios", "web"].indexOf(platform) !== -1) return true;
    dispatch(push(`/dashboard/${PREFERRED_PLATFORM}`));
    return false;
}

export function getPlatformId(platform) {
    return platformMapping[platform];
}

/**
 * Convert base64/URLEncoded data component to raw binary data held in a string
 * @param dataURI
 * @returns {Blob}
 */
export function dataUrlToBlob(dataURI) {
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

export function getApplicationDetails(notificationAppIds, applicationList) {
    let notifAppIds = [];
    let results = {};
    notificationAppIds.map(notif => {
        for (let i = 0; i < notif.app_ids.length; i++) {
            notifAppIds.push(notif.app_ids[i]);
        }
    });

    notifAppIds = _.uniqWith(notifAppIds, _.isEqual);

    for (let appId = 0; appId < notifAppIds.length; appId++) {
        for (let idx = 0; idx < applicationList.length; idx++) {
            if (applicationList[idx].app_id === notifAppIds[appId]) {
                results[applicationList[idx].app_id] = {
                    icon_url: applicationList[idx].icon_url,
                    name: applicationList[idx].name,
                };
            }
        }
    }
    return results;
}

export function generateGetParams(params = {}) {
    const items = Object.entries(params)
        .filter(item => [undefined, null].indexOf(item[1]) === -1)
        .map(item => {
            const key = item[0];
            const title = item[1];
            return `${key}=${title}`;
        });

    return `${items.length > 0 ? "?" : ""}${items.join("&")}`;
}

export function errorHandlerInprog(err, dispatch) {
    let error = "خطایی رخ داده ، مجددا تلاش نمایید.";
    try {
        if (err.response.status !== 500) {
            error = err.response ? err.response.data : {};
            if (typeof error === "object" && Object.values(error).length > 0) {
                error =
                    Object.values(error).length > 0
                        ? Object.values(error)[0]
                        : "";
            }
        }
    } catch (e) {
        error = "خطایی رخ داده ، مجددا تلاش نمایید.";
    }
    dispatch({
        payload: error,
        type: actionTypes.APP.INPROG_ERROR,
    });
}

export const processErrorResponse = resp => {
    const defaultMessage = "خطایی رخ داده ، لطفا مجددا تلاش نمایید.";
    let error = defaultMessage;
    try {
        if (resp.response.status !== 500) {
            error = resp.response ? resp.response.data : {};
            if (typeof error === "object" && Object.values(error).length > 0) {
                error =
                    Object.values(error).length > 0
                        ? Object.values(error)[0]
                        : "";
            }
        }
    } catch (e) {
        error = defaultMessage;
    }
    return error;
};

export const toggleLoading = (type, payload, dispatch) => {
    dispatch({
        type,
        payload,
    });
};
