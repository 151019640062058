import * as Sentry from "@sentry/browser";
import { getConfigs } from "@/index";
import { store } from "@/store";
import _ from "lodash";

const SENTRY_REDUX_KEYS = {
    auth: {
        profile: {
            email: true,
            email_2fa_is_active: true,
            groups: true,
            language: true,
            permissions: true,
            sender_id: true,
            terms_status: true,
            trial_remaining: true,
        },
    },
    router: { location: true },
    app: true,
    form: true,
};

export default async () => {
    const env = CONSOLE_ENV;
    const configs = (await getConfigs()).sentry;

    // Only use Sentry in staging & production
    if (env !== "production" && env !== "staging") return;

    Sentry.init({
        dsn: configs.dsn,
        release: RELEASE_ID,
        environment: configs.environment,
        debug: false,
        ignoreErrors: [
            "Request failed with status code 401",
            "Request failed with status code 403",
            "timeout of 600000ms exceeded",
            "Network Error",
        ],
    });

    Sentry.configureScope(scope => {
        scope.addEventProcessor(async event => {
            const state = store.getState();
            const { auth = {} } = state;
            const { profile = {} } = auth;
            const { email = "" } = profile;

            event.user = { email };

            try {
                event.extra = event.extra || {};
                event.extra["Redux State"] = filterState(
                    store.getState(),
                    SENTRY_REDUX_KEYS,
                );
            } catch (e) {
                console.error(e);
            }

            return event;
        });
    });
};

function filterState(state, filteredKeys) {
    if (!state || !filteredKeys) {
        return null;
    }

    return _.mapValues(filteredKeys, (value, key) => {
        if (typeof value === "object") {
            return filterState(state[key], filteredKeys[key]);
        } else if (filteredKeys[key] === true) {
            return state[key];
        }
    });
}

export const sentryReporterMiddleware = () => next => action => {
    Sentry.addBreadcrumb({
        message: action.type,
        category: "redux action",
        level: "info",
        data: {
            payload: action.payload,
        },
    });
    return next(action);
};
