import React from "react";
import { TextField } from "material-ui";
import { SEGMENTS_VALUE_UNITS_TRANSLATION } from "@/components/users/segmentation/constants";

const SegmentTextValueField = ({
    value,
    label,
    fieldType,
    reduxName,
    onChange,
    errorText,
    valueUnit,
    type,
}) => {
    return (
        <div className="segments__value-with-unit">
            <TextField
                className="mui-textfield segments__field-item"
                hintText={label}
                value={value}
                type={fieldType || "text"}
                name={reduxName}
                onChange={(_, value) => onChange(value)}
                fullWidth={true}
                errorText={errorText}
            />

            {valueUnit && typeof valueUnit === "function" && (
                <div className="segments__value-unit">
                    {SEGMENTS_VALUE_UNITS_TRANSLATION[valueUnit(type)]}
                </div>
            )}

            {valueUnit && typeof valueUnit === "string" && (
                <div className="segments__value-unit">
                    {SEGMENTS_VALUE_UNITS_TRANSLATION[valueUnit]}
                </div>
            )}
        </div>
    );
};

export default SegmentTextValueField;
