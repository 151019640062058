import { useCallback, useEffect } from "react";
import myStorage from "@/myStorage";
import { signOut } from "@/actions/auth";
import { useDispatch } from "react-redux";

export const useSetCustomerEmail = change => {
    const dispatch = useDispatch();

    const pathname = window.location.pathname;
    const search = window.location.search ?? "";

    const getCustomerEmail = useCallback(
        () =>
            (
                search
                    .split(/[?&]/)
                    .filter(i => i.indexOf("customer_email") > -1)[0] ?? ""
            ).split("customer_email=")[1],
        [search],
    );

    const setCustomerEmail = useCallback(() => {
        const customerEmail = getCustomerEmail();
        if (customerEmail) change("customer_email", customerEmail);
    }, [change, getCustomerEmail]);

    useEffect(() => {
        // if user already login, sign out first
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!myStorage.getItem("token")) {
            signOut({
                destPage: pathname + search,
                onFinish: () => setCustomerEmail(),
            })(dispatch);
        } else {
            setCustomerEmail();
        }
    }, [dispatch, pathname, search, setCustomerEmail]);
};
