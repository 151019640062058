import React, { useEffect, useRef, useState } from "react";
import Modal from "@/components/utilities/commons/modal";
import classNames from "classnames";
import PropTypes from "prop-types";
import { defaultTo as dt } from "lodash";
import WizardStepsHeader from "@/components/utilities/commons/WizardStepsHeader";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { changeFormField } from "@/actions/app";
import useSubmitForm from "@/components/utilities/commons/wizard/base/useSubmitForm";
import useStepsWizard from "@/components/utilities/commons/wizard/base/useStepsWizard";
import { getFormValues } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";

const BaseWizard = ({ ...props }) => {
    const {
        history: { goBack },
        title,
        subtitle,
        steps: rawSteps,
        stepFinale,
        footer: Footer,
        formName,
        formValues,
        formInitialData,
        onMountCallback,
        onUnmountCallback,
        testModal: TestModal,
        submitAction,
        uploaderInProgress,
        changeFormField,
        preview: PreviewComponent,
        wizardMode,
        hasReduxForm,
        hasDraftBtn,
        buttonOptions: { submitButtonAction },
        buttonOptions,
        passToSteps,
        passToPreview,
    } = props;

    // Use 'show' only for the things that are not depends to state flow, and static
    const steps = rawSteps.filter(
        step => step.show === undefined || step.show === true,
    );

    const wizardModalRef = useRef(null);

    useEffect(() => {
        // Do things when component mount
        if (onMountCallback) {
            onMountCallback();
        }

        // Do things when component unmount
        return () => {
            if (onUnmountCallback) {
                onUnmountCallback();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [sendInprogress, toggleSendInprogress] = useState(false);
    const [showTestModal, toggleShowTestModal] = useState(false);
    const [showPreview, togglePreviewVisibility] = useState(false);
    const [isDraftFinalStep, setIsDraftFinalStep] = useState(false);

    // Handle Steps (next, prev) of wizards
    const [currentStep, { onNextClick, onPrevClick, jumpToFinale }] =
        useStepsWizard({ steps, values: formValues });
    const [submitForm, onFormSubmitResult] = useSubmitForm(formName);

    const FormStep = currentStep.isFinale
        ? stepFinale
        : dt(steps[currentStep.position], {}).component;

    const callSubmitAction = (data, preventGoBack = false) => {
        toggleSendInprogress(true);
        submitAction(
            data,
            () => {
                toggleSendInprogress(false);
                if (!preventGoBack) {
                    goBack();
                }
            },
            () => {
                toggleSendInprogress(false);
            },
        );
    };

    return (
        <div>
            <Modal
                ref={wizardModalRef}
                className={classNames(`modal--notif`, {
                    "modal--preview-show": showPreview,
                })}
                onModalClose={goBack}
                modalCloseDelay={0}
            >
                {/* Header */}
                <div className="modal-header">
                    <svg className="icon-send-notif">
                        <use xlinkHref="#svg_send_notif" />
                    </svg>
                    <p>{title}</p>
                    <span>{subtitle}</span>
                </div>

                <div
                    className="notif"
                    style={
                        !PreviewComponent ? { justifyContent: "center" } : {}
                    }
                >
                    <div className="notif-col">
                        <div className={classNames(`notif-content`)}>
                            {/* Notif Steps Header */}
                            {!currentStep.isFinale && (
                                <div className="notif-header">
                                    <WizardStepsHeader
                                        steps={dt(steps, []).length}
                                        activeStep={currentStep.position + 1}
                                    />
                                </div>
                            )}

                            {/* Show Preview Button in Mobile */}
                            {PreviewComponent && (
                                <div className="notif-btnwrap">
                                    <FlatButton
                                        className="mui-btn mui-btn--xs mui-btn--border mui-btn--gray-blue"
                                        rippleColor={blue900}
                                        label="نمایش نوتیفیکیشن"
                                        onClick={() => {
                                            togglePreviewVisibility(true);
                                            if (wizardModalRef.current) {
                                                wizardModalRef.current.scrollTop = 0;
                                            }
                                        }}
                                    />
                                </div>
                            )}

                            {FormStep && (
                                <FormStep
                                    formName={formName}
                                    onSubmit={values => values}
                                    onSuccess={values =>
                                        onFormSubmitResult(values)
                                    }
                                    onFailure={errors =>
                                        onFormSubmitResult({ errors })
                                    }
                                    initialValues={formInitialData}
                                    // This two goes in passToSteps
                                    wizardMode={wizardMode}
                                    currentStep={currentStep}
                                    passToSteps={passToSteps}
                                    draftMode={isDraftFinalStep}
                                />
                            )}

                            {Footer && (
                                <Footer
                                    isFirstStep={currentStep.position === 0}
                                    isFinalStep={currentStep.isFinale}
                                    isSendInProgress={sendInprogress}
                                    onNextClick={() => {
                                        const beforeSubmit = () =>
                                            changeFormField(
                                                formName,
                                                "isTestMode",
                                                false,
                                            );
                                        if (hasReduxForm) {
                                            submitForm(beforeSubmit)
                                                .then(onNextClick)
                                                .catch(e => e);
                                        } else {
                                            onNextClick();
                                        }
                                    }}
                                    onPrevClick={onPrevClick}
                                    hasDraftBtn={hasDraftBtn}
                                    onSubmitTest={() => {
                                        const beforeSubmit = () => {
                                            changeFormField(
                                                formName,
                                                "isTestMode",
                                                true,
                                            );
                                            changeFormField(
                                                formName,
                                                "isDraftMode",
                                                false,
                                            );
                                        };
                                        submitForm(beforeSubmit)
                                            .then(() =>
                                                toggleShowTestModal(true),
                                            )
                                            .catch(e => e);
                                        setIsDraftFinalStep(false);
                                    }}
                                    onSubmit={() => {
                                        if (submitButtonAction)
                                            return submitButtonAction();
                                        const beforeSubmit = () => {
                                            changeFormField(
                                                formName,
                                                "isTestMode",
                                                false,
                                            );
                                            changeFormField(
                                                formName,
                                                "isDraftMode",
                                                false,
                                            );
                                        };

                                        submitForm(beforeSubmit)
                                            .then(() => {
                                                // When there is only one step & there is no stepFinale
                                                if (
                                                    steps.length === 1 &&
                                                    !stepFinale
                                                ) {
                                                    callSubmitAction(
                                                        formValues,
                                                    );
                                                } else {
                                                    jumpToFinale();
                                                }
                                            })
                                            .catch(e => e);
                                        setIsDraftFinalStep(false);
                                    }}
                                    onSubmitDraft={() => {
                                        if (submitButtonAction)
                                            return submitButtonAction();
                                        const beforeSubmit = () => {
                                            changeFormField(
                                                formName,
                                                "isTestMode",
                                                false,
                                            );
                                            changeFormField(
                                                formName,
                                                "isDraftMode",
                                                true,
                                            );
                                        };
                                        submitForm(beforeSubmit)
                                            .then(() => {
                                                // When there is only one step & there is no stepFinale
                                                if (
                                                    steps.length === 1 &&
                                                    !stepFinale
                                                ) {
                                                    callSubmitAction(
                                                        formValues,
                                                    );
                                                } else {
                                                    jumpToFinale();
                                                }
                                            })
                                            .catch(e => e);
                                        setIsDraftFinalStep(true);
                                    }}
                                    onConfirmNotificationSend={() =>
                                        callSubmitAction(formValues)
                                    }
                                    uploaderInProgress={!!uploaderInProgress}
                                    hideSubmitBtn={
                                        dt(steps[currentStep.position], {})
                                            .hideSubmitBtn
                                    }
                                    showTestBtn={
                                        dt(steps[currentStep.position], {})
                                            .showTestBtn
                                    }
                                    buttonOptions={buttonOptions}
                                    hideNextBtn={
                                        currentStep.isFinale ||
                                        (!stepFinale &&
                                            currentStep.position ===
                                                steps.length - 1)
                                    }
                                />
                            )}
                        </div>
                    </div>

                    {!!PreviewComponent && (
                        <div className="notif-col">
                            <div className="notif-preview-container">
                                <div
                                    className="notif-preview__bg"
                                    onClick={() =>
                                        togglePreviewVisibility(false)
                                    }
                                />
                                <div
                                    id="notifPreview"
                                    className={classNames(
                                        "notif-preview notif-preview--phone",
                                        {
                                            "notif-preview--finale":
                                                currentStep.isFinale,
                                        },
                                    )}
                                >
                                    <button
                                        className="notif-preview__close"
                                        onClick={() =>
                                            togglePreviewVisibility(false)
                                        }
                                    />
                                    <div className="notif-preview__wrap">
                                        <PreviewComponent
                                            formName={formName}
                                            step={currentStep}
                                            passToPreview={passToPreview}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>

            {!!TestModal && showTestModal && (
                <TestModal
                    onModalClose={() => {
                        changeFormField(formName, "isTestMode", "");
                        toggleShowTestModal(false);
                    }}
                    sendNotif={data => callSubmitAction(data, true)}
                    values={formValues}
                />
            )}
        </div>
    );
};

BaseWizard.propTypes = {
    steps: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    footer: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    submitAction: PropTypes.func,
    passToSteps: PropTypes.object,

    preview: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    subtitle: PropTypes.string,
    stepFinale: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    testModal: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.node,
    ]),
    onMountCallback: PropTypes.func,
    onUnmountCallback: PropTypes.func,
    formInitialData: PropTypes.object,
    uploaderInProgress: PropTypes.func,
    hasReduxForm: PropTypes.bool, // if form has 'redux form' set this true to validate form on submit (next/submit) buttons
    buttonOptions: PropTypes.object,
    // buttonOptions: { prevButtonName, nextButtonName, testButtonName, submitButtonName, finalButtonName, submitButtonAction }
    passToPreview: PropTypes.object,
};

export const WIZARD_BTN_OPTIONS = {
    PREV_BTN_NAME: "prevButtonName",
    NEXT_BTN_NAME: "nextButtonName",
    TEST_BTN_NAME: "testButtonName",
    SUBMIT_BTN_NAME: "submitButtonName",
    FINAL_BTN_NAME: "finalButtonName",
    SUBMIT_BTN_ACTION: () => {}, // Implement your code instead of this
};

BaseWizard.defaultProps = {
    steps: [],
    formInitialData: {},
    hasReduxForm: false,
    buttonOptions: {},
};

const mapStateToProps = (state, ownProps) => {
    const { formName } = ownProps;

    return {
        formValues: getFormValues(formName)(state),
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeFormField }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseWizard);
