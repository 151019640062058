import React from "react";
import PropTypes from "prop-types";

class OutsideClickWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.compRef = React.createRef();
        this.onClick = this.onClick.bind(this);
    }

    static defaultProps = {
        onClose: () => null,
    };

    static propTypes = {
        onClose: PropTypes.func.isRequired,
        excludes: PropTypes.array,
    };

    onClick(e) {
        const { onClose, excludes } = this.props;

        if (
            this.compRef.current &&
            !this.compRef.current.contains(e.target) &&
            excludes.every(i => !i.contains(e.target))
        ) {
            if (onClose) onClose();
        }
    }

    componentDidMount() {
        window.addEventListener("click", this.onClick);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onClick);
    }

    render() {
        const { children } = this.props;
        return <div ref={this.compRef}>{children}</div>;
    }
}

export default OutsideClickWrapper;
