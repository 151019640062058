import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withErrorCatcher } from "@/components/utilities";
import { retrieveApplication } from "@/actions/applications";
import PageLoader from "@/components/utils/PageLoader";
import AndroidGuideWizard from "application/guides/wizards/AndroidGuideWizard";
import { PLATFORMS } from "@/constants";

const ManifestPage = props => {
    const {
        match: { params },
        history,
    } = props;
    const dispatch = useDispatch();

    const { platform, appId } = params;
    const { applications } = useSelector(state => state?.inprog || {});

    const application = applications[appId] || undefined;
    const Component = platform ? GUIDE_PAGES[platform] : undefined;

    return (
        <PageLoader
            history={history}
            perform={onFail =>
                retrieveApplication(
                    { appId, platform, showErrorToast: true },
                    null,
                    onFail,
                )(dispatch)
            }
            isReady={!!application}
        >
            {Component && (
                <Component
                    history={history}
                    platform={platform}
                    application={application}
                    isManifestOnly={true}
                />
            )}
        </PageLoader>
    );
};

const GUIDE_PAGES = {
    [PLATFORMS.ANDROID]: AndroidGuideWizard,
};

export default withErrorCatcher(withRouter(ManifestPage));
