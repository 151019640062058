import React from "react";
import SendTestDialogBase from "@/components/utilities/commons/wizard/SendTestDialogBase";
import { withErrorCatcher } from "@/components/utilities";
import { useMutation, useQuery } from "react-query";
import {
    addNewEmailFavorite,
    EMAILS_KEYS,
    fetchEmailFavorites,
    removeEmailFavorite,
    sendTestEmail,
} from "@/queries/email";
import AddItemComponent from "@/components/email/email/create/general/AddFaveItemComponent";
import {
    onSuccessAppendToList,
    onSuccessFilterList,
} from "@/queries/constants";
import { normalize } from "@/components/utils/normalize";
import { emailSendNormalizer } from "@/components/email/normalize";
import { successToast } from "@/components/utils/Toasts";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";

const AndroidSendTestModal = props => {
    const { values: formValues } = props;

    const { data, isLoading: fetchIsLoading } = useQuery(
        EMAILS_KEYS.FAV(),
        fetchEmailFavorites,
        { cacheTime: 0, staleTime: Infinity },
    );

    const [removeFavoriteMutation, { isLoading: removeIsLoading }] =
        useMutation(removeEmailFavorite, {
            onSuccess: email =>
                onSuccessFilterList(EMAILS_KEYS.FAV(), "email", email, true),
        });

    const [addFavoriteMutation, { isLoading: addIsLoading }] = useMutation(
        addNewEmailFavorite,
        {
            onSuccess: data =>
                onSuccessAppendToList(EMAILS_KEYS.FAV(), data, true),
        },
    );

    const [sentTestMutation, { isLoading: sendTestIsLoading }] = useMutation(
        sendTestEmail,
        {
            onSuccess: () =>
                successToast(
                    TOAST_CONTENT.SEND_EMAIL_SUCCESS.TITLE,
                    TOAST_CONTENT.SEND_EMAIL_SUCCESS.DESC,
                ),
        },
    );

    const sendTest = async email => {
        let values = formValues;
        if (formValues?.notification) {
            values = {
                ...formValues,
                data: formValues.notification.data,
            };
        }

        const normalized = normalize(values, emailSendNormalizer);

        await sentTestMutation({
            ...normalized,
            filters: {
                ...(normalized?.filters ?? {}),
                email: [email],
            },
        });
    };

    return (
        <SendTestDialogBase
            items={data}
            isLoading={
                fetchIsLoading ||
                sendTestIsLoading ||
                removeIsLoading ||
                addIsLoading
            }
            AddItemComponent={AddItemComponent}
            removeMutation={removeFavoriteMutation}
            addItemMutation={addFavoriteMutation}
            sendTestFunc={sendTest}
            itemKey={"email"}
            itemLabel={"name"}
            dialogTitle={"لیست ایمیل‌ها مورد علاقه شما"}
            addItemLabel={"ایمیل جدید به لیست علاقه مندی‌ها اضافه کن"}
            {...props}
        />
    );
};

export default withErrorCatcher(AndroidSendTestModal);
