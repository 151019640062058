import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import GeofenceFinaleStep from "@/components/notifications/geofence/create/common/GeofenceFinaleStep";
import GeofenceWizardFooter from "@/components/utilities/commons/wizard/base/WizardFooter";
import AndroidGeofenceStep1 from "@/components/notifications/geofence/create/android/steps/AndroidGeofenceStep1";
import AndroidGeofenceStep2 from "@/components/notifications/geofence/create/android/steps/AndroidGeofenceStep2";
import BaseWizard from "@/components/utilities/commons/wizard/base/BaseWizard";
import { createInitialFormData } from "@/components/notifications/geofence/create/android/createInitialFormData";
import AndroidGeofenceStep3 from "@/components/notifications/geofence/create/android/steps/AndroidGeofenceStep3";
import AndroidGeofenceStep4 from "@/components/notifications/geofence/create/android/steps/AndroidGeofenceStep4";
import AndroidNotifPreview from "@/components/notifications/notification/create/android/AndroidNotifPreview";
import { normalize } from "@/components/utils/normalize";
import {
    createGeofence,
    updateGeofence,
    updateGeofenceLocalState,
} from "@/actions/geofence";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { geofenceToApiNormalizer } from "@/components/notifications/geofence/normalize";
import { clearForm } from "@/actions/app";
import { sendTestNotification } from "@/actions/notifications";
import PropTypes from "prop-types";
import { GEOFENCE_WIZARD_MODE } from "@/components/notifications/geofence/constants";
import { androidNotificationNormalizer } from "@/components/notifications/notification/normalize";
import NotificationTestModal from "@/components/notifications/notification/create/controls/NotifTestModal";
import { PLATFORMS } from "@/constants";

const AndroidGeofenceWizard = props => {
    const {
        geofence,
        notification,
        createGeofence,
        updateGeofence,
        history,
        clearForm,
        sendTestNotification,
        wizardMode,
        updateGeofenceLocalState,
    } = props;

    const platform = PLATFORMS.ANDROID;

    return (
        <BaseWizard
            formName="geofence_form"
            title={`ارسال خودکار جغرافیایی`}
            subtitle={`فرم ایجاد ارسال خودکار جغرافیایی`}
            stepFinale={GeofenceFinaleStep}
            footer={GeofenceWizardFooter}
            history={history}
            steps={steps}
            formInitialData={createInitialFormData(geofence, notification)}
            preview={AndroidNotifPreview}
            testModal={NotificationTestModal}
            submitAction={(data, onSuccess, onFailure) => {
                if (data.isTestMode) {
                    sendTestNotification(
                        normalize(data, androidNotificationNormalizer),
                        platform,
                        onSuccess,
                        onFailure,
                    );
                } else {
                    const normalizedData = normalize(
                        data,
                        geofenceToApiNormalizer,
                    );

                    if (wizardMode === GEOFENCE_WIZARD_MODE.CREATE) {
                        createGeofence(normalizedData, onSuccess, onFailure);
                    } else if (wizardMode === GEOFENCE_WIZARD_MODE.EDIT) {
                        const updateLocalState = data =>
                            updateGeofenceLocalState(
                                normalizedData.geofence_id,
                                data,
                                onSuccess,
                            );
                        updateGeofence(
                            normalizedData.geofence_id,
                            normalizedData,
                            updateLocalState,
                            onFailure,
                        );
                    }
                }
            }}
            onUnmountCallback={() => {
                clearForm("geofence_form");
            }}
            hasReduxForm={true}
            passToSteps={{
                platform,
                wizardMode,
            }}
        />
    );
};

const steps = [
    { component: AndroidGeofenceStep1, hideSubmitBtn: true },
    { component: AndroidGeofenceStep2 },
    { component: AndroidGeofenceStep3 },
    { component: AndroidGeofenceStep4 },
];

AndroidGeofenceWizard.defaultProps = {};
AndroidGeofenceWizard.propTypes = {
    geofence: PropTypes.object,
    notification: PropTypes.object,
    createGeofence: PropTypes.func.isRequired,
    updateGeofence: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    sendTestNotification: PropTypes.func.isRequired,
    wizardMode: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            createGeofence,
            updateGeofence,
            clearForm,
            sendTestNotification,
            updateGeofenceLocalState,
        },
        dispatch,
    );
};

export default withErrorCatcher(
    connect(null, mapDispatchToProps)(AndroidGeofenceWizard),
);
