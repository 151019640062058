import { Field } from "redux-form";
import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { NOTIFICATION_TYPES } from "@/components/utilities/commons/wizard/constants";
import PropTypes from "prop-types";
import NotificationType from "@/components/utilities/commons/wizard/controls/NotificationType";

const SelectNotificationTypeComponent = ({ title, notificationTypes }) => {
    return (
        <div className="notif-block">
            <header>
                <p>{title}</p>
            </header>
            <div className="notif-block__body">
                <div className="row row--30">
                    <Field
                        name="notificationTypes"
                        component={NotificationType}
                        notifTypes={notificationTypes}
                    />
                </div>
            </div>
        </div>
    );
};

SelectNotificationTypeComponent.propTypes = {
    title: PropTypes.string,
    notificationTypes: PropTypes.array.isRequired,
};
SelectNotificationTypeComponent.defaultProps = {
    title: "نوع نوتیفیکیشن",
    notificationTypes: NOTIFICATION_TYPES,
};

export default withErrorCatcher(SelectNotificationTypeComponent);
