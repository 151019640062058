import {
    axiosWithAuth,
    createUrl,
    getPlatformName,
    getPlatformNum,
} from "@/queries/constants";

export const EMAILS_KEYS = {
    LIST: () => `emails_list_${getPlatformName()}`,
    GET: () => `email_${getPlatformName()}`,
    FAV: () => `email_favorites_${getPlatformName()}`,
    AUTOMATION_LIST: () => `email_automation_list_${getPlatformName()}`,
    AUTOMATION_GET: () => `email_automation_${getPlatformName()}`,
};

export const fetchEmails = async (key, offset = 0) => {
    const url = await createUrl("messaging/email/");
    const params = { params: { offset, platform: getPlatformNum() } };

    return (await axiosWithAuth().get(url, params))?.data?.results;
};

export const fetchEmail = async (key, wrapperId) => {
    const url = await createUrl(`messaging/email/${wrapperId}`);

    return (await axiosWithAuth().get(url))?.data;
};

export const sendEmail = async data => {
    const url = await createUrl("messaging/email/");

    return (await axiosWithAuth().post(url, data))?.data;
};

export const resendEmail = async wrapper_id => {
    const url = await createUrl("messaging/email/resend/");
    const data = { wrapper_id };

    return (await axiosWithAuth().post(url, data))?.data;
};

export const removeEmail = async wrapperId => {
    const url = await createUrl(`messaging/email/${wrapperId}`);

    await axiosWithAuth().delete(url);
    return wrapperId;
};

export const estimateRecipients = async data => {
    const url = await createUrl(`messaging/email/estimate`);

    return (await axiosWithAuth().post(url, data))?.data?.count;
};

export const fetchEmailFavorites = async () => {
    const url = await createUrl(`email/favorites/`);

    return (await axiosWithAuth().get(url))?.data?.results;
};

export const addNewEmailFavorite = async data => {
    const url = await createUrl(`email/favorites/`);

    return (await axiosWithAuth().post(url, data))?.data;
};

export const removeEmailFavorite = async email => {
    const url = await createUrl(`email/favorites/${email}`);

    await axiosWithAuth().delete(url);
    return email;
};

export const sendTestEmail = async data => {
    const url = await createUrl(`messaging/email/send_test_message/`);

    return (await axiosWithAuth().post(url, data))?.data;
};

/* ******************** Automation *************************** */
export const fetchAutomation = async (key, automationId) => {
    const url = await createUrl(`plus/automation/email/${automationId}`);

    return (await axiosWithAuth().get(url))?.data;
};

export const fetchAutomations = async (key, offset = 0) => {
    const url = await createUrl("plus/automation/email/");
    if (typeof offset === "boolean" && offset === false) offset = 0;
    const params = { params: { offset, platform: getPlatformNum() } };

    return (await axiosWithAuth().get(url, params))?.data?.results;
};

export const createAutomation = async data => {
    const url = await createUrl("plus/automation/email/");

    return (await axiosWithAuth().post(url, data))?.data;
};

export const updateAutomation = async ({ automationId, data }) => {
    const url = await createUrl(`plus/automation/email/${automationId}/`);

    return (await axiosWithAuth().patch(url, data))?.data;
};

export const removeAutomation = async automationId => {
    const url = await createUrl(`plus/automation/email/${automationId}`);

    await axiosWithAuth().delete(url);
    return automationId;
};
