import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import Toggle from "material-ui/Toggle";
import previewWebBackground from "images/notification-preview/web-bg.jpg";
import WindowsToolbar from "images/svg-inline/windows_toolbar.svg";
import MacToolbar from "images/svg-inline/mac_toolbar.svg";
import { formValueSelector } from "redux-form";
import { withErrorCatcher } from "@/components/utilities";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import isEqual from "lodash/isEqual";

const browsers = {
    chrome: require("images/webusers/chrome.png"),
    firefox: require("images/webusers/firefox.png"),
};

const CHROME = "CHROME";
const FIREFOX = "FIREFOX";

export class WebNotifPreview extends Component {
    static propTypes = {
        applications: PropTypes.array,
        step: PropTypes.object,
    };

    static defaultProps = {
        appIds: [],
        applications: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            windowsBrowser: CHROME,
            macBrowser: CHROME,
        };

        this.styles = {
            toggleButton: {
                display: "inline-block",
                verticalAlign: "middle",
                width: "60px",
            },
            track: {
                backgroundColor: "#fff",
                opacity: "0.6",
            },
            trackSwitched: {
                backgroundColor: "#ffffff",
                opacity: "0.6",
            },
            thumbSwitched: {
                backgroundColor: "#ffffff",
            },
        };
    }

    findApp(appIds, applications) {
        const app =
            applications.find(app => app.app_id === appIds[0] && app) || {};
        return app.pushe_subdomain
            ? `${app.pushe_subdomain}.web.pushe.co`
            : app.domain;
    }

    memoizedAppDomain = memoizeOne(this.findApp, isEqual);

    renderPreviewWindows() {
        const { styles } = this;
        const { windowsBrowser } = this.state;

        return (
            <div
                ref={ref => (this.previewWeb = ref)}
                className={classNames("preview-web preview-web--windows", {
                    "preview-web--chrome": windowsBrowser === CHROME,
                    "preview-web--firefox": windowsBrowser === FIREFOX,
                })}
            >
                <div className="preview-web__title">Windows - Chrome</div>
                <div
                    className="preview-web__body"
                    style={{ backgroundImage: `url(${previewWebBackground})` }}
                >
                    <div
                        className="preview-body preview-body--avatar"
                        ref={ref => (this.previewBody = ref)}
                    >
                        {windowsBrowser === CHROME &&
                            this.renderWindowsChromeBody()}
                        {windowsBrowser === FIREFOX &&
                            this.renderWindowsFirefoxBody()}
                    </div>
                    <footer>
                        <WindowsToolbar />
                        <time>
                            <p>6:30 AM</p>
                            <p>7/30/2015</p>
                        </time>
                    </footer>
                </div>
                <div className="preview-toggle">
                    {false && (
                        <>
                            <button
                                className={classNames("preview-toggle__btn", {
                                    active:
                                        this.state.windowsBrowser === CHROME,
                                })}
                                onClick={() =>
                                    this.setState({ windowsBrowser: CHROME })
                                }
                                data-tip="وقتی کاربر نوتیفیکیشن را دریافت می‌کند، حالت کوچک نوتیفیکیشن را خواهد دید"
                            >
                                Chrome
                            </button>
                            <Toggle
                                label=""
                                className="preview-toggle__input"
                                defaultToggled={
                                    this.state.windowsBrowser !== CHROME
                                }
                                onToggle={(event, isInputChecked) =>
                                    this.setState({
                                        windowsBrowser: isInputChecked
                                            ? FIREFOX
                                            : CHROME,
                                    })
                                }
                                style={styles.toggleButton}
                                trackStyle={styles.track}
                                trackSwitchedStyle={styles.trackSwitched}
                                thumbSwitchedStyle={styles.thumbSwitched}
                            />
                            <button
                                className={classNames("preview-toggle__btn", {
                                    active:
                                        this.state.windowsBrowser === FIREFOX,
                                })}
                                onClick={() =>
                                    this.setState({ windowsBrowser: FIREFOX })
                                }
                                data-tip="وقتی کاربر لیست نوتیفیکیشن‌ها را پایین بکشد، حالت بزرگ نوتیفیکیشن را خواهد دید"
                            >
                                Firefox
                            </button>
                        </>
                    )}
                </div>
            </div>
        );
    }

    renderPreviewMac() {
        const { styles } = this;

        return (
            <div className="preview-web preview-web--mac">
                <div className="preview-web__title">Mac - Chrome</div>
                <div
                    className="preview-web__body"
                    style={{ backgroundImage: `url(${previewWebBackground})` }}
                >
                    <header>
                        <MacToolbar />
                        <time>Thu 01:57</time>
                    </header>
                    <div className="preview-body preview-body--avatar">
                        {this.renderMacBody()}
                    </div>
                </div>
                <div className="preview-toggle">
                    {false && (
                        <>
                            <button
                                className={classNames("preview-toggle__btn", {
                                    active: this.state.macBrowser === CHROME,
                                })}
                                onClick={() =>
                                    this.setState({ macBrowser: CHROME })
                                }
                                data-tip="وقتی کاربر نوتیفیکیشن را دریافت می‌کند، حالت کوچک نوتیفیکیشن را خواهد دید"
                            >
                                Chrome
                            </button>
                            <Toggle
                                label=""
                                className="preview-toggle__input"
                                defaultToggled={
                                    this.state.macBrowser !== CHROME
                                }
                                onToggle={(event, isInputChecked) =>
                                    this.setState({
                                        macBrowser: isInputChecked
                                            ? FIREFOX
                                            : CHROME,
                                    })
                                }
                                style={styles.toggleButton}
                                trackStyle={styles.track}
                                trackSwitchedStyle={styles.trackSwitched}
                                thumbSwitchedStyle={styles.thumbSwitched}
                            />
                            <button
                                className={classNames("preview-toggle__btn", {
                                    active: this.state.macBrowser === FIREFOX,
                                })}
                                onClick={() =>
                                    this.setState({ macBrowser: FIREFOX })
                                }
                                data-tip="وقتی کاربر لیست نوتیفیکیشن‌ها را پایین بکشد، حالت بزرگ نوتیفیکیشن را خواهد دید"
                            >
                                Firefox
                            </button>
                        </>
                    )}
                </div>
            </div>
        );
    }

    renderWindowsChromeBody() {
        const { title, content, icon, image, buttons, appIds, applications } =
            this.props;
        let buttonsArray =
            Array.isArray(buttons) && buttons.length > 0
                ? buttons.slice(0, 2)
                : [];
        let domain = this.memoizedAppDomain(appIds, applications);

        return (
            <div>
                <div className="preview-body__main">
                    {image && (
                        <div
                            className="preview-body__img"
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    )}

                    <div className="preview-body__content">
                        <div
                            className={classNames(
                                "preview-body__icon",
                                !icon && "default",
                            )}
                            style={{
                                backgroundImage: `url(${
                                    icon || browsers.chrome
                                })`,
                            }}
                        />
                        <div className="preview-body__innerContents">
                            <div className="preview-body__title">
                                {title || "تیتر"}
                            </div>
                            <div className="preview-body__desc">
                                {content || "متن نوتیفیکیشن"}
                            </div>
                            <div className="preview-body__browserSub">
                                Google Chrome • {domain || "example.com"}
                            </div>
                        </div>
                    </div>

                    {buttonsArray.length > 0 && (
                        <div
                            className={classNames(
                                "preview-body__buttons",
                                buttonsArray.length > 1 && "multiple",
                            )}
                        >
                            {buttonsArray.map((button, indx) => {
                                return (
                                    <div key={indx} className="previewBtn">
                                        {button.btn_content}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderMacBody() {
        const { macBrowser } = this.state;
        const { title, content, icon, appIds, applications } = this.props;
        const browserLogo =
            macBrowser === CHROME ? browsers.chrome : browsers.firefox;
        let domain = this.memoizedAppDomain(appIds, applications);

        return (
            <div className="preview-body__main">
                <div
                    className="preview-body__browser-logo"
                    style={{ backgroundImage: `url(${browserLogo})` }}
                />

                <div className="preview-body__content">
                    <div className="preview-body__title">{title || "تیتر"}</div>
                    <div className="preview-body__browserSub">
                        {domain || "example.com"}
                    </div>
                    <div className="preview-body__desc">
                        {content || "متن نوتیفیکیشن"}
                    </div>
                </div>

                {icon && (
                    <div
                        className="preview-body__icon"
                        style={{ backgroundImage: `url(${icon})` }}
                    />
                )}
            </div>
        );
    }

    render() {
        return (
            <div className="preview preview--web">
                {this.renderPreviewWindows()}
                {this.renderPreviewMac()}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { formName } = ownProps;
    let action, title, content, image, icon, buttons, appIds;

    let formValue = formValueSelector(formName || "notification_wizard")(
        state,
        "notification.data.action",
        "notification.data.title",
        "notification.data.content",
        "notification.data.image",
        "notification.data.icon",
        "notification.data.buttons",
        "app_ids",
    );
    let Data = formValue?.notification?.data ?? {};

    appIds = formValue?.app_ids;

    if (
        Data.title ||
        Data.content ||
        Data.image ||
        Data.icon ||
        Data.buttons ||
        Data.action
    ) {
        ({ action, title, content, image, icon, buttons } = Data);
    } else {
        let formValue = formValueSelector(formName || "notification_wizard")(
            state,
            "data.action",
            "data.title",
            "data.content",
            "data.image",
            "data.icon",
            "data.buttons",
            "app_ids",
        );
        let Data = formValue?.data ?? {};
        ({ action, title, content, image, icon, buttons } = Data);
    }

    return {
        appIds,
        action,
        title,
        content,
        icon,
        image,
        buttons,
    };
}

export default withErrorCatcher(
    connect(mapStateToProps, null)(WebNotifPreview),
);
