import React, { Component } from "react";
import "styles/modules/_mod-support-widget.scss";
import support from "@/../assets/images/svg/support.svg";
import { sendTicket } from "@/actions/dashboard";
import { withErrorCatcher } from "@/components/utilities";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ticketFormValidations } from "@/components/utils/validations/ticketForm";
import TextField from "material-ui/TextField";
import isEmpty from "lodash/isEmpty";
import { TOAST_CONTENT } from "@/components/utils/assertion-texts";
import { successToast, errorToast } from "@/components/utils/Toasts";
import { CircleLoader } from "@/components/utilities";

class SupportWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
            errors: {},
            isLoading: false,
        };
    }

    onSendClicked(e) {
        e.preventDefault();

        let { name, email, subject, message } = this.state;
        let { isLogin, userEmail } = this.props;
        if (isLogin) {
            email = userEmail;
        }
        let errors = ticketFormValidations({ name, email, subject, message });
        this.setState({ errors });
        if (isEmpty(errors)) {
            let body = { name, email, subject, message, file: null };
            const onSuccess = () => {
                this.setState({
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                    errors: {},
                    isLoading: false,
                });
                successToast(
                    TOAST_CONTENT.SEND_TICKET_SUCCESS.TITLE,
                    TOAST_CONTENT.SEND_TICKET_SUCCESS.DESC,
                );
                this.props.onClose();
            };
            const onFailure = () => {
                this.setState({ isLoading: false });
                errorToast(
                    TOAST_CONTENT.SEND_TICKET_ERROR.TITLE,
                    TOAST_CONTENT.SEND_TICKET_ERROR.DESC,
                );
            };

            this.setState({ isLoading: true }, () => {
                this.props.sendTicket(body, onSuccess, onFailure);
            });
        }
    }

    generateEmailInput() {
        const { errors, email } = this.state;
        const { isLogin } = this.props;
        if (!isLogin) {
            return (
                <TextField
                    errorText={errors.email}
                    name="email"
                    type="text"
                    floatingLabelText="ایمیل"
                    fullWidth={true}
                    style={{ width: "80%", margin: "0px auto" }}
                    floatingLabelShrinkStyle={{ color: "#66B44E" }}
                    underlineFocusStyle={{ borderBottomColor: "#66B44E" }}
                    inputStyle={{
                        height: "85%",
                        paddingTop: "20px",
                        marginTop: "10px",
                    }}
                    onChange={event =>
                        this.setState({ email: event.target.value })
                    }
                    value={email}
                />
            );
        }
    }

    render() {
        const { errors, name, subject, message, isLoading } = this.state;
        const { onClose } = this.props;
        return (
            <div className="support-widget open" id="supportWidget">
                <svg className="support-btn-close-icon" onClick={onClose}>
                    <use href="#svg_support_close" />
                </svg>
                <img src={support} className="image" />
                <div className="description">
                    <h4>همیشه در کنارتان هستیم</h4>
                    <p>در اولین فرصت با ایمیل به شما پاسخ خواهیم داد.</p>
                    <span className="subtitle">
                        شاید جواب سوال شما اینجا باشد:{" "}
                        <a href="https://pushe.co/faq/">سوالات متداول</a> ،{" "}
                        <a href="https://pushe.co/docs">مستندات</a>
                    </span>
                </div>
                <form className="support-form" id="supportForm">
                    <div className="errors">
                        وارد کردن تمامی فیلد ها الزامی است.
                    </div>
                    <TextField
                        errorText={errors.name}
                        name="name"
                        type="text"
                        floatingLabelText="نام"
                        fullWidth={true}
                        style={{ width: "80%", margin: "0px auto" }}
                        floatingLabelShrinkStyle={{ color: "#66B44E" }}
                        underlineFocusStyle={{ borderBottomColor: "#66B44E" }}
                        inputStyle={{
                            height: "85%",
                            paddingTop: "20px",
                            marginTop: "10px",
                        }}
                        onChange={event =>
                            this.setState({ name: event.target.value })
                        }
                        value={name}
                    />

                    {this.generateEmailInput()}
                    <TextField
                        errorText={errors.subject}
                        name="subject"
                        type="text"
                        floatingLabelText="عنوان"
                        fullWidth={true}
                        style={{ width: "80%", margin: "0px auto" }}
                        floatingLabelShrinkStyle={{ color: "#66B44E" }}
                        underlineFocusStyle={{ borderBottomColor: "#66B44E" }}
                        inputStyle={{
                            height: "85%",
                            paddingTop: "20px",
                            marginTop: "10px",
                        }}
                        onChange={event =>
                            this.setState({ subject: event.target.value })
                        }
                        value={subject}
                    />
                    <TextField
                        errorText={errors.message}
                        name="subject"
                        type="text"
                        floatingLabelText="سوال"
                        fullWidth={true}
                        style={{ width: "80%", margin: "0px auto" }}
                        floatingLabelShrinkStyle={{ color: "#66B44E" }}
                        underlineFocusStyle={{ borderBottomColor: "#66B44E" }}
                        onChange={event =>
                            this.setState({ message: event.target.value })
                        }
                        value={message}
                        multiLine={true}
                        rows={2}
                        rowsMax={4}
                    />
                    <button
                        onClick={e => this.onSendClicked(e)}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <CircleLoader size={20} style={{ top: "2px" }} />
                        ) : (
                            "ارسال"
                        )}
                    </button>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { auth, app } = state;
    const { profile } = auth;
    const { demo } = app;

    let userEmail = "";
    let isLogin = false;
    if (!demo && profile) {
        isLogin = true;
        userEmail = profile.email;
    }
    return {
        userEmail,
        isLogin,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ sendTicket }, dispatch);
}

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(SupportWidget),
);
