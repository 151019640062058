import React from "react";
import {
    PERMISSIONS,
    useHasPermission,
    withErrorCatcher,
} from "@/components/utilities";
import PagePlaceholder from "@/components/utilities/extra/PagePlaceholder";
import InfoIcon from "images/svg-inline/info.svg";
import { blue900 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

const AutomationPagePlaceholder = ({ history }) => {
    const hasCreatePermission = useHasPermission(
        PERMISSIONS.NOTIFICATION_AUTOMATION_CREATE,
    );
    const platform = useSelector(state => state?.app?.platform);
    return (
        <>
            <PagePlaceholder
                icon={<InfoIcon />}
                body={"هیچ نوتیفیکیشن اتوماتیکی یافت نشد"}
                footer={
                    <div
                        data-tip={
                            !hasCreatePermission
                                ? "برای ساخت نوتیفیکیشن اتوماتیک باید پنل خود را ارتقا دهید"
                                : ""
                        }
                    >
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            disabled={!hasCreatePermission}
                            label={"ساخت اعلان اتوماتیک"}
                            onClick={() =>
                                history.push(
                                    `/notification/automation/${platform}/create/`,
                                )
                            }
                        />
                    </div>
                }
            />
            <ReactTooltip className="tooltip" effect="solid" place="bottom" />
        </>
    );
};

export default withErrorCatcher(AutomationPagePlaceholder);
