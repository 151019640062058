import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import BaseModal from "@/components/utilities/modal/baseModal";
import { withErrorCatcher } from "@/components/utilities";

const RenameApplicationModal = props => {
    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const { app: { id } = {}, onClose } = props;
    const appNames = props.app?.favorite_data.name;
    useEffect(() => {
        setName(appNames);
    }, [appNames]);
    function renderError() {
        if (error != null && typeof error !== "string") {
            const errors = Object.entries(error.data).map(item => `${item[1]}`);
            return errors.map((key, index) => <div key={index}>{key}</div>);
        } else if (typeof error === "string") {
            return <div>{error}</div>;
        }
    }

    const onSuccess = () => {
        onClose();
        setError(null);
    };

    return (
        <BaseModal
            onClose={() => onClose()}
            title="تغییر نام دستگاه مورد علاقه"
            desc="برای دستگاه خود یک نام جدید انتخاب کنید."
            body={
                <div>
                    <TextField
                        className="mui-textfield"
                        floatingLabelText="نام"
                        value={name}
                        type="text"
                        name="deviceRename"
                        onChange={event => {
                            setName(event.target.value);
                            setError(null);
                        }}
                        fullWidth={true}
                        errorText={renderError()}
                    />
                </div>
            }
            footer={
                <div>
                    <FlatButton
                        className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                        rippleColor={blue900}
                        label="ذخیره"
                        onClick={() => {
                            if (!name) {
                                setError("این فیلد الزامی است");
                            } else {
                                props.onAccept(
                                    { name, id },
                                    onSuccess(),
                                    error => setError(error),
                                );
                            }
                        }}
                    />
                    <FlatButton
                        className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                        rippleColor={blue900}
                        label="انصراف"
                        onClick={() => onClose()}
                    />
                </div>
            }
        />
    );
};

RenameApplicationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    error: PropTypes.object,
};

export default withErrorCatcher(RenameApplicationModal);
