
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const BlankLink = makeShortcode("BlankLink");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`اضافه‌کردن پلاگین به پروژه`}</h1>
    <p>{`به فایل `}<inlineCode parentName="p">{`pubspec.yaml`}</inlineCode>{` `}<BlankLink link="https://pub.dev/packages/pushe_flutter" mdxType="BlankLink">{`پلاگین پوشه`}</BlankLink>{` را اضافه‌کنید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yml",
        "metastring": "{4}",
        "{4}": true
      }}>{`dependencies:
    # ...
    # Add Pushe
    pushe_flutter: ^2.4.0
`}</code></pre>
    <p>{`سپس سینک کنید یا دستور `}<inlineCode parentName="p">{`flutter packages get`}</inlineCode>{` را در ترمینال وارد کنید تا پلاگین‌ها به پروژه اضافه‌ شوند.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;