/* eslint-disable no-undef */
import MobileDetect from "mobile-detect";

const util = {};
import * as jalaali from "jalaali-js";
import AndroidStudio from "images/app-builder/android-studio.svg";
import Basic4Android from "images/app-builder/basic4android.png";
import Cordova from "images/app-builder/cordova.svg";
import huawei from "images/app-builder/huawei-logo-color.png";
import huaweiGray from "images/app-builder/huawei-logo-gray.png";
import Eclipse from "images/app-builder/eclipse.svg";
import Unity from "images/app-builder/unity.svg";
import ReactNative from "images/app-builder/react-native.png";
import AppBuilder from "images/app-builder/default.png";
import Xcode from "images/app-builder/xcode.png";
import defaultIcon from "images/app-icon/app-blank.png";
import OnsSignal from "images/app-builder/onesignal.svg";
import FlutterIcon from "images/app-builder/flutter_gray.svg";
import eventHandler from "@/components/utils/events/eventHandler";

global.windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth || //IE <= 8
    document.body.clientWidth;

global.windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight || //IE <= 8
    document.body.clientHeight;

global.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;

global.cancelAnimationFrame =
    window.cancelAnimationFrame || window.mozCancelAnimationFrame;

// media query responsive
global.SCREEN = {
    XS: 500,
    SM: 800,
    MD: 1050,
    LG: 1200,
    XLG: 1400,
    XXLG: 2500,
};

global.md = new MobileDetect(window.navigator.userAgent);
util.isDesktop = !(md.mobile() || md.tablet());
util.isIOS = md.os() === "iOS";
util.isIOSSafari = md.os() && md.userAgent() === "Safari";
util.isIe =
    navigator.appName == "Microsoft Internet Explorer" ||
    !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)
    );

if (window.pageYOffset) {
    util._getScrollTop = function () {
        return window.pageYOffset;
    };
} else {
    util._getScrollTop = function () {
        return document.documentElement.scrollTop || document.body.scrollTop;
    };
}

util._isEmptyString = function (str) {
    return str.match(/\S/) ? false : true;
};

util._generateIconUrl = function (iconUrl) {
    if (iconUrl) {
        return iconUrl;
    }
    return defaultIcon;
};

util._calculteUnReadAlerts = function (alerts, item, value) {
    let counter = 0;
    for (let index = 0; index < alerts.length; index++) {
        if (alerts[index][item] === value) counter += 1;
    }
    return counter;
};

util._generateImageUrl = function (framework) {
    let imageUrl = "";

    switch (framework) {
        case "Cordova":
            imageUrl = Cordova;
            break;
        case "huawei":
            imageUrl = huawei;
            break;
        case "huaweiGray":
            imageUrl = huaweiGray;
            break;
        case "AndroidStudio":
            imageUrl = AndroidStudio;
            break;
        case "Basic4Android":
            imageUrl = Basic4Android;
            break;
        case "Unity":
            imageUrl = Unity;
            break;
        case "Eclipse":
            imageUrl = Eclipse;
            break;
        case "ReactNative":
            imageUrl = ReactNative;
            break;
        case "AppBuilder":
            imageUrl = AppBuilder;
            break;
        case "Xcode":
            imageUrl = Xcode;
            break;
        case "OneSignal":
            imageUrl = OnsSignal;
            break;
        case "Flutter":
            imageUrl = FlutterIcon;
            break;
        default:
            imageUrl = "";
    }

    return imageUrl;
};

util._isOlderThan = function (timeOffset, time) {
    let today = new Date();
    today.setDate(new Date().getDate() - timeOffset);
    let priorDay = today.toISOString().slice(0, 10);
    const creationDay = time.slice(0, 10);
    const pDay = Date.parse(priorDay);
    const cDay = Date.parse(creationDay);
    return cDay > pDay;
};

util._isValidDate = value => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
        // it is a date
        return !isNaN(value.getTime());
    } else {
        // not a date
        return false;
    }
};

util._convertToLocalTimezone = function (datetime) {
    if (datetime) {
        try {
            const time = new Date(datetime);
            const jDate = jalaali.toJalaali(time);

            const hour = `${("0" + time.getHours()).slice(-2)}:${(
                "0" + time.getMinutes()
            ).slice(-2)}`;
            // Use this link to make minutes & hours multiple digits - https://stackoverflow.com/a/23579692/8823891
            const date = `${jDate.jy}/${jDate.jm}/${jDate.jd}`;

            return util._latinToPersian(`${hour} ${date}`);
        } catch (e) {
            console.error(e);
        }
    }
    return null;
};

util._latinToPersian = function (input) {
    let result;
    if (typeof input === "undefined") return "not found";
    const str = input.toString();

    result = str.replace(/0/g, "۰");
    result = result.replace(/1/g, "۱");
    result = result.replace(/2/g, "۲");
    result = result.replace(/3/g, "۳");
    result = result.replace(/4/g, "۴");
    result = result.replace(/5/g, "۵");
    result = result.replace(/6/g, "۶");
    result = result.replace(/7/g, "۷");
    result = result.replace(/8/g, "۸");
    result = result.replace(/9/g, "۹");

    return result;
};

util._addClass = function ($elem, ...newClassName) {
    let classNameArray = Array.isArray(...newClassName)
        ? [].concat(...newClassName)
        : newClassName;

    classNameArray.forEach(klass => {
        if (!$elem.className.includes(klass)) {
            $elem.className = $elem.className.concat(` ${klass}`);
        }
    });
};

util._removeClass = function ($elem, ...removeClassName) {
    let classNameArray = Array.isArray(...removeClassName)
        ? [].concat(...removeClassName)
        : removeClassName;

    classNameArray.forEach(klass => {
        if ($elem.className.includes(klass)) {
            $elem.className = $elem.className.replace(` ${klass}`, "");
        }
    });
};

util._hasClass = function ($elem, className) {
    return $elem.className.includes(className) ? true : false;
};

util._isWindowOverflow = function () {
    return document.body.scrollHeight > window.innerHeight;
};

let setGlobalSizes = function () {
    global.windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth || //IE <= 8
        document.body.clientWidth;

    global.windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight || //IE <= 8
        document.body.clientHeight;
};

eventHandler.on("resize", setGlobalSizes);

export function getDate(date) {
    return `${date.getFullYear()}-${
        +date.getUTCMonth() + 1
    }-${date.getUTCDate()}`;
}

export function getTime(date) {
    return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
}

export const hasOneSignal = (app_ids, applications) => {
    if (!app_ids || !applications) return false;
    if (!Array.isArray(app_ids)) app_ids = [app_ids];
    return app_ids.some(
        appId =>
            (applications.find(i => i.app_id === appId) || {})[
                "has_one_signal_user"
            ],
    );
};

export function combineDateTime(date, time) {
    if (typeof date !== "object" || date === null) return null;
    if (typeof time !== "object" || time === null) return null;

    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time.getHours(),
        time.getMinutes(),
    );
}

export function convertArrayToObj(prop1, prop2, arr) {
    let changList = arr.reduce((total, currentValue) => {
        const data = {};
        data[prop1] = currentValue;
        data[prop2] = currentValue;

        return [...total, data];
    }, []);
    return changList;
}

export function getActionType(action) {
    const { url = "", action_type, market_package_name } = action;

    const markets = ["market", "bazaar", "myket", "iranapps"];

    if (url === "" && action_type === "A") {
        return "open_app";
    } else if (url.includes("tg://join?invite=") && action_type === "U") {
        return "join_telegram";
    } else if (url.includes("tg://resolve?domain=") && action_type === "U") {
        return "invite_telegram";
    } else if (url.includes("tel:") && action_type === "U") {
        return "open_tel";
    } else if (url.includes("sms:") && action_type === "U") {
        return "open_sms";
    } else if (url.includes("mailto") && action_type === "U") {
        return "open_emil";
    } else if (
        market_package_name !== "" &&
        markets.some(i => url.startsWith(i)) &&
        action_type === "U"
    ) {
        return "app_market";
    } else if (url !== "" && action_type === "W") {
        return "web_page";
    } else if (url.includes("http") && action_type === "U") {
        return "open_link";
    } else if (url !== "" && action_type === "U") {
        return "other_intents";
    } else if (action_type === "G") {
        return "open_dialogue";
    } else if (action_type === "L") {
        return "download_app";
    } else if (action_type === "T") {
        return "app_activity";
    }
}

export default util;
