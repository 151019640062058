import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import {
    Card,
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardListWrapper,
    DateTimeComponent,
    DeleteModalAction,
    DisplayAppComponent,
    DisplayStatistics,
    ResendModalAction,
    StatisticsComponent,
    StatusComponent,
} from "@/components/utilities/modules/CardList";
import { tabs } from "@/components/sms/sms/list/android/tabs/tabs";
import { createStatusObj } from "@/components/notifications/notification/list/common/createCardStatus";
import NotificationStatistics from "@/components/notifications/notification/list/common/notification-cards/NotificationStatistics";
import { Notification } from "@/components/utils/lib/svgicons";
import { useInfiniteQuery, useMutation } from "react-query";
import {
    commonGetFetchMore,
    onSuccessFilterList,
    onSuccessAppendToList,
} from "@/queries/constants";
import { fetchAllSMS, removeSMS, resendSMS, SMS_KEYS } from "@/queries/sms";
import SMSPagePlaceholder from "@/components/sms/sms/list/SMSPagePlaceholder";
import FixedHeader from "@/components/sms/sms/list/common/FixedHeader";

const AndroidSMSList = props => {
    const { history } = props;
    const platform = "android";

    const { status, fetchMore, canFetchMore, isFetchingMore, data } =
        useInfiniteQuery(SMS_KEYS.LIST(), fetchAllSMS, {
            cacheTime: Infinity,
            staleTime: 0,
            getFetchMore: commonGetFetchMore,
        });

    const [
        resendSMSMutation,
        { error: resendError, isLoading: resendIsLoading },
    ] = useMutation(resendSMS, {
        onSuccess: data => onSuccessAppendToList(SMS_KEYS.LIST(), data),
    });

    const [
        removeSMSMutation,
        { error: removeError, isLoading: removeIsLoading },
    ] = useMutation(removeSMS, {
        onSuccess: wrapperId =>
            onSuccessFilterList(SMS_KEYS.LIST(), "wrapper_id", wrapperId),
    });

    if (data?.[0]?.length === 0 && !isFetchingMore)
        return <SMSPagePlaceholder history={history} />;

    return (
        <>
            <FixedHeader
                title={"پیامک ها"}
                labelBtn={"ارسال پیامک"}
                redirectClicked={() => history.push(`/sms/${platform}/create/`)}
            />
            <CardListWrapper
                hasMore={canFetchMore}
                isLoading={isFetchingMore || status === "loading"}
                onEnter={() => canFetchMore && !isFetchingMore && fetchMore()}
            >
                {data?.map((group, i) => (
                    <React.Fragment key={i}>
                        {group?.map(n => {
                            const statusList = createStatusObj(n);

                            return (
                                <Card key={n?.["wrapper_id"]}>
                                    <CardHeader
                                        StatusComponent={StatusComponent}
                                        statusProps={{
                                            statusList,
                                        }}
                                        DateTimeComponent={DateTimeComponent}
                                        dateTimeProps={{
                                            dateTime: n?.["created_at"],
                                        }}
                                    />
                                    <CardBody
                                        tabs={tabs}
                                        tabProps={{
                                            notification: n,
                                        }}
                                        fixedComponents={[DisplayAppComponent]}
                                        fixedProps={{
                                            appIds: n?.["app_ids"],
                                            platform: platform,
                                        }}
                                    />
                                    <CardFooter>
                                        <StatisticsComponent
                                            statistics={n?.statistics}
                                            meta={[
                                                {
                                                    key: "accurate_recipients",
                                                    icon: "svg_user_2",
                                                    name: "تعداد مخاطبین",
                                                },
                                                // {key: 'delivered', icon: 'svg_eye', name: 'تعداد دریافت‌ها'},
                                            ]}
                                        />
                                        <div className="notif-action-buttom-wrapper">
                                            <DisplayStatistics
                                                statisticsComponent={
                                                    NotificationStatistics
                                                }
                                                statisticsComponentProps={{
                                                    wrapperId:
                                                        n?.["wrapper_id"],
                                                }}
                                                disabled={true}
                                            />
                                            <CardActions
                                                actions={[
                                                    {
                                                        id: "1",
                                                        type: "button",
                                                        tip: "ارسال دوباره",
                                                        // eslint-disable-next-line react/display-name
                                                        iconComponent: () => (
                                                            <Notification viewBox="0 0 21 21" />
                                                        ),
                                                        modalAction:
                                                            ResendModalAction,
                                                        modalActionProps: {
                                                            title: "ارسال دوباره پیامک",
                                                            body: (
                                                                <div>
                                                                    آیا از ارسال
                                                                    دوباره پیامک
                                                                    {
                                                                        n?.[
                                                                            "wrapper_id"
                                                                        ]
                                                                    }
                                                                    مطمئن هستید؟
                                                                    ‍‍‍
                                                                </div>
                                                            ),
                                                            error: resendError
                                                                ?.response
                                                                ?.data,
                                                            isLoading:
                                                                resendIsLoading,
                                                            onAccept: () =>
                                                                resendSMSMutation(
                                                                    n?.[
                                                                        "wrapper_id"
                                                                    ],
                                                                ),
                                                        },
                                                    },
                                                    {
                                                        id: "2",
                                                        type: "link",
                                                        tip: "فوروارد",
                                                        iconName: "svg_forward",
                                                        url: `/sms/${platform}/forward/${n?.["wrapper_id"]}`,
                                                    },
                                                    {
                                                        id: "3",
                                                        type: "button",
                                                        tip: "حذف",
                                                        iconName: "svg_trash",
                                                        modalAction:
                                                            DeleteModalAction,
                                                        modalActionProps: {
                                                            title: "حذف ایمیل",
                                                            body: (
                                                                <div>
                                                                    آیا از پاک
                                                                    شدن پیامک
                                                                    &nbsp;
                                                                    {
                                                                        n?.[
                                                                            "wrapper_id"
                                                                        ]
                                                                    }
                                                                    &nbsp; مطمئن
                                                                    هستید؟ ‍‍‍
                                                                </div>
                                                            ),
                                                            error: removeError
                                                                ?.response
                                                                ?.data,
                                                            isLoading:
                                                                removeIsLoading,
                                                            onAccept: () =>
                                                                removeSMSMutation(
                                                                    n?.[
                                                                        "wrapper_id"
                                                                    ],
                                                                ),
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </CardFooter>
                                </Card>
                            );
                        })}
                    </React.Fragment>
                ))}
            </CardListWrapper>
        </>
    );
};

export default withErrorCatcher(AndroidSMSList);
