import React from "react";

import classNames from "classnames";

import "styles/modules/_mod-menulist.scss";

export default ({ isOpen, children, className, onSettingClose, id }) => {
    const classname = classNames(
        "menulist",
        { "menulist--open": isOpen },
        `${className}`,
    );
    return (
        <div className={classname} onClick={onSettingClose} id={id}>
            <ul>{children}</ul>

            <div className="menulist-underlay"></div>
        </div>
    );
};
