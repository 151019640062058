import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import DialogActions from "@material-ui/core/DialogActions";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    dialogTitleRoot: {
        textAlign: "right",
        color: theme.palette.dashboard.black,
    },
    dialogActionRoot: {
        direction: "ltr",
        flexDirection: "row",
        width: "100%",
        padding: "10px 10px",
    },
    buttonTextRoot: {
        textAlign: "right",
    },
    listRoot: {
        maxHeight: "400px",
        minHeight: "50px",
        overflowY: "auto",
        direction: "ltr",
    },
    saveContentTextRoot: {
        textAlign: "right",
        fontSize: ".9rem",
        color: "#000",
        padding: "5px 20px",
        marginBottom: 0,
    },
}));

const SendTestDialogBase = props => {
    const {
        onModalClose: onClose,
        AddItemComponent,
        items,
        isLoading,
        removeMutation,
        sendTestFunc,
        addItemMutation,
        itemKey,
        itemLabel,
        dialogTitle,
        addItemLabel,
    } = props;
    const classes = useStyles();

    const [displaySave, toggleDisplaySave] = useState(false);

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
            scroll={"body"}
            classes={{ scrollPaper: classes.scrollPaper }}
            open={true}
        >
            <DialogTitle
                classes={{ root: classes.dialogTitleRoot }}
                id="simple-dialog-title"
            >
                {dialogTitle}
            </DialogTitle>
            <List classes={{ root: classes.listRoot }}>
                {isLoading && <CircleLoader />}

                {items?.map(item => (
                    <ListItem
                        key={item[itemKey]}
                        button
                        alignItems="flex-start"
                        disabled={isLoading}
                        onClick={() => sendTestFunc(item[itemKey])}
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={item[itemKey]}
                            secondary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {item[itemLabel]}
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction
                            onClick={() => removeMutation(item[itemKey])}
                        >
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}

                {items?.length === 0 && (
                    <p style={{ textAlign: "center" }}>هیچ موردی یافت نشد</p>
                )}
            </List>

            {displaySave && AddItemComponent && (
                <AddItemComponent
                    isLoading={isLoading}
                    onToggle={() => toggleDisplaySave(false)}
                    addItemMutation={addItemMutation}
                />
            )}

            {!displaySave && (
                <DialogActions classes={{ root: classes.dialogActionRoot }}>
                    <ListItem
                        autoFocus
                        button
                        disabled={isLoading}
                        onClick={() => toggleDisplaySave(true)}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            classes={{ root: classes.buttonTextRoot }}
                            primary={addItemLabel}
                        />
                    </ListItem>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default withErrorCatcher(SendTestDialogBase);
