import sound1 from "sounds/1.mp3";
import sound2 from "sounds/2.mp3";
import sound3 from "sounds/3.mp3";
import sound4 from "sounds/4.mp3";
import sound5 from "sounds/5.mp3";
import Types from "@/actions/_types";

export const PREFERRED_PLATFORM = "web";

export const cities = [
    { id: 1, key: "East Azerbaijan", name: "آذربایجان شرقی" },
    { id: 2, key: "Azarbayjan-e Gharbi", name: "آذربایجان غربی" },
    { id: 3, key: "Ardabil", name: "اردبیل" },
    { id: 4, key: "Isfahan", name: "اصفهان" },
    { id: 5, key: "Alborz", name: "البرز" },
    { id: 6, key: "Ilam", name: "ایلام" },
    { id: 7, key: "Bushehr", name: "بوشهر" },
    { id: 8, key: "Tehran", name: "تهران" },
    { id: 9, key: "Chahar Mahall va Bakhtiari", name: "چهارمحال و بختیاری" },
    { id: 10, key: "Khorasan-e Jonubi", name: "خراسان جنوبی" },
    { id: 11, key: "Razavi Khorasan", name: "خراسان رضوی" },
    { id: 12, key: "Khorasan-e Shomali", name: "خراسان شمالی" },
    { id: 13, key: "Khuzestan", name: "خوزستان" },
    { id: 14, key: "Zanjan", name: "زنجان" },
    { id: 15, key: "Semnan", name: "سمنان" },
    { id: 16, key: "Sistan and Baluchestan", name: "سیستان و بلوچستان" },
    { id: 17, key: "Fars", name: "فارس" },
    { id: 18, key: "Qazvin", name: "قزوین" },
    { id: 19, key: "Qom", name: "قم" },
    { id: 20, key: "Kordestan", name: "کردستان" },
    { id: 21, key: "Kerman", name: "کرمان" },
    { id: 22, key: "Kermanshah", name: "کرمانشاه" },
    { id: 23, key: "kohgiluyeh va buyer ahmad", name: "کهگیلوئیه و بویراحمد" },
    { id: 24, key: "Golestan", name: "گلستان" },
    { id: 25, key: "Gilan", name: "گیلان" },
    { id: 26, key: "Lorestan", name: "لرستان" },
    { id: 27, key: "Mazandaran", name: "مازندران" },
    { id: 28, key: "Markazi", name: "مرکزی" },
    { id: 29, key: "Hormozgan", name: "هرمزگان" },
    { id: 30, key: "Hamadan", name: "همدان" },
    { id: 31, key: "Yazd", name: "یزد" },
];

export const operators = [
    { id: 1, key: "ir-mci", name: "همراه اول" },
    { id: 2, key: "irancell", name: "ایرانسل" },
    { id: 3, key: "rightel", name: "رایتل" },
];
export const typeDevice = [
    { id: 1, key: "mobail", name: "تلفن همراه" },
    { id: 2, key: "desktop", name: "دسکتاپ" },
];
export const typeBrowser = [
    { id: 1, key: "chrome", name: "کروم" },
    { id: 2, key: "firefox", name: "فایرفاکس" },
    { id: 2, key: "edge", name: "مایکروسافت ایج" },
];

export const brands = [
    { id: 1, key: "samsung", name: "سامسونگ" },
    { id: 2, key: "LGE", name: "ال جی" },
    { id: 3, key: "asus", name: "ایسوس" },
    { id: 4, key: "htc", name: "اچ تی سی" },
    { id: 5, key: "lenovo", name: "لنووو" },
    { id: 6, key: "sony", name: "سونی" },
    { id: 7, key: "huawei", name: "هواوی" },
];

export const networks = [
    { id: 1, key: "data", name: "اینترنت موبایل" },
    { id: 2, key: "wifi", name: "wifi" },
];

export const ttlOptions = [
    { name: "فقط کاربران آنلاین", key: 0 },
    { name: "پانزده دقیقه", key: 60 * 15 },
    { name: "سی دقیقه", key: 60 * 20 },
    { name: "یک ساعت", key: 60 * 60 },
    { name: "دو ساعت", key: 60 * 60 * 2 },
    { name: "سه ساعت", key: 60 * 60 * 3 },
    { name: "چهار ساعت", key: 60 * 60 * 4 },
    { name: "پنج ساعت", key: 60 * 60 * 5 },
    { name: "شش ساعت", key: 60 * 60 * 6 },
    { name: "هفت ساعت", key: 60 * 60 * 7 },
    { name: "هشت ساعت", key: 60 * 60 * 8 },
    { name: "نه ساعت", key: 60 * 60 * 9 },
    { name: "ده ساعت", key: 60 * 60 * 10 },
    { name: "یازده ساعت", key: 60 * 60 * 11 },
    { name: "دوازده ساعت", key: 60 * 60 * 12 },
    { name: "یک روز", key: 60 * 60 * 24 },
    { name: "دو روز", key: 60 * 60 * 24 * 2 },
    { name: "یک هفته", key: 60 * 60 * 24 * 7 },
    { name: "دو هفته", key: 60 * 60 * 24 * 2 * 7 },
    { name: "یک ماه", key: 60 * 60 * 24 * 4 * 7 },
];

export const pricingRange = {
    [Types.PLANS.PROFESSIONAL]: [
        { name: "۵۰ هزار پوش", key: 100000 },
        { name: "۷۵ هزار پوش", key: 150000 },
        { name: "۱۰۰ هزار پوش", key: 200000 },
        { name: "۱۲۵ هزار پوش", key: 250000 },
        { name: "۱۵۰ هزار پوش", key: 300000 },
        { name: "۲۵۰ هزار پوش", key: 500000 },
        { name: "۳۰۰ هزار پوش", key: 600000 },
        { name: "۳۵۰ هزار پوش", key: 700000 },
        { name: "۴۰۰ هزار پوش", key: 800000 },
        { name: "۴۵۰ هزار پوش", key: 900000 },
        { name: "۵۰۰ هزار پوش", key: 1000000 },
        { name: "۱ میلیون پوش", key: 2000000 },
        { name: "۱.۵ میلیون پوش", key: 3000000 },
        { name: "۲.۵ میلیون پوش", key: 5000000 },
        { name: "۵ میلیون پوش", key: 10000000 },
        { name: "۱۰ میلیون پوش", key: 20000000 },
        { name: "۲۵ میلیون پوش", key: 50000000 },
        { name: "۵۰ میلیون پوش", key: 100000000 },
        { name: "۱۰۰ میلیون پوش", key: 200000000 },
        { name: "۱۵۰ میلیون پوش", key: 300000000 },
        { name: "۲۰۰ میلیون پوش", key: 400000000 },
        { name: "۲۲۵ میلیون پوش", key: 450000000 },
    ],
    [Types.PLANS.ENTERPRISE]: [
        { name: "۱۰۰ هزار پوش", key: 300000 },
        { name: "۱۵۰ هزار پوش", key: 450000 },
        { name: "۲۰۰ هزار پوش", key: 600000 },
        { name: "۲۵۰ هزار پوش", key: 750000 },
        { name: "۳۰۰ هزار پوش", key: 900000 },
        { name: "۵۰۰ هزار پوش", key: 1500000 },
        { name: "۶۰۰ هزار پوش", key: 1800000 },
        { name: "۷۰۰ هزار پوش", key: 2100000 },
        { name: "۸۰۰ هزار پوش", key: 2400000 },
        { name: "۹۰۰ هزار پوش", key: 2700000 },
        { name: "۱ میلیون پوش", key: 3000000 },
        { name: "۲ میلیون پوش", key: 6000000 },
        { name: "۳ میلیون پوش", key: 9000000 },
        { name: "۵ میلیون پوش", key: 15000000 },
        { name: "۱۰ میلیون پوش", key: 30000000 },
        { name: "۲۰ میلیون پوش", key: 60000000 },
    ],
};

export const sendRangeOptions = [
    { name: "ارسال آنی", key: 0 },
    { name: "یک ساعت", key: 1 },
    { name: "دو ساعت", key: 2 },
    { name: "سه ساعت", key: 3 },
    { name: "چهار ساعت", key: 4 },
    { name: "پنج ساعت", key: 5 },
    { name: "شش ساعت", key: 6 },
    { name: "هفت ساعت", key: 7 },
    { name: "هشت ساعت", key: 8 },
    { name: "نه ساعت", key: 9 },
    { name: "ده ساعت", key: 10 },
    { name: "یازده ساعت", key: 11 },
    { name: "دوازده ساعت", key: 12 },
    { name: "۲۴ ساعت", key: 24 },
];

export const priorities = [
    { name: "پایین", key: 1 },
    { name: "متوسط", key: 2 },
    { name: "بالا", key: 3 },
];

export const collapseKeyOptions = [
    { name: "بدون کلید جایگزینی", key: "" },
    { name: "کلید جایگزینی-۱", key: "key1" },
    { name: "کلید جایگزینی-۲", key: "key2" },
    { name: "کلید جایگزینی-۳", key: "key3" },
    { name: "کلید جایگزینی-۴", key: "key4" },
];

export const tagOptions = [
    { name: "بدون تگ", key: "" },
    { name: "تگ-۱", key: "tag1" },
    { name: "تگ-۲", key: "tag1" },
];

export const dirOptions = [
    { name: "بصورت خودکار", key: "" },
    { name: "از راست به چپ", key: "rtl" },
    { name: "از چپ به راست", key: "ltr" },
];

// const soundUrl = 'http://cdn.pushe.co/sounds/';
const soundUrl = "https://static.pushe.co/mp3/";

export const soundOptions = [
    { key: `${soundUrl}1.mp3` },
    { key: `${soundUrl}2.mp3` },
    { key: `${soundUrl}3.mp3` },
    { key: `${soundUrl}4.mp3` },
    { key: `${soundUrl}5.mp3` },
    { key: `${soundUrl}6.mp3` },
    { key: `${soundUrl}7.mp3` },
    { key: "" },
];

export const transferKeysIntoPersion = {
    buttons: "دکمه‌ها:",
    wrapper_id: "شناسه نوتیفیکیشن:",
    action: "عملکرد:",
    notif_channel_id: "کانال:",
    custom_content: "جیسون:",
    rate_limit: "نرخ ارسال:",
    time_to_live: "زمان زنده ماندن:",
    close_on_click: "بستن نوتیفیکیشن با کلیک:",
    priority: "اولویت ارسال:",
    unique: "ارسال به کاربران یکتا:",
    data_type: "نوع داده:",
    topics: "تاپیک:",
    filters: "فیلترها:",
    max_recipients: "بیشترین تعداد دریافت کننده:",
    collapse_key: "کلید جایگزینی:",
    sound_url: "صدای نوتیفیکیشن:",
    ticker: "متن نوار نوتیفیکیشن:",
    wake_screen: "روشن شدن صفحه:",
    led_color: "رنگ LED:",
    delay_until: "زمان نمایش نوتیفیکیشن:",
    notif_icon: "آیکون نوار نوتیفیکیشن:",
    operator: "اپراتور:",
    brand: "برند:",
    mobile_net: "اتصال به اینترنت:",
    state: "استان:",
    app_version: "نسخه برنامه",
    device_id: "شناسه دستگاه",
    imei: "شناسه imei",
    p_id: "شناسه",
    events: "رویدادها",
    visits: "بازدیدها",
    custom_id: "شناسه سفارشی",
    email: "ایمیل",
    phone_number: "شماره تلفن",
    android_id: "شناسه اندروید",
    ad_id: "شناسه تبلیغات گوگل",
    tags: "تگ‌ها",
    segment_names: "دسته‌بندی‌ها:",
    eta: "زمان بندی ارسال",
    scheduled_time: "زمان بندی نمایش",
};

export const collapseKeyList = {
    key1: "کلید جایگزینی-۱",
    key2: "کلید جایگزینی-۲",
    key3: "کلید جایگزینی-۳",
    key4: "کلید جایگزینی-۴",
};

export const ledOptions = [
    { id: 1, value: "#FF0000", code: "-65536" },
    { id: 2, value: "#CDDC00", code: "-3286016" },
    { id: 3, value: "#0000FF", code: "-16776961" },
    { id: 4, value: "#FFC800", code: "-14336" },
    { id: 5, value: "#00FF00", code: "-16711936" },
    { id: 6, value: "#FF9800", code: "-26624" },
    { id: 7, value: "#FFFF00", code: "-256" },
    { id: 8, value: "#82C800", code: "-8206336" },
    { id: 9, value: "#FF5700", code: "-43264" },
    { id: 10, value: "#00FFFF", code: "-16711681" },
    { id: 11, value: "#FF0064", code: "-65436" },
    { id: 12, value: "#AA00FF", code: "-5635841" },
];

export const vibrationOptions = [
    { key: sound1, name: "A.mp3" },
    { key: sound2, name: "B.mp3" },
    { key: sound3, name: "C.mp3" },
    { key: sound4, name: "D.mp3" },
    { key: sound5, name: "E.mp3" },
];

export const LANG = {
    EN: "Eng",
    FA: "فارسی",
};
export const BROWSER = {
    CHROME: "chrome",
    FIREFOX: "firefox",
    SAFARI: "safari",
};
export const OS = {
    WINDOWS: "Windows",
    IOS: "iOS",
    LINUX: "Linux",
};

export const CRED_STATUS = {
    CORRECT: "CORRECT",
    FAIL: "FAIL",
    UNEDITABLE: "UNEDITABLE",
};

export const NOTIFICATION_STATUS_TYPES = {
    all: {
        id: 0,
        title: "همه نوتیفیکیشن‌ها",
        icon: "svg_all_items",
        isDivide: true,
        key: "all",
    },
    scheduled: {
        id: 1,
        color: "#FAA652",
        title: "زمان‌بندی شده",
        icon: "svg_hourglass",
        key: "is_scheduled",
    },
    sent: {
        id: 2,
        color: "#4BB599",
        title: "ارسال شده",
        icon: "svg_sent",
        key: "is_sent",
    },
    nonTest: {
        id: 3,
        color: "#666666",
        title: "غیر تستی",
        icon: "svg_non_test",
        isDivide: true,
        key: "non_test",
    },

    test: {
        id: 4,
        color: "#666666",
        title: "تستی",
        icon: "svg_nontest2",
        key: "sample.jsx",
    },

    sending: {
        id: 5,
        color: "#b9a828",
        title: "در حال ارسال",
        icon: "sending",
        key: "isSending",
    },
    notSent: {
        id: 6,
        color: "#e85d4a",
        title: "ارسال نشده",
        icon: "svg_not_sent",
        isDivide: true,
        key: "not_sent",
    },
    custom_content: {
        id: 7,
        color: "#66B44E",
        title: "جیسون",
        icon: "svg_json",
        isDivide: true,
        key: "is_json",
    },
    draft: {
        id: 8,
        color: "#F5BA15",
        title: "پیش‌نویش",
        icon: "svg_draft",
        isDivide: true,
        key: "is_draft",
    },
};

export const iconList = [
    { id: 0, title: "add_box" },
    { id: 1, title: "alarm" },
    { id: 2, title: "announcement" },
    { id: 3, title: "apps" },
    { id: 4, title: "attach_money" },
    { id: 5, title: "audiotrack" },
    { id: 6, title: "build" },
    { id: 7, title: "business" },
    { id: 8, title: "call" },
    { id: 9, title: "cancel" },
    { id: 10, title: "card_giftcard" },
    { id: 11, title: "chat" },
    { id: 12, title: "check" },
    { id: 13, title: "check_box" },
    { id: 14, title: "close" },
    { id: 15, title: "delete" },
    { id: 16, title: "edit" },
    { id: 17, title: "email" },
    { id: 18, title: "event" },
    { id: 19, title: "forward" },
    { id: 20, title: "group" },
    { id: 21, title: "help" },
    { id: 22, title: "highlight_off" },
    { id: 23, title: "history" },
    { id: 24, title: "home" },
    { id: 25, title: "image" },
    { id: 26, title: "info" },
    { id: 27, title: "exit_to_app" },
    { id: 28, title: "favorite" },
    { id: 29, title: "file_download" },
    { id: 30, title: "file_upload" },
    { id: 31, title: "flag" },
    { id: 32, title: "notifications" },
    { id: 33, title: "open_in_browser" },
    { id: 34, title: "pause_circle_filled" },
    { id: 35, title: "payment" },
    { id: 36, title: "person" },
    { id: 37, title: "phone_android" },
    { id: 38, title: "photo_camera" },
    { id: 39, title: "place" },
    { id: 40, title: "language" },
    { id: 41, title: "local_cafe" },
    { id: 42, title: "local_dining" },
    { id: 43, title: "local_offer" },
    { id: 44, title: "loyalty" },
    { id: 45, title: "map" },
    { id: 46, title: "mood_bad" },
    { id: 47, title: "mood" },
    { id: 48, title: "airplane" },
];

export const STORAGE = {
    LOCAL_STORAGE_TRUE: "true",

    ONBOARDING: {
        ANDROID: {
            DASHBOARD: "a-d-onboarding",
        },
    },

    WELCOME: {
        TRIAL_MODE_MODAL: "trial-mode-modal",
    },
};

export const PLATFORM_CODE_ANDROID = 1;
export const PLATFORM_CODE_WEB = 2;
export const PLATFORM_CODE_IOS = 3;

export const PLATFORM_NAME_BY_CODE = {
    [PLATFORM_CODE_ANDROID]: "android",
    [PLATFORM_CODE_WEB]: "web",
    [PLATFORM_CODE_IOS]: "ios",
};

export const TOAST_ICON_CLASSES = {
    SUCCESS: "toast-icon toast-icon--success",
    ERROR: "toast-icon toast-icon--fail",
    WARN: "toast-icon toast-icon--warning",
};

export const NATIONAL_CARD_STATUS = {
    1: "تصویر کارت ملی آپلود شده قابل شناسایی نمی باشد ، لطفا منتظر بمانید تا توسط کارشناس تایید شود",
    2: "تصویر آپلود شده به عنوان کارت ملی شناخته نشده ، لطفا تصویر کارت ملی صحیح آپلود کنید",
    3: "وضعیت کارت ملی توسط سیستم قابل شناسایی نمی باشد ، منتظر بمانید تا توسط کارشناس تایید شود",
    4: "تصویر کارت ملی آپلود شده با اطلاعات کاربری‌تان مطابقت دارد",
    5: "وضعیت شماره ملی وارد شده توسط سیستم قابل شناسایی نمی باشد ، منتظر بمانید تا توسط کارشناس تایید شود",
    6: "شماره ملی وارد شده مطابقت ندارد",
};

export const NATIONAL_CARD_NO_PIC_MESSAGE =
    "تصویر کارت ملی خود را آپلود نکرده اید";
export const FIRST_DAY_EMPTY_CHART_MESSAGE =
    "نمودارها یک روز بعد از دریافت اولین نصب از اپلیکیشن شما و هر ۲۴ ساعت یک بار به روز رسانی می شوند";
export const All_INSTALL_HELPER = `
                                نصب دردسترس: تعداد نصب هایی که در یک ماه اخیر با سرور پوشه در ارتباط بوده اند.

                                کاربر دردسترس: تعداد گوشی هایی که حداقل یکی از اپلیکیشن‌های شما را دارا هستند و در یک ماه اخیر با سرورهای پوشه ارتباط داشته اند.
                            `;

export const get_national_card_status_message = statusNumber => {
    if (!!statusNumber && NATIONAL_CARD_STATUS[statusNumber])
        return NATIONAL_CARD_STATUS[statusNumber];
    return undefined;
};

export const isNationCardValid = (statusNumber, nationalCardUrl) => {
    if (!nationalCardUrl) return false;
    return statusNumber === 4;
};

export const PUSHE_HOME_URL = "https://pushe.co";

export const STATIC_PUSHE_URL = "https://static.pushe.co";

export const PROMPT_TYPE = {
    DEFAULT: "default",
    PUSHE: "pushe",
    OVERLAY: "overlay",
};

export const PLATFORMS = {
    ANDROID: "android",
    WEB: "web",
    IOS: "ios",
};

export const PLATFORM_NUMS = {
    android: PLATFORM_CODE_ANDROID,
    ios: PLATFORM_CODE_IOS,
    web: PLATFORM_CODE_WEB,
};
