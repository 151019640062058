import React from "react";
import classname from "classnames";

export const PTableItem = props => {
    const {
        children,
        mobileChildren,
        columns,
        expand,
        isDesktop,
        mobileTitle,
        className,
        classNames,
    } = props;
    const MAX_STR_LENGTH = 20;

    let style = {};
    if (columns) {
        const columnSize = 100 / columns;
        const width = expand ? columnSize * expand : columnSize;
        style = { width: `${width}%` };
    }

    const dataTip = children && typeof children === "string" ? children : "";
    let trimmedChildren = "";
    if (isDesktop && children && typeof children === "string") {
        if (children.length > MAX_STR_LENGTH) {
            trimmedChildren = children.slice(0, MAX_STR_LENGTH).concat(" ...");
        } else {
            trimmedChildren = children.slice(0, MAX_STR_LENGTH);
        }
    } else {
        trimmedChildren = children;
    }

    return (
        <div
            data-tip={dataTip}
            className={classname("PTableItem", className, classNames)}
            style={style}
            {...props}
        >
            {!isDesktop && mobileTitle}{" "}
            {(!isDesktop && mobileChildren) || trimmedChildren}
        </div>
    );
};
