import React, { useState } from "react";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import { getFormValues, reduxForm } from "redux-form";
import { useReduxForm } from "@/components/utilities/hooks/useReduxForm";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { normalize } from "@/components/utils/normalize";

const _WizardStepFinale = props => {
    const {
        estimateMutationFunc,
        estimateNormalizer = {},
        messageTypeName = "پیام",
    } = props?.passToSteps ?? {};
    const [count, setCount] = useState(-1);

    const { form } = useReduxForm();
    const formValues = useSelector(state => getFormValues(form)(state) ?? {});

    const [mutate, { isLoading }] = useMutation(estimateMutationFunc, {
        onSuccess: count => setCount(count),
    });

    const estimateClickHandler = async () => {
        const normalizedValues = normalize(formValues, estimateNormalizer);
        await mutate(normalizedValues);
    };

    return (
        <form className="notif-finale">
            <div className="notif-block">
                <p>
                    آیا از ارسال این {messageTypeName} به کاربرانتان اطمینان
                    دارید؟
                </p>

                <div className="card">
                    {!isLoading && (
                        <svg className="card-icon">
                            <use xlinkHref="#svg_audience" />
                        </svg>
                    )}

                    {!isLoading && (
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--blue mui-btn--solid"
                            rippleColor={blue900}
                            label="تخمین تعداد مخاطب"
                            onClick={estimateClickHandler}
                        />
                    )}

                    {count > -1 && (
                        <p className="card-text text-fa">{` حدودا:${count}`}</p>
                    )}

                    {isLoading && (
                        <CircleLoader
                            size={40}
                            style={{ margin: "20px auto 0" }}
                        />
                    )}
                </div>
            </div>
        </form>
    );
};

const WizardStepFinale = props => {
    const { formName } = props;

    const Wrapper = reduxForm({
        form: formName,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })(_WizardStepFinale);

    return <Wrapper {...props} />;
};

export default withErrorCatcher(WizardStepFinale);
