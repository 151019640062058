import React, { useState } from "react";
import { toFarsiNumbers, withErrorCatcher } from "@/components/utilities";
import {
    PTable,
    PTableBody,
    PTableHeader,
    PTableItem,
    PTableRow,
} from "@/components/utilities/modules/PTable";
import Util from "@/utils";
import Toggle from "material-ui/Toggle";
import IconButton from "material-ui/IconButton";
import { Pencil, Trash } from "@/components/utils/lib/svgicons";
import DeleteModal from "@/components/utilities/commons/DeleteModal";

export const AutomationBody = props => {
    const {
        updateAutomation,
        history,
        deleteAutomation,
        automationItems,
        platform,
    } = props;

    const [showDeleteModal, toggleDeleteModal] = useState(null);

    return (
        <>
            <PTable columns={7} mobileSizeMax={"1199px"}>
                <PTableHeader>
                    <PTableItem expand={2}>نام</PTableItem>
                    <PTableItem expand={2}>نام اپلیکیشن</PTableItem>
                    <PTableItem expand={2}>تاریخ ایجاد</PTableItem>
                    <PTableItem>تعداد ارسال</PTableItem>
                    <PTableItem>فعال/غیرفعال</PTableItem>
                    <PTableItem>ویرایش</PTableItem>
                    <PTableItem>حذف</PTableItem>
                </PTableHeader>
                <PTableBody>
                    {automationItems.map(automation => {
                        const {
                            automation_id,
                            name,
                            created_at,
                            is_enabled,
                            is_freezed,
                            app_name,
                            recipient_count,
                        } = automation;

                        return (
                            <PTableRow key={automation_id}>
                                <PTableItem expand={2} inMobileHeader>
                                    {name}
                                </PTableItem>
                                <PTableItem
                                    expand={2}
                                    mobileTitle={"نام اپلیکیشن: "}
                                >
                                    {app_name}
                                </PTableItem>
                                <PTableItem expand={2} inMobileHeader>
                                    {Util._convertToLocalTimezone(created_at)}
                                </PTableItem>
                                <PTableItem mobileTitle={"تعداد ارسال: "}>
                                    {toFarsiNumbers(recipient_count || 0)}
                                </PTableItem>
                                <PTableItem
                                    inMobileFooter
                                    mobileTitle={"فعال/غیرفعال: "}
                                >
                                    <Toggle
                                        className="toggle"
                                        style={{
                                            cursor: "pointer",
                                            zIndex: 0,
                                            width: "auto",
                                            margin: "0 10px",
                                        }}
                                        labelPosition="right"
                                        disabled={is_freezed}
                                        toggled={is_enabled}
                                        onToggle={(event, isInputChecked) => {
                                            updateAutomation({
                                                automationId: automation_id,
                                                data: {
                                                    is_enabled: isInputChecked,
                                                },
                                            });
                                        }}
                                        thumbSwitchedStyle={{
                                            backgroundColor: "#0065ff",
                                        }}
                                        trackSwitchedStyle={{
                                            backgroundColor: "#0065ff30",
                                        }}
                                    />
                                </PTableItem>
                                <PTableItem
                                    inMobileFooter
                                    mobileChildren={<></>}
                                >
                                    <div
                                        data-tip={
                                            is_freezed
                                                ? "این پیامک اتوماتیک معلق شده و امکان ویرایش آن وجود ندارد"
                                                : ""
                                        }
                                    >
                                        <IconButton
                                            className="table-btn"
                                            disabled={is_freezed}
                                            data-tip="ویرایش"
                                            onClick={() =>
                                                history.push(
                                                    `/sms/automation/${platform}/edit/${automation_id}`,
                                                )
                                            }
                                        >
                                            <Pencil viewBox="0 0 24 24" />
                                        </IconButton>
                                    </div>
                                </PTableItem>
                                <PTableItem
                                    inMobileFooter
                                    mobileChildren={
                                        <>
                                            <div
                                                data-tip={
                                                    is_freezed
                                                        ? "این پیامک اتوماتیک معلق شده و امکان ویرایش آن وجود ندارد"
                                                        : ""
                                                }
                                            >
                                                <IconButton
                                                    className="table-btn"
                                                    disabled={is_freezed}
                                                    data-tip="ویرایش"
                                                    onClick={() =>
                                                        history.push(
                                                            `/sms/automation/${platform}/edit/${automation_id}`,
                                                        )
                                                    }
                                                >
                                                    <Pencil viewBox="0 0 24 24" />
                                                </IconButton>
                                            </div>
                                            <div>
                                                <IconButton
                                                    className="table-btn"
                                                    data-tip="حذف"
                                                    onClick={() =>
                                                        toggleDeleteModal(
                                                            automation_id,
                                                        )
                                                    }
                                                >
                                                    <Trash viewBox="0 0 19 23" />
                                                </IconButton>
                                            </div>
                                        </>
                                    }
                                >
                                    <div>
                                        <IconButton
                                            className="table-btn"
                                            data-tip="حذف"
                                            onClick={() =>
                                                toggleDeleteModal(automation_id)
                                            }
                                        >
                                            <Trash viewBox="0 0 19 23" />
                                        </IconButton>
                                    </div>
                                </PTableItem>
                            </PTableRow>
                        );
                    })}
                </PTableBody>
            </PTable>

            <br />

            {!!showDeleteModal && (
                <DeleteModal
                    onAccept={() => {
                        const callback = () => toggleDeleteModal(null);
                        deleteAutomation(showDeleteModal, {
                            onSettled: callback,
                        });
                    }}
                    onClose={() => toggleDeleteModal(null)}
                    title={"حذف پیامک اتوماتیک"}
                    body={`آیا از حذف این پیامک اتوماتیک  مطمئن هستید؟`}
                />
            )}
        </>
    );
};

export default withErrorCatcher(AutomationBody);
