import React from "react";
import { withErrorCatcher } from "@/components/utilities";
import { connect } from "react-redux";
import classNames from "classnames";
import { getFormValues } from "redux-form";
import mockupIOS from "images/notification-preview/mockup-ios.png";
import avatar from "images/app-icon/app2.svg";
import Toggle from "material-ui/Toggle";
import uuid from "uuid/v4";

class IosNotifPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isBig: false,
        };
    }

    static checkTextDirection(text) {
        if (typeof text === "undefined" || !text.length) return;
        return /[a-z]|[A-Z]/.test(text[0]); // return ltr true
    }

    renderIosBody() {
        const { isBig } = this.state;
        const {
            values: { data = {} },
        } = this.props;
        const { title, subtitle, content, image, buttons } = data;

        return (
            <>
                <div className="preview-body__main">
                    <div
                        className={classNames("preview-body__header", {
                            isBig: isBig,
                        })}
                    >
                        <div className="preview-body__header-right">
                            {isBig ? (
                                <svg>
                                    <use href="#svg_ellipsis" />
                                </svg>
                            ) : (
                                <time>now</time>
                            )}
                        </div>
                        <div className="preview-body__header-left">
                            <div
                                className="preview-body__header-icon"
                                style={{ backgroundImage: `url(${avatar})` }}
                            />
                            <div className="preview-body__header-appName">
                                PUSHE
                            </div>
                        </div>
                    </div>
                    {isBig && !!image && (
                        <div
                            className="preview-body__img"
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    )}
                    <div className="preview-body__content">
                        <div className="preview-body__text">
                            <div
                                className={classNames("preview-body__right", {
                                    "no-image": isBig || !image,
                                })}
                            >
                                <div className="preview-body__title">
                                    {title || "تیتر"}
                                </div>
                                <div className="preview-body__subtitle">
                                    {subtitle}
                                </div>
                                <div
                                    className={classNames(
                                        "preview-body__desc",
                                        {
                                            ltr: IosNotifPreview.checkTextDirection(
                                                content,
                                            ),
                                        },
                                    )}
                                >
                                    {content || "متن نوتیفیکیشن"}
                                </div>
                            </div>
                            {!isBig && !!image && (
                                <div className="preview-body__smallImage">
                                    <img src={image} alt="" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {isBig && (
                    <div className="preview-body__buttons">
                        {(buttons || []).map(button => {
                            const len = buttons.length;
                            return (
                                <div
                                    className={classNames(
                                        "preview-body__button",
                                        {
                                            single: len === 1,
                                        },
                                    )}
                                    key={uuid()}
                                >
                                    {button.btn_content}
                                </div>
                            );
                        })}
                    </div>
                )}
            </>
        );
    }

    render() {
        const { isBig } = this.state;
        const {
            values: { data = {} },
        } = this.props;

        const d = new Date();
        const timeStr = (() => {
            let hour = d.getHours();
            let minute = d.getMinutes();

            if (hour < 10) {
                hour = `0${hour}`;
            }
            if (minute < 10) {
                minute = `0${minute}`;
            }

            return `${hour}:${minute}`;
        })();
        const dateStr = /(.*)\s/.exec(d.toDateString())[1].replace(/\s/i, ", ");

        const styles = {
            toggleButton: {
                display: "inline-block",
                verticalAlign: "middle",
                width: "60px",
            },
            thumb: {
                backgroundColor: "#0065ff",
            },
            track: {
                backgroundColor: "#0065ff30",
            },
        };

        return (
            <div
                className={classNames("preview", "preview--ios", {
                    "preview--big":
                        isBig && data && (data.big_content || data.big_image),
                })}
            >
                <div
                    className="preview-phone"
                    style={{ backgroundImage: `url(${mockupIOS})` }}
                >
                    {/*<div className="preview-phone__header">*/}
                    {/*    <time>{timeStr}</time>*/}

                    {/*    {data && data.led_color &&*/}
                    {/*    <span className="active" style={{'backgroundColor': data.led_color}}/>*/}
                    {/*    }*/}
                    {/*</div>*/}

                    <div className="preview-phone__date">
                        <time>{timeStr}</time>
                        <span>{dateStr}</span>
                    </div>
                    <div className="preview-phone__body">
                        <div className="preview-body">
                            {this.renderIosBody()}
                        </div>
                    </div>
                </div>

                <div className="preview-toggle">
                    <button
                        className={classNames("preview-toggle__btn", {
                            active: !isBig,
                        })}
                        onClick={() => this.setState({ isBig: false })}
                        data-tip="وقتی کاربر نوتیفیکیشن را دریافت می‌کند، حالت کوچک نوتیفیکیشن را خواهد دید"
                    >
                        حالت کوچک
                    </button>
                    <Toggle
                        label=""
                        className="preview-toggle__input"
                        onToggle={() => this.setState({ isBig: !isBig })}
                        style={styles.toggleButton}
                        thumbStyle={styles.thumb}
                        trackStyle={styles.track}
                        trackSwitchedStyle={styles.track}
                        thumbSwitchedStyle={styles.thumb}
                    />
                    <button
                        className={classNames("preview-toggle__btn", {
                            active: isBig,
                        })}
                        onClick={() => this.setState({ isBig: true })}
                        data-tip="وقتی کاربر لیست نوتیفیکیشن‌ها را پایین بکشد، حالت بزرگ نوتیفیکیشن را خواهد دید"
                    >
                        حالت بزرگ
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        values: getFormValues("notification_wizard")(state),
    };
};

export default withErrorCatcher(
    connect(mapStateToProps, null)(IosNotifPreview),
);
