import React from "react";
import PropTypes from "prop-types";
import { withErrorCatcher } from "@/components/utilities";
import IconButton from "application/components/tables/mobile-table";
import { Flag } from "@/components/utils/lib/svgicons";
import app_blank from "images/app-icon/app-blank.png";

const AppName = ({
    app: { name, icon_url, aggregator, package_name, app_id },
    isSmallSize,
    colSize,
}) => {
    const classes = !isSmallSize
        ? `table-col table-col--${colSize} table-col--right`
        : "small-appName";

    return (
        <div className={classes}>
            <div className="card-table" style={{ padding: "0 12px" }}>
                {aggregator && (
                    <IconButton
                        className="table-btn card-table__flag active"
                        data-tip="نشان شده"
                        tooltipPosition="top-center"
                    >
                        <Flag viewBox="0 0 19 19" />
                    </IconButton>
                )}
                <img src={icon_url || app_blank} alt="" />
                <div className="card-table__text">
                    <span>{name}</span>
                    <p>{package_name}</p>
                    <p>
                        <span style={{ color: "#ccc", fontSize: ".9rem" }}>
                            app_id:
                        </span>{" "}
                        {app_id}
                    </p>
                </div>
            </div>
        </div>
    );
};

AppName.propTypes = {
    app: PropTypes.object.isRequired,
    isSmallSize: PropTypes.bool,
    colSize: PropTypes.number,
};

AppName.defaultProps = {
    app: {},
    colSize: 20,
};

export default withErrorCatcher(AppName);
