import React, { useState } from "react";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";
import Modal from "@/components/utilities/commons/modal";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import SegmentsFieldsWrapper from "@/components/users/segmentation/components/SegmentsFieldsWrapper";
import { TextField } from "redux-form-material-ui";
import { selectOptionBuilderFromArray } from "@/components/utils/finals/selectOptionBuilder";
import PageFilterSelect from "@/components/utils/finals/PageFilterSelect";
import {
    createSegmentation,
    updateSegmentation,
} from "@/actions/segmentations";
import { bindActionCreators } from "redux";
import { normalize } from "@/components/utils/normalize";
import { segmentationToApiNormalizer } from "@/components/users/segmentation/normalize";
import {
    arrayValidator,
    createValidator,
    isRequired,
    isRequiredList,
} from "@/components/utils/validation";
import { SEG_KEYS } from "@/components/users/segmentation/constants";

const SegmentsModal = props => {
    const {
        onClose,
        onSave,
        applications,
        createSegmentation,
        isReadonly,
        handleSubmit,
        buttonLabel = "ذخیره دسته‌بندی",
    } = props;
    const [inprogress, toggleInprogress] = useState(false);

    return (
        <Modal
            className="modal--md modal--edit-user segments__modal"
            onModalClose={onClose}
        >
            <div className="modal-body">
                <form>
                    <h3 className="segments__modal-header">
                        دسته‌بندی کاربران
                    </h3>
                    <div className="row segments__top-wrapper">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <Field
                                name="name"
                                component={TextField}
                                fullWidth={true}
                                placeholder="نام دسته‌بندی*"
                                disabled={isReadonly}
                            />
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <Field
                                name="app_id"
                                component={({
                                    input: { onChange, value },
                                    meta: { touched, error },
                                }) => {
                                    const valueArray =
                                        !!value && !Array.isArray(value)
                                            ? [value]
                                            : [...value];

                                    return (
                                        <>
                                            <PageFilterSelect
                                                placeholder="انتخاب اپلیکیشن*"
                                                isSearchable={true}
                                                isClearable={true}
                                                renderFormTag={false}
                                                options={selectOptionBuilderFromArray(
                                                    applications,
                                                    "name",
                                                    "app_id",
                                                )}
                                                onSelect={val => onChange(val)}
                                                value={
                                                    valueArray.map(appId => {
                                                        const app =
                                                            applications.find(
                                                                app =>
                                                                    app.app_id ===
                                                                    appId,
                                                            );
                                                        let appName;
                                                        if (app) {
                                                            appName = app.name;
                                                        }
                                                        return {
                                                            label:
                                                                appName ||
                                                                appId,
                                                            value: appId,
                                                        };
                                                    })[0]
                                                }
                                                onClear={() => {
                                                    onChange("");
                                                }}
                                                readOnly={isReadonly}
                                                isDisabled={isReadonly}
                                            />
                                            {touched && (
                                                <div
                                                    style={{
                                                        fontSize: ".75rem",
                                                        color: "#f44538",
                                                    }}
                                                >
                                                    {error}
                                                </div>
                                            )}
                                        </>
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <FieldArray
                        name="expression"
                        component={SegmentsFieldsWrapper}
                        isReadonly={isReadonly}
                    />
                </form>
            </div>

            <div className="notif-footer notif-footer--edit-user">
                <div className="notif-footer__wrap">
                    <div className="notif-footer__main">
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            disabled={inprogress}
                            label={inprogress ? <CircleLoader /> : buttonLabel}
                            onClick={handleSubmit(formData => {
                                const normalizedFormData = normalize(
                                    formData,
                                    segmentationToApiNormalizer,
                                );

                                toggleInprogress(true);
                                const onSuccess = () => {
                                    onSave();
                                    toggleInprogress(false);
                                };
                                const onFailure = () => toggleInprogress(false);

                                if (!isReadonly) {
                                    createSegmentation(
                                        normalizedFormData,
                                        onSuccess,
                                        onFailure,
                                    );
                                } else {
                                    onSuccess();
                                }
                            })}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = state => {
    const formData = formValueSelector("segments")(
        state,
        "segment",
        "name",
        "app_id",
        "segment_id",
    );
    const { platform } = state.app;
    const { applications } = state[platform];

    return {
        formData,
        applications,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            createSegmentation,
            updateSegmentation,
        },
        dispatch,
    );
};

const validators = createValidator({
    name: isRequired,
    app_id: isRequired,
    expression: arrayValidator({
        segments: arrayValidator(sg => {
            if (
                sg.iid === SEG_KEYS.SESSION_USER_LIFE.ID ||
                sg.iid === SEG_KEYS.SESSION_RELATIVE_CREATED_AT.ID ||
                sg.iid === SEG_KEYS.SESSION_UPDATED_AT.ID ||
                sg.iid === SEG_KEYS.SESSION_COUNT
            ) {
                return {
                    value: isRequired(sg.value),
                    op: isRequired(sg.op),
                };
            } else if (sg.iid === SEG_KEYS.EVENTS.ID) {
                return {
                    name: isRequired(sg.name),
                    op: isRequired(sg.op),
                    type: isRequired(sg.type),
                    value: isRequired(sg.value),
                };
            } else if (sg.iid === SEG_KEYS.APP_VERSION.ID) {
                return {
                    value: isRequiredList(sg.value),
                };
            } else if (sg.iid === SEG_KEYS.SESSION_COUNT.ID) {
                return {
                    op: isRequired(sg.op),
                    value: isRequired(sg.value),
                };
            } else if (sg.iid === SEG_KEYS?.TAG?.ID) {
                return {
                    value: isRequiredList(sg.value),
                    vKey: isRequired(sg.vKey),
                    op: isRequired(sg.op),
                };
            } else if (sg.iid === SEG_KEYS.STATE.ID) {
                return {
                    value: isRequiredList(sg.value),
                };
            }
        }),
    }),
});

const SegmentsModalForm = reduxForm({
    form: "segments",
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate: validators,
})(SegmentsModal);

export default withErrorCatcher(
    connect(mapStateToProps, mapDispatchToProps)(SegmentsModalForm),
);
