import React, { useState } from "react";
import { toFarsiNumbers, withErrorCatcher } from "@/components/utilities";
import {
    PTable,
    PTableBody,
    PTableHeader,
    PTableItem,
    PTableRow,
} from "@/components/utilities/modules/PTable";
import Util from "@/utils";
import Toggle from "material-ui/Toggle";
import IconButton from "material-ui/IconButton";
import { Pencil, Trash } from "@/components/utils/lib/svgicons";
import DeleteGeofenceModal from "@/components/notifications/geofence/list/DeleteGeofenceModal";

const GeofenceBody = props => {
    const { geofenceList, toggleEnableGeofence, deleteGeofence, history } =
        props;

    const [showDeleteModal, toggleDeleteModal] = useState(false);

    return (
        <>
            <PTable columns={8} mobileSizeMax={"1199px"}>
                <PTableHeader>
                    <PTableItem expand={2}>نام</PTableItem>
                    <PTableItem expand={2}>نام اپلیکیشن</PTableItem>
                    <PTableItem expand={2}>تاریخ ایجاد</PTableItem>
                    <PTableItem>تعداد ارسال</PTableItem>
                    <PTableItem>تعداد کلیک</PTableItem>
                    <PTableItem>فعال/غیرفعال</PTableItem>
                    <PTableItem>ویرایش</PTableItem>
                    <PTableItem>حذف</PTableItem>
                </PTableHeader>
                <PTableBody>
                    {geofenceList.map(geofence => {
                        const {
                            geofence_id,
                            name,
                            created_at,
                            is_enabled,
                            app_name,
                            published,
                            clicked,
                        } = geofence;

                        return (
                            <PTableRow key={geofence_id}>
                                <PTableItem expand={2} inMobileHeader>
                                    {name}
                                </PTableItem>
                                <PTableItem expand={2}>{app_name}</PTableItem>
                                <PTableItem expand={2} inMobileHeader>
                                    {Util._convertToLocalTimezone(created_at)}
                                </PTableItem>
                                <PTableItem mobileTitle={"تعداد ارسال: "}>
                                    {toFarsiNumbers(published || 0)}
                                </PTableItem>
                                <PTableItem mobileTitle={"تعداد کلیک: "}>
                                    {toFarsiNumbers(clicked || 0)}
                                </PTableItem>
                                <PTableItem
                                    inMobileFooter
                                    mobileTitle={"فعال/غیرفعال: "}
                                >
                                    <Toggle
                                        className="toggle"
                                        style={{
                                            cursor: "pointer",
                                            zIndex: 0,
                                            margin: "0 5px",
                                            width: "auto",
                                        }}
                                        labelPosition="right"
                                        toggled={is_enabled}
                                        onToggle={(event, isInputChecked) => {
                                            toggleEnableGeofence(geofence_id, {
                                                is_enabled: isInputChecked,
                                            });
                                        }}
                                        thumbSwitchedStyle={{
                                            backgroundColor: "#0065ff",
                                        }}
                                        trackSwitchedStyle={{
                                            backgroundColor: "#0065ff30",
                                        }}
                                    />
                                </PTableItem>
                                <PTableItem
                                    inMobileFooter
                                    mobileChildren={<></>}
                                >
                                    <div>
                                        <IconButton
                                            className="table-btn"
                                            data-tip="ویرایش"
                                            onClick={() =>
                                                history.push(
                                                    `/notification/geofence/android/edit/${geofence_id}`,
                                                )
                                            }
                                        >
                                            <Pencil viewBox="0 0 24 24" />
                                        </IconButton>
                                    </div>
                                </PTableItem>
                                <PTableItem
                                    inMobileFooter
                                    mobileChildren={
                                        <>
                                            <div>
                                                <IconButton
                                                    className="table-btn"
                                                    data-tip="ویرایش"
                                                    onClick={() =>
                                                        history.push(
                                                            `/notification/geofence/android/edit/${geofence_id}`,
                                                        )
                                                    }
                                                >
                                                    <Pencil viewBox="0 0 24 24" />
                                                </IconButton>
                                            </div>
                                            <div>
                                                <IconButton
                                                    className="table-btn"
                                                    data-tip="حذف"
                                                    onClick={() =>
                                                        toggleDeleteModal(
                                                            geofence_id,
                                                        )
                                                    }
                                                >
                                                    <Trash viewBox="0 0 19 23" />
                                                </IconButton>
                                            </div>
                                        </>
                                    }
                                >
                                    <div>
                                        <IconButton
                                            className="table-btn"
                                            data-tip="حذف"
                                            onClick={() =>
                                                toggleDeleteModal(geofence_id)
                                            }
                                        >
                                            <Trash viewBox="0 0 19 23" />
                                        </IconButton>
                                    </div>
                                </PTableItem>
                            </PTableRow>
                        );
                    })}
                </PTableBody>
            </PTable>

            {showDeleteModal && (
                <DeleteGeofenceModal
                    onAccept={() => {
                        const callback = () => toggleDeleteModal(null);
                        deleteGeofence(showDeleteModal, callback, callback);
                    }}
                    onClose={() => toggleDeleteModal(null)}
                    name={name}
                />
            )}
        </>
    );
};

export default withErrorCatcher(GeofenceBody);
