import React from "react";
import WayPoint from "react-waypoint";
import { CircleLoader, withErrorCatcher } from "@/components/utilities";

const CardListWrapper = props => {
    const { children, onEnter, isLoading, hasMore } = props;

    return (
        <div className="card-list__wrapper">
            <div className="card-list__inner">{children}</div>

            <WayPoint onEnter={onEnter}>
                <div>
                    {isLoading && (
                        <>
                            <div className="card-list__waypoint--loader">
                                <CircleLoader size={40} thickness={4} />
                            </div>
                        </>
                    )}

                    {!hasMore && !isLoading && (
                        <div className="card-list__waypoint--no-data">
                            <p>اطلاعات جدیدی وجود ندارد</p>
                        </div>
                    )}

                    <div className="card-list__waypoint-bottom" />
                </div>
            </WayPoint>
        </div>
    );
};

export default withErrorCatcher(CardListWrapper);
