import React from "react";
import Modal from "@/components/utilities/commons/modal";
import ProfileTabs from "@/components/app/profile/ProfileTabs";
import { withRouter } from "react-router-dom";
import { PREFERRED_PLATFORM } from "@/constants";

const Profile = props => {
    const { history, profileTab, ...others } = props;
    const [previousLocation, _] = React.useState(
        () => others?.location?.state?.previousLocation,
    );

    return (
        <Modal
            className="modal--profile"
            onModalClose={() =>
                history.push(
                    previousLocation || `/dashboard/${PREFERRED_PLATFORM}`,
                )
            }
        >
            <div className="modal-header">
                <svg className="icon-profile">
                    <use xlinkHref="#svg_profile" />
                    account
                </svg>
                <p>حساب کاربری</p>
                <span>اطلاعات حساب کاربری شما</span>
            </div>

            <div className="profile">
                <ProfileTabs profileTab={profileTab} />
            </div>
        </Modal>
    );
};

export default withRouter(Profile);
