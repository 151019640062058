import React from "react";
import Select from "react-select";
import { Close } from "../lib/svgicons";
import classNames from "classnames";

/*
 ** A component to use in main page's filter row
 * either as input search filter or as a select box with options
 *
 * it can have bellow forms:
 *   1. Input search
 *       --> (required)hideMenu={true}, (required)placeholder, isClearable={true}, ...
 *   2. Input search with opening menu
 *       --> (required)isSearchable={true}, (required)placeholder, isClearable={true}, ...
 *   3. Just an opening menu with options
 *       --> (required)options, (required)placeholder, ...
 *
 * @prop options: Required - An array of { label: string, value: string }
 * @prop isSearchable: Boolean - if sets to true select works also as an input text
 * @prop isClearable: isClearable - if sets to true a cross icon adds to the field and can clear field with it
 * @prop placeholder: Required
 * @prop noOptionsMessage: String - A text to show in opened menu when there is no item found
 * @prop placeholderOnInputFocus: String - A text to show instead of "placeholder" when menu is
 *  open or when input is get focused
 * @prop hideMenu: Boolean - If sets to true it disables opening of the menu
 * - Note that if this is true , it sets "isSearchable" to true
 *
 * Available Callbacks:
 * @prop onSelect: Function - A callback function that returns value of the selected item as string
 * @prop onInput: Function - A callback function that returns value of the entered input
 * @prop onInput: Function - A callback function
 *
 */

class PageFilterSelect extends React.Component {
    static defaultProps = {
        isSearchable: false,
        isClearable: false,
        noOptionsMessage: "هیچ موردی پیدا نشد",
    };

    selectRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            showOnFocusPlaceholder: false,
            input: "",
        };
    }

    render() {
        const {
            options,
            isSearchable,
            isClearable,
            placeholder,
            noOptionsMessage,
            placeholderOnInputFocus,
            hideMenu,
            renderFormTag = true,
            readOnly,
            ...props
        } = this.props;

        const { showOnFocusPlaceholder, input } = this.state;

        const renders = (
            <div
                className={classNames(`select--filters__wrapper`, {
                    "is-readonly": readOnly,
                })}
            >
                <Select
                    isMulti={props.isMulti}
                    ref={this.selectRef}
                    classNamePrefix="select--filters"
                    placeholder={
                        !!placeholderOnInputFocus && showOnFocusPlaceholder
                            ? placeholderOnInputFocus
                            : placeholder
                    }
                    isSearchable={isSearchable || hideMenu}
                    isClearable={isClearable}
                    options={options}
                    noOptionsMessage={() =>
                        noOptionsMessage ||
                        PageFilterSelect.defaultProps.noOptionsMessage
                    }
                    onFocus={() =>
                        !!placeholderOnInputFocus &&
                        this.setState({ showOnFocusPlaceholder: true })
                    }
                    onBlur={() =>
                        !!placeholderOnInputFocus &&
                        this.setState({ showOnFocusPlaceholder: false })
                    }
                    onChange={this.onChange.bind(this)}
                    onInputChange={this.onInputChange.bind(this)}
                    menuIsOpen={hideMenu || readOnly ? false : undefined}
                    inputValue={input}
                    components={{ ClearIndicator }}
                    {...props}
                />
                {/* NOTE: this is for when use this component as an input */}
                {hideMenu && input && (
                    <ClearIndicator
                        clearValue={() => this.setState({ input: "" })}
                    />
                )}
            </div>
        );

        if (renderFormTag) {
            return <form onSubmit={e => e.preventDefault()}>{renders}</form>;
        } else {
            return renders;
        }
    }

    onChange(values, action) {
        const { onSelect, onClear, clearBoxAfterSelect, readOnly } = this.props;

        if (readOnly) {
            return;
        }

        let value = Array.isArray(values)
            ? values.map(v => v.value)
            : values?.value;
        const actionName = (action || {}).action;

        if (onClear && actionName === "clear") {
            onClear();
        }

        if (onSelect && value) {
            onSelect(value);
        }

        if (
            clearBoxAfterSelect &&
            this.selectRef.current &&
            actionName === "select-option"
        ) {
            this.setState(
                {
                    input: "",
                },
                () => this.selectRef.current.select.clearValue(),
            );
        }
    }

    onInputChange(value, actionMeta) {
        const { onInput, hideMenu } = this.props;
        const { input } = this.state;

        if (onInput && value) {
            onInput(value);
        }

        if (actionMeta.action === "menu-close" && input && hideMenu) {
            // This cause react-select to works as an input text
            // hideMenu prop is necessary for this
            this.setState(prevState => ({ input: prevState.input }));
        } else {
            this.setState({ input: value });
        }
    }
}

/*
 ** This is custom clear indicator component
 */
const ClearIndicator = props => {
    // eslint-disable-next-line no-unused-vars
    const { cx = "", ...otherProps } = props;
    return (
        <div {...otherProps}>
            <Close
                className="select--filters__clear-icon"
                viewBox="0 0 26 26"
                style={{ fill: "#b3b3b3" }}
                onClick={props.clearValue}
            />
        </div>
    );
};
export default PageFilterSelect;
