import React, { useEffect } from "react";
import {
    hasPermission,
    PERMISSIONS,
    withErrorCatcher,
} from "@/components/utilities";
import GenericSelectFieldComponent from "@/components/utilities/commons/wizard/components/GenericSelectFieldComponent";
import { selectOptionBuilderFromArray } from "@/components/utils/finals/selectOptionBuilder";
import { fetchSegmentation } from "@/actions/segmentations";
import { useDispatch, useSelector } from "react-redux";
import { PLATFORM_CODE_WEB } from "@/constants";

const NotificationSegmentsFilterTab = () => {
    const segmentationList = useSelector(
        state => state?.segmentations?.segmentationList ?? [],
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (hasPermission(PERMISSIONS.SEGMENTATION_LIST))
            fetchSegmentation({ platform: PLATFORM_CODE_WEB })(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GenericSelectFieldComponent
            isMulti={true}
            fieldName="segment_ids"
            placeholder="انتخاب دسته‌بندی کاربران"
            normalize={(value, preValue) => {
                if (value.length > 3) {
                    return preValue;
                }
                return value;
            }}
            options={selectOptionBuilderFromArray(
                segmentationList,
                "name",
                "segment_id",
            )}
        />
    );
};

export default withErrorCatcher(NotificationSegmentsFilterTab);
