// eslint-disable-next-line no-unused-vars
import React from "react";
import { hasPermission } from "@/components/utilities";
import { FILTER_MODE } from "@/components/notifications/notification/constants";
import { PERMISSIONS } from "@/components/utilities/permission/permissions";
import UploadWebFilterFile from "@/components/notifications/notification/create/web/parials/UploadWebFilterFile";
import NotificationSegmentsFilterTab from "@/components/notifications/notification/create/web/parials/NotificationSegmentsFilterTab";
import NotificationWebFiltersTab from "@/components/notifications/notification/create/web/parials/NotificationWebFiltersTab";

export default [
    {
        label: "فیلتر",
        value: FILTER_MODE.FILTERS,
        display: true,
        hasPermission: () => true,
        component: NotificationWebFiltersTab,
    },
    {
        label: "دسته‌بندی‌ها",
        value: FILTER_MODE.SEGMENTS,
        display: true,
        hasPermission: () => hasPermission(PERMISSIONS.SEGMENT_IDS_FILTER_TAB),
        component: NotificationSegmentsFilterTab,
    },
    {
        label: "آپلود فایل",
        value: FILTER_MODE.CSV,
        display: true,
        hasPermission: () => hasPermission(PERMISSIONS.CSV_FILTER_TAB),
        component: UploadWebFilterFile,
    },
];
