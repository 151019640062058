import { useState } from "react";
import { googleSignIn, signIn } from "@/actions/auth";
import { useDispatch } from "react-redux";

export const useSignIn = handleSubmit => {
    const dispatch = useDispatch();
    const [enabled2fa, setEnabled2fa] = useState(false);
    const [signInError, setSignInError] = useState("");
    const [inProgress, toggleInProgress] = useState(false);
    // "recaptcha" add in parameter in function handleSignIn:
    const handleSignIn = ({ email, password }) => {
        if (!email || !password) return;

        setEnabled2fa(false);
        setSignInError(false);
        toggleInProgress(true);
        // "recaptcha" add in parameter in function signIn:
        signIn(email, password, {
            onSuccess: data => {
                if (data?.["two_step_auth"]) setEnabled2fa(true);
                toggleInProgress(false);
            },
            onFailure: error => {
                setSignInError(error);
                toggleInProgress(false);
            },
        })(dispatch);
    };

    const handleGoogleSignIn = () => googleSignIn();

    const goBackFrom2FAPage = () => setEnabled2fa(false);

    return {
        inProgress,
        enabled2fa,
        signInError,
        handleSignIn: handleSubmit(handleSignIn),
        handleGoogleSignIn,
        goBackFrom2FAPage,
    };
};
