import React from "react";
import { NOTIFICATION_MODE } from "@/actions/_types";
import NotificationTiming from "@/components/notifications/notification/create/controls/NotificationTiming";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { withErrorCatcher } from "@/components/utilities";
import { useSelector } from "react-redux";
import { createValidator } from "@/components/utils/validation";
import moment from "moment";
import { FORM_ERRORS } from "@/components/utils/assertion-texts";
import { useReduxFormValues } from "@/components/utilities/hooks/useReduxFormValues";
import { hasOneSignal as checkHasOneSignal } from "@/utils";

const AndroidSMSStep3 = props => {
    const {
        passToSteps: { notificationMode },
        change,
    } = props;

    const fields = ["app_ids", "data", "eta"];
    const formValues = useReduxFormValues("android_sms_wizard", fields);

    const applications = useSelector(
        state => state?.["android"]?.applications ?? [],
    );
    const _hasOneSignal = checkHasOneSignal(formValues?.app_ids, applications);

    const isModalNormal = notificationMode === NOTIFICATION_MODE.NORMAL || true;
    return (
        <form className="notif-step4">
            <>
                {isModalNormal && (
                    <>
                        <div className="notif-block">
                            <div className="notif-block__body">
                                <header>
                                    <p>تعیین زمان ارسال و نمایش پیامک</p>
                                </header>
                                <br />
                                <NotificationTiming
                                    label={"زمان ارسال پیامک"}
                                    change={change}
                                    eta={formValues?.eta}
                                />
                            </div>
                        </div>
                    </>
                )}
            </>
        </form>
    );
};

const etaValidator = (value, key, values) => {
    let eta = values.eta;
    if (eta) {
        let momentObject = moment.utc(eta);
        let now = moment.utc();
        const minutesInTheFuture = moment
            .duration(momentObject.diff(now))
            .asMinutes();
        if (minutesInTheFuture < 15) {
            return FORM_ERRORS.NOTIFICATION_TIME_ERROR;
        }
    }
};

const scheduledTimeValidator = (value, key, values) => {
    let val = values?.data?.scheduled_time;
    if (val) {
        let momentObject = moment.utc(val);
        let now = moment.utc();
        const minutesInTheFuture = moment
            .duration(momentObject.diff(now))
            .asMinutes();
        if (minutesInTheFuture < 60 * 24) {
            return FORM_ERRORS.PUBLISH_TIME_ERROR;
        }
    }
};

const validators = createValidator({
    eta: etaValidator,
    data: {
        scheduled_time: scheduledTimeValidator,
    },
});

const AndroidSMSStep3RF = reduxForm({
    form: "android_sms_wizard",
    validate: validators,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidSMSStep3);

export default withRouter(withErrorCatcher(AndroidSMSStep3RF));
