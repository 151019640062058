import React from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import { blue900 } from "material-ui/styles/colors";
import BaseModal from "@/components/utilities/modal/baseModal";
import { withErrorCatcher } from "@/components/utilities";

class RenameApplicationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            error: null,
        };
    }

    componentDidMount() {
        this.setState({ name: this.props?.applicationName });
    }

    renderError() {
        // eslint-disable-next-line no-unused-vars
        const { error, name } = this.state;
        if (error != null && typeof error !== "string") {
            const errors = Object.entries(error.data).map(item => `${item[1]}`);
            return errors.map((key, index) => <div key={index}>{key}</div>);
        } else if (typeof error === "string") {
            return <div>{error}</div>;
        }
    }

    render() {
        const { name } = this.state;
        // eslint-disable-next-line no-unused-vars
        const { app: { app_id } = {}, onClose } = this.props;

        const callBack = () => {
            onClose();
            this.setState({ error: null });
        };

        return (
            <BaseModal
                onClose={() => this.props.onClose()}
                title="تغییر نام اپلیکیشن"
                desc="برای اپلیکیشن خود یک نام جدید انتخاب کنید."
                body={
                    <div>
                        <TextField
                            className="mui-textfield"
                            floatingLabelText="نام اپلیکیشن*"
                            value={this.state.name}
                            type="text"
                            name="deviceRename"
                            onChange={event => {
                                this.setState({
                                    name: event.target.value,
                                    error: null,
                                });
                            }}
                            fullWidth={true}
                            errorText={this.renderError()}
                        />
                    </div>
                }
                footer={
                    <div>
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--solid mui-btn--blue"
                            rippleColor={blue900}
                            label="ذخیره"
                            onClick={() => {
                                if (!name) {
                                    this.setState({
                                        error: "این فیلد الزامی است",
                                    });
                                } else {
                                    this.props.onAccept(
                                        { name },
                                        callBack(),
                                        error => this.setState({ error }),
                                    );
                                }
                            }}
                        />
                        <FlatButton
                            className="mui-btn mui-btn--md mui-btn--border mui-btn--blue-white"
                            rippleColor={blue900}
                            label="انصراف"
                            onClick={() => onClose()}
                        />
                    </div>
                }
            />
        );
    }
}

RenameApplicationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    error: PropTypes.object,
};

export default withErrorCatcher(RenameApplicationModal);
