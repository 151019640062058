import React from "react";
import { useDispatch } from "react-redux";
import { hasPermission, withErrorCatcher } from "@/components/utilities";
import { changePlatform } from "@/actions/app";
import SidebarWrapState from "@/components/app/sidebar/SidebarWrapState";
import SidebarMenuWeb from "@/components/app/sidebar/menuComponents/SidebarMenuWeb";
import SidebarMenuIos from "@/components/app/sidebar/menuComponents/SiderbarMenuIos";
import SidebarPlusMenuAndroid from "@/components/app/sidebar/menuComponents/SidebarMenuAndroid";

const SidebarMenus = props => {
    const { platform, onMenuItemClick, history } = props;
    const dispatch = useDispatch();

    const checkPermissionOnClick = (
        clickedPlatform,
        permission,
        pushAction,
    ) => {
        if (!!permission && !hasPermission(permission)) {
            return;
        }

        if (clickedPlatform !== platform) {
            changePlatform({ platform: clickedPlatform })(dispatch);
            if (typeof pushAction === "function") {
                pushAction();
            }
        }
    };

    return (
        <div className="menu-list" id="menu-list">
            <SidebarWrapState>
                <SidebarPlusMenuAndroid
                    platform={platform}
                    checkPermissionOnClick={checkPermissionOnClick}
                    onMenuItemClick={onMenuItemClick}
                    history={history}
                />

                <SidebarMenuIos
                    history={history}
                    platform={platform}
                    checkPermissionOnClick={checkPermissionOnClick}
                    onMenuItemClick={onMenuItemClick}
                />

                <SidebarMenuWeb
                    history={history}
                    platform={platform}
                    checkPermissionOnClick={checkPermissionOnClick}
                    onMenuItemClick={onMenuItemClick}
                />
            </SidebarWrapState>
        </div>
    );
};

export default withErrorCatcher(SidebarMenus);
