// Notification
import NotificationListPage from "@/components/notifications/notification/list/NotificationListPage";
import ForwardNotificationPage from "@/components/notifications/notification/create/ForwardNotificationPage";
import EditDraftPage from "@/components/notifications/notification/create/EditDraftPage";
import SendNotificationPage from "@/components/notifications/notification/create/SendNotificationPage";
import WelcomeNotificationPage from "@/components/notifications/notification/create/WelcomeNotificationPage";
import UpdateNotificationPage from "@/components/notifications/notification/create/UpdateNotificationPage";
import NotificationAutomationCreatePage from "@/components/notifications/automation/create/AutomationCreatePage";
import NotificationAutomationEditPage from "@/components/notifications/automation/create/AutomationEditPage";
import NotificationAutomationPage from "@/components/notifications/automation/list/AutomationPage";
import NotificationGeofencePage from "@/components/notifications/geofence/list/GeofencePage";
import NotificationGeofenceCreatePage from "@/components/notifications/geofence/create/GeofenceCreatePage";
import NotificationGeofenceEditPage from "@/components/notifications/geofence/create/GeofenceEditPage";
// Users
import InstallationsPage from "@/components/users/installations/InstallationsPage";
import SegmentationPage from "@/components/users/segmentation/SegmentationPage";
import UsersAnalyticsPage from "@/components/users/analytics/UsersAnalyticsPage";
// Applications
import ApplicationPage from "application/create/ApplicationPage";
import GuidePage from "application/guides/GuidePage";
import ManifestPage from "application/guides/ManifestPage";
import Applications from "application/containers/applications"; // Just for android
import ApplicationsPage from "application/list/ApplicationListPage";
// Email
import SendEmailPage from "@/components/email/email/create/SendEmailPage";
import ForwardEmailPage from "@/components/email/email/create/ForwardEmailPage";
import EmailListPage from "@/components/email/email/list/EmailListPage";
import EmailAutomationListPage from "@/components/email/automation/list/AutomationPage";
import EmailAutomationCreatePage from "@/components/email/automation/create/AutomationCreatePage";
import EmailAutomationEditPage from "@/components/email/automation/create/AutomationEditPage";
// SMS
import SendSMSPage from "@/components/sms/sms/create/SendSMSPage";
import SMSListPage from "@/components/sms/sms/list/SMSListPage";
import ForwardSMSPage from "@/components/sms/sms/create/ForwardSMSPage";
import SMSAutomationCreatePage from "@/components/sms/automation/create/AutomationCreatePage";
import SMSAutomationEditPage from "@/components/sms/automation/create/AutomationEditPage";
import SMSAutomationPage from "@/components/sms/automation/list/AutomationPage";
// Profile
import Profile from "@/components/app/profile/Profile";
import EmailChangeVerifiedPage from "@/components/app/profile/EmailChangeVerifiedPage";
// Misc
import Dashboard from "@/components/dashboard/containers/dashboard";
import FinancialPage from "@/components/financial/FinancialPage";
// Authentication
import SignInPage from "authentication/signin/SignInPage";
import ForgotPassPage from "authentication/forgotpass/ForgotPassPage";
import SignUpPage from "authentication/signup/SignUpPage";
import SignInDeskProPage from "authentication/signin/SignInDeskProPage";
import ResetPassPage from "authentication/forgotpass/ResetPassPage";
import ActivationPage from "authentication/signin/Activation";
import NoafarinAuthPage from "authentication/noafarin/NoafarinAuth";
import OTPAuthPage from "authentication/signin/OTPAuth";
import SegmentationWeb from "@/components/users/segmentation/web/segmentaionWeb";
import DashboardPage from "@/components/newDashboard/DashboardPage";

export default {
    // Notification
    NotificationListPage,
    ForwardNotificationPage,
    EditDraftPage,
    SendNotificationPage,
    WelcomeNotificationPage,
    UpdateNotificationPage,
    NotificationAutomationCreatePage,
    NotificationAutomationEditPage,
    NotificationAutomationPage,
    NotificationGeofencePage,
    NotificationGeofenceCreatePage,
    NotificationGeofenceEditPage,
    // Users
    InstallationsPage,
    SegmentationPage,
    SegmentationWeb,
    UsersAnalyticsPage,
    // Applications
    ApplicationPage,
    GuidePage,
    ManifestPage,
    Applications,
    ApplicationsPage,
    // Email
    SendEmailPage,
    ForwardEmailPage,
    EmailListPage,
    EmailAutomationListPage,
    EmailAutomationCreatePage,
    EmailAutomationEditPage,
    // SMS
    SendSMSPage,
    SMSListPage,
    ForwardSMSPage,
    SMSAutomationCreatePage,
    SMSAutomationEditPage,
    SMSAutomationPage,
    // Profile
    Profile,
    EmailChangeVerifiedPage,
    // MISC
    Dashboard,
    DashboardPage,
    FinancialPage,
    // Authentication
    SignInPage,
    ForgotPassPage,
    SignUpPage,
    SignInDeskProPage,
    ResetPassPage,
    ActivationPage,
    NoafarinAuthPage,
    OTPAuthPage,
};
