import React from "react";
import PropTypes from "prop-types";
import { withErrorCatcher } from "@/components/utilities";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { keyBy, isEqual } from "lodash";
import memoizeOne from "memoize-one";

class FilterSelectionField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsList: this.generateOptionList(),
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const currentOptionsList = this.generateOptionList();
        if (!isEqual(prevState.optionsList.sort(), currentOptionsList.sort())) {
            this.setState({ optionsList: currentOptionsList });
        }
    }

    generateOptionList() {
        const { list = [], mapping } = this.props;

        return this.memoizedOptionList(list, mapping);
    }

    handleChange(values) {
        const {
            input: { onChange },
        } = this.props;
        let value = (values || []).map(i => i.value);
        onChange(value);
    }

    handleValue(value) {
        let list = this.memoizedOptionMap(this.state.optionsList);

        if (value in list) {
            return list[value];
            // eslint-disable-next-line no-extra-boolean-cast
        } else if (!!value) {
            return {
                value,
                label: value,
            };
        }

        return null;
    }

    memoizedOptionList = memoizeOne((list, mapping) =>
        list.map(item => {
            return {
                label: item[mapping.label],
                value: item[mapping.value],
            };
        }),
    );

    memoizedOptionMap = memoizeOne(list => keyBy(list, x => x.value));

    render() {
        const {
            title,
            isMulti,
            placeholder,
            isCreatable,
            input: { value },
            closeMenuOnSelect,
            errorText,
        } = this.props;
        let Component = isCreatable ? CreatableSelect : Select;
        return (
            <div>
                <h5 className="filterSelectField-title">{title}</h5>
                <Component
                    isMulti={isMulti}
                    options={this.state.optionsList}
                    onChange={this.handleChange}
                    onSubmit={e => e.preventDefault()}
                    value={(value || []).map(v => this.handleValue(v))}
                    noOptionsMessage={() => "هیچ موردی یافت نشد"}
                    placeholder={placeholder}
                    closeMenuOnSelect={closeMenuOnSelect}
                    formatCreateLabel={inputValue => `ساخت: "${inputValue}"`}
                    classNamePrefix="filterSelectField"
                />
                {errorText && <p style={style.errorText}>{errorText}</p>}
            </div>
        );
    }
}

export default withErrorCatcher(FilterSelectionField);

FilterSelectionField.propTypes = {
    isMulti: PropTypes.bool,
    isCreatable: PropTypes.bool,
    placeholder: PropTypes.string,
    list: PropTypes.array.isRequired,
    closeMenuOnSelect: PropTypes.bool,
    title: PropTypes.string,
    mapping: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }),
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired, // Redux Form Provides this itself
};

FilterSelectionField.defaultProps = {
    isMulti: true,
    isCreatable: false,
    closeMenuOnSelect: false,
    mapping: { label: "name", value: "key" },
    placeholder: "انتخاب کنید ...",
};

const style = {
    errorText: {
        color: "#f44336",
        fontSize: ".8rem",
        padding: "3px 1px",
    },
};
