import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import Routes from "@/routing/Routes";
import materialTheme, {
    legacyMaterialTheme,
} from "@/routing/layouts/materialTheme";
import { MuiThemeProvider as V0MuiThemeProvider } from "material-ui/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MDXProvider } from "@mdx-js/react";
import svg4everybody from "svg4everybody";
import "styles/main.scss";
import { mdxComponents } from "@/components/utilities/conf/mdxConfig";
import { ReactQueryDevtools } from "react-query-devtools";

svg4everybody();

const App = ({ store, history }) => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <MuiThemeProvider theme={materialTheme}>
                    <V0MuiThemeProvider muiTheme={legacyMaterialTheme}>
                        <MDXProvider components={mdxComponents}>
                            {CONSOLE_ENV === "development" && (
                                <ReactQueryDevtools initialIsOpen={false} />
                            )}
                            <Routes />
                        </MDXProvider>
                    </V0MuiThemeProvider>
                </MuiThemeProvider>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;
