import React from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux/es/redux";
import { withRouter } from "react-router-dom";
import { uploadFile } from "@/actions/app";
import { hasOneSignal as checkHasOneSignal } from "@/utils";
import { FULL_CONTENT_TYPE_MODE } from "@/components/notifications/notification/constants";
import { withErrorCatcher } from "@/components/utilities";
import NotificationDetailedContentComponent from "@/components/utilities/commons/wizard/components/NotificationDetailedContentComponent";

const AndroidNotifStep2 = props => {
    const { hasOneSignal, uploadFile, fullContentType, notif_icon, change } =
        props;

    if (!fullContentType) {
        change("fullContentType", FULL_CONTENT_TYPE_MODE.CONTENT);
    }

    return (
        <form className="notif-step2">
            <NotificationDetailedContentComponent
                hasOneSignal={hasOneSignal}
                uploadFile={uploadFile}
                fullContentType={fullContentType}
                notif_icon={notif_icon}
                change={change}
            />
        </form>
    );
};

function mapStateToProps(state, ownProps) {
    const {
        passToSteps: { platform },
    } = ownProps;
    const {
        fullContentType,
        data,
        app_ids = [],
    } = formValueSelector("notification_wizard")(
        state,
        "fullContentType",
        "data",
        "app_ids",
    );
    const hasOneSignal = checkHasOneSignal(
        app_ids,
        state[platform].applications || [],
    );

    return {
        fullContentType,
        notif_icon: (data || {}).notif_icon,
        hasOneSignal,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            uploadFile,
        },
        dispatch,
    );
}

const AndroidNotifStep2RF = reduxForm({
    form: "notification_wizard",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (submitResult, dispatch, props) =>
        props.onSuccess(submitResult),
    onSubmitFail: (error, dispatch, submitError, props) =>
        props.onFailure(error),
})(AndroidNotifStep2);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(AndroidNotifStep2RF),
    ),
);
