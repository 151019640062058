/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withErrorCatcher } from "@/components/utilities";
import classNames from "classnames";
import { PLATFORMS } from "@/constants";
import { NavLink } from "react-router-dom";
import { ListItem } from "material-ui/List";
import { Dash, Home, SortLeft, WebSite } from "@/components/utils/lib/svgicons";
import PropTypes from "prop-types";
import parse from "url-parse";

const MENU_KEYS = {
    WEB_NOTIF: 1,
    USERS: 2,
    android: {
        EMAIL: 3,
        SMS: 4,
    },
    WEB: {
        EMAIL: 5,
        SMS: 6,
    },
};

const default_active_menus = {
    [MENU_KEYS.WEB_NOTIF]: false,
    [MENU_KEYS.USERS]: false,
    [MENU_KEYS.WEB.EMAIL]: false,
    [MENU_KEYS.WEB.SMS]: false,
};
const SidebarMenuWeb = props => {
    const { platform, checkPermissionOnClick, onMenuItemClick, history } =
        props;
    const [activeMenu, setActiveMenu] = useState(default_active_menus);
    const toggleActiveMenu = (activeMenuKey, activeMenuValue) => {
        setActiveMenu({
            ...default_active_menus,
            [activeMenuKey]: activeMenuValue,
        });
    };
    // When page is reloaded or initially opened with one of these pages this function will open them
    const handleOnInitOpenMenus = () => {
        const { pathname } = parse(window.location);
        if (!pathname) return;
        let menu = null;
        const result = pathname.match(
            /(user|installation|segmentation|notification|email|sms).*(web).*/,
        );
        if (result) {
            // eslint-disable-next-line no-unused-vars
            const [_, first] = result;
            switch (first) {
                case "user":
                case "installation":
                case "segmentation":
                    menu = MENU_KEYS.USERS;
                    break;
                case "notification":
                    menu = MENU_KEYS.WEB_NOTIF;
                    break;
                case "email":
                    menu = MENU_KEYS.WEB.EMAIL;
                    break;
                case "sms":
                    menu = MENU_KEYS.WEB.SMS;
                    break;
            }
        }

        if (menu) {
            onMenuItemClick({ hasNested: true });
            toggleActiveMenu(menu, true);
        }
    };

    useEffect(() => {
        handleOnInitOpenMenus();
    }, [history?.location?.pathname]);

    return (
        <div
            className={classNames("menu-catg", {
                "menu-catg--open": platform === PLATFORMS.WEB,
            })}
            onClick={() =>
                checkPermissionOnClick(PLATFORMS.WEB, null, () =>
                    history.push("/dashboard/web"),
                )
            }
        >
            <div className="menu-catg__header" id="web-platform-button">
                <svg className="icon-web">
                    <use href="#svg_web_fill" />
                </svg>
                <span>Web</span>
            </div>

            <div className="menu-catg__body">
                <NavLink to="/dashboard/web">
                    <ListItem
                        key={1}
                        className="menu-item"
                        primaryText=" داشبورد وب"
                        leftIcon={<Home viewBox="0 0 21 21" />}
                        hoverColor="#ffffff"
                        onClick={onMenuItemClick}
                    />
                </NavLink>
                <NavLink to="/application/web">
                    <ListItem
                        key={410}
                        className="menu-item"
                        primaryText="وب‌سایت‌ها"
                        leftIcon={<WebSite viewBox="0 0 20 15" />}
                        hoverColor="#ffffff"
                        onClick={onMenuItemClick}
                    />
                </NavLink>
                <ListItem
                    key={3}
                    //menuKey={MENU_KEYS.WEB_NOTIF}
                    id="platformWebNotifsBtn"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.WEB_NOTIF],
                    })}
                    primaryText="نوتیفیکیشن‌ها"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.WEB_NOTIF]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.WEB_NOTIF, open);
                    }}
                    nestedItems={[
                        <NavLink key={105} to="/notification/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="نوتیفیکیشن‌های ارسالی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink key={106} to="/notification/automation/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="نوتیفیکیشن اتوماتیک"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        /*<NavLink to="/notification/geofence/android">
          <ListItem
              key={103}
              className="menu-item submenu"
              primaryText="نوتیفیکیشن جغرافیایی"
              leftIcon={<Dash viewBox="0 0 492 492"/>}
              hoverColor="#ffffff"
              onClick={onMenuItemClick}
          />
      </NavLink>*/
                    ]}
                />

                <ListItem
                    key={4}
                    //menuKey={MENU_KEYS.USERS}
                    id="platformUsersBtn"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.USERS],
                    })}
                    primaryText="کاربران"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.USERS]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.USERS, open);
                    }}
                    nestedItems={[
                        <NavLink key={121} to="/installation/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="نصب‌ها"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink key={122} to="/segmentation/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="دسته‌بندی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,

                        /*      <NavLink to="/user/android">
                                  <ListItem
                                      key={123}
                                      className="menu-item submenu"
                                      primaryText="آمار کاربران"
                                      leftIcon={<Dash viewBox="0 0 492 492"/>}
                                      hoverColor="#ffffff"
                                      onClick={onMenuItemClick}
                                  />
                              </NavLink>,*/
                    ]}
                />
                <ListItem
                    key={5}
                    //menuKey={MENU_KEYS.WEB.EMAIL}
                    id="email-page-button"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.WEB.EMAIL],
                    })}
                    primaryText="ایمیل‌ها"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.WEB.EMAIL]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.WEB.EMAIL, open);
                    }}
                    nestedItems={[
                        <NavLink key={141} to="/email/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="ایمیل‌های ارسالی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink key={142} to="/email/automation/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="ایمیل‌های اتوماتیک"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                    ]}
                />

                <ListItem
                    key={6}
                    //menuKey={MENU_KEYS.WEB.SMS}
                    id="sms-page-button"
                    className={classNames("menu-item", {
                        "is-active": activeMenu[MENU_KEYS.WEB.SMS],
                    })}
                    primaryText="پیامک‌ها"
                    leftIcon={
                        <SortLeft
                            viewBox="0 0 12 22"
                            className="sort-left-icon"
                        />
                    }
                    hoverColor="#ffffff"
                    onClick={() => onMenuItemClick({ hasNested: true })}
                    primaryTogglesNestedList={true}
                    autoGenerateNestedIndicator={false}
                    open={activeMenu[MENU_KEYS.WEB.SMS]}
                    onNestedListToggle={({ state: { open } }) => {
                        toggleActiveMenu(MENU_KEYS.WEB.SMS, open);
                    }}
                    nestedItems={[
                        <NavLink key={161} to="/sms/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="پیامک‌های ارسالی"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                        <NavLink key={162} to="/sms/automation/web">
                            <ListItem
                                className="menu-item submenu"
                                primaryText="پیامک‌های اتوماتیک"
                                leftIcon={<Dash viewBox="0 0 492 492" />}
                                hoverColor="#ffffff"
                                onClick={onMenuItemClick}
                            />
                        </NavLink>,
                    ]}
                />
            </div>
        </div>
    );
};

SidebarMenuWeb.defaultProps = {};
SidebarMenuWeb.propTypes = {
    platform: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
    checkPermissionOnClick: PropTypes.func.isRequired,
};

export default withErrorCatcher(SidebarMenuWeb);
