import { SERVICE_TYPES_TRANSLATION } from "@/components/financial/constants";

export const renderServiceName = (serviceName, serviceType) => {
    if (!serviceType) return serviceName;
    if (!SERVICE_TYPES_TRANSLATION[serviceType]) return serviceName;
    return SERVICE_TYPES_TRANSLATION[serviceType]?.[serviceName] || serviceName;
};
export const generateServiceName = list => {
    let service = "";
    if (Array.isArray(list)) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].type) {
                service += `${
                    SERVICE_TYPES_TRANSLATION[list[i].type]?.[list[i].name] ||
                    list[i].name
                } `;
            }
            if (list.length === 2 && i === 0) service += " , ";
        }
    }

    return service;
};
