import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    contentRoot: {
        fontSize: "1.1rem",
        color: theme.palette.dashboard.black,
    },
    buttonRoot: {
        color: theme.palette.dashboard.white,
        backgroundColor: theme.palette.dashboard.blue,
        borderRadius: 0,
        "&:hover": {
            color: theme.palette.dashboard.white,
            backgroundColor: darken(theme.palette.dashboard.blue, 0.1),
        },
    },
    buttonText: {
        fontWeight: "bold",
        padding: "6px 16px",
    },
}));

export const UnPaidPermissionModal = props => {
    const { errmsg } = useSelector(state => state?.app?.permissionError ?? {});

    const { onClick } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            BackdropProps={{ style: { backgroundColor: "rgb(0 0 0 / 0.8)" } }}
        >
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-slide-description"
                    classes={{ root: classes.contentRoot }}
                >
                    {errmsg}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    classes={{
                        root: classes.buttonRoot,
                        text: classes.buttonText,
                    }}
                    onClick={onClick}
                >
                    صفحه مالی
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnPaidPermissionModal;
