import React from "react";
import { getFormValues, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import {
    GeofenceMap,
    toFarsiNumbers,
    withErrorCatcher,
} from "@/components/utilities";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux/es/redux";
import { GEOFENCE_TRIGGER_TYPES_TRANSLATIONS } from "@/components/notifications/geofence/constants";

const GeofenceFinaleStep = ({ lat, long, radius, trigger }) => {
    const bgStyle = {
        padding: "0 2px",
        borderRadius: "2px",
        margin: "0 4px",
        color: "#333",
    };
    const radiusInKm =
        typeof Number(radius) === "number" && !isNaN(Number(radius))
            ? Number(radius) / 1000
            : 0.1;
    const radiusInKmFarsi = toFarsiNumbers(radiusInKm);

    return (
        <div className="notif-block">
            <p>
                آیا از ارسال نوتیفیکیشن به هنگام
                <span style={bgStyle}>
                    {GEOFENCE_TRIGGER_TYPES_TRANSLATIONS[trigger]}
                </span>
                کاربران به منطقه جغرافیایی زیر با شعاع
                <span style={bgStyle}>{radiusInKmFarsi}</span>
                کیلومتر اطمینان دارید؟
            </p>

            <br />

            <div className="map-block">
                <GeofenceMap
                    center={[long, lat]}
                    geofenceCenter={[long, lat]}
                    geofenceRadius={radiusInKm}
                    zoom={[12]}
                    interaction={false}
                />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const {
        lat,
        long,
        radius = 1,
        trigger,
    } = getFormValues("geofence_form")(state) || {};
    return {
        lat,
        long,
        radius,
        trigger,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

const GeofenceFinaleStepRF = reduxForm({
    form: "geofence_form",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(GeofenceFinaleStep);

export default withRouter(
    withErrorCatcher(
        connect(mapStateToProps, mapDispatchToProps)(GeofenceFinaleStepRF),
    ),
);
