import React, { useState } from "react";
import PropTypes from "prop-types";
import BaseModal from "@/components/utilities/modal/baseModal";
import { withErrorCatcher } from "@/components/utilities";
import { MODAL_THEME } from "@/actions/_types";

const RemoveApplicationModal = props => {
    const [error, setError] = useState("");
    const { app, onClose } = props;
    const onSuccess = () => {
        onClose();
        setError(null);
    };
    return (
        <BaseModal
            theme={MODAL_THEME.RED}
            title="حذف دستگاه از لیست علاقه مندی ها"
            useDefaultFooter={true}
            desc="آیا مطمئن هستید؟"
            body={
                <div>
                    دستگاه مورد نظرتان &nbsp;{app?.favorite_data.name}&nbsp; از
                    لیست علاقمندی ها حذف می شود و دیگر نمی توانید آن ها را
                    بازیابی کنید.
                </div>
            }
            error={error}
            onClose={onClose}
            onAccept={() =>
                props.onAccept({ id: app.id }, onSuccess(), error =>
                    setError(error),
                )
            }
        />
    );
};

RemoveApplicationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
};

export default withErrorCatcher(RemoveApplicationModal);
