import { store } from "@/store";

/**
 * Accept a feature or list of features name and check if it is available in profile's permissions list
 *
 * @param feature
 */
const hasPermission = feature => {
    let permissions = [];

    try {
        permissions = store.getState().auth.profile.permissions;
    } catch (e) {
        permissions = [];
    } finally {
        if (!Array.isArray(permissions)) {
            permissions = [];
        }
    }

    if (Array.isArray(feature)) {
        return feature.every(feat => permissions.includes(feat));
    }

    return permissions.includes(feature);
};

export { hasPermission };
