import React, { useMemo } from "react";
import { withErrorCatcher } from "@/components/utilities";
import { iranMap, iranMapNames } from "@/components/utilities/map/mapsData";
import Legend from "@/components/utils/map/legend";
import Iran from "@/components/utils/map/iran";
import EmptyChartImagePlaceHolder from "@/components/utilities/emptyChartImagePlaceHolder";
import mapImage from "images/analytics/map.jpeg";
import { FIRST_DAY_EMPTY_CHART_MESSAGE } from "@/constants";

const IranMapCharts = props => {
    const { data } = props;

    // Create a map of counties id to their names (from the actual map data)
    const mappedCountriesIdToName = useMemo(() => {
        return iranMap.features.reduce((acc, curr) => {
            acc[curr.properties.name.toLowerCase()] = curr.id;
            return acc;
        }, {});
    }, []);

    // Create a list containing objects of country `code` and country data value (count) as `value`
    const normalizedMapData = (() => {
        let total = 0;

        const mappedNameToCodes = Object.entries(data).reduce((acc, curr) => {
            const [name, count] = curr;
            let code = mappedCountriesIdToName[fixStateNames(name)];
            if (name === "#all" || !code) return acc;

            if (acc[code]) {
                acc[code] += count;
            } else {
                acc[code] = count;
            }
            total += count;

            return acc;
        }, {});

        return Object.entries(mappedNameToCodes).map(val => ({
            code: val[0],
            value: parseFloat(((val[1] / total) * 100).toFixed(2)),
        }));
    })();
    return (
        <div className="card-g">
            <div className="card-g__wrap">
                <header className="card-g__header">
                    <p className="card-g__title">
                        <svg>
                            <use xlinkHref="#svg_pin" />
                        </svg>
                        ایران
                    </p>
                </header>

                <div className="card-g__body">
                    <p>
                        اطلاعات موقعیت مکانی کاربران به شما کمک می‌کند به الگوی
                        جذب کاربر و هدفمندی تبلیغات خود پی ببرید. ضمن اینکه
                        می‌توانید امکانات اپلیکیشن خود را مطابق با کاربران خود
                        تنظیم کنید.
                    </p>
                </div>
            </div>

            {Object.keys(data).length <= 2 ? (
                <EmptyChartImagePlaceHolder
                    src={mapImage}
                    massage={FIRST_DAY_EMPTY_CHART_MESSAGE}
                    fullWidth
                />
            ) : (
                <div className="row">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "0 15px",
                        }}
                    >
                        <Legend
                            mapNames={iranMapNames}
                            data={normalizedMapData}
                            className="legend--vertical legend--horizontal-xs legend--iran-map"
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "0 15px",
                        }}
                    >
                        <Iran data={normalizedMapData} />
                    </div>
                </div>
            )}
        </div>
    );
};

const fixStateNames = name => {
    const mapOfNamesToActualMapNames = {
        "east azerbaijan": "east azarbaijan",
        "azarbayjan-e gharbi": "west azarbaijan",
        ardabil: "ardebil",
        "khorasan-e jonubi": "south khorasan",
        "khorasan-e shomali": "north khorasan",
        "chahar mahall va bakhtiari": "chahar mahall and bakhtiari",
        "kohgiluyeh va buyer ahmad": "kohgiluyeh and buyer ahmad",
    };
    return mapOfNamesToActualMapNames[name] || name;
};

export default withErrorCatcher(IranMapCharts);
